import React from "react";
import Modal from "react-bootstrap/Modal";
import "../permission-oem/permission.scss";

export type IpropsPersmission = {
  show: boolean;
  handleSkhow: (p: boolean) => void;
};

export default function Index(props: IpropsPersmission) {
  return (
    <Modal
      show={props?.show}
      onHide={() => props?.handleSkhow(false)}
      className="small forDesactivate"
    >
      <Modal.Header>
        <div className="headerpermission noStep">
          <h2 className="title">Permission</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={() => props?.handleSkhow(false)}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row m-0 p-0 desactivepopup">
          <h3 className="title withIcon">
            <span className="icon-info"></span>
            Warning
          </h3>

          <p>Your current role doesn’t give you access to this feature.</p>
          <p>
            Contact your Program owner or Admin to change your role if needed.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
