/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { contact } from "../../../../../utils/country_phone";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactFlagsSelect from "react-flags-select";
import Modal from "react-bootstrap/Modal";
import { IPropsEditInfoLeft } from "../types";
import { isEmail } from "../../../../../utils";
import FundRaisingGoal from "../../../../widget/slider-fundraising/index";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

const createSliderWithTooltip = Slider.Range;
const Range = createSliderWithTooltip;
export default function EditInfoDetailLeft(props: IPropsEditInfoLeft) {
  return (
    <Modal
      show={props.showDetailsLeft}
      className="createCompanypopup large forStep5custom"
    >
      <form onSubmit={props?.handleSubmit(props?.submit)}>
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Investor page Details</h2>
            <a className="close" onClick={() => props?.closeModalInfoLeft()}>
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row forPitch">
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-3 pr-md-2  p-0">
              <label>
                Name of the organization
                {props.errors.nameOfOrganisation && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...props?.register("nameOfOrganisation", {
                  required: props?.required,
                })}
              />
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-3 pl-md-2">
              <label>
                Investment size
                {props.errorFundraising && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <FundRaisingGoal {...props} />
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-3 pr-md-2  p-0">
              <label>
                Email address
                {props.errors.email?.type === "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                {props.errors.email?.type === "validate" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      Email address is not valide
                    </span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...props?.register("email", {
                  required: props?.required,
                  validate: (email: string) => isEmail(email),
                })}
              />
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-3 pl-md-2 numberphone">
              <label>
                Phone number
                {props.errors.phoneNumber?.type === "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                {props.errors.phoneNumber?.type === "validate" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">Phone number is not valid</span>
                  </div>
                )}
              </label>
              <div className="numberphone" style={{ position: "relative" }}>
                <input
                  type="number"
                  className="phonenumber"
                  style={{
                    textIndent:
                      contact?.find((el: any) => el.code == props?.selected)
                        ?.dial_code?.length === 2
                        ? 35
                        : contact?.find((el: any) => el.code == props?.selected)
                            ?.dial_code?.length === 3
                        ? 40
                        : contact?.find((el: any) => el.code == props?.selected)
                            ?.dial_code?.length === 4
                        ? 50
                        : 60,
                    paddingBottom: 5,
                  }}
                  {...props?.register("phoneNumber", {
                    required: props?.required,
                    validate: (phone: number) =>
                      isValidPhoneNumber(
                        contact?.find((el: any) => el.code == props?.selected)
                          ?.dial_code + phone?.toString()
                      ),
                  })}
                />
                <span style={{ position: "absolute", top: 5, left: 60 }}>
                  (
                  {contact &&
                    contact.find((el: any) => el.code == props?.selected)
                      ?.dial_code}
                  )
                </span>
                <ReactFlagsSelect
                  className="selectFlag"
                  selected={props?.selected}
                  onSelect={(code) => props?.setSelected(code)}
                  searchable={true}
                  showSelectedLabel={false}
                  placeholder=" "
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right forPitch">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => props?.closeModalInfoLeft()}
              type="button"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-devinsider px-5">
              {props?.loader ? <Loader /> : "Save"}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
