/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from '../../../../widget/header-logo-center/header-logo-center';
import UseCompleteContributorAccount from '../../../../../controller/account-ctr/complete-create-acount-expert-contributor/use-complete-contributor-account';
import { checkIfExist, getSStorage } from '../../../../../utils';

export default function CompleteContributorAccount() {
  const { params } = UseCompleteContributorAccount();

  if (params === undefined) {
    return <Redirect to='/signin' />;
  }
  // const sess = getSStorage('businessEmailExpert');

  // if (!checkIfExist(sess)) {
  //   return <Redirect to='/start-expert' />;
  // }

  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <div className='head-getstarted'>
          <h1>Thank you for your form submission.</h1>
        </div>
        <div className='contentBorder border-top border-bottom stepfinal'>
          <p className='mail'>{params?.email}</p>
          <p>
            We are currently processing your submission. This usually takes less
            than 48 hours.
            <br />
            We'll be in touch soon!
          </p>
        </div>
      </div>
    </div>
  );
}
