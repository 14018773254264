import { Dispatch } from "../../types/type-action";
import * as Service from "../../../service/applicatif/company-page-isv";
import store from "../../store";

export const GET_OTHER_COMPANY_PAGE = "GET_OTHER_COMPANY_PAGE";
export const GET_OTHER_COMPANY_PAGE_ATTRIBUTE =
  "GET_OTHER_COMPANY_PAGE_ATTRIBUTE";

/**
 * @param id id company page
 * @callback function  (params:any) {
   return response company age
 }
 */
const getOtherCompanyPageAction = (
  id: string,
  callback: any,
  idOem: any = null
) => {
  return async (dispatch: Dispatch) => {
    try {
      let result = null;
      //Dev #45847,V2.1 [OEM/Ecosystem user interface] Preview Mode of an ISV company profile
      result = await Service.getCompanyPage(
        id,
        store.getState().userReducer.token
      );
      if (result?.data !== "Forbidden" && result.status === 200) {
        dispatch({
          type: GET_OTHER_COMPANY_PAGE,
          payload: result && result.data,
        });
      }

      callback(result);

      return;
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/*********************************************************************
 *
 * Get attribute in company page
 */
const getOtherCompanyPageAttributeAction = (
  att: string,
  idCompany: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.getAttributeCompanyPage(
        att,
        store.getState().userReducer.token,
        idCompany
      );
      if (result && result.status === 200) {
        dispatch({
          type: GET_OTHER_COMPANY_PAGE_ATTRIBUTE,
          payload: {
            data: result && result.data,
            attribute: customAattribute(att),
          },
        });
      }
      callback(result);
      return;
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * @param key string to custom key in reducer
 * @returns string to modifie payload reducer
 */
const customAattribute = (attr: string): string => {
  let temp: string = "";
  switch (attr) {
    case "segmentDetails":
      temp = "segment_details";
      break;
    case "targetedIndustry":
      temp = "targeted_industry";
      break;
    case "oemProgramSegmentInterest":
      temp = "oem_program_segment_interest";
      break;
    case "distributionChannel":
      temp = "distribution_channel";
      break;
    case "targetedCountry":
      temp = "targeted_country";
      break;
    case "oemProgramSegmentInterestText":
      temp = "oemProgramSegmentInterestText";
      break;
    case "partners":
      temp = "partners";
      break;
    default:
      break;
  }
  return temp;
};

export { getOtherCompanyPageAction, getOtherCompanyPageAttributeAction };
