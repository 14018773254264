import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { IAd } from '../../../redux/types/classified-ads-interface';
import { getSearchResults } from '../../../service/applicatif/classified-ads';
import { useHistory } from 'react-router-dom';

export default function UseClassifiedAdsResearch() {
  const location: any = useLocation();
  const [searchText, setSearchText] = useState<string>(
    location?.state?.inputText || ''
  );
  const history = useHistory();
  const [page, setPage] = useState<number>(1);
  const [ads, setAds] = useState<Array<IAd>>([]);
  const [total, setTotal] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);

  const getResults = async (key: string, value: any) => {
    let params = {
      search: searchText,
      page: page,
      [key]: value,
    };

    console.log('Params', params);

    let data = await getSearchResults(params);
    if (data) {
      setLoader(false);
      return data;
    }
  };

  useEffect(() => {
    (async () => {
      setLoader(true);
      setPage(1);
      let data = await getResults('page', 1);
      if (data) {
        setAds(data?.results);
        setTotal(parseInt(data?.total_result_to_paginate));
      }
    })();
  }, []);

  const handleChange = (text: string) => {
    setSearchText(text);
  };

  const handleClick = async () => {
    setLoader(true);
    setPage(1);
    let data = await getResults('page', 1);
    if (data) {
      setAds(data?.results);
      setTotal(parseInt(data?.total_result_to_paginate));
    }
  };

  const handleViewMore = async () => {
    setPage(page + 1);
    setLoader(true);
    let data = await getResults('page', page + 1);
    if (data) {
      setAds([...ads, ...data?.results]);
      setTotal(parseInt(data?.total_result_to_paginate));
    }
  };

  const goToCategoryPage = (idCategory: any) => {
    history.push('/classified-ads/category/' + idCategory);
  };

  return {
    searchText,
    handleViewMore,
    total,
    ads,
    loader,
    showLogin,
    setShowLogin,
    handleChange,
    handleClick,
    page,
    goToCategoryPage,
  };
}
