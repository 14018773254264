import React, { useState } from "react";
import { useHistory } from "react-router";
// import useEventListener from "../../../hooks/event-handler";

export default function UseCompleteAboutExpert() {
  const history = useHistory();
  const [aboutExpert, setAboutExpert] = useState<string>(
    sessionStorage.getItem("aboutExpert")
      ? sessionStorage.getItem("aboutExpert") || ""
      : ""
  );

  const navigateCompletePostExpert = () => {
    if (aboutExpert) {
      sessionStorage.setItem("aboutExpert", aboutExpert);
    }
    history.push("/complete-post-expert");
  };

  // const keyEnter = ({ key }: any) => {
  //   if (key == "Enter") {
  //     navigateCompletePostExpert();
  //   } else return;
  // };

  // useEventListener("keydown", keyEnter);
  return { aboutExpert, setAboutExpert, navigateCompletePostExpert };
}
