import React, { useEffect, useState, useCallback } from "react";
import store from "../../../../redux/store";
import { getDataService } from "../../../../service/applicatif/back-end-service";
import { url } from "../../../../service/constant";
import { useHistory } from "react-router";

export default function Index() {
  /* get top like contributions */
  const [unanswerdDiscussion, setUnanswerdDiscussion] = useState<Array<any>>(
    []
  );

  const [loaderUnanswered, setloaderUnanswered] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    async function getData() {
      setloaderUnanswered(true);
      const result = await getDataService(
        url.community.get_unanswerd_discussion,
        {},
        store.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        setloaderUnanswered(false);
        setUnanswerdDiscussion(result?.data);
      }
    }
    getData();
  }, []);

  const viewAllUnanswered = () => {
    history?.push("/community/all-without-replies", {
      activeKey: "author",
    });
  };

  const goToreplayPost = (categoryId: string, currentPostId: string) => {
    history.push("/community/reply-post/" + categoryId + "/" + currentPostId);
  };

  const goToLabelCategory = (
    idCategory: any,
    labelId: string,
    post_type: string
  ) => {
    post_type === "1" &&
      history.push("/community/one-category/" + idCategory + "/" + labelId);

    post_type === "2" &&
      history.push("/community/article-category/" + idCategory + "/" + labelId);
  };

  return {
    unanswerdDiscussion,
    viewAllUnanswered,
    goToreplayPost,
    loaderUnanswered,
    goToLabelCategory,
  };
}
