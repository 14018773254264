/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent, useEffect } from 'react';

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/

//Bug #47392 "email address already in use error" - Should not apply when activating Software Ecosystem/ OEM account

import './style-partner-program.css';
import Footer from '../../../widget/footer/footer';
import Header from '../../../widget/index';
import usePartnerProgramSignup from '../../../../controller/account-ctr/complete-partner-program-signup/use-partner-program-signup';
import { BtnNext } from '../../../widget/bouton/btn-next';
import { assetVideoUrl, checkAndSetIfIsNumber, checkSpacingTxtPhoneNumber } from '../../../../utils';
import ReactPlayer from 'react-player';
import ReactFlagsSelect from 'react-flags-select';
import { contact } from '../../../../utils/country_phone';

export default function PartnerProgram() {
  const providerCreateProgram = usePartnerProgramSignup();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelect = (countryCode: any) => {
    providerCreateProgram.setSelected(countryCode);
    const dialCode = contact?.find(
      (el: any) => el.code == countryCode
    )?.dial_code;
    providerCreateProgram.setDialCodeSelected(dialCode ? dialCode : "");
  };

  return (
    <div className="layoutpartner">
      <Header />
      <section className="partener forProgram">
        <div className="container">
          <div className="textforjoin">
            <h1 className="title">
              Join Devinsider to create your OEM/Ecosystem partner program page
            </h1>
            <p>
              Devinsider is a global community dedicated to helping disruptive
              B2B <br />
              software vendors solve their business challenges and establish
              relevant <br />
              connections with technology partners (OEM and Ecosystems),
              investors, <br />
              and resellers.
            </p>
            {/* <img src={DevinsiderForReseller} alt="" className="imageforjoin" /> */}
            <ReactPlayer
              url={assetVideoUrl()}
              muted={false}
              volume={1}
              loop
              onReady={(playe: ReactPlayer) => {
                console.log("onReady", playe.getDuration());
              }}
              playing={true}
              controls
              className="videoforjoin"
            />
            {/*<img src={DevinsiderForReseller} alt="" className="imageforjoin" />*/}
            <p className="mb-6"></p>
            {/* <YouTube className="iframe" videoId="diQam5yvYyg" opts={opts} onReady={onPlayerReady} /> */}
          </div>
          <div className="getintouch">
            <h1 className="title">Register your program</h1>

            {!providerCreateProgram.sucessSentData && (
              <p>
                To register your program or to learn more about the benefits of joining Devinsider please fill out the form below.
              </p>
            )}
            {providerCreateProgram.sucessSentData && (
              <p>
                Thank you, one of our representatives will contact you directly
                for on-boarding.
              </p>
            )}
            {!providerCreateProgram.sucessSentData && (
              <div className="formGetInTouch row px-3">
                <div className="lastandfirstname col-12 px-0">
                  <div className="form-group">
                    {providerCreateProgram.errorFisteNameEcs && (
                      <label>
                        <div className="container-error d-inline">
                          <span className="error-red">Error</span>
                          <span className="txt-error">
                            This field is required
                          </span>
                        </div>
                      </label>
                    )}
                    <input
                      type="text"
                      placeholder="First name"
                      className="form-control"
                      value={providerCreateProgram.fisteNameEcosystem}
                      onChange={(evt: ChangeEvent<HTMLInputElement>): void =>
                        providerCreateProgram.setfisteNameEcosystem(
                          evt.target.value
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    {providerCreateProgram.errorLastNameEcs && (
                      <label>
                        <div className="container-error d-inline">
                          <span className="error-red">Error</span>
                          <span className="txt-error">
                            This field is required
                          </span>
                        </div>
                      </label>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      value={providerCreateProgram.lastNameEcosystem}
                      onChange={(evt: ChangeEvent<HTMLInputElement>): void =>
                        providerCreateProgram.setlastNameEcosystem(
                          evt.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-md-12 col-12 from-group px-0"></div>
                <div className="col-xl-6 col-md-12 col-12 from-group px-0"></div>
                <div className="col-12 form-group px-0">
                  {providerCreateProgram.errorJobTitleEcs && (
                    <label>
                      <div className="container-error d-inline">
                        <span className="error-red">Error</span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    </label>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job title"
                    value={providerCreateProgram.jobTitleEcosystem}
                    onChange={(evt: ChangeEvent<HTMLInputElement>): void =>
                      providerCreateProgram.setJobTitleEcosystem(
                        evt.target.value
                      )
                    }
                  />
                </div>
                <div className="col-12 form-group px-0">
                  {providerCreateProgram.errorCompanyEcs && (
                    <label>
                      <div className="container-error d-inline">
                        <span className="error-red">Error</span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    </label>
                  )}
                  <input
                    type="text"
                    placeholder="Company"
                    className="form-control"
                    value={providerCreateProgram.companyEcosystem}
                    onChange={(evt: ChangeEvent<HTMLInputElement>): void =>
                      providerCreateProgram.setCompanyEcosystem(
                        evt.target.value
                      )
                    }
                  />
                </div>
                <div className="col-12 form-group px-0">
                  {providerCreateProgram.errorCountryEcs && (
                    <label>
                      <div className="container-error d-inline">
                        <span className="error-red">Error</span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    </label>
                  )}
                  <select
                    name="pets"
                    id="pet-select"
                    className="custom-select"
                    onChange={(event: ChangeEvent<HTMLSelectElement>): void => {
                      //setCountry(event.target.value);
                      providerCreateProgram.setCountryEcosystem(
                        event.target.value
                      );
                    }}
                  >
                    <option value="">Country</option>
                    {providerCreateProgram.dataCompleted?.country?.map(
                      (element: any) => (
                        <option value={element.id}>
                          {`${element.country_name}`}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className="col-12 form-group px-0">
                  {providerCreateProgram.errorEmailEcs && (
                    <label>
                      <div className="container-error d-inline">
                        <span className="error-red">Error</span>
                        <span className="txt-error">
                          {providerCreateProgram.emailError}
                        </span>
                      </div>
                    </label>
                  )}
                  {providerCreateProgram.emailAlreadyExists && (
                    <label>
                      <div className="container-error d-inline">
                        <span className="error-red">Error</span>
                        <span className="txt-error">
                          This email address is already in use on Devinsider!
                        </span>
                      </div>
                    </label>
                  )}
                  <input
                    type="email"
                    placeholder="Email"
                    className="form-control"
                    value={providerCreateProgram.emailEcosystem}
                    onChange={(evt: ChangeEvent<HTMLInputElement>): void =>
                      providerCreateProgram.setEmailEcosteme(evt.target.value)
                    }
                    onBlur={(evt: ChangeEvent<HTMLInputElement>): void => {
                      if (evt.target.value) {
                        providerCreateProgram.checkIfEmailAlreadyExists(
                          evt.target.value
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-12 form-group px-0">
                  {providerCreateProgram.errorPhoneEcs === "validate" && (
                    <label>
                      <div className="container-error pl-2 d-inline">
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          Phone number is not valid
                        </span>
                      </div>
                    </label>
                  )}
                  {providerCreateProgram.errorPhoneEcs === "required" && (
                    <label>
                      <div className="container-error pl-2 d-inline">
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    </label>
                  )}

                  <div className="numberphone" style={{ position: "relative" }}>
                    <input
                      type="tel"
                      className="phonenumber"
                      value={providerCreateProgram.phoneEcosystem}
                      style={{
                        textIndent: checkSpacingTxtPhoneNumber(
                          providerCreateProgram.selected
                        ),
                        paddingBottom: 5,
                      }}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        checkAndSetIfIsNumber(
                          evt.target.value,
                          providerCreateProgram?.setPhoneEcosystem
                        );
                      }}
                    />
                    <span style={{ position: "absolute", top: 5, left: 60 }}>
                      (
                      {contact &&
                        contact.find(
                          (el: any) => el.code == providerCreateProgram.selected
                        )?.dial_code}
                      )
                    </span>
                    <ReactFlagsSelect
                      className="selectFlag"
                      selected={providerCreateProgram.selected}
                      onSelect={handleSelect}
                      searchable={true}
                      showSelectedLabel={false}
                      placeholder=" "
                    />
                  </div>
                </div>
                <div className="form-group form-check px-0 col-12 checkContent">
                  <input
                    type="checkbox"
                    checked={providerCreateProgram.cgu}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      providerCreateProgram.acceptCgu(e.target.checked)
                    }
                  />
                  <label className="noabs">
                    Yes, Devinsider may contact me for further discussion.
                    <br /> (
                    <span
                      onClick={() => window.open("/privacy-policy", "_blank")}
                    >
                      Privacy policy
                    </span>
                    )
                    {providerCreateProgram.cguNotAccepted && (
                      <div className={`container-error ${"d-block"}`}>
                        <span className="error-red" style={{ color: "white" }}>
                          {" "}
                          error{" "}
                        </span>
                        <span
                          className="txt-error"
                          style={{ color: "#DC3545" }}
                        >
                          This field is required
                        </span>
                      </div>
                    )}
                  </label>
                </div>
                <div className="col-12 px-0">
                  <button
                    className="btn btn-devinsider large containerBtnBtn"
                    onClick={() =>
                      !providerCreateProgram.emailAlreadyExists
                        ? providerCreateProgram.createAcount()
                        : null
                    }
                    disabled={
                      providerCreateProgram.emailAlreadyExists ||
                      providerCreateProgram.checkingEmailAlreadyExists
                    }
                  >
                    <BtnNext
                      text={"Submit"}
                      showLoader={
                        providerCreateProgram.loader ||
                        providerCreateProgram.checkingEmailAlreadyExists
                      }
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
