import React, { useEffect } from "react";
import Header from "../../../../widget/index";
import "../Top.scss";
import providerUseAuthor, {
  ItemAuthor,
} from "../../../../../controller/community-ctr/bloc-community-ctr/use-view-all-best-answer-author";
import { LoadingArticle } from "../../../../widget/loader";
import Pagination from "../../../../widget/use-pagination/pagination";
import { concatString, fileUrl, refaCtoNullKey } from "../../../../../utils";
import SearchForm from "../../../../widget/community/search/index";
import Footer from "../../../../widget/footer/footer";

export default function BestAnswer() {
  const providerViewAllController = providerUseAuthor();

  let firstColumn: Array<ItemAuthor> =
    providerViewAllController?.dataAuthor?.allData?.slice(0, 6);
  let secondColumn: Array<ItemAuthor> =
    providerViewAllController?.dataAuthor?.allData?.slice(6, 12);
  let thirdColumn: Array<ItemAuthor> =
    providerViewAllController?.dataAuthor?.allData?.slice(12, 18);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="container mx-auto">
        <ul className="breadcrumbPerso">
          <li>
            <a href="javascript:;">Community</a>
          </li>
          <li>Discussion categories</li>
        </ul>
        <div className="searchTopic">
          <SearchForm
            categories={
              providerViewAllController?.communityReducer?.discussionCategories
            }
            filterSearchText={providerViewAllController?.filterSearchText}
            handleFilterTxt={providerViewAllController?.handleFilterTxt}
            searchText={providerViewAllController?.inputText}
            handleCateGory={providerViewAllController?.handleCateGory}
          />
        </div>
        <div className="rightCommunityForum topContainer">
          <div className="block">
            <h2 className="title withLink">Top best answer authors</h2>

            <div className="timeRange">
              <p>Time range: </p>
              <a
                href="javascript:;"
                className={
                  providerViewAllController?.activeFilter === "last-day"
                    ? "active"
                    : ""
                }
                onClick={() =>
                  providerViewAllController?.setFilterAuthor("last-day")
                }
              >
                Last day (24 Hours)
              </a>
              <a
                href="javascript:;"
                className={
                  providerViewAllController?.activeFilter === "last-week"
                    ? "active"
                    : ""
                }
                onClick={() =>
                  providerViewAllController?.setFilterAuthor("last-week")
                }
              >
                Last week (7 Days)
              </a>
              <a
                href="javascript:;"
                className={
                  providerViewAllController?.activeFilter === "last-month"
                    ? "active"
                    : ""
                }
                onClick={() =>
                  providerViewAllController?.setFilterAuthor("last-month")
                }
              >
                Last months (30 Days)
              </a>
              <a
                href="javascript:;"
                className={
                  providerViewAllController?.activeFilter === "last-six-mounth"
                    ? "active"
                    : ""
                }
                onClick={() =>
                  providerViewAllController?.setFilterAuthor("last-six-mounth")
                }
              >
                Last 6 Months{" "}
              </a>
              <a
                href="javascript:;"
                className={
                  providerViewAllController?.activeFilter === "last-year"
                    ? "active"
                    : ""
                }
                onClick={() =>
                  providerViewAllController?.setFilterAuthor("last-year")
                }
              >
                Last year
              </a>
              <a
                href="javascript:;"
                className={
                  providerViewAllController?.activeFilter === "all-time"
                    ? "active"
                    : ""
                }
                onClick={() =>
                  providerViewAllController?.setFilterAuthor("all-time")
                }
              >
                All time
              </a>
            </div>
            <h3 className="title">
              Top best answer authors in "All categories"
            </h3>

            <div className="listAuthors">
              <div className="allList">
                <div className="rankAll">
                  {providerViewAllController?.topBestAuthor?.map(
                    (el: ItemAuthor, index: number) => (
                      <div className="itemRank" key={index}>
                        <div className="rang">#{el?.order_rank}</div>
                        <div className="infoRank">
                          <div className="imageAffiliatedcompany">
                            <img src={fileUrl(el?.profil_picture)} alt="" />
                          </div>
                          <div
                            className="nameAndLike"
                            onClick={(e: any) => {
                              providerViewAllController?.showActivityUser(
                                el?.id
                              );
                            }}
                          >
                            <p className="name">
                              {concatString(el?.user_name)}
                            </p>
                            <span className="like">
                              {el?.number_of_like} solutions
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="myrankContent">
                  <div className="myrank">
                    <div className="itemRank">
                      <div className="rang">
                        Your rank:
                        <br />#
                        {refaCtoNullKey(
                          providerViewAllController?.myRank?.order_rank
                        )}
                      </div>
                      <div className="infoRank">
                        <div className={`imageAffiliatedcompany empty`}>
                          {providerViewAllController?.myRank
                            ?.profil_picture && (
                            <img
                              src={fileUrl(
                                providerViewAllController?.myRank
                                  ?.profil_picture
                              )}
                              alt=""
                            />
                          )}
                        </div>
                        <div className="nameAndLike">
                          <p className="name">
                            {providerViewAllController?.myRank?.user_name ||
                              "Anonymous"}
                          </p>
                          {providerViewAllController?.myRank
                            ?.number_of_like && (
                            <span className="like">
                              {providerViewAllController?.myRank
                                ?.number_of_like + " "}
                              solutions
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="otherList row ">
                {providerViewAllController?.loaderAuthor ? (
                  <LoadingArticle />
                ) : (
                  <>
                    <div className="col-4">
                      {firstColumn?.map((el: ItemAuthor, index: number) => (
                        <div className="itemRank" key={index}>
                          <div className="rang">{el?.order_rank}</div>
                          <div className="infoRank">
                            <div
                              className="imageAffiliatedcompany"
                              onClick={(e: any) => {
                                providerViewAllController?.showActivityUser(
                                  el?.id
                                );
                              }}
                            >
                              <img src={fileUrl(el?.profil_picture)} alt="" />
                            </div>
                            <div className="nameAndLike">
                              <p className="name">
                                {concatString(el?.user_name)}
                              </p>
                              <span className="like">
                                {el?.number_of_like} Solutions
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-4">
                      {secondColumn?.map((el: ItemAuthor, index: number) => (
                        <div className="itemRank" key={index}>
                          <div className="rang">{el?.order_rank}</div>
                          <div className="infoRank">
                            <div
                              className="imageAffiliatedcompany"
                              onClick={(e: any) => {
                                providerViewAllController?.showActivityUser(
                                  el?.id
                                );
                              }}
                            >
                              <img src={fileUrl(el?.profil_picture)} alt="" />
                            </div>
                            <div className="nameAndLike">
                              <p className="name">
                                {concatString(el?.user_name)}
                              </p>
                              <span className="like">
                                {el?.number_of_like} Solutions
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-4">
                      {thirdColumn?.map((el: ItemAuthor, index: number) => (
                        <div className="itemRank" key={index}>
                          <div className="rang">{el?.order_rank}</div>
                          <div className="infoRank">
                            <div
                              className="imageAffiliatedcompany"
                              onClick={(e: any) => {
                                providerViewAllController?.showActivityUser(
                                  el?.id
                                );
                              }}
                            >
                              <img src={fileUrl(el?.profil_picture)} alt="" />
                            </div>
                            <div className="nameAndLike">
                              <p className="name">
                                {concatString(el?.user_name)}
                              </p>
                              <span className="like">
                                {el?.number_of_like} Solutions
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="pagination">
                      <Pagination
                        className="pagination"
                        currentPage={providerViewAllController?.currentPage}
                        totalCount={
                          providerViewAllController?.dataAuthor?.totalData
                        }
                        pageSize={18}
                        onPageChange={(page: any) =>
                          providerViewAllController?.handlePageClick(page)
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
