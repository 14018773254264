import { IsvReducer } from "../../types/isv-interface";
import { IAction } from "../../types/type-action";
import {
  SET_SUBSCRIBED_DISCUSSION,
  SET_UPCOMING_EVENT,
} from "../../actions/isv-action";
import { SubscribedDiscussion, UpcomingEvent } from "../../../interface/isv";

const initialState: IsvReducer = {
  upComingEvent: [],
  subscribedDiscussion: [],
};

export default function isvReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case SET_UPCOMING_EVENT:
      return {
        ...state,
        upComingEvent: action.payload as Array<UpcomingEvent>,
      };
    case SET_SUBSCRIBED_DISCUSSION:
      return {
        ...state,
        subscribedDiscussion: action.payload
          .favorites as Array<SubscribedDiscussion>,
      };
    default:
      return state;
  }
}
