import './../Oem-page.scss';
import React, { useState, useEffect } from 'react';
import Header from '../../../widget/index';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as BlockComponent from './block-oem';
import UseEditSettings from '../../../widget/oem-program-targeting/use-program-targeting';
import useProfilPageEdit from '../../../../controller/oem-ctr/use-profil-page-edit';
import Link from './link-header/index';
import ModalEditSettings from '../../../widget/oem-program-targeting/index';
import OemProgramSegment from '../../../../screen/container/oem/profil-oem/block-oem/oem-program-segment';
import Footer from '../../../widget/footer/footer';
import ModalPermission from '../../../widget/permission-oem/index';
import { LoadingAllPage } from '../../../widget/loader';
import UseManageCompanyAndBilling from '../../../widget/manage-company-and-billing/use-manage-company-and-billing';
import PlanDetails from '../../../widget/manage-company-and-billing/index';
import useOemResources from '../../../../controller/oem-ctr/block-component/use-oem-resources';
import UseOverview from '../../../../controller/oem-ctr/block-component/use-oem-overview-ctr';
import UseSolution from '../../../../controller/oem-ctr/block-component/use-solutions-ctr';
import UseOemKeyBenefict from '../../../../controller/oem-ctr/block-component/use-oem-key-benefict';
import UseOemGettingStarted from '../../../../controller/oem-ctr/block-component/use-oem-getting-started';
import { Redirect } from 'react-router';
import { OEM_PROGRAM, OEM_PROGRAM_EDITOR, OEM_PROGRAM_OUTREACH_REPRESENTATIVE } from '../../../../service/constant';

export default function OemProfilPage() {
  const providerUseManageCompanyAndBilling = UseManageCompanyAndBilling();
  const providerRessource = useOemResources();
  const providerOverview = UseOverview();
  const providerSolution = UseSolution();
  const providerKeyBenefic = UseOemKeyBenefict();
  const providerGettingStarted = UseOemGettingStarted();
  const {
    oemProgram,
    editMode,
    switchEditMode,
    defaultValue,
    scrollPosition,
    loader,
    publishOrUpdate,
    showPreview,
    isCompletedProgram,
    isOtherCompanyPage,
    goBackMatchingPanel,
    addFavorite,
    handLePersmission,
    showPermission,
    validateEventRole,
    validateEventEditProgram,
    validatePublishEvent,
    loadingPage,
    configOtherCompany,
    contactOemPage,
    isPreview,
    userReducer,
  } = useProfilPageEdit();

  const providerEditSettings = { ...UseEditSettings() };
  const txtBtn =
    oemProgram?.validate_by_devinsider && oemProgram?.status
      ? 'Update'
      : 'Publish';

  const allOemMembers = oemProgram?.oemProgramMembers;
  const userRole = allOemMembers.find(
    (Tuser) => Tuser.user.id == userReducer.id
  );
  const oemRole = userRole ? userRole.role : "";

  const showMessage =
    (!isCompletedProgram &&
      !isOtherCompanyPage &&
      !oemProgram?.validate_by_devinsider) ||
    oemProgram?.status === false;

  const isOnEditWysiwyg =
    providerOverview.isEditClick ||
    providerKeyBenefic.isEditClick ||
    providerSolution.isEditClick ||
    providerGettingStarted.isEditClick;

  const isOem = userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1;

  if (!isOem) {
    return <Redirect to={'/profil-not-found'} />;
  }

  return (
    <div className={`profilInvestor ${editMode && "editMode"}`}>
      <Header />
      <section className="InvestorPage ">
        {loadingPage && <LoadingAllPage />}
        <BlockComponent.OemBackground />
        <div className="container mx-auto cont2">
          <div className="titleContent d-xl-block d-lg-none d-md-none  d-none">
            <h3 className="title">{defaultValue?.programName} </h3>
          </div>
          <div className="contentLeftInvestor">
            <div
              className={`secondHeader ${
                scrollPosition < 470 ? "" : "fixedSecondHeader"
              }`}
            >
              <div className="container mx-auto">
                <Link
                  isEmptyFile={
                    providerRessource.isOtherUserType &&
                    providerRessource.isEmptyFile
                  }
                />
              </div>
            </div>
            {showMessage && (
              <div className="firstIn justForProfil">
                <b>Welcome to Devinsider!</b> The last step in the registration
                process is to complete your program page. On this page you
                describe what your program is about and how ISVs can benefit
                from it. Once your program page has been completed, approved,
                and published it is visible to ISVs and the matchmaking starts!
                <button className="btn" onClick={switchEditMode}>
                  Complete program page
                </button>
              </div>
            )}

            <div className="generalInfo" id="about">
              <BlockComponent.OemCompany />
              <BlockComponent.OemProgramInfo />
            </div>
            <div className="investorArticle justForProfil ">
              <OemProgramSegment />
            </div>
          </div>

          {!isPreview && (
            <div
              className={`contentRightInvestor ${
                scrollPosition < 470 ? "" : "fixContentRight"
              }`}
            >
              <BlockComponent.OemManaging
                switchEditMode={() => {
                  if (validateEventEditProgram()) {
                    switchEditMode();
                    providerOverview.clickCancel();
                    providerSolution.clickCancel();
                    providerKeyBenefic.clickCancel();
                    providerGettingStarted.clickCancel();
                  }
                }}
                showPreview={showPreview}
                setShowPageSettings={() =>
                  providerEditSettings?.setShowPageSettings()
                }
                showBtnManage={
                  oemRole &&
                  oemRole != OEM_PROGRAM_OUTREACH_REPRESENTATIVE &&
                  oemRole != OEM_PROGRAM_EDITOR
                    ? true
                    : false
                }
                showBtnEditPage={
                  oemRole &&
                  oemRole != OEM_PROGRAM_OUTREACH_REPRESENTATIVE &&
                  oemRole != OEM_PROGRAM_EDITOR
                    ? true
                    : false
                }
                showBtnPublish={
                  oemRole &&
                  oemRole != OEM_PROGRAM_OUTREACH_REPRESENTATIVE &&
                  oemRole != OEM_PROGRAM_EDITOR
                    ? true
                    : false
                }
                setShowManagePage={() =>
                  validateEventRole() &&
                  providerUseManageCompanyAndBilling?.setShowBillingCenter(true)
                }
                publishOrUpdate={() =>
                  validatePublishEvent() && publishOrUpdate()
                }
                editMode={editMode}
                oemProgram={oemProgram}
                loader={loader}
                txtBtn={txtBtn}
                isOtherUser={isOtherCompanyPage}
                matching_value={configOtherCompany.matching}
                goBackMatchingPanel={goBackMatchingPanel}
                addFavorite={addFavorite}
                isFavorite={configOtherCompany.is_favorite}
                contactOem={() => contactOemPage()}
                isPreview={isPreview}
                isOnEditWysiwyg={isOnEditWysiwyg}
              />
            </div>
          )}

          <div className="titleContent d-xl-none d-lg-block d-md-block d-block">
            <h3 className="title">{defaultValue?.programName}</h3>
          </div>
        </div>
        <div id="overview">
          <BlockComponent.OemOverview
            isDoneEditing={editMode}
            isPreview={isPreview}
            {...providerOverview}
          />
        </div>
        <div id="solutions">
          <BlockComponent.OemSolution
            isDoneEditing={editMode}
            isPreview={isPreview}
            {...providerSolution}
          />
        </div>
        <div id="key">
          <BlockComponent.OemKeyBenefict
            isDoneEditing={editMode}
            isPreview={isPreview}
            {...providerKeyBenefic}
          />
        </div>
        <div id="started">
          <BlockComponent.OemGettingStared
            isDoneEditing={editMode}
            isPreview={isPreview}
            {...providerGettingStarted}
          />
        </div>
        <div id="resource">
          <BlockComponent.OemResources
            fileDataSource={providerRessource.fileDataSource}
            isOtherUserType={providerRessource.isOtherUserType}
            showModalEditRessource={() =>
              providerRessource.showModalEditRessource()
            }
            isPreview={providerRessource.isPreview}
            isEdit={providerRessource.isEdit}
            removeFile={(p: any, index: any) =>
              providerRessource.removeFile(p, index)
            }
            setFileResource={(event: any, index: any) =>
              providerRessource.setFileResource(event, index)
            }
            closeRessourceModal={() => providerRessource.closeRessourceModal()}
            loader={providerRessource.loader}
            isShowRessource={providerRessource.isShowRessource}
            saveUpdate={() => providerRessource.saveUpdate()}
            isEmptyFile={providerRessource.isEmptyFile}
          />
        </div>

        <ModalEditSettings {...providerEditSettings} />

        <PlanDetails {...providerUseManageCompanyAndBilling} />
        <ModalPermission
          handleSkhow={handLePersmission}
          show={showPermission}
        />
      </section>
      <Footer />
    </div>
  );
}
