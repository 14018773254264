import React from "react";
import Header from "../../../widget/index";
import Footer from "../../../widget/footer/footer";
import UseGetStartedOem from "../../../../controller/get-started-ctr/oem-program-ctr/use-get-started-oem";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";
import { isEmail, between } from "../../../../utils/index";
import discover from "../../../../resources/images/findProgram/discover.png";
import match from "../../../../resources/images/findProgram/match.png";
import connect from "../../../../resources/images/findProgram/connect.png";
import access from "../../../../resources/images/findProgram/access.png";
import apply from "../../../../resources/images/findProgram/apply.png";
import review from "../../../../resources/images/findProgram/review.png";


export default function GetStartedInvestor() {
  const {
    createAccountInvestor,
    goToCommunity,
    goToBrowseProgram,
    goToIsvCreation
  } = UseGetStartedOem();
  return (
    <div>
      <Header />
      <section className="joinHome findProgram">
        <div className="container mx-auto">
            <h1 className="title">Connect to hundreds of <br/>
                partner programs for <br/>software vendors
            </h1>
            {/*<p className="descHead">
            Identify and apply to the most relevant partner programs <br/>
            to get access to industry-leading technology and business <br/>
            development opportunities.
            </p>*/}
          <a onClick={()=>goToIsvCreation()} className="getStartedLink">
            Get started
            <span className="itsfree">(It's free!)</span>
          </a>
        </div>
      </section>
      <section className="homeDescription bgblanc">
        <div className="container mx-auto">
          <ul className="showcase oem">
            <li>
              <p className="description">
                Discover partner
                programs in your niche
              </p>
              <img src={discover} alt=""/>
            </li>
            <li>
              <p className="description">
                Match and compare 
                within a few clicks
              </p>
              <img src={match} alt=""/>
            </li>
            <li>
              <p className="description">
                Connect and start
                onboarding
              </p>
              <img src={connect} alt=""/>
            </li>
          </ul>
        </div>
      </section>
      <section className="inclinereseller attractInvest prog">
        <div className="container mx-auto">
          <div className="texte">
            <h1 className="title">
            Get access to hundreds of technology 
            partner programs
            {" "}
            </h1>
            <p className="my-4">
            Match with OEM and Ecosystem partner programs based on your <br/>
            company profile and preferences. Devinsider keeps you up-to-date on the <br/>
            latest partnership opportunities in your market.
            </p>
            <a onClick={()=>goToIsvCreation()} href="javascript:;" className="getStartedLink mt-4">
              Get started
            </a>
          </div>
          <img src={access} alt="" />
        </div>
      </section>
      <section className="inclinereseller attractInvest prog">
        <div className="container mx-auto">
        <img src={apply} alt="" />
          <div className="texte">
            <h1 className="title">
            Apply to the most relevant partner <br/>
            programs for your business
            {" "}
            </h1>
            <p className="my-4">
            Easily compare different partnership offers, including the benefits and <br/>
            how to get started. Directly contact the right program representative for <br/>
            onboarding.
            </p>
            <a onClick={()=>goToIsvCreation()} href="javascript:;" className="getStartedLink mt-4">
              Get started
            </a>
          </div>
        </div>
      </section>
      <section className="getStartedHome matchInv">
        <div className="container mx-auto">
          <h1 className="title">
          Get started with Devinsider
          </h1>
          <p>
          Browse through all partner programs on Devinsider to find technology partners 
            that move your software business forward.
          </p>
          <a
            href="javascript:;"
            className="getStartedLink"
            onClick={()=>goToBrowseProgram()}
          >
            Browse programs
          </a>
        </div>
      </section>
      <section className="inclinereseller review">
        <div className="container mx-auto">
          <div className="texte">
            <h1 className="title">
            Review and discuss technology <br/>
            partnerships in the Devinsider <br/>
            Community
            {" "}
            </h1>
            <p className="my-4">
            Learn about the experiences of insiders, ask questions, <br/>
            and share your own partner program reviews with <br/>
            Devinsider community members.
            </p>
            <a onClick={()=>goToCommunity()} href="javascript:;" className="getStartedLink mt-4">
            Join the community
            </a>
          </div>
          <img src={review} alt="" />
        </div>
      </section>
      <Footer />
    </div>
  );
}
