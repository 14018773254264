import { join, split } from "lodash";
import React from "react";
import DiscussionCtr from "../../../../../controller/community-ctr/home-page/discussion-category";
import { ITemDetailsDiscutionCategorie } from "../../../../../redux/types/community-interface";

const DiscussionCategory = (props: any) => {
  const renderLastActivity = (last_activity: string) => {
    const last_splitted = split(last_activity, " ");
    if (parseInt(last_splitted[0]) > 1) last_splitted[1] += "s";
    return join(last_splitted, " ");
  };

  const discussionCategory = DiscussionCtr();

  return (
    <>
      <h2 className="title">Discussion categories</h2>
      <ul className="listBlockright categDiscussion">
        <li className="title">
          <div className="itemBlock">Subject</div>
          <div className="itemBlock">Contributions</div>
          <div className="itemBlock">Last activity</div>
        </li>

        {discussionCategory?.communityReducer?.detailsDiscutionCategorie?.map(
          (el: ITemDetailsDiscutionCategorie, index: number) => (
            <li
              key={index}
              onClick={() => discussionCategory?.goToLabelCategory(el?.id, "0")}
            >
              <div className="itemBlock txtLabel">
                <h3 className="title">
                  <span className="icon-discussion-semi"></span>
                  {el.title}
                </h3>
              </div>
              <div className="itemBlock contributionItem">
                {el.contribution}
              </div>
              <div className="itemBlock">
                <span className="time">
                  {el.last_activity === null
                    ? "-"
                    : renderLastActivity(el.last_activity)}
                </span>
              </div>
            </li>
          )
        )}
      </ul>
    </>
  );
};

export default DiscussionCategory;
