/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import UseEditProgramSegment from "../../../../../controller/oem-ctr/block-component/use-program-segment";
export default function SolutionSpecial() {
  const providerSolutionSpecial = { ...UseEditProgramSegment() };

  const lengthSolution = providerSolutionSpecial?.solutionList?.length;

  return (
    <>
      <ul className="investorInfoList moreInfo">
        <li>
          <label>Program segment(s)</label>
          <div className="listOf">
            {providerSolutionSpecial?.solutionList
              ?.filter((el: any, index: number) => {
                return index < providerSolutionSpecial?.index1;
              })
              ?.map((element: any, index: number) => (
                <p key={index}>{element?.name || element?.label}</p>
              ))}

            {lengthSolution > 6 && (
              <a
                className="seeall"
                onClick={(evt) => {
                  evt.preventDefault();
                  providerSolutionSpecial?.setindex1(
                    providerSolutionSpecial?.index1 === lengthSolution
                      ? 6
                      : lengthSolution
                  );
                }}
              >
                See{" "}
                {providerSolutionSpecial?.index1 === lengthSolution
                  ? "less"
                  : "more"}
                {providerSolutionSpecial?.index1 === lengthSolution
                  ? ""
                  : `(${lengthSolution - providerSolutionSpecial?.index1})`}
              </a>
            )}
          </div>
        </li>
      </ul>
    </>
  );
}
