/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from 'react';
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from '../../../../widget/header-logo-center/header-logo-center';
import UseCompleteProfileReseller from '../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-profile-reseller';
import { checkIfExist, getSStorage } from '../../../../../utils';
import { Redirect } from 'react-router-dom';
export default function CompleteProfilPictureResseler() {
  const {
    navigateToCompleteCompany,
    goBack,
    handleBtn,
    changeHandler,
    fileRef,
    selectedFile,
    isFilePicked,
    filePictureReseller,
    firstNameReseller,
    lastNameReseller,
    companyReseller,
    jobReseller,
    fileSizeError,
  } = UseCompleteProfileReseller();

  const sess = getSStorage('emailReseller');

  if (!checkIfExist(sess)) {
    return <Redirect to='/complete-info-reseller' />;
  }

  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <div className='head-getstarted'>
          <div className='stepInscription'>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
          </div>
          <a
            className='link-back'
            onClick={() => {
              goBack();
            }}
          >
            <span className='icon-back'></span>
          </a>
          <h1>Upload your profile picture.</h1>
          {fileSizeError && (
            <span className='txt-error'>The maximum file size is 2 MB. Please upload a photo with a smaller file size.</span>
          )}
        </div>
        <div className='contentBorder row align-items-stretch small-container'>
          <div className='col-xl-7 col-md-6 col-12 d-flex align-items-center photo-upload-getstrated'>
            <a
              className={`upload-photo ${selectedFile ? 'noBackground' : ''}`}
              onClick={() => handleBtn()}
            >
              {isFilePicked && selectedFile ? (
                <img
                  src={URL.createObjectURL(
                    filePictureReseller ? filePictureReseller : selectedFile
                  )}
                  alt=''
                />
              ) : (
                <span></span>
              )}
            </a>
            <div className='info-final-getstarted'>
              <div className='nom'>
                {firstNameReseller} {lastNameReseller}
              </div>
              <div className='post'>{jobReseller}</div>
              <div className='post'>
                <b>{companyReseller}</b>
              </div>
              <div className='membersince'>
                {`Member since ${new Date().toLocaleString('en-US', {
                  month: 'long',
                })} ${new Date().getDate()},  ${new Date().getFullYear()} `}{' '}
              </div>
            </div>
          </div>
          <div className='col-xl-5 col-md-6 col-12 d-flex flex-column align-items-center justify-content-center py-4 p-0 '>
            <a className='link-add-photo' onClick={() => handleBtn()}>
              Add photo
            </a>
            <input
              type='file'
              accept='image/png, image/jpeg'
              style={{ display: 'none' }}
              ref={fileRef}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                changeHandler(event)
              }
            />
             <p>
              (Max file size: 2 MB)
            </p>
          </div>
        </div>
        <div className='contentBorder row align-items-stretch'>
          <div className='col-12 d-flex align-items-center flex-column p-0'>
            <button
              className='btn btn-devinsider large'
              onClick={() => navigateToCompleteCompany()}
            >
              Next
            </button>
            <a className='skip' onClick={() => navigateToCompleteCompany(true)}>
              Skip
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
