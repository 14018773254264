/* eslint-disable jsx-a11y/anchor-is-valid */
/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from 'react';
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import UseCompleteProfilPicture from '../../../../../controller/account-ctr/create-oem-account/use-complete-profile-picture-oem';
import Header from '../../../../widget/header-logo-center/header-logo-center';
import './style-complete-profil-picture.css';
import { BtnNext } from '../../../../widget/bouton/btn-next';
import Modal from '../../../../widget/modal/modal';
import StepperOem from '../../../../widget/stepper/StepperOem';
import { checkIfExist, getSStorage } from '../../../../../utils';
import { Redirect } from 'react-router-dom';

export default function CompleteProfilPicture() {
  const {
    isFilePicked,
    selectedFile,
    fileRef,
    isLoaderShow,
    dataOem,
    error,
    history,
    setError,
    changeHandler,
    handleBtn,
    validateIsvInscription,
    skipvalidateIsvInscription,
    fileSizeError,
  } = UseCompleteProfilPicture();

  const sess: any = getSStorage('oemProject');

  if (!checkIfExist(sess)) {
    return <Redirect to='/partner-program-registration' />;
  }

  return (
    <div>
      <Header />
      <Modal open={error} handleModal={() => setError(false)} />
      <div className='getstartedContainer'>
        <StepperOem step={1} />
        <div className='head-getstarted pict'>
          <a className='link-back' onClick={() => history.goBack()}>
            <span className='icon-back'></span>
          </a>
          {fileSizeError && (
            <span className='txt-error'>The maximum file size is 2 MB. Please upload a photo with a smaller file size.</span>
          )}
        </div>
        <div className='contentBorder row align-items-stretch small-container'>
          <div className='col-xl-7 col-md-6 col-12 d-flex align-items-center photo-upload-getstrated'>
            <a
              className={`upload-photo ${selectedFile ? 'noBackground' : ''}`}
              onClick={() => handleBtn()}
            >
              {isFilePicked && selectedFile ? (
                <img src={URL.createObjectURL(selectedFile)} alt='' />
              ) : (
                <span></span>
              )}
            </a>
            <div className='info-final-getstarted'>
              <div className='nom'>
                {dataOem.firstName} {dataOem.lastName}
              </div>
              <div className='post'>{dataOem.jobTitle}</div>
              <div className='post'>
                {' '}
                <b>{dataOem.companyName}</b>{' '}
              </div>
              <div className='membersince'>
                {`Member since ${new Date().toLocaleString('en-US', {
                  month: 'long',
                })} ${new Date().getDate()},  ${new Date().getFullYear()} `}{' '}
              </div>
            </div>
          </div>
          <div className='col-xl-5 col-md-6 col-12 d-flex flex-column align-items-center justify-content-center py-4 p-0 '>
            <a onClick={() => handleBtn()} className='link-add-photo'>
              Add photo
            </a>
            <input
              type='file'
              id='getFile'
              accept='image/png, image/jpeg'
              ref={fileRef}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                changeHandler(event)
              }
              style={{ display: 'none' }}
            />
            <p>
              (Max file size: 2 MB)
            </p>
          </div>
        </div>
        <div className='contentBorder row align-items-stretch'>
          <div className='col-12 d-flex align-items-center flex-column p-0'>
            <div className='btn btn-devinsider large'>
              <BtnNext
                text='Next'
                click={() => validateIsvInscription()}
                showLoader={isLoaderShow}
              />
            </div>

            <a className='skip' onClick={() => skipvalidateIsvInscription()}>
              Skip
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
