import React, { useState } from "react";
import ManageCommunityAuthors from "../../../../controller/community-ctr/manage-feed-ctr/community-authors-ctr";
import { fileUrl } from "../../../../utils";
import Pagination from "../../../widget/use-pagination/pagination";
import { BtnAction } from "../../../widget/bouton/btn-action";
import { BtnConfirm } from "./../../../widget/bouton/btn-confirm";

export default function CommunityAuthor() {
  const providerManageCommunityUser = ManageCommunityAuthors();

  return (
    <div className="accountSettings contentRightsettings">
      <h1 className="title">Manage my Feed</h1>
      <ul className="listAffiliatedleft feedAffiliated">
        {providerManageCommunityUser?.communityReducer?.communityAuthors?.user_followed?.map(
          (el: any, key: number) => (
            <li>
              <div className="itemAuthor">
                <div
                  className={`imageAffiliatedcompany  ${
                    el.file_name ? "" : "empty"
                  }`}
                >
                  {el?.file_name && (
                    <img alt="Profile" src={`${fileUrl(el?.file_name)}`} />
                  )}
                </div>
                <div className="infoAffiliated">
                  <h3
                    className="title pointer"
                    onClick={() =>
                      providerManageCommunityUser.goActivityUser(el?.id)
                    }
                  >
                    {el?.first_name}&nbsp;{el?.last_name}
                  </h3>
                  <div className="post">{el?.job_title}</div>
                </div>
              </div>
              <div className="btnAndConfirm">
                <BtnAction
                  text="Following"
                  showLoader={
                    key === providerManageCommunityUser?.currentElement
                      ? providerManageCommunityUser?.loader
                      : false
                  }
                  classes="followFeed"
                  click={(data: any) =>
                    providerManageCommunityUser?.handleUnFollowClick(data, key)
                  }
                />
                {providerManageCommunityUser?.confirmFollow &&
                  key === providerManageCommunityUser?.currentElement && (
                    <BtnConfirm
                      cancelClick={() =>
                        providerManageCommunityUser?.cancelFollowClick()
                      }
                      submitClick={() =>
                        providerManageCommunityUser?.submitFollowClick(el)
                      }
                    />
                  )}
              </div>
            </li>
          )
        )}
      </ul>
      <div className="short-height"></div>
      <div className="pagination">
        <Pagination
          className="pagination"
          currentPage={providerManageCommunityUser?.currentCommunityAuthorsPage}
          totalCount={
            providerManageCommunityUser?.communityReducer?.communityAuthors
              ?.total
          }
          pageSize={providerManageCommunityUser?.limitUsers}
          onPageChange={(page: any) =>
            providerManageCommunityUser?.handlePageClick(page)
          }
        />
      </div>
    </div>
  );
}
