import { Dispatch } from "../../types/type-action";
import * as Service from "../../../service/applicatif/back-end-service";
import store from "../../store";
import { url } from "../../../service/constant";

export const GET_COMPANY_PAGE_INVESTOR = "GET_COMPANY_PAGE_INVESTOR";
export const UPDATE_COMPANY_PAGE_INVESTOR = "UPDATE_COMPANY_PAGE_INVESTOR";
export const UPDATE_COMPANY_PAGE_INVESTOR_PAYMENT_METHOD =
  "UPDATE_COMPANY_PAGE_INVESTOR_PAYMENT_METHOD";
export const UPDATE_DRAFT_DATA = "UPDATE_DRAFT_DATA";
export const UPDATE_DRAFT_RESSOURCE_DATA = "UPDATE_DRAFT_RESSOURCE_DATA";
export const UPDATE_DRAFT_EXPERTISE_DATA = "UPDATE_DRAFT_EXPERTISE_DATA";
export const REMOVE_RESOURCE_FILE = "REMOVE_RESOURCE_FILE";
export const UPDATE_BILLING_CENTER_CARD_INVESTOR =
  "UPDATE_BILLING_CENTER_CARD_INVESTOR";
export const GET_INVESTOR_ATTRIBUT = "GET_INVESTOR_ATTRIBUT";
export const UPDATE_DRAFT_PORTFOLIO_DATA = "UPDATE_DRAFT_PORTFOLIO_DATA";
export const UPDATE_OR_PUBLISH_DRAFT__DATA = "UPDATE_OR_PUBLISH_DRAFT__DATA";
export const REMOVE_LOGO_DRAFT = "REMOVE_LOGO_INVESTOR_PAGE";
export const REMOVE_COUVERTURE_DRAFT = "REMOVE_COUVERTURE_INVESTOR_PAGE";
//Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
export const UNPUBLISH_PROGRAM = "UNPUBLISH_PROGRAM";

const unpublishInvestorPage = () => {
  return {
    type: UNPUBLISH_PROGRAM,
    payload: false,
  };
};

const removeLogoInvestorPage = (photoProfilDraft: {
  photo_profil_draft: string;
  photo_profil: string;
}) => {
  return {
    type: REMOVE_LOGO_DRAFT,
    payload: photoProfilDraft,
  };
};
const removeCouvertureInvestorPage = (photoCouvertureDraft: {
  photo_couverture_draft: string;
  photo_couverture: string;
}) => {
  return {
    type: REMOVE_COUVERTURE_DRAFT,
    payload: photoCouvertureDraft,
  };
};

const updatePaymentMethod = (paymentMethod: string) => {
  return {
    type: UPDATE_COMPANY_PAGE_INVESTOR_PAYMENT_METHOD,
    payload: paymentMethod,
  };
};
/**
 * @param id number , id company page
 * @callback function  (params:any) {
   return response company age
 }
 */
const getCPInvestorAction = (id: string, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let result = await Service.getDataService(
        url?.investor_company_page?.url_get_company_page + id,
        {},
        store?.getState()?.userReducer?.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: GET_COMPANY_PAGE_INVESTOR,
          payload: result && result.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * @param id number , id company page
 * @callback function  (params:any) {
   return response company age
 }
 */
const updateCPInvestorAction = (id: string, data: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let result = await Service.postDataFormDataService(
        data,
        url?.investor_company_page?.url_update_investor_page + id,
        store?.getState()?.userReducer?.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: UPDATE_COMPANY_PAGE_INVESTOR,
          payload: result && result.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * Update draft data
 */
const updateDraftDataAction = (
  params: any,
  idCompany: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.postDataFormDataService(
        params,
        url?.investor_company_page?.updateDraftData + idCompany,
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: UPDATE_DRAFT_DATA,
          payload: result?.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * Update draft resource
 */
const updateDrafResourceAction = (
  params: any,
  idCompany: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.postDataFormDataService(
        params,
        url?.investor_company_page?.updateDraftResourceDraft + idCompany,
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: UPDATE_DRAFT_RESSOURCE_DATA,
          payload: result?.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * Update draft resource
 */
const updateDrafExpertiseAction = (
  params: any,
  idCompany: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.postDataFormDataService(
        params,
        url?.investor_company_page?.updateDraftExpertise + idCompany,
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: UPDATE_DRAFT_EXPERTISE_DATA,
          payload: result?.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * Remove file ressource
 */
const removeFileRessourceAction = (
  idCompany: string,
  fileId: any,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.postDataFormDataService(
        {},
        url?.investor_company_page?.removeRessourceFile +
          idCompany +
          "/" +
          fileId,
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: REMOVE_RESOURCE_FILE,
          payload: {
            id: fileId,
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

//BILLING CENTER UPDATE CARD INFORMATION
/**********************************
 *
 * Update payment method: card info
 */
const updateBillingCenterCardInvestor = (payment_method: string) => {
  return {
    type: UPDATE_BILLING_CENTER_CARD_INVESTOR,
    payload: payment_method,
  };
};
/**
 * Update draft resource
 */
const updatePortfolioAction = (
  params: any,
  idCompany: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.postDataFormDataService(
        params,
        url?.investor_company_page?.update_portFolio + idCompany,
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: UPDATE_DRAFT_PORTFOLIO_DATA,
          payload: result?.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * publish and update draft data
 */
const updateAndPublishDraftAction = (idCompany: string, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.postJsonDataService(
        url?.investor_company_page?.publish_company_page_draft + idCompany,
        {},
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: UPDATE_OR_PUBLISH_DRAFT__DATA,
          payload: result?.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * publish and update draft data
 */
const getInvestorAttributes = (
  key: string,
  completed_url: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.getDataService(
        url?.investor_company_page?.get_investor_attribut + completed_url,
        {},
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: GET_INVESTOR_ATTRIBUT,
          payload: { data: result?.data, key: key },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export {
  getCPInvestorAction,
  updateCPInvestorAction,
  updatePaymentMethod,
  updateDraftDataAction,
  updateDrafResourceAction,
  updateDrafExpertiseAction,
  removeFileRessourceAction,
  updateBillingCenterCardInvestor,
  updatePortfolioAction,
  updateAndPublishDraftAction,
  getInvestorAttributes,
  removeLogoInvestorPage,
  removeCouvertureInvestorPage,
  //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
  unpublishInvestorPage,
};
