import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { getDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";

type IUnread = {
  unread: number;
};

export const BlockMessage = (props: IUnread) => {
  const { unread } = props;

  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [notifMessage, setNotifMessage] = useState<any>(unread);

  useEffect(() => {
    if (unread == -1) {
      const getUnreadCategory = async () => {
        let response = await getDataService(
          url.tchat.unread_in_category,
          {},
          userReducer.token
        );
        console.log(response);
        if (response?.status === 200) {
          const {
            COMMUNITY,
            CLASSIFIED_ADS,
            FIND_PROGRAM,
            FIND_INVESTOR,
            FIND_ISV,
          } = response && response.data;
          const totalUnread =
            (COMMUNITY || 0) +
            (CLASSIFIED_ADS || 0) +
            (FIND_PROGRAM || 0) +
            (FIND_INVESTOR || 0) +
            (FIND_ISV || 0);
          setNotifMessage(totalUnread);
        }
      };
      getUnreadCategory();
    }
  }, []);
  return (
    <a href="javascript:;" className="linkHeader">
      <span className="icon-message"></span>
      <span className="mobile">Message</span>
      {unread != -1 && unread > 0 && <span className="non-lu">{unread}</span>}
      {notifMessage > 0 && <span className="non-lu">{notifMessage}</span>}
    </a>
  );
};
