import React from "react";
import { CONTRIBUTOR, ISV } from "../../../../service/constant";
import * as Section from "./index";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import useDashboardMyFeed from "../../../../controller/dashboard-ctr/commun-dashboars/use-dashboard-my-feed";
import { Myfeed } from "../../../../interface/isv";
import InfiniteScroll from "react-infinite-scroll-component";
import Feeds from "../../../widget/feeds/feeds";
import { LoadingFeed } from "../../../widget/loader";

export default function MyFeeds(props: any) {
  const feedCtr = useDashboardMyFeed();
  const userState = useSelector((state: rootState) => state.userReducer);

  return (
    <>
      <InfiniteScroll
        dataLength={feedCtr?.myFeedList?.myfeed?.results?.length}
        next={feedCtr?.loadMoreFeed}
        hasMore={feedCtr?.hasMore}
        loader={<LoadingFeed />}
      >
        {feedCtr?.myFeedList?.myfeed?.results?.map((myfeeds, index: number) => (
          <div key={index}>
            <div className="bigBlock feed">
              {index < 1 && (
                <h3 className="title" onClick={feedCtr?.goToManageMyFeed}>
                  My feed
                  <a href="javascript:;">Manage my feed</a>
                </h3>
              )}

              <ul className="feedList">
                {myfeeds?.feeds?.map((e: Myfeed, i: number) => (
                  <Feeds
                    feed={e}
                    key={i}
                    handleLiked={(idParent: string) =>
                      feedCtr.handleLiked(idParent)
                    }
                  />
                ))}
              </ul>
            </div>
            {/* {(userState?.roles?.[0] === ISV ||
              userState?.roles?.[0] === CONTRIBUTOR) && (
              <Section.NewInvestorPrograms news={myfeeds?.news} />
            )} */}
          </div>
        ))}
      </InfiniteScroll>
    </>
  );
}
