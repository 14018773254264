import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { useEffect, useState } from "react";
import {
  getFilteredClassifiedAds,
  resetFavoriteAds,
} from "../../../service/applicatif/classified-ads";
import { IAd } from "../../../redux/types/classified-ads-interface";
import { OEM_PROGRAM } from "../../../service/constant";
import { useHistory } from "react-router-dom";

export type AdsResult = {
  results: Array<IAd>;
  total_result_to_paginate: string;
};

export default function useFavoriteCtr() {
  const classifiedAdsReducer = useSelector(
    (state: rootState) => state?.classifiedAdsReducer
  );
  const userReducer = useSelector((state: rootState) => state?.userReducer);
  const oemProgramReducer = useSelector(
    (state: rootState) => state?.oemProgramReducer.program
  );
  const [selectedIndustry, setSelectedIndustry] = useState<Array<any>>([]);
  const [selectedLocation, setSelectedLocation] = useState<Array<any>>([]);
  const [selectedSolution, setSelectedSolution] = useState<Array<any>>([]);
  const [selectedType, setSelectedType] = useState<Array<any>>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [viewMoreLoader, setViewMoreLoader] = useState<boolean>(false);
  const [ads, setAds] = useState<Array<IAd>>([]);
  const [totalAds, setTotalAds] = useState<string>("0");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const history = useHistory();
  useEffect(() => {
    (async () => {
      const data: AdsResult = await getData(1);
      setTotalAds(data?.total_result_to_paginate);
    })();
  }, []);

  const filter = async () => {
    setAds([]);
    const data: AdsResult = await getData(1);
    setCurrentPage(1);
    setTotalAds(data?.total_result_to_paginate);
  };

  const getData = async (page: number, loading: boolean = true) => {
    if (loading) {
      setLoader(true);
    }

    const data: AdsResult = await getFilteredClassifiedAds(
      {
        favorite: "1",
        page,
        type: 0,
        location: selectedLocation?.join(",").toString(),
        targetedIndustry: selectedIndustry?.join(",").toString(),
        search: "",
        companyType: selectedType?.join(",").toString(),
        softwareSolution: selectedSolution?.join(",").toString(),
        oem_program: oemProgramReducer.id,
      },
      userReducer?.token
    );

    if (page === 1) {
      setAds(data?.results);
    } else {
      setAds((current) => [...current, ...data?.results]);
    }
    setLoader(false);

    return data;
  };

  const resetFilter = async () => {
    setSelectedIndustry([]);
    setSelectedLocation([]);
    setSelectedSolution([]);
    setSelectedType([]);
    setAds([]);
    setCurrentPage(1);
    setLoader(true);
    await resetFavoriteAds(userReducer?.token, setAds, setTotalAds);
    setLoader(false);
  };

  const handleViewMore = async () => {
    setViewMoreLoader(true);
    const page = currentPage + 1;
    setCurrentPage(page);
    await getData(page, false);
    setViewMoreLoader(false);
  };

  const filterRemoveFavorite = (item: IAd) => {
    const filteredAds = ads?.filter((e: IAd) => e?.id !== item?.id);
    const newAdsTotal = parseInt(totalAds) - 1;
    setTotalAds(newAdsTotal.toString());
    setAds(filteredAds);
  };

  const goToCategoryPage = (idCategory: any) => {
    history.push("/classified-ads/category/" + idCategory);
  };

  return {
    classifiedAdsReducer,
    selectedIndustry,
    setSelectedIndustry,
    selectedLocation,
    setSelectedLocation,
    selectedSolution,
    setSelectedSolution,
    selectedType,
    setSelectedType,
    filter,
    resetFilter,
    ads,
    loader,
    handleViewMore,
    totalAds,
    viewMoreLoader,
    filterRemoveFavorite,
    goToCategoryPage,
  };
}
