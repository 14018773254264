import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { postWithoutTokenCombineUrl } from "../../../service/api";
import { url } from "../../../service/constant/index";
export default function useCompleteAccount() {
  let params: any = useLocation();
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);

  const removeAllRouteBack = () => {
    history.go(1);
  };

  useEffect(() => {
    window.addEventListener("popstate", removeAllRouteBack);
    return () => {
      window.removeEventListener("popstate", removeAllRouteBack);
    };
  }, []);

  const resendEmail = async () => {
    setShowLoader(true);
    setError(false);
    let response = await postWithoutTokenCombineUrl(
      url.acount.resendEmail,
      params?.state.email
    );
    if (response?.status == 200) {
      setShowLoader(false);
    } else {
      setShowLoader(false);
      setError(true);
    }
  };

  return {
    params: params?.state,
    showLoader,
    error,
    history,
    resendEmail,
  };
}
