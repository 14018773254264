import React from "react";

const CompanyPageCard = (props: any) => {
  return (
    <>
      {props.menuDrop}
      <div className="imgCompany">
        <img src={props.photo_profil} alt="" />
      </div>
      <div className="infoCompany">
        <div className="info">
          <h3 className="title">{props.company_name}</h3>
          <p className="country">{props.country_name}</p>
          <a href="javascript:;" className="siteweb">
            {props.website}
          </a>
        </div>
        {props.linkRequest}
      </div>
    </>
  );
};
CompanyPageCard.defaultProps = {
  linkRequest: null,
  menuDrop: null
};
export default CompanyPageCard;
