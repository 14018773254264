import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { getDataWithoutToken } from "../../../../service/api";
import { url } from "../../../../service/constant";
import { getMonth } from "../../../../utils";

export default function UsePublishEvent() {
  const now = new Date();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const [modalPublishEvent, setModalPublishEvent] = useState<boolean>(false);
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );
  const [modalErroPlan, setModalErroPlan] = useState(false);

  const [statePublishEvent, setStatePublishEvent] = useState<{
    number_left: number;
    number_max: number;
    next_grant_at: string;
  }>({
    number_left: 0,
    number_max: 0,
    next_grant_at: "",
  });

  const closePublishModal = () => {
    setStatePublishEvent({
      number_left: 0,
      number_max: 0,
      next_grant_at: "",
    });
    setModalPublishEvent(false);
    setChoiseValue(undefined);
  };

  const showmodalPublishEvent = () => {
    const isFree = oemProgram?.oem_subscription?.id === 1;
    if (isFree) {
      setModalErroPlan(true);
    } else {
      setModalPublishEvent(true);
    }
  };

  const closeModalErroPlan = () => {
    modalErroPlan && setModalErroPlan(false);
  };

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + "/information/plan_details",
        { month: currentMonth, oem: oemProgram.id },
        userReducer.token
      );
      if (response?.status === 200) {
        const { oem_program_event }: any = response.data.free_status;
        setStatePublishEvent(oem_program_event);
        setChargingPlanDetails(false);
        setChoiseValue(undefined);
      }
    }
    if (modalPublishEvent) {
      getPlanDetails();
    }
  }, [currentMonth, modalPublishEvent]);

  return {
    modalPublishEvent,
    closePublishModal,
    setModalPublishEvent,
    chargingPlanDetails,
    statePublishEvent,
    handleChoise,
    choiseValue,
    showmodalPublishEvent,
    modalErroPlan,
    closeModalErroPlan,
  };
}
