import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  InputPropsEditInfo,
  Tcountry,
} from "../../../../../controller/company-ctr/types/types-company-page";
import { annee } from "../../../../../utils/index";
import { isURL, isRequired } from "../../../../../utils/index";

const validCompanyWebSite = (txt: string): boolean => {
  if (isRequired(txt)) {
    if (isURL(txt)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export default function EditBasicInfo(props: InputPropsEditInfo) {
  return (
    <div className="bodyCreateCompany">
      <div className="contentBorder row">
        <div className="form-group required col-12">
          <div className="aideContent">
            <label htmlFor="">Company "Trade" Name</label>
            <OverlayTrigger
              overlay={
                <Tooltip id="trade">
                  Please enter your company’s trade name, or the name commonly
                  used for public reference (commonly exludes “Inc.”, “LLC”,
                  etc…)
                </Tooltip>
              }
            >
              <span className="icon-aide"></span>
            </OverlayTrigger>
            {props.errors?.companyTradeName && (
              <div className="container-error pl-2 d-inline">
                {/* change d-inline on d-none */}
                <span className="error-red"> error </span>
                <span className="txt-error">this field is required</span>
              </div>
            )}
          </div>
          <input
            type="text"
            className="form-control"
            {...props.register("companyTradeName", {
              required: props.required,
            })}
          />
        </div>
        <div className="form-group required col-12">
          <div className="aideContent">
            <label htmlFor="">Company "Legal" Name</label>
            <OverlayTrigger
              overlay={
                <Tooltip id="trade">
                  Please enter your company’s legal name (commonly ending in
                  “Inc”, “LLC”, etc…) This is the name that is registered with
                  your state of incorporation/registration
                </Tooltip>
              }
            >
              <span className="icon-aide"></span>
            </OverlayTrigger>
            {props.errors?.companyLegalName && (
              <div className="container-error pl-2 d-inline">
                {/* change d-inline on d-none */}
                <span className="error-red"> error </span>
                <span className="txt-error">this field is required</span>
              </div>
            )}
          </div>
          <input
            type="text"
            className="form-control"
            {...props.register("companyLegalName", {
              required: props.required,
            })}
          />
        </div>
        <div className="form-group p-0 required col-12">
          <label htmlFor="website">Website</label>
          {props.errors?.webSite && (
            <div className="container-error pl-2 d-inline">
              {/* change d-inline on d-none */}
              <span className="error-red"> error </span>
              <span className="txt-error">this field is required</span>
            </div>
          )}
          {props?.errors?.webSite?.type == "validate" && (
            <div className="container-error pl-2 d-inline">
              {/* change d-inline on d-none */}
              <span className="error-red"> error </span>
              <span className="txt-error">your website url is not a valid</span>
            </div>
          )}
          <input
            type="text"
            className="form-control"
            {...props.register("webSite", {
              validate: (txt: string) => validCompanyWebSite(txt),
              required: props.required,
            })}
          />
        </div>
        <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
          <label htmlFor="location">Headquarters location</label>
          {props.errors?.hQLocation && (
            <div className="container-error pl-2 d-inline">
              {/* change d-inline on d-none */}
              <span className="error-red"> error </span>
              <span className="txt-error">this field is required</span>
            </div>
          )}
          <select
            className="custom-select"
            {...props.register("hQLocation", {
              required: props.required,
            })}
          >
            <option value="">Country ...</option>
            {props.dataCity &&
              props.dataCity.map((el: Tcountry, index: number) => (
                <option value={el.id} key={index}>
                  {el.country_name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
          <label htmlFor="location">&nbsp;</label>
          <input
            type="text"
            className="form-control"
            {...props.register("city")}
          />
        </div>
        <div className="form-group col-12 required">
          <label htmlFor="size">Company size</label>
          {props.errors?.companySize && (
            <div className="container-error pl-2 d-inline">
              {/* change d-inline on d-none */}
              <span className="error-red"> error </span>
              <span className="txt-error">this field is required</span>
            </div>
          )}
          <select
            className="custom-select"
            {...props.register("companySize", {
              required: props.required,
            })}
          >
            <option value="">Select an option</option>
            {props.companySizeData &&
              props.companySizeData.map((el: any, index: number) => {
                return (
                  <option value={JSON.stringify(el)} key={index}>
                    {Object.keys(el)}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="form-group col-12 required">
          <label htmlFor="size">Year founded</label>
          {props.errors?.foundingDate && (
            <div className="container-error pl-2 d-inline">
              {/* change d-inline on d-none */}
              <span className="error-red"> error </span>
              <span className="txt-error">this field is required</span>
            </div>
          )}
          <select
            className="custom-select"
            {...props.register("foundingDate", {
              required: props.required,
            })}
          >
            <option value="">Select a date</option>
            {annee &&
              annee().map((el: any, index: number) => (
                <option value={el?.value} key={index}>
                  {el?.label}
                </option>
              ))}
          </select>
        </div>
      </div>
    </div>
  );
}
