import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
export default function UseCompleteContributorAccount() {
  let params: any = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("popstate", () => {
      history.go(1);
    });
  }, []);

  return {
    params: params?.state,
  };
}
