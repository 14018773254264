import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ChoosePublish from './choose-publish';
import FundingRound from './funding-round';
import CompanySizePopup from '../../../../../widget/slider-company-size-slider/index';
import AnnualTurnover from '../../../../../widget/slider-annual-turnover';
import FundRaisingGoal from '../../../../../widget/slider-fundraising/index';
import RegionContry from '../../../../../widget/pays/index';
import SofTwareSolution from '../../../../../widget/software-solution';
import { IPropsSettingCP } from '../../types';
import Form from 'react-bootstrap/Form';
import { IListTarget } from '../../../../../widget/industries-list/use-IL';
export default function SettingPageInvestor(props: IPropsSettingCP) {
  let allSolutions = [
    ...props?.solutionChecked,
    ...props?.solutionCheckedFeatures,
  ];

  if (props?.filterSolutionChecked)
    allSolutions = props?.filterSolutionChecked(allSolutions);

  const [activeId, setActiveId] = useState('');
  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId('');
    } else {
      setActiveId(id);
    }
  }
  return (
    <div>
      <Accordion className="accordionForSetting settingPageAccordion">
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="1"
            className={`btnAccordion ${activeId === "1" ? "active" : ""}`}
            onClick={() => toggleActive("1")}
          >
            Choose how you want to publish your Investor page:
            <div className="explication">
              {props?.wantToShow
                ? "I want ISVs to find me and receive inbound inqueries."
                : "I want to remain invisible and don't want ISVs to find me."}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <div className="panel-body py-4">
              <ChoosePublish
                changeSelect={(p: boolean) => {
                  props?.changeSelect(p);
                }}
                wantToShow={props?.wantToShow}
              />
            </div>
          </Accordion.Collapse>
        </div>
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="2"
            className={`btnAccordion ${activeId === "2" ? "active" : ""}`}
            onClick={() => toggleActive("2")}
          >
            Company size:
            <div className="explication">
              {props?.companySizeSlide?.[0]} to {props?.companySizeSlide?.[1]}{" "}
              employees
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <div className="col-xl-6 col-lg-8 col-md-12 col-12  p-0 pb-5 m-0">
              <p className="description_settings">
                Define the ISV characteristic you are interested in?
              </p>
              <CompanySizePopup
                companySize={props?.companySize}
                companySizeSlide={props?.companySizeSlide}
                setCompanySize={(evt: Array<number>) => {
                  props?.setCompanySize(evt);
                }}
                customSetCompanySize={(min: number, max: number) => {
                  props?.customSetCompanySize(min, max);
                }}
              />
            </div>
          </Accordion.Collapse>
        </div>

        {/* ANNUAL TURNOVER */}
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="8"
            className={`btnAccordion ${activeId === "8" ? "active" : ""}`}
            onClick={() => toggleActive("8")}
          >
            Annual revenue:
            <div className="explication">
              {"$ " + props?.annualTurnoverSlide?.[0]}
              {props?.unitTurnover} to {"$ " + props?.annualTurnoverSlide?.[1]}
              {props?.unitTurnoverMax}{" "}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="8">
            <div className="col-xl-6 col-lg-8 col-md-12 col-12  p-0 pb-5 m-0">
              <p className="description_settings">
                Define the ISV characteristic you are interested in?
              </p>
              <AnnualTurnover
                annualTurnoverSlide={props?.annualTurnoverSlide}
                annualTurnover={props?.annualTurnover}
                unitTurnover={props?.unitTurnover}
                unitTurnoverMax={props?.unitTurnoverMax}
                setAnnualTurnover={(evt: Array<number>) => {
                  props?.setAnnualTurnover(evt);
                }}
                customSetAnnualSize={(min: number, max: number) =>
                  props?.customSetAnnualSize(min, max)
                }
              />
            </div>
          </Accordion.Collapse>
        </div>
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="3"
            className={`btnAccordion ${activeId === "3" ? "active" : ""}`}
            onClick={() => toggleActive("3")}
          >
            Funding round:
            {props?.foundingRound?.map((el: any, index: number) => (
              <div className="explication" key={index}>
                {el?.label}
                {index != props?.foundingRound?.length - 1 && " & "}
              </div>
            ))}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <div className="panel-body">
              <p className="description_settings">
                Define the ISV characteristic you are interested in?
              </p>
              <FundingRound {...props} />
            </div>
          </Accordion.Collapse>
        </div>
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="4"
            className={`btnAccordion ${activeId === "4" ? "active" : ""}`}
            onClick={() => toggleActive("4")}
          >
            Fundraising goal:
            <div className="explication">
              {"$ " + props?.fundraisingGoalSlide?.[0]}
              {props?.funMin < 1000000 ? "K USD" : "M USD"} to{" "}
              {"$ " + props?.fundraisingGoalSlide?.[1]}
              {props?.funMax < 1000000 ? "K USD" : "M USD"}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <div className="panel-body">
              <p className="description_settings">
                Define the ISV characteristic you are interested in?
              </p>
              <FundRaisingGoal {...props} />
            </div>
          </Accordion.Collapse>
        </div>
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="5"
            className={`btnAccordion ${activeId === "5" ? "active" : ""}`}
            onClick={() => toggleActive("5")}
          >
            Region(s) / Country(ies):
            <div className="explication">
              {props?.paysChecked?.length} countries selected
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <div className="panel-body">
              <p className="description_settings">
                Define the ISV characteristic you are interested in?
              </p>
              <RegionContry {...props} />
            </div>
          </Accordion.Collapse>
        </div>
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="6"
            className={`btnAccordion ${activeId === "6" ? "active" : ""}`}
            onClick={() => toggleActive("6")}
          >
            What ISV solution category(ies) are you interested in ? :
            <div className="explication">
              {allSolutions.length + " "}
              solution{allSolutions.length > 1 ? "s" : ""} selected
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <SofTwareSolution
              solutionChecked={props?.solutionChecked}
              addSolutionCheched={(p: { id: number; name: string }) =>
                props?.addSolutionCheched(p)
              }
              addSolutionChechedFeatured={(p: { id: number; name: string }) =>
                props?.addSolutionChechedFeatured(p)
              }
              featuredSolution={props?.featuredSolution}
              solutionCheckedFeatures={props?.solutionCheckedFeatures}
              solutionLeftColumnData={props?.solutionLeftColumnData}
              solutionRigthColumnData={props?.solutionRigthColumnData}
              filterSolutionChecked={props?.filterSolutionChecked}
              removeItemSelected={props?.removeItemSelected}
            />
          </Accordion.Collapse>
        </div>
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="7"
            className={`btnAccordion ${activeId === "7" ? "active" : ""}`}
            onClick={() => toggleActive("7")}
          >
            {/* Dev #47949 V2.1 [Investor] Modifier une phrase dans une rubrique de "Page Settings" */}
            Define which industries the ISV should target:
            <div className="explication">
              {props?.companyTargetSelected?.length} industries selected
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <div className="getstartedContainer largeContainer">
              <div className="contentTargetIndustry">
                <div className="col4">
                  {props?.companyTarget?.map(
                    (element: IListTarget, index: number) => (
                      <div
                        onClick={() => {
                          props?.addTargetCompany(element);
                        }}
                        key={index}
                      >
                        <Form.Check
                          custom
                          checked={
                            props?.companyTargetSelected?.find(
                              (ele: IListTarget) => ele.id == element.id
                            )
                              ? true
                              : false
                          }
                          label={element.name}
                          id={element.id.toString()}
                        />
                      </div>
                    )
                  )}
                </div>
                <div className="col4">
                  {props?.companyTargetSecound?.map(
                    (element: IListTarget, index: number) => (
                      <div
                        onClick={() => {
                          props?.addTargetCompany(element);
                        }}
                        key={index}
                      >
                        <Form.Check
                          custom
                          checked={
                            props?.companyTargetSelected?.find(
                              (ele: IListTarget) => ele.id == element.id
                            )
                              ? true
                              : false
                          }
                          label={element.name}
                          id={element.id.toString()}
                          onChange={() => null}
                        />
                      </div>
                    )
                  )}
                </div>
                <div className="col4">
                  {props?.lastCompanyTarget?.map(
                    (element: IListTarget, index: number) => (
                      <div
                        onClick={() => {
                          props?.addTargetCompany(element);
                        }}
                        key={index}
                      >
                        <Form.Check
                          custom
                          onChange={() => null}
                          checked={
                            props?.companyTargetSelected?.find(
                              (ele: IListTarget) => ele.id == element.id
                            )
                              ? true
                              : false
                          }
                          label={element.name}
                          id={element.id.toString()}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
}
