/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import * as T from "../../../../../../../controller/company-ctr/types/types-company-page";
import { fileUrl, getDomainWebSite } from "../../../../../../../utils";

export default function BodyRequestAdmin(props: T.TFormAddEmailRequestAdmin) {
  return (
    <div className="bodyCreateCompany">
      Your request for admin access will be sent to the current company
      <br />
      page admins for review :
      <ul className="listCompanyPagePopup">
        <li>
          <div className="imgctt">
            <img src={fileUrl(props.logoCompany)} alt="" />{" "}
          </div>
          <div className="info">
            <b>{props?.companyName}</b>
            <p>{props?.pays}</p>
            <a href="javascript:;">{props?.webSite}</a>
          </div>
        </li>
      </ul>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="acceptit"
          checked={props?.acceptTerm}
          onChange={() =>
            props?.toogleAcceptTerm &&
            props.toogleAcceptTerm(!props?.acceptTerm)
          }
        />
        <label htmlFor="acceptit" className="custom-control-label mini-font">
          I verify that I am authorized representative of {props?.companyName}{" "}
          and have the right to act on its behalf in the management of this page
        </label>
      </div>
    </div>
  );
}
