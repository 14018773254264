import './FindInvestor.scss';
import Header from '../../../widget/index';
import Select from 'react-select';
import FindInvestorBlocFilter from './bloc-filter-find-investor/bloc-filter-find-investor';
//@ts-ignore
import ReactCountryFlag from 'react-country-flag';
import UseFindInvestor from '../../../../controller/investor-ctr/find-investor/use-find-investor';
import Pagination from '../../../widget/use-pagination/pagination';
import { LoaderCommon } from '../../../widget/loader';
import { fileUrl } from '../../../../utils';
import moment from 'moment';
import { IInvestor } from '../../../../redux/types/find-investor-interface';
import ModalPayementUnlock from '../../../widget/payement-isv/unlock-investor/index';
import { Redirect, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
/* TICKETS PROD: REMOVE FOR V2*/
import photoAddCompany from '../../../../resources/images/addCompany.png';
import MatchingInvestor from '../../../../hooks/MatchingInvestor';

export default function FindInvestor() {
  const history = useHistory();

  /* TICKETS PROD: REMOVE FOR V2*/
  const [showModal, setShowModal] = useState(true);
  /* TICKETS PROD: REMOVE FOR V2*/

  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains('open')) {
      elementH3.classList.remove('open');
    } else {
      elementH3.classList.add('open');
    }
  }

  const goToInvestorPage = (id: any) => {
    //Dev #48044, V2.1 [ISV] Preview Mode of an investor page
    history.push('/inv-page/' + id + '?preview=1');
  };

  const renderPercent = (percentage: number, is_unlocked: boolean) => {
    if (percentage < 70) {
      return (
        <div className={`matchValue m60 ${!is_unlocked ? ' locked' : ''}`}>
          {percentage}% Match
        </div>
      );
    } else if (percentage >= 70 && percentage < 80) {
      return (
        <div className={`matchValue m80 ${!is_unlocked ? ' locked' : ''}`}>
          {percentage}% Match
        </div>
      );
    } else if (percentage >= 80 && percentage < 90) {
      return (
        <div className={`matchValue m80 ${!is_unlocked ? ' locked' : ''}`}>
          {percentage}% Match
        </div>
      );
    } else if (percentage >= 90) {
      return (
        <div className={`matchValue m80 ${!is_unlocked ? ' locked' : ''}`}>
          {percentage}% Match
        </div>
      );
    } else {
      return (
        <div className={`matchValue m80 ${!is_unlocked ? ' locked' : ''}`}>
          {percentage}% Match
        </div>
      );
    }
  };

  const renderButton = (el: IInvestor) => {
    if (el?.is_unlocked) {
      return (
        <div className='containerAction'>
          <div className='lockedPage'>
            Unlocked at {moment(el?.unlocked_at).format('MMM Do, yyyy')}
          </div>
          <div className='btn-group' role='group'>
            <button
              className='cancel btn btn-devinsider'
              onClick={() => goToInvestorPage(el?.investor?.id)}
            >
              View
            </button>
            <button
              className='cancel btn btn-devinsider'
              onClick={() => {
                providerFindInvestor?.contactIvestor(el?.investor?.id);
              }}
            >
              Contact
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className='containerAction'>
          <div className='lockedPage'>
            <span className='icon-locked'></span>Page locked
          </div>
          <div className='btn-group' role='group'>
            {el?.matching_value < 70 ? (
              <>
                <button
                  className='cancel btn btn-devinsider'
                  onClick={() => goToInvestorPage(el?.investor?.id)}
                >
                  Preview
                </button>
                <button className='cancel btn btn-devinsider disabled' disabled>
                  Unlock
                </button>
              </>
            ) : (
              <>
                <button
                  className='cancel btn btn-devinsider'
                  onClick={() => goToInvestorPage(el?.investor?.id)}
                >
                  Preview
                </button>
                <button
                  className='cancel btn btn-devinsider'
                  onClick={() => {
                    providerFindInvestor?.addToUnlocked(el?.investor?.id);
                  }}
                >
                  Unlock
                </button>
              </>
            )}
          </div>
        </div>
      );
    }
  };

  const isIndividual = (el: IInvestor): boolean => {
    return !el?.investor?.institutional_investor_type
      ? true
      : el?.investor?.institutional_investor_type?.name === 'Business Angel'
      ? true
      : false;
  };

  const providerFindInvestor = UseFindInvestor();

  const renderFindMatchmakingPanel = () => {
    return (
      <>
        <Header />
        <FindInvestorBlocFilter {...providerFindInvestor} />
        <ModalPayementUnlock
          {...providerFindInvestor.providerModalUseUnlockInvestor}
          unlockedInvestor={() => providerFindInvestor.unlockedInvestor()}
          loading={providerFindInvestor.loading}
        />
        <div className='container mx-auto minHeightwin'>
          <div className='listOfIsv'>
            <div className='headerOfList'>
              <div className='leftHead'>
                <div className='number'>
                  Showing{' '}
                  <b>{providerFindInvestor?.investors?.total_to_paginate}</b>{' '}
                  Investors
                </div>
                <form
                  action=''
                  onClick={(e) => openBlock(e)}
                  onSubmit={providerFindInvestor?.handleSubmit(
                    providerFindInvestor?.onSubmitSearch
                  )}
                >
                  <input
                    placeholder='Search...'
                    type='text'
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    {...providerFindInvestor?.register('search')}
                  />
                </form>
              </div>
              <div className='rightHead'>
                <div className='sortedby labelSelect'>
                  <label>Sorted by :</label>
                  <Select
                    className='triOreder'
                    isClearable={false}
                    options={providerFindInvestor?.filtered}
                    classNamePrefix='tri'
                    defaultValue={providerFindInvestor?.filtered[0]}
                    value={
                      providerFindInvestor?.filtered?.filter(
                        (e) => e?.value === providerFindInvestor?.sortBy
                      )[0]
                    }
                    onChange={(filter: any) =>
                      providerFindInvestor?.handleFilter(filter)
                    }
                  />
                </div>
              </div>
            </div>
            <div className='scrollContainer'>
              {providerFindInvestor?.loader ? (
                <LoaderCommon />
              ) : (
                <ul className='listOfIsvMatch'>
                  {providerFindInvestor?.investors?.results?.map((el, key) => (
                    <li key={key}>
                      <div className='head'>
                        <div
                          className={`isvLogo ${
                            isIndividual(el) ? 'b-radius' : ''
                          } ${el?.is_unlocked ? '' : 'locked'}`}
                        >
                          <img
                            src={fileUrl(el?.investor?.photo_profil)}
                            alt=''
                          />
                        </div>
                        <div className='isvInfo'>
                          <h3 className='title'>
                            <span className='name'>
                              {el?.investor?.organization_name}
                            </span>
                            <span
                              className={
                                el?.is_favorite
                                  ? 'icon-star-on'
                                  : 'icon-star-off'
                              }
                              onClick={() =>
                                providerFindInvestor?.addToFavorites(
                                  el?.investor?.id
                                )
                              }
                            ></span>
                          </h3>
                          {renderPercent(el?.matching_value, el.is_unlocked)}
                        </div>
                      </div>
                      <div className='foot'>
                        <div>
                          <p className='title'>INVESTOR TYPE</p>
                          <span className='semi-bold'>
                            {el?.investor?.institutional_investor_type
                              ? el?.investor?.institutional_investor_type?.name
                              : el?.investor?.investor_type?.name}
                          </span>
                        </div>
                        <div>
                          <p className='title'>Headquarters location</p>
                          <span className='semi-bold'>
                            <ReactCountryFlag
                              countryCode={el?.investor?.country?.cc_iso}
                              svg
                              title={el?.investor?.country?.code}
                            />{' '}
                            {el?.investor?.country?.code}
                          </span>
                        </div>
                      </div>
                      {renderButton(el)}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className='pagginationCategory fixePagination'>
              <div className='pagination'>
                <Pagination
                  className='pagination'
                  currentPage={providerFindInvestor?.page || 1}
                  totalCount={
                    providerFindInvestor?.investors?.total_to_paginate
                  }
                  pageSize={providerFindInvestor?.limit}
                  onPageChange={(page: any) =>
                    providerFindInvestor?.loadMoreProgram(page)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (!providerFindInvestor.isIsv) {
    return <Redirect to={'/profil-not-found'} />;
  }

  return (
    <MatchingInvestor>
      <div className='findIsvcontainer findInvestor'>
        {/* BEGIN TO UNCOMMENT FOR V2*/}
        {/* {renderFindMatchmakingPanel()} */}
        {/* END TO UNCOMMENT FOR V2*/}

        {/* TICKETS PROD: REMOVE FOR V2*/}
        <Header />
        <div className='NotFoundContainer'>
          <div className='container mx-auto'>
            <div className='contentBorder'>
              <h3 className='title'>4 0 4</h3>
              <div className='image'>
                <img src={photoAddCompany} alt='' />
              </div>
              <div className='text-center sTitle'>
                <p>Oops! page not found</p>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} className='small forDesactivate popPers'>
          <Modal.Header>
            <div className='headerpermission noStep'>
              <h2 className='title'>Find Investors</h2>
              <a
                href='javascript:;'
                className='close'
                onClick={() => setShowModal(false)}
              >
                <span className='icon-close-pop'></span>
              </a>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='contentBorder row m-0 p-0 desactivepopup'>
              <p>
                We are currently on-boarding Investors for matchmaking and will
                release this feature soon. In the meantime check out <br />
                the{' '}
                <a
                  href='javascript:;'
                  onClick={() => history.push('/community/home')}
                >
                  community
                </a>{' '}
                where you can engage with Investors and share your expertise.
              </p>
            </div>
          </Modal.Body>
        </Modal>
        {/* TICKETS PROD: REMOVE FOR V2*/}
      </div>
    </MatchingInvestor>
  );
}
