import React, { ChangeEvent } from "react";
import { IPropsSettingsPublish } from "../../types";

export default function ChoosePublish(props: IPropsSettingsPublish) {
  return (
    <div className="row">
      <div className="custom-control custom-radio col-12 lastStepInvestorRadio">
        <input
          type="radio"
          className="custom-control-input"
          name="choice"
          id="choice1"
          checked={props?.wantToShow}
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            props?.changeSelect(true)
          }
        />
        <label className="custom-control-label" htmlFor="choice1">
          <b>I want ISVs to find me and receive inbound inqueries.</b>
          <br />
          Your investor page will be listed in the matchmaking and can be viewed by ISV members. 
          Only relevant ISVs that closely match with your defined criteria are able to contact you.
        </label>
      </div>
      <div className="custom-control custom-radio col-12 lastStepInvestorRadio">
        <input
          type="radio"
          className="custom-control-input"
          name="choice"
          id="choice2"
          checked={!props?.wantToShow}
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            props?.changeSelect(false)
          }
        />
        <label className="custom-control-label" htmlFor="choice2">
          <b>I want to remain invisible and don't want ISVs to find me.</b>
          <br />
          Your investor page will not be visible to ISV members.
        </label>
      </div>
    </div>
  );
}
