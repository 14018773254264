/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import "./template-website.scss";
import React, { ChangeEvent, useMemo } from "react";
import { Editor } from "react-draft-wysiwyg";
import AddImage from "../../../resources/images/photo-add.jpg";
import { IPropsTemplate } from "./type";
import { fileUrl } from "../../../utils";

export default function TemplateWebsite(props: IPropsTemplate) {
  const customFileToShow = () =>
    props?.dataFile != ""
      ? URL.createObjectURL(props?.dataFile)
      : props?.fileUrl
      ? fileUrl(props?.fileUrl)
      : AddImage;

  return (
    <article className="investorArticle bgGris">
      <div className="mx-auto container cont3">
        {props?.isHaveError &&
          props?.editorState?.getCurrentContent()?.getPlainText("\u0001") ==
            "" && (
            <div className="container-error pl-2 d-inline">
              <span className="error-red"> error </span>
              <span className="txt-error">
                You have some incomplete field, please verify and submit again.
              </span>
            </div>
          )}
        <div className="contentLeftInvestor">
          <div className="editShow">
            <div className="elementText">
              <div className="imageCtt">
                <label htmlFor="">
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/*"
                    style={{
                      opacity: 0,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      props.setFileExpertise(event);
                    }}
                  />
                </label>

                <a className="removeImg" onClick={() => props?.removeFile()}>
                  <span className="icon-close-pop" />
                </a>
                <img src={customFileToShow()} alt="" className="noBg2" />
              </div>
              <Editor
                editorState={props?.editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                stripPastedStyles={true}
                onEditorStateChange={(editorState: any) => {
                  props.setEditorState(editorState);
                }}
                toolbar={{
                  image: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: undefined,
                    previewImage: false,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: "auto",
                      width: "auto",
                    },
                  },
                }}
              />

              <div className="disposition">
                <p>Choose disposition: </p>
                <a
                  className={`${
                    props?.disposition?.toString() === "upTextPhoto"
                      ? "active"
                      : ""
                  } disposition upTextPhoto`}
                  onClick={() => props?.choseDisposition("upTextPhoto")}
                />
                <a
                  className={`${
                    props?.disposition?.toString() === "upPhotoText"
                      ? "active"
                      : ""
                  } disposition upPhotoText `}
                  onClick={() => props?.choseDisposition("upPhotoText")}
                />
                <a
                  className={`${
                    props?.disposition?.toString() === "photoText"
                      ? "active"
                      : ""
                  } disposition photoText `}
                  onClick={() => props?.choseDisposition("photoText")}
                />
                <a
                  className={`${
                    props?.disposition?.toString() === "textPhoto"
                      ? "active"
                      : ""
                  } disposition textPhoto `}
                  onClick={() => props?.choseDisposition("textPhoto")}
                />
              </div>
              <a
                className="removeElement"
                onClick={() => props?.removeElement()}
              >
                Remove this element
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
