import React, { useState, FunctionComponent, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import Accordion from 'react-bootstrap/Accordion';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import { ReactComponent as LoaderMail } from '../../../resources/images/Rolling.svg';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  length
} from '../../../utils';

type loginSecurityAccount = {
  setPassword: (evt: string) => void;
  setNewPassWord: (evt: string) => void;
  setNewConfirmPassword: (evt: string) => void;
  updatePasswordInfo: () => void;
  removeTxtAfterUpdate: () => void;
  setPrimaryMail: (evt: string) => void;
  setWorkedEmail: (evt: string) => void;
  setEditEmail: (evt: string) => void;
  sentEmailToVerifieDigitCode: () => void;
  setPassWordToVerifie: (evt: string) => void;
  setDigitCode: (evt: string) => void;
  updateEmail: () => void;
  setShowErrorOldPassword: (value: boolean) => void;
  isLoaderShow: boolean;
  primaryMail: string;
  workedMail: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
  editEmail: string;
  digitCode: string;
  isEmailUpdated: string;
  emailNotSafe: string;
  emailTxtError: string;
  isShowLoaderUpdateEmail: boolean;
  emailSent: boolean;
  passWordToVerifie: string;
  isPassWordUpdated: string;
  errorEmail: boolean;
  showErrorOldPassword: boolean;
  setIsLoaderShow: (value: boolean) => void;
  showIdenticalPassWordsError: boolean;
  setShowIdenticalPassWordsError: (value: boolean) => void;
};
export const Loginandsecurity: FunctionComponent<loginSecurityAccount> = ({
  password,
  newPassword,
  confirmNewPassword,
  isLoaderShow,
  primaryMail,
  workedMail,
  editEmail,
  emailSent,
  passWordToVerifie,
  digitCode,
  isEmailUpdated,
  emailNotSafe,
  emailTxtError,
  isPassWordUpdated,
  errorEmail,
  isShowLoaderUpdateEmail,
  setPassWordToVerifie,
  setDigitCode,
  updateEmail,
  sentEmailToVerifieDigitCode,
  setPassword,
  setNewPassWord,
  setNewConfirmPassword,
  updatePasswordInfo,
  removeTxtAfterUpdate,
  setPrimaryMail,
  setWorkedEmail,
  setEditEmail,
  showErrorOldPassword,
  setShowErrorOldPassword,
  setIsLoaderShow,
  showIdenticalPassWordsError,
  setShowIdenticalPassWordsError
}) => {
  let userObject = useSelector((state: rootState) => state.userReducer);
  const [key, setkey] = useState({
    activeId: "0",
    eventId: "0",
  });
  const [isPasswordOk, setIsPasswordOk] = useState(true);
  const [showConfirmPasswordError, setShowConfirmPasswordError] =
    useState(false);
  const [disablePasswordButtonChange, setDisablePasswordButtonChange] =
    useState(true);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [textError, setTextError] = useState("");

  const passwordPattern =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{12,}$/;

  const editPrimaryEmail = (
    <Popover id="popover-basic" className="popoveremail">
      <Popover.Content>
        {isEmailUpdated === "ok" && (
          <div className="textexplic">Email updated</div>
        )}

        {isEmailUpdated === "error" && (
          <div className="textexplic">
            An error occurred ,please verify your password or digit code
            information
          </div>
        )}

        {emailNotSafe === "show-txt" && (
          <div className="textexplic">{emailTxtError}</div>
        )}

        {errorEmail && <div className="textexplic">{emailTxtError}</div>}

        {emailNotSafe === "show-form" && (
          <>
            <div className="textexplic">
              Enter your password to validate the modifications to your
              {editEmail === "primary" ? " primary email" : " work email"}
            </div>
            <div className="formPassword">
              <input
                type="password"
                placeholder="Password"
                value={passWordToVerifie}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  setPassWordToVerifie(evt.target.value)
                }
                autoComplete="off"
              />
              <input
                type="text"
                style={{ marginTop: 15 }}
                placeholder="Enter the digit you received in your email"
                readOnly={emailSent}
                value={digitCode}
                maxLength={4}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  setDigitCode(evt.target.value);
                }}
              />
              {/* Await when mail send after to show this bouton */}
              {!isPassWordUpdated && <button onClick={updateEmail}>OK</button>}
            </div>
          </>
        )}
      </Popover.Content>
    </Popover>
  );

  const checkPasswordPattern = () => {
    if (newPassword !== "") {
      if(!length(12, 100, newPassword)) {
        setIsPasswordOk(false);
        setTextError("password length must be 12 or more characters");
      }
      if (length(12, 100, newPassword) && !newPassword.match(passwordPattern)) {
        setIsPasswordOk(false);
        setTextError("password must contain at least one uppercase, lowercase, special character and one number");
        setDisablePasswordButtonChange(true);
      }
    }
  };

  const handlePassword = (pass: string) => {
    setPassword(pass);
    setShowErrorOldPassword(false);
  };

  const handleNewPasswordChange = (password: string) => {
    setNewPassWord(password);
    if (password) {
      if (confirmNewPassword === password) setShowConfirmPasswordError(false);
      setIsPasswordOk(true);
    }
    setDisablePasswordButtonChange(!password.match(passwordPattern));
  };

  const handleConfirmNewPasswordChange = (confirmPassword: string) => {
    setNewConfirmPassword(confirmPassword);
  };

  const handleUpdatePassword = () => {
    setShowIdenticalPassWordsError(false);
    setShowConfirmPasswordError(false);
    setShowErrorOldPassword(false);
    setIsPasswordOk(true);
    setIsLoaderShow(true);
    if (newPassword !== confirmNewPassword) {
      setIsLoaderShow(false);
      setShowConfirmPasswordError(true);
    }
    if (newPassword === confirmNewPassword && isPasswordOk)
      updatePasswordInfo();
  };

  return (
    <div className="contentRightsettings">
      <h1 className="title">Login and Security settings</h1>
      <Accordion className="accordionForSetting" activeKey={key.eventId}>
        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="1"
            onClick={() => {
              if (key.activeId === "1" && key.activeId === "1") {
                setkey({ eventId: "0", activeId: "0" });
              } else {
                setkey({ activeId: "1", eventId: "1" });
              }
              // toggleActive(1);
            }}
            className={`btnAccordion ${key.activeId === "1" ? "active" : ""}`}
          >
            Email addresses
            <div className="explication">
              Change, add or remove your email addresses
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <div className="panel-body">
              <ul className="listSettings pl-3">
                <li>
                  <h2 className="title emailEdit">
                    <span className="emailName">
                      {" "}
                      {userObject.roles.indexOf("ROLE_ISV") !== -1
                        ? "Primary email"
                        : "Email"}{" "}
                    </span>
                    <div
                      className={`${
                        editEmail === "primary"
                          ? "zoneEdit editform"
                          : "zoneEdit"
                      }`}
                    >
                      <div className="emailText">{primaryMail}</div>
                      <div className="formForEmail">
                        <input
                          type="text"
                          value={primaryMail}
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            setPrimaryMail(evt.target.value)
                          }
                        />
                        <div className="btnctt d-flex">
                          <button
                            className="cancel"
                            onClick={() => {
                              setkey({ eventId: "0", activeId: "0" });
                              setEditEmail("");
                            }}
                          >
                            Cancel
                          </button>
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="right"
                            overlay={editPrimaryEmail}
                            onEnter={() => {
                              sentEmailToVerifieDigitCode();
                            }}
                          >
                            <button
                              className="saveEmail"
                              disabled={isShowLoaderUpdateEmail}
                            >
                              {isShowLoaderUpdateEmail ? (
                                <Loader />
                              ) : (
                                "Send verification Email"
                              )}
                            </button>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                    {editEmail !== "primary" && (
                      <Link to="#" onClick={() => setEditEmail("primary")}>
                        Edit
                      </Link>
                    )}
                  </h2>
                </li>
                {/* ROLE HAVING 2 EMAILS => ISV, */}
                {userObject.roles.indexOf("ROLE_ISV") !== -1 ? (
                  <li>
                    <h2 className="title emailEdit">
                      <span className="emailName">Work email</span>
                      <div
                        className={`${
                          editEmail === "work"
                            ? "zoneEdit editform"
                            : "zoneEdit"
                        }`}
                      >
                        <div className="emailText">
                          {userObject?.primary_email}
                        </div>
                        <div className="formForEmail">
                          <input
                            type="text"
                            defaultValue={
                              userObject?.primary_email === null
                                ? ""
                                : userObject?.primary_email
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              setWorkedEmail(evt.target.value)
                            }
                          />
                          <div className="btnctt">
                            <button
                              className="cancel"
                              onClick={() => {
                                setkey({ eventId: "0", activeId: "0" });
                                setEditEmail("");
                              }}
                            >
                              Cancel
                            </button>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={editPrimaryEmail}
                              onEnter={() => {
                                sentEmailToVerifieDigitCode();
                              }}
                            >
                              <button
                                className="saveEmail"
                                disabled={isShowLoaderUpdateEmail}
                              >
                                {isShowLoaderUpdateEmail ? (
                                  <LoaderMail />
                                ) : (
                                  "Send verification Email"
                                )}
                              </button>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {editEmail !== "work" && (
                        <Link to="#" onClick={() => setEditEmail("work")}>
                          Edit
                        </Link>
                      )}
                    </h2>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </Accordion.Collapse>
        </div>

        <div className="lineAccordion">
          <Accordion.Toggle
            eventKey="2"
            onClick={() => {
              if (key.activeId === "2" && key.activeId === "2") {
                setkey({ eventId: "0", activeId: "0" });
              } else {
                setkey({ eventId: "2", activeId: "2" });
              }
              setEditEmail("");
              removeTxtAfterUpdate();
              //toggleActive(2);
            }}
            className={`btnAccordion ${key.activeId === "2" ? "active" : ""}`}
          >
            Password
            {isPassWordUpdated === "ok" && <div> Password updated</div>}
            {isPassWordUpdated === "error" && (
              <div className="container-error">
                <span className="error-red">Error</span>
                <span className="txt-error">
                  An error occured after update password
                </span>
              </div>
            )}
            {showErrorOldPassword && (
              <div className="container-error">
                <span className="error-red">Error</span>
                <span className="txt-error">Incorrect old password</span>
              </div>
            )}
            {showIdenticalPassWordsError && (
              <div className="container-error">
                <span className="error-red">Error</span>
                <span className="txt-error">
                  New password cannot be the same as old
                </span>
              </div>
            )}
            <div className="explication">
              Reset your password to protect your account
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={"2"}>
            <div className="panel-body">
              <div className="formForEmail changePassword pt-0 pl-0">
                <div className="changePwd">
                  <input
                    type={showOldPassword ? "text" : "password"}
                    placeholder="Old password"
                    value={password}
                    autoComplete="new-password"
                    onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                      handlePassword(evt.target.value)
                    }
                  />
                  <span
                    className="showPassWord pointer"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    {!showOldPassword ? "Show" : "Hide"}
                  </span>
                  {!isPasswordOk && (
                    <div className="container-error pl-2 d-inline">
                      <span className="error-red"> error </span>
                      <span className="txt-error">
                        {textError}
                      </span>
                    </div>
                  )}
                </div>
                <div className="changePwd">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New password"
                    value={newPassword}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                      handleNewPasswordChange(evt.target.value)
                    }
                    onBlur={checkPasswordPattern}
                  />
                  <span
                    className="showPassWord pointer"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {!showNewPassword ? "Show" : "Hide"}
                  </span>
                  {showConfirmPasswordError && (
                    <div className="container-error pl-2 d-inline">
                      <span className="error-red"> error </span>
                      <span className="txt-error">password does not match</span>
                    </div>
                  )}
                </div>
                <div className="changePwd">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm new password"
                    value={confirmNewPassword}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                      handleConfirmNewPasswordChange(evt.target.value)
                    }
                  />
                  <span
                    className="showPassWord pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {!showConfirmPassword ? "Show" : "Hide"}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                  className="btnctt mt-3"
                >
                  <button
                    className="cancel ml-0"
                    onClick={() => {
                      setkey({ eventId: "0", activeId: "0" });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="saveEmail"
                    onClick={handleUpdatePassword}
                    disabled={
                      isLoaderShow ||
                      disablePasswordButtonChange ||
                      isEmpty(password) ||
                      isEmpty(newPassword) ||
                      isEmpty(confirmNewPassword)
                    }
                  >
                    {isLoaderShow ? <Loader /> : "Change password"}
                  </button>
                </div>
              </div>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  );
};
