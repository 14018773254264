import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import { oem_roles } from '../../../utils';
export type PropTabPanHeader = {
  setTabNavigate: (s: string) => void;
  setShowBillingCenter: () => void;
  companyName: string;
  role: Array<string>;
};
export default function TabPanHeader(props: PropTabPanHeader) {
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const oemProgram = useSelector((state: rootState) => state.oemProgramReducer);

  const isOemOutreach = userReducer?.oemProgramMembers?.find(
    (el) => el.oem_program.id?.toString() === oemProgram.program?.id?.toString()
  );

  const isOutRepresentative =
    isOemOutreach?.role === oem_roles.outreach_representative;
  return (
    <div className='menuPlanTab borderAll'>
      <Nav variant='pills'>
        <div className='col-lg-3 evo'>
          <div className='title'>
            <h5>{props?.companyName}</h5>
          </div>
        </div>
        {/* <Nav.Item className="col-lg-2">
          <Nav.Link
            className="plan"
            eventKey="plan"
            onClick={() => props?.setTabNavigate("plan")}
          >
            Plan details
          </Nav.Link>
        </Nav.Item> */}
        {!isOutRepresentative && (
          <Nav.Item className='col-lg-2'>
            {(props.role?.indexOf('ROLE_ISV') !== -1 ||
              props.role?.indexOf('ROLE_OEM_PROGRAM') !== -1) && (
              <Nav.Link
                eventKey='role'
                onClick={() => props?.setTabNavigate('role')}
              >
                Roles
              </Nav.Link>
            )}
          </Nav.Item>
        )}
        {!isOutRepresentative && (
          <></>
          // <Nav.Item className='col-lg-3'>
          //   <Nav.Link
          //     eventKey='transaction'
          //     onClick={() => props?.setTabNavigate('transaction')}
          //   >
          //     Transaction history
          //   </Nav.Link>
          // </Nav.Item>
        )}
        {!isOutRepresentative && (
          <></>
          // <Nav.Item className='col-lg-2'>
          //   <Nav.Link
          //     eventKey='billingCenter'
          //     onClick={() => props?.setTabNavigate('billingCenter')}
          //   >
          //     <button
          //       className='cancel'
          //       onClick={() => props.setShowBillingCenter()}
          //     >
          //       Billing center
          //     </button>
          //   </Nav.Link>
          // </Nav.Item>
        )}
      </Nav>
    </div>
  );
}
