import * as React from "react";
import "../../widget/bouton/style-btn-next.css";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";

type Props = {
  text: string;
  styles?: React.CSSProperties;
  click?: () => void;
  showLoader?: boolean;
};

export const BtnNext: React.FC<Props> = (props) => {
  const { text, styles, click, showLoader = false } = props;

  return (
    <div
      className="btn-next-container"
      onClick={() => !showLoader && click && click()}
      style={{ ...styles, padding: "0.3rem" }}
    >
      {showLoader ? (
        <div className="loadIcon">
          <Loader />
        </div>
      ) : (
        <label htmlFor="" className="label-text-next">
          {text}
        </label>
      )}
    </div>
  );
};
