import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import { getPaymentIntent } from '../../../../service/applicatif/settings';
import { getData, getDataWithoutToken } from '../../../../service/api';
import { operationBillingAmount, url } from '../../../../service/constant';
import { getMonth } from '../../../../utils';

export default function useGuestAds() {
  let userObject = useSelector((state: rootState) => state.userReducer);
  const now = new Date();
  const [clientSecret, setClientSecret] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [options, setOptions] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [cardEmpty, setCardEmpty] = useState<boolean>(true);
  const [cardComplete, setCardComplete] = useState<boolean>(false);
  const [cardError, setCardError] = useState<any>(null);
  const [paymentIntentGuest, setPaymentIntentGuest] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [modalAdds, setmodalAdds] = useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [amountClassifiedAds, setAmountClassifiedAds] = useState<string>('');
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + ' ' + now.getFullYear()
  );

  const initAttribute = (clientSecret: string, amount: string, pi: string) => {
    setClientSecret(clientSecret);
    setAmount(amount);
    setOptions({
      clientSecret: clientSecret,
      appearance: {
        theme: 'stripe',
      },
    });
    setPaymentIntentGuest(pi);
  };

  const [stateAdds, setstateAdds] = useState<{
    number_left: number;
    number_max: number;
    // next_grant: string;
    // spent_by: Array<{
    //   id: string;
    //   first_name: string;
    //   last_name: string;
    //   user_uri: string;
    //   at: string;
    //   number: string;
    //   role: string;
    // }>;
  }>({ number_left: 0, number_max: 0 });

  const closeAddsModal = () => {
    setstateAdds({
      number_left: 0,
      number_max: 0,
    });
    setmodalAdds(false);
    setShow(false);
    setChoiseValue(undefined);
  };

  const showmodalAdds = () => {
    setmodalAdds(true);
    setShow(true);
  };

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };

  const getDataWs = async () => {
    const tempUrl =
      url.settings.billingCenter +
      '/' +
      operationBillingAmount.POST_CLASSIFIED_ADS +
      '/' +
      userReducer.guestCompany.id;

    let response = await getData(tempUrl, userReducer.token);
    if (response && response.data) setAmountClassifiedAds(response.data);
  };

  useEffect(() => {
    (async () => {
      userReducer.token && userReducer.guestCompany.id && getDataWs();
    })();
  }, [userReducer]);

  useEffect(() => {
    async function client_secret() {
      const params = {
        description:
          'BUY_CLASSIFIED_ADS [Guest User: ' + userObject?.email + ']',
        currency: 'usd',
        type: 'STRIPE',
      };
      const response = await getPaymentIntent(params, userObject?.token);
      if (response?.status === 200) {
        initAttribute(
          response?.data?.client_secret,
          response?.data?.amount,
          response?.data?.pi
        );
      }
    }
    client_secret();

    loading && setLoading(false);
    errorMessage && setErrorMessage('');
    cardComplete && setCardComplete(false);
    cardEmpty && setCardEmpty(true);
    cardError && setCardError(null);
  }, []);

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + '/information/plan_details',
        { month: currentMonth },
        userReducer.token
      );
      if (response?.status === 200) {
        const { guest_classified_ads }: any = response?.data?.free_status;
        setstateAdds(guest_classified_ads);
        setChargingPlanDetails(false);
        const isHaveFree = guest_classified_ads?.number_left > 0;
        if (isHaveFree) {
          setChoiseValue('FREE');
        }
      }
    }
    if (modalAdds) {
      getPlanDetails();
    }
  }, [currentMonth, modalAdds]);

  return {
    clientSecret,
    amount,
    options,
    loading,
    setLoading,
    errorMessage,
    setErrorMessage,
    cardComplete,
    cardEmpty,
    setCardComplete,
    setCardEmpty,
    cardError,
    setCardError,
    paymentIntentGuest,
    setPaymentIntentGuest,
    show,
    setShow,
    modalAdds,
    closeAddsModal,
    setmodalAdds,
    amountClassifiedAds,
    chargingPlanDetails,
    stateAdds,
    handleChoise,
    choiseValue,
    showmodalAdds,
  };
}
