/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React from 'react';
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
/*import { HeaderContainer, Logo } from "./style-header-logo-center";*/
import logoImg from '../../../resources/images/logo-lettre.svg';
import useWelcomeCtr from '../../../controller/use-welcom-ctr';
export default function Header(props: any) {
  const { backToHome, goLogin } = useWelcomeCtr();
  return (
    <div>
      <header>
        <nav>
          <div className='container mx-auto longMenu alreadyHeader'>
            <div className='navbar-brand'>
              <img
                src={logoImg}
                alt='Logo devinsider'
                className='logoHeaderCenter'
                onClick={() => backToHome()}
              />
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
