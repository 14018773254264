import {
  getDataService,
  postJsonDataService,
} from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import store from "../../store";
import { Dispatch } from "../../types/classified-ads-interface";

export const constante = {
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_FILTERS: "GET_FITLERS",
  GET_FRESH_FINDS: "GET_FRESH_FINDS",
  GET_ERROR_FRESH_FINDS: "GET_ERROR_FRESH_FINDS",
  LIKE_ADS: "LIKE_ADS",
  GET_MORE_FRESH_FINDS: "GET_MORE_FRESH_FINDS",
  LISTING_MY_CLASSIFIED_ADS: "LISTING_MY_CLASSIFIED_ADS",
  MORE_LISTING: "MORE_LISTING",
};

export const getCategories = (callback: (data: any) => void) => {
  return async (dispatch: Dispatch) => {
    try {
      let result = await getDataService(
        url?.classified_ads?.get_categories,
        {},
        store && store?.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        dispatch({
          type: constante.GET_CATEGORIES,
          payload: result?.data,
        });
      }
      callback(result);
    } catch (error: any) {}
  };
};

export const getFreshFinds = (params: any, callback: (data: any) => void) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.classified_ads?.get_fresh_finds,
        params,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante.GET_FRESH_FINDS,
          payload: {
            data: response?.data,
          },
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};

export const likeAnAd = (
  params: {
    idAds: number;
    status: number;
  },
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
    } catch (error: any) {}
  };
};

export const getMoreFreshFinds = (
  params: any,
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.classified_ads?.get_fresh_finds,
        params,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.GET_MORE_FRESH_FINDS,
          payload: response?.data,
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};

export const getFilters = (params: any, callback: (data: any) => void) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.classified_ads?.get_filters,
        params,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.GET_FILTERS,
          payload: response?.data,
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};

export const listingClassifiedAds = (
  page: number,
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await getDataService(
        url?.classified_ads?.listing + page,
        {},
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type:
            page === 1
              ? constante?.LISTING_MY_CLASSIFIED_ADS
              : constante?.MORE_LISTING,
          payload: response?.data,
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};
