/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "react-bootstrap/Modal";
import BodyRequestAdmin from "./body";
import * as T from "../../../../../../../controller/company-ctr/types/types-company-page";
import { ReactComponent as Loader } from "../../../../../../../resources/images/Rolling.svg";

export default function FormAddEmailRequestAdmin(props: T.TRequestAdmin) {
  return (
    <div>
      {/* Request step 1 */}
      <Modal
        show={props.showRequestAdmin}
        className="createCompanypopup smallPopup forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Request admin access</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => props?.cancelRequestAdmin()}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight">
          <BodyRequestAdmin
            companyName={props?.companyName}
            pays={props?.pays}
            webSite={props?.webSite}
            acceptTerm={props?.acceptTerm}
            workEmail={props?.workEmail}
            logoCompany={props?.logoCompany}
            toogleAcceptTerm={(p: boolean) => props?.toogleAcceptTerm(p)}
            errorDiffWorkEmail={props.errorDiffWorkEmail}
            emailRequest={props?.emailRequest}
            cancelRequestAdmin={() => props?.cancelRequestAdmin()}
            isShowLoaderSendRequest={props?.isShowLoaderSendRequest}
          />
        </Modal.Body>
        {props?.acceptTerm && (
          <Modal.Footer>
            <div className="contentBorder formForEmail text-right">
              <button
                className="btn btn-devinsider cancel"
                onClick={() => props?.cancelRequestAdmin()}
              >
                Cancel
              </button>
              <button
                className="btn btn-devinsider px-5"
                onClick={() => props?.sendReQuestAdmin()}
              >
                {props?.isShowLoaderSendRequest ? (
                  <Loader />
                ) : (
                  "Request admin access"
                )}
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
      {/* end Request  step 1*/}
    </div>
  );
}
