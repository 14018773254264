/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { url } from '../../../service/constant';
import { rootState } from '../../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { postJsonDataService } from '../../../service/applicatif/company-page-investor';
import { Link, Redirect, useHistory } from 'react-router-dom';
import {
  concatString,
  dateDiff,
  getTxtNotif,
  limitedText,
} from '../../../utils';
import { addNotification } from '../../../redux/actions/notification-action';
import { Notification } from '../../../redux/types/notification-interface';
import { isEmpty } from 'lodash';
type IProps = {};

export default function BlockNotif(props: IProps) {
  //Dev #48735,Bug pour indiquer que la notif est "lus"
  const state = useSelector((state: rootState) => state);
  const userReducer = state.userReducer;
  const notificationReducer = state.notificationReducer;
  const [notification, setnotification] = useState<Array<Notification>>([]);
  const [loaderRead, setLoaderRead] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isConnected = !isEmpty(userReducer?.token);

  const history = useHistory();

  const showUserActivity = (userId: number) => {
    history.push('/community/activity-user/' + userId);
  };

  const markAllNotisAsRead = async () => {
    const result = await postJsonDataService(
      url.notification.mark_all_as_read,
      {},
      userReducer.token
    );
    if (result?.status === 200) {
      const tempNotif: Array<Notification> = notification.map(
        (el: Notification) => ({ ...el, status: true })
      );
      setnotification(tempNotif);
    }
  };

  const showCommunityPost = (notif: Notification) => {
    const categorieId = notif?.post?.category?.id;
    const postId = notif?.post?.id;
    history.push('/community/reply-post/' + categorieId + '/' + postId);
  };

  useEffect(() => {
    async function getNotification() {
      const response = await postJsonDataService(
        url.notification.get_notification,
        {
          type: '',
          page: 1,
        },
        userReducer.token
      );
      if (response?.status === 200) {
        setnotification(response?.data?.list_notifications);
        dispatch(
          addNotification(
            response?.data?.number_notifcations_unread,
            response?.data?.list_notifications
          )
        );
      }
    }

    console.log('isConnected', isConnected);
    if (isConnected) {
      getNotification();
    }
    return () => {};
  }, []);

  const showAllNotif = () => {
    history.push('/notifications');
  };

  const markNotifAsRead = (notif: Notification) => {
    // Dev #48735,Bug pour indiquer que la notif est "lus"
    async function readNotif() {
      const response = await postJsonDataService(
        url.notification.mark_read + notif.id,
        {},
        userReducer.token
      );

      if (response?.status === 200) {
        const tempNotif = notificationReducer.data.map((el: Notification) => {
          if (el.id == notif.id) {
            return {
              ...el,

              status: true,
            };
          } else return el;
        });
        setnotification(tempNotif);
        dispatch(
          addNotification(notificationReducer.totalNumberUnread - 1, tempNotif)
        );
        setLoaderRead(false);
      }
    }
    const isNotRead = userReducer?.token && !notif.status;

    if (isNotRead) {
      setLoaderRead(true);
      readNotif();
    }
    //redirect to the notification
    const categorieId = notif?.post?.category?.id;
    const postId = notif?.post?.id;
    if (categorieId && postId) {
      history.push('/community/reply-post/' + categorieId + '/' + postId);
    }
  };

  const counting = (data: Array<Notification>) =>
    data.filter((el) => !el.status).length;

  let unreadNotif = counting(notification);

  return (
    <div className='item notif'>
      <Link to='#' className='linkHeader'>
        <span className='icon-notification'></span>
        <span className='mobile'>Notification</span>
        {/* Dev #48735,Bug pour indiquer que la notif est "lus" */}
        {notificationReducer.totalNumberUnread > 0 ? (
          <span className='non-lu'>
            {notificationReducer.totalNumberUnread}
          </span>
        ) : (
          <></>
        )}
      </Link>

      {notification.length > 0 && (
        <div className='submenu'>
          <div className='head_notif'>
            <Link to='#' className='text_notif'>
              Notification{' '}
              {notificationReducer.data.length > 0 &&
                '(' + notificationReducer.data.length + ')'}
            </Link>
            <Link
              to='#'
              onClick={(e) => {
                e.preventDefault();
                !loaderRead && markAllNotisAsRead();
              }}
            >
              Mark all as read
            </Link>
          </div>

          {notificationReducer.data?.map((notif: Notification, index) => {
            return (
              <Link
                to='#'
                className='all_notif'
                onClick={(e) => {
                  e.preventDefault();
                  markNotifAsRead(notif);
                }}
                key={index}
              >
                <div className={!notif?.status ? 'unread' : ''}>
                  <span className='icon-bubble mr-2 ico'></span>
                  <span className='message type'>
                    {notif?.type != 'SOLUTION_DISCUSSION_I_PARTICIPATED' &&
                    notif?.type !=
                      'SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE' ? (
                      <span
                        className='user'
                        onClick={() =>
                          showUserActivity(notif?.user_who_notify?.id)
                        }
                      >
                        {concatString(
                          notif?.user_who_notify?.first_name,
                          notif?.user_who_notify?.last_name,
                          ' '
                        )}
                      </span>
                    ) : (
                      <span className='defaultComment'>
                        The forum discussion{' '}
                      </span>
                    )}
                    {notif?.type != 'SOLUTION_DISCUSSION_I_PARTICIPATED' &&
                    notif?.type !=
                      'SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE' ? (
                      ''
                    ) : (
                      <span
                        className='yourComment'
                        onClick={() => showCommunityPost(notif)}
                      >
                        {limitedText(notif?.post?.title, 20) + ' '}
                      </span>
                    )}
                    <span className='defaultComment'>
                      {' ' + getTxtNotif(notif?.type) + ' '}
                    </span>{' '}
                    <span
                      className='yourComment'
                      onClick={() =>
                        notif?.type != 'SOLUTION_DISCUSSION_I_PARTICIPATED' &&
                        notif?.type !=
                          'SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE'
                          ? showCommunityPost(notif)
                          : showUserActivity(notif?.user_who_notify?.id)
                      }
                    >
                      {notif?.type != 'SOLUTION_DISCUSSION_I_PARTICIPATED' &&
                      notif?.type != 'SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE'
                        ? limitedText(notif?.post?.title, 20)
                        : notif?.user_who_notify?.first_name}
                    </span>
                  </span>
                  <span className='date'>{dateDiff(notif?.created_at)}</span>
                </div>
              </Link>
            );
          })}

          <Link
            to='#'
            className='seeAll'
            onClick={(e) => {
              e.preventDefault();
              showAllNotif();
            }}
          >
            See all
          </Link>
        </div>
      )}
    </div>
  );
}
