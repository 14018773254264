/* eslint-disable jsx-a11y/anchor-is-valid */
/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from 'react';
import { Redirect } from 'react-router-dom';

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import './style-complete-adresse.css';
import Header from '../../../../widget/header-logo-center/header-logo-center';
import useCompleteAdresse from '../../../../../controller/account-ctr/complete-create-acount-isv/use-complete-adresse';
import useEventListener from '../../../../../hooks/event-handler';
import ReactFlagsSelect from 'react-flags-select';
import { contact } from '../../../../../utils/country_phone';
import { checkIfExist, getSStorage } from '../../../../../utils';
export default function CompleteAdresse() {
  const {
    params,
    phone,
    companyName,
    jobTitle,
    history,
    countryError,
    phoneError,
    jobTytleError,
    companyNameError,
    dataCompleted,
    selected,
    setPhoneNumber,
    setCountry,
    setCompany,
    setJob,
    choiseInterested,
    webSite,
    setWebSite,
    websiteTxtError,
    errorWebSiteIsv,
    setSelected,
    dialCodeSelected,
    setDialCodeSelected
  } = useCompleteAdresse();

  const keyEnter = ({ key }: any) => {
    if (key == 'Enter') {
      choiseInterested();
    } else return;
  };

  useEventListener('keydown', keyEnter);

  const sess = getSStorage('email');

  const handleSelect = (countryCode: any) => {
    setSelected(countryCode);
    const dialCode = contact?.find(
      (el: any) => el.code == countryCode
    )?.dial_code;
    setDialCodeSelected(dialCode ? dialCode : "");
  };

  if (!checkIfExist(sess)) {
    return <Redirect to='/complete-info' />;
  }

  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <a onClick={() => history.goBack()} className="link-back">
            <span className="icon-back"></span>
          </a>
          <h1>Create your account.</h1>
        </div>
        <div className="contentBorder border-top row pb-4">
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2 p-0">
            <label htmlFor="">
              Country/Region
              <div
                className={`container-error-address pl-2 ${
                  countryError ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <select
              name="pets"
              id="pet-select"
              className="custom-select"
              onChange={(event: ChangeEvent<HTMLSelectElement>): void => {
                setCountry(event.target.value);
              }}
            >
              <option value="">Country</option>
              {dataCompleted?.country?.map((element: any, index: number) => (
                <option
                  value={element.id}
                  selected={
                    element.id.toString() == sessionStorage.getItem("country")
                  }
                  key={index}
                >
                  {`${element.country_name}`}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group  col-xl-6 col-md-6 col-sm-12 pl-xl-2 pl-md-2 p-0">
            <label htmlFor="">
              Phone number
              {phoneError === "validate" && (
                <div className="container-error pl-2 d-inline">
                  {/* change d-inline on d-none */}
                  <span className="error-red"> error </span>
                  <span className="txt-error">Phone number is not valid</span>
                </div>
              )}
            </label>
            <div className="numberphone" style={{ position: "relative" }}>
              <input
                type="number"
                className="phonenumber"
                value={phone}
                style={{
                  textIndent:
                    dialCodeSelected?.length === 2
                      ? 35
                      : dialCodeSelected?.length === 3
                      ? 40
                      : dialCodeSelected?.length === 4
                      ? 50
                      : 60,
                  paddingBottom: 5,
                }}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  setPhoneNumber(evt.target.value);
                }}
              />
              <span style={{ position: "absolute", top: 5, left: 60 }}>
                ({dialCodeSelected})
              </span>
              <ReactFlagsSelect
                className="selectFlag"
                selected={selected}
                onSelect={handleSelect}
                searchable={true}
                showSelectedLabel={false}
                placeholder=" "
              />
            </div>
          </div>
          <div className="form-group  required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2 p-0">
            <label htmlFor="">
              Company name
              <div
                className={`container-error-address pl-2 ${
                  companyNameError ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <input
              value={companyName}
              type="text"
              name=""
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                setCompany(event.target.value)
              }
              disabled={
                sessionStorage.getItem("token_affiliated") ? true : false
              }
              placeholder="Company name"
              className="form-control"
            />
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pl-xl-2 pl-md-2 p-0">
            <label htmlFor="">
              Website
              {errorWebSiteIsv && (
                <div className="container-error-adress pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">{websiteTxtError}</span>
                </div>
              )}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Begin with http:// or https:// or www."
              value={webSite}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                setWebSite(evt.target.value)
              }
              disabled={
                sessionStorage.getItem("token_affiliated") ? true : false
              }
            />
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2 p-0">
            <label htmlFor="">
              Job title
              <div
                className={`container-error-address pl-2 ${
                  jobTytleError ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <input
              type="text"
              name=""
              value={jobTitle}
              placeholder="Job title"
              className="form-control"
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                setJob(event.target.value)
              }
            />
          </div>
        </div>
        <div className="contentBorder border-top row align-items-stretch">
          <div className="col-12 d-flex align-items-center p-0">
            <button
              className="btn btn-devinsider large"
              onClick={() => choiseInterested()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
