/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
import ReactPlayer from "react-player";
import { ReactComponent as Loader } from "../../../../../../resources/images/Rolling.svg";
import DragAndDrop from "../../../../../widget/drag-and-drop/index";
import { useSelector } from "react-redux";
import { rootState } from "../../../../../../redux/reducers";
import baseUrl from "../../../../../../service/constant";
export default function ModalEditVideo(props: T.IpropsEditVideo) {
  const currentState = useSelector((state: rootState) => state);
  const companyPage = currentState?.companyPageReducer;
  // Dev #47679 V1 Agrandir la taille max de la video dans company page
  const videoUrl = useMemo(
    () => (props.selectedFile ? URL.createObjectURL(props.selectedFile) : ""),
    [props?.selectedFile]
  );
  return (
    <>
      <Modal
        show={props.isShowVideoEdit}
        className="createCompanypopup large forStep5custom"
      >
        {/* smallPopup */}
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Company video</h2>
            <a className="close" onClick={() => props.handleModalVideo()}>
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight">
          <div className="contentBorder row forOverview">
            <div className="upload">
              <div>
                <ul className="saveFile" onClick={() => props.handleBtn()}>
                  <button className="favorite styled" type="button">
                    {/* Edit Log time Watch Agrandir la taille max de la video dans company page */}
                    Choose file (100mb max)
                  </button>
                  {props?.isFilePicked && (
                    <span className="favorite styled">
                      {props?.uploadVideoStatus?.details?.total !==
                        props?.uploadVideoStatus?.details?.loaded &&
                        " Uploading... (" +
                          Math.round(
                            (props?.uploadVideoStatus?.details?.loaded * 100) /
                              props?.uploadVideoStatus?.details?.total
                          ) +
                          "%)"}
                    </span>
                  )}
                </ul>

                <DragAndDrop
                  handleDrop={(p: any) => props.handleDrop(p)}
                  urlValue="video/mp4,video/x-m4v"
                >
                  {/* Dev #47679 V1 Agrandir la taille max de la video dans company page */}
                  <div style={{ height: "100%", width: "100%" }}>
                    {props.isFilePicked ? (
                      <ReactPlayer
                        url={videoUrl}
                        height="420"
                        width="250"
                        style={{ marginLeft: "5px" }}
                        playing={true}
                        muted={true}
                        controls={true}
                        loop={false}
                      />
                    ) : (
                      <p>Drop the file here ...</p>
                    )}
                  </div>
                </DragAndDrop>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="contentBorder row forOverview">
          <div className="formForEmail ">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => props.handleModalVideo()}
            >
              Cancel
            </button>
            <button
              className="btn btn-devinsider px-5 save"
              onClick={() => props.updateVideoPresentation()}
            >
              {props.isShowLoaderUpdateVideo ? <Loader /> : "Save"}
            </button>
          </div>
        </Modal.Footer>
        <input
          type="file"
          id="getFile"
          accept="video/mp4,video/x-m4v,video/*"
          ref={props.fileRef}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            props.changeHandler(event)
          }
          style={{ display: "none !important" }}
        />
      </Modal>
    </>
  );
}
