import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import store from "../../../../redux/store";
import { getDataService } from "../../../../service/applicatif/back-end-service";
import { url } from "../../../../service/constant";

export type IPropsContributor = {
  id: string;
  title: string;
  labels: Array<{
    category: any;
    icone: string;
    id: number;
    main: boolean;
    slug: string;
    title: string;
  }>;
  category: {
    about: boolean;
    icon: string;
    id: number;
    isFollowed: boolean;
    slug: string;
    title: string;
  };

  post_type: { id: number; type: string };

  numberOfLikes: number;
  numberOfReplies: number;
  numberOfViews: number;
  created_at: string;
  updated_at: string;
};
export default function Index() {
  /* get top like contributions */
  const [topLikedContributions, setTopLikedContributions] = useState<
    Array<IPropsContributor>
  >([]);
  const [loaderContributor, setLoaderContributor] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    async function getData() {
      setLoaderContributor(true);
      const result = await getDataService(
        url.community.get_top_liked_contribution,
        {},
        store.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        setTopLikedContributions(result?.data);
        setLoaderContributor(false);
      }
    }
    getData();
  }, []);

  const viewAllContributor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "contributions",
    });
  };

  const goToreplayPost = (categoryId: string, currentPostId: string) => {
    history.push("/community/reply-post/" + categoryId + "/" + currentPostId);
  };

  const goToLabelCategory = (
    idCategory: any,
    labelId: string,
    postType: string
  ) => {
    postType === "1" &&
      history.push("/community/one-category/" + idCategory + "/" + labelId);
    postType === "2" &&
      history.push("/community/article-category/" + idCategory + "/" + labelId);
  };

  return {
    topLikedContributions,
    viewAllContributor,
    loaderContributor,
    goToreplayPost,
    goToLabelCategory,
  };
}
