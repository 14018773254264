import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getSStorage, setSessionStorageObject } from "../../../utils/index";
import useEventListener from "../../../hooks/event-handler";

export default function UseCompleteCategoryInvestor() {
  const history = useHistory();

  const [companySize, setCompanySize] = useState<Array<number>>(
    JSON.parse(getSStorage("companySize") || "[0,10]")
  );

  const [annualTurnover, setAnnualTurnover] = useState<Array<number>>(
    JSON.parse(getSStorage("annualTurnover") || "[0,90]")
  );
  const [foundingRound, setFoundingRound] = useState(
    getSStorage("foundingRound")
      ? JSON.parse(getSStorage("foundingRound") || "[]")
      : []
  );
  const [fundraisingGoal, setFundraisingGoal] = useState<Array<number>>(
    JSON.parse(getSStorage("fundraisingGoal") || "[10,110]")
  );

  const [errorCompanySize, setErrorCompanySize] = useState<boolean>(false);
  const [errorAnnualTurnover, setErrorAnnualTurnover] =
    useState<boolean>(false);
  const [errorFoundingRound, setErrorFoundingRound] = useState<boolean>(false);
  const [errorFundraisingGoal, setErrorFundraisingGoal] =
    useState<boolean>(false);
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );

  const [companySizeSlide, setCompanySizeSlide] = useState([
    JSON.parse(getSStorage("companySizeSlide")?.toString() || "[0,3200]")[0],
    JSON.parse(getSStorage("companySizeSlide")?.toString() || "[0,3200]")[1],
  ]);

  const [annualTurnoverSlide, setAnnualTurnoverSlide] = useState([
    JSON.parse(getSStorage("annualTurnoverSlide")?.toString() || "[0,90]")[0],
    JSON.parse(getSStorage("annualTurnoverSlide")?.toString() || "[0,90]")[1],
  ]);
  const [unitTurnover, setUnitTurnover] = useState(
    getSStorage("unitTurnover")?.toString() || " "
  );
  const [unitTurnoverMax, setUnitTurnoverMax] = useState(
    getSStorage("unitTurnoverMax")?.toString() || "M USD"
  );

  const [fundraisingGoalSlide, setFundraisingGoalSlide] = useState([
    JSON.parse(
      getSStorage("fundraisingGoalSlide")?.toString() || "[50,60]"
    )[0],
    JSON.parse(
      getSStorage("fundraisingGoalSlide")?.toString() || "[50,60]"
    )[1],
  ]);
  const [unitFundraisingGoal, setUnitFundraisingGoal] = useState(
    getSStorage("unitFundraisingGoal")?.toString() || ""
  );
  const [unitFundraisingGoalMax, setUnitFundraisingGoalMax] = useState(
    getSStorage("unitFundraisingGoalMax")?.toString() || "M USD"
  );

  /*
   * Valid CompanySize
   * params string
   * return boolean
   */
  const validCompanySize = (): boolean => {
    if (companySize.length > 0) {
      return true;
    } else {
      setErrorCompanySize(true);
      return false;
    }
  };

  /*
   * Valid AnnualTurnover
   * params string
   * return boolean
   */
  const validAnnualTurnover = (): boolean => {
    if (annualTurnover.length > 0) {
      return true;
    } else {
      setErrorAnnualTurnover(true);
      return false;
    }
  };

  /*
   * Valid FoundingRound
   * params string
   * return boolean
   */
  const validFoundingRound = (): boolean => {
    if (foundingRound.length > 0) {
      return true;
    } else {
      setErrorFoundingRound(true);
      return false;
    }
  };

  /*
   * Valid FoundingRound
   * params string
   * return boolean
   */
  const validFundraisingGoal = (): boolean => {
    if (fundraisingGoal.length > 0) {
      return true;
    } else {
      setErrorFundraisingGoal(true);
      return false;
    }
  };

  useEffect(() => {
    setErrorCompanySize(false);
    return () => {};
  }, [companySize]);

  useEffect(() => {
    setErrorAnnualTurnover(false);
    return () => {};
  }, [annualTurnover]);

  useEffect(() => {
    setErrorFoundingRound(false);
    return () => {};
  }, [foundingRound]);

  useEffect(() => {
    setErrorFundraisingGoal(false);
    return () => {};
  }, [fundraisingGoal]);

  const navigate = () => {
    setSessionStorageObject({
      companySize: JSON.stringify(companySize),
      companySizeSlide: JSON.stringify(companySizeSlide),
      annualTurnover: JSON.stringify(annualTurnover),
      annualTurnoverSlide: JSON.stringify(annualTurnoverSlide),
      unitTurnover: unitTurnover,
      unitTurnoverMax: unitTurnoverMax,
      foundingRound: JSON.stringify(foundingRound),
      fundraisingGoal: JSON.stringify(fundraisingGoal),
      fundraisingGoalSlide: JSON.stringify(fundraisingGoalSlide),
      unitFundraisingGoal: unitFundraisingGoal,
      unitFundraisingGoalMax: unitFundraisingGoalMax,
    });
    history.push("/complete-category-investor");
  };

  const navigateToIndustrieTarget = () => {
    if (
      validCompanySize() &&
      validAnnualTurnover() &&
      validFoundingRound() &&
      validFundraisingGoal()
    ) {
      navigate();
    } else return;
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateToIndustrieTarget();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  const customSetCompanySize = (min: number, max: number) => {
    let tempMin: number = 0;
    let tempMax: number = 0;

    switch (min) {
      case 0:
        tempMin = 0;
        break;
      case 1:
        tempMin = 5;
        break;
      case 2:
        tempMin = 15;
        break;
      case 3:
        tempMin = 25;
        break;
      case 4:
        tempMin = 50;
        break;
      case 5:
        tempMin = 100;
        break;
      case 6:
        tempMin = 250;
        break;
      case 7:
        tempMin = 500;
        break;
      case 8:
        tempMin = 1000;
        break;
      case 9:
        tempMin = 3000;
        break;
      case 10:
        tempMin = 3000;
        break;
      default:
        break;
    }
    switch (max) {
      case 0:
        tempMax = 3200;
        break;
      case 1:
        tempMax = 5;
        break;
      case 2:
        tempMax = 15;
        break;
      case 3:
        tempMax = 25;
        break;
      case 4:
        tempMax = 50;
        break;
      case 5:
        tempMax = 100;
        break;
      case 6:
        tempMax = 250;
        break;
      case 7:
        tempMax = 500;
        break;
      case 8:
        tempMax = 1000;
        break;
      case 9:
        tempMax = 3000;
        break;
      case 10:
        tempMax = 3200;
        break;
      default:
        break;
    }
    setCompanySizeSlide([tempMin, tempMax]);
    setCompanySize([min, max]);
  };

  const customSetAnnualSize = (min: number, max: number) => {
    let tempMin: number = 0;
    let tempMax: number = 0;
    switch (min) {
      case 0:
      case 1:
        tempMin = 0;
        setUnitTurnover("");
        break;
      case 10:
      case 11:
        tempMin = 500;
        setUnitTurnover("K USD");
        break;
      case 20:
      case 21:
        tempMin = 2;
        setUnitTurnover("M USD");
        break;
      case 30:
      case 31:
        tempMin = 5;
        setUnitTurnover("M USD");
        break;
      case 40:
      case 41:
        tempMin = 10;
        setUnitTurnover("M USD");
        break;
      case 50:
      case 51:
        tempMin = 25;
        setUnitTurnover("M USD");

        break;
      case 60:
      case 61:
        tempMin = 50;
        setUnitTurnover("M USD");
        break;
      case 70:
      case 71:
        tempMin = 200;
        setUnitTurnover("M USD");
        break;
      case 80:
      case 81:
        tempMin = 500;
        setUnitTurnover("M USD");
        break;
      default:
        break;
    }
    switch (max) {
      case 0:
      case 1:
        tempMax = 600;
        break;
      case 10:
      case 11:
        tempMax = 500;
        setUnitTurnoverMax("K USD");
        break;
      case 20:
      case 21:
        tempMax = 2;
        setUnitTurnoverMax("M USD");
        break;
      case 30:
      case 31:
        tempMax = 5;
        setUnitTurnoverMax("M USD");

        break;
      case 40:
      case 41:
        tempMax = 10;
        setUnitTurnoverMax("M USD");

        break;
      case 50:
      case 51:
        tempMax = 25;
        setUnitTurnoverMax("M USD");

        break;
      case 60:
      case 61:
        tempMax = 50;
        setUnitTurnoverMax("M USD");

        break;
      case 70:
      case 71:
        tempMax = 200;
        setUnitTurnoverMax("M USD");

        break;
      case 80:
      case 81:
        tempMax = 500;
        setUnitTurnoverMax("M USD");

        break;
      case 90:
      case 91:
        tempMax = 600;
        setUnitTurnoverMax("M USD");

        break;
      default:
        break;
    }
    setAnnualTurnoverSlide([tempMin, tempMax]);
    setAnnualTurnover([min, max]);
  };

  const customFundraisingGoalSlide = (min: number, max: number) => {
    let tempMin: number = 0;
    let tempMax: number = 0;
    switch (min) {
      case 0:
      case 1:
        tempMin = 0;
        setUnitFundraisingGoal("");
        break;
      case 10:
      case 11:
        tempMin = 50;
        setUnitFundraisingGoal("K USD");
        break;
      case 20:
      case 21:
        tempMin = 250;
        setUnitFundraisingGoal("K USD");
        break;
      case 30:
      case 31:
        tempMin = 500;
        setUnitFundraisingGoal("K USD");
        break;
      case 40:
      case 41:
        tempMin = 1;
        setUnitFundraisingGoal("M USD");
        break;
      case 50:
      case 51:
        tempMin = 2;
        setUnitFundraisingGoal("M USD");
        break;
      case 60:
      case 61:
        tempMin = 5;
        setUnitFundraisingGoal("M USD");
        break;
      case 70:
      case 71:
        tempMin = 10;
        setUnitFundraisingGoal("M USD");
        break;
      case 80:
      case 81:
        tempMin = 15;
        setUnitFundraisingGoal("M USD");
        break;
      case 90:
      case 91:
        tempMin = 25;
        setUnitFundraisingGoal("M USD");
        break;
      case 100:
      case 101:
        tempMin = 50;
        setUnitFundraisingGoal("M USD");
        break;
      default:
        break;
    }
    switch (max) {
      case 0:
      case 1:
        tempMax = 60;
        break;
      case 10:
      case 11:
        tempMax = 50;
        setUnitFundraisingGoalMax("K USD");
        break;
      case 20:
      case 21:
        tempMax = 250;
        setUnitFundraisingGoalMax("K USD");
        break;
      case 30:
      case 31:
        tempMax = 500;
        setUnitFundraisingGoalMax("K USD");
        break;
      case 40:
      case 41:
        tempMax = 1;
        setUnitFundraisingGoalMax("M USD");
        break;
      case 50:
      case 51:
        tempMax = 2;
        setUnitFundraisingGoalMax("M USD");
        break;
      case 60:
      case 61:
        tempMax = 5;
        setUnitFundraisingGoalMax("M USD");
        break;
      case 70:
      case 71:
        tempMax = 10;
        setUnitFundraisingGoalMax("M USD");
        break;
      case 80:
      case 81:
        tempMax = 15;
        setUnitFundraisingGoalMax("M USD");
        break;
      case 90:
      case 91:
        tempMax = 25;
        setUnitFundraisingGoalMax("M USD");
        break;
      case 100:
      case 101:
        tempMax = 50;
        setUnitFundraisingGoalMax("M USD");
        break;
      case 100:
      case 110:
        tempMax = 60;
        setUnitFundraisingGoalMax("M USD");
        break;
      default:
        break;
    }
    setFundraisingGoalSlide([tempMin, tempMax]);
    setFundraisingGoal([min, max]);
  };

  return {
    navigateToIndustrieTarget,
    companySize,
    setCompanySize,
    annualTurnover,
    setAnnualTurnover,
    foundingRound,
    setFoundingRound,
    fundraisingGoal,
    setFundraisingGoal,
    errorCompanySize,
    errorAnnualTurnover,
    errorFoundingRound,
    errorFundraisingGoal,
    dataCompleted,
    companySizeSlide,
    customSetCompanySize,
    annualTurnoverSlide,
    customSetAnnualSize,
    fundraisingGoalSlide,
    setFundraisingGoalSlide,
    customFundraisingGoalSlide,
    unitTurnover,
    unitTurnoverMax,
    unitFundraisingGoal,
    unitFundraisingGoalMax,
  };
}
