import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  getSStorage,
  isRequired,
  setSessionStorageObject,
} from "../../../utils";
import useNavigation from "../../../hooks/use-navigate";
import { useCompleteDataStorageOem } from "./use-complete-data-storage-oem";

interface StepData {
  programName: string;
}

interface Categories {
  embedded: string;
  ecoSystem: string;
}

export default function useCompleteProgramTypeOem() {
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const { dataOem, addDataOem } = useCompleteDataStorageOem();
  const [type, setType] = useState<string>(
    dataOem?.oemProgramType ? dataOem?.oemProgramType.toString() : ""
  );
  const [typeError, setTypeError] = useState<boolean>(false);
  const [segmentError, setSegmentError] = useState<boolean>(false);
  const [categories, setCategories] = useState<Categories>({
    embedded: "1",
    ecoSystem: "2",
  });
  const [dropDownItems, setDropDownItems] = useState<Array<object>>([]);
  const [segments, setSegments] = useState<any>([]);

  const { navigate } = useNavigation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  /**
   * Set select list depends on type
   */
  useEffect(() => {
    if (type === categories.embedded) {
      setDropDownItems(dataCompleted?.oem_program_type?.["Embedded Software"]);
    }

    if (type === categories.ecoSystem) {
      setDropDownItems(dataCompleted?.oem_program_type?.["Software Ecosystem"]);
    }

    setSegments([]);
  }, [type]);

  /**
   * Init select with default value
   */
  useEffect(() => {
    setSegments(JSON.parse(getSStorage("segments") || "[]"));
  }, []);

  useEffect(() => {
    setSegmentError(false);
  }, [segments]);

  /**
   * Submit data
   * @param {StepData} data
   */
  const onSubmit = (data: StepData) => {
    if (!isRequired(type)) {
      setTypeError(true);
      return;
    }

    if (segments.length < 1) {
      setSegmentError(true);
      return;
    }
    const programSegments = segments?.map(
      (e: { id: number; value: string; label: string }) => e.id
    );

    addDataOem({
      oemProgramType: parseInt(type),
      programName: data.programName,
      oemProgramTypeCategory: JSON.stringify(programSegments),
    });

    setSessionStorageObject({ segments: JSON.stringify(segments) });
    navigate("/complete-company-target-oem");
  };

  const handleTypeChange = (type: string) => {
    setTypeError(false);
    setType(type);
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    handleTypeChange,
    typeError,
    type,
    categories,
    dataCompleted,
    dropDownItems,
    dataOem,
    segments,
    setSegments,
    segmentError,
    setTypeError,
    setSegmentError,
    reset,
  };
}
