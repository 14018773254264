/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from 'react';
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from '../../../../widget/header-logo-center/header-logo-center';
import UseCompleteAdresseReseller from '../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-adresse-reseller';
import ReactFlagsSelect from 'react-flags-select';
import { contact } from '../../../../../utils/country_phone';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Redirect } from 'react-router-dom';
import { checkIfExist, getSStorage } from '../../../../../utils';
export default function CompleteAddressResseler() {
  const {
    goBack,
    dataCompleted,
    onSubmitForm,
    register,
    handleSubmit,
    errors,
    selected,
    setSelected,
    dialCodeSelected,
    setDialCodeSelected
  } = UseCompleteAdresseReseller();

  const sess = getSStorage('emailReseller');

  if (!checkIfExist(sess)) {
    return <Redirect to='/complete-info-reseller' />;
  }

  const handleSelect = (countryCode: any) => {
    setSelected(countryCode);
    const dialCode = contact?.find(
      (el: any) => el.code == countryCode
    )?.dial_code;
    setDialCodeSelected(dialCode ? dialCode : "");
  };

  return (
    <div>
      <Header />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="getstartedContainer">
          <div className="head-getstarted">
            <div className="stepInscription">
              <div className="stepItem done"></div>
              <div className="stepItem done"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
            </div>
            <a
              className="link-back"
              onClick={() => {
                goBack();
              }}
            >
              <span className="icon-back"></span>
            </a>
            <h1>Create your account.</h1>
          </div>
          <div className="contentBorder border-top row pb-4">
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2 p-0">
              <label htmlFor="">
                Country/Region{" "}
                {errors.countryReseller && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <select
                className="custom-select"
                {...register("countryReseller", {
                  required: true,
                })}
              >
                <option value="">Country</option>
                {dataCompleted?.country?.map((element: any, index: number) => (
                  <option
                    value={element.id}
                    selected={
                      element.id.toString() ==
                      sessionStorage.getItem("countryReseller")
                    }
                    key={index}
                  >
                    {`${element.country_name}`}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pl-xl-2 pl-md-2 p-0">
              <label htmlFor="">
                Phone number
                {errors.phoneReseller?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                {errors.phoneReseller?.type === "validate" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">Phone number is not valid</span>
                  </div>
                )}
              </label>
              <div className="numberphone" style={{ position: "relative" }}>
                <input
                  type="number"
                  className="phonenumber"
                  style={{
                    textIndent:
                      dialCodeSelected?.length === 2
                        ? 35
                        : dialCodeSelected?.length === 3
                        ? 40
                        : dialCodeSelected?.length === 4
                        ? 50
                        : 60,
                    paddingBottom: 5,
                  }}
                  {...register("phoneReseller", {
                    required: true,
                    validate: (phone: any) =>
                      isValidPhoneNumber(
                        dialCodeSelected + phone?.toString()
                      ),
                  })}
                />
                <span style={{ position: "absolute", top: 5, left: 60 }}>
                  (
                  {dialCodeSelected}
                  )
                </span>
                <ReactFlagsSelect
                  className="selectFlag"
                  selected={selected}
                  onSelect={handleSelect}
                  searchable={true}
                  showSelectedLabel={false}
                  placeholder=" "
                />
              </div>
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2 p-0">
              <label htmlFor="">
                Company name
                {errors.companyReseller?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                placeholder="Company name"
                className="form-control"
                {...register("companyReseller", {
                  required: true,
                })}
              />
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pl-xl-2 pl-md-2 p-0">
              <label htmlFor="">
                Job title
                {errors.jobReseller?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                placeholder="Job title"
                className="form-control"
                {...register("jobReseller", {
                  required: true,
                })}
              />
            </div>
          </div>
          <div className="contentBorder border-top row align-items-stretch">
            <div className="col-12 d-flex align-items-center p-0">
              <button className="btn btn-devinsider large" type="submit">
                Next{" "}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
