/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
import { ReactComponent as Loader } from "../../../../../../resources/images/Rolling.svg";

export default function ModalEditChart(props: T.TEditChart) {
  const getValue = (p: string) =>
    props.updateChartForm?.find((v: T.ItemsGraph) => v?.year == p)?.amount;

  const setValue = (p: string, value: string) =>
    props.updateChartForm?.map((t: T.ItemsGraph) => {
      if (t?.year == p) {
        return {
          ...t,
          amount: value,
        };
      } else {
        return t;
      }
    });
  return (
    <div>
      <Modal
        show={props.showFinancialChart}
        className="createCompanypopup smallPopup forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Annual revenue</h2>
            <a className="close" onClick={() => props.cancelUpdateChart()}>
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight">
          <div className="contentBorder row">
            <div className="col-12 pb-4">
            Show investors the annual revenue progression from the past two years and a projection of the three upcoming years.
            </div>
            {props.dataGrapH?.map((el: T.ItemsGraph, index: number) => (
              <div className="form-group col-12" key={index}>
                <label htmlFor="">{el?.year}</label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>USD</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    value={getValue(el?.year)}
                    pattern="[0-9]*"
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      const regex = /^[0-9\b]+$/;
                      if (
                        evt.target.value === "" ||
                        regex.test(evt.target.value)
                      ) {
                        props.setUpdateChartForm(
                          setValue(el?.year, evt?.target?.value)
                        );
                      }
                    }}
                  />
                </InputGroup>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => props.cancelUpdateChart()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-devinsider px-5 save"
              onClick={() => props.updateGrapheData()}
            >
              {props.loaderChart ? <Loader /> : "Save"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
