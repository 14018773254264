import React, { useEffect, useState } from "react";
import { postUserActivity } from "../../../service/applicatif/acount";
import store from "../../../redux/store";
import { url } from "../../../service/constant";
import { PostUserFilter } from "../../../interface/community";
import { useHistory } from "react-router-dom";

export default function RepliesByUser(userId: number) {
  const [repliesByUser, setRepliesByUser] = useState<any>([]);
  const [repliesUserFilter, setRepliesUserFilter] = useState<PostUserFilter>({
    postTypes: "",
    limit: "10",
    page: "1",
    sortBy: "post_date",
    orderBy: "DESC",
  });
  const [loadingReplies, setLoadingReplies] = useState<boolean>(false);
  const history = useHistory();

  const getReplyByUser = async (params: any) => {
    setLoadingReplies(true);
    const response = await postUserActivity(
      url.community.activity.get_replies_by_user,
      params,
      store.getState().userReducer.token
    );
    if (response?.status === 200) {
      setRepliesByUser(response?.data);
    }
    setLoadingReplies(false);
  };

  useEffect(() => {
    const params = { ...repliesUserFilter, idUser: userId };
    getReplyByUser(params);
    setRepliesUserFilter(params);
  }, [userId]);

  const handleChangePostType = (e: any) => {
    const postType = e.value;

    const paramsPostType = {
      ...repliesUserFilter,
      postTypes: postType,
      page: "1",
    };

    getReplyByUser(paramsPostType);
    setRepliesUserFilter(paramsPostType);
  };

  const handleChangeShow = (e: any) => {
    const show = e.value;
    const params = {
      ...repliesUserFilter,
      page: "1",
      limit: show,
    };
    getReplyByUser(params);
    setRepliesUserFilter(params);
  };

  const handleChangeSorted = (e: any) => {
    const sorted = e.value;

    const params = {
      ...repliesUserFilter,
      page: "1",
      sortBy: sorted,
    };
    getReplyByUser(params);
    setRepliesUserFilter(params);
  };

  const handlePage = (p: any) => {
    const params = {
      ...repliesUserFilter,
      page: p,
    };
    getReplyByUser(params);
    setRepliesUserFilter(params);
  };
  const goToreplayPost = (postId: number, idCategorie: number) => {
    const url = "/community/reply-post/" + idCategorie + "/" + postId;
    history.push(url);
  };

  return {
    repliesByUser,
    repliesUserFilter,
    setRepliesUserFilter,
    loadingReplies,
    handleChangePostType,
    handleChangeSorted,
    handleChangeShow,
    handlePage,
    goToreplayPost,
  };
}
