import Header from '../../../../widget/header-logo-center/header-logo-center';
import StepperOem from '../../../../widget/stepper/StepperOem';
import React from 'react';
import useCompleteCategiresOem from '../../../../../controller/account-ctr/create-oem-account/use-complete-categories-oem';
import SoftwareSolution from '../../../../widget/software-solution';
import { checkIfExist, getSStorage } from '../../../../../utils';
import { Redirect } from 'react-router-dom';

export default function CompleteCategoriesOem() {
  const {
    solutionLeftColumnData,
    solutionRigthColumnData,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    featuredSolution,
    onSubmit,
    hasError,
    filterSolutionChecked,
    removeItemSelected,
  } = useCompleteCategiresOem();

  const sess: any = getSStorage('oemProject');

  if (!checkIfExist(sess)) {
    return <Redirect to='/partner-program-registration' />;
  }
  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <StepperOem step={3} />
        <div className='contentBorder border-top row py-2'>
          <div className='col-12 form-group required mb-0'>
            <label htmlFor='jobtitle required'>
              What ISV solution category(ies) are you interested in?
            </label>
            <span className='pl-3'>Select all that apply</span>
          </div>
          <div className='col-12'>
            {hasError && (
              <div className='container-error d-inline'>
                <span className='error-red'> error </span>
                <span className='txt-error'>This field is required</span>
              </div>
            )}
          </div>
          <SoftwareSolution
            addSolutionCheched={addSolutionCheched}
            addSolutionChechedFeatured={addSolutionChechedFeatured}
            featuredSolution={featuredSolution}
            solutionChecked={solutionChecked}
            solutionCheckedFeatures={solutionCheckedFeatures}
            solutionRigthColumnData={solutionRigthColumnData}
            solutionLeftColumnData={solutionLeftColumnData}
            filterSolutionChecked={filterSolutionChecked}
            removeItemSelected={removeItemSelected}
          />
        </div>

        <div className='contentBorder border-top row align-items-stretch'>
          <button
            type='submit'
            className='btn btn-devinsider large'
            onClick={onSubmit}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
