import React, { useEffect, useRef, useCallback } from 'react';

export default function useEventListener(
  eventName: string,
  handler: any,
  element = window
) {
  const savedHandler: any = useRef();
  const isCtrlPressed: any = useRef(false);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Control') {
      isCtrlPressed.current = true;
    }
  }, []);

  const handleKeyUp = useCallback((event) => {
    if (event.key === 'Control') {
      isCtrlPressed.current = false;
    }
  }, []);

  const handleEvent = useCallback(
    (event) => {
      if (isCtrlPressed.current && event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        handler('CtrlEnter');
      } else if (!isCtrlPressed.current && event.key === 'Enter') {
        handler('Enter');
      }
    },
    [handler]
  );

  useEffect(() => {
    savedHandler.current = handleEvent;
    return () => {};
  }, [handleEvent]);

  useEffect(() => {
    const isSupported = element?.addEventListener;
    if (!isSupported) return;

    element.addEventListener(eventName, savedHandler.current);
    element.addEventListener('keydown', handleKeyDown);
    element.addEventListener('keyup', handleKeyUp);

    return () => {
      element.removeEventListener(eventName, savedHandler.current);
      element.removeEventListener('keydown', handleKeyDown);
      element.removeEventListener('keyup', handleKeyUp);
    };
  }, [eventName, element, handleKeyDown, handleKeyUp]);

  return savedHandler.current;
}
