import React, { ChangeEvent } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Loader } from '../../../../../../resources/images/Rolling.svg';

export type IProps = {
  isShowKeyCompetitive: boolean;
  setIsShowKeyCompetitive: (p: boolean) => void;
  valueOfKeyCompetitiveForm: string;
  setValueOfKeyCompetitiveForm: (t: string) => void;
  loader: boolean;
  updatePicth: () => void;
};

export default function ModalEditKeyCompetitive(props: IProps) {
  return (
    <Modal
      show={props.isShowKeyCompetitive}
      className='createCompanypopup large forStep5custom'
    >
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>Key competitive Differentiator</h2>
          <a
            className='close'
            onClick={() => props.setIsShowKeyCompetitive(false)}
          >
            <span className='icon-close-pop'></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className=''>
        <div className='contentBorder row forPitch'>
          <div className='form-group col-12'>
            <label htmlFor=''>
              {' '}
              What are your key competitive differentiator(s)?
            </label>
          </div>
          <div className='form-group col-12 descCompany m-0'>
            <textarea
              maxLength={2000}
              value={props.valueOfKeyCompetitiveForm}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                props.setValueOfKeyCompetitiveForm(event.target.value)
              }
            ></textarea>
            <span className='caractercount'>
              {0 + (props.valueOfKeyCompetitiveForm?.length || 0) + ' / 2000'}
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='contentBorder formForEmail text-right forPitch'>
          <button
            className='btn btn-devinsider cancel'
            onClick={() => props.setIsShowKeyCompetitive(false)}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-devinsider px-5 save'
            onClick={() => props.updatePicth()}
          >
            {props.loader ? <Loader /> : 'Save'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
