/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IPropsEditSolutionIndustrie } from '../types';
import SofTwareSolution from '../../../../widget/software-solution/index';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';

export default function EditInfoSolutionIndustrie(
  props: IPropsEditSolutionIndustrie
) {
  return (
    <Modal
      show={props.showIndustry}
      className='createCompanypopup large forStep5custom'
    >
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>Solution industry</h2>
          <a className='close' onClick={() => props.setShowIndustry()}>
            <span className='icon-close-pop'></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <SofTwareSolution {...props} />
      </Modal.Body>
      <Modal.Footer>
        <div className='contentBorder formForEmail text-right forPitch'>
          <button
            className='btn btn-devinsider cancel'
            onClick={() => props.setShowIndustry()}
            type='button'
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-devinsider px-5'
            onClick={() => props?.saveSolutionChecked()}
          >
            {props?.loader ? <Loader /> : 'Save'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
