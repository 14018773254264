import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { ReactComponent as Loader } from "../../../../../../../../resources/images/Rolling.svg";
import { IpropsEditExpert } from "./type";
export default function ModalEditInfoUser(props: IpropsEditExpert) {
  return (
    <Modal
      show={props?.showEditModal}
      onHide={() => props?.cancelChange()}
      className="modalDevinsider medium"
    >
      <form onSubmit={props.handleSubmit(props?.submit)}>
        <Modal.Header closeButton>
          <Modal.Title>User details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0">
            <div className="col-12 p-0 form-group required">
              <label htmlFor="first-name">First name</label>
              {props.errors.firstName && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}

              <input
                type="text"
                className="input-text form-control"
                {...props.register("firstName", {
                  required: true,
                })}
              />
            </div>
            <div className="col-12 p-0 form-group required">
              <label htmlFor="last-name">Last name</label>
              {props.errors.lastName && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <input
                type="text"
                className="input-text form-control"
                {...props.register("lastName", {
                  required: true,
                })}
              />
            </div>
            <div className="col-6 form-group required p-0 pr-1">
              <label htmlFor="location">Location</label>
              {props.errors.location && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <select
                className="custom-select"
                {...props.register("location", {
                  required: true,
                })}
              >
                {props.dataCompleted?.country?.map(
                  (element: any, index: number) => (
                    <option
                      key={index}
                      value={element?.country_name}
                      selected={
                        element?.country_name ==
                        props.userState?.country?.country_name
                      }
                    >
                      {`${element?.country_name}`}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="col-6 form-group p-0 pl-1">
              <label htmlFor="first-name">&nbsp;</label>
              <input
                type="text"
                className="input-text form-control"
                placeholder="City"
                {...props.register("city")}
              />
            </div>

            <div className="col-12 p-0 form-group required">
              <label htmlFor="company">Job title</label>
              {props.errors.jobTitle && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <input
                type="text"
                className="input-text form-control"
                {...props.register("jobTitle", {
                  required: true,
                })}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancelbtn" onClick={() => props?.cancelChange()}>
            Cancel
          </Button>
          <Button
            className="validate"
            type="submit"
            disabled={props?.isLoaderShow}
          >
            {props?.isLoaderShow ? <Loader /> : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
