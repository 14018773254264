/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from 'react';
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from '../../../../widget/header-logo-center/header-logo-center';
import UseCompleteCompanyDescriptionReseller from '../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-company-key-competitive';
import { checkIfExist, getSStorage } from '../../../../../utils';
import { Redirect } from 'react-router-dom';

export default function CompleteCompanyKeyCompetitive() {
  const {
    navigateToCompleteLogo,
    keyCompetitiveValue,
    setKeyCompetitiveValue,
    goBack,
  } = UseCompleteCompanyDescriptionReseller();

  const sess = getSStorage('emailReseller');

  if (!checkIfExist(sess)) {
    return <Redirect to='/complete-info-reseller' />;
  }

  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <div className='head-getstarted'>
          <div className='stepInscription'>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem'></div>
          </div>
          <a className='link-back' onClick={() => goBack()}>
            <span className='icon-back'></span>
          </a>
          <h1>Company information</h1>
        </div>
        <div className='contentBorder border-top row pt-3 pb-4'>
          <div className='form-group col-xl-12 p-0 m-0 descCompany'>
            <label htmlFor=''>
              Highlight your key competitive differentiator(s)
            </label>
            <textarea
              value={keyCompetitiveValue}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                setKeyCompetitiveValue(event.target.value)
              }
              maxLength={450}
            />
            <span className='caractercount'>
              {0 + keyCompetitiveValue.length || 0} / 450
            </span>
          </div>
        </div>
        <div className='contentBorder border-top row align-items-stretch pt-5'>
          <button
            className='btn btn-devinsider large'
            onClick={() => navigateToCompleteLogo()}
          >
            Next
          </button>
          <a className='skip' onClick={() => navigateToCompleteLogo(true)}>
            Skip
          </a>
        </div>
      </div>
    </div>
  );
}
