import React, { useEffect } from "react";
import Header from "../../widget/index";
import Footer from "../../widget/footer/footer";
import LogoDI from "../../../resources/images/logo-round.svg"
import "./static-page.scss";

export default function About() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);
  return (
    <div>
      <Header />
      <section className="joinHome about">
        <div className="container mx-auto">
          <img className="logoAbout" src={LogoDI} alt="" />
          <h1 className="title">Welcome to Devinsider!</h1>
        </div>
      </section>
      <section className="aboutDescription">
        <div className="container mx-auto">
          <p>
          We are a social media platform fully dedicated to accompanying B2B software vendors along each stage of their business  
          lifecycle.  Our mission is to help software vendors succeed so that their technologies can change the world of tomorrow. <br/><br/>
          Devinsider provides access to valuable resources in order to grow software businesses globally. Whether that be through  
          strategic technology partnerships, investment opportunities, or by learning best practices from thought leaders in specific  
          domains.  Devinsider unites all of these resources and peer-to-peer exchanges onto one platform, and is the go-to platform to  
          ensure business success.<br/><br/>
          
          <b>Headquarters</b><br/>
          Devinsider<br/>
          23-25 rue Jean-Jacques Rousseau<br/>
          75001 Paris<br/><br/>

          contact@devinsider.com
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}
