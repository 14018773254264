import React from "react";
import Header from "../../../widget/index";
import { useHistory } from "react-router";
import "../classifiedAds.scss";
import UseHomepage from "../../../../controller/classified-ads-ctr/homepage-ctr/homepage-ctr";
import { LoaderCommon } from "../../../widget/loader";
import { IAd } from "../../../../redux/types/classified-ads-interface";
import { LoginModal } from "../../../widget/login/login";
import AdsItem from "../../../widget/ads";
import Footer from "../../../widget/footer/footer";

export default function HomePageClassifiedAds() {
  const history = useHistory();

  const goToCategoryPage = (idCategory: any) => {
    history.push("/classified-ads/category/" + idCategory);
  };

  const providerClassifiedAds = UseHomepage();
  return (
    <div>
      <Header />
      <section className="classified">
        <div className="container mx-auto">
          <div className="explore-ads">
            <h2 className="title">Explore ads</h2>
            <ul className="listOfAds">
              {providerClassifiedAds?.classifiedAdsReducer?.categories?.map(
                (el: any, i: number) => (
                  <li key={i}>
                    <a
                      href="javascript:;"
                      onClick={() => goToCategoryPage(el?.id)}
                    >
                      <span className={el?.icon} />
                      {el?.type}
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="freshfinds">
            <h2 className="title">Fresh finds</h2>
            {!providerClassifiedAds?.classifiedAdsReducer?.freshFinds
              ?.dataFetchSuccess || providerClassifiedAds?.loader ? (
              <LoaderCommon />
            ) : (
              <div className="listFresh">
                {providerClassifiedAds?.classifiedAdsReducer?.freshFinds?.freshFinds?.map(
                  (el: IAd, i: number) => (
                    <AdsItem
                      ads={el}
                      key={i}
                      setShowLogin={providerClassifiedAds?.setShowLogin}
                      goToCategory={(categoryId: string) =>
                        goToCategoryPage(categoryId)
                      }
                    />
                  )
                )}
                <div className="linkView">
                  {providerClassifiedAds?.viewMoreLoader && <LoaderCommon />}

                  {providerClassifiedAds?.showViewMore && (
                    <a
                      href="javascript:;"
                      className="viewAll"
                      onClick={providerClassifiedAds?.handleViewMore}
                    >
                      View more
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <LoginModal
        urlType={""}
        show={providerClassifiedAds?.showLogin}
        setShow={providerClassifiedAds?.setShowLogin}
      />
      <Footer />
    </div>
  );
}
