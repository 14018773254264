import React from "react";
import Select from "react-select";
import { CONTRIBUTOR, ISV, RESELLER } from "../../../../../../service/constant";

type IProps = {
  handleChangePostType: (e: any) => void;
  name: string;
  roleCurrentUser: string;
  roleUserShowInActivity?: string;
};
export default function SeachPostBy(props: IProps) {
  const showPrAndPreview =
    props.roleCurrentUser === ISV && props.roleUserShowInActivity === ISV;

  const options = showPrAndPreview
    ? [
        { value: "1", label: "View forum discussions only" },
        { value: "2", label: "View articles only" },
        { value: "3", label: "View press release only" },
        { value: "", label: `All posts by ${props.name}` },
        { value: "4", label: "View program reviews only" },
      ]
    : [
        { value: "1", label: "View forum discussions only" },
        { value: "2", label: "View articles only" },
      ];

  return (
    <Select
      onChange={(e: any) => props.handleChangePostType(e)}
      className="triOreder nameOfTitle"
      isClearable={false}
      options={options}
      classNamePrefix="tri"
      defaultValue={{
        value: "",
        label: `All posts by ${props?.name}`,
      }}
    />
  );
}
