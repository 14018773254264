import React from 'react';
import Header from '../../../widget/header-logo-center/header-logo-center';
import UseCreate from '../../../../controller/account-ctr/create-oem-account/complete-process-affilliated-account/use-info';
import { isEmail } from '../../../../utils';

export default function Index1() {
  const createCtr = UseCreate();

  return (
    <div>
      <Header />
      <form
        className='getstartedContainer'
        onSubmit={createCtr.handleSubmit(createCtr.onSubmit)}
      >
        <div className='head-getstarted'>
          <h1>Let's create your account.</h1>
          <span>Getting started with devinsider is fast and free.</span>
        </div>
        <div className='contentBorder border-top row pb-4'>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='first-name required'>
              First name
              {createCtr.errors?.first_name && (
                <div className='container-error pl-2 d-inline'>
                  {/* change d-inline on d-none */}
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
            </label>

            <input
              type='text'
              className='input-text form-control'
              {...createCtr?.register('first_name', {
                required: true,
              })}
            />
          </div>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='first-name required'>
              Last name{' '}
              {createCtr.errors?.last_name && (
                <div className='container-error pl-2 d-inline'>
                  {/* change d-inline on d-none */}
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
            </label>

            <input
              type='text'
              className='input-text form-control'
              {...createCtr.register('last_name', {
                required: true,
              })}
            />
          </div>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='mail required'>
              Email{' '}
              {createCtr.errors?.email?.type === 'required' && (
                <div className='container-error pl-2 d-inline'>
                  {/* change d-inline on d-none */}
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
              {createCtr.errors.email?.type === 'validate' && (
                <div className='container-error pl-2 d-inline'>
                  {/* change d-inline on d-none */}
                  <span className='error-red'> error </span>
                  <span className='txt-error'>Email address is not valide</span>
                </div>
              )}
            </label>

            <input
              type='text'
              className='input-text form-control'
              {...createCtr.register('email', {
                required: true,
                validate: (email: string) => isEmail(email),
              })}
              disabled={
                sessionStorage?.getItem('token_affiliated') ? true : false
              }
            />
          </div>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='password required'>
              Password
              {createCtr.errors?.password?.type === 'required' && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
              {createCtr.errors?.password?.type === 'minLength' && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>
                    password length must be 12 or more characters
                  </span>
                </div>
              )}
              {createCtr.errors?.password?.type == 'pattern' && (
                <div className='container-error pl-2 d-inline'>
                  {/* change d-inline on d-none */}
                  <span className='error-red'> error </span>
                  <span className='txt-error'>
                    password must contain at least one uppercase, lowercase,
                    special character and one number
                  </span>
                </div>
              )}
            </label>

            <input
              type='password'
              className='input-text form-control'
              {...createCtr.register('password', {
                required: true,
                minLength: 12,
                pattern:
                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{12,}$/,
              })}
            />
          </div>
        </div>
        <div className='contentBorder border-top row py-3 align-items-stretch'>
          <div className='form-group form-check col-xl-6 col-md-6 col-sm-12'>
            <input
              type='checkbox'
              className='form-check-input'
              {...createCtr.register('checked', { required: true })}
            />
            <label htmlFor='' className='form-check-label termsLink'>
              I agree to Devinsider’s&nbsp;
              <a onClick={() => window.open('/term-of-use', '_blank')}>
                terms
              </a>{' '}
              and &nbsp;
              <a onClick={() => window.open('/privacy-policy', '_blank')}>
                privacy policy
              </a>
              .
            </label>
            {createCtr.errors?.checked && (
              <div className='container-error pl-2 d-inline'>
                {/* change d-inline on d-none */}
                <span className='error-red'> error </span>
                <span className='txt-error'>This field is required</span>
              </div>
            )}
          </div>
          <div className='col-xl-6 col-md-6 col-sm-12 d-flex align-items-center pr-0'>
            <button className='btn btn-devinsider large containerBtnBtn'>
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
