import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getData, postData } from "../../../service/api";
import { url as URL } from "../../../service/constant";

export default function ContactSupportCtr() {
  const history = useHistory();
  const [topQuestions, setTopQuestions] = useState<Array<any>>([]);
  const [formGeneralQuestion, setFormGeneralQuestion] = useState<{
    data: {
      email: string;
      firstName: string;
      lastName: string;
      company: string;
      topic: string;
      message: string;
    };
    sendingQuestion: boolean;
  }>({
    data: {
      email: "",
      firstName: "",
      lastName: "",
      company: "",
      topic: "",
      message: "",
    },
    sendingQuestion: false,
  });
  const [sendingQuestion, setSendingQuestion] = useState<boolean>(false);

  const handleQuestionSelected = (categoryId: number, postId: number) => {
    history.push("/community/reply-post/" + categoryId + "/" + postId);
  };
  const handleGeneralQuestionSent = async () => {
    setSendingQuestion(true);
    let newData = { ...formGeneralQuestion };
    newData.sendingQuestion = true;
    setFormGeneralQuestion(newData);
    if (
      formGeneralQuestion.data.firstName === "" ||
      formGeneralQuestion.data.lastName === "" ||
      formGeneralQuestion.data.email === "" ||
      formGeneralQuestion.data.topic === "" ||
      formGeneralQuestion.data.message === "" ||
      formGeneralQuestion.data.company === ""
    ) {
      setSendingQuestion(false);
      setSendingQuestion(false);
      return;
    }
    //send data to the server
    try {
      const response = await postData(
        URL.community.contact_support_footer,
        formGeneralQuestion.data,
        ""
      );
      if (response?.status === 200) {
        toast("Question successfully sent!");
        setFormGeneralQuestion({
          data: {
            email: "",
            firstName: "",
            lastName: "",
            company: "",
            topic: "",
            message: "",
          },
          sendingQuestion: false,
        });
      }
    } catch (e: any) {
      // Bug #47401 Contact & support contact via email form does not work
      toast(
        "An error occurred while sending your message. Please try again later or contact us on contact@devinsider.com."
      );
    }
    setSendingQuestion(false);
    setSendingQuestion(false);
  };
  //redirect to community
  const goToCommunity = () => {
    history.push("/community/home");
  };
  //load data on start
  useEffect(() => {
    //get all data
    (async () => {
      const response = await getData(URL.community.top_questions, "");
      if (response?.status === 200) {
        setTopQuestions(response?.data);
      }
    })();
  }, []);
  return {
    topQuestions,
    handleQuestionSelected,
    goToCommunity,
    handleGeneralQuestionSent,
    formGeneralQuestion,
    setFormGeneralQuestion,
    sendingQuestion,
  };
}
