import UseIndustriesList from "../../../screen/widget/industries-list/use-IL";
import { useEffect, useState } from "react";
import {
  clearSessionObject,
  getSStorage,
  setSessionStorageObject,
} from "../../../utils";
import { useCompleteDataStorageOem } from "./use-complete-data-storage-oem";
import { createUserAndProgram } from "../../../service/applicatif/oem";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewOemProgram,
  signinAction,
} from "../../../redux/actions/acounts-action";
import { postWithoutTokenCombineUrl } from "../../../service/api";
import { url } from "../../../service/constant";
import { useHistory } from "react-router-dom";
import { rootState } from "../../../redux/reducers";
import { createProgramePage } from "../../../redux/actions/oem-program-action";
import { useDataContext } from "../../../context/index";

export default function useCompleteIndustryOem() {
  const { addDataOem, dataOem } = useCompleteDataStorageOem();
  const { setFilePictureOem, filePictureOem } = useDataContext();

  const history = useHistory();
  const [defaultValueSolution, setDefaultValueSolution] = useState<any>({
    defaultSelected: JSON.parse(
      getSStorage("solutionIndustriesCheckedOem") || "[]"
    ),
  });
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userState = useSelector((state: rootState) => state?.userReducer);

  const {
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    companyTargetSelected,
    lastCompanyTarget,
  } = UseIndustriesList(defaultValueSolution);

  /**
   * Save state and remove error
   */
  useEffect(() => {
    setHasError(false);
    setSessionStorageObject({
      solutionIndustriesCheckedOem: JSON.stringify(companyTargetSelected),
    });
    const targetedIndustry = companyTargetSelected.map((e: any) => e.id);
    addDataOem({ targetedIndustry: JSON.stringify(targetedIndustry) });
  }, [companyTargetSelected]);

  const cbCreateNewProgram = (result: any) => {
    if (result?.status === 200) {
      let oemToAdd = {
        role: "PROGRAM_OWNER",
        oem_program: {
          id: result?.data?.id,
          program_name: result?.data?.program_name,
          uuid: result?.data?.uuid,
          status: false,
        },
      };
      dispatch(addNewOemProgram(oemToAdd));
      clearSessionObject();
      history?.push("/OEM/profil/" + result?.data?.id);
    }
  };
  /**
   * Submit and save project
   */
  const onSubmit = async () => {
    if (companyTargetSelected?.length < 1) {
      setHasError(true);
      return;
    }
    setLoading(true);
    //Create new Program if user is connected
    const isConnectedOem = userState?.token !== "";

    if (isConnectedOem) {
      const {
        country,
        location,
        city,
        website,
        minAnnualTurnover,
        maxAnnualTurnover,
        minCompanySize,
        maxCompanySize,
        oemProgramType,
        programName,
        oemProgramTypeCategory,
        segmentDetails,
        segments,
        targetedCountry,
        targetedIndustry,
      } = dataOem;
      dispatch(
        createProgramePage(
          {
            country,
            location,
            city,
            website,
            minAnnualTurnover,
            maxAnnualTurnover,
            minCompanySize,
            maxCompanySize,
            oemProgramType,
            programName,
            oemProgramTypeCategory,
            segmentDetails,
            segments,
            targetedCountry,
            targetedIndustry,
            companyName: dataOem?.programName,
          },
          cbCreateNewProgram
        )
      );
    } else {
      const params = {
        fileName: filePictureOem ? filePictureOem : "",
        ...dataOem,
        //Bug #47394 Company name in OEM profile changes to "ISV Partner Program"
        //companyName: dataOem?.programName
      };
      const result = await createUserAndProgram(params);
      if (result?.status === 200) {
        await postWithoutTokenCombineUrl(
          url?.acount?.manualActivation,
          dataOem.email!
        );
        dispatch(
          signinAction(
            {
              email: dataOem.email?.trim(),
              password: dataOem.password?.trim(),
            },
            (result: any) => {
              if (result?.status === 200) {
                const id = result.data?.oemProgramMembers?.[0]?.oem_program.id;
                clearSessionObject();
                history?.push("/OEM/profil/" + id, { isSignup: true });
              }
            }
          )
        );
      } else {
        setLoading(false);
      }
    }
  };

  return {
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    companyTargetSelected,
    lastCompanyTarget,
    onSubmit,
    hasError,
    loading,
  };
}
