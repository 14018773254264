import React from "react";
import getTopLikedAuthor, {
  IpropsAuthor,
} from "../../../../../controller/community-ctr/home-page/top-liked-author";
import { fileUrl, refaCtoNullKey } from "../../../../../utils";
import { LoadingArticle } from "../../../../widget/loader";

const TopLikedAuthor = () => {
  const topLikedAuthor = { ...getTopLikedAuthor() };
  return (
    <>
      <h2 className="title">Top liked authors</h2>
      <ul className="separateElement">
        {topLikedAuthor?.loaderAuthor ? (
          <LoadingArticle />
        ) : (
          topLikedAuthor?.topLikedAuthor?.map((author: any, index: number) => (
            <li key={index}>
              <div
                className="leftItem itemAuthor"
                onClick={() =>
                  topLikedAuthor?.showActivityUser(
                    author?.user_to_like_id?.toString()
                  )
                }
              >
                <div className="imageAffiliatedcompany empty">
                  {author?.profil_picture && (
                    <img
                      className="imageAffiliatedcompany"
                      src={fileUrl(author?.profil_picture)}
                    />
                  )}
                </div>
                <div className="infoAffiliated">
                  <h3 className="title">{refaCtoNullKey(author?.user_name)}</h3>
                  <div className="post">
                    {refaCtoNullKey(author?.job_title)}
                  </div>
                </div>
              </div>
              <div className="rightItem likeContent">
                <span className="icon-like-full"></span>

                {refaCtoNullKey(author?.number_of_like)}
              </div>
            </li>
          ))
        )}

        {topLikedAuthor?.topLikedAuthor?.length > 0 && (
          <li
            className="linkForLi"
            onClick={() => topLikedAuthor?.viewAllAuthor()}
          >
            <a
              href="javascript"
              onClick={(e) => e?.preventDefault()}
              className="viewAll"
            >
              View all
            </a>
          </li>
        )}
      </ul>
    </>
  );
};

export default TopLikedAuthor;
