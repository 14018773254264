import { IOemProgram } from "./type-oem";
import { useEffect, useState } from "react";
import { getSStorage, setSessionStorageObject } from "../../../utils";

export function useCompleteDataStorageOem() {
  const _dataName = "oemProject";
  const [dataOem, setDataOem] = useState<IOemProgram>(
    JSON.parse(getSStorage(_dataName) || "{}")
  );

  useEffect(() => {
    if (!getSStorage(_dataName)) {
      setSessionStorageObject({
        [_dataName]: JSON.stringify({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          jobTitle: "",
          companyName: "",
          country: 0,
          labels: "[]",
          "--": "--",
          _country: 0,
          _companyName: "",
          location: "",
          city: "",
          website: "",
          minAnnualTurnover: 0,
          maxAnnualTurnover: 0,
          minCompanySize: 0,
          maxCompanySize: 0,
          oemProgramType: 0,
          programName: "",
          oemProgramTypeCategory: "[]",
          segmentDetails: "[]",
          segments: "[]",
          targetedCountry: "[]",
          targetedIndustry: "[]",
        } as IOemProgram),
      });
    }
    setDataOem(JSON.parse(getSStorage(_dataName) || "{}"));
  }, []);

  /**
   * Add data in object
   * @param {IOemProgram} data
   */
  const addDataOem = (data: IOemProgram): void => {
    const saved = JSON.parse(getSStorage(_dataName) || "{}");
    const newObject = { ...saved, ...data };
    setSessionStorageObject({ [_dataName]: JSON.stringify(newObject) });
    setDataOem(newObject);
  };

  const verifyData = (): boolean => {
    return true;
  };
  return { dataOem, addDataOem, verifyData };
}
