/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from 'react';
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from '../../../../widget/header-logo-center/header-logo-center';
import { BtnNext } from '../../../../widget/bouton/btn-next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import UseCompleteCompanyInfoReseller from '../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-company-info-reseller';
import {
  checkIfExist,
  getSStorage,
  isUrlOptionnelValue,
} from '../../../../../utils';
import { Redirect } from 'react-router-dom';
export default function CompleteCompanyReseller() {
  const {
    goBack,
    mockRegion,
    register,
    handleSubmit,
    errors,
    getValues,
    watch,
    dataCompleted,
    onSubmitForm,
  } = UseCompleteCompanyInfoReseller();

  const sess = getSStorage('emailReseller');

  if (!checkIfExist(sess)) {
    return <Redirect to='/complete-info-reseller' />;
  }

  return (
    <div>
      <Header />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className='getstartedContainer'>
          <div className='head-getstarted'>
            <div className='stepInscription'>
              <div className='stepItem done'></div>
              <div className='stepItem done'></div>
              <div className='stepItem done'></div>
              <div className='stepItem done'></div>
              <div className='stepItem'></div>
              <div className='stepItem'></div>
              <div className='stepItem'></div>
              <div className='stepItem'></div>
              <div className='stepItem'></div>
              <div className='stepItem'></div>
            </div>
            <a className='link-back' onClick={() => goBack()}>
              <span className='icon-back'></span>
            </a>
            <h1>Create your account.</h1>
          </div>
          <div className='contentBorder border-top row pb-4'>
            <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2 p-0'>
              <div className='aideContent'>
                <label htmlFor=''>
                  Company "Trade" Name
                  {errors.companyTradeName && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}
                </label>
                <OverlayTrigger
                  overlay={
                    <Tooltip id='trade'>
                      Please enter your company’s trade name, or the name
                      commonly used for public reference (commonly exludes
                      “Inc.”, “LLC”, etc…)
                    </Tooltip>
                  }
                >
                  <span className='icon-aide'></span>
                </OverlayTrigger>
              </div>
              <input
                type='text'
                className='form-control'
                placeholder={'Company trade Name'}
                {...register('companyTradeName', {
                  required: true,
                })}
              />
            </div>
            <div className='required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2'>
              <div className='aideContent'>
                <label htmlFor=''>
                  Company "Legal" Name ?{' '}
                  {errors.companyTradeLegalName && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}
                </label>
                <OverlayTrigger
                  overlay={
                    <Tooltip id='legal'>
                      Please enter your company’s legal name (commonly ending in
                      “Inc”, “LLC”, etc…) This is the name that is registered
                      with your state of incorporation/registration.
                    </Tooltip>
                  }
                >
                  <span className='icon-aide'></span>
                </OverlayTrigger>
              </div>
              <input
                type='text'
                className='form-control'
                placeholder={'Company legal Name'}
                {...register('companyTradeLegalName', {
                  required: true,
                })}
              />
            </div>
            <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2 p-0'>
              <label htmlFor=''>
                Company Location
                {errors.companyLocation && (
                  <div className='container-error pl-2 d-inline'>
                    {/* change d-inline on d-none */}
                    <span className='error-red'> error </span>
                    <span className='txt-error'>This field is required</span>
                  </div>
                )}
              </label>

              <select
                className='custom-select'
                {...register('companyLocation', {
                  required: true,
                })}
              >
                <option value=''>Country</option>
                {dataCompleted?.country?.map((element: any, index: number) => (
                  <option
                    value={element.id}
                    selected={
                      element.id.toString() ==
                      sessionStorage.getItem('companyLocation')
                    }
                    key={index}
                  >
                    {`${element.country_name}`}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2'>
              <label htmlFor=''>&nbsp;</label>
              <input
                type='text'
                className='form-control'
                placeholder={'City'}
                {...register('companyCity', {
                  required: false,
                })}
              />
            </div>
            <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2 p-0'>
              <label htmlFor=''>
                Website
                {errors.website?.type === 'validate' && (
                  <div className='container-error pl-2 d-inline'>
                    {/* change d-inline on d-none */}
                    <span className='error-red'> error </span>
                    <span className='txt-error'>Url is not valide</span>
                  </div>
                )}
                {errors.website?.type == 'required' && (
                  <div className='container-error pl-2 d-inline'>
                    {/* change d-inline on d-none */}
                    <span className='error-red'> error </span>
                    <span className='txt-error'>This field is required</span>
                  </div>
                )}
              </label>
              <input
                type='text'
                className='form-control'
                placeholder={'Begin with http:// or https:// or www'}
                {...register('website', {
                  validate: (url: string) => isUrlOptionnelValue(url),
                  required: true,
                })}
              />
            </div>
          </div>
          <div className='contentBorder border-top row align-items-stretch'>
            <button
              className='btn btn-devinsider large containerBtnBtn'
              type='submit'
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
