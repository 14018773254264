import React from "react";
import { UpcomingEvent } from "../../../../interface/isv";
import { formatHourIsvUpcomingEvent, limitedText } from "../../../../utils";

interface IUpcommingEventProps {
  data: Array<UpcomingEvent>;
  viewAllEvent: () => void;
  navigateSelectedEvent: (id: number) => void;
}

export default function UpcommingEvent(props: IUpcommingEventProps) {
  const { data } = props;
  return ( null
    /*Miaro <div className="block allblock">
      <h3 className="title">Upcoming events</h3>
      <ul className="calendar">
        {data?.length > 0 &&
          data?.map((e: UpcomingEvent, index: number) => (
            <li className="item" key={index}>
              <div
                className="date"
                onClick={() => props.navigateSelectedEvent(e.id)}
              >
                <div className="cal">
                  <span className="month">{e.at_date.split(" ")[0]}</span>
                  <span className="day">{e.at_date.split(" ")[1]}</span>
                </div>
                {limitedText(e.title, 50)}
              </div>
              <div className="infoEvent">
                <div className="dateEvent">
                  {formatHourIsvUpcomingEvent(e.at_time)}
                </div>
                <div className="placeEvent">{e.event_type}</div>
              </div>
            </li>
          ))}
      </ul>
      <a
        href="javascript:;"
        className="seeAll"
        onClick={() => props?.viewAllEvent()}
      >
        See all
        <span className="icon-back" />
      </a>
    </div>*/
  );
}
