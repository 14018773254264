import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
export default function UseGetStartedReseller() {
  const history = useHistory();
  const userState = useSelector((state: rootState) => state.userReducer);
  const createAccountReseller = () => {
    history.push("/complete-info-reseller");
  };
  const joinTheConversation = () => {
    return history.push("/community/home");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return { userState, createAccountReseller, joinTheConversation };
}
