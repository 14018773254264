import React from "react";
//Type formulaire for edit user

export default function modalConfirm() {
  return (
    <div className="bodyCreateCompany">
      <div className="contentBorder row">You will lose your progress </div>
    </div>
  );
}
