import React, { ChangeEvent } from "react";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import UseCompleteInfoGuest from "../../../../../controller/account-ctr/complete-create-account-guest/use-complete-info-guest";
import ReactFlagsSelect from "react-flags-select";
import { contact } from "../../../../../utils/country_phone";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import CheckIcon from "@material-ui/icons/Check";
import CreatableSelect from "react-select/creatable";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isEmail } from "../../../../../utils";
import Alert from "react-bootstrap/Alert";
import { useHistory } from "react-router-dom";

export default function CompleteInfoGuest() {
  const history = useHistory();
  const {
    selected,
    loader,
    onSubmit,
    register,
    handleSubmit,
    errors,
    setSelected,
    dataCompleted,
    checkIfAlreadyEmailExist,
    emailNotSafe,
    txtEmailError,
    getValues,
    isErrorUserType,
    haveError,
    setUserTypeGuest,
    dialCodeSelected,
    setDialCodeSelected
  } = UseCompleteInfoGuest();

  const handleSelect = (countryCode: any) => {
    setSelected(countryCode);
    const dialCode = contact?.find(
      (el: any) => el.code == countryCode
    )?.dial_code;
    setDialCodeSelected(dialCode ? dialCode : "");
  };

  return (
    <div>
      <Header />
      <form className="getstartedContainer" onSubmit={handleSubmit(onSubmit)}>
        <div className="getstartedContainer">
          <div className="head-getstarted">
            <h1>Create your account.</h1>
            {haveError && <Alert variant="danger">An error occurred</Alert>}
          </div>
          <div className="contentBorder border-top row pb-4">
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label htmlFor="first-name required">
                First name
                {errors?.firstNameGuest && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...register("firstNameGuest", {
                  required: true,
                })}
              />
            </div>
            <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label htmlFor="last-name">
                Last name
                {errors?.lastNameGuest && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...register("lastNameGuest", {
                  required: true,
                })}
              />
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label htmlFor="first-name required">
                Job title
                {errors?.jobTitleGuest && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...register("jobTitleGuest", {
                  required: true,
                })}
              />
            </div>
            <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label htmlFor="organisation required">
                Phone number
                {errors?.phoneNumberGuest?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                {errors.phoneNumberGuest?.type === "validate" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">Phone number is not valid</span>
                  </div>
                )}
              </label>
              <div className="numberphone" style={{ position: "relative" }}>
                <input
                  type="number"
                  className="phonenumber"
                  style={{
                    textIndent:
                      dialCodeSelected?.length == 2
                        ? 35
                        : dialCodeSelected?.length == 3
                        ? 40
                        : dialCodeSelected?.length == 4
                        ? 50
                        : 60,
                    paddingBottom: 5,
                  }}
                  {...register("phoneNumberGuest", {
                    required: true,
                    validate: (phone: any) =>
                      isValidPhoneNumber(dialCodeSelected + phone?.toString()),
                  })}
                />
                <span style={{ position: "absolute", top: 5, left: 60 }}>
                  ({dialCodeSelected})
                </span>
                <ReactFlagsSelect
                  className="selectFlag"
                  selected={selected}
                  onSelect={handleSelect}
                  searchable={true}
                  showSelectedLabel={false}
                  placeholder=" "
                />
              </div>
            </div>
            {/* // Dev #47822 V1 [Guest] remove the field "user type" */}
            {/* <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label htmlFor="usertype required">
                User type
                {isErrorUserType && (
                  <div className="container-error pl-2 d-inline">
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <CreatableSelect
                isClearable
                options={dataCompleted?.guestUserType?.map(
                  (el: { id: number; type: string }) => {
                    return {
                      value: el?.id,
                      label: el?.type,
                    };
                  }
                )}
                onChange={(evt: any) => {
                  setUserTypeGuest(evt);
                }}
              />
            </div> */}
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label htmlFor="companyname">Company name</label>
              <input
                type="text"
                className="input-text form-control"
                {...register("companyNameGuest", {
                  required: true,
                })}
              />
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label htmlFor="website ">Website</label>
              <input
                type="text"
                className="input-text form-control"
                {...register("webSiteGuest", {})}
              />
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label htmlFor="hqlocation">
                HQ location
                {errors?.hqLocationGuest && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <select
                id=""
                className="custom-select"
                {...register("hqLocationGuest", {
                  required: true,
                })}
              >
                <option value="">Country...</option>
                {dataCompleted?.country?.map((el: any) => (
                  <option value={el.id} key={el.id}>
                    {el.country_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="contentBorder border-top  row py-3">
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              {emailNotSafe === "false" && (
                <CheckIcon className="mailcheckOK" />
              )}
              <label htmlFor="businessemail">
                Business email
                {errors?.businessEmailGuest?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                {errors.businessEmailGuest?.type === "validate" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      Email address is not valide
                    </span>
                  </div>
                )}
                {emailNotSafe === "true" && (
                  <div className="container-error d-inline pl-3">
                    <span className="error-red"> error </span>
                    <span className="txt-error">{txtEmailError}</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...register("businessEmailGuest", {
                  required: true,
                  validate: (email: string) => isEmail(email),
                })}
                onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                  checkIfAlreadyEmailExist(e.target.value);
                }}
              />
            </div>
            <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label htmlFor="password">
                Password (12 or more characters)
                {errors?.passwordGuest?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                {errors.passwordGuest?.type == "minLength" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      password length must be 12 or more characters
                    </span>
                  </div>
                )}
                {errors?.passwordGuest?.type == "pattern" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      password must contain at least one uppercase, lowercase,
                      special character and one number
                    </span>
                  </div>
                )}
              </label>
              <input
                type="password"
                className="input-text form-control"
                {...register("passwordGuest", {
                  required: true,
                  minLength: 12,
                  pattern:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{12,}$/,
                })}
              />
            </div>
          </div>
          <div className="contentBorder border-top row py-3 align-items-stretch">
            <div className="form-group form-check col-xl-6 col-md-6 col-sm-12">
              <input
                type="checkbox"
                className="form-check-input"
                {...register("acceptPrivacyTerms", {
                  required: true,
                })}
              />
              <label htmlFor="" className="form-check-label termsLink">
                I agree to Devinsider’s&nbsp;
                <a onClick={() => window.open("/term-of-use", "_blank")}>
                  terms
                </a>{" "}
                and &nbsp;
                <a onClick={() => window.open("/privacy-policy", "_blank")}>
                  privacy policy
                </a>
                .
              </label>

              {errors?.acceptPrivacyTerms && (
                <div className="container-error pl-2 d-inline">
                  {/* change d-inline on d-none */}
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 d-flex align-items-center pr-0">
              <button
                className="btn btn-devinsider large containerBtnBtn"
                type="submit"
              >
                {loader ? <Loader /> : "Create a guest account"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
