import React from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../redux/reducers';
import { ISV } from '../service/constant';
import { Redirect } from 'react-router-dom';

export default function MatchingInvestor(props: any) {
  const currentUser = useSelector((state: rootState) => state.userReducer);

  if (!currentUser.roles.includes(ISV)) {
    return <Redirect to={'/profil-not-found'} />;
  }

  return <div>{props.children}</div>;
}
