import React, { useState, useEffect } from "react";
import store from "../../../redux/store";
import { postJsonDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import { useHistory } from "react-router-dom";
import UseSearch from "../../../screen/widget/community/search/use-search";

import { rootState } from "../../../redux/reducers";
import { useSelector } from "react-redux";

export type ItemUnanswered = {
  id: string;
  title: string;
  numberOfLikes: string;
  numberOfViews: string;
  labels: Array<{ id: number; title: string }>;
  created_at: string;
  updated_at: string;
  solve: boolean;
  category: { id: number; title: string; slug: string };
  user: {
    id: number;
    email: string;
    roles: Array<string>;
    username: string;
    last_name: string;
    first_name: string;
    file_name: string;
  };
  post_type: {
    id: number;
    type: string;
  };
  last_activity_by: {
    last_name: string;
    first_name: string;
  };
};

export default function UseViewAllUnanswered() {
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [loader, setloader] = useState<boolean>(false);
  const [allData, setallData] = useState<Array<ItemUnanswered>>([]);
  const [dataLength, setDataLength] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [orderBy, setorderBy] = useState<"DESC" | "ASC">("DESC");
  const [sortedBy, setsortedBy] = useState<
    "post_date" | "first_activity" | "last_activity" | "replies" | "views"
  >("post_date");
  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();
  const history = useHistory();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const getUnanswered = async (
    page: number,
    limit: number,
    orderBy: string,
    sortBy: string
  ) => {
    let params = {
      page,
      limit,
      orderBy,
      sortBy,
    };
    setloader(true);
    const response = await postJsonDataService(
      url?.community?.get_all_unanswered,
      params,
      store?.getState().userReducer?.token
    );
    if (response?.status === 200) {
      setallData(response?.data?.result);
      setDataLength(response?.data?.total_result_to_paginate);
      setloader(false);
    }
  };

  useEffect(() => {
    getUnanswered(currentPage, limit, orderBy, sortedBy);
    return () => {};
  }, []);

  const setPaginateDataFilter = (params: any) => {
    getUnanswered(currentPage, params, orderBy, sortedBy);
    setLimit(params);
  };

  const handlePageClick = (page: number) => {
    getUnanswered(page, limit, orderBy, sortedBy);
    setcurrentPage(page);
  };

  const setSortedate = (p: any) => {
    setLimit(10);
    setsortedBy(p);
    getUnanswered(currentPage, 10, orderBy, p);
  };

  const showActivityUser = (idUser: string) => {
    history.push("/community/activity-user/" + idUser);
  };

  const goToLabelCategory = (
    idCategory: any,
    labelId: string,
    postType: string
  ) => {
    postType === "1" &&
      history.push("/community/one-category/" + idCategory + "/" + labelId);

    postType === "2" &&
      history.push("/community/article-category/" + idCategory + "/" + labelId);
  };

  const goToreplayPost = (idCategorie: string, postId: string) => {
    history.push("/community/reply-post/" + idCategorie + "/" + postId);
  };

  return {
    allData,
    dataLength,
    loader,
    limit,
    setPaginateDataFilter,
    currentPage,
    handlePageClick,
    sortedBy,
    setSortedate,
    showActivityUser,
    goToreplayPost,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    communityReducer,
    goToLabelCategory,
  };
}
