import React from "react";

import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

export type Iprops = {
  setTabNavigate: (p: string) => void;
  setEditProfilCompany: () => void;
  setShowManageCompanyPage: () => void;
  isAdmin: boolean;
  isOtherUser: boolean;
};
export default function index(props: Iprops) {
  return (
    <div
      className={`${
        props.isOtherUser ? "d-none menuCompanyTab" : "menuCompanyTab"
      }`}
    >
      <Nav variant="pills">
        <Nav.Item>
          <Nav.Link
            eventKey="overview"
            onClick={() => props?.setTabNavigate("overview")}
          >
            Overview
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="strategy"
            onClick={() => props?.setTabNavigate("strategy")}
          >
            Strategy
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="financial"
            onClick={() => props?.setTabNavigate("financial")}
          >
            Financial
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="Pitch"
            onClick={() => props?.setTabNavigate("pitch")}
          >
            Pitch
          </Nav.Link>
        </Nav.Item>
        <div className="doneEditing">
          <Nav.Item className="editBtnNav">
            <Nav.Link onClick={() => props?.setEditProfilCompany()}>
              Done editing
            </Nav.Link>
          </Nav.Item>
        </div>
        {props?.isAdmin && (
          <div className="onMyprofil">
            <Nav.Item className="editBtnNav">
              <Nav.Link onClick={() => props?.setEditProfilCompany()}>
                <span className="icon-edit"></span>
                Edit company page
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="editBtnNav">
              <Nav.Link onClick={() => props?.setShowManageCompanyPage()}>
                Manage
              </Nav.Link>
            </Nav.Item>
          </div>
        )}
      </Nav>
    </div>
  );
}
