/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { updateCompanyPageAction } from "../../../../redux/actions/company-page-isv-action/current_isv_action";
import * as T from "../../types/types-company-page";

export default function UseFinancialCtr() {
  const dispatch = useDispatch();

  let companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const [loaderFinancial, setLoaderFinancial] = useState<boolean>(false);
  const [loaderChart, setLoaderChart] = useState<boolean>(false);

  const [updateChartForm, setUpdateChartForm] = useState<Array<T.ItemsGraph>>(
    []
  );

  const [isShowFinancialAnnual, setIsShowFinancialAnnual] =
    useState<boolean>(false);

  const [showFinancialChart, setShowFinancialChart] = useState<boolean>(false);

  const [annualTurnOverSelected, setAnnualTurnOverSelected] = useState<string>(
    JSON.stringify({
      [companyPageObject?.annual_turnover]: {
        min: companyPageObject?.annual_turnover_min,
        max: companyPageObject?.annual_turnover_max,
      },
    }) || ""
  );

  const [
    isShowModalFundingRoundAndAmount,
    setIsShowModalFundingRoundAndAmount,
  ] = useState<boolean>(false);

  const [fundingRoundValue, setFundingRoundValue] = useState<string>("");

  const [amountWouldRiseSelected, setAmountWouldRiseSelected] = useState(
    JSON.stringify({
      [companyPageObject?.amount_want_to_raise]: {
        min: companyPageObject?.amount_to_raise_min,
        max: companyPageObject?.amount_to_raise_max,
      },
    }) || ""
  );

  const setAnnulTurnover = (params: string) => {
    setAnnualTurnOverSelected(params);
  };

  const cbUpdateAnnualTurnOver = (response: any) => {
    if (response && response.status === 200) {
      setLoaderFinancial(false);
      if (isShowFinancialAnnual) {
        setIsShowFinancialAnnual(false);
      }
      if (isShowModalFundingRoundAndAmount) {
        setIsShowModalFundingRoundAndAmount(false);
      }
    }
  };

  const updateAnnualTurnOver = () => {
    const isNullAmount = amountWouldRiseSelected === "Select an option";
    if (annualTurnOverSelected && !isNullAmount && fundingRoundValue) {
      setLoaderFinancial(true);
      let annualTurnoverTemp: any = Object.values(
        JSON.parse(annualTurnOverSelected)
      )[0];
      let tempAmount = JSON.parse(amountWouldRiseSelected || "{}");
      let objectTemp: any = Object.values(tempAmount)[0];
      const dataToSend = {
        annualTurnover: Object.keys(JSON.parse(annualTurnOverSelected))[0],
        annualTurnoverMin: annualTurnoverTemp?.min || "",
        annualTurnoverMax: annualTurnoverTemp?.max || "",
        amountWantToRaise: Object.keys(tempAmount)[0],
        amountToRaiseMin: objectTemp?.min,
        amountToRaiseMax: objectTemp?.max,
        fundingRound: fundingRoundValue,
      };
      dispatch(
        updateCompanyPageAction(
          dataToSend,
          companyPageObject.id,
          cbUpdateAnnualTurnOver
        )
      );
    }
  };

  const cbUpdateChart = (response: any) => {
    if (response && response?.status === 200) {
      const { companyPageGraphs } = response?.data;
      setUpdateChartForm(companyPageGraphs);
      setLoaderChart(false);
      setShowFinancialChart(false);
    }
  };

  const updateGrapheData = () => {
    setLoaderChart(true);
    let dataMake = updateChartForm?.map((el: T.ItemsGraph) => {
      return {
        year: el?.year,
        company_page: companyPageObject?.id,
        amount: el?.amount,
      };
    });
    let params = {
      companyPageGraphs: JSON.stringify(dataMake),
    };
    dispatch(
      updateCompanyPageAction(
        params,
        companyPageObject.id,
        cbUpdateChart,
        "companyPageGraphs"
      )
    );
  };

  const cancelUpdateChart = () => {
    setUpdateChartForm(companyPageObject?.companyPageGraphs);
    setShowFinancialChart(false);
  };

  useEffect(() => {
    setAnnualTurnOverSelected(
      JSON.stringify({
        [companyPageObject?.annual_turnover]: {
          min: companyPageObject?.annual_turnover_min,
          max: companyPageObject?.annual_turnover_max,
        },
      }) || ""
    );
    setUpdateChartForm(companyPageObject?.companyPageGraphs);
    setFundingRoundValue(companyPageObject?.funding_round?.id?.toString());
    setAmountWouldRiseSelected(
      JSON.stringify({
        [companyPageObject?.amount_want_to_raise]: {
          min: companyPageObject?.amount_to_raise_min,
          max: companyPageObject?.amount_to_raise_max,
        },
      }) || ""
    );
    return () => {};
  }, [companyPageObject]);

  return {
    isShowFinancialAnnual,
    setIsShowFinancialAnnual,
    isShowModalFundingRoundAndAmount,
    setIsShowModalFundingRoundAndAmount,
    annualTurnOverSelected,
    setAnnulTurnover,
    updateAnnualTurnOver,
    loaderFinancial,
    fundingRoundValue,
    setFundingRoundValue,
    setAmountWouldRiseSelected,
    amountWouldRiseSelected,
    showFinancialChart,
    setShowFinancialChart,
    updateChartForm,
    setUpdateChartForm,
    updateGrapheData,
    loaderChart,
    cancelUpdateChart,
  };
}
