import { Dispatch } from "../../types/type-action";
import {
  getMyFeed,
  getSubscribedDiscussion,
  getUpcomingEvent,
} from "../../../service/applicatif/isv";
import store from "../../store";
import { postJsonDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";

export const SET_UPCOMING_EVENT = "SET_UPCOMING_EVENT";
export const SET_SUBSCRIBED_DISCUSSION = "SET_SUBSCRIBED_DISCUSSION";
export const SET_MY_FEED = "SET_MY_FEED";
export const APPEND_MY_FEED = "APPEND_MY_FEED";
export const SET_MY_FEED_OFFSET = "SET_MY_FEED_OFFSET";
export const ADD_FAVORY = "ADD_FAVORY";
export const LIKE_POST = "LIKE_POST";

export const getUpcomingEventAction = () => {
  return async (dispatch: Dispatch) => {
    const res = await getUpcomingEvent(store.getState().userReducer.token);
    if (res?.status === 200) {
      dispatch({ type: SET_UPCOMING_EVENT, payload: res?.data });
    }
  };
};

export const getSubscribeDiscussionAction = (limit: number) => {
  return async (dispatch: Dispatch) => {
    const res = await getSubscribedDiscussion(
      limit,
      store.getState().userReducer.token
    );

    if (res?.status === 200) {
      dispatch({ type: SET_SUBSCRIBED_DISCUSSION, payload: res?.data });
    }
  };
};

/**
 * Use by useReducer not redux
 *
 * @param dispatch
 * @param {() => void} callback
 * @returns {Promise<boolean>}
 */
export const getMyFeedWithoutOffset = async (
  dispatch: any,
  callback: () => void
) => {
  const res = await getMyFeed(0, 4, store.getState().userReducer.token);
  const result = {
    feeds: { news: res?.data.news, feeds: res?.data.results },
    count: res?.data.total_result_to_paginate,
  };

  if (res?.data.results.length < 1) {
    callback();
    return true;
  }

  dispatch({ type: SET_MY_FEED, payload: result });
  dispatch({ type: SET_MY_FEED_OFFSET, payload: 5 });
};

export const markAsFavoriteAction = async (
  idParent: any,
  callback: (p: any) => void,
  dispatch: any
) => {
  try {
    let response = await postJsonDataService(
      url?.community?.favorite?.add_favorite + idParent,
      {},
      store?.getState()?.userReducer?.token
    );
    if (response?.status === 200) {
      dispatch({
        type: ADD_FAVORY,
        payload: idParent,
      });
    }
    callback(response);
  } catch (error: any) {}
};

export const likePost = async (
  idParent: any,
  callback: (p: any) => void,
  dispatch: any
) => {
  try {
    const params = {
      post: idParent,
    };
    let response = await postJsonDataService(
      url?.community?.like_post,
      params,
      store?.getState()?.userReducer?.token
    );
    if (response?.status === 200) {
      dispatch({
        type: LIKE_POST,
        payload: {
          idPost: idParent,
          numberLike: response?.data?.number_likes,
        },
      });
      callback(response);
    }
  } catch (error: any) {}
};

/**
 * Use by useReducer not redux
 *
 * @param {number} limit
 * @param {() => void} callback
 * @param dispatch
 * @param offset
 * @returns {(dispatch: Dispatch) => Promise<boolean>}
 */
export const getMyFeedWithOffset = async (
  limit: number,
  dispatch: any,
  offset: number,
  callback: () => void
) => {
  const nextOffset = offset + limit;

  const res = await getMyFeed(
    offset,
    limit,
    store.getState().userReducer.token
  );

  if (res?.data.results.length < 1) {
    callback();
    return true;
  }

  const result = {
    news: res?.data.news,
    feeds: res?.data.results,
  };

  dispatch({ type: APPEND_MY_FEED, payload: result });
  dispatch({ type: SET_MY_FEED_OFFSET, payload: nextOffset });
};
