import React, { useEffect, useState } from "react";
import { getUserActivity } from "../../../service/applicatif/acount";
import store from "../../../redux/store";
import { url } from "../../../service/constant";
import { useHistory, useParams } from "react-router";

export default function LikesFrom(userId: number) {
  const history = useHistory();
  const params: any = useParams();
  const [likesFrom, setLikesFrom] = useState<Array<any>>([]);

  const getLIkeFrom = async (userId: number) => {
    const response = await getUserActivity(
      url.community.activity.get_likes_from,
      userId,
      store.getState().userReducer.token
    );
    if (response?.status === 200) {
      setLikesFrom(response?.data);
    }
  };

  useEffect(() => {
    //get user contribution
    getLIkeFrom(userId);
  }, [userId]);

  const goToLikeFromViewAll = () => {
    history.push("/community/activity-user/like", {
      type: "like_from",
      userId: params?.id,
    });
  };

  return {
    userId,
    likesFrom,
    goToLikeFromViewAll,
  };
}
