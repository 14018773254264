import React from "react";
import { fileUrl } from "../../../../../../utils";

type IpropsInvestorIsvHeader = {
  profil: string;
  userName: string;
  showProfil: () => void;
};
export default function CommunityHeader(props: IpropsInvestorIsvHeader) {
  return (
    <>
      <div className="titleRepH">Community</div>
      <div className="d-flex mr-5">
        <div className="photo-title">
          <img src={fileUrl(props.profil)} alt="" />
        </div>
        <div className="info-title">
          <div className="company-title" onClick={() => props.showProfil()}>
            <a href="javascript:void(0)">{props?.userName}</a>
          </div>
        </div>
      </div>
    </>
  );
}
