import React, { useEffect, useState } from "react";
import {
  checkIfExist,
  concatString,
  refaCtoNullKey,
  uppercaseFirstLetter,
} from "../../../../../../utils";
import {contact} from "../../../../../../utils/country_phone";

const ProfilBodyInvestorLeft = (props: any) => {
  const [dialCode,setDialCode] = useState<string|null>(null);
  useEffect(()=>{
    if (props?.userState?.phone_number_code !== "" && props?.userState?.phone_number_code !== null){
      setDialCode(contact.filter(element => element.code === props?.userState?.phone_number_code)[0].dial_code);
    }
  },[]);
  return (
    <>
      {/* TEXT DESCRIPTION */}
      <div className="col-12 infoInvestoronly">
        <span className="icon-locked"></span>
        Only ISVs you have contacted can view this information. Your personal
        details will never be published publicly.
      </div>
      {/* LEFT SIDE */}
      <div className="col-xl-7 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <div className="contactInfoItem">
          <h2 className="title">About</h2>
          {checkIfExist(props?.userState?.plain_text) ? (
            props?.userState?.plain_text
          ) : (
            <div className="fauxText">
              <div className="l100"></div>
              <div className="l100"></div>
              <div className="l100"></div>
              <div className="l50"></div>
            </div>
          )}
        </div>
      </div>
      {/* BODY RIGHT SIDE TODO */}
      <div className="col-xl-5 col-lg-6 col-md-12 col-12 pl-xl-4 companyInfo">
        <div className="contactInfoItem">
          <h2 className="title">Contact information</h2>
          <ul className="ulListInfo">
            <li>
              <div className="label">Email</div>
              <div className="infoLabel">{props?.userState?.email}</div>
            </li>
            <li>
              <div className="label">Linkedin</div>
              <div className="infoLabel">
              <a
                  target="_blank"
                  href={
                    "https://www.linkedin.com/in/" +
                    refaCtoNullKey(props?.userState?.linkedin, "-")
                  }
                >
                  {  refaCtoNullKey(props?.userState?.linkedin, "-").length > 14 ? refaCtoNullKey(props?.userState?.linkedin, "-").substring(0,15) + "..." : refaCtoNullKey(props?.userState?.linkedin, "-") }
                </a>
              </div>
            </li>
            <li>
              <div className="label">Phone number</div>
              <div className="infoLabel">
              {dialCode === "" ? <></> : <>{"("+ dialCode + ") "}</>}
                {refaCtoNullKey(props?.userState?.phone, "-")}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProfilBodyInvestorLeft;
