import React from "react";
import DefaultCompany from "../../../../../../resources/images/defaultCompany.png";
import { fileUrl } from "../../../../../../utils";

const CompanyCardExpert = (props: any) => {
  return (
    <div className="contentISV">
      <h2 className="title">Expert contributor</h2>
      <ul className="svList">
        <li>
          <div className="imgSv">
            <img
              src={
                props?.userState?.expertCompany
                  ? fileUrl(props?.userState?.expertCompany?.logo)
                  : DefaultCompany
              }
              alt="Software vendor logo"
            />
          </div>
          <div className="infoSV">
            <>
              <h3 className="title">{props?.userState?.company_name}</h3>
            </>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CompanyCardExpert;
