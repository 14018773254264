import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useEventListener from "../../../hooks/event-handler";

export default function UseCompleteCompanyKeyCompetitive() {
  const history = useHistory();
  const [keyCompetitiveValue, setKeyCompetitiveValue] = useState<string>(
    sessionStorage.getItem("company_key_competitive")
      ? sessionStorage.getItem("company_key_competitive") || ""
      : ""
  );

  const navigateToCompleteLogo = (skip: boolean = false) => {
    if (!skip) {
      sessionStorage.setItem("company_key_competitive", keyCompetitiveValue);
    }
    history.push("/complete-company-logo-reseller");
  };

  const goBack = () => {
    history.goBack();
  };
  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateToCompleteLogo();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    navigateToCompleteLogo,
    keyCompetitiveValue,
    setKeyCompetitiveValue,
    goBack,
  };
}
