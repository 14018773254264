import { IAction } from "../../types/type-action";
import { IcompanyPageReseller } from "../../../interface/company-reseller";
import * as CONSTANTE from "../../actions/company-page-reseller/index";

const initialState: IcompanyPageReseller = {
  id: "0",
  company_trade_name: "",
  company_legal_name: "",
  country: {
    id: 0,
    country_name: "",
  },
  city: "",
  website: "",
  overview: "",
  key_competitive: "",
  photo_profil: "",
  resellerCompanyPostClassifiedAds: {
    id: 0,
    number_left: 0,
    next_grant_at: "",
    amount: 0,
    ads_free_number: 0,
  },
  validate_by_devinsider: true,
  segmentDetails: [],
  targetedIndustry: [],
  targetedCountry: [],
  payment_method: "",
};

export default function resellerCompanyReducer(
  state = initialState,
  action: IAction
) {
  switch (action.type) {
    case CONSTANTE.GET_RESELLER_COMPANY:
      return { ...state, ...action.payload };
    case CONSTANTE.GET_RESELLER_ATTRIBUT:
      return {
        ...state,
        [action.payload.attribute]: action.payload.data,
      };
    case CONSTANTE.REGISTER_RESELLER_CARD_INFORMATION:
      return {
        ...state,
        payment_method: action.payload,
      };
    case CONSTANTE.UPDATE_COMPANY_RESELLER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

const helperFindRemove = (state: any, value: any) => {
  let found = state?.find((el: any) => el?.id == value?.id);
  let returnValue = [];
  if (found) {
    returnValue = [...state?.filter((el: any) => el.id != value?.id)];
  } else {
    returnValue = [value, ...state];
  }
  return returnValue;
};
