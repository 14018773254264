import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateInfoUserAction } from "../../../../../../../../redux/actions/acounts-action";
import { rootState } from "../../../../../../../../redux/reducers";
import { refaCtoNullKey } from "../../../../../../../../utils";
import { ExpertFormEdit } from "./type";

export default function UseEditInfoUserExpert() {
  const userState = useSelector((state: rootState) => state.userReducer);
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const [showEditModal, setShowModal] = useState<boolean>(false);
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm<ExpertFormEdit>({
    mode: "onChange",
    defaultValues: {
      firstName: userState?.first_name,
      lastName: userState?.last_name,
      companyName: userState?.company_name,
      location: userState?.country?.country_name,
      city: refaCtoNullKey(userState?.city),
      jobTitle: userState?.job_title,
    },
  });

  const cancelChange = () => {
    setShowModal(false);
    reset();
  };

  const callBack = (data: any) => {
    setIsLoaderShow(false);
    setShowModal(false);
    if (data) {
      toast("Successfully updated");
    }
  };

  const submit = (data: ExpertFormEdit) => {
    setIsLoaderShow(true);
    const countryId = data?.location
      ? dataCompleted?.country?.find(
          (el: any) => el.country_name == data?.location?.trim()
        )?.id
      : dataCompleted?.country?.find(
          (el: any) => el.country_name == userState?.country?.country_name
        )?.id;

    let params: any = {
      firstName: data?.firstName?.trim(),
      lastName: data?.lastName?.trim(),
      companyName: data?.companyName?.trim(),
      jobTitle: data?.jobTitle?.trim(),
      city: data.city,
      website: data.website,
      country: countryId,
    };
    dispatch(updateInfoUserAction(params, callBack));
  };

  useEffect(() => {
    if (showEditModal) {
      setValue("firstName", userState.first_name);
      setValue("lastName", userState.last_name);
      setValue("location", userState.country.country_name);
      setValue("companyName", userState.company_name);
      setValue("jobTitle", userState.job_title);
      setValue("city", userState.city);
      setValue("website", userState.website);
    }
  }, [showEditModal]);

  return {
    register,
    handleSubmit,
    setValue,
    getValuesForm: getValues,
    dataCompleted,
    userState,
    errors,
    isLoaderShow,
    setIsLoaderShow,
    showEditModal,
    setShowModal,
    submit,
    cancelChange,
  };
}
