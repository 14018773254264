import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";

export default function ModalGeographieTarget(props: T.IpropsFormBody6) {
  const [activeId, setActiveId] = useState<any>("0");

  const [activeId2, setActiveId2] = useState<any>("0");

  function toggleActive2(id: any) {
    if (activeId === id) {
      setActiveId2("null");
    } else {
      setActiveId2(id);
    }
  }

  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }
  return (
    <div className="bodyCreateCompany">
      <div className="contentBorder row">
        <div className="form-group required col-12">
          <label htmlFor="">What geographic market do you target ?</label>
          <span className="selectalloverstyle">
            Select all that apply.
            {props.isShowErroCountrySelected && (
              <div className="container-error pl-2 d-inline">
                <span className="error-red"> error </span>
                <span className="txt-error">select al least one country</span>
              </div>
            )}
          </span>
        </div>
        <div className="geographicList col-12 createCompanyGeographic">
          <Accordion defaultActiveKey={activeId}>
            {props.allContinents &&
              props.allContinents.map((items: any, index: number) => {
                return (
                  <div
                    className={
                      activeId == items.id
                        ? "panel-wrap active-panel"
                        : "panel-wrap"
                    }
                    key={items.id}
                  >
                    <div className="panel-header">
                      <Accordion.Toggle
                        onClick={() => toggleActive(items.id)}
                        className={`${
                          props.paysChecked?.filter(
                            (el: any) => el.idContinent == items.id
                          ).length > 0
                            ? "panel-toggle selectedItem"
                            : "panel-toggle"
                        }`}
                        eventKey={items.id}
                      >
                        {items && items.name}{" "}
                        {props.paysChecked?.filter(
                          (el: any) => el.idContinent == items.id
                        ).length > 1
                          ? `(   ${
                              props.paysChecked?.filter(
                                (el: any) => el.idContinent == items.id
                              ).length
                            } countries selected )`
                          : ""}
                        {props.paysChecked?.filter(
                          (el: any) => el.idContinent == items.id
                        ).length == 1
                          ? `( 1 country  selected )`
                          : ""}
                      </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse eventKey={items.id}>
                      <div className="panel-body">
                        <div
                          onClick={(event) => {
                            event.stopPropagation();
                            props.addPaysInArray({
                              idContinent: items.id,
                              countryesData: items.countries,
                            });
                          }}
                        >
                          <Form.Check
                            custom
                            label={`Select all `}
                            id="all"
                            checked={
                              props.allContinentSelected &&
                              props.allContinentSelected?.find(
                                (el: any) => el == items.id
                              )
                                ? true
                                : false
                            }
                            disabled={true}
                            onClick={() => null}
                            value={index}
                            onChange={() => null}
                          />
                        </div>

                        {items &&
                          items.countries &&
                          items.countries.map((pays: any) => {
                            return (
                              <Form.Check
                                key={pays.id}
                                custom
                                label={pays.country_name}
                                id={pays.id}
                                checked={
                                  props.paysChecked?.find(
                                    (t: any) => t.countrId == pays.id
                                  )
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  props.addPaysInArray({
                                    idContinent: items.id,
                                    countrId: pays.id,
                                    countryName: pays.country_name,
                                  });
                                }}
                                onChange={() => null}
                              />
                            );
                          })}
                      </div>
                    </Accordion.Collapse>
                  </div>
                );
              })}
          </Accordion>
        </div>
        {props.isShowOfficeData && (
          <div className="contentBorder row">
            {props.isShowErrorOffice && (
              <div className="container-error pl-2 d-inline">
                <span className="error-red"> error </span>
                <span className="txt-error">select al least one office</span>
              </div>
            )}
            <div className="geographicList col-12 mb-4">
              <Accordion>
                <div
                  className={`falseSelect ${
                    activeId2 === "1" ? "panel-wrap active-panel" : "panel-wrap"
                  }`}
                >
                  <div className="panel-header">
                    <Accordion.Toggle
                      onClick={() => toggleActive2("1")}
                      className="panel-toggle"
                      eventKey="1"
                    >
                      Do you have an office in North America?
                    </Accordion.Toggle>
                  </div>

                  <Accordion.Collapse eventKey="1">
                    <div className="panel-body">
                      {props.office &&
                        props.office.map((el: string) => (
                          <div onClick={() => props.addOffice(el)} key={el}>
                            <Form.Check
                              custom
                              label={el}
                              checked={
                                props.selectedOffice == el ? true : false
                              }
                              onChange={() => null}
                            />
                          </div>
                        ))}
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
