/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useMemo } from 'react';
import './overview.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import {
  ToverViewInfo,
  TitemSentAaffiliated,
} from '../../../../../controller/company-ctr/types/types-company-page';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import ReactPlayer from 'react-player';
import ModalEditVideo from './modal/modal-edit-video';
import ModalEditSummary from './modal/modal-edit-summary';
import LeadershipTeam from '../overview/leadership-team/leadership-team';
import {
  bolderedText,
  checkIfExist,
  fileUrl,
  uppercaseFirstLetter,
} from '../../../../../utils';
import { useParams } from 'react-router-dom';
import logoDev from '../../../../../resources/images/logodev.png';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../../redux/reducers';
import { INVESTOR } from '../../../../../service/constant';
import ReactHtmlParser from 'react-html-parser';

export default function Overview(props: ToverViewInfo) {
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;
  const userState = useSelector((state: rootState) => state.userReducer);
  const isInvestor = userState?.roles?.[0] === INVESTOR;
  const isShowTxtSummary =
    (props.summaryCompany === '' || props.summaryCompany == null) &&
    !isOtherUser;

  const isHavePitch = isOtherUser && isInvestor && props.valueOfPitch;
  const Video = useMemo(
    () => (
      <>
        <ReactPlayer
          url={`${fileUrl(props.filePresentation)}`}
          height='420'
          width='250'
          style={{ marginLeft: '5px' }}
          playing={false}
          muted={true}
          controls={true}
          loop={true}
        />
      </>
    ),
    [props.filePresentation]
  );

  const isHaveDescription = () =>
    props.leaderShipList &&
    props.leaderShipList.find((el) => checkIfExist(el.description));

  /** TEAM LEADERSHIP */
  const renderTeamLeadership = () => {
    return props.leaderShipList?.map((team: any, index: number) => (
      <div key={index}>
        <LeadershipTeam
          photo_profil={team?.photo_profil}
          name={team?.name}
          linkedin={team?.linkedin}
          job_title={team?.job_title}
          key={index}
          descriptionFauxTexte={
            <div className='fauxText'>
              <div className='l100'></div>
              <div className='l100'></div>
              <div className='l100'></div>
              <div className='l50'></div>
            </div>
          }
          description={team?.description}
        />
      </div>
    ));
  };

  return (
    <div className='overviewcontainer'>
      <div className='company-summary'>
        {isHavePitch && (
          <>
            {/*<h3 className='text-center font-weight-600 mt-5'>Pitch</h3>*/}
            <div className='logodev'>
              <img src={logoDev} alt='' />
              <a>{ReactHtmlParser(bolderedText(props?.valueOfPitch))}</a>
            </div>
          </>
        )}

        <>
          <h3 className='text-center font-weight-600'>Overview</h3>
          <h5 className='mb-2'>Company summary</h5>
          {isShowTxtSummary && <p>Outline a brief overview of your company.</p>}
          <a
            className='linkEditLeftCompany'
            // Dev #47729 V1 [Company page ISV] Bug lorsqu'on Edit le Company Summary
            onClick={() => props.setIsShowOverView(true)}
          >
            Edit<span className='icon-edit-full'></span>
          </a>
        </>

        {props.summaryCompany ? (
          <p> {ReactHtmlParser(bolderedText(props?.summaryCompany))} </p>
        ) : (
          <div className='fauxText'>
            <div className='l100'></div>
            <div className='l100'></div>
            <div className='l100'></div>
            <div className='l50'></div>
          </div>
        )}
      </div>
      <div className='upload'>
        <a
          className='linkEditLeftCompany'
          onClick={() => props.setIsShowVideoEdit(true)}
        >
          Edit<span className='icon-edit-full'></span>
        </a>
        <div>
          {props.filePresentation && Video}
          {!props.filePresentation && !isOtherUser && (
            <p>Do you have more to show? Upload a short company video.</p>
          )}
        </div>
        {props?.editProfilCompany && props.filePresentation && (
          <div
            className='removePhoto ov'
            onClick={() => props?.handleModalRemoveVideo()}
          >
            <a href='javascript:;'>
              <span className='icon-bin'></span>Remove video
            </a>
          </div>
        )}
      </div>
      <div className='teams'>
        <h5 className='mb-3'>Leadership team</h5>
        {!isOtherUser && (
          <>
            {props.leaderShipList?.length > 0 && !isHaveDescription() && (
              <p>Introduce your leadership team.</p>
            )}
            <a
              className='linkEditLeftCompany'
              onClick={() => {
                props.setIsShowLeaderShip(true);
              }}
            >
              Edit<span className='icon-edit-full'></span>
            </a>
          </>
        )}

        {renderTeamLeadership()}
      </div>

      <ModalEditSummary {...props} />
      <ModalEditVideo {...props} />
      <Modal
        show={props.isShowLeaderShip}
        className='createCompanypopup large forTeam'
      >
        {/* smallPopup */}
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Leadership team</h2>
            <a
              className='close'
              onClick={() => props.setIsShowLeaderShip(false)}
            >
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className='withMinHeight'>
          {props.listLeaderShipForm?.map((el: any, index: number) => (
            <div className='member contentBorder Over row m-0 p-0 ' key={index}>
              <ul className='headTeam col-12'>
                <div className='d-flex align-items-center'>
                  {props.listLeaderShipForm[index]?.idForm !== 1 && (
                    <div className='drop'>
                      <Dropdown drop='down'>
                        <Dropdown.Toggle
                          id='dropdown-basic'
                          className='teamMember'
                        >
                          New team member
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {props.affiliatedList?.map(
                            (element: TitemSentAaffiliated, index2) => (
                              <Dropdown.Item
                                className='border-bottom'
                                href='javascript:;'
                                onClick={() => props.addForm(element, index)}
                                key={index2}
                              >
                                {element?.user?.first_name} -{' '}
                                {uppercaseFirstLetter(element?.role)}
                                &nbsp;&nbsp;
                              </Dropdown.Item>
                            )
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}

                  <div
                    onClick={() => props.removeLeaderShip(el?.id, el?.idForm)}
                    className='removeMember'
                  >
                    <label>Remove</label>
                  </div>
                </div>
              </ul>
              <div className='col-4 left'>
                <div className=''>
                  <div className='photo'>
                    {(props.listLeaderShipForm?.[index]?.fileBinaryValue ||
                      props.listLeaderShipForm[index]?.fileType) && (
                      <img
                        src={
                          props.listLeaderShipForm?.[index]?.fileBinaryValue
                            ? URL.createObjectURL(
                                props.listLeaderShipForm?.[index]
                                  ?.fileBinaryValue
                              )
                            : props.listLeaderShipForm[index]?.fileType
                        }
                        alt=''
                      />
                    )}

                    <label
                      className='favorite styled'
                      onClick={() => props.handleBtn()}
                    >
                      <input
                        type='file'
                        accept='image/png, image/jpeg'
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          props.changeHandlerLeaderShip(
                            event,
                            props.listLeaderShipForm[index]?.idForm,
                            index
                          );
                        }}
                        style={{ display: 'none' }}
                      />
                      Choose file
                    </label>
                  </div>

                  <div className='desc'>
                    <div className='name required'>
                      <label htmlFor=''>
                        Member's name
                        {props?.errorsFields?.indexError == el?.idForm &&
                          props?.errorsFields?.isErrorName && (
                            <div className='container-error pl-2 d-inline'>
                              {/* change d-inline on d-none */}
                              <span className='error-red'> error </span>
                              <span className='txt-error'>
                                This field is required
                              </span>
                            </div>
                          )}
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        name='MemberName'
                        value={props.listLeaderShipForm[index]?.name_leaderShip}
                        onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                          props.changeInputLeaderShip({
                            ...props.listLeaderShipForm[index],
                            name_leaderShip: evt?.target?.value,
                          })
                        }
                      />
                    </div>
                    <div className='job required'>
                      <label htmlFor=''>
                        Job title
                        {props?.errorsFields?.indexError == el?.idForm &&
                          props?.errorsFields?.isErrorJobTitle && (
                            <div className='container-error pl-2 d-inline'>
                              {/* change d-inline on d-none */}
                              <span className='error-red'> error </span>
                              <span className='txt-error'>
                                This field is required
                              </span>
                            </div>
                          )}
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        name='Job'
                        value={
                          props.listLeaderShipForm[index]?.job_title_leaderShip
                        }
                        onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                          props.changeInputLeaderShip({
                            ...props.listLeaderShipForm[index],
                            job_title_leaderShip: evt?.target?.value,
                          })
                        }
                      />
                    </div>
                    <div className='in'>
                      <label htmlFor=''>Linkedin</label>
                      <div className='d-flex align-items-center'>
                        Linkedin.com/in/{' '}
                        <input
                          type='text'
                          className='form-control'
                          value={
                            props.listLeaderShipForm[index]
                              ?.linkd_dean_leaderShip
                          }
                          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                            props.changeInputLeaderShip({
                              ...props.listLeaderShipForm[index],
                              linkd_dean_leaderShip: evt?.target?.value,
                            })
                          }
                          onBlur={(evt: ChangeEvent<HTMLInputElement>) => {
                            let url = evt?.target?.value;
                            let custom = url?.includes(
                              'https://www.linkedin.com/in/'
                            )
                              ? url?.replaceAll(
                                  'https://www.linkedin.com/in/',
                                  ''
                                )
                              : url;

                            props.changeInputLeaderShip({
                              ...props.listLeaderShipForm[index],
                              linkd_dean_leaderShip: custom,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {props.listLeaderShipForm[index]?.idForm !== 1 &&
                      props.isShowMailInvite && (
                        <div className='invite'>
                          <label htmlFor=''>
                            Invite this member to join Devinsider
                            {!props.safeEmail &&
                              index == props?.listLeaderShipForm.length - 1 && (
                                <div className='container-error pl-2 d-inline'>
                                  {/* change d-inline on d-none */}
                                  <span className='error-red'> error </span>
                                  <span className='txt-error'>
                                    {props?.txtEmailError}
                                  </span>
                                </div>
                              )}
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            name='Invitation'
                            value={
                              props.listLeaderShipForm[index]?.email_leaderShip
                            }
                            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                              props.changeInputLeaderShip({
                                ...props.listLeaderShipForm[index],
                                email_leaderShip: evt?.target?.value,
                              })
                            }
                            onBlur={(evt: ChangeEvent<HTMLInputElement>) =>
                              props?.checkEmailAction(evt?.target?.value)
                            }
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className='form-group col-8 descCompany m-0 right'>
                <textarea
                  value={
                    props.listLeaderShipForm[index]?.description_leaderShip
                  }
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    props.changeInputLeaderShip({
                      ...props.listLeaderShipForm[index],
                      description_leaderShip: event?.target?.value,
                    })
                  }
                  maxLength={450}
                />

                {props?.errorsFields?.indexError == el?.idForm &&
                  props?.errorsFields?.isErrorDescription && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}

                <span className='caractercount'>
                  {0 +
                    (props.listLeaderShipForm[index]?.description_leaderShip
                      ?.length || 0)}{' '}
                  / 450
                </span>
              </div>
            </div>
          ))}

          {!props.isShowLoaderLeaderShip && (
            <label
              htmlFor='chk-link-1'
              className='addTeam'
              onClick={() => props.addForm()}
            >
              Add team member
            </label>
          )}
        </Modal.Body>
        <Modal.Footer className='contentBorder lead forOverview'>
          <div className='contentBorder formForEmail text-right'>
            <button
              className='btn btn-devinsider cancel'
              onClick={() => props.cancelFormLeaderShip()}
            >
              Cancel
            </button>
            <button
              className='btn btn-devinsider px-5 save'
              onClick={() => props.saveLeaderShip()}
              disabled={props.isShowLoaderLeaderShip}
            >
              {props.isShowLoaderLeaderShip ? <Loader /> : 'Save'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* End popup company team */}

      {/* MODAL REMOVE VIDEO */}
      <Modal
        show={props?.showRemovePopupVideo}
        className='small forDesactivate'
      >
        <Modal.Header>
          <div className='headerpermission noStep'>
            <h2 className='title'>Remove video</h2>
            <a
              href='javascript:;'
              className='close'
              onClick={() => {
                props?.setShowRemovePopupVideo(false);
              }}
            >
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='contentBorder row m-0 p-0 desactivepopup'>
            <p className='p-3'>Are you sure you want to remove this video?</p>
          </div>
          <div className='contentBorder formForEmail text-right'>
            <button
              className='btn btn-devinsider cancel'
              onClick={() => {
                props?.handleModalRemoveVideo();
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-devinsider px-5 save'
              onClick={() => {
                props?.handleRemoveVideo();
              }}
            >
              {props?.processingRemoveVideo ? <Loader /> : <>Remove</>}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
