import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateGuestLogo } from "../../redux/actions/acounts-action";
import { rootState } from "../../redux/reducers";

export default function UseEditProfilGuestCtr() {
  const userState = useSelector((state: rootState) => state.userReducer);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [loaderUpdate, setloaderUpdate] = useState<boolean>(false);
  const refCompanyProfil = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  //event click bouton and lunch pick image in type image , fileRef is ref of img
  const handleRef = () => {
    if (refCompanyProfil) {
      refCompanyProfil?.current?.click();
    }
  };
  const cb = (params: boolean) => {
    setloaderUpdate(false);
    toast("Company logo updated");
  };

  const changeHandlerProfil = async (evt: any) => {
    let file = evt.target.files;
    if (file && file?.[0]) {
      setSelectedFile(file?.[0]);
      const params = {
        logo: file[0],
      };
      setloaderUpdate(true);
      dispatch(updateGuestLogo(params, userState.token, cb));
    }
  };

  return {
    userState,
    refCompanyProfil,
    handleRef,
    selectedFile,
    setSelectedFile,
    changeHandlerProfil,
    loaderUpdate,
  };
}
