import React from "react";
import { fileUrl } from "../../../../../utils";

type IpropsIsvInvestorHeader = {
  profil: string;
  companyName: string;
  roleDiscussion: string;
};
export default function isvToInvestorHeaderMessage(props: IpropsIsvInvestorHeader) {
  return (
    <>
      <div className="d-flex mr-5">
        <div className="photo-title">
          <img src={fileUrl(props.profil)} alt="" />
        </div>
        <div className="info-title">
          <div className="company-title">
            <a href="javascript:;">{props?.companyName}</a>
          </div>
          <div className="company-type">{props?.roleDiscussion}</div>
        </div>
      </div>
    </>
  );
}
