import React from "react";
import Header from "../../widget/index";
import Footer from "../../widget/footer/footer";
import "./static-page.scss";

export default function CommunityGuidelines(){
    return(
        <div>
            <Header/>
            <section className="community-guidelines">
                <div className="container mx-auto">
                    Community guidelines
                </div>
            </section>
            <Footer/>
        </div>
    );
}