import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";

interface Props {
  open: boolean;
  handleModal: (state: boolean) => void;
}

export default function ModalWidget(props: Props) {
  const { open, handleModal } = props;
  function getModalStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
    };
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #F16627",
      boxShadow: theme.shadows[5],
      padding: "10px 0px 0px 0px",
      outline: "none",
      borderRadius: "5px",
    },
  }));

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h4
        id="simple-modal-title"
        style={{
          textAlign: "center",
          margin: "0px",
          padding: "0px",
          fontSize: "20px",
        }}
      >
        Sorry ! An error
      </h4>
      <h5
        id="simple-modal-description"
        style={{ textAlign: "center", fontSize: "18px" }}
      >
        Something went Wrong during your inscription
      </h5>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
