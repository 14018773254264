import React from "react";
import Modal from "react-bootstrap/Modal";
import { concatString, fileUrl } from "../../../../utils";
import { BtnNext } from "../../bouton/btn-next";

type IProps = {
  showModal: boolean;
  handleModal: () => void;
  oemMember: any;
  valideRemoveMember: boolean;
  setValideRemoveMember: (p: boolean) => void;
  removeOemMember: () => void;
  loader: boolean;
};

export default function ModalUpdateProgramOwner(props: IProps) {
  return (
    <Modal
      show={props.showModal}
      className="createCompanypopup medium forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Remove team member</h2>
          <a className="close" onClick={props.handleModal}>
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="contentBorder forBillingCenter">
          <div className="continue mb-5">
            <span className="icon-exclamation-circle"></span>
            <p className="mb-4">Are you sure you want to continue?</p>
            <div>You are about to remove the following team member :</div>
          </div>
          <div className="program mb-5">
            <div className="image">
              <img
                src={fileUrl(props.oemMember?.user?.file_name)}
                className="Image"
              />
            </div>
            <div className="desc">
              <ul>
                <li className="titleProgram">
                  {concatString(
                    props.oemMember?.user?.first_name,
                    props.oemMember?.user?.last_name,
                    " "
                  )}
                </li>
                <li>{props.oemMember?.user?.company_name}</li>
              </ul>
            </div>
          </div>
          <div className="p-4">
            <div className="form-group form-check px-0 col-12 checkContent">
              <input
                type="checkbox"
                className="form-check-input regular-checkbox big-checkbox"
                onChange={() =>
                  props.setValideRemoveMember(!props.valideRemoveMember)
                }
                checked={props.valideRemoveMember}
              />
              This is a permanent action and cannot be undone. <br />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right forPitch">
          <button
            className="btn btn-devinsider cancel"
            onClick={props.handleModal}
          >
            Cancel
          </button>
          <button
            onClick={() => props.removeOemMember()}
            type="submit"
            className="btn btn-devinsider px-5 save"
            disabled={props.loader || !props.valideRemoveMember}
          >
            <BtnNext text={"Remove"} showLoader={props.loader} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
