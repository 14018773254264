import React, { ChangeEvent } from 'react';
import Header from '../../../../widget/header-logo-center/header-logo-center';
import UseCompleteCompanyExpert from '../../../../../controller/account-ctr/complete-create-acount-expert-contributor/use-complete-company-expert';
import useGoBack from '../../../../../hooks/use-navigate';
import { checkIfExist, getSStorage } from '../../../../../utils';
import { Redirect } from 'react-router-dom';

export default function CompleteCompanyExpert() {
  const {
    companyNameExpert,
    setCompanyNameExpert,
    jobTitleExpert,
    setJobTitleExpert,
    webSiteExpert,
    setWebSiteExpert,
    errorCompanyNameExpert,
    errorJobTitleExpert,
    errorWebSiteExpert,
    txtWebsiteError,
    navigateCompleteAboutExpert,
  } = UseCompleteCompanyExpert();
  const { goBack } = useGoBack();

  const sess = getSStorage('businessEmailExpert');

  if (!checkIfExist(sess)) {
    return <Redirect to='/start-expert' />;
  }

  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <div className='head-getstarted'>
          <div className='stepInscription'>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
          </div>
          <a onClick={goBack} className='link-back'>
            <span className='icon-back'></span>
          </a>
          <h1>Create your account.</h1>
        </div>
        <div className='contentBorder border-top row py-3'>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='first-name required'>
              Company name
              <div
                className={errorCompanyNameExpert ? 'p-2 d-inline' : 'd-none'}
              >
                <span className='error-red'> error </span>
                <span className='txt-error'>This field is required</span>
              </div>
            </label>
            <input
              type='text'
              className='input-text form-control'
              value={companyNameExpert}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                setCompanyNameExpert(evt.target.value)
              }
            />
          </div>
          <div className=' form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2'>
            <label htmlFor='first-name required'>
              Job title
              <div className={errorJobTitleExpert ? 'p-2 d-inline' : 'd-none'}>
                <span className='error-red'> error </span>
                <span className='txt-error'>This field is required</span>
              </div>
            </label>
            <input
              type='text'
              className='input-text form-control'
              value={jobTitleExpert}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                setJobTitleExpert(evt.target.value)
              }
            />
          </div>
          <div className='form-group col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='first-name required'>
              Website
              <div className={errorWebSiteExpert ? 'p-2 d-inline' : 'd-none'}>
                <span className='error-red'> error </span>
                <span className='txt-error'>{txtWebsiteError}</span>
              </div>
            </label>
            <input
              type='text'
              className='input-text form-control'
              value={webSiteExpert}
              placeholder='Begin with http:// or https:// or www'
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                setWebSiteExpert(evt.target.value)
              }
            />
          </div>
        </div>
        <div className='contentBorder border-top row align-items-stretch'>
          <div className='col-12 d-flex align-items-center pr-0'>
            <button
              className='btn btn-devinsider large containerBtnBtn'
              onClick={() => navigateCompleteAboutExpert()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
