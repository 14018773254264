import React from 'react';
import { NavLink } from 'react-router-dom';
import { fileUrl } from '../../../../../utils';

type IpropsOemIsvHeader = {
  profil: string;
  companyName: string;
  roleDiscussion: string;
  companyId: string;
};
export default function OemToIsvHeaderMessage(props: IpropsOemIsvHeader) {
  const companyUrl = '/company/company-unlocked/' + props.companyId;
  return (
    <>
      <div className="d-flex mr-5">
        <div className="photo-title">
          <img src={fileUrl(props.profil)} alt="" />
        </div>
        <div className="info-title">
          <div className="company-title">
            {/* <NavLink to={companyUrl}>{props?.companyName}</NavLink> */}
            <a href="javascript:;">{props?.companyName}</a>
          </div>
          <div className="company-type">{props?.roleDiscussion}</div>
        </div>
      </div>
    </>
  );
}
