import React from "react";

export default function HeaderBlue() {
  return (
    <ul className="breadcrumbPerso">
      <li>
        <a href="javascript:;">Community</a>
      </li>
      <li>
        <a href="javascript:;">Press release</a>
      </li>
    </ul>
  );
}
