import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { IsvMember } from '../../../../../../controller/tchat-ctr/type';
import { concatString, fileUrl } from '../../../../../../utils';

type IpropsModal = {
  isShowAddMember: boolean;
  closeModal: () => void;
  allUser: Array<{ role: string; user: IsvMember }>;
  ckeckUser: (id: number) => void;
  allChecked: Array<number>;
  validAddMember: () => void;
  isLoadingAddMember: boolean;
};
export default function Index(props: IpropsModal) {
  return (
    <Modal
      show={props?.isShowAddMember}
      className='createCompanypopup small forAddMember bgGris'
    >
      <Modal.Header>
        <div className='headerpopupCompany headerAddMember noStep'>
          <h2 className='title'> Add member</h2>
          <a href='javascript:;' onClick={props.closeModal} className='close'>
            <span className='icon-close-pop'></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='contentBorder row mx-auto'>
          {/* <form action="" className="searchWord">
            <input type="text" className="keyword" value=" " />
            <button>
              <span className="icon-search"></span>
            </button>
          </form> */}
          <div className='form-group col-12 listMember'>
            {props?.allUser?.map(
              (element: { role: string; user: IsvMember }, index: number) => (
                <div
                  className='custom-control custom-checkbox orange'
                  key={index}
                >
                  <input
                    type='checkbox'
                    id={'member1' + index}
                    className='custom-control-input'
                    onChange={() => props?.ckeckUser(element?.user?.id)}
                    checked={
                      props?.allChecked?.find(
                        (el: number) => el === element?.user?.id
                      )
                        ? true
                        : false
                    }
                  />
                  <label
                    htmlFor={'member1' + index}
                    className='custom-control-label'
                  >
                    <div className='one-program'>
                      <div className='photo'>
                        <img src={fileUrl(element?.user?.file_name)} alt='' />
                      </div>
                      <div className='program-info'>
                        <span className='name'>
                          {concatString(
                            element?.user?.first_name,
                            element?.user?.last_name,
                            ' '
                          )}
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              )
            )}
          </div>
          <div className='termine'>
            <button
              className='btn btn-devinsider px-5'
              disabled={props.isLoadingAddMember}
              onClick={() => props?.validAddMember()}
            >
              Add
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
