import React from "react";

export const FormHeaderAbout = () => (
  <div className="headerpopupCompany">
    <ul className="step">
      <li className="stepli inprogress">About</li>
      <li className="inter"></li>
      <li className="stepli">Solution</li>
      <li className="inter"></li>
      <li className="stepli">Strategy</li>
      <li className="inter"></li>
      <li className="stepli">Funding</li>
    </ul>
    <h2 className="title">Create a company page</h2>
  </div>
);

export const FormHeaderSolution = () => (
  <div className="headerpopupCompany">
    <ul className="step">
      <li className="stepli done">About</li>
      <li className="inter done"></li>
      <li className="stepli inprogress">Solution</li>
      <li className="inter"></li>
      <li className="stepli">Strategy</li>
      <li className="inter"></li>
      <li className="stepli">Funding</li>
    </ul>
    <h2 className="title">Create a company page</h2>
  </div>
);
export const FormHeaderStrategy = () => (
  <div className="headerpopupCompany">
    <ul className="step">
      <li className="stepli done">About</li>
      <li className="inter done"></li>
      <li className="stepli done">Solution</li>
      <li className="inter done"></li>
      <li className="stepli inprogress">Strategy</li>
      <li className="inter"></li>
      <li className="stepli">Funding</li>
    </ul>
    <h2 className="title">Create a company page</h2>
  </div>
);
export const FormHeaderFunding = () => (
  <div className="headerpopupCompany">
    <ul className="step">
      <li className="stepli done">About</li>
      <li className="inter done"></li>
      <li className="stepli done">Solution</li>
      <li className="inter done"></li>
      <li className="stepli done">Strategy</li>
      <li className="inter done"></li>
      <li className="stepli inprogress">Funding</li>
    </ul>
    <h2 className="title">Create a company page</h2>
  </div>
);
