import React, { useState, useEffect } from 'react';
import { IDataResource } from '../../../screen/container/investor/profil-investor/types';
import { useDispatch, useSelector } from 'react-redux';
import { updatePortfolioAction } from '../../../redux/actions/company-page-investor-action/my-investor-page-action';
import { rootState } from '../../../redux/reducers';
import { useForm } from 'react-hook-form';
import * as T from '../../../screen/container/investor/profil-investor/types';
import { isURL } from '../../../utils';
import { useParams } from 'react-router-dom';
import useIL from '../../../screen/widget/industries-list/use-IL';
import useSF from '../../../screen/widget/software-solution/use-SF';

export type IPropsPortFolio = {
  id: string;
  name: string;
  logo: string;
  url: string;
  draft: boolean;
  dataFile: any;
  hqLocation: any;
  industry: Array<{
    id: number;
    name: string;
  }>;
  segmentDetails: Array<any>;
  solution: Array<any>;
  isDeleted?: boolean;
};
export default function UseEditPortfolioCtr() {
  const [loader, setLoader] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [indexEdit, setIndexEdit] = useState<string>('');
  const dispatch = useDispatch();
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;
  const [showIndustry, setShowIndustry] = useState<boolean>(false);
  const [showSpecialization, setShowspecialization] = useState<boolean>(false);

  const CP_Investor = useSelector((state: rootState) =>
    !isOtherUser
      ? state.companyInvestorPageReducer
      : state?.otherCompanyInvestorPageReducer
  );

  const [valueIndustrieSelected, setValueIndustrieSelected] = useState<
    Array<{
      id: number;
      name: string;
    }>
  >([]);

  const [defaultSolutionChecked, setDefaultSolutionChecked] = useState<any>({
    solutionChecked: [],
    solutionCheckedFeatures: [],
  });

  const {
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    backToDefault: clauseIndustrySolution,
  } = useIL({ defaultSelected: valueIndustrieSelected });

  const {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    backToDefault,
    filterSolutionChecked
  } = useSF({
    defaultValue: defaultSolutionChecked.solutionChecked,
    defaultValueFeatured: defaultSolutionChecked.solutionCheckedFeatures,
    maxSolutions: 3,
  });

  const [isShowModaList, setIsShowModaList] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<File | string>();
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [initialPortFolio, setInitialPortFolio] = useState<
    Array<IPropsPortFolio>
  >([]);

  const [isEdit, setisEdit] = useState<boolean>(false);
  /**
   * Initialise portFolio form in empty value for 4 item
   */
  const initialisePortFolio = () => {
    let draftPortFolio: any = CP_Investor?.investorPagePortfolios?.filter(
      (el: T.PropsPortFolio) =>
        isOtherUser ? el?.draft === false : el?.draft === true
    );

    /**
     * Initialise draft with response value
     */
    let valueStorePortFolio = draftPortFolio?.map((el: IDataResource) => ({
      ...el,
      dataFile: '',
      isChange: false,
      hqLocation: el.hqLocation?.id,
    }));

    setInitialPortFolio(valueStorePortFolio);
  };

  useEffect(() => {
    initialisePortFolio();
    return () => {};
  }, [CP_Investor, params?.id]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm<T.FormPortfolio>({
    mode: 'onSubmit',
  });

  const linkUrl = watch('linkLogo');

  /**
   * show modal liste click
   */
  const clickEdit = () => {
    setisEdit(true);
  };

  const saveUpdate = () => {
    setLoader(true);
  };

  /**
   *  show modal form
   *  clause modal liste portFolio
   */
  const showModal = (index: string) => {
    errorEmpty && setErrorEmpty(false);

    setIndexEdit(index);
    setValue('name', initialPortFolio[parseInt(index)]?.name);
    setValue('linkLogo', initialPortFolio[parseInt(index)]?.url);
    setValue(
      'hqLocation',
      initialPortFolio[parseInt(index)]?.hqLocation || null
    );
    setFileUrl(initialPortFolio[parseInt(index)]?.logo);

    setValueIndustrieSelected(initialPortFolio[parseInt(index)]?.industry);
    setDefaultSolutionChecked({
      solutionChecked: initialPortFolio[parseInt(index)]?.solution,
      solutionCheckedFeatures:
        initialPortFolio[parseInt(index)]?.segmentDetails,
    });
    setIsShowModal(true);
    setIsShowModaList(false);
  };

  /**
   * clause modal form
   * reset dorm
   * show modal liste
   */
  const closeModal = () => {
    setIsShowModal(false);
    setIsShowModaList(true);
    reset();
    clauseIndustrySolution();
    backToDefault();
    setFileUrl(undefined);
    setIndexEdit('');
  };

  const saveFormSelected = async (data: T.FormPortfolio) => {
    if (fileUrl == undefined && data?.linkLogo === '') {
      setErrorEmpty(true);
    } else {
      let tempValue: any = initialPortFolio?.map(
        (el: T.PropsPortFolio, index: number) => {
          if (index === parseInt(indexEdit)) {
            return {
              id: el?.id,
              name: data?.name,
              logo: el?.logo,
              url: data?.linkLogo,
              hqLocation: data.hqLocation,
              draft: true,
              dataFile: fileUrl && typeof fileUrl !== 'string' ? fileUrl : '',
              isChange: true,
              industry: companyTargetSelected,
              solution: solutionChecked,
              segmentDetails: solutionCheckedFeatures,
            };
          } else {
            return el;
          }
        }
      );
      setInitialPortFolio(tempValue);
      closeModal();
    }
  };

  const saveSelectedIndustrie = () => {
    setShowspecialization(false);
    setIsShowModal(true);
  };

  const saveSolutionChecked = () => {
    setShowIndustry(false);
    setIsShowModal(true);
  };

  const handleShowIndustry = () => {
    setIsShowModal(false);
    setShowIndustry(true);
  };

  const showSolutionCategory = () => {
    setIsShowModal(false);
    setShowspecialization(true);
  };

  const cancelModalSolutionCategory = () => {
    backToDefault();
    setShowspecialization(false);
    setIsShowModal(true);
  };

  const cancelModalIndustry = () => {
    clauseIndustrySolution();
    setShowIndustry(false);
    setIsShowModal(true);
  };

  /**
   * restore form data
   */
  useEffect(() => {
    if (errorEmpty) {
      setErrorEmpty(false);
    }
    return () => {};
  }, [fileUrl]);

  /**
   *
   * save file
   */
  const setFilePortfolio = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files?.[0]) {
      let fileName = event?.target?.files?.[0].name
        ?.split('.')
        .pop()
        ?.toLowerCase();

      const filteType =
        fileName === 'png' ||
        fileName?.includes('jpeg') ||
        fileName?.includes('jpg') ||
        fileName?.includes('jfif');

      if (filteType) {
        setFileUrl(event?.target?.files[0]);
      }
    }
  };

  const removeItemsValue = (index: number) => {
    const selectedToDelete = initialPortFolio[index];
    if (selectedToDelete?.id) {
      let tempData: any = initialPortFolio?.map(
        (el: IPropsPortFolio, i: number) => {
          if (i.toString() === index.toString()) {
            return {
              id: el?.id,
              dataFile: '',
              isDeleted: true,
              isChange: true,
              draft: true,
              name: '',
              logo: '',
              url: '',
              hqLocation: '',
              industry: [],
              segmentDetails: [],
              solution: [],
            };
          } else {
            return el;
          }
        }
      );
      setInitialPortFolio(tempData);
    } else {
      let tempData: any = initialPortFolio?.filter(
        (el: IPropsPortFolio, i: number) => i.toString() !== index.toString()
      );
      setInitialPortFolio(tempData);
    }
  };

  const clausePortFolio = () => {
    setIsShowModaList(false);
    initialisePortFolio();
  };

  const cbUpdateDraft = (data: any) => {
    setLoader(false);
    setIsShowModaList(false);
  };

  const updateListePortFolio = () => {
    setLoader(true);
    let filePortFolio = initialPortFolio?.map((el: any) => el?.dataFile);

    let stringDataChange = initialPortFolio?.map((el: any, index: number) => {
      if (el?.isChange === true) {
        const segmentDetails = el.segmentDetails?.map((el: any) => el.id) || [];
        const industry = el.industry?.map((el: any) => el.id) || [];
        const solution = el.solution?.map((el: any) => el.id) || [];
        return {
          id: el?.id || '0',
          name: el?.name,
          url: el?.url,
          logo: el?.dataFile !== '' ? index : '',
          hqLocation: el?.hqLocation,
          industry,
          segmentDetails,
          solution,
        };
      }
    });

    //remove undefined data array
    let dataStringToSend = stringDataChange?.filter(
      (el: any) => el?.id && el?.id !== undefined
    );

    let postData = {
      portfolio: JSON.stringify(dataStringToSend),
      ...filePortFolio,
    };
    dispatch(updatePortfolioAction(postData, CP_Investor.id, cbUpdateDraft));
  };

  useEffect(() => {
    if (linkUrl && isURL(linkUrl)) {
      let data = new URL(
        linkUrl?.startsWith('www') ? 'https://' + linkUrl : linkUrl
      );
      setValue('linkLogo', data?.origin);
    }
    return () => {};
  }, [linkUrl]);

  const addOtherPortFolio = () => {
    const tempPortfolio = {
      id: '',
      name: '',
      logo: '',
      url: '',
      draft: true,
      dataFile: null,
      hqLocation: null,
      industry: [],
      segmentDetails: [],
      solution: [],
    };
    setInitialPortFolio([...initialPortFolio, tempPortfolio]);
  };

  return {
    isEdit,
    setisEdit,
    clickEdit,
    saveUpdate,
    isShowModal,
    showModal,
    closeModal,
    indexEdit,
    fileUrl,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    errors,
    saveForm: saveFormSelected,
    loader,
    setFilePortfolio,
    errorEmpty,
    isShowModaList,
    clausePortFolio,
    setIsShowModaList,
    initialPortFolio,
    updateListePortFolio,
    removeItemsValue,
    addOtherPortFolio,
    showIndustry,
    setShowIndustry,
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    saveSelectedIndustrie,
    clauseIndustrySolution,
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    backToDefault,
    cancelModalIndustry,
    showSpecialization,
    setShowspecialization,
    saveSolutionChecked,
    showSolutionCategory,
    handleShowIndustry,
    cancelModalSolutionCategory,
    otherInvestorPortfolio: isOtherUser
      ? CP_Investor.investorPagePortfolios
      : [],
    isOtherUser,
    filterSolutionChecked,
  };
}
