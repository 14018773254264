import React, { useState, useEffect, useRef } from "react";
import { EditorState } from "draft-js";
import { convertFromRaw } from "draft-js";

export type IProps = {
  initialeObjectText: string;
  initialWysiwygTemplate: string;
};

export default function usePostWysiwig(props: IProps) {
  const [object, setObject] = useState<string>("");
  const [stateFormWysywig, setStateFormWysywig] = useState(
    EditorState?.createEmpty()
  );

  useEffect(() => {
    if (props?.initialeObjectText || props?.initialeObjectText !== "") {
      setObject(props?.initialeObjectText);
    }

    if (props?.initialWysiwygTemplate != "") {
      setStateFormWysywig(
        EditorState?.createWithContent(
          convertFromRaw(JSON.parse(props?.initialWysiwygTemplate))
        )
      );
    } else {
      setStateFormWysywig(EditorState?.createEmpty());
    }

    return () => {};
  }, [props?.initialWysiwygTemplate, props?.initialeObjectText]);

  const setEditorStatePost = (p: any) => {
    setStateFormWysywig(p);
  };

  const setTxtObject = (p: string) => {
    setObject(p);
  };

  return {
    object,
    stateFormWysywig,
    setEditorStatePost,
    setTxtObject,
  };
}
