import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import { getData, getDataWithoutToken } from '../../../../service/api';
import { operationBillingAmount, url } from '../../../../service/constant';
import { getMonth } from '../../../../utils';

export default function UseUnlockInvestor() {
  const now = new Date();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const companyPageReducer = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const [modalAdds, setmodalAdds] = useState<boolean>(false);
  const [amountClassifiedAds, setAmountClassifiedAds] = useState<string>('');
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + ' ' + now.getFullYear()
  );

  const [stateAdds, setstateAdds] = useState<{
    number_left: number;
    number_max: number;
    next_grant: string;
    spent_by: Array<{
      id: string;
      first_name: string;
      last_name: string;
      user_uri: string;
      at: string;
      number: string;
      role: string;
    }>;
  }>({ number_left: 0, number_max: 0, next_grant: '', spent_by: [] });

  const closeAddsModal = () => {
    setstateAdds({
      number_left: 0,
      number_max: 0,
      next_grant: '',
      spent_by: [],
    });
    setmodalAdds(false);
    setChoiseValue(undefined);
  };

  const showmodalAdds = () => {
    setmodalAdds(true);
  };

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };
  const getPlanDetail = async () => {
    const tempUrl =
      url.settings.billingCenter +
      '/' +
      operationBillingAmount.POST_CLASSIFIED_ADS +
      '/' +
      companyPageReducer.id;

    let response = await getData(tempUrl, userReducer.token);
    if (response && response.data) setAmountClassifiedAds(response.data);
  };

  useEffect(() => {
    companyPageReducer.id && userReducer.token && getPlanDetail();
  }, [companyPageReducer]);

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + '/information/plan_details',
        { month: currentMonth },
        userReducer.token
      );
      if (response?.status === 200) {
        const { isv_free_ads }: any = response?.data?.free_status;
        setstateAdds(isv_free_ads);
        setChargingPlanDetails(false);
        const isHaveFree = isv_free_ads?.number_left > 0;
        if (isHaveFree) {
          setChoiseValue('FREE');
        }
      }
    }
    if (modalAdds) {
      getPlanDetails();
    }
  }, [currentMonth, modalAdds]);

  return {
    modalAdds,
    closeAddsModal,
    setmodalAdds,
    chargingPlanDetails,
    stateAdds,
    handleChoise,
    choiseValue,
    showmodalAdds,
    amountClassifiedAds,
  };
}
