import profilISV from '../screen/container/profil/user-profil/profil';
import profilISVEdit from '../screen/container/profil/user-profil/profil-edit';
import { ISV } from '../service/constant';
export const routesProfils = [
  {
    path: '/profil/Isv',
    guard: 'private',
    component: profilISV,
    exact: true,
    allowedRole: [ISV],
  },
  {
    path: '/profil',
    guard: 'private',
    component: profilISV,
    exact: true,
  },
  {
    path: '/profil/Isv/edit',
    guard: 'private',
    component: profilISVEdit,
    exact: true,
    allowedRole: [ISV],
  },
  {
    path: '/profil/edit',
    guard: 'private',
    component: profilISVEdit,
    exact: true,
  },
];
