import { Dispatch } from "../../types/type-action";
import * as Service from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import store from "../../store";

export const constante = {
  GET_OTHER_PAGE_INVESTOR: "GET_OTHER_PAGE_INVESTOR",
  GET_OTHER_INVESTOR_ATTRIBUT: "GET_OTHER_INVESTOR_ATTRIBUT",
  RESTORE_INVESTOR_PAGE: "RESTORE_INVESTOR_PAGE",
};

/**
 * @param id number , id company page
 * @callback function  (params:any) {
   return response company age
 }
*/
const getOtherCPInvestorAction = (id: number, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let result = await Service.getDataService(
        url?.investor_company_page?.url_get_company_page + id,
        {},
        store?.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        const payloadData =
          result?.data === "forbidden" || result?.data === "Forbidden"
            ? null
            : result?.data;
        dispatch({
          type: constante.GET_OTHER_PAGE_INVESTOR,
          payload: payloadData,
        });
        callback(result?.data);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * publish and update draft data
 */
const getOtherInvestorAttributes = (
  id: number,
  key: string,
  completed_url: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.getDataService(
        url?.investor_company_page?.get_investor_attribut +
          completed_url +
          "/" +
          id,
        {},
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: constante.GET_OTHER_INVESTOR_ATTRIBUT,
          payload: { data: result?.data, key: key },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * Restore temp investor page
 */
const restoreOtherInvestorPage = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: constante.RESTORE_INVESTOR_PAGE,
        payload: null,
      });
    } catch (error: any) {}
  };
};

export {
  getOtherCPInvestorAction,
  getOtherInvestorAttributes,
  restoreOtherInvestorPage,
};
