import Header from '../../../../widget/header-logo-center/header-logo-center';
import React from 'react';
import StepperOem from '../../../../widget/stepper/StepperOem';
import useCompleteOemCompany from '../../../../../controller/account-ctr/create-oem-account/use-complete-oem-company';
import { checkIfExist, getSStorage } from '../../../../../utils';
import { Redirect } from 'react-router-dom';

export default function CompleteCompanyOem() {
  const { errors, handleSubmit, register, onSubmit, dataOem } =
    useCompleteOemCompany();

  const sess: any = getSStorage('oemProject');

  if (!checkIfExist(sess)) {
    return <Redirect to='/partner-program-registration' />;
  }

  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <StepperOem step={1} />
        <div className='contentBorder border-top row py-4'>
          <div className='col-12 form-group p-0 pb-4'>
            <label htmlFor=''>
              Welcome to Devinsider, {dataOem.firstName}!
            </label>
          </div>

          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='jobtitle required'>
              Job title
              {errors['jobTitleOem'] && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
            </label>
            <input
              type='text'
              className='input-text form-control'
              defaultValue={dataOem?.jobTitle}
              {...register('jobTitleOem', { required: true })}
            />
          </div>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='jobtitle required'>
              Company name
              {errors['companyNameOem'] && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
            </label>
            <input
              type='text'
              className='input-text form-control'
              defaultValue={dataOem?.companyName}
              {...register('companyNameOem', { required: true })}
            />
          </div>
          <div className='contentBorder border-top row align-items-stretch'>
            <button
              type='submit'
              className='btn btn-devinsider large'
              onClick={handleSubmit(onSubmit)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
