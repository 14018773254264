import React, { useState, useEffect } from "react";
import { setSessionStorageObject } from "../../../utils/index";
import { mockRegion } from "../../../utils/mock-region";
import { useHistory } from "react-router-dom";
import useEventListener from "../../../hooks/event-handler";
import { useForm } from "react-hook-form";

export type InputAdressReseller = {
  companyTradeName: string;
  companyTradeLegalName: string;
  companyLocation: string;
  companyCity: string;
  website: string;
};

export default function UseCompleteCompanyInfoReseller() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<InputAdressReseller>({
    defaultValues: {
      companyTradeName:
        sessionStorage.getItem("companyTradeLegalName") ||
        sessionStorage.getItem("companyReseller") ||
        "",
      companyTradeLegalName:
        sessionStorage.getItem("companyTradeLegalName") || "",
      companyLocation: sessionStorage.getItem("companyLocation") || "",
      companyCity: sessionStorage.getItem("companyCity") || "",
      website: sessionStorage.getItem("website") || "",
    },
  });
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );
  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
    } else return;
  };

  useEventListener("keydown", keyEnter);

  const goBack = () => {
    history.goBack();
  };

  const onSubmitForm = async (data: InputAdressReseller) => {
    const dataObject = { ...data };
    setSessionStorageObject(dataObject);
    history.push("/complete-company-reseller-pays");
  };

  return {
    goBack,
    mockRegion,
    register,
    handleSubmit,
    errors,
    getValues,
    watch,
    dataCompleted,
    onSubmitForm,
  };
}
