import React from "react";
import logoLettre from "../../../resources/images/logo-lettre.svg";
import useWelcomeCtr from "../../../controller/use-welcom-ctr";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { logout } from "../../../redux/actions/acounts-action";
import { fileUrl } from "../../../utils";
import { useDataContext } from "../../../context/index";
import BlockNotif from "../../widget/block-notif/index";
import { BlockMessage } from "../bloc-message-notif";
import { Link } from "react-router-dom";

export default function HeaderExpert(props: any) {
  const { getStarted, goLogin } = useWelcomeCtr();
  const userState = useSelector((state: rootState) => state.userReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const urlPath = window.location.href;
  let valueofSubmenu: string = "";
  urlPath.indexOf("match") !== -1
    ? (valueofSubmenu = "match")
    : urlPath.indexOf("events") !== -1
    ? (valueofSubmenu = "events")
    : urlPath.indexOf("community") !== -1
    ? (valueofSubmenu = "community")
    : (valueofSubmenu = "neant");

  //Toogle One
  const { stopEvtSource } = useDataContext();

  const goToSettings = () => {
    history.push("/settings", { tabToShow: "d-account" });
  };

  const cb = (params: boolean) => {
    if (params) {
      history.push("/signin");
    }
  };
  const logOut = () => {
    stopEvtSource();
    dispatch(logout(cb));
  };

  const goToCommunityHome = () => {
    history.push("/community/home");
  };

  const showConversation = () => {
    history.push("/contact/discussion");
  };

  return (
    <div className="principalMenu">
      <div className="container mx-auto">
        <div className="leftItemHeader">
          <div className="logoHeader">
            <img
              src={logoLettre}
              alt=""
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="menuDynamic tl">
            <div className="item">
              <Link to="/community/home" className="parent linkHeader borRight">
                Community
              </Link>
              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span
                      onClick={() => {
                        goToCommunityHome();
                      }}
                    >
                      Community
                    </span>
                    Join a global community for software publishers (ISVs).
                  </div>
                </div>
                <div className="container mx-auto">
                  <Link
                    className="linkHeader"
                    to="/community/discussions-categories"
                  >
                    <span className="name">Forum discussions</span>
                    <p>
                      Discuss the business challenges of your software company
                      with peers and industry experts.
                    </p>
                  </Link>
                  <Link
                    className="linkHeader"
                    to="/community/article-category/0/0"
                  >
                    <span className="name">Articles</span>
                    <p>
                      Share your expertise and insights to help software publishers
                      with high-value content.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            
            <div className="item noArrow">
              <Link to="/classified-ads/home" className="parent linkHeader">
                Marketplace
              </Link>
            </div>
          </div>
          {/* <div className='menuDynamic tl'>
            <div className='item'>
              <Link
                className={`parent linkHeader ${
                  valueofSubmenu === 'events' ? 'active' : ''
                }`}
                to='/events/all-events'
              >
                Events
              </Link>
            </div>
          </div> */}

          {/* <div className="menuDynamic tl">
            <div className="item hidden">
              <a href="javascript:;" className="linkHeader parent">
                Marketplace
              </a>
              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span>Marketplace</span>
                    Identify partnership opportunities with resellers, investors
                    and other professionals working in the software industry.
                  </div>
                </div>
                <div className="container mx-auto"></div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="rightItemHeader">
          {userState && userState.enable ? (
            <div className="menuDynamic tr">
              <div className="item">
                <Link to="/dashboard" className="linkHeader">
                  <span className="icon-home"></span>
                  <span className="mobile">Home</span>
                </Link>
              </div>
              <div
                className="item"
                onClick={(e: any) => {
                  showConversation();
                }}
              >
                <BlockMessage unread={props.unread} />
              </div>
              <BlockNotif />
              <div className="item">
                <a href="javascript:;" className="parent linkHeader">
                  <div className={`profilImg`}>
                    <img
                      alt="Profile"
                      src={`${fileUrl(userState.file_name)}`}
                    />
                  </div>
                  <span className="mobile">My account</span>
                </a>
                <div className="submenu">
                  <Link to="/profil">Profile</Link>

                  <a href="javascript:;" onClick={() => goToSettings()}>
                    Account settings
                  </a>
                  <a href="javascript:;" onClick={() => logOut()}>
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="menuDynamic tr notConnected">
              <div className="item">
                <a
                  href="javascript:;"
                  className="signinHeader"
                  onClick={() => goLogin()}
                >
                  Sign in
                </a>
              </div>
              <div className="item">
                <a
                  href="javascript:;"
                  className="getstartedHeader"
                  onClick={() => getStarted()}
                >
                  Get started
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
