/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  convertCommentFromJSONToHTML,
  customClass,
  fileUrl,
} from '../../../../../utils';
import { IPropsExpertise, ItemsDataSource } from '../types';
import TemplateWebsite from '../../../../widget/template-website/index';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function InfoExpertise(props: IPropsExpertise) {
  function createMarkup(html: string) {
    return {
      __html: html,
    };
  }

  return (
    <>
      <article className='investorArticle bgGris'>
        <div className='mx-auto container cont3'>
          <div className='contentLeftInvestor'>
            {props?.isEditMode && (
              <a
                className='linkEditLeftCompany'
                onClick={(e: any) => {
                  e?.preventDefault();
                  props?.clickEdit();
                }}
              >
                Edit<span className='icon-edit-full'></span>
              </a>
            )}

            <h3 className='title'>
              Expertise{' '}
              {/* Dev #46477 V1 [Investor] Ameliorations concernant le "preview mode" */}
              {!props?.isEditMode && !props?.isEditClick ? (
                <>
                  <span style={{ color: 'red' }}>
                    {!props?.isPreview ? '*' : ''}
                  </span>
                </>
              ) : (
                <></>
              )}
            </h3>
            {(props?.isEditMode ||
              (props?.initialeDataSource?.length === 1 &&
                props?.initialeDataSource[0].editorState
                  .getCurrentContent()
                  .getPlainText() === '' &&
                !props?.isPreview)) && (
              <>
                <p>
                  Describe what your organization specializes in and what value
                  you offer to ISVs.
                </p>
              </>
            )}
            {props?.initialeDataSource?.length > 0 &&
              (!props?.isEditClick || !props?.isEditMode) &&
              props?.initialeDataSource?.map((el: any, index: number) => (
                <div
                  className={`${customClass(
                    el?.fileUrl ? el?.disposition : ' test-only'
                  )} elementDisposition`}
                  key={index}
                >
                  <div className='imageElement'>
                    <img src={fileUrl(el?.fileUrl)} alt='' />
                  </div>
                  <div className='textElement format-wysiwyg'>
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        convertCommentFromJSONToHTML(el?.editorState)
                      )}
                    />
                  </div>
                </div>
              ))}

            {props?.initialeDataSource?.length === 1 &&
              props?.initialeDataSource[0].editorState
                .getCurrentContent()
                .getPlainText() === '' &&
              !props?.isEditClick && (
                <div className='fauxText'>
                  <div className='l100'></div>
                  <div className='l100'></div>
                  <div className='l100'></div>
                  <div className='l100'></div>
                  <div className='l25'></div>
                </div>
              )}

            {props?.isEditClick && props?.isEditMode && (
              <>
                <div className='formForEmail companyInvestorbtnctt editShow'>
                  <button
                    className='btn btn-devinsider cancel'
                    onClick={() => props?.clickCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    className='btn btn-devinsider px-5'
                    onClick={() => props?.saveAllDataForm()}
                  >
                    {props?.loader ? <Loader /> : 'Save'}
                  </button>
                </div>

                <div className={` editShow expertise`}>
                  {props?.initialeDataSource?.map(
                    (element: ItemsDataSource, index: number) => (
                      <TemplateWebsite
                        key={index}
                        editorState={element?.editorState}
                        setEditorState={(p: any) =>
                          props?.setEditorState(p, index)
                        }
                        disposition={element?.disposition}
                        fileUrl={element?.fileUrl}
                        dataFile={element?.dataFile}
                        choseDisposition={(p: string) =>
                          props?.choseDisposition(p, index)
                        }
                        indexOfTemplate={element?.itemIndex}
                        removeElement={() => props?.removeElement(index)}
                        setFileExpertise={(p: any) =>
                          props?.setFileExpertise(p, index)
                        }
                        removeFile={() => props?.removeFile(index)}
                        isHaveError={props?.isHaveError}
                      />
                    )
                  )}
                </div>

                <a
                  className={`${
                    props?.isEditClick ? 'addElement editShow' : 'd-none'
                  }`}
                  onClick={() => props?.addNewElement()}
                >
                  <span>Element</span>
                </a>
              </>
            )}
          </div>
        </div>
      </article>
    </>
  );
}
