import React from "react";
import { useHistory } from "react-router-dom";
import LikesFromCtr from "../../../../../controller/community-ctr/activity-user/likes-from";
import { fileUrl } from "../../../../../utils";
import { url } from "../../../../../service/constant";

const LikesFrom = (props: any) => {
  const history = useHistory();
  const providerLikesFrom = { ...LikesFromCtr(props?.userId) };
  return (
    <>
      <h2 className="title">Likes from</h2>
      <ul className="separateElement">
        {providerLikesFrom?.likesFrom &&
          providerLikesFrom?.likesFrom?.map((like: any, index: number) => (
            <li key={index}>
              <div className="leftItem itemAuthor">
                <div className="imageAffiliatedcompany empty">
                  <img
                    src={fileUrl(like.profil_picture)}
                    className="imageAffiliatedcompany"
                  />
                </div>
                <div className="infoAffiliated">
                  <h3
                    onClick={() =>
                      history.push(
                        url.community.activity.front_user_activity +
                          like.user_id
                      )
                    }
                    className="title"
                  >
                    {like.user_name}
                  </h3>
                  <div className="post">{like.job_title}</div>
                </div>
              </div>
              <div className="rightItem likeContent">
                <span className="icon-like-full"></span>
                {like.nbre_likes}
              </div>
            </li>
          ))}

        <li className="linkForLi">
          <a
            className="viewAll"
            onClick={providerLikesFrom?.goToLikeFromViewAll}
          >
            View all
          </a>
        </li>
      </ul>
    </>
  );
};

export default LikesFrom;
