/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import {
  updateCompanyPageAction,
  addLeaderShipAction,
  removeLeaderShipById,
  update_video_company_page,
} from '../../../../redux/actions/company-page-isv-action/current_isv_action';
import * as T from '../../types/types-company-page';
import { url as URL } from '../../../../service/constant';
import url from '../../../../service/constant';
import {
  fileUrl,
  isEmail,
  isRequired,
  refaCtoNullKey,
} from '../../../../utils';
import { checkEmail_Service } from '../../../../service/applicatif/company-page-isv';
import { useHistory, useLocation } from 'react-router';
import { postDataFormDataService } from '../../../../service/applicatif/back-end-service';
import { toast } from 'react-toastify';
var _ = require('lodash');

export default function useOverview() {
  const dispatch = useDispatch();
  const location = useLocation();

  const fileRef: React.RefObject<HTMLInputElement> = useRef(null);
  //Dev #47679 V1 Agrandir la taille max de la video dans company page
  const [uploadVideoStatus, setUploadVideoStatus] = useState<{
    inProgress: boolean;
    details: {
      total: number;
      loaded: number;
    };
  }>({
    inProgress: false,
    details: {
      total: 0,
      loaded: 0,
    },
  });
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  let companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  let userStateObject = useSelector((state: rootState) => state.userReducer);
  const [loaderOverView, setLoader] = useState<boolean>(false);
  const [isShowLoaderUpdateVideo, setIsShowLoaderUpdateVideo] =
    useState<boolean>(false);
  const [showOverview, setShowOverview] = useState<boolean>(false);
  const [isShowLeaderShip, setIsShowLeaderShip] = useState<boolean>(false);
  const [isShowLoaderLeaderShip, setIsShowLoaderLeaderShip] =
    useState<boolean>(false);
  const [txtEmailError, setTxtEmailError] = useState<string>('');
  const [isShowErrorField, setisShowErrorField] = useState<T.ErrorFields>({
    indexError: 0,
    isErrorName: false,
    isErrorJobTitle: false,
    isErrorDescription: false,
  });

  const [isShowMailInvite, setIsShowMailInvite] = useState<boolean>(true);

  const [showRemovePopupVideo, setShowRemovePopupVideo] =
    useState<boolean>(false);
  const [processingRemoveVideo, setProcessingRemoveVideo] =
    useState<boolean>(false);

  /***********************************************
   *
   * Compay information to edit
   */
  const [editProfilCompany, setEditProfilCompany] = useState<boolean>(false);

  useEffect(() => {
    let state: any = location?.state;
    setEditProfilCompany(state?.editProfil);
    return () => {};
  }, []);

  const [listLeaderShipForm, setlistLeaderShipForm] = useState<
    Array<T.TleaderShipTeam>
  >([]);

  const [safeEmail, setSafeEmail] = useState<boolean>(true);

  const checkEmailAction = async (email: string) => {
    if (email !== '') {
      if (isEmail(email)) {
        setIsShowLoaderLeaderShip(true);
        let response: any = await checkEmail_Service(
          email,
          userStateObject?.token
        );
        if (response?.status === 500) {
          setSafeEmail(false);
          setTxtEmailError(response?.data);
        } else {
          setSafeEmail(true);
        }
        setIsShowLoaderLeaderShip(false);
      } else {
        setSafeEmail(false);
        setTxtEmailError('email is not valide');
      }
    }
  };

  useEffect(() => {
    setlistLeaderShipForm(
      companyPageObject?.companyPageLeadershipTeams?.map((el: any) => {
        return {
          id: el?.id,
          idForm: el?.id_form,
          fileType: fileUrl(el?.photo_profil),
          name_leaderShip: el?.name,
          job_title_leaderShip: el?.job_title,
          linkd_dean_leaderShip: refaCtoNullKey(el?.linkedin),
          description_leaderShip: el?.description,
          email_leaderShip: '',
          fileBinaryValue: undefined,
        };
      })
    );
    return () => {};
  }, [companyPageObject?.companyPageLeadershipTeams]);

  /*********************************************************************
   *
   * Set file on picked button
   */
  const txtExedUpload =
    'This file cannot be uploaded because it exceeds the maximum allowed file size (100mb)';
  // Dev #47679 V1 Agrandir la taille max de la video dans company page
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      const fileSize = event?.target?.files?.[0]?.size;
      if (fileSize > 100000000) {
        toast(txtExedUpload);
        return;
      }
      if (event?.target?.files[0]) {
        setSelectedFile(event?.target?.files[0]);
        setIsFilePicked(true);
      }
    }
  };

  /***********************************************************************
   *
   * Drop file video
   */
  const handleDrop = (params: any) => {
    setSelectedFile(params[0]);
    setIsFilePicked(true);
  };

  /***********************************************************************
   *
   * Click bouton to pick photo
   */
  const handleBtn = () => {
    if (fileRef) {
      fileRef?.current?.click();
    }
  };

  /************************************************************************
   *
   * Company page summary to edit
   */
  const [modalValueSummary, setModalValueSummary] = useState<string>('');
  const [summaryLength, setSummaryLength] = useState<number>(0);

  const [isShowVideoEdit, setIsShowVideoEdit] = useState<boolean>(false);

  useEffect(() => {
    setModalValueSummary(companyPageObject?.company_summary);
    const lineBreak =
      companyPageObject?.company_summary?.split('\n').length - 1;

    setSummaryLength(companyPageObject?.company_summary?.length - lineBreak);
    return () => {};
  }, [companyPageObject]);

  /***********************************
   *
   * Update company summary
   */
  const cbUpdateSummary = (response: any) => {
    if (response && response.status === 200) {
      setLoader(false);
      setShowOverview(false);
    }
  };

  const updateCompanySummary = () => {
    let params = {
      companySummary: modalValueSummary,
    };
    setLoader(true);

    console.log(
      'modalValueSummary',
      modalValueSummary,
      modalValueSummary.length
    );
    dispatch(
      updateCompanyPageAction(params, companyPageObject.id, cbUpdateSummary)
    );
  };

  /****************************************
   *
   * Callback for update video
   */
  const cbUpdateVideo = (response: any) => {
    // Dev #47679 V1 Agrandir la taille max de la video dans company page
    //an error occured
    if (response === undefined) {
      toast('An error occured while uploading your video, please try again');
    } else {
      toast('Your video has been uploaded successfully!');
    }

    setIsShowLoaderUpdateVideo(false);
    setIsShowVideoEdit(false);
    setEditProfilCompany(true);
    setUploadVideoStatus({
      ...uploadVideoStatus,
      inProgress: false,
    });
  };

  //   Dev #47679 OPEN V1 Agrandir la taille max de la video dans company page
  const callBackProgression = (progressEvent: any) => {
    setUploadVideoStatus({
      ...uploadVideoStatus,
      details: {
        total: progressEvent?.total,
        loaded: progressEvent?.loaded,
      },
    });
  };

  const updateVideoPresentation = () => {
    let params = {
      videoProfile: selectedFile ? selectedFile : '',
    };
    setIsShowLoaderUpdateVideo(true);
    // Dev #47679 V1 Agrandir la taille max de la video dans company page
    setUploadVideoStatus({
      ...uploadVideoStatus,
      inProgress: true,
    });
    dispatch(
      updateCompanyPageAction(
        params,
        companyPageObject.id,
        cbUpdateVideo,
        undefined,
        callBackProgression
      )
    );
  };

  /****************************************************************
   *
   * create new form with index + 1 value if dataselected null
   */
  const addForm = (data?: T.TitemSentAaffiliated, index?: number) => {
    /************************************
     *
     * if data and index to change existe
     */
    if (data && index?.toString()) {
      setIsShowMailInvite(false);
      /*******************************
       *
       * Get all form
       */
      let tempState = listLeaderShipForm;
      /**********************************
       *
       * Get form to update
       */
      let tempToChangeValue = tempState[index];
      /****************************************************
       *
       * Change form in el?.idForm to data to remove
       */
      let dataMake = tempState?.map((el: T.TleaderShipTeam) => {
        if (el?.idForm === tempToChangeValue?.idForm) {
          return {
            id: tempToChangeValue?.id ? tempToChangeValue?.id : '',
            idForm: tempToChangeValue?.idForm,
            fileType: data ? fileUrl(data?.user?.file_name) : '',
            name_leaderShip: data ? data?.user?.first_name : '',
            job_title_leaderShip: data ? data?.user?.job_title : '',
            linkd_dean_leaderShip: data
              ? refaCtoNullKey(data?.user?.linkedin)
              : '',
            description_leaderShip: data ? data?.user?.plain_text : '',
            email_leaderShip: '',
            fileBinaryValue: undefined,
          };
        } else {
          return el;
        }
      });
      /********************************
       *
       * Update form liste
       */
      setlistLeaderShipForm([...dataMake]);
    } else {
      setIsShowMailInvite(true);
      if (controlField()) {
        /**********************************
         *
         * Set form with empty value in last idForm + 1
         */
        setlistLeaderShipForm([
          ...listLeaderShipForm,
          {
            id: '',
            idForm:
              listLeaderShipForm[listLeaderShipForm.length - 1].idForm * 1 + 1,
            fileType: '',
            name_leaderShip: '',
            job_title_leaderShip: '',
            linkd_dean_leaderShip: '',
            description_leaderShip: '',
            email_leaderShip: '',
            fileBinaryValue: undefined,
          },
        ]);
      }
    }
  };

  /***************************************************************
   *
   * Update input value in leaderShip file id idForm is equals p.idForm
   */
  const changeInputLeaderShip = (p: T.TleaderShipTeam) => {
    let tempLeaderShip = listLeaderShipForm?.map((el: T.TleaderShipTeam) => {
      if (el.idForm == p.idForm) {
        return p;
      } else {
        return el;
      }
    });
    setlistLeaderShipForm(tempLeaderShip);
    let lastValueWithError = listLeaderShipForm[listLeaderShipForm?.length - 1];

    const isHaveError =
      isShowErrorField?.isErrorJobTitle ||
      isShowErrorField?.isErrorName ||
      isShowErrorField.isErrorDescription;

    if (isHaveError) {
      setisShowErrorField({
        indexError:
          lastValueWithError?.email_leaderShip &&
          lastValueWithError?.name_leaderShip
            ? 0
            : isShowErrorField?.indexError,
        isErrorJobTitle:
          lastValueWithError.job_title_leaderShip != '' ? false : true,
        isErrorName: lastValueWithError?.name_leaderShip != '' ? false : true,
        isErrorDescription:
          lastValueWithError?.description_leaderShip != '' ? false : true,
      });
    }
    if (safeEmail === false) {
      setSafeEmail(true);
    }
  };

  /******************************************************************
   *
   * Update file value in leader Ship file
   */
  const changeHandlerLeaderShip = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    indexInForm: number
  ) => {
    if (event?.target && event?.target?.files) {
      if (event?.target?.files[0]) {
        let tempLeaderShip = listLeaderShipForm?.map(
          (el: T.TleaderShipTeam) => {
            if (el.idForm == index) {
              return {
                ...el,
                fileBinaryValue: event?.target?.files?.[0] || undefined,
                fileType: indexInForm?.toString(),
              };
            } else {
              return el;
            }
          }
        );
        setlistLeaderShipForm(tempLeaderShip);
      }
    }
  };

  /**************************************************************
   *
   * Callback add leaderShip
   */
  const cbAddLeaderShip = (response: any) => {
    if (response && response?.status === 201) {
      setIsShowLoaderLeaderShip(false);
      setIsShowLeaderShip(false);
      setlistLeaderShipForm(
        response?.data?.map((el: any) => {
          return {
            id: el?.id,
            idForm: el?.id_form,
            fileType: fileUrl(el?.photo_profil),
            name_leaderShip: el?.name,
            job_title_leaderShip: el?.job_title,
            linkd_dean_leaderShip: refaCtoNullKey(el?.linkedin),
            description_leaderShip: el?.description,
            email_leaderShip: '',
            fileBinaryValue: undefined,
          };
        })
      );
    }
  };

  const controlField = (): boolean => {
    const incompletedIndex = listLeaderShipForm.findIndex(
      (teams) =>
        !isRequired(teams.name_leaderShip.trim()) ||
        !isRequired(teams.name_leaderShip?.trim()) ||
        !isRequired(teams.description_leaderShip?.trim()) ||
        safeEmail == false
    );

    if (incompletedIndex !== -1) {
      const uncompletedForm = listLeaderShipForm[incompletedIndex];
      setisShowErrorField({
        indexError: listLeaderShipForm[incompletedIndex].idForm,
        isErrorJobTitle: !isRequired(
          uncompletedForm?.job_title_leaderShip?.trim()
        ),
        isErrorName: !isRequired(uncompletedForm?.name_leaderShip?.trim()),
        isErrorDescription: !isRequired(
          uncompletedForm?.description_leaderShip?.trim()
        ),
      });
      return false;
    } else {
      return true;
    }
  };

  /*******************************************************************
   *
   * Url : if click toogle file we set file without base url
   * imageKey : index of image in map , we set in fileType
   */
  const saveLeaderShip = () => {
    if (controlField()) {
      let tempUser = listLeaderShipForm?.map((el: T.TleaderShipTeam) => {
        return {
          id: el.id,
          name: el.name_leaderShip,
          jobTitle: el.job_title_leaderShip,
          linkedin: refaCtoNullKey(el.linkd_dean_leaderShip),
          description: el.description_leaderShip,
          idForm: el.idForm,
          imageKey: el?.fileBinaryValue ? el?.fileType : '',
          url:
            el?.fileBinaryValue == undefined
              ? el?.fileType?.replace(url?.baseUrl, '')
              : '',
          emailInvitation: el?.email_leaderShip || '',
        };
      });
      setIsShowLoaderLeaderShip(true);
      let dataToPost = {
        users: JSON.stringify(tempUser),
        ...listLeaderShipForm?.map((el: any) => el?.fileBinaryValue),
      };
      dispatch(addLeaderShipAction(dataToPost, cbAddLeaderShip));
    }
  };

  /***********************************************************
   *
   * Cancel leaderShip form and restore form to initial reducer
   */
  const cancelFormLeaderShip = () => {
    setIsShowLeaderShip(false);
    setlistLeaderShipForm(
      companyPageObject?.companyPageLeadershipTeams?.map((el: any) => {
        return {
          id: el?.id,
          idForm: el?.id_form,
          fileType: fileUrl(el?.photo_profil),
          name_leaderShip: el?.name,
          job_title_leaderShip: el?.job_title,
          linkd_dean_leaderShip: refaCtoNullKey(el?.linkedin),
          description_leaderShip: el?.description,
          email_leaderShip: '',
          fileBinaryValue: undefined,
        };
      })
    );
  };

  /*************************************************
   *
   * Cancel state of summary value and close modal
   */
  const cancelUpdateSummary = () => {
    setShowOverview(false);
    setModalValueSummary(companyPageObject?.company_summary || '');
  };

  const cbRemoveLeaderShip = (response: any) => {
    if (response && response?.status === 200) {
      let dataResponse = response?.data;
      let dataAfterMake = dataResponse?.map((el: any) => {
        return {
          id: el?.id,
          idForm: el?.id_form,
          fileType: fileUrl(el?.photo_profil),
          name_leaderShip: el?.name,
          job_title_leaderShip: el?.job_title,
          linkd_dean_leaderShip: refaCtoNullKey(el?.linkedin),
          description_leaderShip: el?.description,
          email_leaderShip: '',
          fileBinaryValue: undefined,
        };
      });
      setlistLeaderShipForm(dataAfterMake);
    }
  };

  const removeLeaderShip = async (id?: string, idForm?: string) => {
    const isHaveForm = listLeaderShipForm.length > 1;

    if (isHaveForm) {
      let tempValueToremove = listLeaderShipForm?.find(
        (el) => el?.idForm?.toString() == idForm
      );
      const isEmptyForm = tempValueToremove?.id == '';
      if (isEmptyForm) {
        let tempState = listLeaderShipForm?.filter(
          (el) => el?.idForm?.toString() != idForm
        );
        setlistLeaderShipForm(tempState);
      } else {
        if (id) {
          dispatch(removeLeaderShipById(parseInt(id), cbRemoveLeaderShip));
        }
      }
    }
  };

  useEffect(() => {
    if (!isShowVideoEdit) {
      setIsFilePicked(false);
      setSelectedFile(undefined);
    }
  }, [isShowVideoEdit]);

  const handleRemoveVideo = async () => {
    setProcessingRemoveVideo(true);
    const response = await postDataFormDataService(
      {
        _videoProfile: '1',
      },
      URL.company.updateCompanyPage + '/' + companyPageObject.id,
      userStateObject.token
    );
    if (response?.status === 200) {
      //update redux
      dispatch(update_video_company_page(response?.data?.video_profile));
      setShowRemovePopupVideo(false);
    }
    setProcessingRemoveVideo(false);
  };

  const handleModalVideo = () => {
    setIsShowVideoEdit(!isShowVideoEdit);
    isShowLoaderUpdateVideo &&
      setIsShowLoaderUpdateVideo(!isShowLoaderUpdateVideo);
  };

  const handleModalRemoveVideo = () => {
    setShowRemovePopupVideo(!showRemovePopupVideo);
    processingRemoveVideo && setProcessingRemoveVideo(!processingRemoveVideo);
  };

  useEffect(() => {
    if (isShowLeaderShip) {
      setIsShowLoaderLeaderShip(false);
    }
  }, [isShowLeaderShip]);

  return {
    modalValueSummary,
    setModalValueSummary,
    updateCompanySummary,
    showOverview,
    setShowOverview,
    loaderOverView,
    isShowVideoEdit,
    setIsShowVideoEdit,
    changeHandler,
    handleBtn,
    fileRef,
    isFilePicked,
    selectedFile,
    updateVideoPresentation,
    isShowLoaderUpdateVideo,
    addForm,
    listLeaderShipForm,
    isShowLeaderShip,
    setIsShowLeaderShip,
    changeInputLeaderShip,
    changeHandlerLeaderShip,
    saveLeaderShip,
    isShowLoaderLeaderShip,
    cancelFormLeaderShip,
    cancelUpdateSummary,
    removeLeaderShip,
    handleDrop,
    isShowErrorField,
    isShowMailInvite,
    safeEmail,
    checkEmailAction,
    editProfilCompany,
    setEditProfilCompany,
    txtEmailError,
    showRemovePopupVideo,
    setShowRemovePopupVideo,
    processingRemoveVideo,
    handleRemoveVideo,
    handleModalVideo,
    handleModalRemoveVideo,
    uploadVideoStatus,
    summaryLength,
    setSummaryLength,
  };
}
