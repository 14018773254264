import NotFound from '../screen/container/not-found/not-found';
import CardRegistrationInput from '../screen/container/settings/billing-center/payment-info/card-registration-input';
import SubscriptionPlan from '../screen/container/settings/billing-center/subscription-plan';
export const routesBillingCenter = [
  {
    component: NotFound, // CardRegistrationInput,
    exact: true,
    path: '/billing-center/card-registration',
    guard: 'private',
  },
  {
    component: NotFound, // SubscriptionPlan,
    exact: true,
    path: '/billing-center/subscription-plan',
    guard: 'private',
  },
];
