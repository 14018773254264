import { rootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useHistory, useLocation } from 'react-router';
import { ClassifiedAdsCategories, ClassifiedPost } from './type';
import {
  editClassifiedAds,
  postClassifiedAds,
} from '../../../service/applicatif/classified-ads';
import {
  GUEST,
  INVESTOR,
  ISV,
  MODERATOR,
  OEM_PROGRAM,
  RESELLER,
  url,
} from '../../../service/constant';
import { AdsDetail } from '../detail-ads-ctr/type';
import { toast } from 'react-toastify';
import UsePayementAdds from '../../../screen/widget/payement-isv/publish-adds/use-publish-adds';
import UsePayementGuest from '../../../screen/widget/payement-guest/payement-adds/use-payement-guest';
import UsePayementReseller from '../../../screen/widget/payement-reseller/publish-adds/use-publish-adds';
import UsePayementInvestor from '../../../screen/widget/payement-investors/publish-adds/use-publish-adds';

import DefaultCompany from '../../../resources/images/defaultCompany.png';
import { isModerator } from '../../../utils';
import { postData } from '../../../service/api';

export default function usePostAdsCtr() {
  const user = useSelector((state: rootState) => state.userReducer);
  const company = useSelector((state: rootState) => state?.companyPageReducer);
  const location: any = useLocation();
  const history = useHistory();

  const [title, setTitle] = useState<string>('');
  const [displayAuthorName, setDisplayAuthorName] = useState<boolean>(true);
  const [displayCompanyName, setDisplayCompanyName] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [edit, setEdit] = useState<boolean>(false);
  const [type, setType] = useState<ClassifiedAdsCategories>({
    type: '',
    id: '',
    icon: '',
  });
  const [postAdsFromGuest, setPostAdsFromGuest] = useState<boolean>(false);
  const [postAdsFromReseller, setPostAdsFromReseller] =
    useState<boolean>(false);
  const [paymentIntentGuest, setPaymentIntentGuest] = useState<string>('');

  //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
  const [mentions, setMentions] = useState<
    Array<{ text: string; value: string; url: string }>
  >([]);
  const [registeredMentions, setRegisteredMentions] = useState<Array<string>>(
    []
  );

  //for error when posting from specific user
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [isErrorObject, setIsErrorObject] = useState(false);
  const isIsv = user?.roles?.indexOf(ISV) !== -1;
  const isGuest = user?.roles?.indexOf(GUEST) !== -1;
  const isReseller = user?.roles?.indexOf(RESELLER) !== -1;
  const isInvestor = user?.roles?.indexOf(INVESTOR) !== -1;

  var providerUsePayementAdds: any;
  var providerUsePayementGuest: any;
  var providerUsePayementReseller: any;
  var providerUsePayementInvestor: any;

  if (isIsv) {
    providerUsePayementAdds = UsePayementAdds();
  }

  if (isGuest) {
    providerUsePayementGuest = UsePayementGuest();
  }

  if (isReseller) {
    providerUsePayementReseller = UsePayementReseller();
  }

  if (isInvestor) {
    providerUsePayementInvestor = UsePayementInvestor();
  }

  useEffect(() => {
    setType(location?.state?.params);
  }, [location]);

  const publishAdds = async () => {
    if (title.length < 1) {
      setIsErrorObject(true);
      return;
    }
    setLoader(true);

    let data: ClassifiedPost = {
      classifiedAdsType: parseInt(type?.id),
      title,
      content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      displayAuthorName: +displayAuthorName,
      displayCompanyName: isGuest ? +true : +displayCompanyName,
      choice: 'FREE',
      paymentId: '',
      //category_id: type?.id,
    };
    const res = await postClassifiedAds(data, user?.token);
    if (res?.status === 200) {
      //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
      let postId = res?.data;
      (async () => {
        await postData(
          url?.community?.mention + 's/register',
          {
            postId: postId,
            postType: 'CLASSIFIED_ADS',
            userId: registeredMentions,
          },
          user.token
        );
      })();
      toast('successfully  published');
      history.push('/classified-ads/details/' + res?.data);
      isGuest && providerUsePayementGuest?.closeAddsModal();
      isIsv && providerUsePayementAdds?.closeAddsModal();
      isReseller && providerUsePayementReseller?.closeAddsModal();
      isInvestor && providerUsePayementInvestor?.closeAddsModal();
    } else {
      toast('An error occured');
    }
    setLoader(false);
  };

  const postAds = async () => {
    // if (title.length < 1) {
    //   setIsErrorObject(true);
    //   return;
    // }
    isGuest && providerUsePayementGuest?.showmodalAdds();
    isIsv && providerUsePayementAdds?.showmodalAdds();
    isReseller && providerUsePayementReseller?.showmodalAdds();
    isInvestor && providerUsePayementInvestor?.showmodalAdds();
  };

  const companyPageObject = useSelector((state: rootState) => {
    let userRole = user?.roles?.[0];
    if (userRole === ISV)
      return {
        photo_profil: state.companyPageReducer.photo_profil,
        company_name: state.companyPageReducer.company_name,
      };
    if (userRole === OEM_PROGRAM)
      return {
        photo_profil: state.oemProgramReducer.program.photo_profil,
        company_name: state.oemProgramReducer.program.program_name,
      };
    if (userRole === INVESTOR)
      return {
        photo_profil: state.companyInvestorPageReducer.photo_profil,
        company_name: state.companyInvestorPageReducer.organization_name,
      };
    if (userRole === RESELLER)
      return {
        photo_profil: state.resellerCompanyReducer.photo_profil,
        company_name: state.resellerCompanyReducer.company_legal_name,
      };
    if (userRole === GUEST)
      return {
        photo_profil: state.userReducer.guestCompany.logo,
        company_name: state.userReducer.company_name,
      };
    if (userRole === MODERATOR) {
      const adds = location.state.ad;
      const userInfo = location.state.ad?.user;
      const userRoleInModerator = adds?.user?.roles?.[0];

      const photo_profil =
        userRoleInModerator === ISV
          ? userInfo?.companyPageReducer?.photo_profil
          : userRoleInModerator === OEM_PROGRAM
          ? userInfo?.oemProgramReducer?.program?.photo_profil
          : userRoleInModerator === INVESTOR
          ? userInfo?.investor_members?.investor_page?.photo_profil
          : userInfo?.resellerCompanyReducer?.photo_profil;
      return {
        photo_profil: photo_profil,
        company_name: adds?.user?.company_name,
      };
    }
    return { photo_profil: DefaultCompany, company_name: '' };
  });

  const cancelAds = () => {
    let route: string = !edit
      ? '/classified-ads/home'
      : '/classified-ads/details/' + location?.state?.ad?.id;
    history.push(route);
  };

  useEffect(() => {
    const isEditing =
      location?.state?.ad &&
      location?.state?.params === location?.state?.ad?.classified_ads_type;

    if (isEditing) {
      let ad: AdsDetail = location?.state?.ad;
      setTitle(ad?.title);
      setDisplayAuthorName(ad?.display_author_name);
      setDisplayCompanyName(ad?.display_company_name);
      setEditorState(
        EditorState?.createWithContent(convertFromRaw(JSON.parse(ad?.content)))
      );
      setEdit(true);
    } else {
      setTitle('');
      setDisplayAuthorName(true);
      setDisplayCompanyName(true);
      setEditorState(EditorState.createEmpty());
      setEdit(false);
    }
  }, [location]);

  const editPost = async () => {
    if (title.length < 1) {
      alert('No title');
      return;
    }
    setLoader(true);
    let data: ClassifiedPost = {
      classifiedAdsType: parseInt(type?.id),
      title,
      content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      displayAuthorName: +displayAuthorName,
      displayCompanyName: isGuest ? +true : +displayCompanyName,
      choice: '',
      paymentId: '',
      //category_id: type.id,
    };

    const res = await editClassifiedAds(
      location?.state?.ad?.id,
      data,
      user?.token
    );

    history.push('/classified-ads/details/' + res?.id);
  };

  const postAdsFromUser = async (mode: string, paymentId: string) => {
    setLoader(true);
    //publish ads
    let data: ClassifiedPost = {
      classifiedAdsType: parseInt(type?.id),
      title,
      content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      displayAuthorName: +displayAuthorName,
      displayCompanyName: isGuest ? +true : +displayCompanyName,
      choice: mode,
      paymentId: paymentId,
      //category_id: type.id,
    };

    let result = await postClassifiedAds(data, user?.token);
    if (result?.status === 200) {
      //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
      let postId = result?.data;
      (async () => {
        let response = await postData(
          url?.community?.mention + 's/register',
          {
            postId: postId,
            postType: 'CLASSIFIED_ADS',
            userId: registeredMentions,
          },
          user.token
        );
      })();
      setLoader(false);
      history.push('/classified-ads/details/' + result?.data);
    }
  };

  const updateMention = async (value: any) => {
    let keyWord =
      value?.blocks[0]?.text.includes('@') &&
      value?.blocks[0]?.text?.split('@');
    let keyWordText = keyWord[keyWord.length - 1]?.trim() ?? '';
    if (keyWordText !== '') {
      let response = await postData(
        url?.community.mention + '/create',
        {
          postType: '',
          keyWord: keyWordText,
        },
        user.token
      );
      if (response?.status === 200) {
        setMentions(
          response?.data?.map((rep: any) => {
            return {
              text: rep?.first_name + ' ' + rep?.last_name,
              value: rep?.first_name + ' ' + rep?.last_name,
              url: '/community/activity-user/' + rep?.id,
            };
          })
        );
      }
    }

    //register current mention
    let mentionsKeyArray = Object?.keys(value?.entityMap);
    let mentionsData: Array<any> = [];
    mentionsKeyArray.map((key: string) => {
      if (value?.entityMap[key]?.type === 'MENTION') {
        mentionsData.push(value?.entityMap[key]?.data?.url?.split('/').pop());
      }
    });
    setRegisteredMentions(mentionsData);
  };

  const postAdds = () => {
    console.log('Clickkk');
    postAds();
  };

  return {
    user: isModerator(user.roles) ? location.state.ad?.user : user,
    editorState,
    postAds,
    cancelAds,
    setEditorState,
    setTitle,
    title,
    displayAuthorName,
    displayCompanyName,
    setDisplayAuthorName,
    setDisplayCompanyName,
    loader,
    type,
    companyPageObject,
    edit,
    editPost,
    postAdsFromGuest,
    postAdsFromReseller,
    setPostAdsFromGuest,
    setPostAdsFromReseller,
    postAdsFromUser,
    errorMessage,
    paymentIntentGuest,
    setPaymentIntentGuest,
    providerUsePayementAdds,
    publishAdds,
    isGuest,
    isIsv,
    isReseller,
    isInvestor,
    providerUsePayementGuest,
    providerUsePayementReseller,
    isErrorObject,
    providerUsePayementInvestor,
    //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
    mentions,
    updateMention,
    postAdds,
  };
}
