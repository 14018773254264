import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilters } from "../../../../redux/actions/find-investor-action";
import { rootState } from "../../../../redux/reducers";

export enum filterType {
  investor_type,
  location,
  solution,
  industry,
  otherWay,
}

export default function UseFindInvestorFilter(){
    const dispatch = useDispatch();

    const [isShowBloc, setisShowBloc] = useState<filterType>(filterType.otherWay);
    const findInvestorReducer = useSelector((state:rootState)=>state?.findInvestorReducer);
    const [sizeSlide, setSizeSlide] = useState<Array<number>>([70,100]);
    const [size, setSize] = useState<Array<number>>([]);

    const [indexType, setIndexType] = useState(6);
    const [indexLocation, setIndexLocation] = useState(6);
    const [indexSolution, setIndexSolution] = useState(6);
    const [indexIndustry, setIndexIndustry] = useState(6);

    const customSetSize = (min: number, max: number) => {
      let tempMin: number = 0;
      let tempMax: number = 0;
      switch (min) {
        case 0:
          tempMin = 70;
          break;
        case 1:
          tempMin = 80;
          break;
        case 2:
          tempMin = 90;
          break;
        case 3:
          tempMin = 100;
          break;
        default:
          tempMin = 70;
          break;
      }
      switch (max) {
        case 0:
          tempMax = 70;
          break;
        case 1:
          tempMax = 80;
          break;
        case 2:
          tempMax = 90;
          break;
        case 3:
          tempMax = 100;
          break;
        default:
          tempMax = 100;
          break;
      }
      setSizeSlide([tempMin, tempMax]);
      setSize([min, max]);
    };

    const handleBlock = (params: filterType) => {
      if (params === isShowBloc) {
        setisShowBloc(filterType?.otherWay);
      } else {
        setisShowBloc(params);
      }
    };

    const callback = (data:any)=>{
      if(data?.status === 200){

      }
    }

    // Update Filters
    useEffect(()=>{
      let url = window.location.href;
      let type = url.includes("favorite")? "favorite" :url.includes("investor-unlocked")? "investor-unlocked": "";
      dispatch(getFilters(type, callback));
    },[])

    return {
        size,
        setSize,
        sizeSlide,
        customSetSize,
        findInvestorReducer,
        isShowBloc,
        setisShowBloc,
        handleBlock,
        indexType,
        indexIndustry,
        indexLocation,
        indexSolution,
        setIndexType,
        setIndexIndustry,
        setIndexLocation,
        setIndexSolution,
    }
}