import { useEffect, useRef, useState } from "react";
import {
  updatePhotoBackgroundDraft,
  updatePhotoProfilDraft,
} from "../../../service/applicatif/oem";
import { OemProgram } from "../type";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import {
  remove_couverture_oem_program,
  remove_logo_oem_program,
  updateOemProgramDraftImages,
} from "../../../redux/actions/oem-program-action";
import { postDataFormDataService } from "../../../service/applicatif/company-page-investor";
import { url as URL } from "../../../service/constant";

export default function useOemBackground() {
  const backgroundInput = useRef<HTMLInputElement>(null);
  const profileInput = useRef<HTMLInputElement>(null);
  const oemProgram: OemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const dispatch = useDispatch();
  const [defaultValue, setDefaultValue] = useState<any>();
  const [backgroundLoading, setBackgroundLoading] = useState<boolean>(false);
  const [profilLoading, setProfilLoading] = useState<boolean>(false);
  const [showPopupRemoveLogo, setShowPopupRemoveLogo] =
    useState<boolean>(false);
  const [showPopupRemoveCouverture, setShowPopupRemoveCouverture] =
    useState<boolean>(false);
  const [processingRemovePicture, setProcessingRemovePicture] =
    useState<boolean>(false);

  useEffect(() => {
    setDefaultValue({
      background: oemProgram?.photo_couverture_draft
        ? oemProgram?.photo_couverture_draft
        : oemProgram?.photo_couverture,
      profil: oemProgram?.photo_profil_draft
        ? oemProgram?.photo_profil_draft
        : oemProgram?.photo_profil,
    });
  }, [oemProgram]);

  const handleBackgroundInput = async () => {
    const file: File | undefined = backgroundInput.current?.files?.[0];
    if (file && file.type.startsWith("image")) {
      setBackgroundLoading(true);
      const result = await updatePhotoBackgroundDraft(
        file,
        oemProgram.id,
        userReducer.token
      );
      if (result.status === 200) {
        dispatch(updateOemProgramDraftImages("background", result?.data));
      }
      setBackgroundLoading(false);
    }
  };

  const handleProfileInput = async () => {
    const file: File | undefined = profileInput.current?.files?.[0];
    if (file && file.type.startsWith("image")) {
      setProfilLoading(true);
      const result = await updatePhotoProfilDraft(
        file,
        oemProgram.id,
        userReducer.token
      );
      if (result.status === 200) {
        dispatch(updateOemProgramDraftImages("profil", result.data));
      }
      setProfilLoading(false);
    }
  };

  const handleRemoveLogoOemProgram = async () => {
    setProcessingRemovePicture(true);
    //update api
    const response = await postDataFormDataService(
      {
        _photoProfile: "1",
      },
      URL.oem.get_program + oemProgram.id,
      userReducer.token
    );
    if (response?.status === 200) {
      //update redux
      dispatch(remove_logo_oem_program(response?.data?.photo_profil));
    }
    if (profileInput && profileInput?.current?.value) {
      profileInput.current.value = "";
    }
    setProcessingRemovePicture(false);
    setShowPopupRemoveLogo(false);
  };

  const handleRemoveCouvertureOemProgram = async () => {
    setProcessingRemovePicture(true);
    //update api
    const response = await postDataFormDataService(
      {
        _photoCouverture: "1",
      },
      URL.oem.get_program + oemProgram.id,
      userReducer.token
    );
    if (response?.status === 200) {
      //update redux
      dispatch(remove_couverture_oem_program(response?.data?.photo_couverture));
    }
    if (backgroundInput && backgroundInput?.current?.value) {
      backgroundInput.current.value = "";
    }
    setProcessingRemovePicture(false);
    setShowPopupRemoveCouverture(false);
  };

  return {
    backgroundInput,
    profileInput,
    handleBackgroundInput,
    handleProfileInput,
    defaultValue,
    profilLoading,
    backgroundLoading,
    showPopupRemoveCouverture,
    setShowPopupRemoveCouverture,
    setShowPopupRemoveLogo,
    showPopupRemoveLogo,
    processingRemovePicture,
    handleRemoveLogoOemProgram,
    handleRemoveCouvertureOemProgram,
  };
}
