/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Header from '../../../widget/index';
import Footer from '../../../widget/footer/footer';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import ModalConfirm from './modal/modalConfirm';
import UseCompanyCtr from '../../../../controller/company-ctr/create-company/use-company-ctr';
import { ReactComponent as Loader } from '../../../../resources/images/Rolling.svg';
import CompanyPageCard from './company_page_card/company-page-card';
import FormAddEmailClaimOwnerShip from './request-for-company/claim-ownership/modal-claim-ownership/index';
import FormAddRequestAdmin from './request-for-company/request-admin-access/modal-request-admin/index';
import { Redirect } from 'react-router-dom';
import SuccessRequestClaim from './request-for-company/claim-ownership/modal-success-claim-owner/index';
import SuccessRequestAdmin from './request-for-company/request-admin-access/modal-success-request-admin/index';
import * as T from '../../../../controller/company-ctr/types/types-company-page';
import * as Form from './form-create-company-page/index';
import UseClaimCtr from '../../../../controller/company-ctr/create-company/block-create-company-controller/use-claim-ctr';
import UseRequestAdminCtr from '../../../../controller/company-ctr/create-company/block-create-company-controller/use-request-admin-ctr';
import BecomeVerified from './request-for-company/become-verified/modal-become-verified/index';
import VerifyingBecomeVerified from './request-for-company/become-verified/modal-verifying-become-verified/index';
import SuccessBecomeVerified from './request-for-company/become-verified/modal-success-become-verified/index';
import UseBecomeVerifiedCtr from '../../../../controller/company-ctr/create-company/block-create-company-controller/use-become-verified-ctr';
import FormContactUs from './request-for-company/contact-us/modal-contact-us/index';
import SuccessContactUs from './request-for-company/contact-us/modal-success-contact-us/index';
import { checkIfExist, fileUrl } from '../../../../utils';
import { getData } from '../../../../service/api';
import { rootState } from '../../../../redux/reducers';
export default function CreateCompany() {
  const providerUseCompanyCtr: any = { ...UseCompanyCtr() };
  const provideUseClaimCtr = { ...UseClaimCtr() };
  const providerUseRequestAdminCtr = { ...UseRequestAdminCtr() };
  const providerUseBecomeVerifiedCtr = { ...UseBecomeVerifiedCtr() };
  const userState = useSelector((state: rootState) => state.userReducer);

  /**
   * @param type string , for detect if show become verified
   * @param element object , initialise element
   */
  const customRenderModal = (type: string, element: any) => {
    if (type == '1') {
      if (
        providerUseCompanyCtr?.userObject?.primary_email == '' ||
        providerUseCompanyCtr?.userObject?.primary_email == null
      ) {
        providerUseBecomeVerifiedCtr.setIsBecomeVerified(true);
      } else {
        providerUseRequestAdminCtr?.showFormRequestAdmin(element);
      }
    } else {
      if (
        providerUseCompanyCtr?.userObject?.primary_email == '' ||
        providerUseCompanyCtr?.userObject?.primary_email == null
      ) {
        providerUseBecomeVerifiedCtr.setIsBecomeVerified(true);
      } else {
        provideUseClaimCtr?.showFormClaim(element);
      }
    }
  };

  /**
   * show become verified id work email not exist
   */
  const customCreteCompanyPageScren = () => {
    const nextPage =
      providerUseCompanyCtr?.userObject?.isvInCompanyPage?.company_page?.next_page?.toString() ||
      '';
    const isNotVerifiedEmail =
      providerUseCompanyCtr?.userObject?.primary_email == '' ||
      providerUseCompanyCtr?.userObject?.primary_email == null;
    if (isNotVerifiedEmail) {
      (async () => {
        let response = await getData(
          '/api/odoo/verified_pro/checking/' + userState.id,
          userState.token
        );

        if (response.status === 200) {
          if (response.data === false) {
            providerUseBecomeVerifiedCtr.setIsBecomeVerified(true);
          } else {
            provideUseClaimCtr?.handleShowSuccessContactUs(true);
          }
        }
      })();
    } else {
      providerUseCompanyCtr?.customFormShow(nextPage);
    }
  };

  const renderCompanies = (companies: Array<T.TCompanyShearch>) => (
    <>
      {companies?.map((element: T.TCompanyShearch) => (
        <li key={element.id}>
          <CompanyPageCard
            menuDrop={
              <div className='menudrop'>
                <Dropdown drop='left'>
                  <Dropdown.Toggle id='dropdown-basic'>
                    <span className='icon-inprogress'></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href='javascript:;'
                      onClick={() =>
                        providerUseRequestAdminCtr?.setIsBecomeVerified(true)
                      }
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item href='javascript:;'>Remove</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }
            linkRequest={
              <a
                href='javascript:;'
                onClick={() => {
                  customRenderModal(element?.status, element);
                }}
                className='request'
              >
                {element?.status === '1'
                  ? 'Request admin access'
                  : 'Claim ownership'}
              </a>
            }
            photo_profil={fileUrl(element.photo_profil)}
            company_name={element.company_name}
            country_name={element.country_name}
            website={element.website}
          />
        </li>
      ))}
    </>
  );

  const isCompletedAccount =
    providerUseCompanyCtr?.companyPageObject?.completed &&
    providerUseCompanyCtr?.userObject?.isvInCompanyPage?.company_page
      ?.completed &&
    checkIfExist(
      providerUseCompanyCtr?.userObject?.isvInCompanyPage?.company_page.id
    );

  if (isCompletedAccount) {
    return <Redirect to='/company/profil' />;
  }

  return (
    <div>
      <Header />
      <section className='createCompany'>
        <div className='container mx-auto'>
          <div className='instruction'>
            <h2 className='title'>
              Get the most
              <br /> out of Devinsider
            </h2>
            With your company page you get access to relevant technology
            programs and a pool of investors that are looking for opportunities
            in the software industry.
          </div>
          <div className='listOfProgram'>
            <div className='notHaveComapny'>
              Your company doesn't appear on the results below ?
              <a
                href='javascript:;'
                onClick={() => customCreteCompanyPageScren()}
                className='createCompanyLink'
              >
                Create a company page
              </a>
            </div>
            <div className='list'>
              <h2 className='title'>
                <b> {providerUseCompanyCtr?.proposalCompanies?.length} </b>{' '}
                result
                {providerUseCompanyCtr?.proposalCompanies?.length > 1
                  ? 's'
                  : ''}
                &nbsp;for your company
                <b> {providerUseCompanyCtr?.userObject?.company_name}</b>
              </h2>
              <ul className='resultListCompany'>
                {renderCompanies(providerUseCompanyCtr?.proposalCompanies)}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <FormAddEmailClaimOwnerShip
        showRequestStep1={provideUseClaimCtr?.showFormAddEmailClaim}
        companyName={provideUseClaimCtr?.companyTosendClaim?.companyName}
        pays={provideUseClaimCtr?.companyTosendClaim?.pays}
        webSite={provideUseClaimCtr?.companyTosendClaim?.webSite}
        acceptTerm={provideUseClaimCtr?.companyTosendClaim?.acceptTerm}
        workEmail={provideUseClaimCtr?.companyTosendClaim?.workEmail}
        logoCompany={provideUseClaimCtr?.companyTosendClaim?.logoCompany}
        toogleAcceptTerm={(p: boolean) =>
          provideUseClaimCtr?.toogleAcceptTerm(p)
        }
        errorDiffWorkEmail={provideUseClaimCtr?.errorDiffWorkEmail}
        emailRequest={provideUseClaimCtr?.emailRequest}
        sendReQuestClaim={() => provideUseClaimCtr?.sendReQuestClaim()}
        isShowLoaderSendClaim={provideUseClaimCtr?.isShowLoaderSendClaim}
        cancelSendClaim={() => provideUseClaimCtr?.cancelSendClaim()}
        showContactUs={() => provideUseClaimCtr?.showFormContactUs()}
      />
      <SuccessRequestClaim
        showRequestStep3={provideUseClaimCtr?.isShowSuccessRequestClaim}
        handleCloseRequestStep3={() =>
          provideUseClaimCtr?.setIsShowSuccessRequestClaim(false)
        }
        showContactUs={() => provideUseClaimCtr?.showFormContactUs()}
        email={provideUseClaimCtr?.emailRequest}
      />

      <FormAddRequestAdmin
        showRequestAdmin={providerUseRequestAdminCtr?.showRequestAdmin}
        companyName={
          providerUseRequestAdminCtr?.companyTosendClaim?.companyName
        }
        pays={providerUseRequestAdminCtr?.companyTosendClaim?.pays}
        webSite={providerUseRequestAdminCtr?.companyTosendClaim?.webSite}
        acceptTerm={providerUseRequestAdminCtr?.companyTosendClaim?.acceptTerm}
        workEmail={providerUseRequestAdminCtr?.companyTosendClaim?.workEmail}
        logoCompany={
          providerUseRequestAdminCtr?.companyTosendClaim?.logoCompany
        }
        toogleAcceptTerm={(p: boolean) =>
          providerUseRequestAdminCtr?.toogleAcceptTerm(p)
        }
        errorDiffWorkEmail={providerUseRequestAdminCtr?.errorDiffWorkEmail}
        emailRequest={providerUseRequestAdminCtr?.emailRequest}
        cancelRequestAdmin={() =>
          providerUseRequestAdminCtr.cancelRequestAdmin()
        }
        sendReQuestAdmin={() => providerUseRequestAdminCtr.sendReQuestAdmin()}
        isShowLoaderSendRequest={
          providerUseRequestAdminCtr.isShowLoaderSendRequest
        }
      />

      <SuccessRequestAdmin
        showSuccessMessage={
          providerUseRequestAdminCtr?.isShowSuccessRequestAdmin
        }
        closeSucessMessage={() =>
          providerUseRequestAdminCtr?.setIsShowSuccessRequestAdmin(false)
        }
        showFormContactUs={() => provideUseClaimCtr?.showFormContactUs()}
      />

      <BecomeVerified
        isShowBecomeVerified={
          providerUseBecomeVerifiedCtr?.isShowBecomeVerified
        }
        setIsBecomeVerified={() =>
          providerUseBecomeVerifiedCtr?.setIsBecomeVerified(
            !providerUseBecomeVerifiedCtr?.isShowBecomeVerified
          )
        }
        showFormSendEmail={() => {
          providerUseBecomeVerifiedCtr?.setIsBecomeVerified(
            !providerUseBecomeVerifiedCtr?.isShowBecomeVerified
          );
          providerUseBecomeVerifiedCtr?.handleVerifieEmail(
            !providerUseBecomeVerifiedCtr?.verifieEmail
          );
        }}
      />

      <VerifyingBecomeVerified
        isShowEmail={providerUseBecomeVerifiedCtr?.verifieEmail}
        setIsShowEmail={() =>
          providerUseBecomeVerifiedCtr?.handleVerifieEmail(
            !providerUseBecomeVerifiedCtr?.verifieEmail
          )
        }
        isCheched={providerUseBecomeVerifiedCtr?.isCheched}
        setIsCheched={() =>
          providerUseBecomeVerifiedCtr?.setIsCheched(
            !providerUseBecomeVerifiedCtr?.isCheched
          )
        }
        showContactUs={() => {
          providerUseBecomeVerifiedCtr?.handleVerifieEmail(
            !providerUseBecomeVerifiedCtr?.verifieEmail
          );
          provideUseClaimCtr?.showFormContactUs();
        }}
        sendRequest={() => providerUseBecomeVerifiedCtr?.sendRequest()}
        setWorkEmailTovalid={(p: string) =>
          providerUseBecomeVerifiedCtr?.setWorkEmailTovalid(p)
        }
        workEmailTovalid={providerUseBecomeVerifiedCtr?.workEmailTovalid}
        errorEmail={providerUseBecomeVerifiedCtr?.errorEmail}
        emailErrorTxt={providerUseBecomeVerifiedCtr?.emailErrorTxt}
        loader={providerUseBecomeVerifiedCtr?.loader}
      />

      <SuccessBecomeVerified
        isShowSuccess={providerUseBecomeVerifiedCtr?.isShowSuccess}
        setIsShowSuccess={() =>
          providerUseBecomeVerifiedCtr?.setIsShowSuccess(
            !providerUseBecomeVerifiedCtr?.isShowSuccess
          )
        }
        emailTxt={providerUseBecomeVerifiedCtr?.workEmailTovalid}
      />

      <FormContactUs
        isShowContactUs={provideUseClaimCtr?.isShowContactUs}
        sendFormContactUs={() => provideUseClaimCtr?.sendFormContactUs()}
        required
        labelEmailAdress='emailAdress'
        labelPhoneNumber='phoneNumber'
        labelTextMessage='textMessage'
        labelName='name'
        errors={provideUseClaimCtr?.errors}
        handleSubmit={(p: any) => provideUseClaimCtr?.handleSubmit(p)}
        submit={(p: any) => provideUseClaimCtr?.submitFormContactUs(p)}
        register={provideUseClaimCtr?.register}
        setCloseContactUsModal={() =>
          provideUseClaimCtr?.setCloseContactUsModal()
        }
        loaderContactUs={provideUseClaimCtr?.loaderContactUs}
        selected={provideUseClaimCtr?.selected}
        setSelected={(p: string) => provideUseClaimCtr?.setSelected(p)}
      />
      <SuccessContactUs
        handleShowSuccessContactUs={(p: boolean) =>
          provideUseClaimCtr?.handleShowSuccessContactUs(p)
        }
        isShowSuccessContactUs={provideUseClaimCtr?.isShowSuccessContactUs}
      />

      <Form.CreateCompanyForm1
        labelCompanyLegalName='companyLegalName'
        labelCompanyTradeName='companyTradeName'
        labelWebSite='webSite'
        register={providerUseCompanyCtr?.register}
        required
        errors={providerUseCompanyCtr?.errors}
        showStep1={providerUseCompanyCtr?.showStep1}
        handleSubmit={(p: any) => providerUseCompanyCtr?.handleSubmit(p)}
        submit={(p: any) => providerUseCompanyCtr?.submit(p)}
        handleCloseStep1={providerUseCompanyCtr?.handleCloseStep1}
        getValuesForm={providerUseCompanyCtr?.getValues}
      />

      <Form.CreateCompanyForm2
        hQLocation='hQLocation'
        companySize='companySize'
        city='city'
        dataCity={providerUseCompanyCtr?.dataCompleted?.country || []}
        companySizeData={providerUseCompanyCtr?.dataCompleted?.company_size}
        annualTurnover='annualTurnover'
        annualTurnoverData={
          providerUseCompanyCtr?.dataCompleted?.annual_turnover
        }
        foundingDate='foundingDate'
        register={providerUseCompanyCtr?.registerForm2}
        required
        errors={providerUseCompanyCtr?.errorsForm2}
        getValuesForm2={providerUseCompanyCtr?.getValuesForm2}
        showStep2={providerUseCompanyCtr?.showStep2}
        handleSubmitForm2={(params: any) =>
          providerUseCompanyCtr?.handleSubmitForm2(params)
        }
        submitForm2={(p: any) => providerUseCompanyCtr?.submitForm2(p)}
        handleBackStep1={() => providerUseCompanyCtr?.handleBackStep1()}
        handleCloseStep2={() => providerUseCompanyCtr?.handleCloseStep2()}
      />

      <Form.CreateCompanyForm3
        solutionLeftColumnData={providerUseCompanyCtr?.solutionLeftColumnData}
        solutionRigthColumnData={providerUseCompanyCtr?.solutionRigthColumnData}
        solutionChecked={providerUseCompanyCtr?.solutionChecked}
        solutionCheckedValue={providerUseCompanyCtr?.solutionCheckedValue}
        allSolutionSellected={providerUseCompanyCtr?.allSolutionSellected}
        featuredSolution={providerUseCompanyCtr?.featuredSolution}
        selectSolution={providerUseCompanyCtr?.selectSolution}
        removeItemSelected={providerUseCompanyCtr?.removeItemSelected}
        errorData={providerUseCompanyCtr?.isHaveError}
        showStep3={providerUseCompanyCtr?.showStep3}
        handleBackStep2={() => providerUseCompanyCtr?.handleBackStep2()}
        handleCloseStep3={() => providerUseCompanyCtr?.handleCloseStep3()}
        submitForm3={() => providerUseCompanyCtr?.submitForm3()}
        allOtherValue={providerUseCompanyCtr.allOtherValue}
        setOtherValueTxt={(data: any, txtOther) =>
          providerUseCompanyCtr.setOtherValueTxt(data, txtOther)
        }
      />

      <Form.CreateCompanyForm4
        companyTarget={providerUseCompanyCtr?.companyTarget}
        companyTargetSecound={providerUseCompanyCtr?.companyTargetSecound}
        lastCompanyTarget={providerUseCompanyCtr?.lastCompanyTarget}
        errorData={providerUseCompanyCtr?.isHaveErrorTargetCompany}
        companyTargetSelected={providerUseCompanyCtr?.companyTargetSelected}
        addTargetCompany={providerUseCompanyCtr?.addTargetCompany}
        showStep4={providerUseCompanyCtr?.showStep4}
        handleBackStep3={() => providerUseCompanyCtr?.handleBackStep3()}
        handleCloseStep4={() => providerUseCompanyCtr?.handleCloseStep4()}
        submitForm4={() => providerUseCompanyCtr?.submitForm4()}
      />

      <Form.CreateCompanyForm5
        licencing={providerUseCompanyCtr?.dataCompleted?.licensing_model}
        distribution={
          providerUseCompanyCtr?.dataCompleted?.distribution_channel
        }
        addLicencing={(data: any) => {
          providerUseCompanyCtr?.addLicencingChecked(data);
        }}
        addDistribution={(data: any) =>
          providerUseCompanyCtr?.addDistributionChecked(data)
        }
        licenCingModelSelected={providerUseCompanyCtr?.licenCingModelSelected}
        distributedChanelSelected={
          providerUseCompanyCtr?.distributedChanelSelected
        }
        isErrorLicencingModel={providerUseCompanyCtr?.isErrorLicencingModel}
        isErrorDistribuTionChanel={
          providerUseCompanyCtr?.isErrorDistribuTionChanel
        }
        showStep5={providerUseCompanyCtr?.showStep5}
        handleBackStep4={() => providerUseCompanyCtr?.handleBackStep4()}
        handleCloseStep5={() => providerUseCompanyCtr?.handleCloseStep5()}
        submitForm5={() => providerUseCompanyCtr?.submitForm5()}
      />

      <Form.CreateCompanyForm6
        office={providerUseCompanyCtr?.dataCompleted?.office}
        selectedOffice={providerUseCompanyCtr?.selectedOffice}
        allContinents={providerUseCompanyCtr?.allContinents}
        paysChecked={providerUseCompanyCtr?.paysChecked}
        allContinentSelected={providerUseCompanyCtr?.allContinentSelected}
        isShowOfficeData={providerUseCompanyCtr?.isShowOfficeData}
        isShowErroCountrySelected={
          providerUseCompanyCtr?.isErrorCountrySelected
        }
        isShowErrorOffice={providerUseCompanyCtr?.isShowErrorOffice}
        addPaysInArray={providerUseCompanyCtr?.addPaysInArray}
        addOffice={providerUseCompanyCtr?.addOffice}
        showStep6={providerUseCompanyCtr?.showStep6}
        handleBackStep5={() => providerUseCompanyCtr?.handleBackStep5()}
        handleCloseStep6={() => providerUseCompanyCtr?.handleCloseStep6()}
        nextSubmitForm6={() => providerUseCompanyCtr?.submitForm6()}
        submitForm6={() => providerUseCompanyCtr?.submitForm6(true)}
      />

      <Form.CreateCompanyForm7
        technologiesPartnership={
          providerUseCompanyCtr?.dataCompleted?.technologies_partnership
        }
        currentPartener={providerUseCompanyCtr?.technologiesPartnership}
        addParterShip={providerUseCompanyCtr?.addParterShip}
        partenerShipSelected={providerUseCompanyCtr?.partenerShipSelected}
        checkedSoftware={providerUseCompanyCtr?.checkedSoftware}
        setCheckedSoftware={(p: string) =>
          providerUseCompanyCtr?.setCheckedSoftware(p)
        }
        isShowErrorPartenerShip={providerUseCompanyCtr?.isShowErrorPartenerShip}
        setCurrentSelected={(param: any) =>
          providerUseCompanyCtr?.setCurrentSelected(param)
        }
        currentSelected={providerUseCompanyCtr?.currentSelected}
        addListOption={(param: any) =>
          providerUseCompanyCtr?.addListOption(param)
        }
        removeSelected={(param: any) =>
          providerUseCompanyCtr?.removeSelected(param)
        }
        currentSelectedList={providerUseCompanyCtr?.currentSelectedList}
        showStep7={providerUseCompanyCtr?.showStep7}
        handleBackStep6={() => providerUseCompanyCtr?.handleBackStep6()}
        handleCloseStep7={() => providerUseCompanyCtr?.handleCloseStep7()}
        nextSubmitForm7={() => providerUseCompanyCtr?.submitForm7()}
        submitForm7={() => providerUseCompanyCtr?.submitForm7(true)}
      />

      <Form.CreateCompanyForm8
        fundingRound={providerUseCompanyCtr?.dataCompleted?.fundingRound}
        amontWouldRise={
          providerUseCompanyCtr?.dataCompleted?.amount_you_would_like_to_raise
        }
        setFundingRound={(value: any) =>
          providerUseCompanyCtr?.setFundingRound(value)
        }
        setAmountWouldLike={(value: any) =>
          providerUseCompanyCtr?.setAmountWouldLike(value)
        }
        fundingRoundValue={providerUseCompanyCtr?.fundingRoundValue}
        amountWouldLike={providerUseCompanyCtr?.amountWouldLike}
        isShowErrorFoundindRound={
          providerUseCompanyCtr?.isShowErrorFoundindRound
        }
        isShowAMountWouldLike={providerUseCompanyCtr?.isShowAMountWouldLike}
        showStep8={providerUseCompanyCtr?.showStep8}
        handleBackStep7={() => providerUseCompanyCtr?.handleBackStep7()}
        handleCloseStep8={() => providerUseCompanyCtr?.handleCloseStep8()}
        completeCreateCompanyPage={() =>
          providerUseCompanyCtr?.completeCreateCompanyPage()
        }
        loader={providerUseCompanyCtr?.loader}
      />

      {/* Step 9 Modal confirm */}
      <Modal
        show={providerUseCompanyCtr?.isShowModalConfirm}
        className='createCompanypopup smallPopup'
      >
        <Modal.Header>
          <div className='headerpopupCompany'>
            <h2 className='title'>Are you sure you want to leave?</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ModalConfirm />
        </Modal.Body>
        <Modal.Footer className='leaveProgress'>
          <div className='contentBorder m-0 d-flex'>
            <button
              className='btn btn-devinsider containerBtnBtn'
              onClick={() => providerUseCompanyCtr?.leaveFormCreateCompany()}
            >
              {providerUseCompanyCtr?.loader ? <Loader /> : 'Yes'}
            </button>
            <a
              className='btn btn-devinsider containerBtnBtn'
              onClick={() => providerUseCompanyCtr?.cancelCreateCompanyPage()}
            >
              No
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
