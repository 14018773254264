import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import { isURL, refaCtoNullKey } from "../../../../../../../../utils";
import ReactFlagsSelect from "react-flags-select";
import { contact } from "../../../../../../../../utils/country_phone";
import Button from "react-bootstrap/Button";
import { ReactComponent as Loader } from "../../../../../../../../resources/images/Rolling.svg";
import { IpropsContact } from "./type";
export default function ModalEditInfoUser(props: IpropsContact) {
  return (
    <Modal
      show={props?.contactShow}
      onHide={() => props?.setContactShow(false)}
      className="modalDevinsider medium"
    >
      <Modal.Header closeButton>
        <Modal.Title>Contact information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row m-0 p-0">
          <div className="col-12 p-0 form-group">
            <label htmlFor="" className="smallIcon">
              Email
            </label>
            <input
              type="text"
              className="input-text form-control"
              readOnly
              value={props?.email}
            />
          </div>

          <div className="col-12 p-0 form-group">
            <label htmlFor="" className="smallIcon">
              Phone number{" "}
            </label>
            {props?.statePhone && (
              <div className={`container-error pl-2 "d-inline`}>
                <span className="error-red"> error </span>
                <span className="txt-error">
                  The phone number is not a valid format
                </span>
              </div>
            )}
            <div className="numberphone" style={{ position: "relative" }}>
              <input
                type="number"
                className="phonenumber"
                style={{
                  textIndent:
                    contact?.find((el: any) => el.name == props?.codeFlags)
                      ?.dial_code?.length == 2
                      ? 35
                      : contact?.find((el: any) => el.name == props?.codeFlags)
                          ?.dial_code?.length == 3
                      ? 50
                      : contact?.find((el: any) => el.name == props?.codeFlags)
                          ?.dial_code?.length == 4
                      ? 50
                      : 60,
                  paddingBottom: 5,
                }}
                value={props?.phone}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  props?.setPhone(evt.target.value)
                }
              />
              <span style={{ position: "absolute", top: 5, left: 60 }}>
                (
                {contact &&
                  contact.find((el: any) => el.code == props?.codeFlags)
                    ?.dial_code}
                )
              </span>
              <ReactFlagsSelect
                className="selectFlag"
                selected={props?.codeFlags}
                onSelect={(code) => {
                  props?.setcodeFlags(code);
                }}
                searchable={true}
                showSelectedLabel={false}
                placeholder=" "
              />
            </div>
          </div>
          <div className="col-12 p-0 form-group">
            <label htmlFor="">Linkedin </label>
            {props?.stateLinkDean && (
              <div className={`container-error pl-2 "d-inline`}>
                <span className="error-red"> error </span>
                <span className="txt-error">
                  your linkedin url is not a valid
                </span>
              </div>
            )}
            <div className="linkedintext">
              linkedin.com/in/
              <input
                type="text"
                className="input-text form-control"
                value={refaCtoNullKey(props.linkedin)}
                onBlur={() => {
                  let url: string = props?.linkedin;
                  if (url && url != "" && url != "null") {
                    if (
                      isURL(url) &&
                      !url?.includes("https://www.linkedin.com/in/")
                    ) {
                      props?.setStateLinkDean(true);
                    } else {
                      let custom = url?.includes("https://www.linkedin.com/in/")
                        ? url?.split("https://www.linkedin.com/in/").join("")
                        : url;

                      props?.setLinkedin(custom);
                    }
                  }
                }}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  props?.setLinkedin(evt.target.value)
                }
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancelbtn"
          onClick={() => {
            props?.cancelChange();
          }}
        >
          Cancel
        </Button>

        <Button
          className="validate"
          onClick={() => props?.validAfterUpdate()}
          disabled={props?.isLoaderShow}
        >
          {props?.isLoaderShow ? <Loader /> : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
