import React from "react";
import { useHistory } from "react-router-dom";

export default function UseAccountChoixCtr() {
  const history = useHistory();

  const goLogin = (): void => {
    history.push("/signin");
  };
  const getStarted = (): void => {
    history.push("/account-choise");
  };
  const navigateCompleteInfo = (): void => {
    history.push("/complete-info");
  };

  const completResellerAcount = (): void => {
    history.push("/complete-info-reseller");
  };
  const programRegistration = (): void => {
    history.push("/partner-program-registration");
  };

  const navigateCompleteInvestor = () => {
    history.push("/complete-info-investor");
  };
  return {
    goLogin,
    navigateCompleteInfo,
    completResellerAcount,
    navigateCompleteInvestor,
    getStarted,
    programRegistration,
  };
}
