import {
  getDataService,
  postJsonDataService,
  deleteMethode,
  postDataFormDataService,
} from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import store from "../../store";
import { Dispatch } from "../../types/event-interface";

export const constante = {
  GET_EVENT_FILTERS: "GET_FILTERS_EVENT",
  GET_ALL_EVENTS: "GET_ALL_EVENTS",
  APPEND_ALL_EVENTS: "APPEND_ALL_EVENTS",
  GET_MY_EVENTS: "GET_MY_EVENTS",
  APPEND_MY_EVENTS: "APPEND_MY_EVENTS",
  DELETE_EVENTS: "DELETE_EVENTS",
  UPDATE_MY_EVENTS: "UPDATE_MY_EVENTS",
};

export const getFilters = (callback: (data: any) => void) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await getDataService(
        url?.programs?.left_menu?.get_event,
        {},
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante.GET_EVENT_FILTERS,
          payload: response?.data,
        });
      }
      callback(response);
    } catch (error) {
      console?.log(error);
    }
  };
};

export const getallEvents = (
  params: {
    tags: string;
    event_type: string;
    limit: number;
    page: number;
    search: string;
  },
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.events?.get_all_events,
        params,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        // if (response?.data?.results?.length < 1) {
        //   callback(response);
        //   return true;
        // }
        dispatch({
          type: constante.GET_ALL_EVENTS,
          payload: {
            ...response?.data,
            current_page: 1,
          },
        });
      }
      callback(response);
    } catch (error: any) {}
  };
};

export const appendallEvents = (
  params: {
    tags: string;
    event_type: string;
    limit: number;
    page: number;
    search: string;
  },
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.events?.get_all_events,
        params,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        if (response?.data?.results?.length < 1) {
          callback(response);
          return true;
        }
        dispatch({
          type: constante.APPEND_ALL_EVENTS,
          payload: {
            ...response?.data,
            current_page: params?.page,
          },
        });
      }
      callback(response);
    } catch (error: any) {}
  };
};

export const getMyEvents = (
  params: {
    oem_program_id: string;
    tags: string;
    event_type: string;
    limit: number;
    page: number;
    search: string;
  },
  isModerator: boolean = false,
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      const tempurl = isModerator
        ? url?.events?.get_all_events
        : url?.events?.get_my_events;
      let response = await postJsonDataService(
        tempurl,
        params,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante.GET_MY_EVENTS,
          payload: response?.data,
        });
      }
      callback(response);
    } catch (error: any) {}
  };
};

export const appendMyEvents = (
  params: {
    oem_program_id: number;
    tags: string;
    event_type: string;
    limit: number;
    page: number;
    search: string;
  },
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.events?.get_my_events,
        params,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        if (response?.data?.results?.length < 1) {
          callback(response);
          return true;
        }
        dispatch({
          type: constante.APPEND_MY_EVENTS,
          payload: response?.data,
        });
      }
      callback(response);
    } catch (error: any) {}
  };
};

export const deleteEventsAction = (
  eventId: number,
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await deleteMethode(
        url?.events?.delete_events + eventId,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 201) {
        dispatch({
          type: constante.DELETE_EVENTS,
          payload: eventId,
        });
      }
      callback(response);
    } catch (error: any) {}
  };
};

export const updateEventsAction = (
  eventId: string,
  params: any,
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postDataFormDataService(
        params,
        url?.events?.update_events + eventId,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante.UPDATE_MY_EVENTS,
          payload: response?.data,
        });
      }
      callback(response);
    } catch (error: any) {}
  };
};
