/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from 'react';
import Header from '../../../../widget/header-logo-center/header-logo-center';
import UseCompletePictureExpert from '../../../../../controller/account-ctr/complete-create-acount-expert-contributor/use-complete-picture-expert';
import useGoBack from '../../../../../hooks/use-navigate';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import { checkIfExist, getSStorage } from '../../../../../utils';
import { Redirect } from 'react-router-dom';

export default function CompletePictureExpert() {
  const {
    isFilePicked,
    selectedFile,
    fileRef,
    history,
    changeHandler,
    handleBtn,
    firstNameExpert,
    lastNameExpert,
    companyExpert,
    jobTitleExpert,
    loader,
    createExpertUser,
    fileSizeError,
    skipLoader,
    skipvalidateExpertInscription
  } = UseCompletePictureExpert();
  const { goBack } = useGoBack();

  const sess = getSStorage('businessEmailExpert');

  if (!checkIfExist(sess)) {
    return <Redirect to='/start-expert' />;
  }

  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <div className='head-getstarted medium'>
          <div className='stepInscription'>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            {/* <div className="stepItem"></div> */}
          </div>
          <a className='link-back' onClick={goBack}>
            <span className='icon-back'></span>
          </a>
          <h1>upload your profile picture</h1>
          {fileSizeError && (
            <span className='txt-error'>The maximum file size is 2 MB. Please upload a photo with a smaller file size.</span>
          )}
        </div>
        <div className='contentBorder row align-items-stretch small-container'>
          <div className='col-xl-7 col-md-6 col-12 d-flex align-items-center photo-upload-getstrated'>
            <a
              className={`upload-photo ${selectedFile ? 'noBackground' : ''}`}
              onClick={() => handleBtn()}
            >
              {isFilePicked && selectedFile ? (
                <img src={URL.createObjectURL(selectedFile)} alt='' />
              ) : (
                <span></span>
              )}
            </a>
            <div className='info-final-getstarted'>
              <div className='nom'>
                {firstNameExpert} {lastNameExpert}
              </div>
              <div className='post'>{jobTitleExpert}</div>
              <div className='post'>
                <b>{companyExpert}</b>
              </div>
              <div className='membersince'>
                {`Member since ${new Date().toLocaleString('en-US', {
                  month: 'long',
                })} ${new Date().getDate()},  ${new Date().getFullYear()} `}{' '}
              </div>
            </div>
          </div>
          <div className='col-xl-5 col-md-6 col-12 d-flex flex-column align-items-center justify-content-center py-4 p-0 '>
            <a className='link-add-photo' onClick={() => handleBtn()}>
              Add photo
            </a>
            <input
              type='file'
              id='getFile'
              accept='image/png, image/jpeg'
              ref={fileRef}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                changeHandler(event)
              }
              style={{ display: 'none' }}
            />
            <p>
              (Max file size: 2 MB)
            </p>
          </div>
        </div>
        <div className='contentBorder row align-items-stretch'>
          <div className='col-12 d-flex align-items-center flex-column p-0'>
            <button
              className='btn btn-devinsider large'
              onClick={createExpertUser}
            >
              {loader ? <Loader /> : 'Done'}
            </button>
            {/* <a className="skip">Skip</a> */}
          </div>
          {skipLoader ? (
            <Loader />
          ) : (
            <a className="skip" onClick={() => skipvalidateExpertInscription()}>
              Skip
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
