import OemProfilPage from '../screen/container/oem/profil-oem/index';
import CompleteProfilOem from '../screen/container/acount/create-oem-account/complete-profil-oem';
import CompleteCompanyOem from '../screen/container/acount/create-oem-account/complete-company-oem';
import CompleteWebSiteLocationOem from '../screen/container/acount/create-oem-account/complete-website-location-oem';
import CompleteProgramTypeOem from '../screen/container/acount/create-oem-account/complete-program-type-oem';
import CompleteCompanyTargetOem from '../screen/container/acount/create-oem-account/complete-company-target-oem';
import CompleteCategoriesOem from '../screen/container/acount/create-oem-account/complete-categories-oem';
import CompleteIndustryOem from '../screen/container/acount/create-oem-account/complete-industry-oem';
import CompleteInterested from '../screen/container/acount/create-oem-account/complete-interested-oem/complete-interested';
import CompleteProfilPicture from '../screen/container/acount/create-oem-account/complete-profil-picture-oem/complete-profil-picture';

import CompleteInfo from '../screen/container/acount/complete-process-oem-create-account/complete-info';
import CompleteAdress from '../screen/container/acount/complete-process-oem-create-account/complete-adress';
import CompleteProfile from '../screen/container/acount/complete-process-oem-create-account/uploadProfile';

import FindProgramLanding from '../screen/container/programs/home-find-program/find-program-landing';

export const routesOEM = [
  {
    path: '/OEM/profil/:id',
    guard: 'private',
    exact: true,
    component: OemProfilPage,
  },
  {
    path: '/complete-profil-oem',
    exact: true,
    component: CompleteProfilOem,
    guard: 'public',
  },
  {
    path: '/complete-profil-oem-interest',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteInterested,
  },
  {
    path: '/complete-profil-picture-oem',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteProfilPicture,
  },

  {
    path: '/complete-company-oem',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteCompanyOem,
  },
  {
    path: '/complete-website-location-oem',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteWebSiteLocationOem,
  },
  {
    path: '/complete-program-type-oem',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteProgramTypeOem,
  },
  {
    path: '/complete-company-target-oem',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteCompanyTargetOem,
  },
  {
    path: '/complete-categories-oem',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteCategoriesOem,
  },
  {
    path: '/complete-industry-oem',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteIndustryOem,
  },

  //index process create account
  {
    path: '/complete-oem-info-affilliated',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteInfo,
  },
  {
    path: '/complete-oem-adress-affilliated',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteAdress,
  },
  {
    path: '/complete-oem-profil-affilliated',
    guard: 'not-logged-page',
    exact: true,
    component: CompleteProfile,
  },

  //Complete create program page

  {
    path: '/news-oem-website-location',
    guard: 'private',
    exact: true,
    component: CompleteWebSiteLocationOem,
  },
  {
    path: '/new-oem-program-type',
    guard: 'private',
    exact: true,
    component: CompleteProgramTypeOem,
  },

  {
    path: '/new-company-target',
    guard: 'private',
    exact: true,
    component: CompleteCompanyTargetOem,
  },
  {
    path: '/new-categories-oem',
    guard: 'private',
    exact: true,
    component: CompleteCategoriesOem,
  },
  {
    path: '/new-industry-oem',
    guard: 'private',
    exact: true,
    component: CompleteIndustryOem,
  },
  {
    path: '/landing-find-prog',
    exact: true,
    component: FindProgramLanding,
    guard: 'public',
  },
];
