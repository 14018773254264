import React from 'react';
import Header from '../../../../widget/header-logo-center/header-logo-center';
import UseCompleteInterested from '../../../../../controller/account-ctr/complete-create-acount-isv/use-complete-interested';
import UseCompleteInterestedExpert from '../../../../../controller/account-ctr/complete-create-acount-expert-contributor/use-complete-interested-expert';
import useGoBack from '../../../../../hooks/use-navigate';
import { ReactComponent as Loader } from '../../../../../resources/images/loader-chargement.svg';
import { checkIfExist, getSStorage } from '../../../../../utils';
import { Redirect } from 'react-router-dom';
export default function CompleteInterestedExpert() {
  const {
    dataInterested,
    dataChecked,
    loader,
    checkedRadio,
    navigateCompleteProfile,
  } = UseCompleteInterestedExpert();
  const { goBack } = useGoBack();

  const sess = getSStorage('businessEmailExpert');

  if (!checkIfExist(sess)) {
    return <Redirect to='/start-expert' />;
  }

  return (
    <div>
      <Header />

      <div className='getstartedContainer'>
        <div className='head-getstarted medium'>
          <div className='stepInscription'>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem'></div>
          </div>
          <a className='link-back' onClick={goBack}>
            <span className='icon-back'></span>
          </a>
          <h1>Tell us what topics are you interested in.</h1>
        </div>
        <div className={`loader-container ${loader ? 'd-flex' : 'd-none'}`}>
          <Loader />
        </div>
        <div className='contentBorder justify-content-center flex-wrap  content-link-topic d-flex pb-3'>
          {dataInterested &&
            dataInterested
              .filter((element) => element.main && element?.icone !== '')
              .map((v) => (
                <div
                  onClick={() => checkedRadio(v.id)}
                  key={v.id}
                  className={`link-topic ${
                    dataChecked?.find((el) => el == v.id) ? 'active' : ''
                  }`}
                >
                  {<div dangerouslySetInnerHTML={{ __html: v && v.icone }} />}

                  <span>{v.title}</span>
                </div>
              ))}
        </div>
        <div className='contentBorder py-3 border-top d-flex flex-wrap checkListTopic'>
          {dataInterested &&
            dataInterested
              .filter((element) => !element.main || element.icone === '')
              .map((element) => (
                <div
                  className='custom-control custom-checkbox getstartedcheckbox'
                  key={element.id}
                >
                  <input
                    type='checkbox'
                    className='custom-control-input'
                    id={`checkbox${element.id}`}
                    defaultChecked={
                      dataChecked?.find((el) => el == element.id) ? true : false
                    }
                  />
                  <label
                    className='custom-control-label'
                    htmlFor={`checkbox${element.id}`}
                    onClick={() => checkedRadio(element.id)}
                  >
                    {element.title}
                  </label>
                </div>
              ))}
        </div>
        <div className='contentBorder row align-items-stretch'>
          <div className='col-12 d-flex align-items-center p-0'>
            <button
              className='btn btn-devinsider large'
              onClick={() =>
                dataChecked && dataChecked.length >= 3
                  ? navigateCompleteProfile()
                  : null
              }
            >
              {dataChecked && dataChecked.length >= 3
                ? 'Next'
                : `Select at least ${3 - dataChecked.length} more`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
