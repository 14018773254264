import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { isRequired, setSessionStorageObject } from "../../../utils/index";
import { contact } from "../../../utils/country_phone";
import { isValidPhoneNumber } from "react-phone-number-input";
import useEventListener from "../../../hooks/event-handler";

export default function UseCompleteCountryExpert() {
  const history = useHistory();
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );
  const [selected, setSelected] = useState(
    sessionStorage.getItem("phoneSelectedExpert")
      ? contact?.find(
          (el: any) =>
            el.dial_code == sessionStorage.getItem("phoneSelectedExpert")
        )?.code || ""
      : "US"
  );

  const [dialCodeSelected, setDialCodeSelected] = useState(
    sessionStorage.getItem("phoneSelectedExpert")
      ? sessionStorage.getItem("phoneSelectedExpert")
      : "+1"
  );
  
  const [phoneNumberExpert, setPhoneNumberExpert] = useState<string>(
    sessionStorage.getItem("phoneNumberExpert")
      ? sessionStorage.getItem("phoneNumberExpert") || ""
      : ""
  );
  const [countryRegionExpert, setCountryRegionExpert] = useState(
    sessionStorage.getItem("countryRegionExpert")
      ? sessionStorage.getItem("countryRegionExpert") || ""
      : ""
  );

  // Error value required
  const [errPhoneNumberExpert, setErrPhoneNumberExpert] =
    useState<boolean>(false);
  const [errCountryRegionExpert, setErrCountryRegionExpert] =
    useState<boolean>(false);

  useEffect(() => {
    return () => {
      setErrCountryRegionExpert(false);
    };
  }, [countryRegionExpert]);

  useEffect(() => {
    return () => {
      setErrPhoneNumberExpert(false);
    };
  }, [phoneNumberExpert]);

  const validPhoneNumber = (txt: string): boolean => {
    if (isRequired(txt)) {
      if (
        isValidPhoneNumber(
          contact?.find((el: any) => el.code == selected)?.dial_code + txt
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const navigateCompleteInterested = () => {
    if (
      isRequired(countryRegionExpert) &&
      validPhoneNumber(phoneNumberExpert)
    ) {
      setSessionStorageObject({
        phoneNumberExpert,
        countryRegionExpert,
        phoneSelectedExpert: dialCodeSelected,
        countryPhoneCode: selected,
      });
      history.push("/complete-interested-expert");
    } else {
      if (!isRequired(countryRegionExpert)) {
        setErrCountryRegionExpert(true);
      }
      if (!validPhoneNumber(phoneNumberExpert)) {
        setErrPhoneNumberExpert(true);
      }
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateCompleteInterested();
    } else return;
  };
  useEventListener("keydown", keyEnter);

  return {
    dataCompleted,
    selected,
    setSelected,
    phoneNumberExpert,
    countryRegionExpert,
    setCountryRegionExpert,
    setPhoneNumberExpert,
    errCountryRegionExpert,
    errPhoneNumberExpert,
    navigateCompleteInterested,
    dialCodeSelected,
    setDialCodeSelected
  };
}
