import React from "react";
import Select from "react-select";

type IProps = {
  handleChangeSorted: (e: any) => void;
};
export default function SortedBy(props: IProps) {
  return (
    <Select
      onChange={(e: any) => props.handleChangeSorted(e)}
      className="triOreder"
      isClearable={false}
      options={[
        { value: "post_date", label: "Post date" },
        { value: "last_activity", label: "Latest activity" },
        { value: "replies", label: "Replies" },
        { value: "views", label: "Views" },
      ]}
      classNamePrefix="tri"
      defaultValue={{
        value: "activity",
        label: "Latest activity",
      }}
    />
  );
}
