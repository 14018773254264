import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import useEventListener from "../../../hooks/event-handler";

export interface ItemsSolution {
  id: number;
  name: string;
}
export interface ISolutionListe {
  id: number;
  name: string;
  segmentDetails: Array<ItemsSolution>;
}

export interface IdataMakeSolution {
  idSolutionOffert: number;
  idSegment: number;
  nameSegment: string;
}

export default function UseCompleteCompanySolutionOffertReseller() {
  const [allResellerSolution, setAllResellerSolution] = useState<
    Array<ISolutionListe>
  >([]);

  const [solutionLeftColumnData, setSolutionLeftColumnData] = useState<
    Array<ISolutionListe>
  >([]);

  const [solutionRigthColumnData, setSolutionRigthColumnData] = useState<
    Array<ISolutionListe>
  >([]);
  const [loader, setLoaderState] = useState<boolean>(false);

  const [solutionChecked, setSolutionChecked] = useState<
    Array<IdataMakeSolution>
  >(JSON.parse(sessionStorage.getItem("solution_company") || "[]"));

  const [allSolutionSellected, setAllSolutionSellected] = useState<
    Array<string>
  >([]);
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );
  const [featuredSolution, setFeaturedSolution] = useState<ISolutionListe>();

  const [errorData, seterrorData] = useState<boolean>(false);

  const goBack = () => {
    history.goBack();
  };

  const history = useHistory();

  const [allOtherValue, setAllOtherValue] = useState<
    Array<{
      idSolutionOffert: number;
      idSegment: number;
      nameSegment: string;
      otherTxt: string;
    }>
  >(JSON.parse(sessionStorage.getItem("other_software") || "[]"));

  const isNameIncludes = (value1: string, value2: string) => {
    if (
      !value1?.toLowerCase().includes("other") &&
      !value2?.toLowerCase().includes("other") &&
      value2?.trim() == "Analytics Software" &&
      value1?.trim() == "Sales Analytics Software"
    ) {
      return false;
    }
    return (
      !value1?.toLowerCase().includes("other") &&
      !value2?.toLowerCase().includes("other") &&
      (value1?.toLowerCase().includes(value2?.toLowerCase()) ||
        value2?.toLowerCase().includes(value1?.toLowerCase()))
    );
  };

  const setRightLeftFeaturedSolutions = (
    dataMake: any,
    data: IdataMakeSolution
  ) => {
    //featured solutions
    solutionLeftColumnData?.forEach((el: ISolutionListe) => {
      el?.segmentDetails?.forEach((segmentDetails: ItemsSolution) => {
        //check if already exists in solutionschecked
        let checkIfExists = solutionChecked?.find(
          (solution: any) => segmentDetails?.id === solution?.idSegment
        );
        if (
          isNameIncludes(segmentDetails?.name, data?.nameSegment) &&
          data?.idSolutionOffert !== el?.id &&
          segmentDetails?.id !== data?.idSegment &&
          !checkIfExists
        ) {
          dataMake = [
            ...dataMake,
            {
              idSegment: segmentDetails.id,
              idSolutionOffert: el.id,
              nameSegment: segmentDetails.name,
            },
          ];
        }
      });
    });
    solutionRigthColumnData?.forEach((el: ISolutionListe) => {
      el?.segmentDetails?.forEach((segmentDetails: ItemsSolution) => {
        if (
          isNameIncludes(segmentDetails?.name, data?.nameSegment) &&
          data?.idSolutionOffert !== el?.id &&
          segmentDetails?.id !== data?.idSegment
        ) {
          dataMake = [
            ...dataMake,
            {
              idSegment: segmentDetails.id,
              idSolutionOffert: el.id,
              nameSegment: segmentDetails.name,
            },
          ];
        }
      });
    });
    featuredSolution?.segmentDetails?.forEach((el: ItemsSolution) => {
      if (
        isNameIncludes(el.name, data?.nameSegment) &&
        el.id !== data.idSegment
      ) {
        dataMake = [
          ...dataMake,
          {
            idSegment: el.id,
            idSolutionOffert: featuredSolution.id,
            nameSegment: el.name,
          },
        ];
      }
    });
    return dataMake;
  };

  const setOtherValueTxt = (
    data: {
      idSolutionOffert: number;
      idSegment: number;
      nameSegment: string;
    },
    otherTxt: string
  ) => {
    const foundIfAlreadyExist = allOtherValue?.find(
      (el) => el.idSegment?.toString() === data.idSegment?.toString()
    );

    let tempTxt = [];

    if (foundIfAlreadyExist) {
      tempTxt = allOtherValue?.map((el) => {
        if (el.idSegment === data.idSegment) {
          return {
            ...el,
            otherTxt,
          };
        } else {
          return el;
        }
      });
    } else {
      tempTxt = [...allOtherValue, { ...data, otherTxt }];
    }
    setAllOtherValue(tempTxt);
  };

  const navigateCompleteIndustrieTarget = () => {
    if (solutionChecked.length > 0) {
      sessionStorage.setItem(
        "solution_company",
        JSON.stringify(solutionChecked)
      );
      sessionStorage.setItem("other_software", JSON.stringify(allOtherValue));
      history.push("/complete-company-industrie-target-reseller");
    } else {
      seterrorData(true);
    }
  };

  useEffect(() => {
    async function getSolution() {
      //let response: any = await getResellerSolution();
      let dataSolutionOffert = dataCompleted?.segments;
      // if (response && response.status == 200 && response.data) {
      let featured = dataSolutionOffert?.find((el: any) => el.id == 9999);
      setFeaturedSolution(featured);
      setAllResellerSolution(
        dataSolutionOffert?.filter((el: any) => el.id != 9999)
      );
      setLoaderState(false);
    }
    getSolution();
    return () => {};
  }, []);

  useEffect(() => {
    let lengthSolution = allResellerSolution.length;
    if (lengthSolution % 2 == 0) {
      setSolutionLeftColumnData(
        allResellerSolution.slice(0, lengthSolution / 2)
      );
      setSolutionRigthColumnData(
        allResellerSolution.slice(lengthSolution / 2, lengthSolution + 1)
      );
    } else {
      setSolutionLeftColumnData(
        allResellerSolution.slice(0, (lengthSolution - 1) / 2 + 1)
      );
      setSolutionRigthColumnData(
        allResellerSolution.slice(
          (lengthSolution - 1) / 2 + 1,
          lengthSolution + 1
        )
      );
    }
    return () => {};
  }, [allResellerSolution]);

  useEffect(() => {
    seterrorData(false);
    return () => {};
  }, [solutionChecked]);

  const transFormnData = (data: any) => {
    const removeOther = data?.solutionOffert?.filter(
      (el: any) => el.name?.toLocaleLowerCase() !== "other"
    );
    let custom = removeOther?.map((el: any) => {
      let temp = {
        idSolutionOffert: data.idSolutionOffert,
        idSegment: el && el.id,
        nameSegment: el && el.name,
      };
      return temp;
    });
    return custom;
  };

  //Dev #50488
  const selectSolution = (data: any) => {
    //DATA CUSTOM
    let dataMake: Array<IdataMakeSolution> = [];
    //FIND IF PARAM IS ARRAY OF DATA , for add multiple select All
    if (data && data.solutionOffert) {
      //find if bouton is selected all is clicked
      let solutionSellectedAll = allSolutionSellected?.find(
        (el) => el == data.idSolutionOffert
      );
      //tranform data if array
      let dataAfterTransform = transFormnData(data);
      dataMake = [...dataAfterTransform];
      //if selected all is not clicked
      if (!solutionSellectedAll) {
        //remove if item is existe deja
        let dataToStore =
          solutionChecked &&
          solutionChecked.filter(
            (el: any) => el.idSolutionOffert != data.idSolutionOffert
          );

        dataMake?.map((el: any) => {
          dataMake = setRightLeftFeaturedSolutions(dataMake, el);
        });

        //Performe data to store
        let transFormDataToStore = [...dataToStore, ...dataMake];
        //selecte all data
        setSolutionChecked(transFormDataToStore);
        //active checked selected all is clicked
        setAllSolutionSellected([
          ...allSolutionSellected,
          data.idSolutionOffert,
        ]);
      } else {
        let tempSolutions = [...solutionChecked];
        data?.solutionOffert?.map((el: any) => {
          tempSolutions = tempSolutions?.filter(
            (elem: any) =>
              el.id != elem.idSegment &&
              el.name != elem.nameSegment &&
              !isNameIncludes(elem.nameSegment, el.name)
          );
        });

        setSolutionChecked(tempSolutions);

        //Make selected all is not clicked
        setAllSolutionSellected(
          allSolutionSellected.filter((el) => el != data.idSolutionOffert)
        );
      }
    } else {
      dataMake = [data];
      let found = solutionChecked?.find(
        (el: any) => el.idSegment == data.idSegment
      );

      if (!found) {
        dataMake = setRightLeftFeaturedSolutions(dataMake, data);
        setSolutionChecked([...solutionChecked, ...dataMake]);
      } else {
        //deselection item
        const removeOtherTxt = allOtherValue?.filter(
          (tempRemove) =>
            tempRemove.idSegment.toString() !== data.idSegment?.toString()
        );

        setAllOtherValue(removeOtherTxt);

        let tempSolutions = solutionChecked?.filter(
          (elem: any) =>
            elem.idSegment != data.idSegment &&
            !isNameIncludes(elem?.nameSegment, data?.nameSegment)
        );
        setSolutionChecked(tempSolutions);

        let found2 = solutionChecked?.find(
          (elem: any) =>
            elem.idSegment != data.idSegment &&
            isNameIncludes(elem?.nameSegment, data?.nameSegment)
        );
        if(found2){
          setAllSolutionSellected(
            allSolutionSellected.filter((el) => el != data.idSolutionOffert && el != found2?.idSolutionOffert.toString())
          );
        }else{
          setAllSolutionSellected(
            allSolutionSellected.filter((el) => el != data.idSolutionOffert)
          );
        }
      }
    }
  };

  const removeItemSelected = (solution: any) => {
    let found = solutionChecked?.find(
      (el: IdataMakeSolution) => el.idSegment == solution.idSegment
    );
    if (found) {
      let temp: Array<IdataMakeSolution> = solutionChecked?.filter(
        (t: IdataMakeSolution) => {
          return (
            t.idSegment !== solution.idSegment &&
            !isNameIncludes(t.nameSegment, solution.nameSegment)
          );
        }
      );
      setSolutionChecked(temp);
      if(solution.idSolutionOffert){
         setAllSolutionSellected(
           allSolutionSellected.filter((el) => el != solution.idSolutionOffert)
         );
      }
    } else {
      return;
    }
  };

  // Dev #50488 - Reseller- Création compte
  const newFilteredSolutionChecked = (solutions: any) => {
    let alreadyExist: Array<string> = [];
    return solutions?.filter((el: IdataMakeSolution) => {
      if (alreadyExist.indexOf(el.nameSegment) === -1) {
        alreadyExist.push(el.nameSegment);
        return el;
      }
      if (el.nameSegment === "Other") return true;
    });
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateCompleteIndustrieTarget();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    navigateCompleteIndustrieTarget,
    allResellerSolution,
    solutionLeftColumnData,
    solutionRigthColumnData,
    loader,
    solutionChecked,
    allSolutionSellected,
    featuredSolution,
    errorData,
    setSolutionChecked,
    selectSolution,
    removeItemSelected,
    goBack,
    setAllOtherValue,
    allOtherValue,
    setOtherValueTxt,
    newFilteredSolutionChecked,
  };
}
