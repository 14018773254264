import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '../../header-classified-ads/Header.scss';
import React, { useState, useEffect, ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { stripePrivateKey } from '../../../../service/constant';
import { CheckoutForm, IpropsCCheckout } from './checkoutForm';
import { ReactComponent as Loader } from '../../../../resources/images/Rolling.svg';

// const stripePromise = loadStripe(stripePrivateKey);

type IProps = IpropsCCheckout & {
  show: boolean;
  setShow: (p: boolean) => void;
  modalAdds: boolean;
  closeAddsModal: () => void;
  chargingPlanDetails: boolean;
  stateAdds: {
    number_left: number;
    number_max: number;
    next_grant: string;
    spent_by: Array<{
      id: string;
      first_name: string;
      last_name: string;
      user_uri: string;
      at: string;
      number: string;
      role: string;
    }>;
  };
  handleChoise: (p: string) => void;
  choiseValue: string | undefined;
  publishAdds: () => void;

  amountClassifiedAds: string;
};
export default function index(props: IProps) {
  return (
    <Modal
      show={props?.show}
      className='createCompanypopup small forStep5custom'
    >
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>Publish classified ad</h2>
          <a
            href='javascript:;'
            className='close'
            onClick={() => props?.setShow(false)}
          >
            <span className='icon-close-pop'></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className=''>
        {/* <Elements stripe={stripePromise}>
          <CheckoutForm
            postAdsFromUser={props?.postAdsFromUser}
            amount={props.amount}
            cardComplete={props.cardComplete}
            cardEmpty={props.cardEmpty}
            cardError={props.cardError}
            loading={props.loading}
            clientSecret={props.clientSecret}
            errorMessage={props.errorMessage}
            setLoading={(p: boolean) => props.setLoading(p)}
            setCardComplete={(p: boolean) => props.setCardComplete(p)}
            setCardEmpty={(p: boolean) => props.setCardEmpty(p)}
            setCardError={(p: any) => props.setCardError(p)}
            setErrorMessage={(p: string) => props.setErrorMessage(p)}
          />
        </Elements> */}
        <div className='row forAds'>
          {!props.chargingPlanDetails ? (
            <div className='border radius col-12 d-flex'>
              <input
                type='radio'
                defaultChecked={props.stateAdds?.number_left > 0 ? true : false}
                disabled={props.stateAdds?.number_left < 1 ? true : false}
                name='choice'
                value='FREE'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  props.handleChoise(e.target.value)
                }
              />
              <h5>
                {props.stateAdds?.number_left} out of{' '}
                {props.stateAdds?.number_max} free classified ads
              </h5>
              {/* <label htmlFor="">
                Next grant: on{" "}
                {moment(new Date(props?.stateAdds?.next_grant)).format(
                  "MMM D, YYYY"
                )}
              </label> */}
            </div>
          ) : (
            'Loading...'
          )}

          {/* {props.stateAdds.number_left < 1 && (
            <div className="border radius col-12 d-flex">
              <input
                type="radio"
                name="choice"
                value="PAY"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  props.handleChoise(e.target.value)
                }
              />
              <h5>classified ad</h5>
              <h5>{props.amountAds !== "" && <>$ {props.amountAds} USD</>}</h5>
            </div>
          )}
          {payWithoutCarte && (
            <div className="payC">
              <p>You do not have a credit card registered.</p>
              <p onClick={showAddCart}>
                To continue <a href="javascript:;">add Credit card</a>
              </p>
            </div>
          )}
          {payWithCarte && (
            <div className="payC">
              <label htmlFor="">
                By continuing, you registered card will be automatically charged
              </label>
            </div>
          )} */}
        </div>
      </Modal.Body>
      {/* <div className="contentBorder formForEmail text-right"></div> */}
      {!props.chargingPlanDetails && (
        <Modal.Footer>
          <div className='contentBorder formForEmail text-right'>
            <button
              className='btn btn-devinsider cancel'
              onClick={() => props.closeAddsModal()}
            >
              Cancel
            </button>

            <button
              className='btn btn-devinsider px-5 save'
              disabled={props.stateAdds?.number_left > 0 ? false : true}
              onClick={() => props.publishAdds()}
            >
              Continue
            </button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
