import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import { useForm } from "react-hook-form";
import { url } from "../../service/constant";
import { rootState } from "../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";
import {
  getDataService,
  postDataFormDataService,
} from "../../service/applicatif/back-end-service";
import { getMyEvents } from "../../redux/actions/event-action";
import { timeRange } from "../../utils/time-range";
import { toast } from "react-toastify";
import UsePublishEvent from "../../screen/widget/payement-oem/publish-event/use-publish-event";

export type IPropsEvent = {
  startTime: string;
  endTime: string;
  isAllDate: boolean;
  eventUrl: string;
  eventTitle: string;
  eventDescription: string;
  eventTags: string;
};
export const UseCreateEventsCtr = () => {
  const [startDate, setStartDate] = useState<Date>(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const [showNext, setShowNext] = useState(false);
  const history = useHistory();
  const [loaderCreateEvent, setloaderCreateEvent] = useState<boolean>(false);
  const [eventTypeSelected, setEventTypeSelected] = useState<string>("");
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const currentProgramPage = useSelector(
    (state: rootState) => state.oemProgramReducer
  );
  const [nbrDescription, setNbrDescription] = useState<number>(0);
  const [labelsCustom, setlabelsCustom] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const [labelsCustomSuggestion, setlabelsCustomSuggestion] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const [isShowEndTime, setisShowEndTime] = useState<boolean>(false);
  const [isAllTime, setisAllTime] = useState<boolean>(false);
  const [isErrorInfo, setisErrorInfo] = useState(false);
  const [txtError, settxtError] = useState<string>("");
  const providerPayement = UsePublishEvent();

  const [show, setShow] = useState<boolean>(false);
  const [activeCategorie, setactiveCategorie] = useState<number>(-1);
  const eventObject = useSelector((state: rootState) => state?.eventReducer);

  const toogleModal = useCallback(
    (p: boolean) => {
      userReducer && setShow(p);
    },
    [show]
  );
  const toogleActiveCategorie = useCallback(
    (p: number) => {
      setactiveCategorie(p);
    },
    [activeCategorie]
  );

  const goToMyEvents = () => {
    history.push("/events/my-events");
  };

  const goToAllEvents = () => {
    history.push("/events/all-events");
  };

  const urlPath = window.location.href;
  const isFound: any = urlPath.indexOf("my-events");

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<IPropsEvent>({ mode: "onChange" });

  const callback = (data: any) => {
    history?.push("/events/my-events");
  };

  const getMyEventsFunction = () => {
    let params = {
      oem_program_id: currentProgramPage?.program?.id,
      tags: "",
      event_type: "",
      limit: 10,
      page: 1,
      search: "",
    };
    dispatch(getMyEvents(params, false, callback));
  };

  const onSubmit = async (data: IPropsEvent) => {
    let startDateSelected = timeRange?.find(
      (el: any) => el?.id?.toString() === data?.startTime?.toString()
    );
    let endDateSelected = timeRange?.find(
      (el: any) => el?.id?.toString() === data?.endTime?.toString()
    );
    var dateDiffHour: number = 0;
    let selectedDate = moment(startDate).format("YYYY-MM-DD");
    let formatedDateToSend = moment(startDate).format("DD-MM-YYYY");

    const dateTimeStart = moment(
      new Date(selectedDate + " " + startDateSelected?.value)
    );

    const dateTimeEnd = moment(
      new Date(selectedDate + " " + endDateSelected?.value)
    );

    if (data?.startTime !== "" || data?.endTime !== "") {
      dateDiffHour = isAllTime
        ? 24
        : moment.duration(dateTimeEnd.diff(dateTimeStart)).asHours();
    }

    let isEmptyTime =
      (data?.startTime === "" ||
        data?.endTime === "" ||
        data?.endTime === undefined) &&
      !isAllTime
        ? true
        : false;

    if (isEmptyTime) {
      setisErrorInfo(true);
      settxtError("start time and end time is required");
      return;
    }

    if (dateDiffHour < 1 && data?.startTime !== "" && data?.endTime !== "") {
      setisErrorInfo(true);
      settxtError("start time must be greater than end time");
      return;
    }

    let allTags = labelsCustom?.map((el: any, index: number) => el?.value);
    let allTagsCustom = labelsCustomSuggestion?.map((el: any) => el?.value);

    let joinLabels = [...allTags, ...allTagsCustom];

    let params = {
      eventType: eventTypeSelected,
      at: isAllTime
        ? formatedDateToSend
        : formatedDateToSend + " " + startDateSelected?.value,
      duration: dateDiffHour + " h",
      tags: joinLabels?.join(","),
      presentationText: data?.eventDescription,
      register: data?.eventUrl,
      title: data?.eventTitle,
      oemProgram: currentProgramPage?.program?.id,
      beginAt: isAllTime ? "" : data?.startTime,
      endAt: isAllTime ? "" : data?.endTime,
    };
    setloaderCreateEvent(true);

    let response = await postDataFormDataService(
      params,
      url?.events?.create_event,
      userReducer?.token
    );
    setloaderCreateEvent(false);
    if (response?.status === 200) {
      reset();
      if (isAllTime) {
        setisAllTime(false);
      }
      setStartDate(new Date(Date.now() + 3600 * 1000 * 24));
      toast("Event published succeffuly");
      getMyEventsFunction();
      setShowNext(false);
    } else {
      toast("An error occured");
    }
  };

  const promiseOptions = async (inputValue: string) => {
    let labels = await getDataService(
      url.events?.getTags + "?title=" + inputValue + "&itemPerPage=" + 10,
      {},
      userReducer?.token
    );
    let tempResult = labels?.data?.map((el: any) => ({
      label: el?.title,
      value: el?.title,
    }));
    return tempResult;
  };

  watch((el) => {
    setNbrDescription(getValues("eventDescription")?.length || 0);
  });

  useEffect(() => {
    if (loaderCreateEvent) {
      setloaderCreateEvent(false);
    }

    if (isErrorInfo) {
      setisErrorInfo(false);
      settxtError("");
    }

    if (!showNext) {
      setStartDate(new Date(Date.now() + 3600 * 1000 * 24));
      reset();
    }

    return () => {};
  }, [showNext]);

  const handleSetCustomLabel = (label: { label: string; value: string }) => {
    let found = labelsCustomSuggestion?.find(
      (el: any) => el?.value === label?.value
    );

    if (found) {
      let updatedLabels = labelsCustomSuggestion?.filter(
        (el: any) => el?.value !== label?.value
      );
      setlabelsCustomSuggestion(updatedLabels);
    } else {
      setlabelsCustomSuggestion([...labelsCustomSuggestion, label]);
    }
  };

  const isSelected = (
    data: Array<{ label: string; value: string }>,
    value: string
  ): boolean => {
    return data?.find((el: any) => el?.value === value) ? true : false;
  };

  const handleCreateEvent = () => {
    providerPayement.showmodalPublishEvent();
  };

  const continuePublish = () => {
    providerPayement.closePublishModal();
    setShow(true);
  };

  return {
    goToMyEvents,
    goToAllEvents,
    eventTypeSelected,
    startDate,
    showNext,
    setShowNext,
    setStartDate,
    setEventTypeSelected,
    register,
    handleSubmit,
    errors,
    onSubmit,
    loaderCreateEvent,
    promiseOptions,
    labelsCustom,
    setlabelsCustom,
    getValues,
    nbrDescription,
    isInMyEvents: isFound !== -1 ? true : false,
    isShowEndTime,
    setisShowEndTime,
    isAllTime,
    setisAllTime,
    isErrorInfo,
    txtError,
    handleSetCustomLabel,
    isSelected,
    labelsCustomSuggestion,
    providerPayement,
    continuePublish,
    handleCreateEvent,
    eventObject,
    show,
    activeCategorie,
    setShow: toogleModal,
    setactiveCategorie,
    toogleActiveCategorie,
    userReducer,
  };
};
