import { useEffect, useState } from "react";
import { EditorState } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getSStorage, isModerator } from "../../../utils";
import usePostWysiwig from "../../../screen/widget/post-wysiwig/use-post-wysiwig";
import { rootState } from "../../../redux/reducers";
import {
  createPost,
  getTags,
} from "../../../service/applicatif/community-service";
import { url } from "../../../service/constant";
import { getDataService } from "../../../service/applicatif/back-end-service";
import { convertToRaw } from "draft-js";
import { toast } from "react-toastify";
import UsePayementPr from "../../../screen/widget/payement-isv/publish-pr/use-publish-press-release";
import { useDebounce } from "use-debounce";
import { postData } from "../../../service/api";
import UseHeaderCtr from '../../../../src/controller/community-ctr/header-ctr';

export type ITagsProps = {
  id: string;
  name: string;
};

export default function PressReleaseCtr() {
  const history = useHistory();

  const userObject = useSelector((state: rootState) => state?.userReducer);

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );

  const providerHeaderProps = { ...UseHeaderCtr() };


  const [postType, setPostType] = useState<number>(0);
  const [tags, setTags] = useState<ITagsProps[]>([]);
  const [errorTags, setErrorTags] = useState<boolean>(false);
  const [errorAffiliated, setErrorAffiliated] = useState<boolean>(false);
  const { object, setEditorStatePost, setTxtObject, stateFormWysywig } =
    usePostWysiwig({ initialWysiwygTemplate: "", initialeObjectText: "" });
  const [titleError, settitleError] = useState<boolean>(false);

  const [loader, setLoader] = useState<boolean>(false);

  const providerUsePayementPr = UsePayementPr();
  const [loading, setLoading] = useState<boolean>(false);

  const [txtFilter, setTxtFilter] = useState<string>("");
  const [debouncedText] = useDebounce(txtFilter, 100);
  const [dataTagsFilter, setDataTagsFilter] = useState<Array<string>>([]);
  const [selectedTags, setSelectedTags] = useState<string>("");
  const [isLoadFilter, setIsLoadFilter] = useState(true);
  const [dataListe, setDataListe] = useState<Array<string>>([]);
  const [labelError, setlabelError] = useState<boolean>(false);

  //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
  const [mentions, setMentions] = useState<
    Array<{ text: string; value: string; url: string }>
  >([]);
  const [registeredMentions, setRegisteredMentions] = useState<Array<string>>(
    []
  );

  // initial tags
  useEffect(() => {
    let data = getTags();
    let temp: ITagsProps[] = [];
    data
      ? data.then((value) => {
          value?.map(
            (el: any) => (temp = [...temp, { id: el?.name, name: el?.name }])
          );
          setTags(temp);
        })
      : setTags([]);
  }, []);

  // set the post type
  useEffect(() => {
    setPostType(
      dataCompleted?.category_and_post_type?.post_type?.filter(
        (el: any) => el.type === "Press Release"
      )[0]?.id
    );
  }, []);

  useEffect(() => {
    if (errorTags) {
      setErrorTags(false);
    }
  }, [txtFilter]);

  //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
  const cb = (b: boolean, metadata: any) => {
    setLoading(false);
    errorAffiliated && setErrorAffiliated(false);
    b && toast("successfully  published");
    if (b) {
      history.push("/community/press-releases/0");
      let postId = metadata;
      (async () => {
        let response = await postData(
          url?.community?.mention + "s/register",
          {
            postId: postId,
            postType: "FORUM_DISCUSSION",
            userId: registeredMentions,
          },
          userObject.token
        );
      })();
    } else {
      toast("An error occured");
    }
  };

  const cancelPost = () => {
    setTxtFilter("");
    setSelectedTags("");
    setTxtObject("");
    setEditorStatePost(EditorState?.createEmpty());
    history.goBack();
  };

  useEffect(() => {
    if (titleError) settitleError(false);
    return () => {};
  }, [object]);

  const publishPR = () => {
    setLoading(true);
    let tempLabels = txtFilter?.split(",");
    const removeAllSpace = tempLabels?.filter((item: string) => item !== " ");
    let data = {
      title: object,
      description: JSON.stringify(
        convertToRaw(stateFormWysywig.getCurrentContent())
      ),
      slug: "",
      emailMe: 0,
      postType: postType,
      tags: removeAllSpace?.join()?.trim(),
      choice: providerUsePayementPr?.choiseValue,
    };
    createPost(data, cb);
  };

  // create post
  const post = () => {
    if (object?.length < 1) {
      settitleError(true);
      return;
    }
    if (isModerator(userObject.roles)) {
      publishPR();
    } else {
       if (!userObject?.isvInCompanyPage) {
         setErrorAffiliated(true);
         return;
       }
       let tempTags = txtFilter.split(",");

       const removeSpacer = tempTags?.filter((el) => el !== "");

       let isInIntervale =
         removeSpacer?.length > 0 &&
         removeSpacer?.length <= 3 &&
         txtFilter !== "";

      //  isInIntervale && providerUsePayementPr.showmodalPR();
      isInIntervale && publishPR();
       !isInIntervale && setErrorTags(true);
    }
  };

  // post pr 
  const postPr = (categorie: number) => {   
    providerHeaderProps?.createDiscution(categorie);
  }

  // load tags
  const promiseOptions = async (inputValue: string) => {
    let labels = await getDataService(
      url.community?.get_tags,
      { name: inputValue },
      userObject?.token
    );
    let tempResult = labels?.data?.map((el: any) => ({
      label: el?.name,
      value: el?.name,
    }));

    setDataTagsFilter(tempResult);
  };

  const handleSelectedLabels = (p: string) => {
    if (labelError) {
      setlabelError(false);
    }
    if (isLoadFilter) {
      setIsLoadFilter(false);
    }
    setSelectedTags(p);
  };

  const handleFilterSelect = (txt: string) => {
    setDataTagsFilter([]);
    const convertArray = txtFilter.split(",");

    //Check if txt isAlreadyExist
    const isExist = convertArray?.find((el: string) => el === txt);
    //remove last string
    convertArray.pop();
    const reformToString = convertArray.join(",");
    if (!isExist) {
      //Make a ';' in end to handlea auther recherche
      const concatString =
        reformToString !== "" && reformToString?.length > 0
          ? reformToString + "," + txt + ","
          : txt + ",";
      setTxtFilter(concatString);
    } else {
      const concat =
        reformToString !== "" && reformToString?.length > 0
          ? reformToString + ","
          : "";
      setTxtFilter(concat);
    }
  };

  useEffect(() => {
    async function filterSearch() {
      if (debouncedText) {
        //Convert to Array
        const handleString = txtFilter?.split(",");

        const removeSpace = handleString?.filter((el) => el != " ");
        //Change Liste selected
        const setselectedTagsInChoose = tags.filter((el: ITagsProps) => {
          const found = removeSpace?.find(
            (item: string) => item?.trim() === el?.name?.trim()
          );
          if (found) {
            return el;
          }
        });
        const tempDataList = setselectedTagsInChoose?.map((i) => i.name);
        setDataListe(tempDataList);

        //Get last Value
        const lastString = removeSpace?.pop() || "";
        if (lastString) {
          promiseOptions(lastString);
        }
      }
    }

    if (isLoadFilter) {
      dataTagsFilter?.length > 0 && setDataTagsFilter([]);
      filterSearch();
    } else {
      setIsLoadFilter(true);
    }

    if (txtFilter === "") {
      setDataListe([]);
      setTxtFilter("");
    }
    return () => {};
  }, [debouncedText]);

  useEffect(() => {
    //Convert to Array
    const handleString = txtFilter?.split(",");

    const findTxt = handleString?.find((el: string) => el === selectedTags);

    //Remove txt tags if exist
    if (findTxt) {
      const tempArray = handleString?.filter((el) => el !== selectedTags);
      setTxtFilter(tempArray?.join(","));
      setDataListe(tempArray);
    } else {
      //Add new string when not found
      const concatString =
        txtFilter?.length > 0 ? txtFilter + "," + selectedTags : selectedTags;
      const dataArr = concatString?.split(",");
      //Remove occurence
      const toStringOccurence = dataArr?.filter((el) => el !== "");

      setTxtFilter(toStringOccurence?.join(","));
      setDataListe(dataArr);
    }
  }, [selectedTags]);

  return {
    tags,
    selectedTags,
    errorTags,
    stateFormWysywig,
    setEditorStatePost,
    post,
    loader,
    object,
    setTxtObject,
    errorAffiliated,
    cancelPost,
    setErrorAffiliated,
    promiseOptions,
    titleError,
    user: {},
    providerUsePayementPr,
    loading,
    publishPR,
    txtFilter,
    setTxtFilter,
    dataTagsFilter,
    handleFilterSelect,
    dataListe,
    handleSelectedLabels,
    postPr,
    //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
    mentions,
    updateMention: async (value: any) => {
      let keyWord =
        value?.blocks[0]?.text.includes("@") &&
        value?.blocks[0]?.text?.split("@");
      let keyWordText = keyWord[keyWord.length - 1]?.trim() ?? "";
      if (keyWordText !== "") {
        let response = await postData(
          url?.community.mention + "/create",
          {
            postType: "",
            keyWord: keyWordText,
          },
          userObject.token
        );
        if (response?.status === 200) {
          setMentions(
            response?.data?.map((rep: any) => {
              return {
                text: rep?.first_name + " " + rep?.last_name,
                value: rep?.first_name + " " + rep?.last_name,
                url: "/community/activity-user/" + rep?.id,
              };
            })
          );
        }
      }

      //register current mention
      let mentionsKeyArray = Object?.keys(value?.entityMap);
      let mentionsData: Array<any> = [];
      mentionsKeyArray.map((key: string) => {
        if (value?.entityMap[key]?.type === "MENTION") {
          mentionsData.push(value?.entityMap[key]?.data?.url?.split("/").pop());
        }
      });
      setRegisteredMentions(mentionsData);
    },
  };
}
