import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

/*
 * If you want to start measuring performance in your app, pass a function
 * To log results (for example: reportWebVitals(console.log))
 * Or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();

// Server : 6LffKosaAAAAAOca4THx6w3d6UbDa505oD2g12gr
