import { Dispatch } from '../../types/type-action';
import * as Service from '../../../service/applicatif/company-page-isv';
import store from '../../store';

export const CREATE_COMPANY_PAGE = 'CREATE_COMPANY_PAGE';
export const GET_COMPANY_PAGE = 'GET_COMPANY_PAGE';
export const GET_COMPANY_PAGE_ATTRIBUTE = 'GET_COMPANY_PAGE_ATTRIBUTE';
export const UPDATE_COMPANY_PAGE = 'UPDATE_COMPANY_PAGE';
export const GET_COMPANY_PAGE_PROGRESS = 'GET_COMPANY_PAGE_PROGRESS';
export const SEND_INVITATION_AFFILIATED = 'SEND_INVITATION_AFFILIATED';
export const REMOVE_INVITATION_AFFILIATED = 'REMOVE_INVITATION_AFFILIATED';
export const UPDATE_ROLE_AFFILIATED = 'UPDATE_ROLE_AFFILIATED';
export const REMOVE_ROLE = 'REMOVE_ROLE';
export const ADD_LEADER_SHIP = 'ADD_LEADER_SHIP';
export const REMOVE_LEADER_SHIP = 'REMOVE_LEADER_SHIP';
export const VALID_REQUEST_TO_AFFILIATED = 'VALID_REQUEST_TO_AFFILIATED';
export const UPDATE_BILLING_CENTER_OWNER = 'UPDATE_BILLING_CENTER_OWNER';
export const UPDATE_BILLING_CENTER_CARD = 'UPDATE_BILLING_CENTER_CARD';
export const UPDATE_VIDEO_COMPANY_PAGE = 'UPDATE_VIDEO_COMPANY_PAGE';
export const REMOVE_LOGO_COMPANY_PAGE = 'REMOVE_LOGO_COMPANY_PAGE';
export const REMOVE_COUVERTURE_COMPANY_PAGE = 'REMOVE_COUVERTURE_COMPANY_PAGE';

export const remove_logo_company_page = (payload: string) => {
  return {
    type: REMOVE_LOGO_COMPANY_PAGE,
    payload: payload,
  };
};

export const remove_couverture_company_page = (payload: string) => {
  return {
    type: REMOVE_COUVERTURE_COMPANY_PAGE,
    payload: payload,
  };
};

export const update_video_company_page = (payload: string) => {
  return {
    type: UPDATE_VIDEO_COMPANY_PAGE,
    payload: payload,
  };
};

/**
 * @param params params to create company page action
 * @callback function  (params:any) {
   return response company age
 }
 */
const createCompanyPageAction = (params: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.createaAcompanyPage(
        params,
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: CREATE_COMPANY_PAGE,
          payload: result && result.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * @param id id company page
 * @callback function  (params:any) {
   return response company age
 }
 */
const getCompanyPageAction = (id: string, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.getCompanyPage(
        id,
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: GET_COMPANY_PAGE,
          payload: result && result.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/*********************************************************************
 *
 * Get attribute in company page
 */
const getCompanyPageAttributeAction = (att: string, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.getAttributeCompanyPage(
        att,
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: GET_COMPANY_PAGE_ATTRIBUTE,
          payload: {
            data: result && result.data,
            attribute: customAattribute(att),
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/***********************************************************
 *
 * Update company page
 * Dev #47679 V1 Agrandir la taille max de la video dans company page
 */
const updateCompanyPageAction = (
  data: any,
  id: string | null,
  callback: any,
  keyToUpdate?: string,
  callBackProgression?: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.updateCompanyPage(
        data,
        id,
        store.getState().userReducer.token,
        callBackProgression
      );
      if (result && result.status === 200) {
        callback(result);
        if (keyToUpdate) {
          dispatch({
            type: UPDATE_COMPANY_PAGE,
            payload: {
              data: result && result.data?.[keyToUpdate],
              keyToUpdate,
            },
          });
        } else {
          dispatch({
            type: UPDATE_COMPANY_PAGE,
            payload: result && result.data,
          });
        }
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/*****************************************
 *
 * Send invitation to affilliated
 */
const sendInvitationAffilliated = (params: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    Service.sendInvitation(params, store.getState().userReducer.token)
      .then((result) => {
        if (result && result.status === 201) {
          dispatch({
            type: SEND_INVITATION_AFFILIATED,
            payload: result && result.data,
          });
          callback(result);
          return;
        }
      })
      .catch((error) => {
        error.response.status = 400;
        callback(error?.response);
      });
  };
};

/*****************************************
 *
 * Update role  affilliated
 */
const updateRoleAffiliatedAction = (
  params: { role?: string; idUser?: string; user: string; companyPage: string },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.updateRoleAffiliated(
        params,
        store.getState().userReducer.token
      );
      if (result && result.status === 201) {
        dispatch({
          type: UPDATE_ROLE_AFFILIATED,
          payload: params,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**************************************************************
 *
 * Send invitation to affilliated
 */
const removeInvitationAction = (params: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.removeInvitation(
        params,
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: REMOVE_INVITATION_AFFILIATED,
          payload: {
            id: params,
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/***********************************************************************
 *
 * Remove user affiliated
 */
const removeUserRoleAffilliatedAction = (id: string, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.removeUserRole(
        id,
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: REMOVE_ROLE,
          payload: {
            id: id,
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/***********************************************************
 *
 * Add leader ship data
 */
const addLeaderShipAction = (params: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.addLeaderShip(
        params,
        store.getState().userReducer.token
      );
      if (result && result.status === 201) {
        dispatch({
          type: ADD_LEADER_SHIP,
          payload: result.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/***********************************************************************
 *
 * Remove user affiliated
 */
const removeLeaderShipById = (id: number, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.removeLeaderShipById(
        id,
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: REMOVE_LEADER_SHIP,
          payload: id,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/*****************************************
 *
 * valid request to affiliated
 */
const validRequestToAffiliatedAction = (params: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.validRequestToAffiliated_Service(
        params,
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        let customUserPayload;
        if (params?.decision === 'MEMBER_INVITATION_APPROVE') {
          customUserPayload = {
            idInvitation: params?.invitationId,
            event: params?.decision,
            isvInCompanyPage: [
              {
                role: result?.data?.role,
                user: {
                  city: result?.data?.user?.city,
                  country: result?.data?.user?.country,
                  id: result?.data?.user?.id,
                  file_name: result?.data?.user?.file_name,
                  first_name: result?.data?.user?.first_name,
                  job_title: result?.data?.user?.job_title,
                  last_name: result?.data?.user?.last_name,
                  linkedin: result?.data?.user?.linkedin,
                  plain_text: result?.data?.user?.plain_text,
                },
              },
            ],
          };
        } else {
          customUserPayload = {
            idInvitation: params?.invitationId,
            event: params?.decision,
            isvInCompanyPage: {
              role: '',
              user: {},
            },
          };
        }

        dispatch({
          type: VALID_REQUEST_TO_AFFILIATED,
          payload: customUserPayload,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**********************************
 *
 * Update billing center owner
 */
const updateBillingCenterOwner = (newBillingOwner: any) => {
  return {
    type: UPDATE_BILLING_CENTER_OWNER,
    payload: newBillingOwner,
  };
};

/**********************************
 *
 * Update payment method: card info
 */
const updateBillingCenterCard = (payment_method: string) => {
  return {
    type: UPDATE_BILLING_CENTER_CARD,
    payload: payment_method,
  };
};

/**
 * @param key string to custom key in reducer
 * @returns string to modifie payload reducer
 */
const customAattribute = (attr: string): string => {
  let temp: string = '';

  switch (attr) {
    case 'segmentDetails':
      temp = 'segment_details';
      break;
    case 'targetedIndustry':
      temp = 'targeted_industry';
      break;
    case 'oemProgramSegmentInterest':
      temp = 'oem_program_segment_interest';
      break;
    case 'distributionChannel':
      temp = 'distribution_channel';
      break;
    case 'targetedCountry':
      temp = 'targeted_country';
      break;
    case 'oemProgramSegmentInterestText':
      temp = 'oemProgramSegmentInterestText';
      break;
    case 'partners':
      temp = 'partners';
      break;
    default:
      break;
  }
  return temp;
};

export {
  createCompanyPageAction,
  getCompanyPageAction,
  getCompanyPageAttributeAction,
  updateCompanyPageAction,
  sendInvitationAffilliated,
  removeInvitationAction,
  updateRoleAffiliatedAction,
  removeUserRoleAffilliatedAction,
  addLeaderShipAction,
  removeLeaderShipById,
  validRequestToAffiliatedAction,
  updateBillingCenterOwner,
  updateBillingCenterCard,
};
