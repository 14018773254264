import React, { useEffect } from "react";
import Header from "../../../../widget/index";
import "../Top.scss";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import AthorsTab from "./authors";
import ContributionsTab from "./contributions";
import UseViewAll, {
  activeFilterContributor,
} from "../../../../../controller/community-ctr/bloc-community-ctr/use-view-all-top-author-contributor";
import SearchForm from "../../../../widget/community/search/index";
import Footer from "../../../../widget/footer/footer";

export default function AuthorContribution() {
  const providerViewAll = UseViewAll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="container mx-auto">
        <ul className="breadcrumbPerso">
          <li>
            <a href="javascript:;">Community</a>
          </li>
          <li>Discussion categories</li>
        </ul>
        <div className="searchTopic">
          <SearchForm
            categories={providerViewAll?.communityReducer?.discussionCategories}
            filterSearchText={providerViewAll?.filterSearchText}
            handleFilterTxt={providerViewAll?.handleFilterTxt}
            searchText={providerViewAll?.inputText}
            handleCateGory={providerViewAll?.handleCateGory}
          />
        </div>
        <div className="rightCommunityForum topContainer">
          <div className="block">
            <Tab.Container defaultActiveKey={providerViewAll?.defaultActive}>
              <h2 className="title withLink">
                Top liked :
                <Nav.Link
                  eventKey="author"
                  onClick={() => providerViewAll?.setdefaultActive("author")}
                  className={
                    providerViewAll?.defaultActive === "author" ? "active" : ""
                  }
                >
                  Authors
                </Nav.Link>
                <Nav.Link
                  eventKey="contributions"
                  onClick={() =>
                    providerViewAll?.setdefaultActive("contributions")
                  }
                  className={
                    providerViewAll?.defaultActive === "contributions"
                      ? "active"
                      : ""
                  }
                >
                  {providerViewAll.postType === "1" && "Contributions"}
                  {providerViewAll.postType === undefined && "Contributions"}
                  {providerViewAll.postType === "2" && "Article"}
                  {providerViewAll.postType === "3" && "Press releases"}
                  {providerViewAll.postType === "4" && "Partner program review"}
                </Nav.Link>
              </h2>
              <Tab.Content>
                <Tab.Pane eventKey="author">
                  <AthorsTab
                    activeFilter={providerViewAll?.activeFilterTopAuthor}
                    dataAuthor={providerViewAll?.dataAuthor?.allData}
                    categorie={providerViewAll?.labelCategorie}
                    currentPage={providerViewAll?.currentPageAuthor}
                    totalData={providerViewAll?.dataAuthor?.totalData}
                    loadAuthor={providerViewAll?.loaderContributor}
                    setFilterAuthor={(p: activeFilterContributor) =>
                      providerViewAll?.setFilterTopAuthor(p)
                    }
                    handlePageClick={(p: number) =>
                      providerViewAll?.handlePageClickAuthor(p)
                    }
                    topLikedAuthor={providerViewAll?.topLikedAuthor}
                    myRank={providerViewAll?.myRank}
                    showActivityUser={(idUser: string) =>
                      providerViewAll?.showActivityUser(idUser)
                    }
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="contributions">
                  <ContributionsTab
                    activeFilter={providerViewAll?.activeFilterTopContributor}
                    allContributors={providerViewAll?.dataContributors?.allData}
                    setFilter={(p: activeFilterContributor) =>
                      providerViewAll?.setFilterTopContributor(p)
                    }
                    loaderContributor={providerViewAll?.loaderContributor}
                    currentPage={providerViewAll?.currentPageContributor}
                    totalData={providerViewAll?.dataContributors?.totalData}
                    handlePageClick={(p: number) =>
                      providerViewAll?.handlePageClickContributor(p)
                    }
                    categorie={providerViewAll?.labelCategorie}
                    showActivityUser={(idUser: string) =>
                      providerViewAll?.showActivityUser(idUser)
                    }
                    goToLabelCategory={(
                      idCategory: string,
                      idLabel: string,
                      postType: string
                    ) =>
                      providerViewAll.goToLabelCategory(
                        idCategory,
                        idLabel,
                        postType
                      )
                    }
                    goToreplayPost={(idCategory: string, idPost: string) =>
                      providerViewAll.goToreplayPost(idCategory, idPost)
                    }
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
