import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyPageAttributeAction,
  updateCompanyPageAction,
} from "../../../../redux/actions/company-page-isv-action/current_isv_action";
import { rootState } from "../../../../redux/reducers";
import { getSStorage } from "../../../../utils";

export default function UsePartenerShipCtr() {
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  let companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const dispatch = useDispatch();
  const [isShowPartenerShipModal, setisShowPartenerShipModal] =
    useState<boolean>(false);
  const [partenerShipSelected, setPartenerShipSelected] = useState<Array<any>>(
    JSON.parse(companyPageObject?.oemProgramSegmentInterestText || "[]")
  );
  const [isShowErrorPartenerShip, setIsShowErrorPartenerShip] =
    useState<boolean>(false);

  const [checkedSoftware, setCheckedSoftware] = useState<string>("yes");

  const [loader, setLoader] = useState<boolean>(false);

  const [technologiesPartnership, setTechnologiesPartnership] = useState<
    Array<{ value: number; label: string }>
  >(
    dataCompleted?.partners?.map((el: { id: number; name: string }) => ({
      value: el.id,
      label: el.name,
    })) || []
  );
  const [currentSelected, setCurrentSelected] = useState<{
    value: number;
    label: string;
  }>();
  const tempPartners = companyPageObject?.partners?.map((el) => ({
    value: el.id,
    label: el.name,
  }));
  const [currentSelectedList, setCurrentSelectedList] = useState<
    Array<{ value: number; label: string }>
  >(tempPartners || []);

  const cancelTechnologieParteners = () => {
    setisShowPartenerShipModal(false);
    setPartenerShipSelected(
      JSON.parse(companyPageObject?.oemProgramSegmentInterestText)?.map(
        (el: any) => ({
          idSegment: el.idSegment,
          name: el.name,
        })
      )
    );
  };

  const addParterShip = (params: any) => {
    let found = partenerShipSelected?.find(
      (el: any) => el.idSegment == params.idSegment && el.name == params.name
    );

    if (!found) {
      setPartenerShipSelected([...partenerShipSelected, params]);
    } else {
      setPartenerShipSelected(
        partenerShipSelected?.filter(
          (elem: any) => elem.idSegment != params.idSegment
        )
      );
    }
  };

  const addListOption = (data: any) => {
    if (currentSelected) {
      let currentOptionList = technologiesPartnership?.filter(
        (el: { value: number; label: string }) => el.value !== data.value
      );
      setCurrentSelectedList([...currentSelectedList, currentSelected]);
      setTechnologiesPartnership(currentOptionList);
      setCurrentSelected({ label: "", value: 0 });
    }
  };

  const removeSelected = (id: number) => {
    let data = currentSelectedList?.find(
      (el: { value: number; label: string }) => el.value === id
    );
    let tempValue = currentSelectedList?.filter(
      (el: { value: number; label: string }) => el.value !== id
    );
    setCurrentSelectedList(tempValue);
    if (data) {
      setTechnologiesPartnership([data, ...technologiesPartnership]);
    }
  };

  const cbOemSegmentInterestText = (response: any) => {
    if (response && response.status === 200) {
      const txtPartenare = response?.data || "[]";
      setPartenerShipSelected(JSON.parse(txtPartenare));
    }
  };

  const setEomProgramText = () => {
    let attribute = "oemProgramSegmentInterestText";

    dispatch(
      getCompanyPageAttributeAction(attribute, cbOemSegmentInterestText)
    );
  };

  const cbInterested = (response: any) => {
    if (response && response.status === 200) {
      if (isShowPartenerShipModal) {
        setLoader(false);
        setisShowPartenerShipModal(false);
      }
    }
  };

  const setEomProgramInterest = () => {
    let attributes = "oemProgramSegmentInterest";

    dispatch(getCompanyPageAttributeAction(attributes, cbInterested));
  };
  const getAttribute = () => {
    setEomProgramText();
    setEomProgramInterest();
  };

  const cbUpdatePartenerShip = async (response: any) => {
    if (response && response.status === 200) {
      getAttribute();
    }
  };

  const updateTechnologiePartener = () => {
    if (partenerShipSelected?.length <= 0) {
      setIsShowErrorPartenerShip(true);
    } else {
      let params = {
        oemProgramSegmentInterest: JSON.stringify(
          partenerShipSelected.map((el: any) => el.idSegment)
        ),
        oemProgramSegmentInterestText: JSON.stringify(
          partenerShipSelected || []
        ),
        partners: JSON.stringify(
          currentSelectedList.map((el: any) => el.value)
        ),
      };

      setLoader(true);
      dispatch(
        updateCompanyPageAction(
          params,
          companyPageObject.id,
          cbUpdatePartenerShip
        )
      );
    }
  };

  return {
    addParterShip,
    cancelTechnologieParteners,
    loaderPartenership: loader,
    isShowPartenerShipModal,
    updateTechnologiePartener,
    technologiesPartnership: dataCompleted?.technologies_partnership,
    currentPartener: technologiesPartnership,
    partenerShipSelected: partenerShipSelected,
    checkedSoftware,
    setCheckedSoftware,
    isShowErrorPartenerShip,
    setCurrentSelected,
    currentSelected,
    addListOption,
    removeSelected,
    currentSelectedList,
    setisShowPartenerShipModal,
  };
}
