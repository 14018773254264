import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  followOrUnfollowCategory,
  getCategoriesFeed,
} from "../../../redux/actions/community-action";
import { rootState } from "../../../redux/reducers";

export default function ManageCategoriesAndLabels() {
  const dispatch = useDispatch();

  const communityReducer = useSelector(
    (state: rootState) => state?.communityReducer
  );

  const [loaderFollow, setLoaderFollow] = useState<Array<boolean>>([]);

  const callback = (data: any) => {
    if (data?.status === 200) {
      setLoaderFollow(
        communityReducer?.detailsDiscutionCategorie?.map((el) => false)
      );
    }
  };

  const cb = (data: any, key: number) => {
    if (data?.status === 201) {
      setFollowCategory(key, false);
    }
  };

  useEffect(() => {
    dispatch(getCategoriesFeed(callback));
  }, []);

  const setFollowCategory = (key: number, value: boolean) => {
    setLoaderFollow(
      loaderFollow?.map((state: boolean, index: number) => {
        if (index === key) return value;
        else return state;
      })
    );
  };

  const followOrUnfollow = (
    idCategory: number,
    state: boolean,
    key: number
  ) => {
    setFollowCategory(key, true);
    let params = {
      idCategory: idCategory,
      state: state,
      key: key,
    };
    dispatch(followOrUnfollowCategory(params, cb));
  };

  return {
    communityReducer,
    followOrUnfollow,
    loaderFollow,
  };
}
