import "./manage-oem.scss";
import Modal from "react-bootstrap/Modal";
import React, { ChangeEvent } from "react";
import { ICompletedProgramTypeCtr } from "../../../../../controller/oem-ctr/type";
import Select from "react-select";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export default function OemSegmentDetail(props: ICompletedProgramTypeCtr) {
  return (
    <Modal
      show={props.modal}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Program details</h2>
          <a className="close" onClick={props.closeModal}>
            <span className="icon-close-pop" />
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="form-group required col-12">
            <label htmlFor="type required">
              What type of program are you offering to ISVs?
              {props.typeError && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </label>
            <div className="row">
              <div className="form-group required col-xl-6 col-md-6 col-sm-12">
                <button
                  className={`btn d-block w-100 ${
                    props.typeProgram === "1" ? "btn-devinsider" : "btn-light"
                  }`}
                  onClick={() => props.setDataType("1")}
                >
                  Software OEM
                </button>
              </div>
              <div className="form-group required col-xl-6 col-md-6 col-sm-12">
                <button
                  className={`btn d-block w-100 ${
                    props.typeProgram === "2" ? "btn-devinsider" : "btn-light"
                  }`}
                  onClick={() => props.setDataType("2")}
                >
                  Technology partnership (Ecosystem program)
                </button>
              </div>
            </div>
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12">
            <label htmlFor="programName required">
              Program name
              {props.errorsProgramName && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </label>
            <input
              type="text"
              className="input-text form-control"
              value={props?.programName}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                props?.setProgramName(evt?.target?.value)
              }
            />
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12">
            <label htmlFor="programName required">
              Program segment(s)
              {props.segmentError && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </label>
            <Select
              options={props.dropDownItems?.map((el: any) => ({
                id: el.id,
                label: el.name,
                value: el.name,
              }))}
              value={props.dataSelected}
              isMulti={true}
              className=""
              onChange={(data) => props.setDataSelected(data)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right forPitch">
          <button
            className="btn btn-devinsider cancel"
            onClick={props.closeModal}
            type="button"
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-devinsider px-5"
            onClick={() => props.onSubmit()}
          >
            {props.loading ? <Loader /> : "Save"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
