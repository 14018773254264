import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFindProgramMenu } from "../../../redux/actions/find-program-action";
import { rootState } from "../../../redux/reducers";

type IMenu = {
    filter : (type:Array<number>, location:Array<number>, solution:Array<number>)=>void;
    resetFilter : ()=>void;
}

const BlocLeft = (props : IMenu) => {
    const dispatch = useDispatch();

    const findProgramReducer = useSelector(
        (state:rootState) => state?.findProgramReducer
    );
    const [limitLocation, setLimitLocation] = useState<number>(6);
    const [limitProgram, setLimitProgram] = useState<number>(6);
    const [limitSolution, setLimitSolution] = useState<number>(6);

    const [selectedHqLocation, setSelectedHqLocation] = useState<Array<number>>([]);
    const [selectedProgram, setSelectedProgram] = useState<Array<number>>([]);
    const [selectedSolution, setSelectedSolution] = useState<Array<number>>([]);

    const cb = (data:any)=>{
        
    }

    useEffect(()=>{
        dispatch(
            getFindProgramMenu(cb)
        );
    }, []);

    const handleLocation = (id:number) => {
        selectedHqLocation?.find(
            (el)=> el === id
        ) ? setSelectedHqLocation(selectedHqLocation?.filter((el) => el !== id))
        : setSelectedHqLocation([...selectedHqLocation, id]);
    };

    const handleProgram = (id:number) => {
        selectedProgram?.find(
            (el)=> el === id
        ) ? setSelectedProgram(selectedProgram?.filter((el) => el !== id))
        : setSelectedProgram([...selectedProgram, id]);
    };

    const handleSolution = (id:number) => {
        selectedSolution?.find(
            (el)=> el === id
        ) ? setSelectedSolution(selectedSolution?.filter((el) => el !== id))
        : setSelectedSolution([...selectedSolution, id]);
    };

    const expandOrNot = (type:string, option:number=1) => {
        switch(type){
            case "PROGRAM" :
                option === 2 ? setLimitProgram(6)
                : setLimitProgram(findProgramReducer?.oem_program_type?.length);    
                return;
            case "LOCATION" : 
                option === 2 ? setLimitLocation(6)
                : setLimitLocation(findProgramReducer?.hq_location?.length);
                return;
            case "SOLUTION" : 
                option === 2 ? setLimitSolution(6)
                : setLimitSolution(findProgramReducer?.program_solution?.length);
                return;
        }
    } 

    const filter = ()=>{
        props?.filter(selectedProgram, selectedHqLocation, selectedSolution);
    }

    const resetFilter = ()=> {
        setSelectedHqLocation([]);
        setSelectedProgram([]);
        setSelectedSolution([]);
        props.resetFilter();
    }

    return {
        findProgramReducer,
        selectedHqLocation,
        selectedProgram,
        selectedSolution,
        resetFilter,
        filter,
        handleLocation,
        handleProgram,
        handleSolution,
        limitLocation,
        limitProgram,
        limitSolution,
        expandOrNot,
    }
}

export default BlocLeft;