/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as T from "../../../controller/company-ctr/types/types-company-page";
import { getSStorage } from "../../../utils/index";
var _ = require("lodash");

export type TPropsData = {
  defaultValue: Array<{
    idSolutionOffert: number;
    idSegment: number;
    nameSegment: string;
  }>;
};
export default function UseSoftwareSolutionCtr(props: TPropsData) {
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );

  const [featuredSolution, setFeaturedSolution] = useState<T.ISolutionListe>({
    id: 0,
    name: "",
    segmentDetails: [],
  });

  /****************************************************
   *
   * Segment detail liste
   */
  const [allResellerSolution, setAllResellerSolution] = useState<
    Array<T.ISolutionListe>
  >([]);

  const [solutionLeftColumnData, setSolutionLeftColumnData] = useState<
    Array<T.ISolutionListe>
  >([]);

  const [solutionRigthColumnData, setSolutionRigthColumnData] = useState<
    Array<T.ISolutionListe>
  >([]);

  const [solutionChecked, setSolutionChecked] = useState<
    Array<T.IdataMakeSolution>
  >(props?.defaultValue || []);

  const [allSolutionSellected, setAllSolutionSellected] = useState<
    Array<string>
  >([]);

  useEffect(() => {
    async function getSolution() {
      let dataSolutionOffert = dataCompleted && dataCompleted?.segments;
      let featured = dataSolutionOffert?.find((el: any) => el.id === 9999);
      setFeaturedSolution(featured);
      setAllResellerSolution(
        dataSolutionOffert?.filter((el: any) => el.id !== 9999)
      );
    }
    getSolution();
    return () => {};
  }, []);

  /**
   * @returns initialise solution selected
   */
  useEffect(() => {
    setSolutionChecked(props?.defaultValue);
    return () => {};
  }, [props?.defaultValue]);

  useEffect(() => {
    let lengthSolution = allResellerSolution?.length;
    if (lengthSolution % 2 == 0) {
      setSolutionLeftColumnData(
        allResellerSolution?.slice(0, lengthSolution / 2)
      );
      setSolutionRigthColumnData(
        allResellerSolution?.slice(lengthSolution / 2, lengthSolution + 1)
      );
    } else {
      setSolutionLeftColumnData(
        allResellerSolution?.slice(0, (lengthSolution - 1) / 2 + 1)
      );
      setSolutionRigthColumnData(
        allResellerSolution?.slice(
          (lengthSolution - 1) / 2 + 1,
          lengthSolution + 1
        )
      );
    }
    return () => {};
  }, [allResellerSolution]);

  const [allOtherValue, setAllOtherValue] = useState<
    Array<{
      idSolutionOffert: number;
      idSegment: number;
      nameSegment: string;
      otherTxt: string;
    }>
  >([]);

  const setOtherValueTxt = (
    data: {
      idSolutionOffert: number;
      idSegment: number;
      nameSegment: string;
    },
    otherTxt: string
  ) => {
    const foundIfAlreadyExist = allOtherValue.find(
      (el) => el.idSegment?.toString() === data.idSegment?.toString()
    );

    let tempTxt = [];

    if (foundIfAlreadyExist) {
      tempTxt = allOtherValue.map((el) => {
        if (el.idSegment === data.idSegment) {
          return {
            ...el,
            otherTxt,
          };
        } else {
          return el;
        }
      });
    } else {
      tempTxt = [...allOtherValue, { ...data, otherTxt }];
    }
    setAllOtherValue(tempTxt);
  };

  const transFormnData = (data: any) => {
    const removeOther = data?.solutionOffert?.filter(
      (el: any) => el.name?.toLocaleLowerCase() !== "other"
    );

    let custom = removeOther?.map((el: any) => {
      let temp = {
        idSolutionOffert: data.idSolutionOffert,
        idSegment: el && el.id,
        nameSegment: el && el.name,
      };
      return temp;
    });
    return custom;
  };

  const selectSolution = (data: any) => {
    //DATA CUSTOM
    let dataMake: Array<T.IdataMakeSolution> = [];
    //FIND IF PARAM IS ARRAY OF DATA , for add multiple

    if (data && data.solutionOffert) {
      //find if bouton is selected all is clicked
      let solutionSellectedAll = allSolutionSellected?.find(
        (el) => el == data.idSolutionOffert
      );
      //tranform data if array
      let dataAfterTransform = transFormnData(data);
      dataMake = [...dataAfterTransform];
      //if selected all is not clicked
      if (!solutionSellectedAll) {
        //remove if item is existe deja
        let dataToStore =
          solutionChecked &&
          solutionChecked.filter(
            (el: any) => el.idSolutionOffert != data.idSolutionOffert
          );
        //Performe data to store
        let transFormDataToStore = [...dataToStore, ...dataMake];
        //selecte all data
        setSolutionChecked(transFormDataToStore);
        //active checked selected all is clicked
        setAllSolutionSellected([
          ...allSolutionSellected,
          data.idSolutionOffert,
        ]);
      } else {
        //if all selected is clicked , remove all item same idContinent
        setSolutionChecked(
          solutionChecked.filter(
            (el: any) => el.idSolutionOffert != data.idSolutionOffert
          )
        );
        //Make selected all is not clicked
        setAllSolutionSellected(
          allSolutionSellected.filter((el) => el != data.idSolutionOffert)
        );
      }
    } else {
      dataMake = [data];
      let found =
        solutionChecked &&
        solutionChecked?.find((el: any) => el.idSegment == data.idSegment);
      if (!found) {
        setSolutionChecked([...solutionChecked, ...dataMake]);
      } else {
        setSolutionChecked(
          solutionChecked &&
            solutionChecked?.filter(
              (elem: any) => elem.idSegment != data.idSegment
            )
        );
      }
    }
  };

  const removeItemSelected = (id: number) => {
    let found =
      solutionChecked &&
      solutionChecked?.find((el: T.IdataMakeSolution) => el.idSegment == id);
    if (found) {
      let temp: Array<T.IdataMakeSolution> = solutionChecked?.filter(
        (t: T.IdataMakeSolution) => t.idSegment != id
      );
      setSolutionChecked(temp);
    } else {
      return;
    }
  };

  const backToDefault = () => {
    setSolutionChecked(props?.defaultValue || []);
  };

  return {
    solutionLeftColumnData,
    solutionRigthColumnData,
    solutionChecked,
    allSolutionSellected,
    featuredSolution,
    selectSolution,
    removeItemSelected,
    backToDefault,
    allOtherValue,
    setAllOtherValue,
    setOtherValueTxt,
  };
}
