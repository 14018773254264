import React, { useEffect, useState, useCallback } from "react";
import store from "../../../../redux/store";
import { getDataService } from "../../../../service/applicatif/back-end-service";
import { url } from "../../../../service/constant";
import { useHistory } from "react-router";

export type IpropsAuthor = {
  order_rank: string;
  number_of_like: string;
  profil_picture: string;
  user_name: string;
  job_title: string;
  id: string;
  user_to_like_id: string;
};
export default function Index() {
  /* get top like contributions */
  const [topLikedAuthor, setTopLikedAuthor] = useState<Array<IpropsAuthor>>([]);
  const [loaderAuthor, setLoaderAuthor] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    async function getData() {
      setLoaderAuthor(true);
      const result = await getDataService(
        url.community.get_top_liked_author,
        {},
        store.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        setTopLikedAuthor(result?.data);
        setLoaderAuthor(false);
      }
    }
    getData();
  }, []);

  const viewAllAuthor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "author",
    });
  };

  const showActivityUser = (idUser: string) => {
    history.push("/community/activity-user/" + idUser);
  };

  return {
    topLikedAuthor,
    viewAllAuthor,
    loaderAuthor,
    showActivityUser,
  };
}
