/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { updateCompanyPageAction } from "../../../../redux/actions/company-page-isv-action/current_isv_action";
import { useForm } from "react-hook-form";
import * as T from "../../types/types-company-page";

export default function useUserInfoCtr() {
  const dispatch = useDispatch();
  const [loaderProfil, setLoader] = useState<boolean>(false);

  /************************************
   *
   * Company profil object
   */
  let companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  /************************************
   *
   * Company information in form
   */

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<T.formEditCompanyAdresse>({
    mode: "onBlur",
  });

  const setToInitialValue = () => {
    setValue("companyLegalName", companyPageObject.company_legal_name);
    setValue("companyTradeName", companyPageObject.company_name);
    setValue(
      "webSite",
      companyPageObject.website != "null" && companyPageObject.website
        ? companyPageObject.website
        : ""
    );
    setValue("hQLocation", companyPageObject?.country?.id.toString());
    setValue("city", companyPageObject?.city || "");
    setValue(
      "annualTurnover",
      JSON.stringify({
        [companyPageObject?.annual_turnover]: {
          min: companyPageObject?.annual_turnover_min,
          max: companyPageObject?.annual_turnover_max,
        },
      }) || ""
    );
    setValue("foundingDate", companyPageObject?.founding_date || "");
    setValue(
      "companySize",
      JSON.stringify({
        [companyPageObject?.company_size]: {
          min: companyPageObject?.company_size_min,
          max: companyPageObject?.company_size_max,
        },
      }) || ""
    );
  };

  useEffect(() => {
    if (companyPageObject) {
      setToInitialValue();
    }
  }, [companyPageObject]);

  const [showRequestBasicInformation, setShowRequestBasicInformation] =
    useState(false);

  //Update info company Page
  const submitBasicInfo = (data: T.formEditCompanyAdresse) => {
    let companySizeTemp: any = Object.values(
      JSON.parse(data.companySize || "[]")
    )[0];

    let dataInfo = {
      companyName: data.companyTradeName,
      companyLegalName: data.companyLegalName,
      website: data.webSite,
      country: data.hQLocation,
      city: data.city,
      foundingDate: data.foundingDate,
      companySize: Object.keys(JSON.parse(data.companySize || "[]"))[0],
      companySizeMin: companySizeTemp?.min || "",
      companySizeMax: companySizeTemp?.max || "",
    };
    setLoader(true);
    updateCompanyPage(dataInfo);
  };

  const updateCompanyPage = (data: any) => {
    dispatch(
      updateCompanyPageAction(data, companyPageObject.id, cbUpdateCompany)
    );
  };

  const cbUpdateCompany = (data: any) => {
    if (data && data.status === 200) {
      setLoader(false);
      setShowRequestBasicInformation(false);
    }
  };

  const cancelUpdateform = () => {
    setShowRequestBasicInformation(false);
    setToInitialValue();
    setLoader(false);
  };
  return {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    showRequestBasicInformation,
    setShowRequestBasicInformation,
    submitBasicInfo,
    loaderProfil,
    errors,
    updateCompanyPage,
    cancelUpdateform,
  };
}
