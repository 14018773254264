import thunk from "redux-thunk";
import reducers from "./reducers";
import { applyMiddleware, createStore, compose } from "redux";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist";
import { createLogger } from "redux-logger";

const persistConfig = {
  key: "root",
  storage,
};

const logger = createLogger({
  // ...options
});

const pReducer = persistReducer(persistConfig, reducers);
const store = createStore(pReducer, compose(applyMiddleware(thunk, logger)));

export const persistor = persistStore(store);

export default store;
