/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "react-bootstrap/Modal";
import BodyClaim from "./body";
import * as T from "../../../../../../../controller/company-ctr/types/types-company-page";
import { ReactComponent as Loader } from "../../../../../../../resources/images/Rolling.svg";

export default function FormAddEmailClaimOwner(props: T.TclaimModalFirst) {
  return (
    <div>
      {/* Request step 1 */}
      <Modal
        show={props.showRequestStep1}
        className="createCompanypopup smallPopup forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Request claim ownership</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => props?.cancelSendClaim()}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight">
          <BodyClaim
            companyName={props?.companyName}
            pays={props?.pays}
            webSite={props?.webSite}
            acceptTerm={props?.acceptTerm}
            workEmail={props?.workEmail}
            logoCompany={props?.logoCompany}
            toogleAcceptTerm={(p: boolean) => props?.toogleAcceptTerm(p)}
            errorDiffWorkEmail={props.errorDiffWorkEmail}
            emailRequest={props?.emailRequest}
            sendReQuestClaim={() => props?.sendReQuestClaim()}
            isShowLoaderSendClaim={props?.isShowLoaderSendClaim}
            showContactUs={() => props?.showContactUs()}
          />
        </Modal.Body>
        {props?.acceptTerm && !props?.errorDiffWorkEmail && (
          <Modal.Footer>
            <div className="contentBorder formForEmail text-right">
              <button
                className="btn btn-devinsider cancel"
                onClick={() => props?.cancelSendClaim()}
              >
                Cancel
              </button>
              <button
                className="btn btn-devinsider px-5"
                onClick={() => props?.sendReQuestClaim()}
              >
                {props?.isShowLoaderSendClaim ? <Loader /> : "Claim ownership "}
              </button>
              <div className="haveQuestion">
                Have any questions?&nbsp;
                <a href="javascript:;" onClick={() => props?.showContactUs()}>
                  Contact us
                </a>
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
      {/* end Request  step 1*/}
    </div>
  );
}
