import React, { useState, memo } from "react";
import { IAd } from "../../../redux/types/classified-ads-interface";
import {
  fileUrl,
  treatDuration,
  concatString,
  checkIfExist,
} from "../../../utils";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { adsFavorite } from "../../../service/applicatif/classified-ads";
import { OEM_PROGRAM } from "../../../service/constant";

interface IProps {
  ads: IAd;
  setShowLogin: any;
  selectedAds?: (item: IAd) => void;
  goToCategory: (idCategory: string) => void;
}

function AdsItem(props: IProps) {
  const userReducer = useSelector((state: rootState) => state?.userReducer);
  const oemProgramReducer = useSelector(
    (state: rootState) => state?.oemProgramReducer.program
  );
  const history = useHistory();
  const [isAdsFavorite, setIsAdsFavorite] = useState<boolean>(
    props?.ads?.liked === "1"
  );

  const handleLike = async (id: string) => {
    if (userReducer?.token === "") {
      return props?.setShowLogin(true);
    }
    //check if the user is OEM Ecosystem
    const favorite = await adsFavorite(
      props?.ads!.id,
      userReducer?.token,
      userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1
        ? oemProgramReducer.id
        : null
    );
    if (props?.selectedAds) {
      props.selectedAds(props?.ads);
    } else {
      setIsAdsFavorite(favorite);
    }
  };
  return (
    <div className="item">
      <a
        href="javascript:;"
        className="addFavorisAds"
        onClick={() => handleLike(props?.ads?.id)}
      >
        <span className={isAdsFavorite ? "icon-fav-full" : "icon-fav-line"} />
      </a>
      <div
        className={
          checkIfExist(props?.ads?.logo)
            ? "imgclassified "
            : "imgclassified empty"
        }
      >
        {checkIfExist(props?.ads?.logo) && (
          <img src={fileUrl(props?.ads?.logo)} alt="logo" />
        )}
      </div>
      <div className="infoAds">
        <h3
          className="title"
          onClick={() =>
            history.push("/classified-ads/details/" + props?.ads?.id)
          }
        >
          {props?.ads?.title}
        </h3>
        <div className="name">
          {props?.ads?.company_name}
          <div className="role">{props?.ads?.role.toUpperCase()}</div>
        </div>
        <div className="contry">
          {concatString(props?.ads?.country_name, props?.ads?.city)}
        </div>
      </div>
      <div className="otherInfo">
        <span
          className="topic"
          onClick={() => props.goToCategory(props?.ads?.classified_ads_type_id)}
        >
          {props?.ads?.type}
        </span>
        <div className="datePost">
          {props?.ads?.duration ? treatDuration(props?.ads?.duration) : ""}
        </div>
      </div>
    </div>
  );
}

export default memo(AdsItem);
