//Bug #47392 "email address already in use error" - Should not apply when activating Software Ecosystem/ OEM account

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { postInfoEom } from '../../../service/applicatif/acount';
import {
  isEmail,
  isRequired,
  length,
  between,
  getSStorage,
  isOnlyNumber,
} from '../../../utils/index';
import useEventListener from '../../../hooks/event-handler';
import { getData } from '../../../service/api';
import { url } from '../../../service/constant';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { contact } from '../../../utils/country_phone';

export default function usePartnerProgramSignup() {
  const [emailAlreadyExists, setEmailAlreadyExists] = useState<boolean>(false);
  const [checkingEmailAlreadyExists, setCheckingEmailAlreadyExists] =
    useState<boolean>(false);
  const [fisteNameEcosystem, setfisteNameEcosystem] = useState<string>('');
  const [lastNameEcosystem, setlastNameEcosystem] = useState<string>('');
  const [jobTitleEcosystem, setJobTitleEcosystem] = useState<string>('');
  const [companyEcosystem, setCompanyEcosystem] = useState<string>('');
  const [countryEcosystem, setCountryEcosystem] = useState<string>('');
  const [emailEcosystem, setEmailEcosteme] = useState<string>('');
  const [phoneEcosystem, setPhoneEcosystem] = useState<string>('');
  const [selected, setSelected] = useState('US');
  const [dialCodeSelected, setDialCodeSelected] = useState('+1');
  const [cgu, setStateCgu] = useState<boolean>(false);

  const [errorFisteNameEcs, setErrorfisteNameEcs] = useState<boolean>(false);
  const [errorLastNameEcs, seterrorLastNameEcs] = useState<boolean>(false);
  const [errorJobTitleEcs, seterrorJobTitleEcs] = useState<boolean>(false);
  const [errorCompanyEcs, setErrorCompanyEcs] = useState<boolean>(false);
  const [errorCountryEcs, setErrorCountryEcs] = useState<boolean>(false);
  const [errorEmailEcs, seterrorEmailEcs] = useState<boolean>(false);
  const [errorPhoneEcs, seterrorPhoneEcs] = useState<
    'validate' | 'required' | null
  >(null);
  const [cguNotAccepted, setCguNotAccepted] = useState(false);
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage('completed_account') || '{}')
  );
  const [emailError, setTxtEmailError] = useState<string>(
    'This field is required'
  );

  const [sucessSentData, setSucessSentData] = useState<boolean>(false);
  useEffect(() => {
    setErrorfisteNameEcs(false);
  }, [fisteNameEcosystem]);

  useEffect(() => {
    seterrorLastNameEcs(false);
  }, [lastNameEcosystem]);

  useEffect(() => {
    seterrorJobTitleEcs(false);
  }, [jobTitleEcosystem]);

  useEffect(() => {
    setErrorCompanyEcs(false);
  }, [companyEcosystem]);

  useEffect(() => {
    setErrorCountryEcs(false);
  }, [countryEcosystem]);

  useEffect(() => {
    seterrorEmailEcs(false);
  }, [emailEcosystem]);

  useEffect(() => {
    seterrorEmailEcs(false);
  }, [emailEcosystem]);

  useEffect(() => {
    seterrorPhoneEcs(null);
  }, [phoneEcosystem]);

  useEffect(() => {
    setCguNotAccepted(false);
  }, [cgu]);

  const [loader, setloader] = useState<boolean>(false);
  const history = useHistory();

  const validFirstName = (txt: string) => {
    if (isRequired(txt) && length(3, 25, txt)) {
      return true;
    } else {
      setErrorfisteNameEcs(true);
      return false;
    }
  };

  const validLastName = (txt: string) => {
    if (isRequired(txt) && length(3, 25, txt)) {
      return true;
    } else {
      seterrorLastNameEcs(true);
      return false;
    }
  };

  const validJob = (txt: string) => {
    if (isRequired(txt)) {
      return true;
    } else {
      seterrorJobTitleEcs(true);
      return false;
    }
  };

  const validCompany = (txt: string) => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErrorCompanyEcs(true);
      return false;
    }
  };

  const validCountry = (txt: string) => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErrorCountryEcs(true);
      return false;
    }
  };

  const validEmail = (email: string): boolean => {
    if (isEmail(email) && isRequired(email)) {
      return true;
    } else {
      seterrorEmailEcs(true);
      return false;
    }
  };

  const validPhone = (txt: string): boolean => {
    if (txt) {
      const validatePhone = isValidPhoneNumber(
        contact?.find((el: any) => el.code == selected)?.dial_code + txt
      );
      if (validatePhone) {
        return true;
      } else {
        seterrorPhoneEcs('validate');
        return false;
      }
    } else {
      seterrorPhoneEcs('required');
      return false;
    }
  };

  const acceptCgu = (txt: boolean) => {
    setStateCgu(txt);
  };

  const createAcount = async () => {
    const isValidForm =
      validFirstName(fisteNameEcosystem) &&
      validLastName(lastNameEcosystem) &&
      validJob(jobTitleEcosystem) &&
      validCompany(companyEcosystem) &&
      validCountry(countryEcosystem) &&
      validEmail(emailEcosystem) &&
      validPhone(phoneEcosystem) &&
      cgu &&
      emailEcosystem != '';

    if (isValidForm) {
      checkIfEmailAlreadyExists(emailEcosystem);
      if (emailAlreadyExists === true) return;

      setloader(true);
      const data = {
        firstname: fisteNameEcosystem,
        lastname: lastNameEcosystem,
        jobtitle: jobTitleEcosystem,
        company: companyEcosystem,
        country: countryEcosystem,
        email: emailEcosystem,
        phone: phoneEcosystem,
        information: true,
        type: 'ecosystem',
        countryCode: selected,
      };

      let response = await postInfoEom(data);
      setloader(false);
      if (response && response.status == 200) {
        setSucessSentData(true);
      }
    } else {
      if (!isRequired(emailEcosystem)) {
        setTxtEmailError('This field is required');
      } else {
        if (!isEmail(emailEcosystem)) {
          setTxtEmailError('your email address is not valid');
        }
      }
      if (!cgu) {
        setCguNotAccepted(true);
      }
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key == 'Enter') {
      createAcount();
    } else return;
  };

  //function to check if email already exists
  const checkIfEmailAlreadyExists = (email: string) => {
    //call api check email
    //update state
    setCheckingEmailAlreadyExists(true);
    setEmailAlreadyExists(false);
    (async () => {
      const response = await getData(
        url.acount.checkEmail + '/' + email + '/1',
        ''
      );
      if (response.status === 200) {
        if (response.data.details === true) setEmailAlreadyExists(true);
        setCheckingEmailAlreadyExists(false);
      }
    })();
  };

  useEventListener('keydown', keyEnter);

  return {
    fisteNameEcosystem,
    lastNameEcosystem,
    jobTitleEcosystem,
    companyEcosystem,
    countryEcosystem,
    emailEcosystem,
    phoneEcosystem,
    loader,
    errorFisteNameEcs,
    errorLastNameEcs,
    errorPhoneEcs,
    errorJobTitleEcs,
    errorCompanyEcs,
    errorCountryEcs,
    errorEmailEcs,
    cgu,
    history,
    emailError,
    cguNotAccepted,
    dataCompleted,
    sucessSentData,
    acceptCgu,
    setfisteNameEcosystem,
    setlastNameEcosystem,
    setJobTitleEcosystem,
    setCompanyEcosystem,
    setCountryEcosystem,
    setEmailEcosteme,
    setPhoneEcosystem,
    createAcount,
    checkIfEmailAlreadyExists,
    emailAlreadyExists,
    selected,
    setSelected,
    checkingEmailAlreadyExists,
    dialCodeSelected,
    setDialCodeSelected,
  };
}
