/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as Loader } from '../../../../../../resources/images/Rolling.svg';

export type IProps = {
  isShowTeamUnique: boolean;
  setIsShowTeamUnique: (p: boolean) => void;
  valueOfTeamUniqueForm: string;
  setValueOfTeamUniqueForm: (t: string) => void;
  loader: boolean;
  updatePicth: () => void;
};

export default function ModalTeamDifferenciator(props: IProps) {
  return (
    <Modal
      show={props.isShowTeamUnique}
      className='createCompanypopup large forStep5custom'
    >
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>Team differentiator</h2>
          <a className='close' onClick={() => props.setIsShowTeamUnique(false)}>
            <span className='icon-close-pop'></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className=''>
        <div className='contentBorder row forPitch'>
          <div className='form-group col-12'>
            <label htmlFor=''>What makes your team unique ?</label>
          </div>
          <div className='form-group col-12 descCompany m-0'>
            <textarea
              maxLength={2000}
              value={props.valueOfTeamUniqueForm}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                props.setValueOfTeamUniqueForm(event.target.value)
              }
            ></textarea>
            <span className='caractercount'>
              {0 + (props.valueOfTeamUniqueForm?.length || 0) + ' / 2000'}
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='contentBorder formForEmail text-right forPitch'>
          <button
            className='btn btn-devinsider cancel'
            onClick={() => props.setIsShowTeamUnique(false)}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-devinsider px-5 save'
            onClick={() => props.updatePicth()}
          >
            {props.loader ? <Loader /> : 'Save'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
