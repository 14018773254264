import React, { ChangeEvent } from "react";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import Form from "react-bootstrap/Form";
import UseCompleteCompanyIndustrieTargetReseller, {
  IListTarget,
} from "../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-company-industrie-target-reseller";
import { ReactComponent as Loader } from "../../../../../resources/images/loader-chargement.svg";
import UseCompleteIndistrueInvestor from "../../../../../controller/account-ctr/complete-create-account-investor/use-complete-indistrue-investor";
import useGoBack from "../../../../../hooks/use-navigate";

export default function CompleteIndistrueInvestor() {
  const {
    navigateToCompletePublishInvestor,
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    loader,
    companyTargetSelected,
    errorData,
  } = UseCompleteIndistrueInvestor();
  const { goBack } = useGoBack();
  return (
    <div>
      <Header />
      <div className="getstartedContainer largeContainer">
        <div className="head-getstarted">
          <a onClick={goBack} className="link-back">
            <span className="icon-back"></span>
          </a>
          <h1>Create your Investor page</h1>
          <ul className="stepInvestor">
            <li className="stepInprogress done ">
              <div className="cttSpan">
                <span className="icon-done"></span>
              </div>
              Investor page
            </li>
            <li className="stepInprogress done here">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV target
            </li>
            <li className="stepInprogress">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV outreach
            </li>
          </ul>
        </div>
        <div className="contentBorder border-top row pt-3 pb-4">
          <div className="form-group required col-xl-12 p-0 m-0">
            <label htmlFor="" className="pb-3">
              Define which industries the ISV should target?
              {errorData && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">
                    select all least one industry
                  </span>
                </div>
              )}
            </label>
            {loader && <Loader />}
            <div className="contentTargetIndustry">
              <div className="col4">
                {companyTarget &&
                  companyTarget.map((element: IListTarget, index: number) => (
                    <Form.Check
                      key={index}
                      custom
                      checked={
                        companyTargetSelected?.find(
                          (ele: IListTarget) => ele.id == element.id
                        )
                          ? true
                          : false
                      }
                      defaultChecked={false}
                      label={element.name}
                      id={element.id.toString()}
                      onClick={() => {
                        addTargetCompany(element);
                      }}
                      onChange={() => null}
                    />
                  ))}
              </div>
              <div className="col4">
                {companyTargetSecound &&
                  companyTargetSecound.map(
                    (element: IListTarget, index: number) => (
                      <Form.Check
                        key={index}
                        custom
                        defaultChecked={false}
                        checked={
                          companyTargetSelected?.find(
                            (ele: IListTarget) => ele.id == element.id
                          )
                            ? true
                            : false
                        }
                        label={element.name}
                        id={element.id.toString()}
                        onClick={() => {
                          addTargetCompany(element);
                        }}
                        onChange={() => null}
                      />
                    )
                  )}
              </div>
              <div className="col4">
                {lastCompanyTarget &&
                  lastCompanyTarget.map(
                    (element: IListTarget, index: number) => (
                      <Form.Check
                        key={index}
                        custom
                        defaultChecked={false}
                        onChange={() => null}
                        checked={
                          companyTargetSelected?.find(
                            (ele: IListTarget) => ele.id == element.id
                          )
                            ? true
                            : false
                        }
                        label={element.name}
                        id={element.id.toString()}
                        onClick={() => {
                          addTargetCompany(element);
                        }}
                      />
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="contentBorder border-top row align-items-stretch pt-5">
          <button
            className="btn btn-devinsider large"
            onClick={() => navigateToCompletePublishInvestor()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
