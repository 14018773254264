import React, { useState } from 'react';
import Header from '../../../widget/index';
import '../programs.scss';
import ModalLicensingModel from './complete-company-page-modals/licensing-model';
import ModalGeographyTarget from './complete-company-page-modals/geography-target';
import ModalSummary from './complete-company-page-modals/company-overview';
import ModalTechnologyPartner from './complete-company-page-modals/partnership-company';
import ModalAnnualTurnover from './complete-company-page-modals/annual-turnover';
import {
  UseFinancialCtr,
  UseGeographieTarget,
  UseLicencingModelDistributionChannel,
  UseOverview,
} from '../../../../controller/company-ctr/profil/block-profil-company-controller';
import UseProgramCompleteCompanyPage from '../../../../controller/program-ctr/program-matchmaking-ctr';
import {
  isHaveCompanyLogo,
  isRequired,
  validateCompleteInfo,
} from '../../../../utils';
import Footer from '../../../widget/footer/footer';
import ModalPhoto from '../../../widget/modal/modal-photo';
import UseModalPhoto from '../../../widget/modal/use-modal-photo';
import ModalEditTargetIndustry from '../../company/profil/modal-edit-bloc-company/edit-target-industry';
import UseTargetIndustrieCtr from '../../../../controller/company-ctr/profil/block-profil-company-controller/use-target-industrie-ctr';
import ModalEditBasicInfo from '../../company/profil/modal-edit-bloc-company/edit-company-information';
import UseInfoCompanyCtr from '../../../../controller/company-ctr/profil/block-profil-company-controller/use-user-info-ctr';
import UsePartenerShipCtr from '../../../../controller/company-ctr/profil/block-profil-company-controller/use-partener-ship-ctr';
import { NavLink } from 'react-router-dom';
import MatchingProgram from '../../../../hooks/MatchingProgram';

export default function CompletedPage() {
  const providerCompleteCompanyPage = UseProgramCompleteCompanyPage();
  const providerUseLicencingDistributionC =
    UseLicencingModelDistributionChannel();
  const providerUseGeographieTarget = UseGeographieTarget();
  const providerUseOverview = UseOverview();
  const providerUseFinancialCtr = UseFinancialCtr();
  const providerPhoto = UseModalPhoto();
  const providerIndustry = UseTargetIndustrieCtr();
  const providerUseInfoCompanyCtr = UseInfoCompanyCtr();
  const providerPartenerShip = UsePartenerShipCtr();

  const [showCompletedPage, setShowCompletedPage] = useState(false);

  console.log(
    'showCompletedPage',
    providerCompleteCompanyPage?.companyPageObject.website
  );

  return (
    <MatchingProgram>
      <div className='TwoCaseContainer'>
        <Header />
        {providerCompleteCompanyPage?.progress > 0 ? (
          <div className={`"container mx-auto"`}>
            <div className='contentBorder'>
              <h3 className='title text-center'>Complete your company page</h3>
              <div className='sTitle'>
                <p className='text-center'>
                  To match with Software ecosystem we need some additional
                  information.
                </p>
                <label htmlFor=''>
                  {' '}
                  {providerCompleteCompanyPage?.progress} - remaining completed
                  info
                </label>
                {/* <ProgressBar now={providerCompleteCompanyPage?.progress * 20} /> */}
              </div>
              <div className='list'>
                <ul className='listCompletition'>
                  {!isRequired(
                    providerCompleteCompanyPage?.companyPageObject?.website
                  ) && (
                    <li>
                      <NavLink
                        to='#'
                        className='linkEditLeftCompany'
                        onClick={() =>
                          providerUseInfoCompanyCtr?.setShowRequestBasicInformation(
                            true
                          )
                        }
                      >
                        Edit<span className='icon-edit-full'></span>
                      </NavLink>
                      <div className='completitionLogo'>
                        <span
                          className={`icon-${
                            isRequired(
                              providerCompleteCompanyPage?.companyPageObject
                                ?.website
                            )
                              ? 'check'
                              : 'warning'
                          }`}
                        />
                      </div>
                      <div className='completitionInfo'>
                        <span className='title'>Company information</span>
                        <div>Add your company website.</div>
                      </div>
                    </li>
                  )}

                  {!isRequired(
                    providerCompleteCompanyPage?.companyPageObject
                      ?.company_summary
                  ) && (
                    <li>
                      <NavLink
                        to='#'
                        className='linkEditLeftCompany'
                        onClick={() =>
                          providerUseOverview?.setShowOverview(true)
                        }
                      >
                        Edit<span className='icon-edit-full'></span>
                      </NavLink>
                      <div className='completitionLogo'>
                        <span
                          className={`icon-${
                            isRequired(
                              providerCompleteCompanyPage?.companyPageObject
                                ?.company_summary
                            )
                              ? 'check'
                              : 'warning'
                          }`}
                        />
                      </div>
                      <div className='completitionInfo'>
                        <span className='title'>Company description</span>
                        <div>Outline a brief overview of your company.</div>
                      </div>
                    </li>
                  )}

                  {!isHaveCompanyLogo(
                    providerCompleteCompanyPage?.companyPageObject?.photo_profil
                  ) && (
                    <li>
                      <NavLink
                        to='#'
                        className='linkEditLeftCompany'
                        onClick={() =>
                          providerPhoto?.setIsShowUpdateProfil(true)
                        }
                      >
                        Edit<span className='icon-edit-full'></span>
                      </NavLink>
                      <div className='completitionLogo'>
                        <span
                          className={`icon-${
                            isHaveCompanyLogo(
                              providerCompleteCompanyPage?.companyPageObject
                                ?.photo_profil
                            )
                              ? 'check'
                              : 'warning'
                          }`}
                        />
                      </div>
                      <div className='completitionInfo'>
                        <span className='title'>Company logo </span>
                        <div>Upload your company logo.</div>
                      </div>
                    </li>
                  )}

                  {!isRequired(
                    providerCompleteCompanyPage?.companyPageObject
                      ?.targeted_industry
                  ) && (
                    <li>
                      <NavLink
                        to='#'
                        className='linkEditLeftCompany'
                        onClick={() =>
                          providerIndustry.setShowRequestTargetIndustry(true)
                        }
                      >
                        Edit<span className='icon-edit-full'></span>
                      </NavLink>
                      <div className='completitionLogo'>
                        <span
                          className={`icon-${
                            isRequired(
                              providerCompleteCompanyPage?.companyPageObject
                                ?.targeted_industry
                            )
                              ? 'check'
                              : 'warning'
                          }`}
                        ></span>
                      </div>
                      <div className='completitionInfo'>
                        <span className='title'>Target industry</span>
                        <div>Select your target industry(s).</div>
                      </div>
                    </li>
                  )}

                  {(!isRequired(
                    providerCompleteCompanyPage?.companyPageObject
                      ?.licensing_model
                  ) ||
                    !isRequired(
                      providerCompleteCompanyPage?.companyPageObject
                        ?.distribution_channel
                    )) && (
                    <li>
                      <NavLink
                        to='#'
                        className='linkEditLeftCompany'
                        onClick={() =>
                          providerUseLicencingDistributionC?.setIsShowLicencingModel(
                            true
                          )
                        }
                      >
                        Edit<span className='icon-edit-full'></span>
                      </NavLink>
                      <div className='completitionLogo'>
                        <span
                          className={`icon-${
                            isRequired(
                              providerCompleteCompanyPage?.companyPageObject
                                ?.licensing_model
                            ) &&
                            isRequired(
                              providerCompleteCompanyPage?.companyPageObject
                                ?.distribution_channel
                            )
                              ? 'check'
                              : 'warning'
                          }`}
                        ></span>
                      </div>
                      <div className='completitionInfo'>
                        <span className='title'>Licensing model</span>
                        <div>Select your licensing model(s).</div>
                      </div>
                    </li>
                  )}

                  {!isRequired(
                    providerCompleteCompanyPage?.companyPageObject
                      ?.targeted_country
                  ) && (
                    <li>
                      <NavLink
                        to='#'
                        className='linkEditLeftCompany'
                        onClick={() =>
                          providerUseGeographieTarget?.setshowStrategyGeographic(
                            true
                          )
                        }
                      >
                        Edit<span className='icon-edit-full'></span>
                      </NavLink>
                      <div className='completitionLogo'>
                        <span
                          className={`icon-${
                            isRequired(
                              providerCompleteCompanyPage?.companyPageObject
                                ?.targeted_country
                            )
                              ? 'check'
                              : 'warning'
                          }`}
                        ></span>
                      </div>
                      <div className='completitionInfo'>
                        <span className='title'>Office in North America</span>
                        <div>Do you have an entity in North America.</div>
                      </div>
                    </li>
                  )}

                  {!isRequired(
                    providerCompleteCompanyPage?.companyPageObject
                      ?.oem_program_segment_interest
                  ) && (
                    <li>
                      <NavLink
                        to='#'
                        className='linkEditLeftCompany'
                        onClick={() =>
                          providerPartenerShip?.setisShowPartenerShipModal(true)
                        }
                      >
                        Edit<span className='icon-edit-full'></span>
                      </NavLink>
                      <div className='completitionLogo'>
                        <span
                          className={`icon-${
                            isRequired(
                              providerCompleteCompanyPage?.companyPageObject
                                ?.oem_program_segment_interest
                            )
                              ? 'check'
                              : 'warning'
                          }`}
                        ></span>
                      </div>
                      <div className='completitionInfo'>
                        <span className='title'>
                          Fields of technology partnership you are interested in
                        </span>
                        <div>
                          Helps you find the most relevant programs based on
                          your interests.
                        </div>
                      </div>
                    </li>
                  )}

                  {!isRequired(
                    providerCompleteCompanyPage?.companyPageObject
                      ?.annual_turnover
                  ) && (
                    <li>
                      <NavLink
                        to='#'
                        className='linkEditLeftCompany'
                        onClick={() =>
                          providerUseFinancialCtr?.setIsShowFinancialAnnual(
                            true
                          )
                        }
                      >
                        Edit<span className='icon-edit-full'></span>
                      </NavLink>
                      <div className='completitionLogo'>
                        <span
                          className={`icon-${
                            isRequired(
                              providerCompleteCompanyPage?.companyPageObject
                                ?.annual_turnover
                            )
                              ? 'check'
                              : 'warning'
                          }`}
                        ></span>
                      </div>
                      <div className='completitionInfo'>
                        <span className='title'>Annual revenue</span>
                        <div>
                          Select one of the brackets to indicate your
                          approximate revenue.
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className='pageCompleted'>
            <div className='container mx-auto'>
              <div className='contentBorder text-center'>
                <span className='icon-check'></span>
                <h3 className='title text-center mt-5'>
                  Company page successfully completed.
                </h3>
                <div className='sTitle'>
                  <p className='text-center'>
                    You can match with Software ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/*Modal Edit Summary*/}
        <ModalSummary
          isShowOverView={providerUseOverview?.showOverview}
          setIsShowOverView={providerUseOverview?.setShowOverview}
          modalValueSummary={providerUseOverview?.modalValueSummary}
          setModalValueSummary={providerUseOverview?.setModalValueSummary}
          updateCompanySummary={providerUseOverview?.updateCompanySummary}
          cancelUpdateSummary={providerUseOverview?.cancelUpdateSummary}
          isLoader={providerUseOverview?.loaderOverView}
          summaryLength={providerUseOverview?.summaryLength}
          setSummaryLength={providerUseOverview?.setSummaryLength}
        />

        {/*Modal edit licensing and distribution*/}
        <ModalLicensingModel
          isShowLicencingModel={
            providerUseLicencingDistributionC?.isShowLicencingModel
          }
          cancelUpdateCategorie={
            providerUseLicencingDistributionC?.cancelUpdateCategorie
          }
          licencingModelSelected={
            providerUseLicencingDistributionC?.licenCingModelSelected
          }
          licencingModelData={
            providerCompleteCompanyPage?.dataCompleted?.licensing_model
          }
          distributionModelData={
            providerCompleteCompanyPage?.dataCompleted?.distribution_channel
          }
          distributionModelSelected={
            providerUseLicencingDistributionC?.distributionModelSelected
          }
          addDistributionChannel={
            providerUseLicencingDistributionC?.addDistributionChannel
          }
          addLicencingChecked={
            providerUseLicencingDistributionC?.addLicencingChecked
          }
          updateLicencingModel={
            providerUseLicencingDistributionC?.updateLicencingModel
          }
          loaderlicencingModel={
            providerUseLicencingDistributionC?.loaderlicencingModel
          }
          isHaveError={providerUseLicencingDistributionC?.isHaveErrorCategorie}
        />

        {/*Modal office in north america*/}
        <ModalGeographyTarget
          showStrategyGeographic={
            providerUseGeographieTarget?.showStrategyGeographic
          }
          cancelGeoGraphieTarget={
            providerUseGeographieTarget?.cancelGeoGraphieTarget
          }
          allContinents={
            providerCompleteCompanyPage?.dataCompleted?.targetedCountry
          }
          paysChecked={providerUseGeographieTarget?.paysChecked}
          allContinentSelected={
            providerUseGeographieTarget?.allContinentSelected
          }
          addPaysInArray={providerUseGeographieTarget?.addPaysInArray}
          isShowOfficeData={providerUseGeographieTarget?.isShowOfficeData}
          dataCompleted={providerCompleteCompanyPage?.dataCompleted}
          addOffice={providerUseGeographieTarget?.addOffice}
          isShowErroCountrySelected={
            providerUseGeographieTarget?.isShowErroCountrySelected
          }
          isShowErrorOffice={false}
          selectedOffice={providerUseGeographieTarget?.selectedOffice}
          updateModalGeographicTarget={
            providerUseGeographieTarget?.updateModalGeographicTarget
          }
          loaderTargetCountry={providerUseGeographieTarget?.loaderTargetCountry}
        />

        {/*Modal technology partnership*/}
        <ModalTechnologyPartner
          cancelTechnologieParteners={
            providerPartenerShip.cancelTechnologieParteners
          }
          loaderPartenership={providerPartenerShip.loaderPartenership}
          providerTechnologyPartnerShip={providerPartenerShip}
          show={providerPartenerShip.isShowPartenerShipModal}
          updateTechnologyPartnerShip={
            providerPartenerShip.updateTechnologiePartener
          }
        />

        {/*Modal annual turnover*/}
        <ModalAnnualTurnover
          annualTurnoverData={
            providerCompleteCompanyPage?.dataCompleted?.annual_turnover
          }
          isShowFinancialAnnual={providerUseFinancialCtr?.isShowFinancialAnnual}
          setIsShowFinancialAnnual={
            providerUseFinancialCtr?.setIsShowFinancialAnnual
          }
          annualTurnOverSelected={
            providerUseFinancialCtr?.annualTurnOverSelected
          }
          setAnnulTurnover={providerUseFinancialCtr?.setAnnulTurnover}
          updateAnnualTurnOver={providerUseFinancialCtr?.updateAnnualTurnOver}
          loaderFinancial={providerUseFinancialCtr?.loaderFinancial}
        />

        <ModalPhoto {...providerPhoto} />

        {/* Modal Target industry */}
        <ModalEditTargetIndustry
          show={providerIndustry.show}
          cancelUpdataTargeted={providerIndustry.cancelUpdataTargeted}
          updateTargetIndustrie={providerIndustry.updateTargetIndustrie}
          loaderTargetIndustrie={providerIndustry.loaderTargetIndustrie}
          providerUseTargetIndustrieCtr={providerIndustry}
        />

        {/* POPUP basic information */}
        <ModalEditBasicInfo
          show={providerUseInfoCompanyCtr?.showRequestBasicInformation}
          handleSubmit={(p: any) => providerUseInfoCompanyCtr?.handleSubmit(p)}
          submitBasicInfo={(p: any) =>
            providerUseInfoCompanyCtr?.submitBasicInfo(p)
          }
          cancelUpdateform={providerUseInfoCompanyCtr?.cancelUpdateform}
          register={providerUseInfoCompanyCtr?.register}
          getValues={providerUseInfoCompanyCtr?.getValues}
          errors={providerUseInfoCompanyCtr?.errors}
          loaderProfil={providerUseInfoCompanyCtr?.loaderProfil}
        />
        {/* END POPUP basic information */}

        <Footer />
      </div>
    </MatchingProgram>
  );
}
