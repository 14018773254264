import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootState } from "../../../../redux/reducers";
import { checkPasswordUser } from "../../../../service/applicatif/acount";
import { url } from "../../../../service/constant";

export default function UseSigninCtr(callBackFunction: any) {
  const userReducer = useSelector((state: rootState) => state?.userReducer);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [showPassword, setShowPasswpord] = useState(false);
  const history = useHistory();
  const getStarted = (): void => {
    history.push("/account-choices");
  };

  //check user password
  const checkPassword = (): void => {
    setShowLoader(true);
    setError(false);
    (async () => {
      try {
        let response = await checkPasswordUser(
          url.acount.check_user_password,
          {
            password: password,
          },
          userReducer?.token
        );
        //Dev #48290 Moderator suppression message
        if (response?.status === 200) {
          callBackFunction();
          setShowLoader(false);
          setError(false);
        }
        setError(true);
        setShowLoader(false);
      } catch (error: any) {
        setError(true);
        setShowLoader(false);
      }
    })();
  };

  return {
    showLoader,
    password,
    error,
    showPassword,
    setShowPasswpord,
    getStarted,
    checkPassword,
    setPassword,
  };
}
