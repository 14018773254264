import UnsubscribeContact from '../screen/container/static-page/unsubscribe-contact';
import ClauseAccount from '../screen/container/static-page/clause-account';

export const unsubscribeContact = [
  {
    path: '/mailing/unsubscribe/odoo/platformenablement',
    guard: 'public',
    exact: true,
    component: UnsubscribeContact,
  },
  {
    path: '/mailing/unsubscribe/odoo/newpromotion',
    guard: 'public',
    exact: true,
    component: UnsubscribeContact,
  },
  {
    path: '/mailing/unsubscribe/odoo/partnercommunication',
    guard: 'public',
    exact: true,
    component: UnsubscribeContact,
  },
  {
    path: '/mailing/unsubscribe/odoo/community',
    guard: 'public',
    exact: true,
    component: UnsubscribeContact,
  },
  {
    path: '/closing-account',
    guard: 'public',
    exact: true,
    component: ClauseAccount,
  },
];
