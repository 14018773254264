/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import ReactFlagsSelect from "react-flags-select";
import UseCompletePageInvestor from "../../../../../controller/account-ctr/complete-create-account-investor/use-complete-page-investor";
import { contact } from "../../../../../utils/country_phone";
import useGoBack from "../../../../../hooks/use-navigate";

export default function CompleteInvestorPage() {
  const {
    navigateToInvestorInfo,
    setActive,
    setJobTitileInvestor,
    setOrganisationNameInvestor,
    setEmailInvestor,
    setPhoneNumberInvestor,
    selected,
    setSelected,
    toogleActive,
    jobTitileInvestor,
    organisationNameInvestor,
    emailInvestor,
    phoneNumberInvestor,
    errorJobTitileInvestor,
    errorOrganisationNameInvestor,
    errorEmailAdresseInvestor,
    errorPhoneNumberInvestor,
    txtEmailError,
    phoneNumberError,
    dialCodeSelected,
    setDialCodeSelected
  } = UseCompletePageInvestor();
  const { goBack } = useGoBack();
  const handleSelect = (countryCode: any) => {
    setSelected(countryCode);
    const dialCode = contact?.find(
      (el: any) => el.code == countryCode
    )?.dial_code;
    setDialCodeSelected(dialCode ? dialCode : "");
  };
  
  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <a className="link-back" onClick={goBack}>
            <span className="icon-back"></span>
          </a>
          <h1>Create your Investor page</h1>
          <ul className="stepInvestor">
            <li className="stepInprogress done here">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              Investor page
            </li>
            <li className="stepInprogress">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV target
            </li>
            <li className="stepInprogress">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV outreach
            </li>
          </ul>
        </div>
        <div className="contentBorder border-top row py-4">
          {/* <div className="col-12 form-group p-0 pb-4">
            <label htmlFor="">
              Welcome to Devinsider,{" "}
              {sessionStorage.getItem("firstNameInvestor")?.toString()}! Select
              your investor category:
            </label>
            <div className="choiceContent">
              <a
                className={
                  toogleActive
                    ? "choiceInvestorCategory active"
                    : "choiceInvestorCategory"
                }
                onClick={() => setActive()}
              >
                Institutional investor
              </a>
              <a
                className={
                  toogleActive
                    ? "choiceInvestorCategory"
                    : "choiceInvestorCategory active"
                }
                onClick={() => setActive()}
              >
                Individual investor
              </a>
            </div>
          </div> */}
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label htmlFor="jobtitle required">
              Job title
              <div
                className={`container-error pl-2 ${
                  errorJobTitileInvestor ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <input
              type="text"
              className="input-text form-control "
              value={jobTitileInvestor}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                setJobTitileInvestor(evt.target.value)
              }
            />
          </div>
          <div
            className={`${
              !toogleActive ? "" : "required"
            } form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2`}
          >
            <label htmlFor="organisation required">
              Name of the organization
              <div
                className={`container-error pl-2 ${
                  errorOrganisationNameInvestor ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <input
              type="text"
              className="input-text form-control "
              value={organisationNameInvestor}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                setOrganisationNameInvestor(evt.target.value)
              }
            />
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label htmlFor="adressemail required">
              Email address
              <div
                className={`container-error pl-2 ${
                  errorEmailAdresseInvestor ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">{txtEmailError}</span>
              </div>
            </label>
            <input
              type="text"
              className="input-text form-control "
              value={emailInvestor}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                setEmailInvestor(evt.target.value)
              }
            />
          </div>
          <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
            <label htmlFor="organisation required">
              Phone number
              <div
                className={`container-error pl-2 ${
                  errorPhoneNumberInvestor ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">{phoneNumberError}</span>
              </div>
            </label>
            <div className="numberphone" style={{ position: "relative" }}>
              <input
                type="number"
                className="phonenumber"
                style={{
                  textIndent:
                    dialCodeSelected?.length == 2
                      ? 35
                      : dialCodeSelected?.length == 3
                      ? 40
                      : dialCodeSelected?.length == 4
                      ? 50
                      : 60,
                  paddingBottom: 5,
                }}
                value={phoneNumberInvestor}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  setPhoneNumberInvestor(evt.target.value)
                }
              />
              <span style={{ position: "absolute", top: 5, left: 60 }}>
                ({dialCodeSelected})
              </span>
              <ReactFlagsSelect
                className="selectFlag"
                selected={selected}
                onSelect={handleSelect}
                searchable={true}
                showSelectedLabel={false}
                placeholder=" "
              />
            </div>
          </div>
        </div>
        <div className="contentBorder border-top row align-items-stretch">
          <button
            className="btn btn-devinsider large"
            onClick={() => navigateToInvestorInfo()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
