import './Community.scss';
import React, { ChangeEvent, useState } from 'react';
import Header from '../../widget/index';
import TabCommunity from './tab-community/index';
import UseCommunityCtr from '../../../controller/community-ctr/index';

/** MAIN SIDE COMMUNITY HOME */
import LatestDiscussion from './home-page/latest-discussion';
import DiscussionCategory from './home-page/discussion-category';
import AboutTheCommunity from './home-page/about-the-community';

/******* RIGHT SIDE OF THE COMMUNITY HOME PAGE ********/
import AboutCommunity from './home-page/about-community';
import HandyCommunityLinks from './home-page/handy-community-links';
import TopLikedContribution from './home-page/top-liked-contributions';
import TopLikedAuthor from './home-page/top-liked-author';
import TopBestAnswerAuthor from './home-page/top-best-answer-author';
import UnanswerdDiscussion from './home-page/unanswered-discussion';
import Footer from '../../widget/footer/footer';
import store from '../../../redux/store';

export default function HomePageCommunity() {
  const providerCommunityProps = { ...UseCommunityCtr() };
  let currentUser = store.getState().userReducer?.token;
  return (
    <div>
      <Header />
      <main className='bodyDevinsider'>
        <section className='search'>
          <div className='container'>
            <form action='' className='searchWord'>
              <input
                type='text'
                className='keyword'
                value={providerCommunityProps?.inputSearch}
                onChange={(txt: ChangeEvent<HTMLInputElement>) =>
                  providerCommunityProps?.setInputSearch(txt.target.value)
                }
              />
              <button onClick={providerCommunityProps?.showResult}>
                <span className='icon-search'></span>
              </button>
            </form>
          </div>
        </section>
        <section className='joinConversation'>
          <h1 className='title pb-5'>
            Join the conversation. Discuss your company challenges:
          </h1>
          <div className='contentNavConversation'>
            <div className='container p-0'>
              <TabCommunity
                featuredLabel={providerCommunityProps?.featuredLabels}
                discussionCategories={
                  providerCommunityProps?.discussionCategory
                }
                navigateToFilterCategorie={(p: any) =>
                  providerCommunityProps?.navigateToCategorie(p)
                }
              />
            </div>
          </div>
        </section>
        <section className='forumCommunity'>
          <div className='container mx-auto d-flex community2Col'>
            <div className='rightCommunityForum'>
              {/**** RIGHT SIDE COMMUNITY HOME PAGE ********/}
              <div className='block'>
                <LatestDiscussion
                  latestProvider={
                    providerCommunityProps?.communityReducer?.latestPost
                  }
                  selectedCategorie={providerCommunityProps?.selectedCategorie}
                  setSelectedCategorie={(p: any) =>
                    providerCommunityProps?.setDefaultLatest(p)
                  }
                  loader={providerCommunityProps?.loaderLatest}
                  goReply={(p: any) =>
                    providerCommunityProps?.goToreplayPost(p)
                  }
                  goActivity={(id: string) =>
                    providerCommunityProps?.goActivity(id)
                  }
                  showAllListe={(type: any) =>
                    providerCommunityProps?.showAllListe(type)
                  }
                  goToLabelCategory={(item: any, idLabelTags: string) =>
                    providerCommunityProps.goToLabelCategory(item, idLabelTags)
                  }
                />
              </div>
              <div className='block'>
                <DiscussionCategory />
              </div>
              <div className='block'>
                <AboutTheCommunity />
              </div>
            </div>

            {/**** RIGHT SIDE COMMUNITY HOME PAGE ********/}
            <div className='leftCommunityForum'>
              {(currentUser === '' || currentUser === null) && (
                <div className='block'>
                  <AboutCommunity />
                </div>
              )}

              <div className='block'>
                <HandyCommunityLinks />
              </div>
              <div className='block'>
                <TopLikedContribution />
              </div>
              <div className='block'>
                <TopLikedAuthor />
              </div>
              <div className='block'>
                <TopBestAnswerAuthor />
              </div>
              <div className='block'>
                <UnanswerdDiscussion />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
