import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../redux/reducers";
import { useState } from "react";
import { postData } from "../../service/api";
import { toast } from "react-toastify";
import { unpublishInvestorPage } from "../../redux/actions/company-page-investor-action/my-investor-page-action";

export default function useUnpublishProgram() {
  const state = useSelector((state: rootState) => state);
  const program = state.companyInvestorPageReducer;
  const dispatch = useDispatch();
  const user = state.userReducer;
  const [showPopupUnpublish, setShowPopupUnpublish] = useState<boolean>(false);
  const [showBtnUnpublish, setShowBtnUnpublish] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [gearClick, setGearClick] = useState(1);
  const handleUnpublish = () => {
    setProcessing(true);
    (async () => {
      let response = await postData(
        "/api/matching/unpublish_program",
        {
          programType: "INVESTOR_PAGE",
          programId: program?.id,
        },
        user.token
      );

      //check for result
      if (response.status === 200) {
        if (response.data === "updated") {
          //update redux
          dispatch(unpublishInvestorPage());
          toast("Your page has been unpublished successfully!");
        }
      } else {
        toast("An error occured, please try again!");
      }
      setShowPopupUnpublish(false);
      setShowBtnUnpublish(false);
      setProcessing(false);
      setGearClick(gearClick+1);
    })();
  };
  return {
    handleUnpublish,
    showPopupUnpublish,
    showBtnUnpublish,
    setShowPopupUnpublish,
    setShowBtnUnpublish,
    processing,
    gearClick,
    setGearClick
  };
}
