import React from "react";
import PostByUserCtr from "../../../../../controller/community-ctr/activity-user/posts-by-user-ctr";
import { LoadingArticle } from "../../../../widget/loader";
import Pagination from "../../../../widget/use-pagination/pagination";
// import ItemRender from "./bloc-all-post/item-render";
import * as Block from "./bloc-all-post/index";
type IProps = {
  userId: number;
  user: any;
  roleCurrentUser: string;
  roleUserShowInActivity?: string;
  showDiscussion: any;
  getBadge: any;
  showLabel: any;
};
const PostsByUser = (props: IProps) => {
  const providerPostByUser = PostByUserCtr(props?.userId);

  return (
    <>
      <div className="titleCategory">
        {props?.user && (
          <Block.SeachPostBy
            name={props?.user?.user_name}
            handleChangePostType={(e: any) =>
              providerPostByUser.handleChangePostType(e)
            }
            roleCurrentUser={props.roleCurrentUser}
            roleUserShowInActivity={props.roleUserShowInActivity}
          />
        )}

        <div className="tri">
          <div className="labelSelect">
            <label>Show:</label>
            <Block.ShowTri
              handleChangeShow={(e: any) =>
                providerPostByUser.handleChangeShow(e)
              }
              limit={providerPostByUser.postUserFilter.limit}
            />
          </div>

          <div className="sortedby labelSelect">
            <label>Sorted by :</label>
            <Block.SortedBy
              handleChangeSorted={(e: any) =>
                providerPostByUser.handleChangeSorted(e)
              }
            />
          </div>
        </div>
      </div>
      <ul className="feedList categoryList">
        {providerPostByUser?.loadingPost ? (
          <LoadingArticle />
        ) : (
          <Block.ItemRender
            userPost={providerPostByUser?.postsByCurrentUser?.results}
            showDiscussion={props?.showDiscussion}
            getBadge={props?.getBadge}
            showLabel={props.showLabel}
            goToreplayPost={(postId: number, categoryId: number) =>
              providerPostByUser?.goToreplayPost(postId, categoryId)
            }
          />
        )}
      </ul>
      {providerPostByUser?.postsByCurrentUser?.total > 0 && (
        <div className="pagginationCategory">
          <div className="labelSelect">
            <label>Show:</label>
            <Block.ShowTri
              handleChangeShow={(e: any) =>
                providerPostByUser.handleChangeShow(e)
              }
              limit={providerPostByUser.postUserFilter.limit}
            />
          </div>
          <div className="pagination">
            <Pagination
              totalCount={providerPostByUser?.postsByCurrentUser?.total}
              currentPage={providerPostByUser?.postUserFilter?.page * 1}
              className="pagination"
              idContainer="postByUser"
              pageSize={providerPostByUser?.postUserFilter?.limit}
              onPageChange={(response: any) =>
                providerPostByUser?.setPostUserFilter({
                  ...providerPostByUser?.postUserFilter,
                  page: response,
                })
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PostsByUser;
