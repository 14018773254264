import { IAction, UserObject } from "../../types/acount-interface";
// import {
//   CREATE_USER,
//   SIGNIN,
//   UPDATE_USER,
//   LOGOUT,
//   UPDATE_EMAIL_USER,
//   UPDATE_INFO_USER,
//   REFRESH_TOKEN,
//   UPDATE_OEM_PROGRAM_INFO,
//   ADD_NEW_OEM_PROGRAM,
//   UPDATE_LOGO_GUEST,
//   UPDATE_EXPERT_OVERVIEW,
//   UPDATE_OEM_PROGRAM_ROLE,
//   HANDLE_COMMUNITY_PROFILE,
//   REMOVE_PHOTO_PROFILE_USER,
//   UPDATE_PROGRAM_LIST,
// } from "../../actions/acounts-action";
const CREATE_USER = "CREATE_USER";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILED = "LOGIN_FAILED";
const LOGIN_LODING = "LOGIN_LODING";
const SIGNIN = "SIGNIN";
const UPDATE_USER = "UPDATE_USER";
const LOGOUT = "LOGOUT";
const REFRESH_TOKEN = "REFRESH_TOKEN";
const UPDATE_EMAIL_USER = "UPDATE_EMAIL_USER";
const UPDATE_INFO_USER = "UPDATE_INFO_USER";
const UPDATE_OEM_PROGRAM_INFO = "UPDATE_OEM_PROGRAM_INFO";
const ADD_NEW_OEM_PROGRAM = "ADD_NEW_OEM_PROGRAM";
const UPDATE_LOGO_GUEST = "UPDATE_LOGO_GUEST";
const UPDATE_EXPERT_OVERVIEW = "UPDATE_EXPERT_OVERVIEW";
const DELETEUSER = "DELETEUSER";
const UPDATE_LOGO_EXPERT = "UPDATE_LOGO_EXPERT";
const UPDATE_OEM_PROGRAM_ROLE = "UPDATE_OEM_PROGRAM_ROLE";
const HANDLE_COMMUNITY_PROFILE = "HANDLE_COMMUNITY_PROFILE";
//Bug #47385 delete profile photo
const REMOVE_PHOTO_PROFILE_USER = "REMOVE_PHOTO_PROFILE_USER";
const UPDATE_PROGRAM_LIST = "UPDATE_PROGRAM_LIST";

export const initialState: UserObject = {
  //Bug #47415 Visibility setting display in community
  encoded_username: "",
  email: "",
  roles: [],
  username: "",
  enable: false,
  last_name: "",
  first_name: "",
  plain_text: "",
  file_name: "",
  country: {
    id: "",
    country_name: "",
    continent: {
      name: "",
    },
  },
  phone: "",
  phone_number_code: "",
  company_name: "",
  job_title: "",
  website: "",
  isvInCompanyPage: null,
  guest_user_type: "",
  primary_email: "",
  linkedin: "",
  city: "",
  token: "",
  token_mercure: "",
  token_notification: [],
  user_community_profil: "",
  lock_primary_email: false,
  lock_work_email: false,
  lock_phone_number: false,
  created_at: "",
  refreshToken: "",
  id: "",
  oemProgramMembers: [],
  investor_members: {
    investor_page: {
      id: "0",
      organization_name: "",
      photo_profil: "",
      status: false,
    },
    role: "",
  },
  resellerCompanies: [
    {
      id: 0,
      company_trade_name: "",
      company_legal_name: "",
    },
  ],
  guestCompany: {
    id: 0,
    logo: "",
  },
  expertCompany: {
    id: "",
    company_overview: "",
    logo: "",
    city: "",
    company_legal_name: "",
    company_trade_name: "",
    country: {
      country_name: "",
      id: 0,
    },
    website: "",
  },
};

export const userReducer = (
  state = initialState,
  action: IAction
): UserObject => {
  switch (action.type) {
    //Bug #47385 delete profile photo
    case REMOVE_PHOTO_PROFILE_USER:
      return { ...state, file_name: action.payload };
    case CREATE_USER:
      return { ...state, ...action.payload };
    case SIGNIN:
      return { ...state, ...action.payload };
    case UPDATE_USER:
      return { ...state, ...action.payload };
    case UPDATE_EMAIL_USER:
      return { ...state, ...action.payload };
    case UPDATE_INFO_USER:
      return { ...state, ...action.payload };
    case UPDATE_OEM_PROGRAM_INFO:
      return {
        ...state,
        oemProgramMembers: helperUpdateOem(
          action?.payload,
          state?.oemProgramMembers
        ),
      };
    case UPDATE_OEM_PROGRAM_ROLE:
      return {
        ...state,
        oemProgramMembers: action.payload,
      };
    case ADD_NEW_OEM_PROGRAM:
      return {
        ...state,
        oemProgramMembers: [...state.oemProgramMembers, action?.payload],
      };

    case UPDATE_LOGO_GUEST:
      return {
        ...state,
        guestCompany: action.payload,
      };

    case UPDATE_EXPERT_OVERVIEW:
      return {
        ...state,
        expertCompany: action.payload,
      };

    case HANDLE_COMMUNITY_PROFILE:
      return {
        ...state,
        user_community_profil: action.payload,
      };
    case UPDATE_PROGRAM_LIST:
      return {
        ...state,
        oemProgramMembers: action.payload,
      };

    case REFRESH_TOKEN:
      return { ...state, ...action.payload };
    case LOGOUT:
      return { ...state };
    default:
      return state;
  }
};

const helperUpdateOem = (data: any, state: any) => {
  let tempData = state?.map((el: any) => {
    if (el?.oem_program?.id?.toString() === data?.id?.toString()) {
      let changeData = {
        ...el,
        oem_program: {
          ...state?.oem_program,
          program_name: data?.oem_name,
        },
      };
      return changeData;
    } else {
      return el;
    }
  });

  return tempData;
};

// role: string;
//   oem_program: {
//     id: number;
//     program_name: string;
//     status: boolean;
//   };
