import OneCase from '../screen/container/programs/program-matchmaking/complete-info';
import TwoCase from '../screen/container/programs/program-matchmaking/complete-info-process';
import ThreeCase from '../screen/container/programs/program-matchmaking/matching-panel';
import Directory from './../screen/container/programs/program-directory';
import Event from './../screen/container/programs/program-event';
import ProgramMatchmaking from '../screen/container/programs/program-matchmaking';
import Favorites from '../screen/container/programs/favorites/index';
import HomeFindProgram from '../screen/container/programs/home-find-program/index';
import NotFound from '../screen/container/not-found/not-found';
import { OEM_PROGRAM } from '../service/constant';

export const routesPrograms = [
  {
    component: Directory,
    exact: true,
    path: '/programs/home',
    guard: 'public',
  },
  {
    component: OneCase,
    exact: true,
    path: '/programs/one-case',
    guard: 'private',
    allowedRole: [OEM_PROGRAM],
  },
  {
    component: TwoCase,
    exact: true,
    path: '/programs/two-case',
    guard: 'public',
  },
  {
    //Comment for comming soon
    // component: NotFound, //ThreeCase,
    component: ThreeCase,
    exact: true,
    path: '/programs/program-matching',
    guard: 'public',
  },
  {
    //Comment for comming soon

    //component: Event,
    component: NotFound,
    exact: true,
    path: '/programs/event/:event_id',
    guard: 'public',
  },
  {
    //Comment for comming soon
    // component: ProgramMatchmaking,
    component: NotFound,
    exact: true,
    path: '/program/matchmaking',
    guard: 'private',
  },
  {
    component: NotFound, // Favorites,
    exact: true,
    path: '/programs/favorites',
    gurad: 'private',
  },
  {
    component: HomeFindProgram,
    exact: true,
    path: '/homeFindProgram',
    gurad: 'public',
  },
];
