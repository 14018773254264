import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createUserAction } from "../../../redux/actions/acounts-action/index";
import { ISV } from "../../../service/constant/index";
import useEventListener from "../../../hooks/event-handler";
import { clearSessionObject, getSStorage } from "../../../utils";

export default function UseCompleteProfilPicture() {
  const history = useHistory();
  let params: any = useLocation();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [lastClicked, setLastClicked] = useState<File>();
  const [fileSizeError, setFileSizeError] = useState(false);

  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [skipLoader, setSkipIsLoaderShow] = useState<boolean>(false);
  const fileRef: React.RefObject<HTMLInputElement> = useRef(null);

  let infoUser: any = params.state;

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      if (event?.target?.files[0]) {
        if(event?.target?.files[0].size > 2 * 1024 * 1024) {
          setFileSizeError(true);
        } else {
          setFileSizeError(false);
          setSelectedFile(event?.target?.files[0]);
          setIsFilePicked(true);
        }
      }
    }
  };

  const handleBtn = () => {
    if (fileRef) {
      fileRef?.current?.click();
    }
    setFileSizeError(false);
  };

  const callBack = (data: any) => {
    let dataSend = { ...params?.state };
    if (data?.status == 500) {
      setIsLoaderShow(false);
      setError(true);
      setSkipIsLoaderShow(false);
      clearSessionObject();
      return;
    }
    setIsLoaderShow(false);
    setSkipIsLoaderShow(false);
    clearSessionObject();
    history.push("./complete-account", { email: dataSend?.email });
  };

  let customFom = (isAffiliated: boolean = false) => {
    if (isAffiliated) {
      return {
        ...params?.state,
        roles: getSStorage("type_account") ? getSStorage("type_account") : ISV,
        website: getSStorage("webSite"),
        subscriptionToken: getSStorage("token_affiliated"),
      };
    } else
      return {
        ...params?.state,
        roles: getSStorage("type_account") ? getSStorage("type_account") : ISV,
        website: getSStorage("webSite"),
      };
  };

  const validateIsvInscription = () => {
    
    setIsLoaderShow(true);
    const isAffiliated = getSStorage("type_account") ? true : false;
    let data = {
      ...customFom(isAffiliated),
      fileName: selectedFile ? selectedFile : "",
    };
    dispatch(createUserAction(data, callBack));

  };

  const skipvalidateIsvInscription = () => {
    setSkipIsLoaderShow(true);
    let data = {
      ...customFom(getSStorage("type_account") ? true : false),
    };
    dispatch(createUserAction(data, callBack));
  };

  const nextValidate = () => {};

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      validateIsvInscription();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    isFilePicked,
    selectedFile,
    fileRef,
    isLoaderShow,
    infoUser,
    error,
    params,
    history,
    skipLoader,
    skipvalidateIsvInscription,
    setError,
    setIsLoaderShow,
    changeHandler,
    handleBtn,
    validateIsvInscription,
    nextValidate,
    lastClicked,
    fileSizeError,
    setFileSizeError,
  };
}
