import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Transaction from "./use-transaction";
import Select from "react-select";
import { EventPayed, IpropsTransaction } from "./transaction-type";
import moment from "moment";
import Pagination from "../../../widget/use-pagination/pagination";
import { LoadingFavoriteProgram } from "../../../widget/loader/index";
import TimeRange from "./time-range";

type Iprops = {
  tabType: string;
};
export default function TabPanTransaction(props: Iprops) {
  const providerTransaction = Transaction({ tabType: props?.tabType });

  const formatTxtEvent = (type: EventPayed) => {
    return type === "BUY_UNLOCK_INVESTOR"
      ? "Investor unlock"
      : type === "BUY_CLASSIFIED_ADS"
      ? "Classified ad published"
      : type === "BUY_PRESS_RELEASE"
      ? "Press release published"
      : type === "BUY_UNLOCK_ISV"
      ? "Isv pages Unlock"
      : "";
  };
  return (
    <Tab.Pane eventKey="history_list">
      <TimeRange
        endDate={providerTransaction.endDate}
        lowDate={providerTransaction.lowDate}
        setEndDate={providerTransaction.setEndDate}
        setStartDate={providerTransaction.setStartDate}
      />
      <div className="contentBorder radius p-0 transaction">
        <div className="border-bottom">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
              <div className="title">
                <div className="sortedby labelSelect">
                  <label>Sorted by :</label>
                  <Select
                    onChange={(selected: any) =>
                      providerTransaction.setTriDate(selected?.value)
                    }
                    className="triOreder"
                    isClearable={false}
                    options={[
                      { value: "day", label: "Day" },
                      { value: "week", label: "Weeks" },
                      { value: "month", label: "Month" },
                    ]}
                    classNamePrefix="tri"
                    defaultValue={{
                      value: "day",
                      label: "Day",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
              <div className="title">Description</div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
              <div className="title">Status</div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
              <div className="title">Amount</div>
            </div>
          </div>
        </div>

        {providerTransaction.loaderGet
          ? LoadingFavoriteProgram()
          : providerTransaction?.transaction?.items?.map(
              (el: IpropsTransaction) => (
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                    <div className="">
                      {providerTransaction.triDate === "week"
                        ? el.week_year_char
                        : providerTransaction.triDate === "month"
                        ? el.month_year_char
                        : moment(new Date(el?.date)).format("MMM D, YYYY")}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
                    <div className="">
                      {formatTxtEvent(el?.description)}{" "}
                      {`(${el.number_of_payment}x${el.price_unit}.00)`}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
                    <div className="">{el.status}</div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
                    <div className="">{`$${el.amount}.00USD`}</div>
                  </div>
                </div>
              )
            )}

        <div className="pagginationCategory">
          <Pagination
            className="pagination"
            currentPage={providerTransaction?.transaction?.meta?.pages}
            totalCount={providerTransaction?.transaction?.meta?.total_count}
            pageSize={10}
            onPageChange={(page: any) =>
              providerTransaction?.handlePageClick(page)
            }
          />
        </div>
      </div>
    </Tab.Pane>
  );
}
