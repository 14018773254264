import React, { useState } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { forgotPassword } from "../../../service/applicatif/acount";

const queryString = require("query-string");

export default function useForgotPasswordCtr() {
  let params: any = useLocation();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [emailTemp, setEmailTemp] = useState<string>("");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [emailNotExist, setEmailNotExist] = useState<boolean>(false);

  const resendMailForgotPassword = async () => {
    setShowLoader(true);
    let data = {
      email,
    };
    let response = await forgotPassword(data);
    if (response?.status == 200 && response?.data) {
      setShowLoader(false);
      setEmailSent(true);
    } else {
      setEmailNotExist(true);
      setShowLoader(false);
    }
  };

  return {
    email,
    emailTemp,
    showLoader,
    emailSent,
    emailNotExist,
    setEmailNotExist,
    setEmail,
    setEmailTemp,
    resendMailForgotPassword,
  };
}
