import React from "react";
import { fileUrl } from "../../../../../utils";

type IpropsInvestorIsvHeader = {
  profil: string;
  userName: string;
  showProfil: (userId: string) => void;
  userId: string;
};
export default function CommunityHeader(props: IpropsInvestorIsvHeader) {
  return (
    <>
      <div className="d-flex mr-5">
        <div className="photo-title">
          <img src={fileUrl(props.profil)} alt="" />
        </div>
        <div
          className="info-title"
          onClick={() => props.showProfil(props.userId)}
        >
          <div className="company-title">
            <a href="javascript:;">{props?.userName}</a>
          </div>
        </div>
      </div>
    </>
  );
}
