/* eslint-disable eqeqeq */
import React, { ChangeEvent } from "react";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Select from "react-select";
import useGoBack from "../../../../../hooks/use-navigate";
import UseCompleteCategoryInvestor from "../../../../../controller/account-ctr/complete-create-account-investor/use-complete-target-investor";
const createSliderWithTooltip = Slider.Range;
const Range = createSliderWithTooltip;

export default function CompleteInvestorTarget() {
  const {
    navigateToIndustrieTarget,
    companySize,
    setCompanySize,
    annualTurnover,
    setAnnualTurnover,
    foundingRound,
    setFoundingRound,
    fundraisingGoal,
    setFundraisingGoal,
    errorCompanySize,
    errorAnnualTurnover,
    errorFoundingRound,
    errorFundraisingGoal,
    dataCompleted,
    companySizeSlide,
    customSetCompanySize,
    annualTurnoverSlide,
    customSetAnnualSize,
    fundraisingGoalSlide,
    customFundraisingGoalSlide,
    unitTurnover,
    unitTurnoverMax,
    unitFundraisingGoal,
    unitFundraisingGoalMax,
  } = UseCompleteCategoryInvestor();

  const { goBack } = useGoBack();

  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <a onClick={goBack} className="link-back">
            <span className="icon-back"></span>
          </a>
          <h1>Create your Investor page</h1>
          <ul className="stepInvestor">
            <li className="stepInprogress done ">
              <div className="cttSpan">
                <span className="icon-done"></span>
              </div>
              Investor page
            </li>
            <li className="stepInprogress done here">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV target
            </li>
            <li className="stepInprogress">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV outreach
            </li>
          </ul>
        </div>
        <div className="contentBorder border-top row py-4">
          <div className="col-12 form-group p-0">
            <label htmlFor="" className="fontNormal">
              Define the ISV characteristics you are interested in :
            </label>
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-3 pr-md-3  p-0 mb-5">
            <label htmlFor="jobtitle required">
              Company size
              <div
                className={`container-error pl-2 ${
                  errorCompanySize ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <Range
              className="targetInvestorrange"
              min={0}
              max={10}
              value={companySize}
              step={1}
              marks={{
                0: companySizeSlide[0] == 0 ? "0" : companySizeSlide[0],
                1: "",
                2: "",
                3: "",
                4: "",
                5: "",
                6: "",
                7: "",
                8: "",
                9: "",
                10:
                  companySizeSlide[1] == 10
                    ? "3000+"
                    : companySizeSlide[1] == "3200"
                    ? "3000+"
                    : companySizeSlide[1],
              }}
              onChange={(event) => {
                customSetCompanySize(event[0], event[1]);
                setCompanySize(event);
              }}
            />
          </div>
          <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-3 pl-md-3 mb-5">
            <label htmlFor="organisation required">
              Annual revenue
              <div
                className={`container-error pl-2 ${
                  errorAnnualTurnover ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <Range
              className="targetInvestorrange"
              min={1}
              max={90}
              step={10}
              value={annualTurnover}
              marks={{
                0:
                  //Dev #47947, V2.1 [Investor] Ajouter le $ pour les champs "Annual revenue" et "Fundraising goal"
                  (annualTurnoverSlide[0] <= 1
                    ? "$ 0 USD"
                    : "$ " + annualTurnoverSlide[0]?.toString()) + unitTurnover,
                10: "",
                20: "",
                30: "",
                40: "",
                50: "",
                60: "",
                70: "",
                80: "",
                90:
                  annualTurnoverSlide[1] == 600
                    ? "> $ 500" + unitTurnoverMax
                    : annualTurnoverSlide[1] == 90
                    ? "> $ 500 " + unitTurnoverMax?.toString()
                    : annualTurnoverSlide[1]?.toString() + unitTurnoverMax,
              }}
              onChange={(event) => {
                customSetAnnualSize(event[0], event[1]);
                setAnnualTurnover(event);
              }}
            />
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-3 pr-md-3  p-0 mb-5">
            <label htmlFor="adressemail required">
              Funding round
              <div
                className={`container-error pl-2 ${
                  errorFoundingRound ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <Select
              options={dataCompleted?.fundingRound?.filter((funding: any) => funding.id !== 5).map((el: any) => {
                return {
                  id: el.id,
                  label: el.name,
                  value: el.name,
                };
              })}
              className="multipleSelect"
              isMulti={true}
              placeholder="Select all that apply"
              value={foundingRound}
              onChange={(data) => {
                var i = 0;
                data.forEach((e) => {
                  i++;
                  if (e?.id == 5 && i == data?.length) {
                    data = [e];
                    return;
                  }
                  if (e?.id == 5 && i == 1) {
                    data = [data[1]];
                  }
                });
                setFoundingRound(data);
              }}
            />
          </div>
          <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-3 pl-md-3 mb-5">
            <label htmlFor="organisation required">
              Fundraising goal
              <div
                className={`container-error pl-2 ${
                  errorFundraisingGoal ? "d-inline" : "d-none"
                }`}
              >
                <span className="error-red"> error </span>
                <span className="txt-error">This field is required</span>
              </div>
            </label>
            <Range
              className="targetInvestorrange"
              min={10}
              max={110}
              step={10}
              value={fundraisingGoal}
              defaultValue={fundraisingGoal}
              marks={{
                10:
                  (fundraisingGoalSlide[0] == 10
                    ? "$ 50 USD"
                    : "$ " + fundraisingGoalSlide[0]) + unitFundraisingGoal,

                20: "",
                30: "",
                40: "",
                50: "",
                60: "",
                70: "",
                80: "",
                90: "",
                100: "",
                110:
                  //Dev #47947, V2.1 [Investor] Ajouter le $ pour les champs "Annual revenue" et "Fundraising goal"
                  fundraisingGoalSlide[1] == 60
                    ? "> $ 50" + unitFundraisingGoalMax
                    : fundraisingGoalSlide[1] == 110
                    ? "> $ 50" + unitFundraisingGoalMax
                    : fundraisingGoalSlide[1] + unitFundraisingGoalMax,
              }}
              onChange={(event) => {
                setFundraisingGoal(event);
                customFundraisingGoalSlide(event[0], event[1]);
              }}
            />
          </div>
        </div>
        <div className="contentBorder border-top row align-items-stretch">
          <button
            className="btn btn-devinsider large"
            onClick={() => navigateToIndustrieTarget()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
