import React, { useState } from "react";

export default function UsePersmission() {
  const [showPermission, setshowPermission] = useState<boolean>(false);

  const handLePersmission = () => {
    setshowPermission(!showPermission);
  };

  return {
    showPermission,
    handLePersmission,
  };
}
