/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
import Modal from "react-bootstrap/Modal";
import { FormHeaderSolution } from "../../header/Headerform";
import { getSStorage } from "../../../../../../utils";
export default function CreateCompanyForm3(props: T.InputPropsForm3) {
  const [activeId, setActiveId] = useState("0");
  function toggleActive(id: any) {
    if (activeId == id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }
  const isHaveSelected = (data: any, solution: any): boolean => {
    return (
      data?.filter((el: any) => el.idSolutionOffert == solution.id).length > 0
    );
  };
  const renameOther = (sel: T.IdataMakeSolution) => {
    if (sel.nameSegment.toLowerCase() == "other") {
      const dataSource = JSON.parse(getSStorage("completed_account") || "[]");
      const category = dataSource?.segments?.find(
        (el: any) => el.id?.toString() === sel.idSolutionOffert?.toString()
      );
      return sel.nameSegment + "-" + category?.name;
    } else {
      return sel.nameSegment;
    }
  };

  // Dev #48487, [ISV] Company page - bug sur la séléction des "software solutions" qui sont affichées plusieurs fois dans la liste
  const newFilteredSolutionChecked = (solutions: any) => {
    let alreadyExist: Array<string> = [];
    return solutions?.filter((el: T.IdataMakeSolution) => {
      if (alreadyExist.indexOf(el.nameSegment) === -1) {
        alreadyExist.push(el.nameSegment);
        return el;
      }
    });
  };

  return (
    <Modal show={props?.showStep3} className="createCompanypopup large">
      <Modal.Header>
        <FormHeaderSolution />
        <a className="close back" onClick={props?.handleBackStep2}>
          &nbsp;
        </a>
        <a className="close" onClick={props?.handleCloseStep3}>
          <span className="icon-close-pop"></span>
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="bodyCreateCompany">
          <div className="contentBorder row">
            <div className="form-group m-0 required">
              <label htmlFor="">
                What solution(s) does your company offer?
                {props.errorData && (
                  <div className="container-error pl-2 d-inline">
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      select al least one category{" "}
                    </span>
                  </div>
                )}
              </label>
              <span className="selectalloverstyle">Select all that apply.</span>
            </div>
            <div className="solutionChecked">
              {props.solutionChecked &&
                newFilteredSolutionChecked(props.solutionChecked).map(
                  (el: T.IdataMakeSolution) => (
                    <div
                      className="item"
                      onClick={() => props.removeItemSelected(el)}
                      key={el.idSegment}
                    >
                      {renameOther(el)}
                      <span className="icon-close"></span>
                    </div>
                  )
                )}
            </div>
            <div className="contentCheckboxsolution">
              <h2 className="title">
                {props.featuredSolution && props.featuredSolution.name}{" "}
                {props.solutionChecked?.filter(
                  (el: any) => el.idSolutionOffert == 9999
                ).length > 0
                  ? `(${
                      props.solutionChecked?.filter(
                        (el: any) => el.idSolutionOffert == 9999
                      ).length
                    }  selected )`
                  : ""}
              </h2>
              <div className="flex-checkbox">
                {props.featuredSolution &&
                  props.featuredSolution.segmentDetails.map(
                    (el: T.ItemsSolution) => (
                      <Form.Check
                        key={el.id}
                        custom
                        label={el.name}
                        id={el.id.toString()}
                        checked={
                          props.solutionChecked?.find(
                            (t: any) => t.idSegment == el.id
                          )
                            ? true
                            : false
                        }
                        onClick={() => {
                          props.selectSolution({
                            idSolutionOffert: props?.featuredSolution?.id,
                            idSegment: el.id,
                            nameSegment: el.name,
                          });
                        }}
                        onChange={() => null}
                      />
                    )
                  )}
              </div>
              <div className="listAccordionSolution">
                <Accordion defaultActiveKey={activeId}>
                  <div className="row mx-0">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 pr-xl-2 pr-lg-2 p-0">
                      {props.solutionLeftColumnData &&
                        props.solutionLeftColumnData.map(
                          (solution: T.ISolutionListe) => {
                            return (
                              <div
                                className={
                                  activeId == solution.id.toString()
                                    ? "panel-wrap active-panel"
                                    : "panel-wrap"
                                }
                                key={solution.id}
                              >
                                <div className="panel-header">
                                  <Accordion.Toggle
                                    onClick={() => toggleActive(solution.id)}
                                    className={`panel-toggle ${
                                      isHaveSelected(
                                        props.solutionChecked,
                                        solution.id
                                      )
                                        ? "colored round"
                                        : ""
                                    }`}
                                    eventKey={solution.id.toString()}
                                  >
                                    {solution && solution.name}{" "}
                                    {props.solutionChecked?.filter(
                                      (el: any) =>
                                        el.idSolutionOffert == solution.id
                                    ).length > 0
                                      ? `(${
                                          props.solutionChecked?.filter(
                                            (el: any) =>
                                              el.idSolutionOffert == solution.id
                                          ).length
                                        }  selected )`
                                      : ""}
                                  </Accordion.Toggle>
                                </div>
                                <Accordion.Collapse
                                  eventKey={solution.id.toString()}
                                >
                                  <div className="panel-body">
                                    <div
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        props.selectSolution({
                                          idSolutionOffert: solution.id,
                                          solutionOffert:
                                            solution.segmentDetails,
                                        });
                                      }}
                                    >
                                      <Form.Check
                                        custom
                                        label="Select all"
                                        id="all"
                                        checked={
                                          props.allSolutionSellected &&
                                          props.allSolutionSellected?.find(
                                            (el: any) =>
                                              el == solution.id ||
                                              el.nameSegment === solution.name
                                          )
                                            ? true
                                            : false
                                        }
                                        disabled={true}
                                        onClick={() => null}
                                        onChange={() => null}
                                      />
                                    </div>
                                    {solution.segmentDetails &&
                                      solution.segmentDetails.map(
                                        (solutionItems: T.ItemsSolution) => {
                                          return (
                                            <>
                                              <Form.Check
                                                key={solutionItems.id}
                                                custom
                                                label={solutionItems.name}
                                                id={solutionItems.id.toString()}
                                                checked={
                                                  props.solutionChecked?.find(
                                                    (t: any) =>
                                                      t.idSegment ==
                                                        solutionItems.id ||
                                                      (t.nameSegment ===
                                                        solutionItems.name &&
                                                        t.nameSegment !==
                                                          "Other")
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                onClick={() => {
                                                  props.selectSolution({
                                                    idSolutionOffert:
                                                      solution.id,
                                                    idSegment: solutionItems.id,
                                                    nameSegment:
                                                      solutionItems.name,
                                                  });
                                                }}
                                                onChange={() => null}
                                              />
                                              {solutionItems.name?.toLocaleLowerCase() ===
                                                "other" &&
                                                props.solutionChecked?.find(
                                                  (t: any) =>
                                                    t.idSegment ==
                                                    solutionItems.id
                                                ) && (
                                                  <input
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    placeholder="other"
                                                    value={
                                                      props.allOtherValue?.find(
                                                        (tempTxt) =>
                                                          tempTxt.idSegment.toString() ===
                                                          solutionItems.id.toString()
                                                      )?.otherTxt || ""
                                                    }
                                                    onChange={(evt: any) =>
                                                      props.setOtherValueTxt(
                                                        {
                                                          idSolutionOffert:
                                                            solution.id,
                                                          idSegment:
                                                            solutionItems.id,
                                                          nameSegment:
                                                            solutionItems.name,
                                                        },

                                                        evt.target.value
                                                      )
                                                    }
                                                  />
                                                )}
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                </Accordion.Collapse>
                              </div>
                            );
                          }
                        )}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 pl-xl-2 pl-lg-2 p-0">
                      {props.solutionRigthColumnData &&
                        props.solutionRigthColumnData.map(
                          (solution: T.ISolutionListe) => {
                            return (
                              <div
                                className={
                                  activeId == solution.id.toString()
                                    ? "panel-wrap active-panel"
                                    : "panel-wrap"
                                }
                                key={solution.id}
                              >
                                <div className="panel-header">
                                  <Accordion.Toggle
                                    onClick={() =>
                                      toggleActive(solution.id.toString())
                                    }
                                    className={`panel-toggle ${
                                      props.solutionChecked?.filter(
                                        (el) =>
                                          el.idSolutionOffert == solution.id
                                      ).length > 0
                                        ? "colored round"
                                        : ""
                                    }`}
                                    eventKey={solution.id.toString()}
                                  >
                                    {solution && solution.name}{" "}
                                    {props.solutionChecked?.filter(
                                      (el: any) =>
                                        el.idSolutionOffert == solution.id
                                    ).length > 0
                                      ? `(${
                                          props.solutionChecked?.filter(
                                            (el: any) =>
                                              el.idSolutionOffert == solution.id
                                          ).length
                                        }  selected )`
                                      : ""}
                                  </Accordion.Toggle>
                                </div>
                                <Accordion.Collapse
                                  eventKey={solution.id.toString()}
                                >
                                  <div className="panel-body">
                                    <div
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        props.selectSolution({
                                          idSolutionOffert: solution.id,
                                          solutionOffert:
                                            solution.segmentDetails,
                                        });
                                      }}
                                    >
                                      <Form.Check
                                        custom
                                        label="Select all"
                                        id="all"
                                        checked={
                                          props.allSolutionSellected &&
                                          props.allSolutionSellected?.find(
                                            (el: any) => el == solution.id
                                          )
                                            ? true
                                            : false
                                        }
                                        disabled={true}
                                        onClick={() => null}
                                        onChange={() => null}
                                      />
                                    </div>

                                    {solution.segmentDetails &&
                                      solution.segmentDetails.map(
                                        (solutionItems: T.ItemsSolution) => {
                                          return (
                                            <>
                                              <Form.Check
                                                key={solutionItems.id}
                                                custom
                                                label={solutionItems.name}
                                                id={solutionItems.id.toString()}
                                                checked={
                                                  props.solutionChecked?.find(
                                                    (t: any) =>
                                                      t.idSegment ==
                                                        solutionItems.id ||
                                                      (t.nameSegment ===
                                                        solutionItems.name &&
                                                        t.nameSegment !==
                                                          "Other")
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                onClick={() => {
                                                  props.selectSolution({
                                                    idSolutionOffert:
                                                      solution.id,
                                                    idSegment: solutionItems.id,
                                                    nameSegment:
                                                      solutionItems.name,
                                                  });
                                                }}
                                                onChange={() => null}
                                              />
                                              {solutionItems.name?.toLocaleLowerCase() ===
                                                "other" &&
                                                props.solutionChecked?.find(
                                                  (t: any) =>
                                                    t.idSegment ==
                                                    solutionItems.id
                                                ) && (
                                                  <input
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    placeholder="other"
                                                    value={
                                                      props.allOtherValue?.find(
                                                        (tempTxt) =>
                                                          tempTxt.idSegment.toString() ===
                                                          solutionItems.id.toString()
                                                      )?.otherTxt || ""
                                                    }
                                                    onChange={(evt: any) =>
                                                      props.setOtherValueTxt(
                                                        {
                                                          idSolutionOffert:
                                                            solution.id,
                                                          idSegment:
                                                            solutionItems.id,
                                                          nameSegment:
                                                            solutionItems.name,
                                                        },

                                                        evt.target.value
                                                      )
                                                    }
                                                  />
                                                )}
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                </Accordion.Collapse>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder">
          <button
            className="btn btn-devinsider medium containerBtnBtn"
            onClick={props?.submitForm3}
          >
            Next
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
