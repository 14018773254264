import React, { useState, useEffect } from "react";
import { getSStorage } from "../../../utils";

interface ItemsPays {
  id: number;
  country_name: string;
}
interface IobJectResponse {
  id: number;
  name: string;
  countries: Array<ItemsPays>;
}
interface IdataMake {
  idContinent: string;
  countrId: string;
  countryName: string;
}

export default function UseCompleteContryInvestor(
  defaultSelected: Array<IdataMake>
) {
  const [allContinents, setallContinents] = useState<Array<IobJectResponse>>(
    []
  );
  const [paysChecked, setPaysChecked] =
    useState<Array<IdataMake>>(defaultSelected);

  const [allContinentSelected, setAllContinentSelected] = useState<
    Array<string>
  >([]);
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );

  const transFormnData = (data: any) => {
    let custom = data?.countryesData?.map((el: any) => {
      let temp = {
        idContinent: data.idContinent,
        countrId: el && el.id,
        countryName: el && el.country_name,
      };
      return temp;
    });
    return custom;
  };

  /**
   * @returns initialise default pays selected
   */
  useEffect(() => {
    setPaysChecked(defaultSelected);
    return () => {};
  }, [defaultSelected]);

  const addPaysInArray = (data: any) => {
    //DATA CUSTOM
    let dataMake: Array<IdataMake> = [];
    //FIND IF PARAM IS ARRAY OF DATA , for add multiple
    if (data && data.countryesData) {
      //find if bouton is selected all is clicked
      let cotinentItemIsSelected = allContinentSelected?.find(
        (el) => el == data.idContinent
      );
      //tranform data if array
      let dataAfterTransform = transFormnData(data);
      dataMake = [...dataAfterTransform];

      //if selected all is not clicked
      if (!cotinentItemIsSelected) {
        //remove if item is existe deja
        let dataToStore = paysChecked?.filter(
          (el: any) => el.idContinent != data.idContinent
        );

        //Performe data to store
        let transFormDataToStore = [...dataToStore, ...dataMake];
        //selecte all data
        setPaysChecked(transFormDataToStore);
        //active checked selected all is clicked
        setAllContinentSelected([...allContinentSelected, data.idContinent]);
      } else {
        //if all selected is clicked , remove all item same idContinent
        setPaysChecked(
          paysChecked?.filter((el: any) => el.idContinent != data.idContinent)
        );
        //Make selected all is not clicked
        setAllContinentSelected(
          allContinentSelected?.filter((el) => el != data.idContinent)
        );
      }
    } else {
      dataMake = [data];
      let found = paysChecked?.find((el: any) => el.countrId == data.countrId);
      if (!found) {
        setPaysChecked([...paysChecked, ...dataMake]);
      } else {
        setPaysChecked(
          paysChecked?.filter((elem: any) => elem.countrId != data.countrId)
        );
      }
    }
  };

  useEffect(() => {
    async function getContinent() {
      setallContinents(dataCompleted?.targetedCountry);
    }
    getContinent();
    return () => {};
  }, []);

  const backToDefault = () => {
    setPaysChecked(defaultSelected);
    //c
  };

  return {
    addPaysInArray,
    paysChecked,
    allContinents,
    allContinentSelected,
    backToDefault,
  };
}
