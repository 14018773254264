/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logoLettre from "../../../resources/images/logo-lettre.svg";
import useWelcomeCtr from "../../../controller/use-welcom-ctr";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { logout } from "../../../redux/actions/acounts-action";
import {
  checkIsCompletedInvestorCompanyMatching,
  checkIsCompletedOemCompanyMatching,
  fileUrl,
  isModerator,
} from "../../../utils";
import { LoginModal } from "../login/login";
import LoginDirect from "../login/login-direct";
import BlockNotif from "../../widget/block-notif/index";
import { BlockMessage } from "../bloc-message-notif/index";
import { FundingStage } from "../../../interface/compnay-page-investor";
import { Link } from "react-router-dom";

export default function Header(props: any) {
  const { getStarted, goLogin, showLogin, setShowLogin, started } =
    useWelcomeCtr();
  const userState = useSelector((state: rootState) => state.userReducer);
  const companyPageInfo = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const isConnected = userState?.enable ? true : false;
  const [show, setShow] = useState(false);
  //Toogle One

  const showProfil = () => {
    history.push("/profil");
  };

  const goToSettings = () => {
    history.push("/settings", { tabToShow: "d-account" });
  };

  const cb = (params: boolean) => {
    if (params) {
      history.push("/signin");
    }
  };
  const logOut = () => {
    dispatch(logout(cb));
  };

  const goToCompanyPage = () => {
    const isCompanyCompleted =
      userState?.isvInCompanyPage?.company_page?.completed;
    if (isCompanyCompleted) {
      history.push("/company/profil");
    } else {
      history.push("/company/create");
    }
  };

  const goToProgramMatchmaking = () => {
    const url = !isConnected
      ? "/landing-find-prog"
      : !checkIsCompletedOemCompanyMatching(companyPageInfo)
      ? "/program/matchmaking"
      : "/programs/program-matching";
    history.push(url);
  };

  function goToCommunityHome() {
    history.push("/community/home");
  }

  const goToFindInvestor = (stage: FundingStage = "all") => {
    if (!isConnected) {
      history.push("/landing-find-inv");
      return;
    }

    if (!checkIsCompletedInvestorCompanyMatching(companyPageInfo)) {
      history.push("/find-investors");
    } else {
      history.push("/investor/find-investor-wrapper", { stage });
    }
  };

  const goDashBoard = () => {
    history.push("/dashboard");
  };

  const showDiscussion = () => {
    history.push("/contact/discussion");
  };

  const urlPath = window.location.href;
  var valueofSubmenu: any;
  urlPath.indexOf("find-investor") !== -1
    ? (valueofSubmenu = "find-investor")
    : urlPath.indexOf("community") !== -1
    ? (valueofSubmenu = "community")
    : urlPath?.indexOf("classified-ads") !== -1
    ? (valueofSubmenu = "classified-ads")
    : urlPath?.indexOf("programs") !== -1
    ? (valueofSubmenu = "programs")
    : (valueofSubmenu = "neant");

  const goDashBoardOrWelcome = () => {
    const isConnected = userState?.token;
    if (isConnected) {
      history.push("/dashboard");
    } else {
      history.push("/");
    }
  };

  const isModeratorUser = isModerator(userState.roles);

  return (
    <div className="principalMenu">
      <div className="container mx-auto">
        <div className="leftItemHeader">
          <div className="logoHeader" onClick={() => goDashBoardOrWelcome()}>
            <img src={logoLettre} alt="" style={{ cursor: "pointer" }} />
          </div>
          <div className="menuDynamic tl">
            <div className="item">
              <Link
                // to={{
                //   pathname: `${
                //     userState?.token ? "/ma/home" : "/landing-find-inv"
                //   }`,
                // }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (userState?.token) {
                    return false;
                  }
                  history.push("/landing-find-inv");
                }}
                className="parent linkHeader borRight"
              >
                Corporate development
              </Link>

              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span>Corporate development</span>
                    Access a curated pool of investors that are looking for
                    software companies to invest in.
                  </div>
                </div>
                <div className="container mx-auto">
                  {!isModeratorUser && (
                    <a
                      href="javascript:;"
                      /* onClick={() => goToFindInvestor("early")} */
                      className="linkSubMenu linkHeader"
                    >
                      <span className="name">Early stage funding</span>
                      <span className="orangeLinks linkDisable">
                        coming soon!
                      </span>
                      <p>
                        Match with early stage software investors based on your
                        company profile and preferences.
                      </p>
                    </a>
                  )}
                  {!isModeratorUser && (
                    <a
                      href="javascript:;"
                      // onClick={() => goToFindInvestor("later")}
                      className="linkSubMenu linkHeader"
                    >
                      <span className="name">Later stage funding</span>
                      {/*Miaro ticket 46657*/}
                      <span className="orangeLinks">coming soon!</span>
                      <p>
                        Match with investors to cover funding needs beyond A
                        round.
                      </p>
                    </a>
                  )}
                  {/* <Link to={"/ma/home"} className="linkSubMenu linkHeader">
                    <span className="name">M&A</span>

                    <p>
                      Find exit opportunities among Devinsiderʼs global
                      community of software vendors and institutional investors.
                    </p>
                  </Link> */}
                  {/* <Link
                    to={"/alternative-financing/home"}
                    className="linkSubMenu linkHeader"
                  >
                    <span className="name">Alternative financing</span>

                    <p>
                      Fund your business leveraging alternative solutions to
                      standard equity investments and loans.
                    </p>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="item">
              <Link
                to="/community/home"
                className={`parent linkHeader borRight ${
                  valueofSubmenu === "community" ? "active" : ""
                }`}
              >
                Community
              </Link>

              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span
                      onClick={() => {
                        goToCommunityHome();
                      }}
                    >
                      Community
                    </span>
                    Join a global community for software publishers (ISVs).
                  </div>
                </div>
                <div className="container mx-auto">
                  <Link
                    to="/community/discussions-categories"
                    className="linkHeader"
                  >
                    <span className="name">Forum discussions</span>
                    <p>
                      Discuss the business challenges of your software company
                      with peers and industry experts.
                    </p>
                  </Link>

                  <Link
                    to="/community/article-category/0/0"
                    className="linkHeader"
                  >
                    <span className="name">Articles</span>
                    <p>
                      Share your expertise and insights to help software
                      publishers with high-value content.
                    </p>
                  </Link>

                  <Link to="/community/press-releases/0" className="linkHeader">
                    <span className="name">Press releases</span>
                    <p>
                      Create exposure to peers, potential investors and tech
                      media outlets.
                    </p>
                  </Link>

                  <Link to="/community/partner-program" className="linkHeader">
                    <span className="name">Program reviews</span>
                    <p>
                      Review technology partner programs that you have engaged
                      with.
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="item">
              <Link
                className={`parent linkHeader ${
                  valueofSubmenu === "events" ? "active" : ""
                }`}
                to={"#"}
                onClick={(e) => {
                  e.preventDefault();
                  //goToProgramMatchmaking();
                }}
              >
                Strategic partnerships
              </Link>
              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span>Strategic partnerships</span>
                    Find OEMs and Ecosytems that offer technology partner
                    programs for software publishers (ISVs).
                  </div>
                </div>
                <div className="container mx-auto">
                  <Link to="/programs/home" className="linkSubMenu linkHeader">
                    <span className="name">Directory</span>
                    <p>
                      Browse an extensive directory of partner programs for
                      software vendors (ISVs).
                    </p>
                  </Link>
                  <Link to="" className="linkSubMenu linkHeader">
                    <span className="name">Partner programs</span>
                    <span className="orangeLinks linkDisable">
                      coming soon!
                    </span>
                    <p>
                      Match with OEM and Ecosystem partner programs based on
                      your company profile and preferences.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            {/* {!isModeratorUser && (
              <div className="item">
                <Link to="/programs/home" className="linkHeader">
                  Marketplace
                </Link>

                <div className="submenu">
                  <div className="title">
                    <div className="container mx-auto">
                      <span>Marketplace</span>
                      Identify partnership opportunities with resellers,
                      investors and other professionals working in the software
                      industry.
                    </div>
                  </div>
                  <div className="container mx-auto">
                    <Link
                      to="/programs/home"
                      className="linkSubMenu linkHeader"
                    >
                      <span className="name">Directory</span>
                      <p>
                        Browse an extensive directory of partner programs for
                        software vendors (ISVs).
                      </p>
                    </Link>
                    <a
                      href="javascript:;"
                      onClick={() => goToProgramMatchmaking()}
                      className="linkSubMenu linkHeader "
                    >
                      <span className="name">Technology partner programs</span>
                      <p>
                        Match with OEM and Ecosystem partner programs based on
                        your company profile and preferences.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            )} */}

            {/* <div className="item">
              <Link
                className={`parent linkHeader ${
                  valueofSubmenu === "events" ? "active" : ""
                }`}
                to="/events/all-events"
              >
                Events
              </Link>
            </div> */}
          </div>
        </div>
        <div className="rightItemHeader">
          {userState && userState.enable ? (
            <div className="menuDynamic tr">
              <>
                <div className="item">
                  <a
                    href="javascript:;"
                    className="linkHeader"
                    onClick={(e: any) => {
                      e?.preventDefault();
                      goDashBoard();
                    }}
                  >
                    <span className="icon-home"></span>
                    <span className="mobile">Home</span>
                  </a>
                </div>
                <div className="item" onClick={() => showDiscussion()}>
                  <BlockMessage unread={props.unread} />
                </div>
                <BlockNotif />
              </>

              <div className="item">
                <a href="javascript:;" className="parent linkHeader">
                  <div className={`profilImg`}>
                    {userState.file_name && (
                      <img
                        alt="Profile"
                        src={`${fileUrl(userState.file_name)}`}
                      />
                    )}
                  </div>
                  <span className="mobile">My account</span>
                </a>
                <div className="submenu">
                  <a href="javascript:;" onClick={() => showProfil()}>
                    Profile
                  </a>
                  {!isModerator(userState.roles) && (
                    <>
                      <a href="javascript:;" onClick={() => goToCompanyPage()}>
                        Company page
                      </a>
                      <a href="javascript:;" onClick={() => goToSettings()}>
                        Account settings
                      </a>
                    </>
                  )}

                  <a href="javascript:;" onClick={() => logOut()}>
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="menuDynamic tr notConnected">
              <div className="item">
                <a
                  href="javascript:;"
                  className="signinHeader getstartedHeader"
                  onClick={() => goLogin()}
                >
                  Sign in
                </a>
              </div>
              <div className="item">
                <a
                  href="javascript:;"
                  className="getstartedHeader orange"
                  onClick={() => getStarted()}
                >
                  Get started
                </a>
              </div>
            </div>
          )}
        </div>
        <LoginModal
          urlType={urlPath.indexOf("community") !== -1 ? "community" : ""}
          show={showLogin}
          setShow={setShowLogin}
          goLogin={goLogin}
          getStarted={getStarted}
          started={started}
        />
        <LoginDirect show={show} setShow={setShow} />
      </div>
    </div>
  );
}
