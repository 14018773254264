import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, signinAction } from '../../redux/actions/acounts-action/index';
import useEventListener from '../../hooks/event-handler';
import { postWithoutTokenCombineUrl } from '../../service/api';
import {
  INVESTOR,
  url,
  OEM_PROGRAM,
  GUEST,
  MODERATOR,
} from '../../service/constant/index';
import { useDataContext } from '../../context/index';
import { getOemProfileProgram } from '../../redux/actions/oem-program-action';

export default function UseSigninCtr() {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [showPassword, setShowPasswpord] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();

  const getStarted = (): void => {
    history.push('/account-choices');
  };
  const { startEvtSource } = useDataContext();

  const [isClauseAccount, setIsClauseAccount] = useState(
    location?.state?.clauseAccount
  );

  let redirectUrl: any = location?.state?.redirectUrl || '/dashboard';

  const redirectInvestor = (params: string) => {
    if (params) {
      history?.push('/dashboard', { isSignin: true });
    } else {
      history?.push('/profil/investor');
    }
  };

  const callBack = async (response: any) => {
    let dataResponse = response?.data;
    if (response && response.status === 200) {
      if (dataResponse?.enable) {
        startEvtSource();
        const role = dataResponse?.roles[0];
        if (role === INVESTOR) {
          redirectInvestor(response?.investor_members?.investor_page?.status);
        } else if (role === OEM_PROGRAM) {
          const activeProgram = dataResponse?.oemProgramMembers?.find(
            (program: any) => program.oem_program?.uuid != ''
          );
          if (activeProgram) {
            dispatch(
              getOemProfileProgram(activeProgram.oem_program?.id, () =>
                history.push('/OEM/profil/' + activeProgram.oem_program?.id)
              )
            );
          } else {
            setError(true);
            setShowLoader(false);
          }
        } else if (role === GUEST) {
          history.push('/classified-ads/home');
        }
        //FOR MODERATOR USER
        else if (role === MODERATOR) {
          history.push('/community/home');
        } else {
          history.push(redirectUrl, { fromLogin: true });
        }
      } else {
        history.push('/complete-account', {
          sendMail: true,
          email,
        });
      }
    } else {
      setError(true);
      setShowLoader(false);
    }
  };

  //SIgnin user
  const signinUser = (): void => {
    setShowLoader(true);
    setError(false);
    dispatch(
      signinAction(
        { email: email?.trim(), password: password?.trim() },
        callBack
      )
    );
  };

  const keyEnter = ({ key }: any) => {
    if (key === 'Enter') {
      signinUser();
    } else return;
  };

  useEventListener('keydown', keyEnter);

  const forgotPassword = (): void => {
    history.push('./forgot-password');
  };

  const cb = (params: boolean) => {
    history.push('/signin');
  };

  useEffect(() => {
    dispatch(logout(cb));
    return () => {};
  }, []);

  return {
    showLoader,
    email,
    password,
    error,
    showPassword,
    setShowPasswpord,
    getStarted,
    signinUser,
    setEmail,
    setPassword,
    forgotPassword,
    isClauseAccount,
    setIsClauseAccount,
  };
}
