import moment from "moment";
import React, { ChangeEvent, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootState } from "../../../../redux/reducers";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";

type Iprops = {
  modalPR: boolean;
  closePRModal: () => void;
  chargingPlanDetails: boolean;
  statePR: {
    number_left: number;
    number_max: number;
    next_grant: string;
    spent_by: Array<{
      id: string;
      first_name: string;
      last_name: string;
      user_uri: string;
      at: string;
      number: string;
      role: string;
    }>;
  };
  handleChoise: (p: string) => void;
  choiseValue: string | undefined;
  publishPR: () => void;
  loading: boolean;
  amountPR: string;
};
export default function PublishPrModal(props: Iprops) {
  const companyPage = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const withoutCart =
    companyPage?.payment_method == null ||
    companyPage?.payment_method == undefined;

  const payWithoutCarte = withoutCart && props.choiseValue === "PAY";
  const payWithCarte = !withoutCart && props.choiseValue === "PAY";
  const history = useHistory();

  const showAddCart = () => {
    history.push("/settings", {
      tabToShow: "billing",
    });
  };
  return (
    <Modal
      show={props?.modalPR}
      className="createCompanypopup small forStep5custom"
      style={{ height: "100%" }}
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Publish press release</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={() => props?.closePRModal()}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="row forAds">
          {!props.chargingPlanDetails ? (
            <div className="border radius col-12 d-flex">
              <input
                type="radio"
                defaultChecked={props.statePR?.number_left > 0 ? true : false}
                disabled={props.statePR?.number_left < 1}
                name="choice"
                value="FREE"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  props.handleChoise(e.target.value)
                }
              />
              <h5>
                {props.statePR?.number_left} out of {props.statePR?.number_max}{" "}
                free press release
              </h5>
              {/* <label htmlFor="">
                Next grant: on{" "}
                {moment(new Date(props.statePR.next_grant)).format(
                  "MMM D, YYYY"
                )}
              </label> */}
            </div>
          ) : ("Loading...")
          }
          {/* {props.statePR.number_left < 1 && (
            <div className="border radius col-12 d-flex">
              <input
                type="radio"
                name="choice"
                value="PAY"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  props.handleChoise(e.target.value)
                }
              />
              <h5>Press release</h5>
              <h5>{props.amountPR !== "" && <>$ {props.amountPR} USD</>}</h5>
            </div>
          )}
          {payWithoutCarte && (
            <div className="payC">
              <p>You do not have a credit card registered.</p>
              <p onClick={() => showAddCart()}>
                To continue <a href="javascript:;">add Credit card</a>
              </p>
            </div>
          )}
          {payWithCarte && (
            <div className="payC">
              <label htmlFor="">
                By continuing, you registered card will be automatically charged
              </label>
            </div>
          )} */}
        </div>
      </Modal.Body>
      {props.choiseValue && !props.chargingPlanDetails && (
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => props.closePRModal()}
            >
              Cancel
            </button>
            
              <button
                className="btn btn-devinsider px-5 save"
                disabled={!payWithoutCarte ? false : true}
                onClick={() => props.publishPR()}
              >
                {props.loading ? <Loader /> : "Continue"}
              </button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
