/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import * as T from "../../../../../../../controller/company-ctr/types/types-company-page";
import defaultCompany from "../../../../../../../resources/images/defaultCompany.png";

export default function FormBecomeVerifiedSuccess(
  props: T.TBecomeVerifiedSuccess
) {
  return (
    <div>
      {/* Become a verified profesionnal */}
      <Modal
        show={props?.isShowSuccess}
        className="createCompanypopup smallPopup forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Thank you!</h2>
            <a href="javascript:;" className="close">
              <span
                className="icon-close-pop"
                onClick={() => props?.setIsShowSuccess()}
              ></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight forBecomeVerified">
          <div className="contentBorder">
            <div className="col-12 center">
              <div className="bottom">
                <span className="icon-check"></span>
              </div>
              <p>
                <b>Check your email for confirmation.</b>
              </p>
              <p>
                To continue the process of verifying your professional status,
                click the verification link we sent to your work email address:{" "}
                <br /> {props?.emailTxt}{" "}
              </p>
              <p>
                (Please check your spam or junk mail if you have not received it
                within the next 5 minutes.)
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {/* end Become a verified profesionnal*/}
    </div>
  );
}
