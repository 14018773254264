import React from "react";
import Form from "react-bootstrap/Form";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
import Modal from "react-bootstrap/Modal";
import { FormHeaderSolution } from "../../header/Headerform";

export default function CreateCompanyForm4(props: T.IndustryTargetProps) {
  return (
    <Modal show={props?.showStep4} className="createCompanypopup large">
      <Modal.Header>
        <FormHeaderSolution />
        <a className="close back" onClick={props?.handleBackStep3}>
          &nbsp;
        </a>
        <a className="close" onClick={props?.handleCloseStep4}>
          <span className="icon-close-pop"></span>
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="bodyCreateCompany">
          <div className="contentBorder">
            <div className="form-group">
              <label htmlFor="" className="pb-3 notBold">
                What industry do you target with your software solution(s)?
                Select all that apply.
                {props.errorData && (
                  <div className="container-error pl-2 d-inline">
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      select al least one category{" "}
                    </span>
                  </div>
                )}
              </label>

              <div className="contentTargetIndustry smallLabel">
                <div className="col4">
                  {props.companyTarget &&
                    props.companyTarget.map((element: T.IListTarget) => (
                      <Form.Check
                        key={element.id}
                        custom
                        checked={
                          props.companyTargetSelected?.find(
                            (ele: T.IListTarget) => ele.id == element.id
                          )
                            ? true
                            : false
                        }
                        label={element.name}
                        id={element.id.toString()}
                        onClick={() => {
                          props.addTargetCompany(element);
                        }}
                        onChange={() => {}}
                      />
                    ))}
                </div>
                <div className="col4">
                  {props.companyTargetSecound &&
                    props.companyTargetSecound.map((element: T.IListTarget) => (
                      <Form.Check
                        key={element.id}
                        custom
                        checked={
                          props.companyTargetSelected?.find(
                            (ele: T.IListTarget) => ele.id == element.id
                          )
                            ? true
                            : false
                        }
                        label={element.name}
                        id={element.id.toString()}
                        onClick={() => {
                          props.addTargetCompany(element);
                        }}
                        onChange={() => {}}
                      />
                    ))}
                </div>
                <div className="col4">
                  {props.lastCompanyTarget &&
                    props.lastCompanyTarget.map((element: T.IListTarget) => (
                      <Form.Check
                        key={element.id}
                        custom
                        checked={
                          props.companyTargetSelected?.find(
                            (ele: T.IListTarget) => ele.id == element.id
                          )
                            ? true
                            : false
                        }
                        label={element.name}
                        id={element.id.toString()}
                        onClick={() => {
                          props.addTargetCompany(element);
                        }}
                        onChange={() => {}}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder">
          <button
            className="btn btn-devinsider medium containerBtnBtn"
            onClick={props?.submitForm4}
          >
            Next
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
