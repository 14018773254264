import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import store from '../redux/store';
import { getDataDrupal, urlDrupal } from '../service/constant/index';
export type ItemSlider = {
  body: string;
  field_key: string;
  field_photo: string;
  title: string;
};

export type ItemPub = {
  field_key: string;
  field_photo: string;
  title: string;
};
export default function UseWelcomeCtr() {
  const history = useHistory();
  const [text, setText] = useState<Array<object>>([]);
  const [textBlue, setTextBlue] = useState<any>();
  const [customStory, setCustomStory] = useState<Array<ItemSlider>>([]);
  const [ItemPub, setItemPub] = useState<Array<ItemPub>>([]);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);

  // Dev #47259 Créer une bannière d'information concernant les Cookies
  const [understoodCoockies, setUnderstoodCoockies] = useState<boolean>(false);

  const url = window.location.href;

  const fromCommunity = url?.includes('community');
  const fromClassifiedAds = url?.includes('classified-ads');

  const goLogin = (): void => {
    console.log('goLogin goLogingoLogingoLogin');
    if (!showLogin && (fromCommunity || fromClassifiedAds)) {
      setShowLogin(true);
      return;
    }
    history.push('/signin');
  };

  const getStarted = (): void => {
    if (!showLogin && (fromCommunity || fromClassifiedAds)) {
      setShowLogin(true);
      setStarted(true);
      return;
    }
    history.push('/account-choices');
  };

  const joinTheConversation = (): void => {
    return history.push('/community/home');
  };

  const learnMorePartnerProgram = (): void => {
    return history.push('/landing-find-prog');
  };

  const learnMoreInvestor = (): void => {
    return history.push('/landing-find-inv');
  };

  const learnMoreReseller = (): void => {
    return history.push('/classified-ads/home');
  };
  const learnMoreFindInvestor = (): void => {
    return history.push('/get-started-find-investor');
  };

  useEffect(() => {
    if (!showLogin) setStarted(showLogin);
  }, [showLogin]);

  const backToHome = (): void => {
    history.push('/');
  };

  useEffect(() => {
    // Dev #47259 Créer une bannière d'information concernant les Cookies
    setUnderstoodCoockies(sessionStorage.getItem('cbasics') === 'true');
    async function getData() {
      let result: any = await getDataDrupal(urlDrupal.accueil);
      if (result && result.status === 200) {
        setText(result.data);
      }
      return result;
    }
    getData();
    return () => {};
  }, []);

  useEffect(() => {
    async function getData() {
      let result = await getDataDrupal(urlDrupal.blog);
      if (result && result.data) {
        setTextBlue(result.data[0]);
      }
    }
    getData();
    return () => {};
  }, []);

  useEffect(() => {
    async function getData() {
      let result = await getDataDrupal(urlDrupal.slider);
      if (result && result.data) {
        setCustomStory(result.data);
      }
    }
    getData();
    return () => {};
  }, []);

  useEffect(() => {
    async function getData() {
      let result = await getDataDrupal(urlDrupal.getPub);
      if (result && result.data) {
        setItemPub(result.data);
      }
    }
    getData();
    return () => {};
  }, []);

  return {
    text,
    textBlue,
    customStory,
    ItemPub,
    goLogin,
    getStarted,
    joinTheConversation,
    learnMorePartnerProgram,
    learnMoreInvestor,
    learnMoreReseller,
    learnMoreFindInvestor,
    backToHome,
    showLogin,
    setShowLogin,
    started,
    understoodCoockies,
    setUnderstoodCoockies,
  };
}
