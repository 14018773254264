import React, { useEffect, useState } from 'react';
import { getAllLatestPost } from '../../redux/actions/community-action';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../redux/reducers';
import { useHistory } from 'react-router-dom';
import { getData } from '../../service/api';
import { url } from '../../service/constant';

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const [inputSearch, setInputSearch] = useState<string>('');
  const [loaderLatest, setLoaderLatest] = useState(false);
  const [defaultLatest, setDefaultLatest] = useState(
    communityReducer?.postType.length > 0
      ? communityReducer?.postType?.[0].id
      : 0
  );
  const [featuredLabels, setFeaturedLabels] = useState<any>([]);
  const [discussionCategory, setDiscussionCategory] = useState<any>([]);

  const cbGetLatest = () => {
    setLoaderLatest(false);
  };

  const dispachLatest = (data: any) => {
    dispatch(getAllLatestPost(data, cbGetLatest));
  };

  //loading community home page
  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      const response = await getData(url.community.get_label, '');
      if (response?.data?.featured_label) {
        setFeaturedLabels(response.data.featured_label);
      }
      if (response?.data?.discussion_categories) {
        setDiscussionCategory(response?.data?.discussion_categories);
      }
    })();
  }, []);

  useEffect(() => {
    setLoaderLatest(true);
    const postType = communityReducer?.postType.find(
      (el) => el.id.toString() === defaultLatest.toString()
    );
    let params = {
      //Dev #48422,Remove post par le moderator
      post_type: postType?.type,
      itemsPerPage: 5,
      isDeleted: 0,
    };
    dispachLatest(params);
    return () => {};
  }, [defaultLatest]);

  const showResult = () => {
    history.push('/community/result', {
      inputText: inputSearch,
    });
  };

  const goToreplayPost = (currentPost: any) => {
    history.push(
      '/community/reply-post/' +
        currentPost?.post_type?.id +
        '/' +
        currentPost?.id
    );
  };

  const goActivity = (id: string) => {
    history.push('/community/activity-user/' + id);
  };

  const showAllListe = (type: any) => {
    history.push('/community/view-all-posts/' + type);
  };

  const navigateToCategorie = (params: any) => {
    const categoryId = params?.id || params?.category_id;
    const label = isNaN(params?.label_id) ? '/0' : '/' + params?.label_id;
    const url = `/community/one-category/${categoryId + label}${
      params?.isInFeatured ? '?article=1' : ''
    }`;
    history.push(url);
  };

  const goToLabelCategory = (items: any, tagsId: string) => {
    const postType = items?.post_type?.id;

    if (postType?.toString() === '1' || postType?.toString() === '2') {
      const labels = items.labels?.find(
        (el: any) => el?.id?.toString() === tagsId
      );

      //Is Discussion
      postType?.toString() === '1' &&
        history.push(
          '/community/one-category/' + labels?.category?.id + '/' + tagsId
        );

      //Is article
      postType?.toString() === '2' &&
        history.push(
          '/community/article-category/' + labels?.category?.id + '/' + tagsId
        );
    }
    if (postType?.toString() === '3') {
      history.push('/community/press-releases/' + tagsId);
    }
  };

  return {
    communityReducer,
    selectedCategorie: defaultLatest,
    setDefaultLatest,
    navigateToCategorie,
    loaderLatest,
    inputSearch,
    setInputSearch,
    showResult,
    goToreplayPost,
    goActivity,
    showAllListe,
    featuredLabels,
    discussionCategory,
    goToLabelCategory,
  };
}
