import React from "react";
import Tab from "react-bootstrap/Tab";
import receipts from "../../../../resources/images/receipts.png";
import Receipt from "./use-receipt";
import Pagination from "../../../widget/use-pagination/pagination";
import { LoadingFavoriteProgram } from "../../../widget/loader/index";
import { IPropsReceipt } from "./transaction-type";
import moment from "moment";
type Iprops = {
  tabType: string;
};
export default function TabPanReceipts(props: Iprops) {
  const providerReceipt = Receipt({ tabType: props?.tabType });

  return (
    <Tab.Pane eventKey="receipts_list">
      <div className="contentBorder radius p-0">
        <div className="border-bottom">
          <div className="row">
            <div className="col-xl-1 col-lg-1 col-md-12 col-12 p-4 info">
              <div className="title"></div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
              <div className="title">Date</div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
              <div className="title">Descriptions</div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-12 col-12 p-4 info">
              <div className="title">Status</div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
              <div className="title">Amount</div>
            </div>
          </div>
        </div>
        {providerReceipt.loaderReceipt
          ? LoadingFavoriteProgram()
          : providerReceipt.receipt?.items?.map((el: IPropsReceipt) => (
              <div className="row">
                <div className="col-xl-1 col-lg-1 col-md-12 col-12 p-4 info">
                  <div className="img_receipts">
                    {el.pdf_file_url !== "false" && el.pdf_file_url !== "" && (
                      <a target={"_blank"} href={el.pdf_file_url}>
                        <img
                          src={receipts}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                  <div className="">
                    {moment(el.write_date).format("MMM DD,Y")}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
                  <div className="">Invoice {el.month_create}</div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-12 col-12 p-4 info">
                  <div className="">{el.status}</div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                  <div className="">{`$${el.amount}.00USD`}</div>
                </div>
              </div>
            ))}

        <div className="pagginationCategory">
          <Pagination
            className="pagination"
            currentPage={providerReceipt.receipt?.meta?.pages}
            totalCount={providerReceipt.receipt?.meta.total_count}
            pageSize={10}
            onPageChange={(page: any) =>
              providerReceipt?.handleClickReceipt(page)
            }
          />
        </div>
      </div>
    </Tab.Pane>
  );
}
