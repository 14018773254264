/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from 'react';

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import './style-signin.css';
import LogoRound from '../../../../resources/images/logo-round.svg';
import UseSigninCtr from '../../../../controller/signin-ctr/use-signin-ctr';
import { BtnNext } from '../../../widget/bouton/btn-next';
import Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router-dom';
export default function Signin() {
  const history = useHistory();
  const {
    error,
    showLoader,
    email,
    password,
    showPassword,
    getStarted,
    signinUser,
    setEmail,
    setPassword,
    setShowPasswpord,
    forgotPassword,
    isClauseAccount,
    setIsClauseAccount,
  } = UseSigninCtr();

  return (
    <div>
      <div className='loginScreen'>
        <div className='loginContent'>
          {/* Dev #45160 ajout url home page logo login */}
          <img
            src={LogoRound}
            alt=''
            style={{ cursor: 'pointer' }}
            onClick={() => history.push('/')}
          />
          <h1 className='title'>Welcome back</h1>
          <form action='' className='loginForm'>
            {error && <Alert variant='danger'>Bad credentials</Alert>}
            {isClauseAccount && (
              <Alert variant='success'>Your account has been closed</Alert>
            )}
            <div className='form-group'>
              <input
                type='text'
                placeholder='Email address'
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  setEmail(event.target.value)
                }
                onFocus={() => isClauseAccount && setIsClauseAccount(false)}
              />
            </div>
            <div className='form-group passwordContent'>
              <input
                type={showPassword ? 'text' : 'password'}
                name=''
                id=''
                placeholder='Password'
                className='input password'
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  setPassword(event.target.value)
                }
                onFocus={() => isClauseAccount && setIsClauseAccount(false)}
              />
              <span
                className='showPassWord'
                onClick={() => setShowPasswpord(!showPassword)}
              >
                Show
              </span>
            </div>
            <div className='rememberMe'>
              {/* <div className=" form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                    handleChange(e)
                  }
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Remember me
                </label>
              </div> */}
              <a onClick={() => forgotPassword()}>Forgot password?</a>
            </div>
            <div className='loginbtncontent'>
              <div
                className='btn btn-devinsider large containerBtnBtn'
                onClick={() => signinUser()}
              >
                <BtnNext
                  text={'Log in'}
                  click={(): void => {}}
                  showLoader={showLoader}
                />
              </div>
            </div>

            <div className='haveAccount'>
              Don’t have a Devinsider account?&nbsp;
              <a onClick={() => getStarted()}>Get started</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
