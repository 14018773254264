import HomePageClassifiedAds from '../screen/container/classified-ads/home-page/home-page';
import CategoryClassifiedAds from './../screen/container/classified-ads/category';
import FavoriteClassifiedAds from './../screen/container/classified-ads/favorite';
import ListingClassifiedAds from './../screen/container/classified-ads/listing';
import DetailsClassifiedAds from './../screen/container/classified-ads/details-classified';
import PostClassifiedAds from './../screen/container/classified-ads/post-classified-ad';
import ClassifiedAdsResult from '../screen/container/classified-ads/results';
import { GUEST, INVESTOR, ISV, RESELLER } from '../service/constant';
export const routesClassifiedAds = [
  {
    component: HomePageClassifiedAds,
    exact: true,
    path: '/classified-ads/home',
    guard: 'public',
  },
  {
    component: CategoryClassifiedAds,
    exact: true,
    path: '/classified-ads/category/:id',
    guard: 'public',
  },
  {
    component: FavoriteClassifiedAds,
    exact: true,
    path: '/classified-ads/favorite',
    guard: 'private',
  },
  {
    component: ListingClassifiedAds,
    exact: true,
    path: '/classified-ads/listing',
    guard: 'private',
  },
  {
    component: DetailsClassifiedAds,
    exact: true,
    path: '/classified-ads/details/:id',
    guard: 'public',
  },
  {
    component: PostClassifiedAds,
    exact: true,
    path: '/classified-ads/post',
    guard: 'private',
    allowedRole: [ISV, GUEST, INVESTOR, RESELLER],
  },
  {
    component: ClassifiedAdsResult,
    exact: true,
    path: '/classified-ads/results',
    guard: 'public',
  },
];
