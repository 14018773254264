import ModalEditAnnualTurnover from "../../../company/profil/financial/modal/modal-edit-annual-turnover";
import * as T from "../../../../../controller/company-ctr/types/types-company-page";

export default function ModaAnnualTurnover(props: T.IpropsModalEditAnnual) {
  return (
    <ModalEditAnnualTurnover
      annualTurnoverData={props.annualTurnoverData}
      isShowFinancialAnnual={props.isShowFinancialAnnual}
      setIsShowFinancialAnnual={(params: boolean) =>
        props.setIsShowFinancialAnnual(params)
      }
      annualTurnOverSelected={props.annualTurnOverSelected}
      setAnnulTurnover={(params: string) => props.setAnnulTurnover(params)}
      updateAnnualTurnOver={() => props.updateAnnualTurnOver()}
      loaderFinancial={props.loaderFinancial}
    />
  );
}
