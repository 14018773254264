import ProgramDirectoryCtr from "../../../controller/program-ctr/program-directory-ctr/use-program-directory";
import { IcompanyPage } from "../../../interface/company-page-isv";
import {
  getDataService,
  postJsonDataService,
} from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import { isRequired } from "../../../utils";
import { companyPageReducer } from "../../reducers/company-page-isv-reducer/current_investor_reducer";
import store from "../../store";
import { Dispatch, IProgram } from "../../types/find-program-interface";

export const constante = {
  GET_FIND_PROGRAMS_MENU: "GET_FIND_PROGRAMS_MENU",
  GET_DIRECTORY_PROGRAMS: "GET_DIRETORY_PROGRAMS",
  SHOW_MORE: "SHOW_MORE",
  GET_FILTERED_EVENT: "GET_FILTERED_EVENT",
  GET_EVENTS: "GET_EVENTS",
};

export const getFindProgramMenu = (callback: (data: any) => void) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await getDataService(
        url?.programs?.left_menu?.get_program,
        {},
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.GET_FIND_PROGRAMS_MENU,
          payload: response?.data,
        });
        callback(response);
      }
      callback(response);
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const getDirectoryPrograms = (
  params: {
    limit: number;
    page: number;
    oem_program_type: string;
    hq_location: string;
    solutions: string;
    search?: string;
  },
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.programs?.directory_program,
        params,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.GET_DIRECTORY_PROGRAMS,
          payload: response?.data,
        });
        callback(response);
      }
      callback(response);
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const showMoreInfo = (
  params: {
    id: number;
    solutions: string;
    program: IProgram;
  },
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.programs?.directory_program_more,
        {
          id: params?.id,
          solutions: params?.solutions,
        },
        store && store?.getState().userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.SHOW_MORE,
          payload: {
            data: response?.data,
            program: params?.program,
          },
        });
        callback(response);
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const getFilteredEvent = (callback: (data: any) => void) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await getDataService(
        url?.programs?.left_menu?.get_event,
        {},
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.GET_FILTERED_EVENT,
          payload: response?.data,
        });
        callback(response);
      }
      callback(response);
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const getEvents = (
  params: {
    limit: number;
    page: number;
    tags: string;
    event_type: string;
  },
  idEvent: number,
  callback: (data: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response: any;
      if (idEvent?.toString() !== "0") {
        response = await getDataService(
          url.programs.get_event + idEvent,
          {},
          store && store?.getState()?.userReducer?.token
        );
      } else {
        response = await postJsonDataService(
          url?.programs?.program_events,
          params,
          store && store?.getState()?.userReducer?.token
        );
      }

      if (response?.status === 200) {
        const payload =
          idEvent?.toString() !== "0"
            ? {
                results: [response?.data],
                total: 1,
              }
            : response?.data;

        dispatch({
          type: constante?.GET_EVENTS,
          payload: payload,
        });
        callback(response);
      }
      callback(response);
    } catch (error: any) {
      callback(error?.response);
    }
  };
};
