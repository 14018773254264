import React, { useEffect, useState } from "react";
import { postUserActivity } from "../../../service/applicatif/acount";
import store from "../../../redux/store";
import { url } from "../../../service/constant";
import { PostUserFilter } from "../../../interface/community";
import { useHistory } from "react-router-dom";

export default function PostsByUser(userId: number) {
  const [postsByCurrentUser, setPostsByCurrentUser] = useState<any>([]);
  const [postUserFilter, setPostUserFilter] = useState<PostUserFilter>({
    postTypes: "",
    limit: "10",
    page: "1",
    sortBy: "post_date",
    orderBy: "DESC",
  });
  const [loadingPost, setLoadingPost] = useState<boolean>(false);
  const history = useHistory();
  const handleChangePostType = (e: any) => {
    const postType = e.value;
    const params = {
      ...postUserFilter,
      page: "1",
      postTypes: postType,
    };
    getPostByUser(params);
    setPostUserFilter(params);
  };

  const handleChangeShow = (e: any) => {
    const show = e.value;
    const params = {
      ...postUserFilter,
      page: "1",
      limit: show,
    };
    getPostByUser(params);
    setPostUserFilter(params);
  };

  const handleChangeSorted = (e: any) => {
    const sorted = e.value;

    const params = {
      ...postUserFilter,
      page: "1",
      sortBy: sorted,
    };
    getPostByUser(params);
    setPostUserFilter(params);
  };

  const getPostByUser = async (params: any) => {
    setLoadingPost(true);
    const response = await postUserActivity(
      url.community.activity.get_all_post_by_user_in_activity,
      params,
      store.getState().userReducer.token
    );
    if (response?.status === 200) {
      setPostsByCurrentUser(response?.data);
    }
    setLoadingPost(false);
  };

  useEffect(() => {
    const params = { ...postUserFilter, idUser: userId };
    setPostUserFilter(params);
    getPostByUser(params);
  }, [userId]);

  const goToreplayPost = (postId: number, idCategorie: number) => {
    const url = "/community/reply-post/" + idCategorie + "/" + postId;
    history.push(url);
  };

  return {
    postsByCurrentUser,
    postUserFilter,
    setPostUserFilter,
    loadingPost,
    handleChangePostType,
    handleChangeShow,
    handleChangeSorted,
    goToreplayPost,
  };
}
