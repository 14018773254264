/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as Loader } from '../../../../../../resources/images/Rolling.svg';
import * as T from '../../../../../../controller/company-ctr/types/types-company-page';

export default function ModalEditPitch(props: T.IpropsEditPitch) {
  return (
    <div>
      {/* Popup Devinsider pitch */}
      <Modal
        show={props.isShowPitch}
        className='createCompanypopup large forStep5custom'
      >
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Devinsider pitch</h2>
            <a className='close' onClick={() => props.setIsShowPitch(false)}>
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <div className='contentBorder row forPitch'>
            <div className='form-group col-12'>
              This pitch is the first thing investors look at when viewing your
              page and makes you stand out among other software vendors.
              Describe what makes your company special in less than 600
              characters. (certifications, patents, technology, team, etc...)
            </div>
            <div className='form-group col-12 descCompany m-0'>
              <textarea
                maxLength={600}
                value={props.valueOfPitchForm}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  props.setValueOfPitchForm(event.target.value)
                }
              />
              <span className='caractercount'>
                {0 + (props.valueOfPitchForm?.length || 0) + ' / 600'}{' '}
              </span>
            </div>

            {/*Miaro ticket 47331 <label htmlFor="chk-link-2" className="link">
              Need help to write your pitch?
            </label>
            <input type="checkbox" id="chk-link-2" className="chk-link" />
            <div className="content add">
              <p>
                Devinsider can help you craft a pitch that appeals to software
                investors. <br />
                By cliking this button, a Devinsider specialist will reach out
                to you by email
              </p>
              <button type="submit" className="btn btn-devinsider px-5">
                Request help
              </button>
              </div>*/}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='contentBorder formForEmail text-right forPitch'>
            <button
              className='btn btn-devinsider cancel'
              onClick={() => props.setIsShowPitch(false)}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-devinsider px-5'
              onClick={() => props.updatePicth('pitch')}
            >
              {props.loader ? <Loader /> : 'Save'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
