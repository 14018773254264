import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { rootState } from "../../../redux/reducers";
import { getDataWithoutToken } from "../../../service/api";
import { getDataService } from "../../../service/applicatif/back-end-service";
import {
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  url,
} from "../../../service/constant";
import { getMonth } from "../../../utils";
export default function UseManageCompanyAndBilling() {
  //Payement detail
  const now = new Date();

  const [showBillingCenter, setShowBillingCenter] = useState<boolean>(false);
  const [tabNavigate, setTabNavigate] = useState<string>("role");
  const [tabNavHistory, setTabNavHistory] = useState<string>("history_list");
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);

  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );

  const [showMonthlyBudget, setShowMonthlyBudget] = useState<boolean>(false);

  const [companyName, setCompanyName] = useState<string>("");

  const userObject = useSelector((state: rootState) => state.userReducer);

  const [planDetails, setPlanDetails] = useState<any>(null);

  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<string>("");

  const companyIsv = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const investorPageObject = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );

  const oemProgramObject = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const resellerPage = useSelector(
    (state: rootState) => state.resellerCompanyReducer
  );

  //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
  const [showModalPermissionOem, setShowModalPermissionOem] =
    useState<boolean>(false);

  const role = userObject.roles;

  const history: any = useHistory();
  //get information card from payment method
  const getCardInformationByPaymentMethod = async (paymentMethod: string) => {
    const response: any = await getDataService(
      url.settings.billingCenter +
        "/payment/get_payment_method_information/" +
        paymentMethod,
      {},
      userObject.token
    );
    return response.data;
  };

  const getPlan = async (isOem?: string, mountSelected?: string) => {
    const response = await getDataWithoutToken(
      url.settings.billingCenter + "/information/plan_details",
      { month: mountSelected || currentMonth, oem: isOem },
      userObject.token
    );

    return response;
  };

  useEffect(() => {
    async function getPlanDetails() {
      let oem = oemProgramObject?.id;
      setChargingPlanDetails(true);
      const response: any = await getPlan(oem);
      if (response?.status === 200) {
        setPlanDetails(response?.data);
      }
      setChargingPlanDetails(false);
    }
    if (showBillingCenter) {
      getPlanDetails();
    }
  }, [showBillingCenter]);

  useEffect(() => {
    const userType = userObject.roles?.filter((el) => el !== "USER_ROLE")?.[0];
    if (userType === ISV) {
      setCompanyName(companyIsv.company_name);
      setCurrentPaymentMethod(companyIsv.payment_method);
      return;
    }
    if (userType === OEM_PROGRAM) {
      setCompanyName(oemProgramObject.program_name);
      setCurrentPaymentMethod(oemProgramObject.payment_method);
      return;
    }

    if (userType === INVESTOR) {
      setCompanyName(investorPageObject.organization_name);
      setCurrentPaymentMethod(investorPageObject.payment_method);
      return;
    }

    if (userType === RESELLER) {
      setCompanyName(resellerPage.company_trade_name);
      setCurrentPaymentMethod(resellerPage.payment_method);
      return;
    }
  }, [showBillingCenter]);

  const closeBillingCenter = (isCloseClick?: boolean) => {
    if (isCloseClick) {
      setShowBillingCenter(false);
      return;
    } else {
      setShowBillingCenter(false);
      const route = history?.location?.pathname;
      if (route !== "/settings") {
        history.push("/settings", {
          tabToShow: "billing",
        });
      }
    }
  };

  const showPlanBilling = () => {
    setShowBillingCenter(true);
    tabNavigate !== "plan" && setTabNavigate("plan");
  };

  const showTransactionBilling = () => {
    setShowBillingCenter(true);
    tabNavigate !== "transaction" && setTabNavigate("transaction");
    tabNavHistory !== "history_list" && setTabNavHistory("history_list");
  };

  const showReceiptBilling = () => {
    setShowBillingCenter(true);
    tabNavigate !== "transaction" && setTabNavigate("transaction");
    tabNavHistory !== "receipts_list" && setTabNavHistory("receipts_list");
  };

  const showManageRole = () => {
    setShowBillingCenter(true);
    tabNavigate !== "role" && setTabNavigate("role");
  };

  return {
    investorPageObject,
    oemProgramObject,
    showBillingCenter,
    setShowBillingCenter,
    chargingPlanDetails,
    planDetails,
    role,
    setCurrentMonth,
    companyName,
    tabNavigate,
    setTabNavigate,
    tabNavHistory,
    setTabNavHistory,
    showMonthlyBudget,
    setShowMonthlyBudget,
    getCardInformationByPaymentMethod,
    closeBillingCenter,
    showPlanBilling,
    showTransactionBilling,
    showReceiptBilling,
    showManageRole,
    currentPaymentMethod,
    //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
    showModalPermissionOem,
    setShowModalPermissionOem,
    roleUserIncurrentProgram: (() => {
      if (userObject?.roles?.indexOf(OEM_PROGRAM) !== -1) {
        let roleInCurrentProgram = userObject?.oemProgramMembers?.filter(
          (role: any) => role?.oem_program?.id === oemProgramObject.id
        )[0];
        return roleInCurrentProgram?.role;
      }
      return "";
    })(),
  };
}
