import React, { useEffect, useState } from "react";
import useGoBack from "../../../hooks/use-navigate";
import { useLocation } from "react-router";

export default function StepperOem(props: any) {
  const { step } = props;
  const [allDone, setAllDone] = useState<Array<any>>([]);
  useEffect(() => {
    const allStep = [];
    for (let i = 1; i <= step; i++) {
      allStep.push(i);
    }
    setAllDone(allStep);
  }, [step]);

  const { goBack } = useGoBack();

  return (
    <div className="head-getstarted">
      {step > 1 && (
        <a className="link-back" onClick={goBack}>
          <span className="icon-back"></span>
        </a>
      )}
      <h1>Create your program page</h1>
      <ul className="stepInvestor">
        <li
          className={`stepInprogress ${allDone.includes(1) && "done"} ${
            step === 1 && "here"
          }`}
        >
          <div className="cttSpan">
            <span
              className={!allDone.includes(2) ? "icon-inprogress" : "icon-done"}
            />
          </div>
          User profile
        </li>
        <li
          className={`stepInprogress ${allDone.includes(2) && "done"} ${
            step === 2 && "here"
          }`}
        >
          <div className="cttSpan">
            <span
              className={!allDone.includes(3) ? "icon-inprogress" : "icon-done"}
            />
          </div>
          Program details
        </li>
        <li
          className={`stepInprogress ${allDone.includes(3) && "done"} ${
            step === 3 && "here"
          }`}
        >
          <div className="cttSpan">
            <span
              className={!allDone.includes(4) ? "icon-inprogress" : "icon-done"}
            />
          </div>
          ISV target
        </li>
      </ul>
    </div>
  );
}
