/* eslint-disable jsx-a11y/anchor-is-valid */
//Dev #48042,V2.1 [Investor] Preview Mode of an ISV company profile
import React, { useCallback, useState, ChangeEvent } from 'react';
import './strategy.scss';
import Modal from 'react-bootstrap/Modal';
import ModelLicensing from './modal/modal-edit-licensing-and-distribution';
import ModalGeographieTarget from './modal/modal-edit-geographie-target';
import ModalTechnologieParteners from './modal/modal-edit-technologie-parteners';

import * as T from '../../../../../controller/company-ctr/types/types-company-page';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import UseCompanyCtr from '../../../../../controller/company-ctr/create-company/use-company-ctr';
import { checkIfExist, fileUrl } from '../../../../../utils';
import { useParams } from 'react-router-dom';
import ModalEditProductDevelopment from './modal/modal-edit-product-development';
import UseProductDevelopment from '../../../../../controller/company-ctr/profil/block-profil-company-controller/use-product-development';
import ModalEditExpressionStrategy from './modal/modal-edit-strategy';
import UseExpressionStrategy from '../../../../../controller/company-ctr/profil/block-profil-company-controller/use-expression-strategy-ctr';
import { LOCKED_DATA } from '../../../../../service/constant';

export default function Strategy(props: T.IpropsStrategieEdit) {
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;
  const { isShowErrorOffice } = UseCompanyCtr();
  const [activeId, setActiveId] = useState<any>('');
  const [expendAll, setExpendAll] = useState<boolean>(false);
  const providerProductDevelopment = UseProductDevelopment();
  const providerUseExpressionStrategy = UseExpressionStrategy();

  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId('null');
    } else {
      setActiveId(id);
    }
  }

  const segmentInterestText = JSON?.parse(
    props.oemProgramSegmentInterestText || '[]'
  );

  const TechnologyInPlace = () => (
    <div className='partnership'>
      <div className='bord'>
        <h5 className='title'>Technology partnerships in place</h5>
        <a
          className='linkEditLeftCompany'
          onClick={() => props.setisShowPartenerShipModal(true)}
        >
          Edit<span className='icon-edit-full'></span>
        </a>
        <ul>
          {props.selectedStategieInPlace &&
            props.selectedStategieInPlace.map(
              (
                el: { id: number; name: string; logo: string },
                index: number
              ) => (
                <li key={index}>
                  <img src={fileUrl(el.logo)} alt='' />
                  <label htmlFor=''>{el?.name}</label>
                </li>
              )
            )}
        </ul>
      </div>
    </div>
  );
  const TechnologyInterests = () => (
    <div className='partner bord col-sm-6'>
      <h5 className='title'>Technology partnership interests</h5>
      <a
        className='linkEditLeftCompany'
        onClick={() => props.setisShowPartenerShipModal(true)}
      >
        Edit<span className='icon-edit-full'></span>
      </a>
      <ul>
        {segmentInterestText
          ?.filter((el: any, index: number) => index < props.index3)
          .map((el: { id: number; name: string }, index: number) => (
            <div className='liste' key={index}>
              {el.name}
            </div>
          ))}
      </ul>

      {segmentInterestText?.length > 6 && (
        <li className='link'>
          <a
            onClick={(evt) => {
              evt.preventDefault();
              props.setIndex3(
                props.index3 === segmentInterestText?.length
                  ? 6
                  : segmentInterestText?.length
              );
            }}
          >
            See {props.index3 === segmentInterestText?.length ? 'less' : 'more'}
            (
            {props.index3 === segmentInterestText?.length
              ? 6
              : segmentInterestText?.length - props.index3}
            )
          </a>
        </li>
      )}
    </div>
  );

  const GeographieTarger = () => (
    <div className='target bord col-sm-6'>
      <h5 className='title'>Geographic target Market</h5>
      <a
        className='linkEditLeftCompany'
        onClick={() => props.setshowStrategyGeographic(true)}
      >
        Edit<span className='icon-edit-full'></span>
      </a>
      {props.loader && <Loader />}

      <div className='geographicList'>
        <Accordion defaultActiveKey={activeId}>
          {props.geographieTarget &&
            props.geographieTarget?.map((items: any, index: number) => {
              return (
                <div
                  className={
                    activeId === index
                      ? 'panel-wrap active-panel'
                      : 'panel-wrap'
                  }
                  key={index}
                >
                  <div className='panel-header'>
                    <Accordion.Toggle
                      onClick={() => toggleActive(index)}
                      className={'panel-toggle'}
                      eventKey={index.toString()}
                    >
                      {items && items[0].nameContinent}
                      {`(${items?.length})`}{' '}
                      {items && items[0].idContinent?.toString() === '5' && (
                        <div className='na'>
                          <span className='icon-check' />
                          {props?.typeOfOffice}{' '}
                        </div>
                      )}
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse
                    eventKey={index.toString()}
                    className={expendAll ? 'show' : ''}
                  >
                    <div className='panel-body'>
                      {items &&
                        items?.map((pays: any, index: number) => {
                          return (
                            <Form.Check
                              key={index}
                              custom
                              disabled
                              label={pays.countryName}
                              id={pays.id}
                            />
                          );
                        })}
                    </div>
                  </Accordion.Collapse>
                </div>
              );
            })}
          <a href='javascript:;' onClick={() => setExpendAll(!expendAll)}>
            {!expendAll ? 'Expand all' : 'Collapse content'}
          </a>
        </Accordion>
      </div>
    </div>
  );

  const DistributionChannel = () => (
    <div className='bord distribution col-sm-6'>
      <h5 className='title'>Distribution channel</h5>
      <a
        className='linkEditLeftCompany'
        onClick={() => props.setIsShowLicencingModel(true)}
      >
        Edit<span className='icon-edit-full'></span>
      </a>
      {props.distributionChannel?.map(
        (el: { id: number; name: string }, index: number) => (
          <div className='liste' key={index}>
            {el.name}
          </div>
        )
      )}
    </div>
  );

  const LicencingModel = () => (
    <div className='bord licensing col-sm-6'>
      <h5 className='title'>Licensing model </h5>
      {props.licengingModel?.map(
        (el: { id: number; name: string }, index: number) => (
          <div className='liste' key={index}>
            {el.name}
          </div>
        )
      )}
    </div>
  );
  return (
    <div className='strategycontainer'>
      <h3 className='text-center font-weight-600'>Strategy</h3>
      <div className='list row'>
        {isOtherUser && props.licengingModel.length > 0 && <LicencingModel />}
        {!isOtherUser && <LicencingModel />}

        {isOtherUser && props.distributionChannel?.length > 0 && (
          <DistributionChannel />
        )}
        {!isOtherUser && <DistributionChannel />}

        {isOtherUser && props.geographieTarget?.length > 0 && (
          <GeographieTarger />
        )}
        {!isOtherUser && <GeographieTarger />}

        {isOtherUser && segmentInterestText?.length > 0 && (
          <TechnologyInterests />
        )}
        {!isOtherUser && <TechnologyInterests />}
      </div>

      {isOtherUser && props.selectedStategieInPlace?.length > 0 && (
        <TechnologyInPlace />
      )}
      {!isOtherUser && <TechnologyInPlace />}

      <div className='milestones'>
        <h5 className='title borderBottom'>Future milestones</h5>
        <div className='development'>
          {providerProductDevelopment.valueProductDeveloppement ? (
            <h5 className='title'>Product development</h5>
          ) : !isOtherUser ? (
            <h5 className='title'>Product development</h5>
          ) : (
            <></>
          )}

          <div className='product'>
            {checkIfExist(
              providerProductDevelopment.valueProductDeveloppement
            ) &&
              !isOtherUser && (
                <p>
                  What are the key short-term and long-term technical
                  milestones?
                </p>
              )}

            <a
              className='linkEditLeftCompany'
              onClick={() =>
                providerProductDevelopment.setIsShowModalProductDeveloppement(
                  true
                )
              }
            >
              Edit<span className='icon-edit-full'></span>
            </a>
          </div>
          {providerProductDevelopment.valueProductDeveloppement ? (
            !isOtherUser ? (
              providerProductDevelopment.valueProductDeveloppement
            ) : (
              <div
                className={
                  providerProductDevelopment.valueProductDeveloppement?.indexOf(
                    LOCKED_DATA
                  ) !== -1
                    ? 'blurText'
                    : ''
                }
                style={{
                  userSelect: 'none',
                }}
              >
                {' '}
                {providerProductDevelopment.valueProductDeveloppement.replace(
                  LOCKED_DATA,
                  ''
                )}
              </div>
            )
          ) : !isOtherUser ? (
            <div className='fauxText'>
              <div className='l100'></div>
              <div className='l100'></div>
              <div className='l100'></div>
              <div className='l50'></div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className='strategy'>
          {providerUseExpressionStrategy.valueExpressionStrategic ? (
            <h5 className='title'>Expansion strategy</h5>
          ) : !isOtherUser ? (
            <h5 className='title'>Expansion strategy</h5>
          ) : (
            <></>
          )}

          <div className='product'>
            {checkIfExist(
              providerUseExpressionStrategy.valueExpressionStrategic
            ) &&
              !isOtherUser && <p> What is your geographic expansion plan? </p>}
            <a
              className='linkEditLeftCompany'
              onClick={() =>
                providerUseExpressionStrategy.setIsShowExpressionStrategic(true)
              }
            >
              Edit<span className='icon-edit-full'></span>
            </a>
          </div>
          {providerUseExpressionStrategy.valueExpressionStrategic ? (
            !isOtherUser ? (
              providerUseExpressionStrategy.valueExpressionStrategic
            ) : (
              <div
                className={
                  providerUseExpressionStrategy.valueExpressionStrategic.indexOf(
                    LOCKED_DATA
                  ) !== -1
                    ? 'blurText'
                    : ''
                }
                style={{ userSelect: 'none' }}
              >
                {providerUseExpressionStrategy.valueExpressionStrategic.replace(
                  LOCKED_DATA,
                  ''
                )}
              </div>
            )
          ) : !isOtherUser ? (
            <div className='fauxText'>
              <div className='l100'></div>
              <div className='l100'></div>
              <div className='l100'></div>
              <div className='l50'></div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Modal
        show={props.isShowLicencingModel}
        className='createCompanypopup medium forStep5custom'
      >
        {/* smallPopup */}
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Licensing model & Distribution channel</h2>
            <a className='close' onClick={() => props.cancelUpdateCategorie()}>
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className='withMinHeight'>
          <ModelLicensing
            licengingModelData={props.licengingModelData}
            distributionModelData={props.distributionModelData}
            licenCingModelSelected={props.licenCingModelSelected}
            distributionModelSelected={props.distributionModelSelected}
            isHaveError={props.isHaveError}
            addDistributionChannel={(id: number) => {
              props.addDistributionChannel(id);
            }}
            addLicencing={(id: number) => {
              props.addLicencingChecked(id);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className='contentBorder formForEmail text-right'>
            <button
              className='btn btn-devinsider cancel'
              onClick={() => props.cancelUpdateCategorie()}
            >
              Cancel
            </button>
            <button
              className='btn btn-devinsider px-5'
              onClick={() => props.updateLicencingModel()}
            >
              {props.loaderlicencingModel ? <Loader /> : 'Save'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* End popup company distribution */}
      {/*Popup geographic target*/}
      <Modal
        show={props.showStrategyGeographic}
        className='createCompanypopup medium forStep5custom'
      >
        {/* smallPopup */}
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Geographic target Market</h2>
            <a className='close' onClick={() => props.cancelGeoGraphieTarget()}>
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className='withMinHeight'>
          <ModalGeographieTarget
            allContinents={props.allContinents}
            paysChecked={props.paysChecked}
            addPaysInArray={props.addPaysInArray}
            allContinentSelected={props.allContinentSelected}
            isShowOfficeData={props.isShowOfficeData} //delete it avant
            office={props?.dataCompleted?.office}
            addOffice={props.addOffice}
            isShowErroCountrySelected={props.isShowErroCountrySelected}
            isShowErrorOffice={isShowErrorOffice}
            selectedOffice={props.selectedOffice}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className='contentBorder formForEmail text-right'>
            <button
              className='btn btn-devinsider cancel'
              onClick={() => props.cancelGeoGraphieTarget()}
            >
              Cancel
            </button>
            <button
              className='btn btn-devinsider px-5'
              onClick={() => props.updateModalGeographicTarget()}
            >
              {props.loaderTargetCountry ? <Loader /> : 'Save'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* End popup geographic target */}
      <Modal
        show={props.isShowPartenerShipModal}
        className='createCompanypopup medium forStep5custom'
      >
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Technology partnership</h2>
            <a
              className='close'
              onClick={() => props.cancelTechnologieParteners()}
            >
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>

        <Modal.Body className='withMinHeight'>
          <ModalTechnologieParteners
            technologiesPartnership={
              props?.dataCompleted?.technologies_partnership
            }
            currentPartener={props.technologiesPartnership}
            addParterShip={props.addParterShip}
            partenerShipSelected={props.partenerShipSelected}
            checkedSoftware={props.checkedSoftware}
            setCheckedSoftware={(p: string) => props.setCheckedSoftware(p)}
            isShowErrorPartenerShip={props.isShowErrorPartenerShip}
            setCurrentSelected={(param: any) => props.setCurrentSelected(param)}
            currentSelected={props.currentSelected}
            addListOption={(param: any) => props.addListOption(param)}
            removeSelected={(param: any) => props.removeSelected(param)}
            currentSelectedList={props.currentSelectedList}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className='contentBorder formForEmail text-right'>
            <button
              className='btn btn-devinsider cancel'
              onClick={() => props.cancelTechnologieParteners()}
            >
              Cancel
            </button>
            <button
              className='btn btn-devinsider px-5'
              onClick={() => props.updateTechnologiePartener()}
            >
              {props.loader ? <Loader /> : 'Save'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* End popup company Partnership */}
      {/*Popup Product development*/}
      <ModalEditProductDevelopment
        closeModalProductDeveloppement={
          providerProductDevelopment.closeModalProductDeveloppement
        }
        isShowModalProductDeveloppement={
          providerProductDevelopment.isShowModalProductDeveloppement
        }
        loader={providerProductDevelopment.loader}
        setValueProductDeveloppement={
          providerProductDevelopment.setValueProductDeveloppement
        }
        updateProductDeveloppement={
          providerProductDevelopment.updateProductDeveloppement
        }
        valueProductDeveloppement={
          providerProductDevelopment.valueProductDeveloppement
        }
      />

      <ModalEditExpressionStrategy
        closeModalExpressionStrategic={
          providerUseExpressionStrategy.closeModalExpressionStrategic
        }
        isShowExpressionStrategic={
          providerUseExpressionStrategy.isShowExpressionStrategic
        }
        loader={providerUseExpressionStrategy.loader}
        setValueExpressionStrategic={
          providerUseExpressionStrategy.setValueExpressionStrategic
        }
        updateExpressionStrategic={
          providerUseExpressionStrategy.updateExpressionStrategic
        }
        valueExpressionStrategic={
          providerUseExpressionStrategy.valueExpressionStrategic
        }
      />
    </div>
  );
}
