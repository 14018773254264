import React from "react";
import { useHistory } from "react-router";
export default function useNavigation() {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  /**
   * @param url url to navigate
   * @param params to send url
   * @returns navigate url
   */
  const navigate = (url: string, params?: any) => {
    history.push({ pathname: url, state: params });
  };
  return { goBack, navigate };
}
