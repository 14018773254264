import "./classifiedAds.scss";
import React from "react";
import Header from "../../widget/index";
import splunkLogo from "../../../resources/images/splunk.png";
import "./classifiedAds.scss";
import UseCategory from "../../../controller/classified-ads-ctr/category-ctr";
import ClassifiedAdsSubMenu from "./sub-menu";
import { IAd } from "../../../redux/types/classified-ads-interface";
import AdsItem from "../../widget/ads";
import { LoaderCommon } from "../../widget/loader";

export default function CategoryClassifiedAds() {
  const providerClassifiedAds = UseCategory();
  return (
    <div>
      <Header />
      <section className="classified">
        <ClassifiedAdsSubMenu {...providerClassifiedAds} />
        <div className="container mx-auto minHeightwin">
          <div className="listOfIsv classifiedAdsScroll">
            <div className="scrollContainer">
              {!providerClassifiedAds?.dataFetched ? (
                <LoaderCommon />
              ) : (
                <div className="listFresh">
                  {providerClassifiedAds?.ads?.map((el: IAd, key: number) => (
                    <AdsItem
                      ads={el}
                      setShowLogin={providerClassifiedAds?.setShowLogin}
                      key={key}
                      goToCategory={(idCategory: string) => null}
                    />
                  ))}
                  {providerClassifiedAds?.ads?.length <
                    providerClassifiedAds?.total && (
                    <div className="linkView">
                      {providerClassifiedAds?.loader ? (
                        <LoaderCommon />
                      ) : (
                        <a
                          href="javascript:;"
                          className={`viewAll ${
                            providerClassifiedAds?.loader && `disabled`
                          }`}
                          onClick={providerClassifiedAds?.handleViewMore}
                        >
                          {providerClassifiedAds?.loader ? (
                            <LoaderCommon />
                          ) : (
                            "View more"
                          )}
                        </a>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
