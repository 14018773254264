import React from "react";
import { useHistory } from "react-router-dom";
type IProps = {
  allProgram: Array<any>;
  openWindowProgram: () => void;
  currentOemProgram: any;
  createNewProgram: () => void;
};
export default function BlockProgramList(props: IProps) {
  const history = useHistory();
  return (
    <ul className="listProgramUl">
      {props.allProgram
        ?.filter((el) => el?.oem_program?.uuid != "")
        .map((el: any, index: number) => (
          <li
            key={index}
            onClick={() => {
              props.openWindowProgram();
              history?.replace("/OEM/profil/" + el?.oem_program?.id);
            }}
          >
            <a
              href="javascript:;"
              className={
                el?.oem_program?.id?.toString() ===
                props.currentOemProgram?.program.id?.toString()
                  ? "active"
                  : ""
              }
              onClick={(e) => e?.preventDefault()}
            >
              Program {index + 1} : {el?.oem_program?.program_name}
            </a>
          </li>
        ))}
      <li onClick={() => props.createNewProgram()}>
        <a href="javascript:;" onClick={(e) => e?.preventDefault()}>
          Create new program
        </a>
      </li>
    </ul>
  );
}
