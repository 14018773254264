import React, { ChangeEvent } from "react";

type IProps = {
  setSelectedCategorie: (p: any) => void;
  communityReducer: any;
  selectedCategorie: number;
};
export default function HeaderLatest(props: IProps) {
  return (
    <h2 className="title withLink">
      Latest :
      <a
        onClick={() =>
          props?.setSelectedCategorie(props?.communityReducer?.postType?.[0].id)
        }
        className={
          props?.selectedCategorie ===
          props?.communityReducer?.postType?.[0]?.id
            ? "active"
            : ""
        }
      >
        Discussions
      </a>
      <a
        onClick={() =>
          props?.setSelectedCategorie(props?.communityReducer?.postType?.[1].id)
        }
        className={
          props?.selectedCategorie ===
          props?.communityReducer?.postType?.[1]?.id
            ? "active"
            : ""
        }
      >
        Articles
      </a>
      <a
        onClick={() =>
          props?.setSelectedCategorie(props?.communityReducer?.postType?.[2].id)
        }
        className={
          props?.selectedCategorie ===
          props?.communityReducer?.postType?.[2]?.id
            ? "active"
            : ""
        }
      >
        Press releases
      </a>
      <a
        onClick={() =>
          props?.setSelectedCategorie(props?.communityReducer?.postType?.[3].id)
        }
        className={
          props?.selectedCategorie ===
          props?.communityReducer?.postType?.[3]?.id
            ? "active"
            : ""
        }
      >
        Program reviews
      </a>
      <select
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          props?.setSelectedCategorie(e.target.value);
        }}
      >
        <option value={props?.communityReducer?.postType?.[0]?.id}>
          Discussions
        </option>
        <option value={props?.communityReducer?.postType?.[1]?.id}>
          Articles
        </option>
        <option value={props?.communityReducer?.postType?.[2]?.id}>
          Press releases
        </option>
        <option value={props?.communityReducer?.postType?.[3]?.id}>
          Program reviews
        </option>
      </select>
    </h2>
  );
}
