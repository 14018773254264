import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { getData, getDataWithoutToken } from "../../../../service/api";
import { operationBillingAmount, url } from "../../../../service/constant";
import { getMonth } from "../../../../utils";

export default function UseUnlockInvestor() {
  const now = new Date();
  const userReducer = useSelector((state: rootState) => state.userReducer);

  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const [amountUnlockIsv, setAmountUnlockIsv] = useState<string>("");
  const [modalUnlock, setModalUnlock] = useState<boolean>(false);
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );

  const closeUlocModal = () => {
    setStateUnlock({
      number_left: 0,
      number_max: 0,
      number_unlock_isv_not_in_subscription: 0,
      max_unlock_number: 0,
      number_unlock_isv_max_in_subscription: 0,
    });
    setModalUnlock(false);
    setChoiseValue(undefined);
  };

  const [idIsv, setIdIsv] = useState<number | undefined>();

  const showModalUnlock = (id: number) => {
    setModalUnlock(true);
    setIdIsv(id);
  };

  const [stateUnlock, setStateUnlock] = useState<{
    number_left: number;
    number_max: number;
    number_unlock_isv_not_in_subscription: number;
    max_unlock_number: number;
    number_unlock_isv_max_in_subscription: number;
  }>({
    number_left: 0,
    number_max: 0,
    number_unlock_isv_not_in_subscription: 0,
    max_unlock_number: 0,
    number_unlock_isv_max_in_subscription: 0,
  });

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };

  useEffect(() => {
    (async () => {
      let response = await getData(
        url.settings.billingCenter +
          "/" +
          operationBillingAmount.OEM_UNLOCK_ISV +
          "/" +
          oemProgram.id,
        userReducer.token
      );
      setAmountUnlockIsv(response && response.data);
    })();
  }, []);

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + "/information/plan_details",
        { month: currentMonth, oem: oemProgram.id },
        userReducer.token
      );
      if (response?.status === 200) {
        const { oem_program_unlock_isv, oemProgramBillingDetails }: any =
          response.data.free_status;
        setStateUnlock({
          ...oem_program_unlock_isv,
          ...oemProgramBillingDetails,
        });

        setChargingPlanDetails(false);
        const isHaveFree = oem_program_unlock_isv?.number_left > 0;
        if (isHaveFree) {
          setChoiseValue("FREE");
        } else {
          setChoiseValue("PAY");
        }
      }
    }
    if (modalUnlock) {
      getPlanDetails();
    }
  }, [currentMonth, modalUnlock]);

  return {
    modalUnlock,
    closeUlocModal,
    setModalUnlock,
    chargingPlanDetails,
    stateUnlock,
    handleChoise,
    choiseValue,
    showModalUnlock,
    idIsv,
    amountUnlockIsv,
    max_unlock_number: stateUnlock.max_unlock_number,
    number_unlock_isv_not_in_subscription:
      stateUnlock.number_unlock_isv_not_in_subscription,
  };
}
