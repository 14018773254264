import React, { useEffect, useState, useCallback } from "react";
import store from "../../../../redux/store";
import { getDataService } from "../../../../service/applicatif/back-end-service";
import { url } from "../../../../service/constant";
import { useHistory } from "react-router";

export type IPropsBestAnswer = {
  order_rank: string;
  number_of_like: string;
  profil_picture: string;
  user_name: string;
  job_title: string;
  id: string;
};
export default function Index() {
  /* get top like contributions */
  const [topBestAnswerAuthor, setTopBestAnswerAuthor] = useState<
    Array<IPropsBestAnswer>
  >([]);
  const [loaderBestAnswer, setLoaderBestAnswer] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    async function getData() {
      setLoaderBestAnswer(true);
      const result = await getDataService(
        url.community.get_top_best_answer_author,
        {},
        store.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        setTopBestAnswerAuthor(result?.data);
        setLoaderBestAnswer(false);
      }
    }
    getData();
  }, []);

  const showActivityUser = (idUser: string) => {
    history.push("/community/activity-user/" + idUser);
  };
  const viewAllBestAnswerAuthor = () => {
    history?.push("/community/best-answer");
  };

  return {
    topBestAnswerAuthor,
    loaderBestAnswer,
    showActivityUser,
    viewAllBestAnswerAuthor,
  };
}
