import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import store from '../redux/store';
import { isEmpty } from 'lodash';

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  isSignedIn?: boolean;
  key: string;
  path: string;
  exact: boolean;
  allowedRole?: Array<string>;
}

export default function NotAllowedConnectedPage(props: PrivateRouteProps) {
  const { component: Component, isSignedIn, ...rest } = props;
  const isConnected = !isEmpty(store.getState().userReducer.token);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !isConnected ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/profil-not-found',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
}
