import React from "react";
import { Link } from "react-router-dom";
import url from "../../../../../../service/constant/index";
import DefaultCompany from "../../../../../../resources/images/defaultCompany.png";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../../../../redux/reducers";
import { useHistory, useLocation } from "react-router-dom";
import { fileUrl } from "../../../../../../utils";

const CompanyCardOem = (props: any) => {
  const history = useHistory();

  const oemProgramPage = useSelector(
    (state: rootState) => state.oemProgramReducer
  );

  const userState = useSelector((state: rootState) => state.userReducer);

  const goToCompanyPage = () => {
    history?.push("/OEM/profil/" + oemProgramPage?.program?.id);
  };

  return (
    <div
      className="contentISV"
      onClick={() => goToCompanyPage()}
      style={{ cursor: "pointer" }}
    >
      <h2 className="title">
        {userState?.oemProgramMembers?.length || ""} Affiliated program page
      </h2>
      <ul className="svList">
        <li>
          <div className="imgSv">
            <img
              src={
                oemProgramPage?.program
                  ? fileUrl(oemProgramPage?.program?.photo_profil)
                  : DefaultCompany
              }
              alt="Software vendor logo"
            />
          </div>
          <div className="infoSV">
            <>
              {/* Bug #47394 Company name in OEM profile changes to "ISV Partner Program" */}
              <h3 className="title">{oemProgramPage?.program?.program_name}</h3>
              <div className="role">
                {userState?.oemProgramMembers?.[0].role}
              </div>
            </>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CompanyCardOem;
