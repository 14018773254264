import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateCompanyPageAction } from "../../../../redux/actions/company-page-isv-action/current_isv_action";
import { rootState } from "../../../../redux/reducers";

export default function UseProductDevelopment() {
  const params: any = useParams();
  const dispatch = useDispatch();
  let companyPageObject = useSelector((state: rootState) =>
    params?.id ? state?.otherUserCompanyIsvReducer : state.companyPageReducer
  );
  const [isShowModalProductDeveloppement, setIsShowModalProductDeveloppement] =
    useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [valueProductDeveloppement, setValueProductDeveloppement] =
    useState<string>(
      companyPageObject?.future_milestones_product_development || ""
    );
  const cbUpdateProductDeveloppement = (response: any) => {
    if (response && response.status === 200) {
      setLoader(false);
      setIsShowModalProductDeveloppement(false);
    }
  };

  const updateProductDeveloppement = () => {
    let params = {
      futureMilestonesProductDevelopment: valueProductDeveloppement,
    };
    setLoader(true);

    dispatch(
      updateCompanyPageAction(
        params,
        companyPageObject?.id,
        cbUpdateProductDeveloppement
      )
    );
  };

  const closeModalProductDeveloppement = () => {
    setValueProductDeveloppement(
      companyPageObject?.future_milestones_product_development || ""
    );
    setIsShowModalProductDeveloppement(false);
  };

  useEffect(() => {
    setValueProductDeveloppement(
      companyPageObject?.future_milestones_product_development || ""
    );
    return () => {};
  }, [companyPageObject]);
  return {
    isShowModalProductDeveloppement,
    setIsShowModalProductDeveloppement,
    loader,
    closeModalProductDeveloppement,
    updateProductDeveloppement,
    setValueProductDeveloppement,
    valueProductDeveloppement,
  };
}
