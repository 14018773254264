import { concatString } from '../../../utils';
import {
  IDiscutionCategorie,
  ItemChildreen,
  ITemDetailsDiscutionCategorie,
  ItemsReplyPost,
} from '../../types/community-interface';

export const helperLatest = (params: any): Array<any> => {
  let tempData = params?.map((el: any) => ({
    id: el?.id,
    author: el?.user,
    last_activity: el?.last_activity_by,
    labels: el?.labels,
    tags: el?.tags,
    title: el?.title,
    updated_at: el?.updated_at,
    post_type: el?.post_type,
    isLocked: el?.isLocked,
    isPinned: el?.isPinned,
  }));
  return tempData;
};

export const helperSetChildPost = (
  state: Array<ItemsReplyPost>,
  allChild: any,
  parentId: string,
  stateAllReply: Array<ItemsReplyPost>
): Array<ItemsReplyPost> => {
  let tempMap = state?.find((el: ItemsReplyPost, index: number) => {
    return el?.id?.toString() === parentId?.toString();
  });
  let tempItemSetChilldreen: any = {
    ...tempMap,
    childreen: allChild,
  };

  return stateAllReply?.map((item: ItemsReplyPost) => {
    if (item?.id?.toString() === parentId?.toString()) {
      return tempItemSetChilldreen;
    } else {
      return item;
    }
  });
};

export const helperSetDeepChild = (
  state: Array<ItemsReplyPost>,
  allChild: any,
  parentId: string,
  currentPageChildreen: number
): Array<ItemsReplyPost> => {
  let tempMap = state?.map((el: ItemsReplyPost, index: number) => {
    if (el?.id?.toString() === parentId?.toString()) {
      return {
        ...el,
        childreen: [...el?.childreen, ...allChild],
        currentPageChildreen,
      };
    } else {
      return el;
    }
  });
  return tempMap;
};

export const helperFindReply = (
  allReply: Array<ItemsReplyPost>,
  numberLike: number,
  idCurrentParent: number,
  isChildreen: boolean,
  idChildreen: number
): Array<ItemsReplyPost> => {
  if (isChildreen) {
    //Find current parent with childreen to update
    let replyToUpdateChildreen = allReply?.find(
      (el: ItemsReplyPost) => el?.id === idCurrentParent
    );
    //Update childreen
    let updateChildreen =
      replyToUpdateChildreen?.childreen?.map((el: ItemChildreen) => {
        if (el?.id === idChildreen) {
          return {
            ...el,
            numberOfLikes: numberLike,
            liked: !el?.liked,
          };
        } else {
          return el;
        }
      }) || [];

    //return allReplye
    let makeAllReplyeChildreen = allReply?.map((el: ItemsReplyPost) => {
      if (el?.id === idCurrentParent) {
        return {
          ...el,
          childreen: updateChildreen,
        };
      } else {
        return el;
      }
    });
    return makeAllReplyeChildreen;
  } else {
    let makeAllReply = allReply?.map((el: ItemsReplyPost) => {
      if (el?.id === idCurrentParent) {
        return {
          ...el,
          numberOfLikes: numberLike,
          liked: !el?.liked,
        };
      } else {
        return el;
      }
    });
    return makeAllReply;
  }
};

export const followCategory = (
  categories: Array<ITemDetailsDiscutionCategorie>,
  params: {
    idCategory: number;
    state: number;
  }
) => {
  let updatedCategories: Array<ITemDetailsDiscutionCategorie> = categories?.map(
    (el: ITemDetailsDiscutionCategorie) =>
      el?.id === params?.idCategory
        ? {
            ...el,
            is_followed: !params?.state,
          }
        : el
  );
  return updatedCategories;
};

export const helperMarkBestAnswer = (
  allReply: Array<ItemsReplyPost>,
  idCurrentParent: number,
  isChildreen: boolean,
  idChildreen: number,
  childReplye: any
): Array<ItemsReplyPost> => {
  if (isChildreen) {
    //Find current parent with childreen to update
    let replyToUpdateChildreen = allReply?.find(
      (el: ItemsReplyPost) => el?.id?.toString() === idCurrentParent?.toString()
    );

    // //Update childreen
    // let updateChildreen = replyToUpdateChildreen?.childreen?.map(
    //   (el: ItemChildreen) => {
    //     if (el?.id?.toString() === idChildreen?.toString()) {
    //       return {
    //         ...el,
    //         best_answer: true,
    //       };
    //     } else {
    //       return el;
    //     }
    //   }
    // );

    //return allReplye
    let makeAllReplyeChildreen = allReply?.map((el: ItemsReplyPost) => {
      if (el?.id?.toString() === idCurrentParent?.toString()) {
        return {
          ...el,
          childreen: childReplye,
        };
      } else {
        return el;
      }
    });
    return makeAllReplyeChildreen;
  } else {
    let makeAllReply = allReply?.map((el: ItemsReplyPost) => {
      if (el?.id?.toString() === idCurrentParent?.toString()) {
        return {
          ...el,
          best_answer: true,
          childreen: childReplye,
        };
      } else {
        return el;
      }
    });
    return makeAllReply;
  }
};

export const helperRemovePost = (
  allReply: Array<ItemsReplyPost>,
  idCurrentParent: number,
  isChildreen: boolean,
  idChildreen: number,
  userToRemove: any
): Array<ItemsReplyPost> => {
  if (isChildreen) {
    //Find current parent with childreen to update
    let replyToUpdateChildreen = allReply?.find(
      (el: ItemsReplyPost) => el?.id?.toString() === idCurrentParent?.toString()
    );

    //Update childreen
    let updateChildreen = replyToUpdateChildreen?.childreen?.map(
      (el: ItemChildreen) => {
        if (el?.id?.toString() === idChildreen?.toString()) {
          return {
            ...el,
            isDeleted: true,
            deleted_by: userToRemove,
          };
        } else {
          return el;
        }
      }
    );

    //return allReplye
    let makeAllReplyeChildreen = allReply?.map((el: ItemsReplyPost) => {
      if (el?.id?.toString() === idCurrentParent?.toString()) {
        return {
          ...el,
          childreen: updateChildreen || [],
        };
      } else {
        return el;
      }
    });
    return makeAllReplyeChildreen;
  } else {
    let makeAllReply = allReply?.map((el: ItemsReplyPost) => {
      if (el?.id?.toString() === idCurrentParent?.toString()) {
        return {
          ...el,
          isDeleted: true,
          deleted_by: userToRemove,
        };
      } else {
        return el;
      }
    });
    return makeAllReply;
  }
};

export const helperUpdateReply = (
  allReply: Array<ItemsReplyPost>,
  idCurrentParent: number,
  isChildreen: boolean,
  idChildreen: number,
  content: string
): Array<ItemsReplyPost> => {
  if (isChildreen) {
    //Find current parent with childreen to update
    let replyToUpdateChildreen = allReply?.find(
      (el: ItemsReplyPost) => el?.id?.toString() === idCurrentParent?.toString()
    );
    //Update childreen
    let updateChildreen = replyToUpdateChildreen?.childreen?.map(
      (el: ItemChildreen) => {
        if (el?.id?.toString() === idChildreen?.toString()) {
          return {
            ...el,
            content: content,
          };
        } else {
          return el;
        }
      }
    );

    //return allReplye
    let makeAllReplyeChildreen = allReply?.map((el: ItemsReplyPost) => {
      if (el?.id?.toString() === idCurrentParent?.toString()) {
        return {
          ...el,
          childreen: updateChildreen || [],
        };
      } else {
        return el;
      }
    });
    return makeAllReplyeChildreen;
  } else {
    let makeAllReply = allReply?.map((el: ItemsReplyPost) => {
      if (el?.id?.toString() === idCurrentParent?.toString()) {
        return {
          ...el,
          content: content,
        };
      } else {
        return el;
      }
    });
    return makeAllReply;
  }
};

export const helperCheckStateFollow = (state: any, user: any) => {
  let found = state?.find(
    (el: any) => el?.id?.toString() === user?.id?.toString()
  );
  var tempFollowing;
  if (found) {
    tempFollowing = state?.filter(
      (el: any) => el?.id?.toString() !== user?.id?.toString()
    );
  } else {
    tempFollowing = [...state, user];
  }
  return tempFollowing;
};
