/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import * as T from "../../../../../../../controller/company-ctr/types/types-company-page";
import { ReactComponent as Loader } from "../../../../../../../resources/images/Rolling.svg";

export default function FormBecomeVerifiedVerifying(props: T.TVerifiedEmail) {
  return (
    <div>
      {/* Become a verified profesionnal */}
      <Modal
        show={props?.isShowEmail}
        className="createCompanypopup smallPopup forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Become a verified professional</h2>
            <a href="javascript:;" className="close">
              <span
                className="icon-close-pop"
                onClick={() => props?.setIsShowEmail()}
              ></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight forBecomeVerified">
          <div className="contentBorder">
            <div className="col-12 left">
              <p className="bottom">
                Verifying your work email allows you to get affiliated or create
                your own company page.
              </p>
              <p className="bottom">
                {props?.errorEmail && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">{props?.emailErrorTxt}</span>
                  </div>
                )}
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={props?.workEmailTovalid}
                  onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                    props?.setWorkEmailTovalid(evt?.target?.value)
                  }
                  placeholder="work email"
                />
              </p>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="acceptit"
                  checked={props?.isCheched}
                  onChange={() => props?.setIsCheched()}
                />
                <label
                  htmlFor="acceptit"
                  className="custom-control-label mini-font"
                >
                  I don't have any professional email address
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right forPitch">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => props?.setIsShowEmail()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-devinsider px-5"
              onClick={() =>
                props?.isCheched ? props?.showContactUs() : props?.sendRequest()
              }
              disabled={props?.loader}
            >
              {props?.loader ? (
                <Loader />
              ) : props?.isCheched && !props?.loader ? (
                "Get in touch"
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* end Become a verified profesionnal*/}
    </div>
  );
}
