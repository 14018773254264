import * as React from "react";
import "../../widget/bouton/style-btn-action.css";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";

type Props = {
    text: any;
    classes?: string;
    styles?: React.CSSProperties;
    click?: (data?:any) => void;
    showLoader?: boolean;
};

export const BtnAction: React.FC<Props> = (props) => {
    const { text, classes, styles, click, showLoader = false} = props;

    return (
        <button 
            className={classes}
            onClick={() => !showLoader && click && click()}
            style={{ ...styles, padding: "0.3rem" }}
        >
            {showLoader ? (
                <span className="loadIcon">
                    <Loader />
                </span>
            ) : (
                    <label htmlFor="" className="label-text-action">
                        {text}
                    </label>
                )}
        </button>
    );
};
