import React, { ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { concatString, isURL, refaCtoNullKey } from '../../../../../utils';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';

export type IpropsModal = {
  dataSource: any;
};
export default function ModalEditAboutDifferentiators(props: IpropsModal) {
  return (
    <Modal
      show={props?.dataSource?.difShow}
      onHide={() => props?.dataSource?.setDifShow(false)}
      className='modalDevinsider large'
    >
      <Modal.Header closeButton>
        <Modal.Title>Key competitive Differentiators</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='contentBorder row m-0 p-0'>
          <div className='col-12 descCompany'>
            <span>
              <b>Highlight your key competitive differentiatiors</b>
            </span>
            <textarea
              name=''
              value={refaCtoNullKey(
                props?.dataSource?.infoCompanyToEdit?.key_competitive,
                ''
              )}
              onChange={(evt: ChangeEvent<HTMLTextAreaElement>) =>
                props?.dataSource?.setInfoCompanyToEdit({
                  ...props?.dataSource?.infoCompanyToEdit,
                  key_competitive: evt.target.value,
                })
              }
              maxLength={450}
            />
            {props?.dataSource?.infoCompanyToEdit.key_competitive != 'null' && (
              <div className='caractercount'>
                {`${
                  0 +
                  (props?.dataSource?.infoCompanyToEdit?.key_competitive
                    ?.length || 0)
                } / 450`}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='cancelbtn'
          onClick={() => props?.dataSource?.cancelChange()}
        >
          Cancel
        </Button>
        <Button
          className='validate'
          onClick={() => props?.dataSource?.updateCompanyResellerDetails()}
          disabled={props?.dataSource?.isLoaderShow}
        >
          {props?.dataSource?.isLoaderShow ? <Loader /> : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
