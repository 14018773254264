import React from "react";

export default function TextInfo() {
  return (
    <div className="bodyCreateCompany ">
      <div className="finalpopup success">
        <h3 className="title">
          Your admin request <br />
          has been sent!
        </h3>
        Your request will be reviewed by the current admin(s)
        <br /> of the page.
      </div>
    </div>
  );
}
