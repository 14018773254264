/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as Loader } from '../../../../../../resources/images/Rolling.svg';
import * as T from '../../../../../../controller/company-ctr/types/types-company-page';

export default function ModalEditSummary(props: T.IpropsEditSummary) {
  const handleChangeSummary = (event: ChangeEvent<HTMLTextAreaElement>) => {
    props.setModalValueSummary(event.target.value);
    props.setSummaryLength(event?.target?.value?.length);
  };

  return (
    <>
      <Modal
        show={props.isShowOverView}
        className='createCompanypopup large forStep5custom'
      >
        {/* smallPopup */}
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Company summary </h2>
            <a className='close' onClick={() => props.cancelUpdateSummary()}>
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <div className='contentBorder row forOverview'>
            <div className='col-12 descCompany'>
              <label htmlFor=''>Outline a brief overview of your company</label>
              <textarea
                value={props.modalValueSummary}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  handleChangeSummary(event)
                }
                maxLength={2000}
              />
              <span className='caractercount'>
                {0 + (props.summaryLength || 0)} / 2000
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='contentBorder row forOverview'>
          <div className='contentBorder formForEmail text-right'>
            <button
              className='btn btn-devinsider cancel'
              onClick={() => props.cancelUpdateSummary()}
            >
              Cancel
            </button>
            <button
              className='btn btn-devinsider px-5 save'
              onClick={() => props.updateCompanySummary()}
            >
              {props.isLoader ? <Loader /> : 'Save'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
