import React from "react";
import {
  activeFilterContributor,
  ItemAuthor,
} from "../../../../../controller/community-ctr/bloc-community-ctr/use-view-all-top-author-contributor";
import { concatString, fileUrl, refaCtoNullKey } from "../../../../../utils";
import Pagination from "../../../../widget/use-pagination/pagination";
import { LoadingArticle } from "../../../../widget/loader";

export type IPropsAuthor = {
  activeFilter:
    | "last-day"
    | "last-week"
    | "last-month"
    | "last-six-mounth"
    | "last-year"
    | "all-time";
  dataAuthor: Array<ItemAuthor>;
  topLikedAuthor: Array<ItemAuthor>;
  setFilterAuthor: (p: activeFilterContributor) => void;
  loadAuthor: boolean;
  totalData: number;
  handlePageClick: (p: number) => void;
  currentPage: number;
  categorie: string;
  myRank: ItemAuthor;
  showActivityUser: (p: string) => void;
};

export default function AthorsTab(props: IPropsAuthor) {
  const style={cursor:"pointer"};
  let firstColumn: Array<ItemAuthor> = props?.dataAuthor?.slice(0, 6);
  let secondColumn: Array<ItemAuthor> = props?.dataAuthor?.slice(6, 12);
  let thirdColumn: Array<ItemAuthor> = props?.dataAuthor?.slice(12, 18);
  return (
    <div>
      <div className="timeRange">
        <p>Time range: </p>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-day" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("last-day")}
        >
          Last day (24 Hours)
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-week" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("last-week")}
        >
          Last week (7 Days)
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-month" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("last-month")}
        >
          Last months (30 Days)
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-six-mounth" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("last-six-mounth")}
        >
          Last 6 months{" "}
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-year" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("last-year")}
        >
          Last year
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "all-time" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("all-time")}
        >
          All time
        </a>
      </div>
      <h3 className="title">Top liked authors in "{props?.categorie}"</h3>

      <div className="listAuthors">
        <div className="allList">
          <div className="rankAll">
            {props?.topLikedAuthor?.map((el: ItemAuthor, index: number) => (
              <div className="itemRank" key={index}>
                <div className="rang">#{el?.order_rank}</div>
                <div className="infoRank">
                  <div className="imageAffiliatedcompany empty">
                    <img src={fileUrl(el?.profil_picture)} alt="" />
                  </div>
                  <div
                    style={style}
                    className="nameAndLike"
                    onClick={(e: any) => {
                      props?.showActivityUser(el?.user_to_like_id);
                    }}
                  >
                    <p className="name">{concatString(el?.user_name)}</p>
                    <span className="like">{el?.number_of_like} Like{parseInt(el?.number_of_like) > 1 ? "s":""}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="myrankContent">
            <div className="myrank">
              <div className="itemRank">
                <div className="rang">
                  Your rank:
                  <br />#{refaCtoNullKey(props?.myRank?.order_rank)}
                </div>
                <div className="infoRank">
                  <div className={`imageAffiliatedcompany empty`}>
                    {props?.myRank?.profil_picture && (
                      <img
                        src={fileUrl(props?.myRank?.profil_picture)}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="nameAndLike" style={style} onClick={(e: any) => {
                          props?.showActivityUser(props?.myRank?.id);
                        }}>
                    <p className="name">
                      {props?.myRank?.user_name || "Anonymous"}
                    </p>
                    {props?.myRank?.number_of_like && (
                      <span className="like">
                        {props?.myRank?.number_of_like + " "} Like{parseInt(props?.myRank?.number_of_like) > 1 ? "s":""}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="otherList row ">
          {props?.loadAuthor ? (
            <LoadingArticle />
          ) : (
            <>
              <div className="col-4">
                {firstColumn?.map((el: ItemAuthor, index: number) => (
                  <div className="itemRank" key={index}>
                    <div className="rang">{el?.order_rank}</div>
                    <div className="infoRank">
                      <div
                        className="imageAffiliatedcompany empty"
                      >
                        <img src={fileUrl(el?.profil_picture)} alt="" />
                      </div>
                      <div className="nameAndLike" style={style} onClick={(e: any) => {
                          props?.showActivityUser(el?.user_to_like_id);
                        }}>
                        <p className="name">{concatString(el?.user_name)}</p>
                        <span className="like">{el?.number_of_like} Like{parseInt(el?.number_of_like) > 1 ? "s":""} </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-4">
                {secondColumn?.map((el: ItemAuthor, index: number) => (
                  <div className="itemRank" key={index}>
                    <div className="rang">{el?.order_rank}</div>
                    <div className="infoRank">
                      <div
                        className="imageAffiliatedcompany"
                      >
                        <img src={fileUrl(el?.profil_picture)} alt="" />
                      </div>
                      <div className="nameAndLike" style={style} onClick={(e: any) => {
                          props?.showActivityUser(el?.user_to_like_id);
                        }}>
                        <p className="name">{concatString(el?.user_name)}</p>
                        <span className="like">{el?.number_of_like} Like{parseInt(el?.number_of_like) > 1 ?"s":""}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-4">
                {thirdColumn?.map((el: ItemAuthor, index: number) => (
                  <div className="itemRank" key={index}>
                    <div className="rang">{el?.order_rank}</div>
                    <div className="infoRank">
                      <div
                        className="imageAffiliatedcompany"
                      >
                        <img src={fileUrl(el?.profil_picture)} alt="" />
                      </div>
                      <div className="nameAndLike" style={style} onClick={(e: any) => {
                          props?.showActivityUser(el?.user_to_like_id);
                        }}>
                        <p className="name">{concatString(el?.user_name)}</p>
                        <span className="like">{el?.number_of_like}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination">
                <Pagination
                  className="pagination"
                  currentPage={props?.currentPage}
                  totalCount={props?.totalData}
                  pageSize={18}
                  onPageChange={(page: any) => props?.handlePageClick(page)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
