import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { rootState } from "../../../redux/reducers";
import store from "../../../redux/store";
import { IAd } from "../../../redux/types/classified-ads-interface";
import { getFilteredClassifiedAds } from "../../../service/applicatif/classified-ads";
import { OEM_PROGRAM } from "../../../service/constant";
import { ClassifiedAdsCategories } from "../post-ads-ctr/type";

export default function UseCategory() {
  const location: any = useLocation();
  const params: any = useParams();
  const dispatch = useDispatch();

  const userObject = useSelector((state: rootState) => state?.userReducer);
  const oemProgram = useSelector(
    (state: rootState) => state?.oemProgramReducer?.program
  );

  const role = userObject?.roles?.filter((el) => el !== "ROLE_USER");

  const isOem = role?.[0] === OEM_PROGRAM;

  const classifiedAdsReducer = useSelector(
    (state: rootState) => state?.classifiedAdsReducer
  );
  const [showLogin, setShowLogin] = useState<boolean>(false);

  const [category, setCategory] = useState<ClassifiedAdsCategories>({
    id: "",
    type: "",
    icon: "",
  });
  const [ads, setAds] = useState<Array<IAd>>([]);
  const [total, setTotal] = useState<number>(0);
  const [current, setCurrent] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [reset, setReset] = useState<boolean>(false);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setCategory(
      classifiedAdsReducer?.categories?.filter((el) => el?.id === params?.id)[0]
    );
  }, [params?.id]);

  const resetFilter = async () => {
    setCurrent(1);
    setSelectedType([]);
    setSelectedLocation([]);
    setSelectedSolution([]);
    setSelectedIndustry([]);
    setReset(true);
  };

  useEffect(() => {
    setLoader(true);
    setReset(false);
    setDataFetched(false);
    (async () => await getFiltered("page", current, callback))();
  }, [reset === true]);

  const callback = (data: any) => {
    if (data) {
      setLoader(false);
      setAds(data?.results);
      setTotal(data?.total_result_to_paginate);
      setDataFetched(true);
    }
  };

  const callbackMore = (data: any) => {
    if (data) {
      setLoader(false);
      setAds([...ads, ...data?.results]);
    }
  };

  const filter = async () => {
    setCurrent(1);
    setLoader(true);
    setDataFetched(false);
    await getFiltered("page", 1, callback);
  };

  const getFiltered = async (key: string, value: any, callback: any) => {
    let dataParams = {
      type: params?.id,
      companyType: selectedType?.join()?.trim(),
      location: selectedLocation?.join()?.trim(),
      softwareSolution: selectedSolution?.join()?.trim(),
      targetedIndustry: selectedIndustry?.join()?.trim(),
      page: current,
      favorite: "0",
      search: search,
      oem_program: isOem ? oemProgram?.id : "0",
      [key]: value,
    };
    let data = await getFilteredClassifiedAds(
      dataParams,
      store && store?.getState()?.userReducer?.token
    );
    callback(data);
  };

  useEffect(() => {
    setCurrent(1);
    setLoader(true);
    setDataFetched(false);
    (async () => await getFiltered("page", 1, callback))();
  }, [params?.id]);

  const handleViewMore = async () => {
    setCurrent(current + 1);
    setLoader(true);
    await getFiltered("page", current + 1, callbackMore);
  };

  const handleSearch = async () => {
    setCurrent(1);
    setLoader(true);
    setDataFetched(false);
    (async () => await getFiltered("page", 1, callback))();
  };

  return {
    classifiedAdsReducer,
    category,
    selectedType,
    setSelectedType,
    selectedLocation,
    setSelectedLocation,
    selectedSolution,
    setSelectedSolution,
    selectedIndustry,
    setSelectedIndustry,
    resetFilter,
    filter,
    ads,
    showLogin,
    setShowLogin,
    loader,
    handleViewMore,
    total,
    dataFetched,
    searchbar: {
      search: search,
      setSearch: setSearch,
      handleSearch: handleSearch,
    },
  };
}
