import React from "react";
import { SubscribedDiscussion } from "../../../../interface/isv";
import { ItemDiscussion } from "../../../../redux/types/community-interface";
import { useHistory } from "react-router";
import { extractIdFromUri } from "../../../../utils";
import { useState, useEffect } from "react";

interface ISubscribeDiscussionProps {
  data: Array<SubscribedDiscussion>;
}

export default function SubscribeDiscussion(props: ISubscribeDiscussionProps) {
  const [scrollPosition, setScrollPosition] = useState(0);
  let setposSubscribtion =
    document.getElementById("fixWhenScroll")?.offsetTop || 600;

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { data } = props;
  const history = useHistory();

  const goToreplayPost = (item: any) => {
    // const categorieId = extractIdFromUri(item?.category);
    const categorieId = item?.category?.id;
    history.push("/community/reply-post/" + categorieId + "/" + item?.id);
  };

  const viewAllFavorite = () => {
    history.push("/community/favorite-discussions");
  };

  return (
    <>
      <div
        className={`block allblock ${
          scrollPosition < setposSubscribtion - 60 ? "" : "transparenceDiv"
        }`}
        id="fixWhenScroll"
      >
        <h3 className="title">Favorite discussions</h3>
        <ul className="listDiscussion">
          {data?.length > 0 &&
            data.map((e: SubscribedDiscussion, index: number) => (
              <li key={index}>
                <a href="javascript:;" onClick={() => goToreplayPost(e)}>
                  <span className="icon-discussion" />
                  {e.title}
                </a>
              </li>
            ))}
        </ul>
        <a
          href="javascript:;"
          className="text-center seeAll"
          onClick={(e) => {
            viewAllFavorite();
          }}
        >
          See all
          <span className="icon-back" />
        </a>
      </div>
      <div
        className={`block allblock secondBlock ${
          scrollPosition < setposSubscribtion - 60 ? "" : "showopacity"
        }`}
      >
        <h3 className="title">Favorite discussions</h3>
        <ul className="listDiscussion">
          {data?.length > 0 &&
            data.map((e: SubscribedDiscussion, index: number) => (
              <li key={index}>
                <a href="javascript:;" onClick={() => goToreplayPost(e)}>
                  <span className="icon-discussion" />
                  {e.title}
                </a>
              </li>
            ))}
        </ul>
        <a
          href="javascript:;"
          className="text-center seeAll"
          onClick={(e) => {
            viewAllFavorite();
          }}
        >
          See all
          <span className="icon-back" />
        </a>
      </div>
    </>
  );
}
