import { useState } from "react";
import { useHistory } from "react-router";
import useEventListener from "../../../hooks/event-handler";

type IPropsSearch = {
    searchText?: string,
    handleChange?: (a:string)=>void,
    handleClick?: ()=>void,
}

export default function UseHeaderClassifiedAds(props : IPropsSearch){
    const history = useHistory();
    const [searchText, setSearchText] = useState<string>("");

    const handleChange = (text:string)=>{
        setSearchText(text);
    };

    const handleClick = () => {
        history?.push("/classified-ads/results",{
            inputText : searchText,
        });
    };

    const handleEnterClick = ({key}:any) => {
        if (key == "Enter") {
            let text = props?.searchText? props?.searchText : searchText;
            if (text?.length>0) {
                props?.handleClick? props?.handleClick() : handleClick();
            }
          } else return;
    }

    useEventListener("keydown", handleEnterClick);

    return {
        handleChange : props?.handleChange? props?.handleChange : handleChange,
        handleClick : props?.handleClick? props?.handleClick : handleClick,
        searchText : props?.searchText? props?.searchText : searchText,
    }
}