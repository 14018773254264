// import Discussion from '../screen/container/discussion/discussion';
import Conversation from '../screen/container/tchat-screen/index';

export const discussionRoute = [
  // {
  //   path: '/discussion',
  //   guard: 'public',
  //   exact: true,
  //   component: Discussion,
  // },
  {
    path: '/contact/discussion',
    guard: 'private',
    exact: true,
    component: Conversation,
  },
];
