import React from "react";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import { useHistory } from "react-router";
import Footer from "../../../../widget/footer/footer";
export default function StartContributor() {
  const history = useHistory();

  const completeContributor = () => {
    history.push("/complete-info-expert");
  };

  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <h1>Create your account.</h1>
        </div>
        <div className="contentBorder border-top border-bottom stepfinal">
          <p>
            {/* Dev #47039 [Expert Contributor] retirer une phrase du texte, lors de la création d'un compte. */}
            Fill out the following form to submit your expert contributor
            application for approval.
            <br />
            Your application will be reviewed for compliance with our{" "}
            <a
              style={{ color: "#46ACD3" }}
              onClick={() => window.open("/privacy-policy", "_blank")}
            >
              platform policy
            </a>
            .{" "}
            {/*and{" "}
            <a
              style={{ color: "#46ACD3" }}
              onClick={() => window.open("privacy", "_blank")}
            >
              Review guidelines
            </a>*/}
            <br /> Please ensure the correct information is entered to avoid any
            bottlenecks during the application process.
          </p>
        </div>
        <div className="contentBorder border-top row align-items-stretch">
          <button
            className="btn btn-devinsider large"
            onClick={() => completeContributor()}
          >
            Let's start!
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
