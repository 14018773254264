import React from "react";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";

export default function ModelLicensing(
  props: T.ILicencingAndDistributionChannelModelEdit
) {
  return (
    <div className="bodyCreateCompany">
      <div className="contentBorder row">
        <div className="form-group required m-0 col-12">
          <label htmlFor="">Licensing model</label>
          <span className="selectalloverstyle">
            {props?.isHaveError && (
              <div className="container-error pl-2 d-inline">
                <span className="error-red"> error </span>
                <span className="txt-error">select al least one category </span>
              </div>
            )}
          </span>
        </div>
        <div className="col-12 forStep5custom p-2">
          {props.licengingModelData &&
            props.licengingModelData.map((el: T.Tchannel, index: number) => (
              <div
                className="custom-control custom-checkbox"
                key={index}
                onClick={() => props.addLicencing(el.id)}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={el.id.toString()}
                  checked={
                    props.licenCingModelSelected &&
                    props.licenCingModelSelected?.find(
                      (it: number) => it == el.id
                    )
                      ? true
                      : false
                  }
                  onChange={() => null}
                />
                <label htmlFor="c1" className="custom-control-label">
                  {el.name}
                </label>
              </div>
            ))}
        </div>
      </div>
      <div className="contentBorder row">
        <div className="form-group required m-0 col-12">
          <label htmlFor="">Distribution channel</label>
          <span className="selectalloverstyle">
            {/* {props?.isHaveError && (
              <div className="container-error pl-2 d-inline">
                <span className="error-red"> error </span>
                <span className="txt-error">select al least one category </span>
              </div>
            )} */}
          </span>
        </div>
        <div className="col-12 forStep5custom p-2">
          {props.distributionModelData &&
            props.distributionModelData.map((el: T.Tchannel, index: number) => (
              <div
                className="custom-control custom-checkbox"
                key={index}
                onClick={() => props.addDistributionChannel(el.id)}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={el.id.toString()}
                  checked={
                    props.distributionModelSelected &&
                    props.distributionModelSelected?.find(
                      (it: number) => it == el.id
                    )
                      ? true
                      : false
                  }
                  onChange={() => null}
                />
                <label htmlFor="c1" className="custom-control-label">
                  {el.name}
                </label>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
