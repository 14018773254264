import React, { useState, useEffect } from "react";
import useSF from "../../../screen/widget/software-solution/use-SF";
import { rootState } from "../../../redux/reducers";
import { OemProgram, OemProgramDraft, OemProgramReducer } from "../type";
import { useDispatch, useSelector } from "react-redux";

export default function UseProgramSegment() {
  const oemProgramReducer: OemProgramReducer = useSelector(
    (state: rootState) => state.oemProgramReducer
  );

  const oemProgram: OemProgram = oemProgramReducer.program;

  const oemProgramSegments = oemProgramReducer?.draft?.oemProgramTypeCategory
    ? oemProgramReducer?.draft?.oemProgramTypeCategory
    : oemProgram?.oem_program_type_category;

  const [valuesolutionChecked, setValuesolutionChecked] = useState<
    Array<{ id: number; name: string }>
  >([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [index1, setindex1] = useState<number>(6);

  const [valueFeaturedsolutionChecked, setValueFeaturedsolutionChecked] =
    useState<Array<{ id: number; name: string }>>([]);

  const {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
  } = useSF({
    defaultValue: valuesolutionChecked,
    defaultValueFeatured: valueFeaturedsolutionChecked,
  });

  useEffect(() => {
    setValuesolutionChecked(oemProgram?.segments);
    setValueFeaturedsolutionChecked(oemProgram?.segment_details);
    return () => {};
  }, [oemProgram?.segments, oemProgram?.segment_details]);

  return {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    loader,
    index1,
    setindex1,
    solutionList: oemProgramSegments,
  };
}
