import { Label } from "../../../interface/interface-acount/index";
import {
  createUser,
  getInfoUser,
  getInterseted,
  signin,
  updateInfoUser,
  updateMyEmail,
} from "../../../service/applicatif/acount";
import {
  getDataService,
  postDataFormDataService,
  postJsonDataService,
} from "../../../service/applicatif/back-end-service";
import { getUserMembersProgram } from "../../../service/applicatif/oem";
import { url } from "../../../service/constant";
import store from "../../store";
import { Dispatch } from "../../types/acount-interface";
export const CREATE_USER = "CREATE_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_LODING = "LOGIN_LODING";
export const SIGNIN = "SIGNIN";
export const UPDATE_USER = "UPDATE_USER";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const UPDATE_EMAIL_USER = "UPDATE_EMAIL_USER";
export const UPDATE_INFO_USER = "UPDATE_INFO_USER";
export const UPDATE_OEM_PROGRAM_INFO = "UPDATE_OEM_PROGRAM_INFO";
export const ADD_NEW_OEM_PROGRAM = "ADD_NEW_OEM_PROGRAM";
export const UPDATE_LOGO_GUEST = "UPDATE_LOGO_GUEST";
export const UPDATE_EXPERT_OVERVIEW = "UPDATE_EXPERT_OVERVIEW";
export const DELETEUSER = "DELETEUSER";
export const UPDATE_LOGO_EXPERT = "UPDATE_LOGO_EXPERT";
export const UPDATE_OEM_PROGRAM_ROLE = "UPDATE_OEM_PROGRAM_ROLE";
export const HANDLE_COMMUNITY_PROFILE = "HANDLE_COMMUNITY_PROFILE";
//Bug #47385 delete profile photo
export const REMOVE_PHOTO_PROFILE_USER = "REMOVE_PHOTO_PROFILE_USER";
export const UPDATE_PROGRAM_LIST = "UPDATE_PROGRAM_LIST";

const removePhotoProfileUser = (payload: string) => {
  return {
    type: REMOVE_PHOTO_PROFILE_USER,
    payload: payload,
  };
};

const createUserAction = (user: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await createUser(user);
      if (result) {
        dispatch({
          type: CREATE_USER,
          payload: result,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error.response);
    }
  };
};

const signinAction =
  (data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
      let response = await signin(data?.email, data?.password);
      if (response && response.status == 200) {
        let token = response?.data?.token;
        let token_mercure = response?.data?.notification;
        let refreshToken = response?.data?.refresh_token;
        let resultSigning = await getInfoUser(token);
        let userObject = {
          ...resultSigning.data,
          token,
          refreshToken,
          token_mercure,
        };
        dispatch({
          type: SIGNIN,
          payload: userObject,
        });
        callback(resultSigning);
      } else {
        callback(response);
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };

const getInfoUserAction = (callback: any) => async (dispatch: Dispatch) => {
  try {
    let resultSigning = await getInfoUser(store.getState().userReducer.token);
    let userObject = {
      ...resultSigning.data,
      token: store.getState().userReducer.token,
    };
    dispatch({
      type: UPDATE_INFO_USER,
      payload: userObject,
    });
    callback(resultSigning);
  } catch (error: any) {
    callback(error?.response);
  }
};

const updateOemprogramName = (data: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: UPDATE_OEM_PROGRAM_INFO,
      payload: data,
    });
  } catch (error: any) {}
};

const addNewOemProgram = (data: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: ADD_NEW_OEM_PROGRAM,
      payload: data,
    });
  } catch (error: any) {}
};

const updateInfoUserAction =
  (data: any, callback: any) => async (dispatch: Dispatch) => {
    if (data?.userCommunityProfil) {
      dispatch({
        type: HANDLE_COMMUNITY_PROFILE,
        payload: data?.userCommunityProfil,
      });
    }
    try {
      let response = await updateInfoUser(
        data,
        store && store.getState().userReducer.token
      );

      if (response && response.status === 200) {
        dispatch({
          type: UPDATE_USER,
          payload: response?.data,
        });
        callback(true);
      } else {
        callback(false);
      }
    } catch (error) {}
  };

const updateExperInfoCompany =
  (data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url.acount.update_expert_overview,
        data,
        store && store.getState().userReducer.token
      );

      if (response && response.status === 200) {
        dispatch({
          type: UPDATE_EXPERT_OVERVIEW,
          payload: response?.data,
        });
        callback(true);
      } else {
        callback(false);
      }
    } catch (error) {}
  };

const updateLogoExpert =
  (data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
      let response = await postDataFormDataService(
        data,
        url.acount.update_logo_expert,
        store && store.getState().userReducer.token
      );
      //Same result for UPDATE_EXPERT_OVERVIEW and i use a same actio type
      if (response && response.status === 200) {
        dispatch({
          type: UPDATE_EXPERT_OVERVIEW,
          payload: response?.data,
        });
        callback(true);
      } else {
        callback(false);
      }
    } catch (error) {}
  };

const getInterestedAction = (cb: (data: Array<Label>) => void) => {
  return async (dispatch: Dispatch) => {
    let result = await getInterseted();
    cb(result);
    return result;
  };
};

const updateEmailUser = (
  data: { email: string; token: string; password: string },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await updateMyEmail(
        data,
        store.getState().userReducer.token
      );
      if (response && response.status == 200) {
        dispatch({
          type: UPDATE_EMAIL_USER,
          payload: response.data,
        });
        callback(true);
      } else {
        callback(false);
      }
    } catch (error) {
      callback(false);
    }
  };
};

const logout = (cb: (params: boolean) => void) => {
  return async (dispatch: Dispatch) => {
    let data: any = "logout";
    dispatch({
      type: LOGOUT,
      payload: data,
    });
    cb(true);
    return;
  };
};

const updateTokenAndRefreshTokenAction = (
  data: any,
  cb: (params: boolean) => void
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REFRESH_TOKEN,
      payload: data,
    });
    cb(true);
    return;
  };
};

const updateGuestLogo = (
  params: { logo: File },
  token: string,
  cb: (params: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postDataFormDataService(
        params,
        url?.acount?.update_logo_guest,
        token
      );
      if (result?.status === 200) {
        dispatch({
          type: UPDATE_LOGO_GUEST,
          payload: result?.data,
        });
        cb(true);
      }
    } catch (error) {
      cb(error);
    }
  };
};

const updateOemRoleListe = () => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url?.acount?.get_oem_program_list,
        {},
        store.getState().userReducer.token
      );
      if (result?.status === 200) {
        const updateDataListe = result?.data?.map((el: any) => {
          const item = {
            role: el?.role,
            oem_program: {
              ...el,
            },
          };
          return item;
        });
        dispatch({
          type: UPDATE_OEM_PROGRAM_ROLE,
          payload: updateDataListe,
        });
      }
    } catch (error) {}
  };
};

const updateOemProgramList = () => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url?.acount?.get_oem_program_list,
        {},
        store.getState().userReducer.token
      );
      if (result?.status === 200) {
        const updateDataListe = result?.data?.map((el: any) => {
          const item = {
            role: el?.role,
            oem_program: {
              ...el,
            },
          };
          return item;
        });
        dispatch({
          type: UPDATE_PROGRAM_LIST,
          payload: updateDataListe,
        });
      }
    } catch (error) {}
  };
};

export {
  addNewOemProgram,
  createUserAction,
  getInfoUserAction,
  getInterestedAction,
  logout,
  removePhotoProfileUser,
  signinAction,
  updateEmailUser,
  updateExperInfoCompany,
  updateGuestLogo,
  updateInfoUserAction,
  updateLogoExpert,
  updateOemProgramList,
  updateOemRoleListe,
  updateOemprogramName,
  updateTokenAndRefreshTokenAction,
};
