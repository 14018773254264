import { useHistory, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import {
  adsFavorite,
  deleteAds,
  getAdsSegmentDetails,
  getClassifiedAdsDetail,
} from '../../../service/applicatif/classified-ads';
import { AdsAttributeEnum, AdsDetail, AdsEntityTypeEnum } from './type';
import {
  conversationType,
  getAdsEntityTypeByRole,
  isModerator,
} from '../../../utils';
import { GUEST, MODERATOR, url } from '../../../service/constant';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import { toast } from 'react-toastify';
import { getData } from '../../../service/api';
import { checkPasswordUser } from '../../../service/applicatif/acount';

export default function useDetailAds() {
  const params: any = useParams();
  const history = useHistory();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [ads, setAds] = useState<AdsDetail | null>(null);
  const [loadingAdds, setLoadingAdds] = useState<boolean>(false);

  const [software, setSoftware] = useState<Array<{ id: number; name: string }>>(
    []
  );
  const [industry, setIndustry] = useState<Array<{ id: number; name: string }>>(
    []
  );
  const [adsUserRole, setAdsUserRole] = useState<string>('');

  const [softwareCount, setSoftwareCount] = useState<number>(6);
  const [industryCount, setIndustryCount] = useState<number>(6);
  const [industryLoader, setIndustryLoader] = useState<boolean>(true);
  const [softwareLoader, setSoftwareLoader] = useState<boolean>(true);
  const [isAdsFavorite, setIsAdsFavorite] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [loaderButton, setLoaderButton] = useState<boolean>(false);

  const [companyInfos, setCompanyInfos] = useState<any>(null);
  const [showConfirmPasswordModerator, setShowConfirmPasswordModerator] =
    useState<boolean>(false);

  const urlPath = window.location.href;

  const seeMore = (state: any, count: number) => state(count);

  const seeLess = (state: any) => state(6);

  const favorite = async () => {
    if (userReducer?.token === '') {
      return setShowLogin(true);
    }
    const favorite = await adsFavorite(ads!.id, userReducer?.token);
    setIsAdsFavorite(favorite);
  };

  const sendMessage = () => {
    if (userReducer?.token === '') {
      return setShowLogin(true);
    }

    if (isOwner) {
      return history.push('/contact/discussion');
    } else {
      return history.push('/contact/discussion', {
        userId: ads?.id,
        discussionType: conversationType.entity_classific_adds,
      });
    }
  };

  const goEdit = () => {
    history?.push('/classified-ads/post', {
      params: ads?.classified_ads_type,
      ad: ads,
    });
  };

  const callback = (data: any) => {
    if (data?.status === 204) {
      setLoaderButton(false);
      toast('The Ads is successfully removed ');
      isModerator(userReducer.roles)
        ? history?.push('/classified-ads/home')
        : history?.push('/classified-ads/listing');
    }
  };

  const deletePost = async () => {
    setLoaderButton(true);
    await deleteAds(ads ? ads?.id?.toString() : '-1', callback);
  };

  useEffect(() => {
    setIsOwner(Boolean(ads?.user?.id === userReducer?.id));

    const getInfoUser = async (userId: any) => {
      const response = await getData(
        url.community.activity.get_information_company + userId,
        userReducer.token
      );
      if (response?.status === 200) {
        setCompanyInfos(response?.data);
      }
    };

    console.log('adsadsadsads', ads);

    if (ads?.user?.id) getInfoUser(ads?.user?.id);
  }, [ads?.user?.id]);

  useEffect(() => {
    (async () => {
      setLoadingAdds(true);
      const res: AdsDetail = await getClassifiedAdsDetail(
        params?.id,
        userReducer?.token
      );
      setLoadingAdds(false);
      setAds(res);
      setIsAdsFavorite(res?.liked);

      const role = res?.user?.roles?.[0];

      setAdsUserRole(role);

      const entityType: AdsEntityTypeEnum = getAdsEntityTypeByRole(role);

      if (role !== GUEST) {
        const segment = await getAdsSegmentDetails(
          res?.page?.id,
          entityType,
          AdsAttributeEnum.SEGMENT_DETAILS
        );

        softwareLoader && setSoftwareLoader(false);

        const allIndustries = await getAdsSegmentDetails(
          res?.page?.id,
          entityType,
          AdsAttributeEnum.TARGET_INDUSTRY
        );

        industryLoader && setIndustryLoader(false);

        if (allIndustries?.status === 200) {
          setIndustry(allIndustries?.data);
        }

        if (segment?.status === 200) {
          setSoftware(segment?.data);
        }
      } else {
        softwareLoader && setSoftwareLoader(false);
      }
    })();
  }, [params?.id]);

  const banUserProcess = () => {
    //function checkPassword user is the same signature as I need, so I'll use this one
    try {
      (async () => {
        let response = await checkPasswordUser(
          url.acount.ban_user,
          {
            user_email: ads?.user?.id,
          },
          userReducer.token
        );
        if (response?.status === 200) {
          toast('The user has been banned successfully from Devinsider!');
          //redirect to current page to see the change
          window?.location?.reload();
        }
      })();
    } catch (error: any) {
      toast(
        'An error occured while processing the operation, try again or contact the administrator. Thanks!'
      );
    }
    setShowConfirmPasswordModerator(false);
  };

  return {
    ads,
    software,
    industry,
    softwareCount,
    setSoftwareCount,
    industryCount,
    setIndustryCount,
    seeMore,
    seeLess,
    adsUserRole,
    industryLoader,
    softwareLoader,
    favorite,
    isAdsFavorite,
    showLogin,
    setShowLogin,
    urlPath,
    sendMessage,
    isOwner,
    goEdit,
    deletePost,
    loaderButton,
    userReducer,
    isUserModerator: userReducer?.roles?.indexOf(MODERATOR) !== -1,
    companyInfos,
    setShowConfirmPasswordModerator,
    showConfirmPasswordModerator,
    banUserProcess: () => banUserProcess(),
    loadingAdds,
  };
}
