/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "react-bootstrap/Modal";
import ClaimStep3 from "./text-info-success";
import * as T from "../../../../../../../controller/company-ctr/types/types-company-page";

export default function SuccessRequestClaim(props: T.TclaimModalTree) {
  return (
    <div>
      {/* Request step 3 */}
      <Modal
        show={props.showRequestStep3}
        className="createCompanypopup smallPopup forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Thank you!</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={props.handleCloseRequestStep3}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight">
          <ClaimStep3 email={props?.email} />
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail ">
            <div className="haveQuestion text-center">
              Have any questions?&nbsp;
              <a href="javascript:;" onClick={() => props?.showContactUs()}>
                Contact us
              </a>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* end Request  step 3*/}
    </div>
  );
}
