/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from 'react';
import { IPropsRessource, IDataResource } from '../types';
import ModalEditRessource from '../../../../widget/modal-edit-ressources/index';
import { getExtensionFileName } from '../../../../../utils';
import baseUrl from '../../../../../service/constant';
export default function InfoResource(props: IPropsRessource) {
  // Dev #46476 V1 [Investor] Ameliorations concernant les texts explicatifs de la 'investor page'
  const haveResources = (() =>
    props?.fileDataSource?.filter(
      (resource: any) =>
        resource?.file_name !== '' && resource?.file_location !== ''
    )?.length > 0)();

  const filterdEmptyPreview = () => {
    return props?.fileDataSource?.filter(
      (resource: any) =>
        resource?.file_name !== ''
    );
  };


  const ResourcePage = () => (
    <>
      <article className='investorArticle bgGris'>
        <div className='mx-auto container cont3'>
          {props.isShowRessounce && (
            <div className='contentLeftInvestor'>
              <div className='formForEmail companyInvestorbtnctt editShow'>
                {!props?.isEdit && (
                  <a
                    className='linkEditLeftCompany'
                    onClick={() => props?.clickEdit()}
                  >
                    Edit<span className='icon-edit-full'></span>
                  </a>
                )}
              </div>
              <h3 className='title'>Resources</h3>
              {/* // Dev #46476 V1 [Investor] Ameliorations concernant les texts explicatifs de la 'investor page' */}
              {!props?.isPreview &&
                (props?.isEditMode || haveResources === false) && (
                  <p> Provide additional documentation (optional).</p>
                )}
              <ul className='listSquareItem resource grisbg'>
                {
                  (props.isPreview || props.isOtherUser) ? filterdEmptyPreview()?.map(
                    (el: IDataResource, index: number) => {
                      if (el?.file_name !== '') {
                        return (
                          <li key={index}>
                            {el?.file_name && props?.isEdit && (
                              <a
                                className='removeImg'
                                onClick={() => props.removeFile(el?.id, index)}
                              >
                                <span className='icon-close-pop'></span>
                              </a>
                            )}

                            <label>
                              <input
                                type='file'
                                accept='.doc,.docx,.pdf'
                                style={{ display: 'none' }}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  props.setFileResource(event, index);
                                }}
                                disabled={!props?.isEdit || el?.file_name !== ''}
                              />
                              <a
                                className={`${el?.file_name ? '' : 'empty'} `}
                                onClick={() => {
                                  if (el?.file_location !== '') {
                                    window
                                      ?.open(
                                        baseUrl.baseUrl + '/' + el?.file_location,
                                        '_blank'
                                      )
                                      ?.focus();
                                  }
                                  return;
                                }}
                              >
                                <div className='typeDoc'>
                                  <span
                                    className={getExtensionFileName(el.file_name)}
                                  ></span>
                                </div>
                                <div className='titleDoc'>
                                  {el?.file_name?.split('.')[0]}
                                </div>
                              </a>
                            </label>
                          </li>
                        );
                      }
                    }
                  ) : props?.fileDataSource?.map(
                    (el: IDataResource, index: number) => {
                      return (
                        <li key={index}>
                          {el?.file_name && props?.isEdit && (
                            <a
                              className='removeImg'
                              onClick={() => props.removeFile(el?.id, index)}
                            >
                              <span className='icon-close-pop'></span>
                            </a>
                          )}

                          <label>
                            <input
                              type='file'
                              accept='.doc,.docx,.pdf'
                              style={{ display: 'none' }}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                props.setFileResource(event, index);
                              }}
                              disabled={!props?.isEdit || el?.file_name !== ''}
                            />
                            <a
                              className={`${el?.file_name ? '' : 'empty'} `}
                              onClick={() => {
                                if (el?.file_location !== '') {
                                  window
                                    ?.open(
                                      baseUrl.baseUrl + '/' + el?.file_location,
                                      '_blank'
                                    )
                                    ?.focus();
                                }
                                return;
                              }}
                            >
                              <div className='typeDoc'>
                                <span
                                  className={getExtensionFileName(el.file_name)}
                                ></span>
                              </div>
                              <div className='titleDoc'>
                                {el?.file_name?.split('.')[0]}
                              </div>
                            </a>
                          </label>
                        </li>
                      );
                    }
                  )
                }
              </ul>
              <ModalEditRessource {...props} />
            </div>
          )}
        </div>
      </article>
    </>
  ) 
  
  const UnlockedResource = () => (
    <>
      <article className='investorArticle bgGris'>
        <div className='mx-auto container cont3'>
          <div className='contentLeftInvestor'>
            <h3 className='title'>Resources </h3>

            <ul className='listSquareItem resource grisbg'>
            {props?.fileDataSource?.map(
              (el: IDataResource, index: number) => {
                if (el?.file_name !== '') {
                  return (
                    <li className='resourcesLocked' key={index}>
                    {' '}
                  </li>
                  );
                }
              }
            )}
            </ul>
          </div>
        </div>
      </article>
    </>
  )


  

  return (
    <>
      {props.isPreview && props?.isLockedPage ? UnlockedResource() : props.isPreview && !props?.isLockedPage ? ResourcePage() : <></>}
      {!props.isPreview && ResourcePage()}
    </>
  );
}
