import React, { ChangeEvent } from "react";
import ModalEditRessource from "../../../../widget/modal-edit-ressources/index";
import { getExtensionFileName } from "../../../../../utils";

type IProps = {
  fileDataSource: any;
  isOtherUserType: any;
  showModalEditRessource: () => void;
  isPreview: any;
  isEdit: any;
  removeFile: (p: any, index: any) => void;
  setFileResource: (event: any, index: any) => void;
  closeRessourceModal: () => void;
  loader: boolean;
  isShowRessource: boolean;
  saveUpdate: () => void;
  isEmptyFile: boolean;
};
export default function OemResources(props: IProps) {
  return (
    <>
      {props.isOtherUserType && props.isEmptyFile ? (
        <></>
      ) : (
        <article className="investorArticle bgGris">
          <div className="mx-auto container cont3">
            <div className="contentLeftInvestor">
              <a
                className="linkEditLeftCompany"
                onClick={() => props.showModalEditRessource()}
              >
                Edit
                <span className="icon-edit-full" />
              </a>

              <h3 className="title">Resources </h3>
              {!props.isPreview && (<p>Provide additional documentation about your program.</p>)}

              <ul className="listSquareItem resource grisbg">
                {props?.fileDataSource?.map((el: any, index: number) =>
                  props.isPreview &&
                  el?.file_location === "" &&
                  el?.file_name === "" ? (
                    <></>
                  ) : (
                    <li key={index}>
                      {el?.file_name && props?.isEdit && (
                        <a
                          className="removeImg"
                          onClick={() => props.removeFile(el?.id, index)}
                        >
                          <span className="icon-close-pop"></span>
                        </a>
                      )}

                      <label>
                        <input
                          type="file"
                          accept=".doc,.docx,.pdf"
                          style={{ display: "none" }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            props.setFileResource(event, index);
                          }}
                          disabled={!props?.isEdit || el?.file_name != ""}
                        />
                        <a
                          target="_blank"
                          className={`${el?.file_name ? "" : "empty"} `}
                        >
                          <div className="typeDoc">
                            <span
                              className={getExtensionFileName(el.file_name)}
                            ></span>
                          </div>
                          <div className="titleDoc">
                            {el?.file_name?.split(".")[0]}
                          </div>
                        </a>
                      </label>
                    </li>
                  )
                )}
              </ul>

              <ModalEditRessource
                closeRessourceModal={() => props?.closeRessourceModal()}
                fileDataSource={props?.fileDataSource}
                fileRessourceEdit={props?.fileDataSource}
                setFileResource={(file: any, index: number) =>
                  props?.setFileResource(file, index)
                }
                loader={props?.loader}
                removeFile={(id: number, index: number) =>
                  props?.removeFile(id, index)
                }
                isShowRessource={props?.isShowRessource}
                saveUpdate={() => props?.saveUpdate()}
              />
            </div>
          </div>
        </article>
      )}
    </>
  );
}
