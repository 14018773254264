import React, { ChangeEvent, useEffect, useState } from "react";
import Header from "../../../widget/index";
import Select from "react-select";

import BlockFilter from "../bloc-filter/filter-matching-panel";
import { LoadingMatchProgram } from "../../../widget/loader";
import UseProviderMatching from "../../../../controller/program-ctr/program-matchmaking-ctr/bloc-matching-panel/use-matching-panel-ctr";
import Pagination from "../../../widget/use-pagination/pagination";
import { changeNumberValue } from "../../../../utils";
import ItemListe from "./bloc-matching-panel/item-liste";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { Modal } from "react-bootstrap";
/* TICKETS PROD: REMOVE FOR V2*/
import photoAddCompany from "../../../../resources/images/addCompany.png";

export default function ThreeCase() {
  /* TICKETS PROD: REMOVE FOR V2*/
  const [showModal, setShowModal] = useState(true);
  /* TICKETS PROD: REMOVE FOR V2*/

  // Bug #48516,access to matchmaking
  const history = useHistory();
  const reduxStore = useSelector((rootState: rootState) => rootState);
  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  const providerMatching = UseProviderMatching();

  (() => {
    const haveCompanyPage = (() =>
      providerMatching.isIsv &&
      reduxStore.userReducer.isvInCompanyPage !== null)();
    if (haveCompanyPage === false) history.push("/program/matchmaking");
  })();

  if (!providerMatching.isIsv) {
    return <Redirect to={"/profil-not-found"} />;
  }

  return (
    <div className="findIsvcontainer threeCaseContainer">
      <Header />
      {/* BEGIN TO UNCOMMENT FOR V2 */}
      {/* <BlockFilter
        setSelectedType={(id: string) => providerMatching?.handleFilterType(id)}
        selectedType={providerMatching?.programType}
        selectedCountry={providerMatching?.countries}
        setSelectedCountry={(idCountry: string) =>
          providerMatching?.handleFilterCountry(idCountry)
        }
        selectedProgramType={providerMatching?.segmentsDetails}
        setSelectedProgramType={(id: string) =>
          providerMatching?.handleFilterProgramSolution(id)
        }
        lunchFilter={() => providerMatching?.lunchFilter()}
        resetFilter={() => providerMatching?.resetFilter()}
        sliderValue={providerMatching?.sliderValue}
        setSliderValue={(value: Array<number>) =>
          providerMatching?.setSliderValue(value)
        }
        type={"matching"}
      /> */}
      {/* END TO UNCOMMENT FOR V2 */}

      {/* <div className="container mx-auto minHeightwin">
        <div className="listOfIsv">
          <div className="headerOfList">
            <div className="leftHead">
              <div className="number">
                Showing{" "}
                {!providerMatching?.loaderMatching && (
                  <>
                    {" "}
                    <b>
                      {changeNumberValue(
                        providerMatching?.allProgram?.totalDataPaginate
                      )}
                    </b>{" "}
                    Programs
                  </>
                )}
              </div>
              <form
                onClick={(e) => openBlock(e)}
                onSubmit={(e) => {
                  e?.preventDefault();
                }}
              >
                <input
                  placeholder="Search..."
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    providerMatching?.setSearch_name(e?.target?.value)
                  }
                  value={providerMatching?.search_name}
                  onBlur={() =>
                    providerMatching?.search_name?.length <= 0 &&
                    providerMatching?.handleSearche()
                  }
                />
              </form>
            </div>
            <div className="rightHead">
              <div className="sortedby labelSelect">
                <label>Sorted by :</label>
                <Select
                  className="triOreder"
                  isClearable={false}
                  options={providerMatching?.filtered}
                  classNamePrefix="tri"
                  defaultValue={providerMatching?.filtered[0]}
                  onChange={(filter: any) =>
                    providerMatching?.handleFilter(filter)
                  }
                />
              </div>
            </div>
          </div>
          <div className="scrollContainer">
            {providerMatching?.loaderMatching ? (
              <LoadingMatchProgram />
            ) : (
              <ItemListe
                allProgram={providerMatching?.allProgram}
                addFavorite={providerMatching?.addFavorite}
                showProgramPage={(id: number) =>
                  providerMatching?.showProgramPage(id)
                }
              />
            )}
          </div>

          <div className="pagginationCategory fixePagination">
            <div className="pagination">
              <Pagination
                className="pagination"
                currentPage={providerMatching?.allProgram?.currentPage}
                totalCount={providerMatching?.allProgram?.totalDataPaginate}
                pageSize={20}
                onPageChange={(page: any) =>
                  providerMatching?.loadMoreProgram(page)
                }
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* TICKETS PROD: REMOVE FOR V2*/}
      <div className="NotFoundContainer">
        <div className="container mx-auto">
          <div className="contentBorder">
            <h3 className="title">4 0 4</h3>
            <div className="image">
              <img src={photoAddCompany} alt="" />
            </div>
            <div className="text-center sTitle">
              <p>Oops! page not found</p>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} className="small forDesactivate popPers">
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Find ISVs</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => setShowModal(false)}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            <p>
              We are currently on-boarding OEM Programs for matchmaking and will
              release this feature soon. In the meantime check out <br />
              the{" "}
              <a
                href="javascript:;"
                onClick={() => history.push("/community/home")}
              >
                community
              </a>{" "}
              where you can engage with OEM Programs and share your expertise.
            </p>
          </div>
        </Modal.Body>
      </Modal>
      {/* TICKETS PROD: REMOVE FOR V2*/}
    </div>
  );
}
