import React, { ChangeEvent } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as Loader } from '../../../../../../resources/images/Rolling.svg';

export type IProps = {
  isShowPitchPoint: boolean;
  setIsShowPitchPoint: (p: boolean) => void;
  pitchPointValueForm: string;
  setPitchPointValueForm: (t: string) => void;
  loader: boolean;
  updatePicth: () => void;
};
export default function ModalEditPainPoint(props: IProps) {
  return (
    <Modal
      show={props.isShowPitchPoint}
      className='createCompanypopup large forStep5custom'
    >
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>Pain point</h2>
          <Link
            to='#'
            className='close'
            onClick={() => props.setIsShowPitchPoint(false)}
          >
            <span className='icon-close-pop'></span>
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body className=''>
        <div className='contentBorder row forPitch'>
          <div className='form-group col-12'>
            <label htmlFor=''>What pain point are you solving? </label>
          </div>
          <div className='form-group col-12 descCompany m-0'>
            <textarea
              maxLength={2000}
              value={props.pitchPointValueForm}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                props.setPitchPointValueForm(event.target.value)
              }
            />
            <span className='caractercount'>
              {0 + (props.pitchPointValueForm?.length || 0) + ' / 2000'}
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='contentBorder formForEmail text-right forPitch'>
          <button
            className='btn btn-devinsider cancel'
            onClick={() => props.setIsShowPitchPoint(false)}
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn btn-devinsider px-5'
            onClick={() => props.updatePicth()}
          >
            {props.loader ? <Loader /> : 'Save'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
