import "./ActivityUser.scss";
import React from "react";
import Header from "../../../widget/index";
import Footer from "../../../widget/footer/footer";
import ActivityUserCtr from "../../../../controller/community-ctr/activity-user";

import UserInformation from "./user-information";
import LikesFrom from "./likes-from";
import LikesGiven from "./likes-given";
import UserContribution from "./contribution-user";
import ActivityAboutUser from "./about";
import PostsByUser from "./posts-by-user";
import RepliesByUser from "./replies-by-user";
import BestAnswerByUser from "./best-answers-by-user";
import BreadCrumbsUser from "./breadcrumbs-user";
import SearchForm from "../../../widget/community/search/index";
import { LoadingAllPage } from "../../../widget/loader";

export default function ActivityUser() {
  const providerActivityUser = { ...ActivityUserCtr() };

  return (
    <div>
      <Header />
      <section className="discussion">
        {providerActivityUser?.loadingFetchUser && <LoadingAllPage />}

        <div className="container mx-auto">
          <>
            <BreadCrumbsUser user={providerActivityUser?.user} />
          </>
          <div className="searchTopic">
            <SearchForm
              categories={
                providerActivityUser?.communityReducer?.discussionCategories
              }
              filterSearchText={providerActivityUser?.filterSearchText}
              handleFilterTxt={providerActivityUser?.handleFilterTxt}
              searchText={providerActivityUser?.inputText}
              handleCateGory={providerActivityUser?.handleCateGory}
            />
          </div>
          <div className="rightCommunityForum allWidth oneCateg">
            <div className="labelLeft activityProfil">
              <UserInformation
                user={providerActivityUser?.user}
                userId={providerActivityUser?.userId}
              />
              <div className="block">
                <LikesFrom userId={providerActivityUser?.userId} />
              </div>
              <div className="block">
                <LikesGiven userId={providerActivityUser?.userId} />
              </div>
            </div>
            <div className="containerCategory">
              <UserContribution
                userId={providerActivityUser?.userId}
                roleCurrentUser={providerActivityUser.role}
                roleUserShowInActivity={providerActivityUser.user?.roles}
              />
              <div className="contactInfoItem">
                <ActivityAboutUser
                  userId={providerActivityUser?.userId}
                  userShowActivity={providerActivityUser.user}
                />
              </div>
              <div className="blockOfList" id="postByUser">
                <PostsByUser
                  userId={providerActivityUser?.userId}
                  user={providerActivityUser?.user}
                  getBadge={providerActivityUser?.getBadge}
                  showDiscussion={(post: any) =>
                    providerActivityUser?.goToreplayPost(post)
                  }
                  showLabel={(
                    categoryId: string,
                    labelId: string,
                    postType: number
                  ) =>
                    providerActivityUser?.showLabel(
                      categoryId,
                      labelId,
                      postType
                    )
                  }
                  roleCurrentUser={providerActivityUser.role}
                  roleUserShowInActivity={providerActivityUser.user?.roles}
                />
              </div>
              <div className="blockOfList" id="repliesByUser">
                <RepliesByUser
                  userId={providerActivityUser?.userId}
                  user={providerActivityUser?.user}
                  getBadge={providerActivityUser?.getBadge}
                  showDiscussion={(post: any) =>
                    providerActivityUser?.goToreplayPost(post)
                  }
                  showLabel={(
                    categoryId: string,
                    labelId: string,
                    postType: number
                  ) =>
                    providerActivityUser?.showLabel(
                      categoryId,
                      labelId,
                      postType
                    )
                  }
                />
              </div>
              <div className="blockOfList" id="bestAnswerByUser">
                <BestAnswerByUser
                  userId={providerActivityUser?.userId}
                  user={providerActivityUser?.user}
                  getBadge={providerActivityUser?.getBadge}
                  showDiscussion={(post: any) =>
                    providerActivityUser?.goToreplayPost(post)
                  }
                  showLabel={(
                    categoryId: string,
                    labelId: string,
                    postType: number
                  ) =>
                    providerActivityUser?.showLabel(
                      categoryId,
                      labelId,
                      postType
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
