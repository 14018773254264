import Myevents from '../screen/container/events/myevents';
import Allevents from '../screen/container/events/allevents';
import NotFound from '../screen/container/not-found/not-found';

export const routesEvents = [
  {
    path: '/events/my-events',
    guard: 'private',
    exact: true,
    component: NotFound, // Myevents,
  },
  {
    path: '/events/all-events',
    guard: 'private',
    exact: true,
    component: NotFound, //Allevents,
  },
  {
    path: '/events/all-event-moderator',
    guard: 'private',
    exact: true,
    component: NotFound, // Myevents,
  },
];
