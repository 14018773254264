import React from "react";
import "./style-footer.css";
import { Container } from "react-bootstrap";
import FooterLink from "../../widget/footer/footer-link";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { useHistory } from "react-router";
import {
  CONTRIBUTOR,
  GUEST,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  linkedInUrl,
} from "../../../service/constant";

type IProps = {
  postType?: string;
};
export default function Footer(props: IProps) {
  const history = useHistory();
  const userReducer = useSelector((state: rootState) => state?.userReducer);
  const constante = {
    NAVIGATE_TO_ISV_PARTNER_PROGRAM: "/partner-program-registration",
    NAVIGATE_TO_GETSTARTED_INVESTOR: "/get-started-inv",
    NAVIGATE_TO_GETSTARTED_RESELLER: "/get-started-reseller",
    NAVIGATE_TO_COMMUNITY: "/community/home",
    NAVIGATE_TO_CLASSIFIED_ADS: "/classified-ads/home",
    NAVIGATE_TO_PROGRAM_DIRECTORY: "/programs/home",
    NAVIGATE_TO_EVENT: "/programs/event/0",
    NAVIGATE_TO_COMMUNITY_GUIDELINES: "/community-guidelines",
    NAVIGATE_TO_TERM_OF_USE: "/term-of-use",
    NAVIGATE_TO_PRIVACY_POLICY: "/privacy-policy",
    NAVIGATE_TO_CONTACT: "/contact/support",
    NAVIGATE_TO_SUPPORT: "/contact/support",
    NAVIGATE_TO_ABOUT: "/about",
    NAVIGATE_TO_MATCHING_PROGRAM: "/programs/program-matching",
    NAVIGATE_TO_OEM_EVENT: "/events/all-events",
    NAVIGATE_TO_ADDS: "/classified-ads/home",
    NAVIGATE_TO_SPONSORS: "/get-started-sponsors",
  };
  const getRole = (roles: Array<string>) => {
    if (roles?.indexOf(ISV) !== -1)
      return { title: "Software vendor", badge: "isv" };
    if (roles?.indexOf(OEM_PROGRAM) !== -1)
      return { title: "Program holder", badge: "oem" };
    if (roles?.indexOf(INVESTOR) !== -1)
      return { title: "Investor", badge: "investor" };
    if (roles?.indexOf(RESELLER) !== -1)
      return { title: "Reseller", badge: "reseller" };
    if (roles?.indexOf(CONTRIBUTOR) !== -1)
      return { title: "Reseller", badge: "expert" };
    return { title: "Guest", badge: "guest" };
  };
  const role = getRole(userReducer?.roles)?.badge;

  let pressLinkConditionsArray = ["isv"];
  let classifiedAdsLinkConditionsArray = [
    "oem",
    "reseller",
    "guest",
    "investor",
    "isv",
  ];
  let programLinkConditionsArray = ["isv"];
  let eventsLinkConditionsArray = ["isv"];

  let showProgram: boolean = programLinkConditionsArray.some(
    (item) => role === item
  );
  let showEvents: boolean = eventsLinkConditionsArray.some(
    (item) => role === item
  );

  const isIsv = userReducer.roles?.[0] === ISV;
  const isOem = userReducer.roles?.[0] === OEM_PROGRAM;
  const isInvetor = userReducer.roles?.[0] === INVESTOR;
  const isReseller = userReducer.roles?.[0] === RESELLER;
  const isGuest = userReducer.roles?.[0] === GUEST;

  const isNotConnected = userReducer.token === "";

  return (
    <div>
      <footer>
        <Container>
          {!props.postType?.toString() && (
            <>
              <ul className="linkfooter">
                <FooterLink text="Company" liclasses="title" />
                <FooterLink text="About" url={constante?.NAVIGATE_TO_ABOUT} />
                <FooterLink
                  text="Contact & support"
                  url={constante?.NAVIGATE_TO_CONTACT}
                />
                {/* <FooterLink
                  text="Sponsors"
                  url={constante?.NAVIGATE_TO_SPONSORS}
                /> */}
              </ul>
              {!isGuest && (
                <ul className="linkfooter">
                  <FooterLink text="Devinsider for..." liclasses="title" />
                  {isNotConnected && (
                    <>
                      <FooterLink
                        text="OEM/Ecosystem partner programs"
                        url={"/partner-program-registration"}
                      />
                      <FooterLink
                        text="Institutional investors"
                        url={constante?.NAVIGATE_TO_GETSTARTED_INVESTOR}
                      />
                    </>
                  )}
                </ul>
              )}

              <ul className="linkfooter">
                <FooterLink text="Resources" liclasses="title" />
                {/* Dev #48040 Guest - Page profile */}
                <FooterLink
                  text={
                    isNotConnected
                      ? "Community"
                      : isGuest
                      ? "Marketplace"
                      : "Community"
                  }
                  url={
                    isNotConnected
                      ? constante?.NAVIGATE_TO_COMMUNITY
                      : isGuest
                      ? constante?.NAVIGATE_TO_CLASSIFIED_ADS
                      : constante?.NAVIGATE_TO_COMMUNITY
                  }
                />

                {(isIsv || isNotConnected) && (
                  <FooterLink
                    text="Program directory"
                    url={constante?.NAVIGATE_TO_PROGRAM_DIRECTORY}
                  />
                )}
                {/* {(isOem || isIsv || isNotConnected) && (
                  <FooterLink
                    text="Events"
                    url={
                      isIsv
                        ? constante?.NAVIGATE_TO_EVENT
                        : constante.NAVIGATE_TO_OEM_EVENT
                    }
                  />
                )} */}
                {/* <FooterLink
                  text="Marketplace"
                  url="/classified-ads/home"
                /> */}
              </ul>
            </>
          )}

          <ul className="longlink">
            <FooterLink text="Devinsider" />
            {/* <FooterLink
              text="Community guidelines"
              url={constante?.NAVIGATE_TO_COMMUNITY_GUIDELINES}
            /> */}
            {/* Dev #47804, V1 [Moblie responsive] quelques améliorations à mettre en place sur les posts du forum */}
            <FooterLink
              text="Terms of use"
              url={constante?.NAVIGATE_TO_TERM_OF_USE}
            />
            <FooterLink
              text="Privacy policy"
              url={constante?.NAVIGATE_TO_PRIVACY_POLICY}
            />
            <FooterLink
              icon={true}
              iconclasses="icon-linkedin"
              url={linkedInUrl}
              target={"_blank"}
            />
            <FooterLink text={"© Devinsider " + new Date().getFullYear()} />
          </ul>
        </Container>
      </footer>
    </div>
  );
}
