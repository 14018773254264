import { useState } from "react";
import { IPropsAbout } from "../type";
import { concatString, refaCtoNullKey } from "../../../../../../../utils";

export default function BlockCompanyDetails(props: IPropsAbout) {
  return (
    <div className="col-lg-4 col-12 contactInfo">
      <div className="boxEditProfil">
        <a
          className="editItem"
          onClick={() => props?.dataSource?.setCompanyDetailsShow(true)}
        >
          Edit
          <span className="icon-edit-full"></span>
        </a>
        <h3 className="title">Company details</h3>
        <ul className="ulListInfo smallLabel">
          <li>
            <div className="label">Legal name</div>
            <div className="infoLabel">
              {props?.dataSource?.companyResellerState?.company_legal_name}
            </div>
          </li>
          <li>
            <div className="label">Website</div>
            <div className="infoLabel">
              {refaCtoNullKey(props?.dataSource?.companyResellerState?.website)}
            </div>
          </li>
          <li>
            <div className="label">Location</div>
            <div className="infoLabel">
              {concatString(
                props?.dataSource?.companyResellerState?.country?.country_name,
                props?.dataSource?.companyResellerState?.city
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
