/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IPropsStrategy } from "../types";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling-white.svg";
import EditStrategicPartener from "../modal-edit/edit-strategic-partener";
export default function InfoStrategy(props: IPropsStrategy) {
  return (
    <>
      <article className="investorArticle bgGris">
        <div className="mx-auto container cont3">
          <div className="contentLeftInvestor">
            <a
              className="linkEditLeftCompany"
              onClick={() => props?.showModalStategic()}
            >
              Edit<span className="icon-edit-full"></span>
            </a>
            <h3 className="title">Strategic partnerships</h3>
            {props?.isEditMode && (
              <>
                <p>
                Select which strategic partnerships you are open to facilitate.
                </p>
              </>
            )}
            <ul className="listSquareItem strategic">
              <li>
                <a
                  className={
                    props?.listStrategic?.find((e) => e.id == 1) ? "active" : ""
                  }
                >
                  <span className="icon-strategic-alliance"></span>
                  strategic alliance
                </a>
              </li>
              <li>
                <a
                  className={
                    props?.listStrategic?.find((e) => e.id == 2) ? "active" : ""
                  }
                >
                  <span className="icon-mergers-acquisitions"></span>
                  Mergers & acquisitions
                </a>
              </li>
              <li>
                <a
                  className={
                    props?.listStrategic?.find((e) => e.id == 3) ? "active" : ""
                  }
                >
                  <span className="icon-stock-buybacks"></span>
                  Stock buybacks
                </a>
              </li>
              <li>
                <a
                  className={
                    props?.listStrategic?.find((e) => e.id == 4) ? "active" : ""
                  }
                >
                  <span className="icon-IP-sales"></span>
                  IP sale
                </a>
              </li>
            </ul>
            <EditStrategicPartener {...props} />
          </div>
        </div>
      </article>
    </>
  );
}
