import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootState } from "../../../../redux/reducers";
import {
  CONTRIBUTOR,
  INVESTOR,
  OEM_PROGRAM,
  RESELLER,
  ISV,
} from "../../../../service/constant";
import { confirmAlert } from 'react-confirm-alert';
import UseCommunityPRCtr from '../../../../controller/community-ctr/bloc-community-ctr/use-press-release-ctr';
import ModalPayementPR from '../../../widget/payement-isv/publish-pr';

export type IPropsModal = {
  show: boolean;
  setShow: (p: boolean) => void;
  activeCategorie: number;
  setactiveCategorie: (p: number) => void;
  createPost: (p: number) => void;
  role: string[];
};

const postType = ["Press release", "Partner Program review"];

const nonCompatibleRole = [OEM_PROGRAM, INVESTOR, RESELLER, CONTRIBUTOR];

export default function ModalStartPost(props: IPropsModal) {
  const history = useHistory();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const user = useSelector((state: rootState) => state.userReducer);
  const providerCommunity = { ...UseCommunityPRCtr() };

  const isOem = props?.role[0]?.toString() === OEM_PROGRAM;
  const isIsv = props?.role[0]?.toString() === ISV;

  const renameArticle = (postType: string) => {
    if (isOem && postType === "Article") {
      return "FEATURED ARTICLE";
    } else {
      return postType?.toUpperCase();
    }
  };

  const [errorAffiliated, setErrorAffiliated] = useState<boolean>(false);

  const renderTextFeaturedArticleOem = () => {
    if (isOem && oemProgram.oem_subscription.id === 1) {
      return (
        // <label>
        //   *To publish a featured article, you need to{" "}
        //   <a
        //     style={{ color: "blue" }}
        //     onClick={() => history.push("/billing-center/subscription-plan")}
        //   >
        //     <u>
        //       <b>upgrade</b>
        //     </u>
        //   </a>{" "}
        //   your current subscription
        // </label>
        <label>
          *The publication of 'Featured articles' is upcoming.
        </label>
      );
    }
    return <></>;
  };

  const renderTypeDiscussion = (index: number, el: any) => {
    if (
      isOem &&
      oemProgram.oem_subscription.id === 1 &&
      el.type === "Article"
    ) {
      return (
        <>
          <a
            className="choicePost noHover"
            style={{
              cursor: "no-drop",
            }}
            onClick={() => {}}
          >
            <span className={el.icon} />
            {renameArticle(el?.type)}
          </a>
        </>
      );
    }
    return (
      <a
        className={`choicePost ${
          props?.activeCategorie === index ? "active" : ""
        }`}
        onClick={(e: any) => {
          e?.preventDefault();
          props?.setactiveCategorie(index);
        }}
      >
        <span className={el.icon} />
        {renameArticle(el?.type)}
      </a>
    );
  };

  // const affiliatedError = () => {
  //   if(errorAffiliated) {
  //     confirmAlert({
  //       customUI: ({ onClose }) => {
  //         return (
  //           <div className='custom-ui'>
  //             <h1>Complete information</h1>
  //             <p>
  //               You must be affiliated to a company page in order to publish a
  //               press release
  //             </p>
  //           </div>
  //         );
  //       },
  //     });
  //     setErrorAffiliated(false);
  //   }
  // }

  const handleSubButton = () => {
    // if(isIsv && !user?.isvInCompanyPage) {
    //   setErrorAffiliated(true);
    //   props?.setShow(false);
    // } else {
      if(props?.activeCategorie === 2) {
        if(isIsv && !user?.isvInCompanyPage) {
          setErrorAffiliated(true);
          props?.setShow(false);
        } else {
          providerCommunity.providerUsePayementPr.showmodalPR();
          props?.setShow(false);
        }
      }else if(props?.activeCategorie === 3) {
        if(isIsv && !user?.isvInCompanyPage) {
          setErrorAffiliated(true);
          props?.setShow(false);
        } else {
          props?.createPost(props?.activeCategorie);
        }
      }
       else {
        props?.createPost(props?.activeCategorie);
      }
    // }
  }

  return (
    <>
    <Modal
      show={props?.show}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Start a post</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={() => props?.setShow(false)}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="contentBorder row forPitch choicePostcontainer">
          <label htmlFor="">Select a type of message :</label>
          <div className="listPost">
            {communityReducer?.postType?.map(
              (
                el: { id: number; type: string; icon: string },
                index: number
              ) => (
                <div key={index}>
                  {nonCompatibleRole?.find(
                    (e) => e === props?.role[0]?.toString()
                  ) &&
                  postType?.find(
                    (e) => e?.toLowerCase() === el?.type?.toLowerCase()
                  ) ? (
                    <></>
                  ) : (
                    renderTypeDiscussion(index, el)
                  )}
                </div>
              )
            )}
          </div>
          {renderTextFeaturedArticleOem()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => {
              props?.setactiveCategorie(0);
              props?.setShow(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-devinsider px-5 save"
            // onClick={() => props?.createPost(props?.activeCategorie)}
            onClick={handleSubButton}
        
          >
            Next
          </button>
        </div>
      </Modal.Footer>
    </Modal>
    {/* {affiliatedError()} */}
    <ModalPayementPR
      {...providerCommunity.providerUsePayementPr}
      loading={providerCommunity.loading}
      publishPR={() => providerCommunity.postPr(props?.activeCategorie)}
    />

    {/*Popup Permission*/}
    <Modal show={errorAffiliated} className="small forDesactivate">
      <Modal.Header>
        <div className="headerpermission noStep">
          <h2 className="title">Oops...</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={() => setErrorAffiliated(false)}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row m-0 p-0 desactivepopup">
          <h3 className="title withIcon">
            <span className="icon-info"></span>
            
          </h3>
          {
            props?.activeCategorie === 3 ? (
              <p>You must be affiliated to a company page in order to publish a program review</p>
            ) : (
              <p>You must be affiliated to a company page in order to publish a press release</p>
            )
          }
          <p></p>
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
}
