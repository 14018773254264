import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signinAction } from "../../../redux/actions/acounts-action/index";
import { postWithoutTokenCombineUrl } from "../../../service/api";
import {
  INVESTOR,
  url,
  OEM_PROGRAM,
  GUEST,
} from "../../../service/constant/index";

export default function UseSigninCtr() {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [showPassword, setShowPasswpord] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const getStarted = (): void => {
    history.push("/account-choices");
  };
  let redirectUrl: any = location?.state?.redirectUrl || "/dashboard";

  const redirectInvestor = (params: string) => {
    if (params) {
      history?.push("/dashboard", { isSignin: true });
    } else {
      history?.push("profil/inv");
    }
  };

  const callBack = async (data: any) => {
    let dataResponse = data?.data;
    if (data && data.status === 200) {
      if (dataResponse?.enable) {
        const role = dataResponse?.roles[0];
        if (role === INVESTOR) {
          redirectInvestor(data?.investor_members?.investor_page?.status);
        } else if (role === OEM_PROGRAM) {
          history.push(
            "/OEM/profil/" + dataResponse?.oemProgramMembers?.[0].oem_program.id
          );
        } else if (role === GUEST) {
          history.push("/classified-ads/home");
        } else {
          history.push(redirectUrl, { fromLogin: true });
        }
      } else {
        await postWithoutTokenCombineUrl(url.acount.resendEmail, email);
        history.push("/complete-account", {
          sendMail: true,
          email,
        });
      }
    } else {
      setError(true);
      setShowLoader(false);
    }
  };

  //SIgnin user
  const signinUser = (): void => {
    setShowLoader(true);
    setError(false);
    dispatch(
      signinAction(
        { email: email?.trim(), password: password?.trim() },
        callBack
      )
    );
  };

  const forgotPassword = (): void => {
    history.push("./forgot-password");
  };

  return {
    showLoader,
    email,
    password,
    error,
    showPassword,
    setShowPasswpord,
    getStarted,
    signinUser,
    setEmail,
    setPassword,
    forgotPassword,
  };
}
