import CompleteInfo from '../screen/container/acount/complete-create-account-reseller/complete-info-reseller/complete-info-reseller';
import CompleteAdresse from '../screen/container/acount/complete-create-account-reseller/complete-address-reseller/complete-address-reseller';
import CompletePicture from '../screen/container/acount/complete-create-account-reseller/complete-profil-picture-reseller/complete-profil-picture-reseller';
import CompleteCompanyPage from '../screen/container/acount/complete-create-account-reseller/complete-company-reseller/complete-company-info-reseller';
import CompleteCompanyPays from '../screen/container/acount/complete-create-account-reseller/complete-company-reseller/complete-company-pays-reseller';
import CompleteCompanySolutionOffertReseller from '../screen/container/acount/complete-create-account-reseller/complete-company-reseller/complete-company-solution-offert-reseller';
import CompleteCompanyIndustrieTargetReseller from '../screen/container/acount/complete-create-account-reseller/complete-company-reseller/complete-company-industrie-target-reseller';
import CompleteCompanyDescriptionReseller from '../screen/container/acount/complete-create-account-reseller/complete-company-reseller/complete-company-description-reseller';
import CompleteCompanyKeyCompetitive from '../screen/container/acount/complete-create-account-reseller/complete-company-reseller/complete-company-key-competitive';
import CompleteCompanyLogoReseller from '../screen/container/acount/complete-create-account-reseller/complete-company-reseller/complete-company-logo-reseller';
import GetStartedReseller from '../screen/container/get-started/reseller/get-started-reseller';
import ProfilResellerEdit from '../screen/container/profil/user-profil/profil-edit/profil-edit-reseller/index';
import CompleteInterestedResseller from '../screen/container/acount/complete-create-account-reseller/complete-interested-reseller/complete-interested-reseller';
import { RESELLER } from '../service/constant';
export const routesReseller = [
  {
    path: '/complete-info-reseller',
    exact: true,
    component: CompleteInfo,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-adresse-reseller',
    exact: true,
    component: CompleteAdresse,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-picture-reseller',
    exact: true,
    component: CompletePicture,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-company-reseller',
    exact: true,
    component: CompleteCompanyPage,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-company-reseller-pays',
    exact: true,
    component: CompleteCompanyPays,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-interested-reseller',
    exact: true,
    component: CompleteInterestedResseller,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-company-solution-offert-reseller',
    exact: true,
    component: CompleteCompanySolutionOffertReseller,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-company-industrie-target-reseller',
    exact: true,
    component: CompleteCompanyIndustrieTargetReseller,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-company-description-reseller',
    exact: true,
    component: CompleteCompanyDescriptionReseller,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-company-key-competitive',
    exact: true,
    component: CompleteCompanyKeyCompetitive,
    guard: 'not-logged-page',
  },
  {
    path: '/complete-company-logo-reseller',
    exact: true,
    component: CompleteCompanyLogoReseller,
    guard: 'not-logged-page',
  },
  {
    path: '/get-started-reseller',
    exact: true,
    component: GetStartedReseller,
    guard: 'not-logged-page',
  },
  {
    path: '/reseller/profile/edit',
    exact: true,
    component: ProfilResellerEdit,
    guard: 'private',
    allowedRole: [RESELLER],
  },
];
