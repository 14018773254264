import * as React from "react";
import "./style-btn-confirm.scss";

type Props = {
    submitClick: (data?:any) => void;
    cancelClick: () => void;
};

export const BtnConfirm: React.FC<Props> = (props) => {
    const {submitClick, cancelClick} = props;
    return (
        <div className="btn-confirm-wrap">
            <div className="txt">Are you sure?</div>
            <div className="btnCtt">
                <button className="btn-confirm-cancel" onClick={() => cancelClick()}>Cancel</button>
                <button className="btn-confirm-ok" onClick={() => submitClick()}>Confirm</button>
            </div>
        </div>
    );
};
