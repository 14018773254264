export const timeRange = [
  {
    id: 1,
    label: "01:00am",
    value: "01:00:00",
  },
  {
    id: 2,
    label: "02:00am",
    value: "02:00:00",
  },
  {
    id: 3,
    label: "03:00am",
    value: "03:00:00",
  },
  {
    id: 4,
    label: "04:00am",
    value: "04:00:00",
  },
  {
    id: 5,
    label: "05:00am",
    value: "05:00:00",
  },
  {
    id: 6,
    label: "06:00am",
    value: "06:00:00",
  },
  { id: 7, label: "07:00am", value: "07:00:00" },
  { id: 8, label: "08:00am", value: "08:00:00" },
  { id: 9, label: "09:00am", value: "09:00:00" },
  { id: 10, label: "10:00am", value: "10:00:00" },
  { id: 11, label: "11:00am", value: "11:00:00" },
  { id: 12, label: "12:00pm", value: "12:00:00" },
  { id: 13, label: "01:00pm", value: "13:00:00" },
  { id: 14, label: "02:00pm", value: "14:00:00" },
  { id: 15, label: "03:00pm", value: "15:00:00" },
  { id: 16, label: "04:00pm", value: "16:00:00" },
  { id: 17, label: "05:00pm", value: "17:00:00" },
  { id: 18, label: "06:00pm", value: "18:00:00" },
  { id: 19, label: "07:00pm", value: "19:00:00" },
  { id: 20, label: "08:00pm", value: "20:00:00" },
  { id: 21, label: "09:00pm", value: "21:00:00" },
  { id: 22, label: "10:00pm", value: "22:00:00" },
  { id: 23, label: "11:00pm", value: "23:00:00" },
  { id: 24, label: "12:00am", value: "24:00:00" },
];
