import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { IPropsSettings } from "../types";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling-white.svg";
import moment from "moment";
import { formatAMPM } from "../../../../../utils";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import UseUnpublishProgram from "../../../../../controller/investor-ctr/use-unpublish-investor-ctr";
import { BtnNext } from "../../../../widget/bouton/btn-next";
import warning from "../../../../../resources/images/warning.svg";

export default function SettingProfil(props: IPropsSettings) {
  //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
  const unpublishProgramCtr = UseUnpublishProgram();
  const history = useHistory();
  const txtAnonymous =
    props?.isAnonyme || props?.investorPage?.status === false
      ? "Publish"
      : "Update";

  const closeAndShowPopup = () => {
    unpublishProgramCtr.setShowBtnUnpublish(false);
    unpublishProgramCtr.setShowPopupUnpublish(true);
  };

  const gearUnpublish  = () => {
    unpublishProgramCtr.setShowBtnUnpublish(true)
    unpublishProgramCtr.setGearClick(unpublishProgramCtr.gearClick+1)
  }
  const cancelPopUp = () => {
    unpublishProgramCtr.setShowPopupUnpublish(false)
    unpublishProgramCtr.setGearClick(unpublishProgramCtr.gearClick+1)
  }

  return (
    <>
      {!props.isOtherUser && !props.isPreview && (
        <div className="contentRightInvestor">
          <div className="editShow ">
            {!props.isOnEditWysiwyg && (
              <button
                className="btnforEdit doneEdit"
                onClick={() => {
                  props.doneEditing();
                }}
              >
                Done editing
              </button>
            )}

            <button
              className="btnforEdit preview"
              onClick={() => props?.showPreview()}
            >
              Preview
            </button>
          </div>
          <button
            className="btnSetting justForProfil"
            onClick={() => {
              props.showPageSetting();
            }}
          >
            Page settings
          </button>
          {/* <button
            className="btnSetting justForProfil"
            onClick={() => props.showManage()}
          >
            Manage page
          </button> */}
          <span className="separator justForProfil"></span>
          <button
            className="btnSetting justForProfil"
            onClick={() => {
              props.showEditMode();
            }}
          >
            Edit page
          </button>
          <div className="infoPublication position-relative">
            <div className="infoItem">
              <span className="icon-mini-key"></span>
              <label>
                Status :{" "}
                <b>
                  {
                    //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
                    props?.investorPage?.isv_find_me
                      ? props?.editMode
                        ? "Draft"
                        : props?.investorPage?.validate_by_devinsider
                        ? props?.investorPage?.status
                          ? "Published"
                          : "Unpublished"
                        : "Inactive"
                      : props?.editMode
                      ? "Draft (unpublished)"
                      : props?.investorPage?.validate_by_devinsider
                      ? props?.investorPage?.status
                        ? "Active"
                        : "Deactivated"
                      : "Unpublished"
                  }
                </b>
              </label>
              {
                //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
                props?.investorPage?.status === true &&
                  props?.investorPage?.validate_by_devinsider === true &&
                  !props?.editMode && (
                    <span
                      className="icon-settings ml-1"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        gearUnpublish()
                      }
                    ></span>
                  )
              }
            </div>
            <div className="infoItem">
              <span className="icon-calendar"></span>
              <label>Last update: </label>
              {unpublishProgramCtr.showBtnUnpublish && unpublishProgramCtr.gearClick%2 === 0 && (
                <div
                  className="btnUnpublish"
                  onClick={() => closeAndShowPopup()}
                >
                  Unpublish
                </div>
              )}
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id="tooltip-disabled">
                    <b>
                      {props?.lastUpdate &&
                        moment(new Date(props?.lastUpdate)).format(
                          "MMM D , YYYY [at] h:mm A"
                        )}{" "}
                    </b>
                  </Tooltip>
                }
                defaultShow={false}
              >
                <b className="justForProfil">
                  {props?.lastUpdate &&
                    moment(new Date(props?.lastUpdate)).format(
                      "MMM D, YYYY [at] h:mm A"
                    )}{" "}
                </b>
              </OverlayTrigger>
            </div>
          </div>
          {props?.isNullDraft && (
            <div className="cttbtnUpdate justForProfil">
              {props?.validateBydevinsider && props?.isAnonyme === false ? (
                <button onClick={() => props?.updateDraft()}>
                  {props?.loader ? <Loader /> : txtAnonymous}
                </button>
              ) : (
                <>
                  {/* //Dev #48047 V2 [Investor] Conditions d'accès à l'investor qui
                  a choisit l'option "I want to remain invisible and don’t want
                  ISVs to find me." */}
                  <button
                    style={
                      props?.isAnonyme
                        ? {
                            cursor: "not-allowed",
                            backgroundColor: "#E7E7E7",
                            border: "1px solid #E7E7E7",
                            color: "#4F4F4F",
                          }
                        : { cursor: "default" }
                    }
                    onClick={() =>
                      props?.isAnonyme ? null : props?.updateDraft()
                    }
                  >
                    {props?.loader ? <Loader /> : "Publish"}
                  </button>
                </>
              )}
            </div>
          )}
          {props?.isAnonyme && (
            <div className="visibility">
              Your visibility settings are set to remain invisible. To publish
              your investor page and receive inquiries from ISVs, change your
              visibility settings under "page settings".
            </div>
          )}
        </div>
      )}

      {props.isOtherUser && (
        <div className="contentRightInvestor cont">
          {props.matching && (
            <div className="matchValue m90">{props.matching} % Match</div>
          )}
          {!props.isLockedPage && (
            <div className="contact">
              <Link to="#" className="btn btn-devinsider px-5 save">
                Contact
              </Link>
            </div>
          )}

          <span className="separator justForProfil"></span>
          <div className="favorites">
            <Link
              to="#"
              className="btn-devinsider px-5 cancel"
              onClick={props.addFavorite}
            >
              <span
                className={`${
                  props.is_favorite ? "icon-star-on" : "icon-star-off"
                } star`}
              ></span>{" "}
              Favorites
            </Link>
            <Link
              to="#"
              className="btn-devinsider px-5 cancel"
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              Back to the Results panel
            </Link>
          </div>
        </div>
      )}

      {/* MODAL CONFIRMATION FOR UNPUBLISHING PROGRAM */}
      {/* //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page */}
      <Modal
        show={unpublishProgramCtr.showPopupUnpublish}
        className="createCompanypopup large forStep5custom forUnpublish"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Unpublish</h2>
            <NavLink
              to="#"
              className="close"
              onClick={() => cancelPopUp()}
            >
              <span className="icon-close-pop"></span>
            </NavLink>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="contentBorder">
            <img src={warning} alt="" />
            <p className="mb-5 mt-5 text-center">
              Are you sure you want to unpublish your page?{" "}
            </p>
            <p>
              When you unpublish your page, the status will be changed to draft
              and your page will be removed from the matchmaking results. <br />
              You can always re-publish your page at a later point.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => cancelPopUp()}
            >
              Cancel
            </button>
            <button
              className="btn btn-devinsider px-5 save"
              onClick={() => unpublishProgramCtr.handleUnpublish()}
            >
              <BtnNext
                text="Confirm"
                showLoader={unpublishProgramCtr.processing}
              />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
