import React from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../redux/reducers';
import { ISV, MODERATOR, OEM_PROGRAM } from '../service/constant';
import { Redirect } from 'react-router-dom';

export default function PressReleaseComponent(props: any) {
  const currentUser = useSelector((state: rootState) => state.userReducer);

  if (
    currentUser.roles.length &&
    !currentUser.roles.includes(ISV) &&
    !currentUser.roles.includes(OEM_PROGRAM) &&
    !currentUser.roles.includes(MODERATOR)
  ) {
    return <Redirect to={'/profil-not-found'} />;
  }

  return <div>{props.children}</div>;
}
