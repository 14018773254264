import React from "react";
import { confirmAlert } from "react-confirm-alert";

export type Iprops = {
  txt: Array<string>;
};
export default function Alert(props: Iprops) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          {props.txt.map((t) => (
            <p>{t}</p>
          ))}
        </div>
      );
    },
  });
}
