import React, { useState, useEffect } from "react";
import { EditorState } from "draft-js";
import { updateDraftDataAction } from "../../../redux/actions/company-page-investor-action/my-investor-page-action";
import { rootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { customDataStore, getSStorage } from "../../../utils";
import { useParams } from "react-router-dom";

// investor_funding_round
export default function useEditInfoStrategyCtr() {
  const [loader, setLoader] = useState<boolean>(false);
  const [itemsClick, setItemsClick] = useState<number>(0);
  const params : {id : any} = useParams();
  const isOtherUser = params?.id? true : false;

  const CP_Investor = useSelector(
    (state: rootState) => !isOtherUser? state.companyInvestorPageReducer : state?.otherCompanyInvestorPageReducer
  );
  let CP_DRAFT_DATA = JSON.parse(CP_Investor.investor_page_draft || "{}");
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const [showModalStrategic, setShowModalStrategic] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [initiaStrategic, setInitiaStrategic] = useState(
    CP_DRAFT_DATA?.investor_strategic_partnership
      ? CP_DRAFT_DATA?.investor_strategic_partnership
      : CP_Investor?.investor_strategic_partnership
  );

  const saveClickStrategic = (element: number) => {
    let dataSelected = dataCompleted?.investorStrategicPartnership?.find(
      (el: { id: number; name: string }) => el?.id == element
    );
    let tempValue = customDataStore(initiaStrategic, dataSelected);
    setInitiaStrategic(tempValue);
  };

  const closeEditStrategic = () => {
    setShowModalStrategic(false);
    setLoader(false);
    setInitiaStrategic(
      CP_DRAFT_DATA?.investor_strategic_partnership
        ? CP_DRAFT_DATA?.investor_strategic_partnership
        : CP_Investor?.investor_strategic_partnership
    );
  };

  useEffect(() => {
    setInitiaStrategic(
      CP_DRAFT_DATA?.investor_strategic_partnership
        ? CP_DRAFT_DATA?.investor_strategic_partnership
        : CP_Investor?.investor_strategic_partnership
    );
    return () => {};
  }, [CP_Investor?.investor_strategic_partnership, params?.id]);

  const showModalEdit = () => {
    setShowModalStrategic(true);
  };

  const cbUpdateDraft = (data: any) => {
    setItemsClick(0);
    setLoader(false);
    setShowModalStrategic(false);
  };

  const saveStrategic = () => {
    setLoader(true);
    let dataToSend = {
      ...JSON.parse(CP_Investor?.investor_page_draft || "{}"),
      investor_strategic_partnership: initiaStrategic,
    };
    dispatch(
      updateDraftDataAction(
        { investorPageDraft: JSON.stringify(dataToSend) },
        CP_Investor.id,
        cbUpdateDraft
      )
    );
  };

  return {
    saveClickStrategic,
    loader,
    itemsClick,
    saveStrategic,
    closeEditStrategic,
    showModalEdit,
    showModalStrategic,
    initiaStrategic,
  };
}
