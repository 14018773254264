import React from "react";
import {
  activeFilterContributor,
  ItemAuthor,
} from "../../../../../controller/community-ctr/bloc-community-ctr/use-view-all-top-author-contributor";
import { concatString, fileUrl, refaCtoNullKey } from "../../../../../utils";
import Pagination from "../../../../widget/use-pagination/pagination";
import { LoadingArticle } from "../../../../widget/loader";
import { ItemUser } from "../../../../../controller/community-ctr/bloc-community-ctr/use-view-all-likeds";

export type IPropsAuthor = {
  activeFilter:
    | "last-day"
    | "last-week"
    | "last-month"
    | "last-six-mounth"
    | "last-year"
    | "all-time";
  dataAuthor: Array<ItemUser>;
  topLikedAuthor: Array<ItemUser>;
  setFilterAuthor: (p: activeFilterContributor) => void;
  loading: boolean;
  totalData: number;
  handlePageClick: (p: number) => void;
  currentPage: number;
  categorie: string;
  showActivityUser: (p: string) => void;
};

export default function AthorsTab(props: IPropsAuthor) {
  let firstColumn: Array<ItemUser> = props?.dataAuthor?.slice(0, 6);
  let secondColumn: Array<ItemUser> = props?.dataAuthor?.slice(6, 12);
  let thirdColumn: Array<ItemUser> = props?.dataAuthor?.slice(12, 18);
  return (
    <div>
      <div className="timeRange">
        <p>Time range: </p>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-day" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("last-day")}
        >
          Last day (24 Hours)
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-week" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("last-week")}
        >
          Last week (7 Days)
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-month" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("last-month")}
        >
          Last months (30 Days)
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-six-mounth" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("last-six-mounth")}
        >
          Last 6 Months{" "}
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-year" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("last-year")}
        >
          Last year
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "all-time" ? "active" : ""}
          onClick={() => props?.setFilterAuthor("all-time")}
        >
          All time
        </a>
      </div>
      <h3 className="title">Top liked authors in "{props?.categorie}"</h3>

      <div className="listAuthors">
        <div className="allList">
          <div className="rankAll">
            {props?.topLikedAuthor?.map((el: ItemUser, index: number) => (
              <div className="itemRank" key={index}>
                <div className="rang">#{el?.order_rank}</div>
                <div className="infoRank">
                  <div className="imageAffiliatedcompany empty">
                    <img src={fileUrl(el?.profil_picture)} alt="" />
                  </div>
                  <div
                    className="nameAndLike"
                    onClick={(e: any) => {
                      props?.showActivityUser(el?.user_id?.toString());
                    }}
                  >
                    <p className="name">{concatString(el?.name)}</p>
                    <span className="like">{el?.nbre_likes} Likes</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
        </div>

        <div className="otherList row ">
          {props?.loading ? (
            <LoadingArticle />
          ) : (
            <>
              <div className="col-4">
                {firstColumn?.map((el: ItemUser, index: number) => (
                  <div className="itemRank" key={index}>
                    <div className="rang">{el?.order_rank}</div>
                    <div className="infoRank">
                      <div
                        className="imageAffiliatedcompany empty"
                        onClick={(e: any) => {
                          props?.showActivityUser(el?.user_id);
                        }}
                      >
                        <img src={fileUrl(el?.profil_picture)} alt="" />
                      </div>
                      <div className="nameAndLike">
                        <p className="name">{concatString(el?.name)}</p>
                        <span className="like">{el?.nbre_likes} Likes</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-4">
                {secondColumn?.map((el: ItemUser, index: number) => (
                  <div className="itemRank" key={index}>
                    <div className="rang">{el?.order_rank}</div>
                    <div className="infoRank">
                      <div
                        className="imageAffiliatedcompany"
                        onClick={(e: any) => {
                          props?.showActivityUser(el?.user_id);
                        }}
                      >
                        <img src={fileUrl(el?.profil_picture)} alt="" />
                      </div>
                      <div className="nameAndLike">
                        <p className="name">{concatString(el?.name)}</p>
                        <span className="like">{el?.nbre_likes} Likes</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-4">
                {thirdColumn?.map((el: ItemUser, index: number) => (
                  <div className="itemRank" key={index}>
                    <div className="rang">{el?.order_rank}</div>
                    <div className="infoRank">
                      <div
                        className="imageAffiliatedcompany"
                        onClick={(e: any) => {
                          props?.showActivityUser(el?.user_id);
                        }}
                      >
                        <img src={fileUrl(el?.profil_picture)} alt="" />
                      </div>
                      <div className="nameAndLike">
                        <p className="name">{concatString(el?.name)}</p>
                        <span className="like">{el?.nbre_likes} Likes</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination">
                <Pagination
                  className="pagination"
                  currentPage={props?.currentPage}
                  totalCount={props?.totalData}
                  pageSize={18}
                  onPageChange={(page: any) => props?.handlePageClick(page)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
