import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import BestAnswerByUserCtr from "../../../../../controller/community-ctr/activity-user/best-answers-by-user-ctr";
import { LoadingArticle } from "../../../../widget/loader";
import Pagination from "../../../../widget/use-pagination/pagination";
import { dateDiff, fileUrl } from "../../../../../utils";

const BestAnswerByUser = (props: any) => {
  const providerBestAnserByUser = BestAnswerByUserCtr(props?.userId);

  const renderData = (userPost: Array<any>) => {
    return userPost?.map((post: any) => (
      <li key={post?.id}>
        <div className="infoAbout">
          <div className="withPost">
            <div className="imageAffiliatedcompany empty">
              {post?.user?.file_name && (
                <img
                  src={fileUrl(post?.user?.file_name)}
                  alt="profil picture"
                />
              )}
            </div>
            <div
              className={
                "nameOfPost " + props?.getBadge(post?.user?.roles).badge
              }
            ></div>
          </div>
          <div className="infoFeed">
            <div
              className="titlePost"
              onClick={() => props?.showDiscussion(post)}
            >
              {/* SOLVE */}
              {post?.solve && <span className="icon-check" />}
              {post?.post_type?.id?.toString() === "2" && (
                <span className="article">Article</span>
              )}
              <span className="title">{post?.title} </span>
            </div>
            <div className="postBy">
              by{" "}
              <Link to={"/community/activity-user/" + post?.user?.id}>
                {post?.user?.first_name + " " + post?.user?.last_name}
              </Link>{" "}
              on {moment(post?.created_at).format("MM-DD-Y hh:mm A")}
            </div>
            <div className="postBy">
              Latest activity {dateDiff(post?.updated_at)} by
              <Link
                to={"/community/activity-user/" + post?.last_activity_by?.id}
              >
                {" "}
                {post?.last_activity_by?.first_name +
                  " " +
                  post?.last_activity_by?.last_name}
              </Link>
            </div>
            <div className="subCategory">
              {post?.labels?.map((label: any) => (
                <span
                  key={label.id}
                  onClick={() =>
                    props.showLabel(post?.category?.id?.toString(), label.id)
                  }
                >
                  {" "}
                  {label.title}{" "}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="replyInfo">
          <b> {post?.numberOfReplies} </b>
          {parseInt(post?.numberOfReplies) > 1 ? "Replies" : "Reply"}
        </div>
        <div className="viewInfo">
          <b> {post?.numberOfViews} </b>
          {parseInt(post?.numberOfViews) > 1 ? "Views" : "View"}
        </div>
      </li>
    ));
  };

  return providerBestAnserByUser?.bestAnswerByUser?.results?.length > 0 ? (
    <>
      <div className="titleCategory">
        <Select
          onChange={(e: any) => providerBestAnserByUser.handleChangePostType(e)}
          className="triOreder nameOfTitle"
          isClearable={false}
          options={[
            { value: "1", label: "View forum Discussions only" },
            { value: "2", label: "View articles only" },
            { value: "", label: "All best answers" },
          ]}
          classNamePrefix="tri"
          defaultValue={{ value: "", label: "All best answers" }}
        />
        <div className="tri">
          <div className="labelSelect">
            <label>Show:</label>
            <Select
              onChange={(e: any) => providerBestAnserByUser.handleChangeShow(e)}
              className="triNumber"
              isClearable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              classNamePrefix="tri"
              defaultValue={{ value: "10", label: "10" }}
              value={{
                value: providerBestAnserByUser.bestAnswerByUserFilter.limit,
                label: providerBestAnserByUser.bestAnswerByUserFilter.limit,
              }}
            />
          </div>

          <div className="sortedby labelSelect">
            <label>Sorted by :</label>
            <Select
              onChange={(e: any) =>
                providerBestAnserByUser.handleChangeSorted(e)
              }
              className="triOreder"
              isClearable={false}
              options={[
                { value: "post_date", label: "Post date" },
                { value: "last_activity", label: "Latest activity" },
                { value: "replies", label: "Replies" },
                { value: "views", label: "Views" },
              ]}
              classNamePrefix="tri"
              defaultValue={{
                value: "activity",
                label: "Latest activity",
              }}
            />
          </div>
        </div>
      </div>
      <ul className="feedList categoryList">
        {providerBestAnserByUser?.loadingBestAnswer ? (
          <LoadingArticle />
        ) : (
          renderData(providerBestAnserByUser?.bestAnswerByUser?.results)
        )}
      </ul>
      <div className="pagginationCategory">
        <div className="labelSelect">
          <label>Show:</label>
          <Select
            onChange={(e: any) => {
              providerBestAnserByUser.handleChangeShow(e);
            }}
            className="triNumber"
            isClearable={false}
            options={[
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "30", label: "30" },
            ]}
            classNamePrefix="tri"
            defaultValue={{ value: "10", label: "10" }}
            value={{
              value: providerBestAnserByUser.bestAnswerByUserFilter.limit,
              label: providerBestAnserByUser.bestAnswerByUserFilter.limit,
            }}
          />
        </div>
        <div className="pagination">
          <Pagination
            totalCount={providerBestAnserByUser?.bestAnswerByUser?.total}
            currentPage={providerBestAnserByUser?.bestAnswerByUserFilter?.page}
            className="pagination"
            idContainer="bestAnswerByUser"
            pageSize={providerBestAnserByUser?.bestAnswerByUserFilter?.limit}
            onPageChange={(p: any) => providerBestAnserByUser.handlePage(p)}
          />
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
export default BestAnswerByUser;
