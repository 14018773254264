import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { getDataWithoutToken } from "../../../../service/api";
import { url } from "../../../../service/constant";
import { getMonth } from "../../../../utils";

export default function UsePostFeaturedArticle() {
  const now = new Date();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const [modalPostFeaturedArticle, setModalPostFeaturedArticle] =
    useState<boolean>(false);
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );

  const [statePostFeaturedArticle, setStatePostFeaturedArticle] = useState<{
    number_left: number;
    number_max: number;
    next_grant_at: string;
  }>({
    number_left: 0,
    number_max: 0,
    next_grant_at: "",
  });

  const closeModalPostFeaturedArticle = () => {
    setStatePostFeaturedArticle({
      number_left: 0,
      number_max: 0,
      next_grant_at: "",
    });
    setModalPostFeaturedArticle(false);
    setChoiseValue(undefined);
  };

  const showModalPublishArticle = () => {
    setModalPostFeaturedArticle(true);
  };

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + "/information/plan_details",
        { month: currentMonth, oem: oemProgram.id },
        userReducer.token
      );
      if (response?.status === 200) {
        const { oem_program_featured_article }: any = response.data.free_status;
        setStatePostFeaturedArticle(oem_program_featured_article);
        setChargingPlanDetails(false);
        setChoiseValue(undefined);
      }
    }
    if (modalPostFeaturedArticle) {
      getPlanDetails();
    }
  }, [currentMonth, modalPostFeaturedArticle]);

  return {
    modalPostFeaturedArticle,
    closeModalPostFeaturedArticle,
    setModalPostFeaturedArticle,
    chargingPlanDetails,
    statePostFeaturedArticle,
    handleChoise,
    choiseValue,
    showModalPublishArticle,
  };
}
