import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { rootState } from '../../../../../redux/reducers';
import store from '../../../../../redux/store';
import { IDiscutionCategorie } from '../../../../../redux/types/community-interface';
import { getDataService } from '../../../../../service/applicatif/back-end-service';
import { url } from '../../../../../service/constant';

const HandyCommunityLinks = () => {
  const history = useHistory();
  /* get discussion category */
  const [discussionAboutCommunity, setDiscussionAboutCommunity] = useState<
    Array<{
      title: string;
      number_of_activity: string;
      last_activity: null | string;
    }>
  >([]);

  {
    /* Bug #47267 Add about community links to handy community links section */
  }
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const goToOtherCategory = (params: any) => {
    history.push('/community/view-other-category/' + params?.id);
  };

  useEffect(() => {
    //get discussion about community
    (async () => {
      const result = await getDataService(
        url.community.get_discussion_about_community,
        {},
        store.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        setDiscussionAboutCommunity(result?.data);
      }
    })();
  }, []);

  const showAllAboutCommunity = (idCategorie: any) => {
    history.push('/community/view-other-category/' + idCategorie, {
      params: idCategorie,
    });
  };

  return (
    <>
      <h2 className='title'>Handy community links</h2>
      <ul className='listOfLinkHandy'>
        <li>
          <a
            style={{ color: '#1897c8' }}
            onClick={() => history.push('/community/discussions-categories')}
          >
            All categories
          </a>
        </li>
        {/* Bug #47267 Add about community links to handy community links section */}
        {communityReducer?.category_about_community?.map(
          (el: IDiscutionCategorie, index: number) => (
            <li key={index}>
              <a
                key={index}
                href='javascript:;'
                onClick={(e) => {
                  e?.preventDefault();
                  goToOtherCategory(el);
                }}
              >
                {el?.title}
              </a>
            </li>
          )
        )}
      </ul>
    </>
  );
};

export default HandyCommunityLinks;
