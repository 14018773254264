import React from "react";
import { useHistory } from "react-router";

enum urlType {
  findInvestor,
  unlocked,
  favorite,
}

export default function SecondHeaderInvestor() {
  const history = useHistory();
  const goToFindInvestor = () => {
    history.push("/investor/find-investor");
  };
  const goToInvestorUnlocked = () => {
    history.push("/investor/investor-unlocked");
  };
  const goToFavoriteInvestor = () => {
    history.push("/investor/favorite");
  };
  const url = window.location.href;
  let submenu = url?.includes("find-investor")
    ? urlType.findInvestor
    : url?.includes("favorite")
    ? urlType.favorite
    : url?.includes("investor-unlocked")
    ? urlType.unlocked
    : urlType.findInvestor;
  return (
    <>
      <div className="secondMenu">
        <div className="container mx-auto">
          <div className="leftItemHeader">
            <h2 className="title">Find investor</h2>
            {/*BEGIN TO UNCOMMENT FOR V2*/}
            {/* <div className="menuDynamic bl">
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={() => {
                    goToFindInvestor();
                  }}
                  className={submenu === urlType.findInvestor ? "active" : ""}
                >
                  Matching results
                </a>
              </div>
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={() => {
                    goToInvestorUnlocked();
                  }}
                  className={submenu === urlType.unlocked ? "active" : ""}
                >
                  Investors unlocked
                </a>
              </div>
              <div className="item">
                <a
                  href="javascript:;"
                  onClick={() => {
                    goToFavoriteInvestor();
                  }}
                  className={submenu === urlType.favorite ? "active" : ""}
                >
                  Favorite
                </a>
              </div>
            </div> */}
            {/*END TO UNCOMMENT FOR V2 */}
          </div>
          <div className="rightItemHeader"></div>
        </div>
      </div>
    </>
  );
}
