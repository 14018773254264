import React from "react";
import UserContributionCtr from "../../../../../controller/community-ctr/activity-user/user-contribution";
import { ISV } from "../../../../../service/constant";

type Iprops = {
  userId: number;
  roleCurrentUser: string;
  roleUserShowInActivity: string | undefined;
};
const UserContribution = (props: Iprops) => {
  const providerUserContribution = { ...UserContributionCtr(props?.userId) };

  const showPrAndPreview =
    props.roleCurrentUser === ISV && props.roleUserShowInActivity === ISV;

  return (
    <>
      <ul className="NumberOfActivity">
        <li>
          <span className="icon-conversation"></span>
          <div className="label">
            <b>
              {providerUserContribution?.informations &&
                providerUserContribution?.informations[0]?.total}
            </b>
            Contributions
          </div>
        </li>
        <li>
          <span className="icon-article"></span>
          <div className="label">
            <b>
              {providerUserContribution?.informations &&
                providerUserContribution?.informations[1]?.total}
            </b>
            Articles
          </div>
        </li>
        {showPrAndPreview && (
          <li>
            <span className="icon-press"></span>
            <div className="label">
              <b>
                {providerUserContribution?.informations &&
                  providerUserContribution?.informations[2]?.total}
              </b>
              Press releases
            </div>
          </li>
        )}

        {showPrAndPreview && (
          <li>
            <span className="icon-program"></span>
            <div className="label">
              <b>
                {providerUserContribution?.informations &&
                  providerUserContribution?.informations[3]?.total}
              </b>
              Program reviews
            </div>
          </li>
        )}

        <li>
          <span className="icon-like"></span>
          <div className="label">
            <b>
              {providerUserContribution?.informations &&
                providerUserContribution?.informations[4]?.total}
            </b>
            Likes
          </div>
        </li>
        <li>
          <span className="icon-check-article"></span>
          <div className="label">
            <b>
              {providerUserContribution?.informations &&
                providerUserContribution?.informations[5]?.total}
            </b>
            Best answers
          </div>
        </li>
      </ul>
    </>
  );
};

export default UserContribution;
