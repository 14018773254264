import React, { useState, useEffect, useRef } from "react";
import { EditorState } from "draft-js";
import { convertFromRaw } from "draft-js";

export type IProps = {
  initialWysiwygTemplate: string;
};

export default function usePostWysiwig(props: IProps) {
  const [stateFormWysywig, setStateFormWysywig] = useState(
    EditorState?.createEmpty()
  );

  useEffect(() => {
    if (props?.initialWysiwygTemplate != "") {
      setStateFormWysywig(
        EditorState?.createWithContent(
          convertFromRaw(JSON.parse(props?.initialWysiwygTemplate))
        )
      );
    } else {
      setStateFormWysywig(EditorState?.createEmpty());
    }

    return () => {};
  }, [props?.initialWysiwygTemplate]);

  const setEditorStatePost = (p: any) => {
    setStateFormWysywig(p);
  };

  return {
    stateFormWysywig,
    setEditorStatePost,
  };
}
