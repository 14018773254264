import React from "react";
import Header from "../../../widget/index";
import photoAddCompany from "../../../../resources/images/addCompany.png";
import "../programs.scss";
import { useHistory } from "react-router";
import Footer from "../../../widget/footer/footer";
export default function OneCase() {
  const history = useHistory();

  const goToCompanyPageCreation = () => {
    history.push("/company/create");
  };

  return (
    <div className="OneCaseContainer">
      <Header />
      <div className="container mx-auto">
        <div className="contentBorder">
          <h3 className="title">Add your company page</h3>
          <div className="image">
            <img src={photoAddCompany} alt="" />
          </div>
          <div className="text-center sTitle">
            <p>
            To match with OEMs and Ecosystems you must be affiliated to a company page
            </p>
            <button
              className="btn btn-devinsider"
              onClick={goToCompanyPageCreation}
            >
              Add a company page
            </button>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
