import profilIndex from '../screen/container/settings/index';
import ManageFeed from '../screen/container/community/manage-feed/index';
import { ISV, OEM_PROGRAM, RESELLER } from '../service/constant';
import { CONTRIBUTOR } from '../service/constant';
import { INVESTOR } from '../service/constant';
import { MODERATOR } from '../service/constant';
export const routeSettings = [
  {
    guard: 'private',
    component: profilIndex,
    exact: true,
    path: '/settings',
  },
  {
    guard: 'private',
    component: ManageFeed,
    exact: true,
    path: '/manage-feed',
    allowedRole: [ISV, RESELLER, INVESTOR, CONTRIBUTOR, MODERATOR, OEM_PROGRAM],
  },
];
