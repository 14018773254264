import React from "react";
import { ItemUserSearch } from "../../../../controller/community-ctr/bloc-community-ctr/use-result-ctr";
import {
  concatString,
  fileUrl,
  getUserType,
  refaCtoNullKey,
} from "../../../../utils";
import Pagination from "../../../widget/use-pagination/pagination";
import { LoadingArticle } from "../../../widget/loader";
export type IPropsAuthor = {
  dataUser: Array<ItemUserSearch>;
  loadAuthor: boolean;
  currentPage: number;
  totalData: number;
  handlePageClick: (p: number) => void;
  goActivity: (p: string) => void;
};

export default function AuthorResult(props: IPropsAuthor) {
  let firstColumn: Array<ItemUserSearch> = props?.dataUser?.slice(0, 6);
  let secondColumn: Array<ItemUserSearch> = props?.dataUser?.slice(6, 12);
  let thirdColumn: Array<ItemUserSearch> = props?.dataUser?.slice(12, 18);

  return (
    <div>
      <div className="allList authorresult">
        {props?.loadAuthor ? (
          <LoadingArticle />
        ) : (
          <>
            <div className="rankAll">
              {firstColumn?.map((el: ItemUserSearch, index: number) => (
                <div className="itemRank" key={index}>
                  <div className="rang">
                    #{index + 1 + (props?.currentPage - 1) * 18}
                  </div>
                  <div className="infoRank">
                    <div className="withPost">
                      <div className="imageAffiliatedcompany empty">
                        <img src={fileUrl(el?.file_name)} alt="" />
                      </div>
                      <div
                        className={`nameOfPost ${getUserType(el?.roles?.[0])}`}
                      ></div>
                    </div>
                    <div className="nameAndLike">
                      <p
                        className="name"
                        onClick={() => {
                          props?.goActivity(el?.id?.toString());
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {/* Dev #47711 V1 [Community] Bug d'affichage sur la page 'Search' lorsque l'on filtre par 'USER' */}
                        {el?.full_name}
                      </p>
                      {refaCtoNullKey(el?.job_title) !== ""
                        ? refaCtoNullKey(el?.job_title) + ", "
                        : refaCtoNullKey(el?.job_title)}

                      {refaCtoNullKey(el?.company_name)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="rankAll">
              {secondColumn?.map((el: ItemUserSearch, index: number) => (
                <div className="itemRank" key={index}>
                  <div className="rang">
                    #{index + 7 + (props?.currentPage - 1) * 18}
                  </div>
                  <div className="infoRank">
                    <div className="withPost">
                      <div className="imageAffiliatedcompany empty">
                        <img src={fileUrl(el?.file_name)} alt="" />
                      </div>
                      <div
                        className={`nameOfPost ${getUserType(el?.roles?.[0])}`}
                      ></div>
                    </div>
                    <div className="nameAndLike">
                      <p
                        className="name"
                        onClick={() => {
                          props?.goActivity(el?.id?.toString());
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {/* Dev #47711 V1 [Community] Bug d'affichage sur la page 'Search' lorsque l'on filtre par 'USER' */}
                        {el?.full_name}
                      </p>
                      <span className="post">
                        {refaCtoNullKey(el?.job_title) !== ""
                          ? refaCtoNullKey(el?.job_title) + ", "
                          : refaCtoNullKey(el?.job_title)}

                        {refaCtoNullKey(el?.company_name)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="rankAll">
              {thirdColumn?.map((el: ItemUserSearch, index: number) => (
                <div className="itemRank" key={index}>
                  <div className="rang">
                    #{index + 13 + (props?.currentPage - 1) * 18}
                  </div>
                  <div className="infoRank">
                    <div className="withPost">
                      <div className="imageAffiliatedcompany empty">
                        <img src={fileUrl(el?.file_name)} alt="" />
                      </div>
                      <div
                        className={`nameOfPost ${getUserType(el?.roles?.[0])}`}
                      ></div>
                    </div>
                    <div className="nameAndLike">
                      <p
                        className="name"
                        onClick={() => {
                          props?.goActivity(el?.id?.toString());
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {/* Dev #47711 V1 [Community] Bug d'affichage sur la page 'Search' lorsque l'on filtre par 'USER' */}
                        {el?.full_name}
                      </p>
                      <span className="post">
                        {refaCtoNullKey(el?.job_title) !== ""
                          ? refaCtoNullKey(el?.job_title) + ", "
                          : refaCtoNullKey(el?.job_title)}

                        {refaCtoNullKey(el?.company_name)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="pagination">
        <Pagination
          className="pagination"
          currentPage={props?.currentPage}
          totalCount={props?.totalData}
          pageSize={18}
          onPageChange={(page: any) => props?.handlePageClick(page)}
        />
      </div>
    </div>
  );
}
