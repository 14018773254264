import './FindIsv.scss';
import React, { ChangeEvent, useState } from 'react';
import Header from '../../widget/index';
//@ts-ignore
import ReactCountryFlag from 'react-country-flag';
import Select from 'react-select';
import FilterListe from './bloc-find-isv/filter';
import UseMatchingIsv from '../../../controller/find-isv/use-matching-isv-ctr';
import { IsvProgramType } from '../../../interface/isv-type';
import { changeNumberValue, concatString, fileUrl } from '../../../utils';
import Pagination from '../../widget/use-pagination/pagination';
import { LoadingMatchResult } from '../../widget/loader';
import moment from 'moment';
import ModalUnlockIvestor from '../../widget/payement-investors/unlock-isv/index';
import ModalUnlockOem from '../../widget/payement-oem/unlock-isv/index';
import RenderPercent from './bloc-find-isv/render-percent';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import { OEM_PROGRAM } from "../../../service/constant";
import { Modal } from 'react-bootstrap';
/* TICKETS PROD: REMOVE FOR V2*/
import photoAddCompany from "../../../resources/images/addCompany.png";

export default function MatchIsv() {
  /* TICKETS PROD: REMOVE FOR V2*/
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();
  /* TICKETS PROD: REMOVE FOR V2*/

  const currentOemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer
  );
  const currentUser = useSelector((state: rootState) => state.userReducer);

  const userProvider = useSelector((state: rootState) => state.userReducer);
  const hasActiveProgram = userProvider?.oemProgramMembers?.find(
    (program: any) =>
      (program.oem_program?.status === true ||
        program.oem_program?.status == "1") &&
      program.oem_program?.uuid != ""
  );

  const currentInvestorPage = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );
  console.log(currentInvestorPage);
  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }
  const providerMatching = UseMatchingIsv();

  // if (
  //   currentOemProgram.program.status &&
  //   (!providerMatching.isAcceptedMatching ||
  //     !currentOemProgram.program.validate_by_devinsider)
  // ) {
  //   return <Redirect to={"/profil-not-found"} />;
  // }

  // if (
  //   (!hasActiveProgram ||
  //     !currentOemProgram.program.status ||
  //     currentOemProgram.program.uuid == "") &&
  //   currentUser.roles.includes(OEM_PROGRAM)
  // ) {
  //   return <Redirect to={"/profil-not-found"} />;
  // }

  // if (
  //   !currentInvestorPage.status ||
  //   (currentInvestorPage.status &&
  //     (!providerMatching.isAcceptedMatching ||
  //       !currentInvestorPage.validate_by_devinsider))
  // ) {
  //   return <Redirect to={"/profil-not-found"} />;
  // }

  if (!providerMatching?.isInvestor && !providerMatching?.isOemUser) {
    return <Redirect to={"/profil-not-found"} />;
  }

  return (
    <div className="findIsvcontainer">
      <Header reloadFilter={providerMatching.lunchFilter} />
      {/* BEGIN TO UNCOMMENT FOR V2*/}
      {/* {providerMatching?.isInvestor && (
        <ModalUnlockIvestor
          {...providerMatching.ProviderinvestorUnlockIsv}
          unlockedInvestor={() => providerMatching.unlockIsvPage()}
          loading={providerMatching.loading}
        />
      )}
      {providerMatching?.isOemUser && (
        <ModalUnlockOem
          {...providerMatching?.ProviderOemUnlockIsv}
          unlockedInvestor={() => providerMatching.unlockIsvPage()}
          loading={providerMatching.loading}
        />
      )}
      <FilterListe
        sliderValue={providerMatching?.sliderValue}
        setSliderValue={(value: Array<number>) =>
          providerMatching?.setSliderValue(value)
        }
        lunchFilter={() => providerMatching?.lunchFilter()}
        setHideType={(p: string) => providerMatching?.handleHideType(p)}
        hideType={providerMatching?.selectedHideType}
        setCountryType={(p: string) => providerMatching?.handleCountry(p)}
        selectedCountry={providerMatching?.selectedCountry}
        selectedTargetedIndustry={providerMatching?.selectedTargeted}
        setSelectedTargeted={(p: string) => providerMatching?.handleTargeted(p)}
        selectedSegment={providerMatching?.selectedSegment}
        setSelectedSegment={(p: string) => providerMatching?.handleSegment(p)}
        selectedAnnual={providerMatching?.selectedAnnual}
        setSelectedAnnual={(p: string) => providerMatching?.handleAnnual(p)}
        selectedCompanySize={providerMatching?.selectedCompanySize}
        setSelectedCompanySize={(p: string) =>
          providerMatching?.handleCompanySize(p)
        }
        resetFilter={() => providerMatching?.resetFilter()}
        userType={providerMatching?.userState?.roles?.[0]}
        selectedFundingRound={providerMatching?.selectedFunding}
        setSelectedFundingRound={(p: string) =>
          providerMatching?.handleFundingRound(p)
        }
        selectedFundraising={providerMatching?.selectedFundraising}
        setSelectedFundraising={(p: string) =>
          providerMatching?.handleFundraising(p)
        }
        filter_type="matching"
        unlocked_left={providerMatching.allIsvMatching.unlocked_left}
      /> */}
      {/* END TO UNCOMMENT FOR V2*/}

      {/* <div className="container mx-auto minHeightwin">
        {providerMatching?.isAccountNotActive ? (
          <div>
            <h1>
              {` You must wait for your ${
                providerMatching?.isOemUser ? "program" : "investor"
              } page to be validated by a
                devinsider admin before you can access this feature`}
            </h1>
          </div>
        ) : (
          <div className="listOfIsv">
            <div className="headerOfList">
              <div className="leftHead">
                <div className="number">
                  Showing{" "}
                  <b>
                    {changeNumberValue(
                      providerMatching?.allIsvMatching?.totalDataPaginate
                    )}
                  </b>{" "}
                  ISVs
                </div>
                <form
                  action=""
                  onClick={(e) => openBlock(e)}
                  onSubmit={(e) => e?.preventDefault()}
                >
                  <input
                    placeholder="Search..."
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      providerMatching?.setSearch_name(e?.target?.value)
                    }
                    value={providerMatching?.search_name}
                    onBlur={() =>
                      providerMatching?.search_name?.length <= 0 &&
                      providerMatching?.handleSearche()
                    }
                  />
                </form>
              </div>
              <div className="rightHead">
                <div className="sortedby labelSelect">
                  <label>Sorted by :</label>
                  <Select
                    className="triOreder"
                    isClearable={false}
                    options={providerMatching?.filtered}
                    defaultValue={providerMatching?.filtered[0]}
                    classNamePrefix="tri"
                    onChange={(filter: any) =>
                      providerMatching?.handleFilter(filter)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="scrollContainer">
              {providerMatching?.loaderMatching ? (
                <LoadingMatchResult />
              ) : (
                <ul className="listOfIsvMatch">
                  {providerMatching?.allIsvMatching?.isv?.map(
                    (isvMatching: IsvProgramType, index: number) => (
                      <li key={index}>
                        <div
                          className={`isvLogo ${
                            isvMatching?.isBlacklisted &&
                            !isvMatching?.is_unlocked
                              ? "blacklisted"
                              : ""
                          }`}
                        >
                          <img
                            src={fileUrl(isvMatching?.isv?.photo_profil)}
                            alt=""
                          />
                        </div>

                        <div className="isvInfo">
                          <h3 className="title">
                            <span className="name">
                              {isvMatching?.isv?.company_name}
                            </span>
                            {!isvMatching?.is_joining_soon && (
                              <span
                                className={`${
                                  isvMatching?.is_favorite
                                    ? "icon-star-on"
                                    : "icon-star-off"
                                }`}
                                onClick={() =>
                                  providerMatching?.addFavorite(
                                    isvMatching?.isv.id
                                  )
                                }
                              />
                            )}
                          </h3>
                          <div className="country">
                            <ReactCountryFlag
                              countryCode={isvMatching?.isv?.country?.cc_iso}
                              svg
                              title={isvMatching?.isv?.country?.country_name}
                            />
                            {isvMatching?.isv?.country?.country_name}
                          </div>
                          {isvMatching?.is_joining_soon ? (
                            <>
                              {" "}
                              <div className="matchValue mJoining">
                                Joining soon
                              </div>
                              <div className="containerAction">
                                <span className="icon-locked"></span>
                                Page locked
                              </div>
                            </>
                          ) : (
                            <>
                              <RenderPercent
                                isUnlock={isvMatching?.is_unlocked}
                                number={isvMatching?.matching_value}
                              />

                              {isvMatching?.is_unlocked ? (
                                <div className="containerAction">
                                  <div className="unlocked ">
                                    Unlocked by {isvMatching?.user_who_unlocks}
                                    <br />
                                    {moment(
                                      new Date(isvMatching?.unlocked_at)
                                    ).format("MMM DD. yyyy")}
                                  </div>

                                  {!isvMatching?.isBlacklisted ? (
                                    <div className="btn-group" role="group">
                                      <button
                                        className="cancel btn btn-devinsider"
                                        onClick={() =>
                                          providerMatching?.contactIsv(
                                            isvMatching?.isv?.id
                                          )
                                        }
                                      >
                                        Contact
                                      </button>
                                      <button
                                        className="cancel btn btn-devinsider"
                                        onClick={() =>
                                          providerMatching?.goToIsvPage(
                                            isvMatching?.isv.id
                                          )
                                        }
                                      >
                                        View
                                      </button>
                                    </div>
                                  ) : isvMatching?.is_unlocked ? (
                                    <div className="btn-group" role="group">
                                      <button
                                        className="cancel btn btn-devinsider"
                                        onClick={() =>
                                          providerMatching?.contactIsv(
                                            isvMatching?.isv?.id
                                          )
                                        }
                                      >
                                        Contact
                                      </button>
                                      <button
                                        className="cancel btn btn-devinsider"
                                        onClick={() =>
                                          providerMatching?.goToIsvPage(
                                            isvMatching?.isv.id
                                          )
                                        }
                                      >
                                        View
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              ) : (
                                <div className="containerAction">
                                  <div className="lockedPage">
                                    <span className="icon-locked"></span>Page
                                    Locked
                                  </div>
                                  {!isvMatching?.isBlacklisted && (
                                    <div className="btn-group" role="group">
                                      <button
                                        className="cancel btn btn-devinsider"
                                        onClick={() =>
                                          providerMatching?.goToIsvPage(
                                            isvMatching?.isv?.id,
                                            true
                                          )
                                        }
                                      >
                                        Preview
                                      </button>
                                      <button
                                        className={`cancel btn btn-devinsider ${
                                          isvMatching?.matching_value < 60 &&
                                          "disabled"
                                        } `}
                                        disabled={
                                          isvMatching?.matching_value < 60
                                        }
                                        onClick={() =>
                                          providerMatching?.unLockedPage(
                                            isvMatching?.isv?.id
                                          )
                                        }
                                      >
                                        Unlock
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
            <div className="pagginationCategory fixePagination">
              <div className="pagination">
                <Pagination
                  className="pagination"
                  currentPage={
                    providerMatching?.allIsvMatching?.currentPage || 1
                  }
                  totalCount={
                    providerMatching?.allIsvMatching?.totalDataPaginate
                  }
                  pageSize={providerMatching?.limit}
                  onPageChange={(page: any) =>
                    providerMatching?.loadMoreIsvMatching(page)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div> */}
      {/* TICKETS PROD: REMOVE FOR V2*/}
      <div className="NotFoundContainer">
        <div className="container mx-auto">
          <div className="contentBorder">
            <h3 className="title">4 0 4</h3>
            <div className="image">
              <img src={photoAddCompany} alt="" />
            </div>
            <div className="text-center sTitle">
              <p>Oops! page not found</p>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} className="small forDesactivate popPers">
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Find ISVs</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => setShowModal(false)}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            <p>
              We are currently on-boarding ISVs for matchmaking and will release
              this feature soon. In the meantime check out <br />
              the{" "}
              <a
                href="javascript:;"
                onClick={() => history.push("/community/home")}
              >
                community
              </a>{" "}
              where you can engage with ISVs and share your expertise.
            </p>
          </div>
        </Modal.Body>
      </Modal>
      {/* TICKETS PROD: REMOVE FOR V2*/}
    </div>
  );
}
