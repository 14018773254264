import { useEffect, useState } from "react";
import { getSStorage, setSessionStorageObject } from "../../../utils";
import { useHistory } from "react-router-dom";
import UseSliderCompanySize from "../../../screen/widget/slider-company-size-slider/use-slider-company-size-slider";
import UsePays from "../../../screen/widget/pays/use-Pays";
import UseSliderAnnualTurnOver from "../../../screen/widget/slider-annual-turnover/use-slider-annual-turnover";
import { useCompleteDataStorageOem } from "./use-complete-data-storage-oem";

export default function useCompleteCompanyTarget() {
  const history = useHistory();
  const { dataOem, addDataOem } = useCompleteDataStorageOem();
  const [defaultValueCompanySize, setDefaultValueCompanySize] = useState<
    Array<number>
  >([dataOem.minCompanySize || 0, dataOem.maxCompanySize || 10]);

  const [defaultValueFunding, setDefaultValueFunding] = useState<Array<number>>(
    [dataOem.minAnnualTurnover || 0, dataOem.maxAnnualTurnover || 600000000]
  );

  const [defaultValueCountry, setDefaultValueCountry] = useState<Array<any>>(
    JSON.parse(getSStorage("paysChecked") || "[]")
  );
  const [countryError, setCountryError] = useState<boolean>(false);
  const [errorAnnual, serErrorAnnual] = useState<boolean>(false);
  const [errorCompanySize, setErrorCompanySize] = useState<boolean>(false);

  const {
    companySize,
    setCompanySize,
    companySizeSlide,
    customSetCompanySize,
    setCompanySizeSlide,
  } = UseSliderCompanySize({ defaultValue: defaultValueCompanySize });

  const {
    annualTurnoverSlide,
    unitTurnover,
    unitTurnoverMax,
    customSetAnnualSize,
    annualTurnover,
    getResult,
  } = UseSliderAnnualTurnOver({ defaultValue: defaultValueFunding });

  const { allContinents, paysChecked, allContinentSelected, addPaysInArray } =
    UsePays(defaultValueCountry);

  const completeCompanySizeData = () => {
    let min = companySizeSlide[0];
    let max = companySizeSlide[1];
    if (min === 0 && max === 0) {
      return true;
    } else {
      return false;
    }
  };

  const completeAnnualData = () => {
    let min = getResult()[0];
    let max = getResult()[1];
    if (min === 0 && max === 0) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    if (paysChecked.length < 1) {
      setCountryError(true);
      return;
    }

    if (completeCompanySizeData()) {
      setErrorCompanySize(true);
      return;
    }

    if (completeAnnualData()) {
      serErrorAnnual(true);
      return;
    }
    const targetedCountry = paysChecked.map((e: any) => e.countrId);

    addDataOem({
      minCompanySize: companySizeSlide[0],
      maxCompanySize: companySizeSlide[1],
      minAnnualTurnover: getResult()[0],
      maxAnnualTurnover: getResult()[1],
      targetedCountry: JSON.stringify(targetedCountry),
    });

    setSessionStorageObject({
      paysChecked: JSON.stringify(paysChecked),
    });
    history.push("/complete-categories-oem");
  };

  useEffect(() => {
    setCountryError(false);
  }, [paysChecked]);

  return {
    setCompanySizeSlide,
    companySizeSlide,
    companySize,
    setCompanySize,
    customSetCompanySize,
    annualTurnoverSlide,
    unitTurnover,
    unitTurnoverMax,
    customSetAnnualSize,
    annualTurnover,
    allContinents,
    paysChecked,
    allContinentSelected,
    addPaysInArray,
    onSubmit,
    countryError,
    errorAnnual,
    errorCompanySize,
  };
}
