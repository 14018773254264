import React from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import TechnologieParteners from "../../../company/profil/strategy/modal/modal-edit-technologie-parteners";

type IProps = {
  show: boolean;
  cancelTechnologieParteners: () => void;
  updateTechnologyPartnerShip: () => void;
  loaderPartenership: boolean;
  providerTechnologyPartnerShip: any;
};
export default function EditPartnershipCompany(props: IProps) {
  return (
    <Modal
      show={props.show}
      className="createCompanypopup medium forStep5custom"
    >
      {/* smallPopup */}
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Technology partnership</h2>
          <a
            className="close"
            onClick={() => props.cancelTechnologieParteners()}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="withMinHeight">
        <TechnologieParteners {...props.providerTechnologyPartnerShip} />
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props.cancelTechnologieParteners()}
          >
            Cancel
          </button>
          <button
            className="btn btn-devinsider px-5"
            onClick={() => props.updateTechnologyPartnerShip()}
          >
            {props.loaderPartenership ? <Loader /> : "Save"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
