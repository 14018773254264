import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import {
  CONTRIBUTOR,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
} from "../../../../service/constant";
import { conversationType } from "../../../../utils/index";

type IpropMenu = {
  activeTab: number;
  setActiveTab: (idActive: number) => void;
  unreadeTypeNbr: any;
  userType: string;
  nbrRequest: number;
  nbrArchive: number;
  handleFilter: (type: any) => void;
};

export default function MenuSelectDiscussion(props: IpropMenu) {
  let tempFilter: any = props.unreadeTypeNbr;

  const { COMMUNITY, CLASSIFIED_ADS, FIND_PROGRAM, FIND_INVESTOR, FIND_ISV } =
    props.unreadeTypeNbr;

  const inputRef = useRef<any>(null);
  const onClear = () => {
    inputRef.current?.select.clearValue();
  };

  useEffect(() => {
    onClear();
  }, [props.activeTab]);

  const totalUnread =
    (COMMUNITY || 0) +
    (CLASSIFIED_ADS || 0) +
    (FIND_PROGRAM || 0) +
    (FIND_INVESTOR || 0) +
    (FIND_ISV || 0);

  const options = [
    {
      value: "COMMUNITY",
      label: (
        <div
          className="cat"
          dangerouslySetInnerHTML={{
            __html: `<div class="ico-container"><span class="icon-msg-community"></span><span class=${
              tempFilter?.["COMMUNITY"] > 0 && "non-lu"
            }>${tempFilter?.["COMMUNITY"] || ""}</span></div> Community`,
          }}
        />
      ),
      className: "community",
    },
    {
      value: "CLASSIFIED_ADS",
      label: (
        <div
          className="cat"
          dangerouslySetInnerHTML={{
            __html: `<div class="ico-container"><span class="icon-msg-classified-ads"></span><span class=${
              tempFilter?.["CLASSIFIED_ADS"] > 0 && "non-lu"
            }>${
              tempFilter?.["CLASSIFIED_ADS"] || ""
            }</span></div></div> Marketplace`,
          }}
        />
      ),
      classname: "ads",
    },
    // {
    //   value: "FIND_PROGRAM",
    //   label: (
    //     <div
    //       className="cat"
    //       dangerouslySetInnerHTML={{
    //         __html: `<div class="ico-container"><span class="icon-msg-find-programs"></span><span class=${
    //           tempFilter?.["FIND_PROGRAM"] > 0 && "non-lu"
    //         }>${
    //           tempFilter?.["FIND_PROGRAM"] || ""
    //         }</span></div></div> Find programs`,
    //       }}
    //     />
    //   ),
    //   classname: "findP",
    // },
    // {
    //   value: "FIND_INVESTOR",
    //   label: (
    //     <div
    //       className="cat"
    //       dangerouslySetInnerHTML={{
    //         __html: `<div class="ico-container"><span class="icon-msg-find-investors"></span><span class=${
    //           tempFilter?.["FIND_INVESTOR"] > 0 && "non-lu"
    //         }>${
    //           tempFilter?.["FIND_INVESTOR"] || ""
    //         }</span></div></div> Find investors`,
    //       }}
    //     />
    //   ),
    //   classname: "findI",
    // },
    // {
    //   value: "FIND_ISV",
    //   label: (
    //     <div
    //       className="cat"
    //       dangerouslySetInnerHTML={{
    //         __html: `<div class="ico-container"><span class="icon-msg-find-investors"></span><span class=${
    //           tempFilter?.["FIND_ISV"] > 0 && "non-lu"
    //         }>${tempFilter?.["FIND_ISV"] || ""}</span></div></div> Find ISVs`,
    //       }}
    //     />
    //   ),
    //   classname: "findI",
    // },
  ];

  const filterOption = (options: Array<any>, ...index: Array<number>) => {
    return options?.filter((el: any, i: number) => !index.includes(i));
  };
  const onValueChanged = (params: any) => {
    props.handleFilter(params);
  };

  const indexFilter = (): Array<number> =>
    props.userType === ISV
      ? [4]
      : props.userType === OEM_PROGRAM
      ? [2, 3]
      : props.userType === INVESTOR
      ? [2, 3]
      : props.userType === CONTRIBUTOR
      ? [1, 2, 3, 4]
      : [2, 3, 4];

  return (
    <div className="container">
      <div className={"row"}>
        <div className="left-tab">
          <ul className="nav" id="tab-left" role="tablist">
            <li
              className="nav-item messages"
              onClick={() => props.handleFilter({ value: "ALL" })}
            >
              <div className="ico-container">
                <span className="icon-msg-message"></span>
                <span className="non-lu">{totalUnread}</span>
              </div>
              <a
                className="nav-link active "
                aria-controls="home"
                aria-selected="true"
              >
                All messages
              </a>
            </li>
            <li className="nav-item selectCat">
              <Select
                classNamePrefix="react-select"
                ref={inputRef}
                autoFocus={false}
                isSearchable={false}
                placeholder={
                  <div className="cat placeHolder">
                    <div className="ico-container">
                      <span className="icon-msg-select-category"></span>
                      {/* <span className="non-lu">19</span> */}
                    </div>
                    <span className="txt">Select a category</span>
                  </div>
                }
                options={filterOption(options, ...indexFilter())}
                onChange={(params: any) => onValueChanged(params)}
              />
            </li>
          </ul>
        </div>

        <div className="right-tab">
          <ul className="nav nav-tabs" id="tab-left" role="tablist">
            <li className="nav-item">
              <a
                className={
                  "nav-link" + (props.activeTab === 0 ? " active" : "")
                }
                id="home-tab"
                data-toggle="tab"
                href="#conversation-tab"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={(e) => {
                  e?.preventDefault();
                  props?.setActiveTab(0);
                }}
              >
                <span>Conversations</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  "nav-link" + (props.activeTab === 1 ? " active" : "")
                }
                id="profile-tab"
                data-toggle="tab"
                href="#isv-request-tab"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={(e) => {
                  e?.preventDefault();
                  props?.setActiveTab(1);
                }}
              >
                <span>
                  {`Message requests   ${
                    props.nbrRequest ? "(" + props.nbrRequest + ")" : ""
                  }`}
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  "nav-link" + (props.activeTab === 2 ? " active" : "")
                }
                id="profile-tab"
                data-toggle="tab"
                href="#archive-tab"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={(e) => {
                  e?.preventDefault();
                  props?.setActiveTab(2);
                }}
              >
                <span>
                  {`Archive  ${
                    props.nbrArchive ? "(" + props.nbrArchive + ")" : ""
                  }`}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
