import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { rootState } from "../../../redux/reducers";
import { useSelector } from "react-redux";
import { postJsonDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import UseSearch from "../../../screen/widget/community/search/use-search";

export type activeFilterContributor =
  | "last-day"
  | "last-week"
  | "last-month"
  | "last-six-mounth"
  | "last-year"
  | "all-time";

export type ItemAuthorViewAll = {
  id: string;
  title: string;
  numberOfLikes: string;
  numberOfViews: string;
  labels: Array<{ id: number; title: string }>;
  created_at: string;
  updated_at: string;
  solve: boolean;
  user: {
    id: number;
    email: string;
    roles: Array<string>;
    username: string;
    last_name: string;
    first_name: string;
    file_name: string;
  };
  post_type: {
    id: number;
    type: string;
  };
  last_activity_by: {
    last_name: string;
    first_name: string;
    id: string;
  };
};
export type ItemAuthor = {
  order_rank: string;
  number_of_like: string;
  profil_picture: string;
  user_name: string;
  job_title: string;
  id: string;
};

export default function UseViewAllBestAnswerAuthor() {
  const location: any = useLocation();
  const history = useHistory();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [postType, setPostType] = useState("");

  const [cateGorieType, setCateGorieType] = useState<string>(
    location?.state?.categorie || ""
  );

  const [activeFilter, setActiveFilter] =
    useState<activeFilterContributor>("all-time");
  const [currentPage, setcurrentPage] = useState(1);

  const [loaderAuthor, setLoaderAuthor] = useState<boolean>(false);

  const [dataAuthor, setdataAuthor] = useState<{
    allData: Array<ItemAuthor>;
    totalData: number;
  }>({ allData: [], totalData: 0 });

  const [myRank, setMyRank] = useState<ItemAuthor>({
    order_rank: "",
    number_of_like: "",
    profil_picture: "",
    user_name: "",
    job_title: "",
    id: "",
  });
  const [topBestAuthor, setTopBestAuthor] = useState<Array<ItemAuthor>>([]);

  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();

  const getTopLike = async (
    page: number,
    postType: string,
    category: string,
    filter: string
  ) => {
    let params = {
      page: page,
      postType: postType,
      category: category,
      filter: filter,
    };
    setLoaderAuthor(true);
    const result = await postJsonDataService(
      url?.community?.view_all_top_best_answer,
      params,
      userReducer?.token
    );
    if (result?.status === 200) {
      let responseProvider = {
        allData: result?.data?.top_liked_answer,
        totalData: result?.data?.total_result_to_paginate,
      };
      setMyRank(result?.data?.my_ranked?.[0]);
      setdataAuthor(responseProvider);
      setTopBestAuthor(result?.data?.top_three);
      setLoaderAuthor(false);
    }
  };

  useEffect(() => {
    getTopLike(currentPage, postType, cateGorieType, activeFilter);
    return () => {};
  }, []);

  const setFilterAuthor = (p: activeFilterContributor) => {
    getTopLike(currentPage, postType, cateGorieType, p);
    setActiveFilter(p);
  };

  const handlePageClick = (p: number) => {
    getTopLike(p, postType, cateGorieType, activeFilter);
    setcurrentPage(p);
  };

  const showActivityUser = (idUser: string) => {
    history.push("/community/activity-user/" + idUser);
  };

  return {
    myRank,
    loaderAuthor,
    topBestAuthor,
    dataAuthor,
    activeFilter,
    setFilterAuthor,
    showActivityUser,
    currentPage,
    handlePageClick,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    communityReducer,
  };
}
