import React, { ChangeEvent } from 'react';
import Header from '../../../../widget/header-logo-center/header-logo-center';
import UseCompleteAboutExpert from '../../../../../controller/account-ctr/complete-create-acount-expert-contributor/use-complete-about-expert';
import useGoBack from '../../../../../hooks/use-navigate';
import { Redirect } from 'react-router-dom';
import { checkIfExist, getSStorage } from '../../../../../utils';

export default function CompleteAboutExpert() {
  const { aboutExpert, setAboutExpert, navigateCompletePostExpert } =
    UseCompleteAboutExpert();
  const { goBack } = useGoBack();

  const sess = getSStorage('businessEmailExpert');

  if (!checkIfExist(sess)) {
    return <Redirect to='/start-expert' />;
  }

  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <div className='head-getstarted'>
          <div className='stepInscription'>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem done'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
            <div className='stepItem'></div>
          </div>
          <a onClick={goBack} className='link-back'>
            <span className='icon-back'></span>
          </a>
          <h1>Create your account.</h1>
        </div>
        <div className='contentBorder border-top row py-3'>
          <div className='form-group col-12 p-0 m-0 descCompany'>
            <label htmlFor='first-name'>
              Tell other community members about yourself and your expertise.
              <div className='d-none'>
                <span className='error-red'> error </span>
                <span className='txt-error'>This field is required</span>
              </div>
            </label>
            <textarea
              value={aboutExpert}
              onChange={(evt: ChangeEvent<HTMLTextAreaElement>) =>
                setAboutExpert(evt.target.value)
              }
              maxLength={450}
            ></textarea>
            <div className='caractercount'>
              {' '}
              {0 + aboutExpert.length || 0} / 450
            </div>
          </div>
        </div>
        <div className='contentBorder border-top row align-items-stretch'>
          <div className='col-12 d-flex align-items-center pr-0'>
            <button
              className='btn btn-devinsider large containerBtnBtn'
              onClick={() => navigateCompletePostExpert()}
            >
              Next
            </button>
          </div>
          <a className='skip' onClick={() => navigateCompletePostExpert()}>
            Skip
          </a>
        </div>
      </div>
    </div>
  );
}
