/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "react-bootstrap/Modal";
import { IPropsEditSpecialization } from "../types";
import SolutionSpecialization from "../../../../widget/industries-list/index";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export default function EditinfoSpecialization(
  props: IPropsEditSpecialization
) {
  return (
    <>
      <Modal
        show={props.showSpecialization}
        className="createCompanypopup large forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Solution specialization</h2>
            <a className="close" onClick={() => props.closeSpecialisation()}>
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <SolutionSpecialization {...props} />
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right forPitch">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => props.closeSpecialisation()}
              type="button"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-devinsider px-5"
              onClick={() => props?.saveSelectedIndustrie()}
            >
              {props?.loader ? <Loader /> : "Save"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
