import React, { useMemo } from 'react';
import moment from 'moment';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../../../../redux/reducers';
import { Link } from 'react-scroll';
import { getUserType, isModerator } from '../../../../../utils';
import { NavLink } from 'react-router-dom';

export type IpropsFixed = {
  provider: any;
  classOfPostFix: any;
  postReply: () => void;
  loaderFavorite: boolean;
  addToFavorite: () => void;
  likedPost: () => void;
  loaderLike: boolean;
  showActivity: (user: any) => void;
  isProtectedReply: boolean;
  communityReducer: any;
};

const Fixed = (props: IpropsFixed) => {
  const isNotMyFavorite =
    !props?.communityReducer?.curentDiscutionReplyPost?.currentPost
      ?.isFavorited;

  const userReducer = useSelector((state: rootState) => state.userReducer);
  let currentPost =
    props?.communityReducer?.curentDiscutionReplyPost?.currentPost;

  return (
    <section className={props?.classOfPostFix}>
      <div className='container mx-auto'>
        <div className='badgePoster'>
          <div className='withPost'>
            <div className='imageAffiliatedcompany empty' />
            <div
              className={`nameOfPost ${getUserType(
                currentPost?.user?.roles?.[0]
              )}`}
            />
          </div>
          <div className='infoAffiliated'>
            <h3
              className='title'
              onClick={() => props?.showActivity(props?.provider?.user)}
            >
              {props?.provider?.user?.first_name}{' '}
              {props?.provider?.user?.last_name}
            </h3>
            <div className='post'>{props?.provider?.user?.job_title}</div>
            <b className='post'>{props?.provider?.user?.company_name}</b>
          </div>
        </div>
        <div className='topicSubject'>
          <div className='title'>
            <h2>{props?.provider?.title}</h2>
            <div className='date'>
              {props?.provider?.created_at &&
                moment(new Date(props?.provider?.created_at)).format(
                  'MMM D, YYYY h:mm a'
                )}
            </div>
          </div>
        </div>
        <div className='likeAndBtn'>
          <div
            className='like'
            onClick={() =>
              !isModerator(userReducer.roles) &&
              !props?.loaderLike &&
              props?.likedPost()
            }
          >
            <NavLink
              to='#'
              className={props?.provider?.liked ? 'active' : ''}
              onClick={(e) => e.preventDefault()}
            >
              <span
                className={
                  props?.communityReducer?.curentDiscutionReplyPost?.currentPost
                    ?.liked
                    ? 'icon-like-full'
                    : 'icon-like'
                }
              />
              like
            </NavLink>
            <b>{props?.provider?.numberOfLikes}</b> Likes
          </div>
          {!isModerator(userReducer.roles) && (
            <div className='btnContentSubject'>
              {isNotMyFavorite && (
                <NavLink
                  to='#'
                  className='addFav'
                  onClick={(e) => {
                    e.preventDefault();
                    props?.addToFavorite();
                  }}
                >
                  {props?.loaderFavorite ? <Loader /> : 'Add to favorites'}
                </NavLink>
              )}
              {!props?.isProtectedReply && (
                <Link
                  href='javascript:;'
                  to='scrollToHere'
                  smooth={true}
                  onClick={() => {
                    props?.postReply();
                  }}
                >
                  Reply
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default React.memo((props: IpropsFixed) => Fixed(props));
