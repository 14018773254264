import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';

import { useHistory } from 'react-router';
import store from '../../../redux/store';
import {
  GUEST,
  INVESTOR,
  ISV,
  MODERATOR,
  OEM_PROGRAM,
  RESELLER,
} from '../../../service/constant';
import UsePayementAdds from '../../../screen/widget/payement-isv/publish-adds/use-publish-adds';
import UsePayementGuest from '../../../screen/widget/payement-guest/payement-adds/use-payement-guest';
import UsePayementReseller from '../../../screen/widget/payement-reseller/publish-adds/use-publish-adds';
import UsePayementInvestor from '../../../screen/widget/payement-investors/publish-adds/use-publish-adds';

export default function Index() {
  const history = useHistory();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const findProgramReducer = useSelector(
    (state: rootState) => state?.findProgramReducer
  );

  const userReducer = useSelector((state: rootState) => state?.userReducer);

  const companyInvestorPage = useSelector(
    (state: rootState) => state?.companyInvestorPageReducer
  );

  const [activeCategorie, setactiveCategorie] = useState<number>(-1);
  const [activeAds, setActiveAds] = useState<number>(-1);

  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showAds, setShowAds] = useState<boolean>(false);
  const [showAdsModalP, setShowAdsModalP] = useState<boolean>(false);

  const [modalErroPlan, setModalErroPlan] = useState(false);
  const [allText, setAllText] = useState(['', '', '']);

  const isIsv = userReducer?.roles?.indexOf(ISV) !== -1;
  const isGuest = userReducer?.roles?.indexOf(GUEST) !== -1;
  const isReseller = userReducer?.roles?.indexOf(RESELLER) !== -1;
  const isInvestor = userReducer?.roles?.indexOf(INVESTOR) !== -1;

  var providerUsePayementAdds: any;
  var providerUsePayementGuest: any;
  var providerUsePayementReseller: any;
  var providerUsePayementInvestor: any;

  if (isIsv) {
    providerUsePayementAdds = UsePayementAdds();
  }

  if (isGuest) {
    providerUsePayementGuest = UsePayementGuest();
  }

  if (isReseller) {
    providerUsePayementReseller = UsePayementReseller();
  }

  if (isInvestor) {
    providerUsePayementInvestor = UsePayementInvestor();
  }

  const toogleModal = useCallback(
    (p: boolean) => {
      store?.getState().userReducer && setShow(p);
    },
    [show]
  );
  const toogleActiveCategorie = useCallback(
    (p: number) => {
      setactiveCategorie(p);
    },
    [activeCategorie]
  );

  const createDiscution = useCallback(
    (p: number) => {
      setShow(false);
      if (p === 0) {
        history?.push('/community/create-discution', {
          post_type: 1,
        });
      }
      if (p === 1) {
        history?.push('/community/create-article', {
          post_type: 2,
        });
      }
      if (p === 2) {
        history.push('/community/post/press-release', {
          post_type: 3,
        });
      }
      if (p === 3) {
        history.push('/community/post/program-review', { post_type: 4 });
      }
    },
    [show]
  );

  const toogleLoginModal = useCallback(
    (p: boolean) => {
      setShowLogin(p);
    },
    [showLogin]
  );

  const createPost = () => {
    userReducer?.enable ? setShow(true) : setShowLogin(true);
  };

  const createAds = () => {
    const isCompteActive = userReducer?.enable;
    console.log('isCompteActive', isCompteActive);
    if (isCompteActive) {
      //Is isv page not completed
      const showCompleteInfoAlert =
        userReducer?.roles?.[0] === ISV &&
        !userReducer?.isvInCompanyPage?.company_page?.completed;

      //Is investor page not valide
      const investorNotValidePage =
        userReducer?.roles?.[0] === INVESTOR &&
        !companyInvestorPage.validate_by_devinsider;

      if (showCompleteInfoAlert) {
        //return postErrorUi("classified-ads");
        setAllText([
          'Oops...',
          '',
          'You must be affiliated to a company page in order to publish classified ads',
        ]);
        setShowAdsModalP(true);
        return;
      }

      if (investorNotValidePage) {
        setAllText([
          'Oops...',
          '',
          'Please publish your investor page to access the "Post ad" feature.',
        ]);
        setShowAdsModalP(true);
        return;
      }
      showModalPostAdds();
    } else {
      setShowLogin(true);
    }
  };

  const toogleActiveAds = useCallback(
    (p: number) => {
      setActiveAds(p);
    },
    [activeAds]
  );

  const closeModalErroPlan = () => {
    modalErroPlan && setModalErroPlan(false);
  };

  const continueTopublishAdds = () => {
    isGuest && providerUsePayementGuest?.closeAddsModal();
    isIsv && providerUsePayementAdds?.closeAddsModal();
    isReseller && providerUsePayementReseller?.closeAddsModal();
    isInvestor && providerUsePayementInvestor?.closeAddsModal();
    setShowAds(true);
  };

  const showModalPostAdds = async () => {
    isGuest && providerUsePayementGuest?.showmodalAdds();
    isIsv && providerUsePayementAdds?.showmodalAdds();
    isReseller && providerUsePayementReseller?.showmodalAdds();
    isInvestor && providerUsePayementInvestor?.showmodalAdds();
  };

  const goToPostAds = (category: any) => {
    history.push('/classified-ads/post', {
      params: category,
    });
    setShowAds(false);
  };

  return {
    toogleModal,
    show,
    setShow,
    createDiscution,
    headerCategories: communityReducer?.discussionCategories?.map(
      (el: any) => ({ id: el?.id, label: el?.title })
    ),
    activeCategorie,
    setactiveCategorie,
    toogleActiveCategorie,
    showLogin,
    setShowLogin,
    createPost,
    showAds,
    setShowAds,
    createAds,
    activeAds,
    toogleActiveAds,
    toogleLoginModal,
    findProgramReducer,
    modalErroPlan,
    setModalErroPlan,
    closeModalErroPlan,
    allText,
    showAdsModalP,
    setShowAdsModalP,
    providerUsePayementAdds,
    providerUsePayementGuest,
    providerUsePayementReseller,
    providerUsePayementInvestor,
    isGuest,
    isIsv,
    isReseller,
    isInvestor,
    continueTopublishAdds,
    showModalPostAdds,
    goToPostAds,
  };
}
