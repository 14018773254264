import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { concatString, isURL, refaCtoNullKey } from "../../../../../utils";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { GUEST } from "../../../../../service/constant";

export type IpropsModal = {
  dataSource: any;
};

export default function ModalEditGuestCompany(props: IpropsModal) {
  return (
    <Modal
      show={props?.dataSource?.companyDetailsShow}
      onHide={() => props?.dataSource?.cancelChange()}
      className="modalDevinsider medium"
    >
      <form
        onSubmit={props?.dataSource?.resellerHandleSubmit(
          props?.dataSource?.updateCompanyResellerDetails
        )}
      >
        <Modal.Header closeButton>
          <Modal.Title>Company details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0">
            <div className="col-12 p-0 form-group required">
              <label htmlFor="company">Company trade name</label>
              {props?.dataSource?.errors.companyName?.type === "required" && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <input
                type="text"
                className="input-text form-control"
                {...props?.dataSource?.resellerRegister("tradeName", {
                  required: true,
                })}
              />
            </div>
            <div className="col-12 p-0 form-group required">
              <label htmlFor="company">Company legal name</label>
              {props?.dataSource?.errors.companyName?.type === "required" && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <input
                type="text"
                className="input-text form-control"
                {...props?.dataSource?.resellerRegister("legalName", {
                  required: true,
                })}
              />
            </div>
            <div className="col-12 p-0 form-group required">
              <label htmlFor="company">Website</label>
              {props?.dataSource?.errors.website?.type === "required" && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <input
                type="text"
                className="input-text form-control"
                {...props?.dataSource?.resellerRegister("website", {
                  required: true,
                })}
              />
            </div>

            <div className="col-6 form-group required p-0 pr-1">
              <label htmlFor="location">Location</label>
              {props?.dataSource?.errors.location?.type === "required" && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <select
                className="custom-select"
                {...props?.dataSource?.resellerRegister("location", {
                  required: true,
                })}
              >
                {props?.dataSource?.dataCompleted?.country?.map(
                  (element: any, index: number) => (
                    <option
                      key={index}
                      value={element.country_name}
                      selected={
                        element.country_name ==
                        props?.dataSource?.infoUserToEdit.country?.country_name
                      }
                    >
                      {`${element.country_name}`}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="col-6 form-group p-0 pl-1">
              <label htmlFor="first-name">&nbsp;</label>
              <input
                type="text"
                className="input-text form-control"
                {...props?.dataSource?.resellerRegister("city")}
                placeholder="City"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbtn"
            onClick={() => props?.dataSource?.cancelChange()}
          >
            Cancel
          </Button>
          <Button
            className="validate"
            type="submit"
            // disabled={props?.dataSource?.isLoaderShow}
          >
            {props?.dataSource?.isLoaderShow ? <Loader /> : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}