import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDataContext } from "../../../context/index";
import { RESELLER } from "../../../service/constant/index";
import { createResellerAccount } from "../../../service/applicatif/acount";
import useEventListener from "../../../hooks/event-handler";

export default function UseCompleteCompanyLogoReseller() {
  const history = useHistory();

  const { filePictureReseller, setFileLogoReseller, fileLogoreReseller } =
    useDataContext();
  const fileRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File>(fileLogoreReseller);
  const [isFilePicked, setIsFilePicked] = useState(
    fileLogoreReseller ? true : false
  );
  const [loader, setLoader] = useState<boolean>(false);
  const companyTradeName = sessionStorage.getItem("companyTradeName");
  const companyLegaleName = sessionStorage.getItem("companyTradeLegalName");

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      if (event?.target?.files[0]) {
        setFileLogoReseller(event?.target?.files[0]);
        setSelectedFile(event?.target?.files[0]);
        setIsFilePicked(true);
      }
    }
  };

  const handleBtn = () => {
    if (fileRef) {
      fileRef?.current?.click();
      ///setIsFilePicked(false);
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const createResellerAcount = async () => {
    setLoader(true);

    const valueOfOther = JSON.parse(
      sessionStorage.getItem("other_software") || "[]"
    )?.map((el: any) => ({
      id: el.idSolutionOffert,
      other: el.otherTxt?.trim(),
    }));

    let data = {
      email: sessionStorage.getItem("emailReseller"),
      password: sessionStorage.getItem("passWordReseller"),
      roles: RESELLER,
      firstName: sessionStorage.getItem("firstNameReseller"),
      lastName: sessionStorage.getItem("lastNameReseller"),
      jobTitle: sessionStorage.getItem("jobReseller"),
      phone: sessionStorage.getItem("phoneReseller"),
      fileName: filePictureReseller,
      labels: sessionStorage.getItem("labelsReseller") || "",
      country: sessionStorage.getItem("countryReseller"),
      companyName: sessionStorage.getItem("companyReseller"),
      phoneNumberCode: sessionStorage.getItem("phoneNumberCode"),
      dialCode: sessionStorage.getItem("dialCode"),
      "--": "--",
      _country: sessionStorage.getItem("companyLocation"),
      companyTradeName: sessionStorage.getItem("companyTradeName"),
      overview: sessionStorage.getItem("company_description"),
      companyLegalName: sessionStorage.getItem("companyTradeLegalName"),
      city: sessionStorage.getItem("companyCity"),
      website: sessionStorage.getItem("website"),
      keyCompetitive: sessionStorage.getItem("company_key_competitive"),
      targetedIndustry: JSON.stringify(
        JSON.parse(sessionStorage.getItem("target_company") || "[]")?.map(
          (el: any) => el.id
        )
      ),
      targetedCountry: JSON.stringify(
        JSON.parse(sessionStorage.getItem("pays_company") || "[]")?.map(
          (el: any) => el.countrId
        )
      ),
      segmentDetails: JSON.stringify(
        JSON.parse(sessionStorage.getItem("solution_company") || "[]")?.map(
          (el: any) => el.idSolutionOffert
        )
      ),
      otherSegmentDetails: JSON.stringify(valueOfOther),

      photoProfil: fileLogoreReseller,
    };

    let createResellerResponse = await createResellerAccount(data);
    setLoader(false);
    if (createResellerResponse && createResellerResponse.status == 200) {
      sessionStorage.clear();
      history.push("./complete-account", {
        email: data.email,
      });
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      createResellerAcount();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    fileRef,
    changeHandler,
    handleBtn,
    selectedFile,
    isFilePicked,
    goBack,
    fileLogoreReseller,
    createResellerAcount,
    companyTradeName,
    companyLegaleName,
    loader,
  };
}
