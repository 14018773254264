import { IAction } from "../../types/type-action";
import {
  OemProgram,
  OemProgramReducer,
} from "../../../controller/oem-ctr/type";

import * as CONSTANTE from "../../actions/oem-program-action";
import { oem_roles } from "../../../utils";

const initialState: OemProgramReducer = {
  program: {
    id: "0",
    program_name: "",
    city: "",
    country: {
      cc_iso: "",
      code: "",
      country_name: "",
      id: 0,
    },
    company_name: "",
    description: null,
    min_company_size: 0,
    benefits: null,
    created_at: "",
    iAmTheProgramOwner: false,
    isv_annual_turnover: null,
    isv_company_size: null,
    location: null,
    max_annual_turnover: "",
    max_company_size: 0,
    min_annual_turnover: "",
    oem_program_type: { id: 0, name: "" },
    oem_program_type_category: [],
    segment_details: [],
    oem_subscription: { id: 0, name: "", isv_unlock_amount: 0 },
    photo_couverture: "",
    photo_profil: "",
    photo_profil_draft: "",
    photo_couverture_draft: "",
    segments: [],
    targeted_country: [],
    targeted_industry: [],
    uuid: "",
    website: "",
    solutions: null,
    validate_by_devinsider: false,
    billing_owner_email: "",
    payment_method: "",
    subscription_begin_at: "",
    subscription_end_at: "",
    oemProgramMembers: [],
    membersInvitations: [],
    oemProgramResources: [],
    billing_owner_username: "",
    oemProgramOverviews: [],
    oemProgramSolutions: [],
    oemProgramBenefit: [],
    oemProgramStarted: [],
    number_of_monthly_budget: 0,
    status: false,
    exist_draft: false,
    updated_at: "",
    oemProgramBillingDetails: {
      max_unlock_number: 0,
      post_event_number: 0,
      post_featured_article_number: 0,
      number_unlock_isv_max_in_subscription: 0,
      unlock_isv_number: 0,
      number_unlock_isv_not_in_subscription: 0,
    },
  },
  draft: {
    companyName: null,
    oemProgramType: null,
    oemProgramTypeCategory: [],
    programName: null,
    website: null,
    solutions: [],
    benefits: [],
    description: [],
    started: [],
    city: null,
    country: null,
  },
  editMode: false,
};

export default function oemProgramReducer(
  state = initialState,
  action: IAction
) {
  switch (action.type) {
    //Dev #45972,V2.1 [OEM/Ecosystem user interface] le pop-up "change billing owner" n'est pas du tout comme sur le mockup
    case CONSTANTE.UDPTADE_BILLING_OWNER_PROGRAM:
      let newOemMembers: any = { ...state };
      newOemMembers = newOemMembers.program.oemProgramMembers;
      newOemMembers.forEach((element: any) => {
        if (action.payload.user_email === element.user.email)
          element.is_billing_owner = true;
        else element.is_billing_owner = false;
      });
      return {
        ...state,
        program: {
          ...state.program,
          billing_owner_email: action.payload.user_email,
          billing_owner_username:
            action.payload.user_first_name +
            " " +
            action.payload.user_last_name,
          oemProgramMembers: newOemMembers,
        },
      };
    //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
    case CONSTANTE.UNPUBLISH_PROGRAM:
      return { ...state, program: { ...state.program, status: false } };
    // Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
    case CONSTANTE.UPDATE_STATUS_OEM_PROGRAM:
      let newProgramFromStatus = { ...state.program };
      newProgramFromStatus.uuid = action.payload.uuid;
      newProgramFromStatus.status = action.payload.status;
      return {
        ...state,
        program: newProgramFromStatus,
      };
    case CONSTANTE.REMOVE_COUVERTURE_OEM_PROGRAM:
      let newProgram = { ...state.program };
      newProgram.photo_couverture = action.payload;
      newProgram.photo_couverture_draft = "";
      return {
        ...state,
        program: newProgram,
      };
    case CONSTANTE.REMOVE_LOGO_OEM_PROGRAM:
      let newProgramFromState = { ...state.program };
      newProgramFromState.photo_profil = action.payload;
      newProgramFromState.photo_profil_draft = "";
      return {
        ...state,
        program: newProgramFromState,
      };
    case CONSTANTE.UPDATE_OEM_PROGRAM_BILLING_DETAILS:
      return {
        ...state,
        program: { ...state.program, oemProgramBillingDetails: action.payload },
      };
    case CONSTANTE.SET_OEM_PROGRAM:
      return { ...state, program: { ...state.program, ...action.payload } };
    case CONSTANTE.INITIALISE_DRAFT:
      return {
        ...state,
        draft: action.payload,
        program: {
          ...state?.program,
        },
      };
    case CONSTANTE.SET_OEM_PROGRAM_DRAFT:
      return {
        ...state,
        draft: action.payload?.draft,
        program: {
          ...state?.program,
          exist_draft: action.payload?.exist_draft,
          updated_at: action.payload?.updated_at,
        },
      };
    case CONSTANTE.SET_OEM_EDIT_MODE:
      return { ...state, editMode: action.payload };
    case CONSTANTE.UPDATE_OEM_PROGRAM_CARD_INFORMATION:
      let newState = { ...state };
      newState.program.payment_method = action.payload;
      return newState;
    case CONSTANTE.UPDATE_DRAFT_RESSOURCE_DATA:
      return {
        ...state,
        editMode: action.payload,
        program: {
          ...state?.program,
          exist_draft: true,
        },
      };
    case CONSTANTE.UPDATE_DRAFT_WYSIWYG_DATA:
      return {
        ...state,
        program: {
          ...state?.program,
          [action?.payload?.key]: customEditWysiwyg(
            action?.payload?.data,
            action?.payload?.key
          ),
          exist_draft: action?.payload?.data?.exist_draft,
          updated_at: action?.payload?.data?.updated_at,
        },
      };
    case CONSTANTE.UPDATE_OEM_SETTINGS:
      return {
        ...state,
        program: {
          ...state?.program,
          ...action?.payload,
        },
      };
    case CONSTANTE.GET_OEM_ATTRIBUT:
      return {
        ...state,
        program: {
          ...state?.program,
          [action?.payload?.key]: action?.payload?.data,
        },
      };
    case CONSTANTE.SEND_INVITATION_ROLE:
      return {
        ...state,
        program: {
          ...state?.program,
          membersInvitations: helperFindRemove(
            state?.program?.membersInvitations,
            action.payload
          ),
        },
      };

    case CONSTANTE.CANCEL_INVITATION_AFFILIATED:
      return {
        ...state,
        program: {
          ...state.program,
          membersInvitations: helperFindRemove(
            state?.program?.membersInvitations,
            action.payload
          ),
        },
      };
    case CONSTANTE.PUPBLISH_OR_UPDATE:
      return {
        ...state,
        program: {
          ...state?.program,
          ...action.payload,
        },
        draft: action?.payload?.oem_program_draft,
      };

    case CONSTANTE.UPDATE_BILLING_DETAIL:
      return {
        ...state,
        program: {
          ...state?.program.oemProgramBillingDetails,
          ...action.payload,
        },
      };

    case CONSTANTE.UPDATE_UNLOCK_BILLING_DETAIL:
      return {
        ...state,
        program: {
          ...state?.program,
          oemProgramBillingDetails: {
            ...state.program.oemProgramBillingDetails,
            unlock_isv_number: action.payload,
          },
        },
      };

    case CONSTANTE.REMOVE_OEM_ROLE:
      return {
        ...state,
        program: {
          ...state?.program,
          oemProgramMembers: removeRoleHelper(
            state.program.oemProgramMembers,
            action.payload
          ),
        },
      };

    case CONSTANTE.UPDATE_ROLE_MEMBER_IN_OEM_PROGRAM:
      //update from new role
      const tempPayload = action.payload.data;
      let newOemProgramMember = state?.program?.oemProgramMembers?.map(
        (oemMember: any) => {
          const found =
            oemMember?.user?.id?.toString() ===
            tempPayload.user?.id?.toString();
          if (found) {
            return tempPayload;
          } else {
            return oemMember;
          }
        }
      );

      let updateOwnerToAdmin = newOemProgramMember;
      const checkIfUpdatePOwner = tempPayload?.role === oem_roles.owner;
      if (checkIfUpdatePOwner) {
        const idUserDownGrad = action?.payload.idDowngrad;
        updateOwnerToAdmin = newOemProgramMember.map((tempOem) => {
          if (tempOem.user.id === idUserDownGrad) {
            tempOem.role = oem_roles.admin;
          }
          return tempOem;
        });
      }
      return {
        ...state,
        program: {
          ...state?.program,
          oemProgramMembers: updateOwnerToAdmin,
        },
      };

    default:
      return state;
  }
}

const helperFindRemove = (state: any, value: any) => {
  let found = state?.find(
    (el: any) => el?.id?.toString() === value?.id.toString()
  );
  let returnValue = [];

  if (found) {
    returnValue = [
      ...state?.filter(
        (el: any) => el.id?.toString() !== value?.id?.toString()
      ),
    ];
  } else {
    returnValue = [value, ...state];
  }
  return returnValue;
};

const customEditWysiwyg = (data: any, key: string) => {
  if (key === "oemProgramOverviews") {
    return data?.oemProgramOverview;
  }
  if (key === "oemProgramBenefit") {
    return data?.oemProgramBenefit;
  }
  if (key === "oemProgramSolutions") {
    return data?.oemProgramSolution;
  }
  if (key === "oemProgramStarted") {
    return data?.oemProgramStarted;
  }
};

const removeRoleHelper = (state: any, value: any): Array<any> => {
  let tempState = state?.filter(
    (el: any) => el?.user?.id?.toString() !== value?.user_id?.toString()
  );
  return tempState;
};
