import Header from '../../../../widget/';
import UseCommunityPRCtr, {
  ITagsProps,
} from '../../../../../controller/community-ctr/bloc-community-ctr/use-press-release-ctr';
import FormPost from '../../../../widget/post-wysiwig/index';
import { ChangeEvent, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Footer from '../../../../widget/footer/footer';
import ModalPayementPR from '../../../../widget/payement-isv/publish-pr';
import ModalPreview from '../../../../widget/preview/index';
import { Link } from 'react-router-dom';
import PressReleaseComponent from '../../../../../hooks/PressReleaseComponent';

export default function StartPressRelease() {
  const providerCommunity = { ...UseCommunityPRCtr() };
  const [modalPreview, setModalPreview] = useState<boolean>(false);

  useEffect(() => {
    const root = document.getElementsByClassName('rdw-option-wrapper');
    const foontSizeWrapper = document.getElementsByClassName(
      'rdw-fontsize-wrapper'
    );
    const fontFamillyWrapper = document.getElementsByClassName(
      'rdw-fontfamily-wrapper'
    );
    const monospace = root[3];
    const subsciprt = root[4];
    const font = root[5];
    const puiss = root[6];
    if (monospace) {
      monospace.className = 'd-none';
      subsciprt.className = 'd-none';
      font.className = 'd-none';
      puiss.className = 'd-none';
    }

    if (foontSizeWrapper) {
      foontSizeWrapper[0].className = 'd-none';
    }

    if (fontFamillyWrapper) {
      fontFamillyWrapper[0].className = 'd-none';
    }
  }, []);

  useEffect(() => {
    if (providerCommunity?.errorAffiliated) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Complete information</h1>
              <p>
                You must be affiliated to a company page in order to publish a
                press release
              </p>
            </div>
          );
        },
      });
      providerCommunity?.setErrorAffiliated(false);
    }
  }, [providerCommunity?.errorAffiliated]);

  const errorTags = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Error</h1>
            <p>Select at least 1 tag and at most 3 tags</p>
          </div>
        );
      },
    });
  };

  const handleModal = () => {
    setModalPreview(!modalPreview);
  };
  return (
    <PressReleaseComponent>
      <div>
        <Header />
        <ModalPayementPR
          {...providerCommunity.providerUsePayementPr}
          loading={providerCommunity.loading}
          publishPR={() => providerCommunity.publishPR()}
        />
        <ModalPreview
          showModal={modalPreview}
          handleModal={handleModal}
          content={providerCommunity?.stateFormWysywig}
        />

        <section className='discussion'>
          <div className='container mx-auto'>
            <ul className='breadcrumbPerso'>
              <li>
                <a href='javascript:;'>Community</a>
              </li>
              <li>New press release</li>
            </ul>
            <div className='rightCommunityForum allWidth oneCateg postContainer'>
              <div className='labelLeft'>
                <div className='block'>
                  <h2 className='title'>Tags</h2>
                  <div className='form-control relative'>
                    <input
                      type='text'
                      className='form-control search-msg lab'
                      placeholder='Search'
                      value={providerCommunity?.txtFilter}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                        providerCommunity?.setTxtFilter(evt.target.value)
                      }
                    />

                    <div
                      className={`listOfChoice ${
                        providerCommunity?.dataTagsFilter?.length < 1
                          ? 'd-none'
                          : ''
                      }`}
                    >
                      <ul>
                        {providerCommunity?.dataTagsFilter?.map((el: any) => (
                          <li
                            onClick={() =>
                              providerCommunity.handleFilterSelect(el?.value)
                            }
                          >
                            {el?.value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <label htmlFor=''>Choose a Tag:</label>

                  {providerCommunity?.errorTags && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>
                        You need to select at least 1 label and no more than 3
                        labels
                      </span>
                    </div>
                  )}
                  <div className='listOfTopic'>
                    {providerCommunity?.tags?.map(
                      (tag: ITagsProps, index: number) => (
                        <Link
                          to={'#'}
                          key={index}
                          id={tag.id}
                          className={
                            providerCommunity?.dataListe?.find(
                              (el) => el.trim() === tag?.name.trim()
                            )
                              ? 'topic active'
                              : 'topic'
                          }
                          onClick={() =>
                            providerCommunity?.handleSelectedLabels(
                              ' ' + tag?.name
                            )
                          }
                        >
                          {tag.name}
                        </Link>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className='containerCategory'>
                <h2 className='title'>
                  New press release{' '}
                  {providerCommunity?.titleError && (
                    <div className='container-error pl-2 d-inline'>
                      <span className='error-red'> error </span>
                      <span className='txt-error'>Subject is required</span>
                    </div>
                  )}
                </h2>
                <FormPost
                  //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
                  mention={providerCommunity?.mentions}
                  updateMention={providerCommunity?.updateMention}
                  editorState={providerCommunity?.stateFormWysywig}
                  setEditorState={providerCommunity?.setEditorStatePost}
                  sendPost={() => {
                    providerCommunity?.errorTags
                      ? errorTags()
                      : providerCommunity?.post();
                  }}
                  cancelPost={() => providerCommunity?.cancelPost()}
                  object={providerCommunity?.object}
                  setObject={(evt: string) =>
                    providerCommunity?.setTxtObject(evt)
                  }
                  loader={providerCommunity?.loader}
                  showPreview={() => handleModal()}
                />
              </div>
            </div>
          </div>
        </section>
        <Footer postType={'3'} />
      </div>
    </PressReleaseComponent>
  );
}
