import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { getSStorage, setSessionStorageObject } from "../../../../utils";
import * as Constante from "./constante";

export default function UseCreate() {
  const [selected, setselected] = useState<string>(
    sessionStorage?.getItem("codePays") || "US"
  );
  const [dialCodeSelected, setDialCodeSelected] = useState<string>(
    sessionStorage?.getItem("dialCode") || "+1"
  );
  const history = useHistory();
  const [result, setResult] = useState("");
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      country: sessionStorage?.getItem("country") || "",
      phoneNumber: sessionStorage?.getItem("phoneNumber") || "",
      company_name: sessionStorage?.getItem("companyName") || "",
      job_title: sessionStorage?.getItem("job_title") || "",
      codePays: sessionStorage?.getItem("selected") || "",
      dialCode: sessionStorage?.getItem("dialCode") || "",
    },
  });

  const onSubmit = (data: any) => {
    setSessionStorageObject({
      country: data?.country || "",
      phoneNumber: data?.phoneNumber || "",
      companyName: sessionStorage?.getItem("companyName") || "",
      job_title: data?.job_title || "",
      codePays: selected || "",
      dialCode: dialCodeSelected
    });
    history.push({
      pathname: "/complete-oem-profil-affilliated",
    });
  };
  const onBack = () => {
    history.goBack();
  };

  return {
    register,
    handleSubmit,
    result,
    setResult,
    onSubmit,
    onBack,
    errors,
    selected,
    setselected,
    country: dataCompleted?.country,
    dialCodeSelected,
    setDialCodeSelected,
    first_name: sessionStorage?.getItem("first_name") || "",
  };
}
