import Header from '../../../../widget/header-logo-center/header-logo-center';
import StepperOem from '../../../../widget/stepper/StepperOem';
import React from 'react';
import useCompleteIndustryOem from '../../../../../controller/account-ctr/create-oem-account/use-complete-industry-oem';
import IndustriesLists from '../../../../widget/industries-list';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import { checkIfExist, getSStorage } from '../../../../../utils';
import { Redirect } from 'react-router-dom';

export default function CompleteIndustryOem() {
  const {
    companyTargetSelected,
    companyTargetSecound,
    companyTarget,
    addTargetCompany,
    lastCompanyTarget,
    onSubmit,
    hasError,
    loading,
  } = useCompleteIndustryOem();

  const sess: any = getSStorage('oemProject');

  if (!checkIfExist(sess)) {
    return <Redirect to='/partner-program-registration' />;
  }

  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <StepperOem step={3} />
        <div className='contentBorder border-top row py-2'>
          <div className='col-12 form-group required mb-0'>
            <label htmlFor='jobtitle required'>
              Define which industries the ISV should target to be qualified for
              your program.
            </label>
          </div>
          <div className='col-12'>
            {hasError && (
              <div className='container-error d-inline'>
                <span className='error-red'> error </span>
                <span className='txt-error'>This field is required</span>
              </div>
            )}
          </div>
          <IndustriesLists
            addTargetCompany={addTargetCompany}
            companyTarget={companyTarget}
            companyTargetSecound={companyTargetSecound}
            companyTargetSelected={companyTargetSelected}
            lastCompanyTarget={lastCompanyTarget}
          />

          <div className='contentBorder border-top row align-items-stretch'>
            <button
              type='submit'
              className='btn btn-devinsider large'
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? <Loader /> : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
