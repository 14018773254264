import React, { useEffect, useState, useCallback } from "react";
import store from "../../../../redux/store";
import { getDataService } from "../../../../service/applicatif/back-end-service";
import { url } from "../../../../service/constant";
import { useHistory } from "react-router-dom";

export default function Index() {
  const history = useHistory();
  /* get discussion category */
  const [discussionAboutCommunity, setDiscussionAboutCommunity] = useState<
    Array<{
      title: string;
      number_of_activity: string;
      last_activity: null | string;
    }>
  >([]);

  useEffect(() => {
    //get discussion about community
    (async () => {
      const result = await getDataService(
        url.community.get_discussion_about_community,
        {},
        store.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        setDiscussionAboutCommunity(result?.data);
      }
    })();
  }, []);

  const showAllAboutCommunity = (idCategorie: any) => {
    history.push("/community/view-other-category/" + idCategorie, {
      params: idCategorie,
    });
  };

  return {
    discussionAboutCommunity,
    showAllAboutCommunity,
  };
}
