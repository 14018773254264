import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as T from '../../../screen/container/investor/profil-investor/types';
import { isInObject } from '../../../utils';
import useSliderFD from '../../../screen/widget/slider-fundraising/use-slider-fundraising';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import { updateDraftDataAction } from '../../../redux/actions/company-page-investor-action/my-investor-page-action';
import { useParams } from 'react-router-dom';

export default function UseEditInfoDetailLeftCtr() {
  const [showEditInfoLeft, setShowEditInfoLeft] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('US');
  const [errorFundraising, setErrorFundraising] = useState<boolean>(false);
  const [valueFundraising, setValueFundraising] = useState<Array<number>>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;

  const CP_Investor = useSelector((state: rootState) =>
    !isOtherUser
      ? state.companyInvestorPageReducer
      : state?.otherCompanyInvestorPageReducer
  );
  let CP_DRAFT_DATA = JSON.parse(CP_Investor.investor_page_draft || '{}');
  //Fundraising
  const {
    unitFundraisingGoal,
    unitFundraisingGoalMax,
    fundraisingGoal,
    customFundraisingGoalSlide,
    fundraisingGoalSlide,
    setFundraisingGoal,
  } = useSliderFD({ defaultValue: valueFundraising });

  useEffect(() => {
    if (errorFundraising) {
      setErrorFundraising(false);
    }
    return () => {};
  }, [fundraisingGoalSlide]);
  /**
   * Initialise foundraising goal
   */
  useEffect(() => {
    setValueFundraising([
      CP_DRAFT_DATA?.min_fundraising_goal
        ? CP_DRAFT_DATA?.min_fundraising_goal
        : CP_Investor?.min_fundraising_goal,

      CP_DRAFT_DATA?.max_fundraising_gol
        ? CP_DRAFT_DATA?.max_fundraising_gol
        : CP_Investor?.max_fundraising_gol,
    ]);
    setValue(
      'email',
      CP_DRAFT_DATA?.email ? CP_DRAFT_DATA?.email : CP_Investor?.email
    );
    setValue(
      'nameOfOrganisation',
      isInObject(CP_DRAFT_DATA, 'organization_name')
        ? CP_DRAFT_DATA?.organization_name
        : CP_Investor?.organization_name
    );
    setValue(
      'phoneNumber',
      CP_DRAFT_DATA?.phone_number
        ? Number(CP_DRAFT_DATA?.phone_number)
        : Number(CP_Investor?.phone_number)
    );
    setSelected(
      CP_DRAFT_DATA?.phone_number_code
        ? CP_DRAFT_DATA?.phone_number_code
        : CP_Investor?.phone_number_code
    );
    return () => {};
  }, [
    CP_Investor?.min_fundraising_goal,
    CP_Investor?.max_fundraising_gol,
    params?.id,
  ]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm<T.FormLeft>({
    mode: 'onSubmit',
  });

  const cbUpdateDraft = (data: any) => {
    setLoader(false);
    setShowEditInfoLeft(false);
  };

  const submitForm = async (data: T.FormLeft) => {
    if (unitFundraisingGoal == '' || unitFundraisingGoalMax == '') {
      setErrorFundraising(true);
    } else {
      let lastValue = JSON.parse(CP_Investor?.investor_page_draft || '{}');
      let newValue = {
        organization_name: data?.nameOfOrganisation,
        email: data?.email,
        min_fundraising_goal:
          unitFundraisingGoal == 'K USD'
            ? fundraisingGoalSlide[0] * 1000
            : fundraisingGoalSlide[0] * 1000000,
        max_fundraising_gol:
          unitFundraisingGoalMax == 'K USD'
            ? fundraisingGoalSlide[1] * 1000
            : fundraisingGoalSlide[1] * 1000000,
        phone_number: data?.phoneNumber,
        phone_number_code: selected,
      };

      setLoader(true);
      dispatch(
        updateDraftDataAction(
          {
            investorPageDraft: JSON.stringify(
              Object.assign(lastValue, newValue)
            ),
          },
          CP_Investor.id,
          cbUpdateDraft
        )
      );
    }
  };

  return {
    showEditInfoLeft,
    setShowEditInfoLeft,
    submitForm,
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    errorFundraising,
    errors,
    selected,
    setSelected,
    unitFundraisingGoal,
    unitFundraisingGoalMax,
    fundraisingGoal,
    customFundraisingGoalSlide,
    fundraisingGoalSlide,
    setFundraisingGoal,
    loader,
  };
}
