import React, { useState, useEffect } from 'react';

import { getSStorage } from '../../../utils';

export interface ItemsSolution {
  id: number;
  name: string;
}
export interface ISolutionListe {
  id: number;
  name: string;
  segmentDetails: Array<ItemsSolution>;
}

export type TPropsData = {
  defaultValue: Array<{ id: number; name: string }>;
  defaultValueFeatured: Array<{ id: number; name: string }>;
  maxSolutions?: number;
};

export interface IdataMakeSolution {
  idSolutionOffert: number;
}
export default function UseCompleteCategoryInvestor(props: TPropsData) {
  const [allSolution, setAllResellerSolution] = useState<Array<ISolutionListe>>(
    []
  );

  const [solutionLeftColumnData, setSolutionLeftColumnData] = useState<
    Array<ISolutionListe>
  >([]);

  const [solutionRigthColumnData, setSolutionRigthColumnData] = useState<
    Array<ISolutionListe>
  >([]);

  const [solutionChecked, setSolutionChecked] = useState<
    Array<{ id: number; name: string }>
  >(props?.defaultValue || []);

  const [solutionCheckedFeatures, setSolutionCheckedFeatured] = useState<
    Array<{ id: number; name: string }>
  >(props?.defaultValueFeatured || []);

  const [featuredSolution, setFeaturedSolution] = useState<ISolutionListe>({
    id: 0,
    name: '',
    segmentDetails: [],
  });

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage('completed_account') || '{}')
  );

  const filterSolutionChecked = (solutions: any) => {
    let alreadyExist: Array<string> = [];
    return solutions?.filter((el: any) => {
      if (alreadyExist.indexOf(el.name) === -1) {
        alreadyExist.push(el.name);
        return el;
      }
      if (el.name === "Other") return true;
    });
  };

  /**
   * @returns initialise solution selected
   */
  useEffect(() => {
    if (props?.defaultValueFeatured?.length > 0) {
      setSolutionCheckedFeatured(props?.defaultValueFeatured);
    }
    return () => {};
  }, [props?.defaultValueFeatured]);

  /**
   * @returns initialise solution selected
   */
  useEffect(() => {
    if (props?.defaultValue?.length > 0) {
      setSolutionChecked(props?.defaultValue);
    }
    return () => {};
  }, [props?.defaultValue]);

  useEffect(() => {
    let lengthSolution = allSolution?.length;
    if (lengthSolution % 2 === 0) {
      setSolutionLeftColumnData(allSolution?.slice(0, lengthSolution / 2));
      setSolutionRigthColumnData(
        allSolution?.slice(lengthSolution / 2, lengthSolution + 1)
      );
    } else {
      setSolutionLeftColumnData(
        allSolution?.slice(0, (lengthSolution - 1) / 2 + 1)
      );
      setSolutionRigthColumnData(
        allSolution?.slice((lengthSolution - 1) / 2 + 1, lengthSolution + 1)
      );
    }
    return () => {};
  }, [allSolution]);

  useEffect(() => {
    async function getSolution() {
      let featured = dataCompleted?.segments?.find((el: any) => el.id === 9999);
      setFeaturedSolution(featured);
      setAllResellerSolution(
        dataCompleted?.segments?.filter((el: any) => el.id !== 9999)
      );
    }
    getSolution();
    return () => {};
  }, []);

  const removeItemSelected = (solution: any) => {
    let found = solutionChecked?.find((el: any) => el.id == solution?.id);
    //found of featured solution
    let found2 = solutionCheckedFeatures?.find(
      (el: any) => el?.name == solution?.name
    );
    console.log(found, found2)
   setSolutionChecked(
     solutionChecked?.filter((elem: any) => elem?.id != solution?.id)
   );
   if (found2)
     setSolutionCheckedFeatured(
       solutionCheckedFeatures?.filter((elem: any) => elem?.id != found2?.id)
     );
  };

  const isNameIncludes = (value1: string, value2: string) => {
    if (
      !value1?.toLowerCase().includes("other") &&
      !value2?.toLowerCase().includes("other") &&
      value2?.trim() == "Analytics Software" &&
      value1?.trim() == "Sales Analytics Software"
    ) {
      return false;
    }
    return (
      !value1?.toLowerCase().includes("other") &&
      !value2?.toLowerCase().includes("other") &&
      (value1?.toLowerCase().includes(value2?.toLowerCase()) ||
        value2?.toLowerCase().includes(value1?.toLowerCase()))
    );
  };

  const addSolutionCheched = (element: any) => {
    const allSolutions = [...solutionChecked, ...solutionCheckedFeatures];
    const reducedSolutions = filterSolutionChecked(allSolutions);

    let found = solutionChecked?.find((el: any) => el.id == element?.id);
    //found of featured solution
    let found2 = featuredSolution?.segmentDetails.find(
      (el: any) => el?.name == element?.name
    );

    if (!found) {
      if (props.maxSolutions) {
        if (reducedSolutions?.length < props.maxSolutions) {
          setSolutionChecked([...solutionChecked, element]);
          if (found2)
            setSolutionCheckedFeatured([...solutionCheckedFeatures, found2]);
        }
      } else {
        setSolutionChecked([...solutionChecked, element]);
        if (found2)
          setSolutionCheckedFeatured([...solutionCheckedFeatures, found2]);
      }
    } else {
      setSolutionChecked(
        solutionChecked?.filter((elem: any) => elem?.id != element?.id)
      );
      if (found2)
        setSolutionCheckedFeatured(
          solutionCheckedFeatures?.filter((elem: any) => elem?.id != found2?.id)
        );
    }
  };

  const addSolutionChechedFeatured = (element: any) => {
   const allSolutions = [...solutionChecked, ...solutionCheckedFeatures];
   const reducedSolutions = filterSolutionChecked(allSolutions);

   let found = solutionCheckedFeatures?.find(
     (el: any) => el?.id == element?.id
   );
   //found of solution
   let foundLeft = solutionLeftColumnData?.find(
     (el: any) => el?.name == element?.name
   );
   let foundRight = solutionRigthColumnData?.find(
     (el: any) => el?.name == element?.name
   );
   let found2 = foundLeft || foundRight;

   if (!found) {
     if (props.maxSolutions) {
       if (reducedSolutions?.length < props.maxSolutions) {
         setSolutionCheckedFeatured([...solutionCheckedFeatures, element]);
         if (found2) setSolutionChecked([...solutionChecked, found2]);
       }
     } else {
       setSolutionCheckedFeatured([...solutionCheckedFeatures, element]);
       if (found2) setSolutionChecked([...solutionChecked, found2]);
     }
   } else {
     setSolutionCheckedFeatured(
       solutionCheckedFeatures?.filter((elem: any) => elem?.id != element?.id)
     );
     if (found2)
       setSolutionChecked(
         solutionChecked?.filter((elem: any) => elem?.id != found2?.id)
       );
   }
  };

  const backToDefault = () => {
    setSolutionChecked(props?.defaultValue);
    setSolutionCheckedFeatured(props?.defaultValueFeatured);
  };

  return {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    backToDefault,
    filterSolutionChecked,
    removeItemSelected
  };
}
