/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import IsvToOemHeaderMessage from './isv-to-oem-header-message';
import OemToIsvheadermessage from './oem-to-isv-header-message';
import IsvToInvestorHeaderMessage from './isv-to-investor-header-message';
import CommunityHeaderMessage from './community-header-message';
import ClassificAddsHeaderMessage from './classific-adds-header-message';

import InvestorToIsv from './investor-to-isv-message';
import Highlighter from 'react-highlight-words';

import {
  Imessage,
  InfoCompany,
  InfoCompanyInvestorIsv,
  InfoCompanyOemIsv,
} from '../../../../../controller/tchat-ctr/type';
import {
  concatString,
  conversationType,
  dateDiff,
  fileUrl,
  isFileOrVideo,
  isImageExtension,
  limitedText,
} from '../../../../../utils';
import { rootState } from '../../../../../redux/reducers';
import { useSelector } from 'react-redux';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { LoadingFileUpload } from '../../../../widget/loader/loading-file-upload';
import {
  GUEST,
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
} from '../../../../../service/constant';
import moment from 'moment';
import { CONTRIBUTOR } from '../../../../../service/constant';

type IPropsDiscussion = {
  discussionType: string;
  infoCompanyIsvToOem: InfoCompany;
  infoCompanyOemToIsv: InfoCompanyOemIsv;
  infoCompanyInvestorIsv: InfoCompanyInvestorIsv;
  infoCompanyIsvToInvestor: InfoCompany;
  infoCompanyClassificAdds: InfoCompany;
  infoComunity: any;
  showProfil: (id: string) => void;
  msgTxt: string;
  handleMsg: (txt: string) => void;
  openBlock: () => void;
  sendMsg: () => void;
  refSendBtn: any;
  allMessage: Array<Imessage>;
  seeMessage: () => void;
  chatParent: any;
  refFile: any;
  handleAddFile: () => void;
  changeHandler: (evt: any) => void;
  selectedFile: Array<File>;
  removeFileSelected: (index: number, el: File) => void;
  loaderSendFile: boolean;
  matchingTxt: string;
  loadOtherMessge: () => void;
  loadOtherMessageInSearch: (scrollType: string) => void;
  isSearchTxtMessage: boolean;
  allFilterTxtMessage: Array<number>;
  handleClickUpDown: (p: string) => void;
  currentIndexFilter: number;
  lineRefs: any;
  txtFilterInMessage: string;
  setTxtFilterInMessage: (p: string) => void;
  setIsFocusMainResearch: (p: boolean) => void;
  parentHeigth: any;
  isLoadingFetchMessage: boolean;
  conversationId: number;
  isSending: boolean;
};

type ITypeAndProfil = {
  profil: string;
  type: string;
};

const TchatMessage = (props: IPropsDiscussion) => {
  //props.seeMessage();
  const userObject = useSelector((state: rootState) => state?.userReducer);
  const oemPage = useSelector(
    (state: rootState) => state?.oemProgramReducer?.program
  );
  const companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const companyResellerPage = useSelector(
    (state: rootState) => state.resellerCompanyReducer
  );
  const companyPageInvestor = useSelector(
    (state: rootState) => state?.companyInvestorPageReducer
  );

  const [isOpen, setisOpen] = useState(false);

  const [toggle, setToggle] = useState(false);

  const [showEmoji, setShowEmoji] = useState(false);

  const role = userObject?.roles?.filter((el) => el !== 'ROLE_USER');
  // console.log('props?.allMessage', props?.allMessage);
  // const messagesFiltered = props?.allMessage.filter(
  //   (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
  // );
  // console.log('messagesFiltered', messagesFiltered);

  const getProfilAndTypeCompany = () => {
    const isIsv = userObject.roles?.[0] === ISV;
    const isOem = userObject.roles?.[0] === OEM_PROGRAM;
    const isInvestor = userObject.roles?.[0] === INVESTOR;
    const isReseller = userObject.roles?.[0] === RESELLER;
    const isGuest = userObject.roles?.[0] === GUEST;
    const isExpert = userObject.roles?.[0] === CONTRIBUTOR;

    if (isOem) {
      return { type: 'OEM', profil: oemPage?.photo_profil };
    }
    if (isIsv) {
      return { type: 'ISV', profil: companyPageObject?.photo_profil };
    }
    if (isGuest) {
      return { type: 'GUEST', profil: userObject?.guestCompany?.logo };
    }
    if (isReseller) {
      return { type: 'RESELLER', profil: companyResellerPage?.photo_profil };
    }
    if (isInvestor) {
      return { type: 'INVESTOR', profil: companyPageInvestor?.photo_profil };
    }
    if (isExpert) {
      return {
        type: 'EXPERT CONTRIBUTOR',
        profil: userObject?.expertCompany?.logo,
      };
    }
    return { type: '', profil: '' };
  };

  const typeAndProfil: ITypeAndProfil = getProfilAndTypeCompany();

  const handleScroll = (evt: any) => {
    const position = evt.target.scrollTop;
    const isInBottom = props.parentHeigth - position < 350;
    const isScrolInConversation =
      position <= 300 &&
      !props.isLoadingFetchMessage &&
      !props.isSearchTxtMessage &&
      props.allMessage.length > 0;

    const isScrolInMainMessage =
      position <= 300 &&
      !props.isLoadingFetchMessage &&
      props.isSearchTxtMessage &&
      props.allMessage.length > 0;

    const isInMainScrollBottom =
      isInBottom &&
      props.isSearchTxtMessage &&
      props.allMessage.length > 0 &&
      !props.isLoadingFetchMessage;

    if (isScrolInConversation) {
      props.loadOtherMessge();
    }

    if (isScrolInMainMessage) {
      props.loadOtherMessageInSearch('top');
    }

    if (isInMainScrollBottom) {
      props.loadOtherMessageInSearch('bottom');
    }
  };

  const isLastSelectedFile = (index: number) =>
    props.loaderSendFile && index === props.selectedFile?.length - 1;

  const checkIfChowDateMessage = (date1: string, date2: string) => {
    let dayOne = new Date(date1).getDate();
    let dayTwo = new Date(date2).getDate() || '';
    if (dayOne === null) {
      return true;
    }
    if (dayOne == dayTwo) {
      return false;
    } else if (dayOne != dayTwo) {
      return true;
    }
  };

  const indexStatus =
    props.allFilterTxtMessage?.length > 0 ? props.currentIndexFilter + 1 : 0;

  const getNameSender = (message: Imessage) => {
    return (userObject.first_name == message.sender.first_name &&
      userObject.last_name == message.sender.last_name) ||
      concatString(message.sender.first_name + '' + message.sender.last_name) ==
        userObject.encoded_username
      ? concatString(userObject.first_name + ' ' + userObject.last_name)
      : concatString(
          message.sender.first_name + ' ' + message.sender.last_name
        );
  };

  const isCreatedConv =
    props.conversationId !== -1 && props.conversationId !== 0;

  return (
    <>
      <div className='tchat-title' ref={props?.lineRefs}>
        {props.discussionType === conversationType?.isv_to_oem && (
          <IsvToOemHeaderMessage
            companyName={props.infoCompanyIsvToOem?.company_name}
            profil={props.infoCompanyIsvToOem?.photo_profil}
            roleDiscussion={props.infoCompanyIsvToOem?.name}
          />
        )}

        {props.discussionType === conversationType?.investor_to_isv && (
          <InvestorToIsv
            companyName={props.infoCompanyInvestorIsv?.company_name}
            profil={props.infoCompanyInvestorIsv?.photo_profil}
            roleDiscussion={props.infoCompanyInvestorIsv?.name}
          />
        )}

        {props.discussionType === conversationType?.oem_to_isv && (
          <OemToIsvheadermessage
            companyName={props.infoCompanyOemToIsv?.company_name}
            profil={props.infoCompanyOemToIsv?.photo_profil}
            roleDiscussion={props.infoCompanyOemToIsv?.name}
            companyId={props.infoCompanyOemToIsv?.id?.toString()}
          />
        )}

        {props.discussionType === conversationType?.isv_to_investor && (
          <IsvToInvestorHeaderMessage
            companyName={props.infoCompanyIsvToInvestor?.company_name}
            profil={props.infoCompanyIsvToInvestor?.photo_profil}
            roleDiscussion={props.infoCompanyIsvToInvestor?.name}
          />
        )}

        {props.discussionType === conversationType?.community && (
          <CommunityHeaderMessage
            profil={props.infoComunity?.file_name}
            userName={concatString(
              props.infoComunity?.first_name +
                ' ' +
                props.infoComunity?.last_name
            )}
            userId={props.infoComunity.id}
            showProfil={(id: string) => props.showProfil(id)}
          />
        )}

        {(props.discussionType === conversationType?.classific_adds_entity ||
          props.discussionType === conversationType?.entity_classific_adds) && (
          <ClassificAddsHeaderMessage
            ads_id={props?.infoCompanyClassificAdds?.id}
            companyName={props.infoCompanyClassificAdds?.company_name}
            profil={props.infoCompanyClassificAdds?.photo_profil}
            roleDiscussion={props.infoCompanyClassificAdds?.name}
            companyName_current_user={
              role?.[0] === OEM_PROGRAM
                ? oemPage?.program_name
                : userObject?.company_name
            }
            profil_current_user={
              typeAndProfil?.profil || userObject?.default_company_logo
            }
            roleDiscussion_current={typeAndProfil?.type}
          />
        )}

        <span
          className={'icon-return open-info' + (isOpen ? ' active' : '')}
          onClick={() => {
            setisOpen(!isOpen);
            props.openBlock();
          }}
        />
      </div>

      <div
        className={`search-message ${props.isSearchTxtMessage ? '' : 'd-none'}`}
      >
        <div className='search-box form-group has-search'>
          <span className='icon-search-oem form-control-feedback'></span>
          <input
            type='text'
            className='form-control'
            placeholder='Search in conversation'
            value={props.txtFilterInMessage}
            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
              props.setTxtFilterInMessage(evt.target.value)
            }
            onFocus={() => props.setIsFocusMainResearch(true)}
            onBlur={() => {
              props.setIsFocusMainResearch(false);
            }}
          />
          <span className='form-control-feedback'>
            {indexStatus}/{props?.allFilterTxtMessage?.length}
          </span>
          <div className='control'>
            <span
              className='icon-msg-up-list'
              onClick={() => props?.handleClickUpDown('up')}
            />
            <span
              className='icon-msg-up-list down'
              onClick={() => props?.handleClickUpDown('down')}
            />
          </div>
          <div className='closed'>
            <a href='javascript:;'>Fermer</a>
          </div>
        </div>
        <hr></hr>
      </div>

      <div
        onScroll={(ev) => handleScroll(ev)}
        ref={props.chatParent}
        className='tchat-message'
        id='parent-targed'
      >
        {props?.allMessage?.map(
          (message: Imessage, index: number, arrayMessage: Array<Imessage>) => (
            <div
              key={index + 'listm'}
              id={index + 'listm'}
              // ref={arrayMessage.length === index + 1 ? setElem : null}
            >
              <div className='newTchat'>
                <div className='date'>
                  <span>
                    {checkIfChowDateMessage(
                      arrayMessage[index - 1]?.created_at,
                      message.created_at
                    ) && moment(new Date(message.created_at)).format('DD-MMM')}
                  </span>
                </div>
              </div>
              <div className='one-message'>
                <div className='user-photo-profil'>
                  <img src={fileUrl(message?.sender?.file_name)} alt='' />
                </div>
                <div className='message-content'>
                  <div className='profil'>
                    <div className='name-user'>{getNameSender(message)}</div>
                    <div className='time'>{dateDiff(message?.created_at)}</div>
                  </div>
                  <div
                    className='full-message'
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    <Highlighter
                      highlightClassName='match'
                      searchWords={[props?.matchingTxt]}
                      autoEscape={true}
                      textToHighlight={message?.message}
                    />
                  </div>
                  <div>
                    {message.attachements?.map((file: any) => {
                      return (
                        <ul className='shared'>
                          {isImageExtension(file?.extension) ? (
                            <li className='fileImages'>
                              <img alt='' src={fileUrl(file?.file_location)} />
                              <a className='cl' href='javascript:;'></a>
                            </li>
                          ) : (
                            <li className='fileOthers'>
                              <a
                                href={fileUrl(file.file_location)}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <span className='icon-empty-file'></span>{' '}
                                <span className='nameFile'>
                                  {limitedText(file?.file, 30)}
                                </span>
                              </a>
                            </li>
                          )}
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            // <ItemMessage
            //   item={message}
            //   matchingTxt={props?.matchingTxt}
            //   key={index}
            //   indexMessage={index}
            //   allMessage={arrayMessage}
            //   lineRefs={props?.templineRefs}
            // />
          )
        )}
      </div>

      <div className={`${toggle && 'messageOpen'} taping-box withApercu`}>
        <ul
          className={`filesMiniature ${
            props.selectedFile?.length < 1 && 'd-none'
          }`}
        >
          {props?.selectedFile?.map((el: File, index) =>
            isFileOrVideo(el?.name, 'image') ? (
              <li className='fileImages' key={index}>
                <img alt='' src={URL.createObjectURL(el)} />
                <a
                  className='cl'
                  href='javascript:;'
                  onClick={(e) => {
                    e.preventDefault();
                    props?.removeFileSelected(index, el);
                  }}
                >
                  <span className='icon-close ic' />
                </a>
                {isLastSelectedFile(index) && <LoadingFileUpload />}
              </li>
            ) : (
              <li className='fileOthers' key={index}>
                <span className='icon-empty-file'></span>{' '}
                <span className='nameFile'>{el?.name}</span>
                <a
                  className='cl'
                  href='javascript:;'
                  onClick={(e) => {
                    e.preventDefault();
                    props?.removeFileSelected(index, el);
                  }}
                >
                  <span className='icon-close ic' />
                </a>
                {isLastSelectedFile(index) && <LoadingFileUpload />}
              </li>
            )
          )}
        </ul>
        <textarea
          className='form-control textForMessage'
          placeholder='Type your message here…'
          value={props.msgTxt}
          onChange={(evt: ChangeEvent<HTMLTextAreaElement>) =>
            props.handleMsg(evt.target.value)
          }
          onFocus={() => props.seeMessage()}
        />
        <a onClick={() => setToggle(!toggle)}>
          <span className='icon-msg-up' />
        </a>
        <div className='action-zone'>
          <div className='icons-action'>
            <span
              className={`${
                isCreatedConv
                  ? 'icon-pj ico-reaction pointer'
                  : 'd-none pointer'
              }`}
              onClick={() => !props?.loaderSendFile && props.handleAddFile()}
            >
              {/* <span
              className={`${
                !isCreatedConv ? 'd-none' : 'icon-media ico-reaction'
              }`}
              onClick={() => !props?.loaderSendFile && props.handleAddFile()}
            > */}
              <input
                type='file'
                name=''
                id=''
                style={{ display: 'none' }}
                ref={props.refFile}
                onChange={(evt: any) => props?.changeHandler(evt)}
              />
            </span>

            <a onClick={() => setShowEmoji(!showEmoji)}>
              <span className='icon-emoticon ico-reaction' />
            </a>
            {showEmoji && (
              <div className='emojiTchat'>
                <Picker
                  set='google'
                  onSelect={(value: any) => {
                    props.handleMsg(props.msgTxt + ' ' + value?.native);
                    setShowEmoji(false);
                  }}
                />
              </div>
            )}
          </div>
          <div className='send'>
            <button
              className={`send-button mr-2${
                props.loaderSendFile || props.isSending ? ' disabled' : ''
              }`}
              ref={props.refSendBtn}
              onClick={() => props.sendMsg()}
              disabled={props.loaderSendFile || props.isSending}
            >
              send
            </button>
            <span className='send-more'>...</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TchatMessage;
