import React, { ChangeEvent, useState } from "react";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "./Header.scss";
import "react-datepicker/dist/react-datepicker.css";
import { UseCreateEventsCtr } from "../../../controller/event-ctr/use-create-events-ctr";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";
import AsyncCreatableSelect from "react-select/async-creatable";
import { timeRange } from "../../../utils/time-range";
import { defaultLabels, isEmail, isURL, isModerator } from "../../../utils";
import ModalChoiseEvent from "./modal/modal-choise-event";
import ModalPayementEvent from "../../widget/payement-oem/publish-event/index";
import ErrorPlan from "../../widget/payement-oem/publish-event/modal-error-subscription-event";

export default function SecondHeaderEvents() {
  const providerCreateEvent = UseCreateEventsCtr();
  const loadingMessage = () => "Loading ...";
  const eventName =
    providerCreateEvent?.eventObject?.event_type?.find(
      (el: any) =>
        el?.id?.toString() ===
        providerCreateEvent?.eventTypeSelected?.toString()
    )?.name || "New events";

  return (
    <>
      <div className="secondMenu">
        <div className="container mx-auto">
          <div className="leftItemHeader">
            <h2 className="title">Event</h2>
            {!isModerator(providerCreateEvent.userReducer?.roles) && (
              <div className="menuDynamic bl">
                <div className="item">
                  <a
                    href="javascript:;"
                    className={
                      providerCreateEvent?.isInMyEvents ? "active" : ""
                    }
                    onClick={(e: any) => {
                      e?.preventDefault();
                      providerCreateEvent?.goToMyEvents();
                    }}
                  >
                    Your events
                  </a>
                </div>
                <div className="item">
                  <a
                    href="javascript:;"
                    className={
                      providerCreateEvent?.isInMyEvents ? "" : "active"
                    }
                    onClick={(e) => {
                      e?.preventDefault();
                      providerCreateEvent?.goToAllEvents();
                    }}
                  >
                    All events
                  </a>
                </div>
              </div>
            )}
          </div>
          {!isModerator(providerCreateEvent.userReducer?.roles) && (
            <div className="rightItemHeader">
              <div className="item">
                <a
                  href="javascript:;"
                  className="startPost"
                  onClick={() => providerCreateEvent?.handleCreateEvent()}
                >
                  Create a new event
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalChoiseEvent
        activeCategorie={providerCreateEvent?.activeCategorie}
        eventObject={providerCreateEvent?.eventObject}
        setEventTypeSelected={(p: any) =>
          providerCreateEvent?.setEventTypeSelected(p)
        }
        setShow={(p: boolean) => providerCreateEvent?.setShow(p)}
        setShowNext={(p: boolean) => providerCreateEvent?.setShowNext(p)}
        show={providerCreateEvent?.show}
      />

      <ModalPayementEvent
        {...providerCreateEvent.providerPayement}
        continuePublish={providerCreateEvent.continuePublish}
      />

      <ErrorPlan
        closeModalErroPlan={
          providerCreateEvent.providerPayement.closeModalErroPlan
        }
        modalErroPlan={providerCreateEvent.providerPayement.modalErroPlan}
      />

      <Modal
        show={providerCreateEvent?.showNext}
        className="createCompanypopup large forStep5custom "
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Create {eventName}</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => providerCreateEvent?.setShowNext(false)}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>

        <Modal.Body>
          <form
            onSubmit={providerCreateEvent?.handleSubmit(
              providerCreateEvent?.onSubmit
            )}
          >
            <div className="eventCreation mx-auto">
              <div className="form-control">
                <label htmlFor="">
                  When?{" "}
                  {providerCreateEvent?.isErrorInfo && (
                    <div className={`container-error pl-2  d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">
                        {providerCreateEvent?.txtError}
                      </span>
                    </div>
                  )}
                </label>
                <div className="dateEvent">
                  <DatePicker
                    selected={providerCreateEvent?.startDate}
                    onChange={(date: Date) => {
                      providerCreateEvent?.setStartDate(date);
                    }}
                    minDate={new Date(Date.now() + 3600 * 1000 * 24)}
                    className="datePicker"
                    dateFormat="d/MM/yyyy"
                  />

                  {!providerCreateEvent?.isAllTime && (
                    <select
                      className="timeEvent"
                      {...providerCreateEvent?.register("startTime", {})}
                    >
                      <option value={""}>Select start time</option>
                      {timeRange?.map(
                        (
                          el: { label: string; value: string; id: number },
                          index: number
                        ) => (
                          <option value={el?.id} key={index}>
                            {el?.label}
                          </option>
                        )
                      )}
                    </select>
                  )}

                  {!providerCreateEvent?.isShowEndTime && (
                    <label
                      className="addEndTime"
                      htmlFor=""
                      onClick={() =>
                        providerCreateEvent?.setisShowEndTime(true)
                      }
                    >
                      Add end time
                    </label>
                  )}

                  {providerCreateEvent?.isShowEndTime &&
                    !providerCreateEvent?.isAllTime && (
                      <>
                        <select
                          className="timeEvent"
                          {...providerCreateEvent?.register("endTime", {})}
                        >
                          <option value={""}>Select end time</option>
                          {timeRange?.map(
                            (
                              el: { label: string; value: string; id: number },
                              index: number
                            ) => (
                              <option value={el?.id} key={index}>
                                {el?.label}
                              </option>
                            )
                          )}
                        </select>
                      </>
                    )}

                  {(providerCreateEvent?.isAllTime ||
                    providerCreateEvent?.isShowEndTime) && (
                    <div className="custom-control custom-checkbox orangecheck">
                      <input
                        type="checkbox"
                        id="ifreplies"
                        className="custom-control-input"
                        onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                          providerCreateEvent?.setisAllTime(
                            evt?.target?.checked
                          )
                        }
                        checked={providerCreateEvent?.isAllTime}
                      />
                      <label
                        htmlFor="ifreplies"
                        className="custom-control-label"
                      >
                        All time
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="">
                  Event title{" "}
                  {providerCreateEvent?.errors?.eventTitle && (
                    <div className={`container-error pl-2  d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
                <input
                  type="text"
                  {...providerCreateEvent?.register("eventTitle", {
                    required: true,
                  })}
                />
              </div>
              <div className="form-control">
                <label htmlFor="">
                  Event url{" "}
                  {providerCreateEvent?.errors?.eventUrl?.type ===
                    "required" && (
                    <div className={`container-error pl-2  d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                  {providerCreateEvent?.errors?.eventUrl?.type ===
                    "validate" && (
                    <div className={`container-error pl-2  d-inline`}>
                      <span className="error-red"> error </span>
                      <span className="txt-error">Url is not valide</span>
                    </div>
                  )}
                </label>
                <input
                  type="text"
                  {...providerCreateEvent?.register("eventUrl", {
                    required: true,
                    validate: (txt: string) => {
                      return isURL(txt);
                    },
                  })}
                />
              </div>
              <div className="form-control">
                <label htmlFor="">Choose a Tag</label>
                <div className="listOfTopic">
                  <span className="label">Suggestion: </span>
                  {defaultLabels?.map((element: any, index: number) => (
                    <span
                      className={
                        providerCreateEvent?.isSelected(
                          providerCreateEvent?.labelsCustomSuggestion,
                          element?.value
                        )
                          ? "active topic"
                          : "topic"
                      }
                      key={index}
                      onClick={() =>
                        providerCreateEvent?.handleSetCustomLabel(element)
                      }
                    >
                      {element?.label}
                    </span>
                  ))}
                </div>
                <div className="form-control no-border">
                  <AsyncCreatableSelect
                    classNamePrefix="zonetext"
                    isMulti
                    cacheOptions
                    defaultOptions
                    loadOptions={(p: string) =>
                      providerCreateEvent?.promiseOptions(p)
                    }
                    loadingMessage={() => loadingMessage()}
                    onChange={(txt: any) =>
                      providerCreateEvent?.setlabelsCustom(txt)
                    }
                  />
                </div>
              </div>
              <div className="form-control">
                <label htmlFor="">
                  Event description{" "}
                  {providerCreateEvent?.errors?.eventDescription && (
                    <div className="container-error pl-2 d-inline">
                      <span className="error-red"> error </span>
                      <span className="txt-error">
                        max length is 500 characters
                      </span>
                    </div>
                  )}
                </label>
                <textarea
                  className=""
                  {...providerCreateEvent?.register("eventDescription", {
                    maxLength: 500,
                  })}
                />
                <div className="caractercount text-right">
                  {providerCreateEvent?.nbrDescription}
                  /500
                </div>
              </div>
              <div className="contentBorder formForEmail text-right forPitch py-3">
                <button
                  type="button"
                  className="btn btn-devinsider cancel"
                  onClick={() => providerCreateEvent?.setShowNext(false)}
                >
                  Cancel
                </button>
                <button className="btn btn-devinsider px-5" type="submit">
                  {providerCreateEvent?.loaderCreateEvent ? <Loader /> : "Save"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
