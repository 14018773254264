//Dev #45635,V2.1 [ISV interface] Bugs - Become a verified professional (with no professional email address)
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import {
  checkIsProfilCompleted,
  uppercaseFirstLetter,
} from "../../../../utils";
import UseCompanyCtr from "../../../../controller/company-ctr/create-company/use-company-ctr";
import UseBecomeVerifiedCtr from "../../../../controller/company-ctr/create-company/block-create-company-controller/use-become-verified-ctr";
import UseClaimCtr from "../../../../controller/company-ctr/create-company/block-create-company-controller/use-claim-ctr";
import FormBecomeVerified from "../../company/create-company/request-for-company/become-verified/modal-become-verified";
import SuccessBecomeVerified from "../../company/create-company/request-for-company/become-verified/modal-success-become-verified/index";
import VerifyingBecomeVerified from "../../company/create-company/request-for-company/become-verified/modal-verifying-become-verified/index";
import FormContactUs from "../../company/create-company/request-for-company/contact-us/modal-contact-us";
import SuccessRequestClaim from "../../company/create-company/request-for-company/claim-ownership/modal-success-claim-owner";
import FormAddEmailClaimOwnerShip from "../../company/create-company/request-for-company/claim-ownership/modal-claim-ownership/index";
import BecomeVerified from "../../company/create-company/request-for-company/become-verified/modal-become-verified/index";
import SuccessContactUs from "../../company/create-company/request-for-company/contact-us/modal-success-contact-us";
import { getData } from "../../../../service/api";

export default function CompletedSection() {
  const history = useHistory();
  const userState = useSelector((state: rootState) => state.userReducer);
  const companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const providerUseCompanyCtr = { ...UseCompanyCtr() };
  const providerUseBecomeVerifiedCtr = { ...UseBecomeVerifiedCtr() };
  const provideUseClaimCtr = { ...UseClaimCtr() };
  const [checkingVerified, setCheckingVerified] = useState<boolean>(false);
  const isNotVerifiedPro = () =>
    providerUseCompanyCtr?.userObject?.primary_email == "" ||
    providerUseCompanyCtr?.userObject?.primary_email == null;

    
  const goToCompanyPage = () => {
    if (isNotVerifiedPro()) {
      handleCheckVerifiedPro();
      return;
    }
    const isCompletedAcount =
      userState?.isvInCompanyPage?.company_page.completed;

    if (isCompletedAcount) {
      history.push("/company/profil");
    } else {
      history.push("/company/create");
    }
  };

  const editProfile = () => {
    history.push("/profil/Isv/edit");
  };
  
  const handleCheckVerifiedPro = () => {
    setCheckingVerified(true);
    (async () => {
      let response = await getData(
        "/api/odoo/verified_pro/checking/" + userState.id,
        userState.token
      );
      if (response.status === 200) {
        if (response.data === false) {
          handleVerifiedProClicked();
        } else {
          provideUseClaimCtr?.handleShowSuccessContactUs(true);
        }
      }
      setCheckingVerified(false);
    })();
  };

  /**
   * show become verified id work email not exist
   */
  const handleVerifiedProClicked = () => {
    const nextPage =
      providerUseCompanyCtr?.userObject?.isvInCompanyPage?.company_page?.next_page?.toString() ||
      "";
    const isNotVerifiedEmail = isNotVerifiedPro();

    if (isNotVerifiedEmail) {
      providerUseBecomeVerifiedCtr.setIsBecomeVerified(true);
    } else {
      providerUseCompanyCtr?.customFormShow(nextPage);
    }
  };

  return (
    <section className="navDashboard">
      <div className="container mx-auto">
        <ul className="stepDashboard">
          <li>
            Welcome {uppercaseFirstLetter(userState?.first_name)}
            &nbsp;
            {uppercaseFirstLetter(userState?.last_name)}! Get started on
            Devinsider with 3 steps:
          </li>
          <li className="stepItem">
            <a
              href="javascript:;"
              onClick={() => editProfile()}
              className={checkIsProfilCompleted(userState) ? "done" : ""}
            >
              {checkIsProfilCompleted(userState) && (
                <span className="icon-check"></span>
              )}
              Complete your profile{" "}
            </a>
          </li>
          <li className="stepItem">
            <a
              href="javascript:;"
              className={userState?.primary_email && "done"}
              onClick={() =>
                isNotVerifiedPro() === true ? handleCheckVerifiedPro() : undefined
              }
              style={{
                cursor: isNotVerifiedPro() === true  ? "pointer" : "unset",
              }}
            >
              {userState?.primary_email && <span className="icon-check"></span>}
              Become a verified professional
            </a>
          </li>
          {/* Bug #47614 Bug - Sur mon dashboard il m'est indiqué que j'ai ajouté ma "company page" alors que cela n'est pas le cas */}
          <li className="stepItem">
            <a
              href="javascript:;"
              className={userState?.isvInCompanyPage !== null ? "done" : ""}
              onClick={() => goToCompanyPage()}
            >
              {userState?.isvInCompanyPage !== null && (
                <span className="icon-check"></span>
              )}
              Add your company page{" "}
            </a>
          </li>
        </ul>
      </div>

      <FormBecomeVerified
        isShowBecomeVerified={
          providerUseBecomeVerifiedCtr?.isShowBecomeVerified
        }
        setIsBecomeVerified={() =>
          providerUseBecomeVerifiedCtr?.setIsBecomeVerified(
            !providerUseBecomeVerifiedCtr?.isShowBecomeVerified
          )
        }
        showFormSendEmail={() => {
          providerUseBecomeVerifiedCtr?.setIsBecomeVerified(
            !providerUseBecomeVerifiedCtr?.isShowBecomeVerified
          );
          providerUseBecomeVerifiedCtr?.handleVerifieEmail(
            !providerUseBecomeVerifiedCtr?.verifieEmail
          );
        }}
      />

      <VerifyingBecomeVerified
        isShowEmail={providerUseBecomeVerifiedCtr?.verifieEmail}
        setIsShowEmail={() =>
          providerUseBecomeVerifiedCtr?.handleVerifieEmail(
            !providerUseBecomeVerifiedCtr?.verifieEmail
          )
        }
        isCheched={providerUseBecomeVerifiedCtr?.isCheched}
        setIsCheched={() =>
          providerUseBecomeVerifiedCtr?.setIsCheched(
            !providerUseBecomeVerifiedCtr?.isCheched
          )
        }
        showContactUs={() => {
          providerUseBecomeVerifiedCtr?.handleVerifieEmail(
            !providerUseBecomeVerifiedCtr?.verifieEmail
          );
          provideUseClaimCtr?.showFormContactUs();
        }}
        sendRequest={() => providerUseBecomeVerifiedCtr?.sendRequest()}
        setWorkEmailTovalid={(p: string) =>
          providerUseBecomeVerifiedCtr?.setWorkEmailTovalid(p)
        }
        workEmailTovalid={providerUseBecomeVerifiedCtr?.workEmailTovalid}
        errorEmail={providerUseBecomeVerifiedCtr?.errorEmail}
        emailErrorTxt={providerUseBecomeVerifiedCtr?.emailErrorTxt}
        loader={providerUseBecomeVerifiedCtr?.loader}
      />

      <FormContactUs
        isShowContactUs={provideUseClaimCtr?.isShowContactUs}
        sendFormContactUs={() => provideUseClaimCtr?.sendFormContactUs()}
        required
        labelEmailAdress="emailAdress"
        labelPhoneNumber="phoneNumber"
        labelTextMessage="textMessage"
        labelName="name"
        errors={provideUseClaimCtr?.errors}
        handleSubmit={(p: any) => provideUseClaimCtr?.handleSubmit(p)}
        submit={(p: any) => provideUseClaimCtr?.submitFormContactUs(p)}
        register={provideUseClaimCtr?.register}
        setCloseContactUsModal={() =>
          provideUseClaimCtr?.setCloseContactUsModal()
        }
        loaderContactUs={provideUseClaimCtr?.loaderContactUs}
        selected={provideUseClaimCtr?.selected}
        setSelected={(p: string) => provideUseClaimCtr?.setSelected(p)}
      />

      <SuccessBecomeVerified
        isShowSuccess={providerUseBecomeVerifiedCtr?.isShowSuccess}
        setIsShowSuccess={() =>
          providerUseBecomeVerifiedCtr?.setIsShowSuccess(
            !providerUseBecomeVerifiedCtr?.isShowSuccess
          )
        }
        emailTxt={providerUseBecomeVerifiedCtr?.workEmailTovalid}
      />

      <FormAddEmailClaimOwnerShip
        showRequestStep1={provideUseClaimCtr?.showFormAddEmailClaim}
        companyName={provideUseClaimCtr?.companyTosendClaim?.companyName}
        pays={provideUseClaimCtr?.companyTosendClaim?.pays}
        webSite={provideUseClaimCtr?.companyTosendClaim?.webSite}
        acceptTerm={provideUseClaimCtr?.companyTosendClaim?.acceptTerm}
        workEmail={provideUseClaimCtr?.companyTosendClaim?.workEmail}
        logoCompany={provideUseClaimCtr?.companyTosendClaim?.logoCompany}
        toogleAcceptTerm={(p: boolean) =>
          provideUseClaimCtr?.toogleAcceptTerm(p)
        }
        errorDiffWorkEmail={provideUseClaimCtr?.errorDiffWorkEmail}
        emailRequest={provideUseClaimCtr?.emailRequest}
        sendReQuestClaim={() => provideUseClaimCtr?.sendReQuestClaim()}
        isShowLoaderSendClaim={provideUseClaimCtr?.isShowLoaderSendClaim}
        cancelSendClaim={() => provideUseClaimCtr?.cancelSendClaim()}
        showContactUs={() => provideUseClaimCtr?.showFormContactUs()}
      />
      <SuccessRequestClaim
        showRequestStep3={provideUseClaimCtr?.isShowSuccessRequestClaim}
        handleCloseRequestStep3={() =>
          provideUseClaimCtr?.setIsShowSuccessRequestClaim(false)
        }
        showContactUs={() => provideUseClaimCtr?.showFormContactUs()}
        email={provideUseClaimCtr?.emailRequest}
      />

      <BecomeVerified
        isShowBecomeVerified={
          providerUseBecomeVerifiedCtr?.isShowBecomeVerified
        }
        setIsBecomeVerified={() =>
          providerUseBecomeVerifiedCtr?.setIsBecomeVerified(
            !providerUseBecomeVerifiedCtr?.isShowBecomeVerified
          )
        }
        showFormSendEmail={() => {
          providerUseBecomeVerifiedCtr?.setIsBecomeVerified(
            !providerUseBecomeVerifiedCtr?.isShowBecomeVerified
          );
          providerUseBecomeVerifiedCtr?.handleVerifieEmail(
            !providerUseBecomeVerifiedCtr?.verifieEmail
          );
        }}
      />

      <SuccessContactUs
        handleShowSuccessContactUs={(p: boolean) =>
          provideUseClaimCtr?.handleShowSuccessContactUs(p)
        }
        isShowSuccessContactUs={provideUseClaimCtr?.isShowSuccessContactUs}
      />
    </section>
  );
}
