/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, ChangeEvent } from "react";
import "./guest-profil-edit.scss";
import Header from "../../../../../widget/index";
import UseEditIsvProfilCtr from "../../../../../../controller/profil-ctr/use-edit-profil-ctr";
import UseEditProviderCtr from "../../../../../../controller/profil-ctr/use-edit-profil-guest-ctr";

import ModalPhoto from "../../../../../widget/modal/modal-photo";
import { concatString, fileUrl, refaCtoNullKey } from "../../../../../../utils";
import { contact } from "../../../../../../utils/country_phone";
import { ReactComponent as Loader } from "../../../../../../resources/images/Rolling-white.svg";

import * as ModalEdit from "../../profil-modal-edit/index";
import Footer from "../../../../../widget/footer/footer";
export default function profilISVEdit() {
  const providerUseEditIsvProfilCtr = { ...UseEditIsvProfilCtr() };

  let phoneNumber = providerUseEditIsvProfilCtr?.userState?.phone
    ? contact?.find(
        (el: any) =>
          el?.code == providerUseEditIsvProfilCtr?.userState?.phone_number_code
      )?.dial_code + providerUseEditIsvProfilCtr?.userState?.phone
    : "";

  const providerGuest = { ...UseEditProviderCtr() };

  const dialCode = (() => {
    if (
      providerUseEditIsvProfilCtr?.userState?.phone_number_code !== "" &&
      providerUseEditIsvProfilCtr?.userState?.phone_number_code !== null
    ) {
      return contact.filter(
        (element) =>
          element.code ===
          providerUseEditIsvProfilCtr?.userState?.phone_number_code
      )[0].dial_code;
    }
    return "";
  })();

  return (
    <div className="isv edit profilIsv">
      <Header />
      <div className="contentProfil">
        <div className="row profilHead m-0 justify-content-between">
          <div className="col-lg-8 col-12 infoProfil d-flex">
            <div
              className={`imageProfil ${
                providerUseEditIsvProfilCtr?.userState.file_name
                  ? "overnoBg"
                  : ""
              }`}
            >
              <a
                onClick={() => providerUseEditIsvProfilCtr?.setProfilUser(true)}
              >
                <div className="absphoto">
                  <span className="icon-edit-full"></span>
                  Edit photo
                </div>
                <img
                  src={fileUrl(
                    providerUseEditIsvProfilCtr?.userState?.file_name
                  )}
                  alt=""
                />
              </a>
            </div>
            <div className="aboutProfil">
              <a
                onClick={() => providerUseEditIsvProfilCtr?.setShow(true)}
                className="editItem"
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <ul className="ulListInfo">
                <li>
                  <div className="label">Full name</div>
                  <div className="infoLabel">
                    {concatString(
                      providerUseEditIsvProfilCtr?.userState.first_name,
                      providerUseEditIsvProfilCtr?.userState.last_name,
                      " "
                    )}
                  </div>
                </li>
                <li>
                  <div className="label">Location</div>
                  <div className="infoLabel">
                    {concatString(
                      providerUseEditIsvProfilCtr?.userState?.country
                        ?.country_name,
                      providerUseEditIsvProfilCtr?.userState?.city
                    )}
                  </div>
                </li>
                <li>
                  <div className="label">Job title</div>
                  <div className="infoLabel">
                    {refaCtoNullKey(
                      providerUseEditIsvProfilCtr?.userState?.job_title,
                      "-"
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-12 guest-action">
            <a
              className="editDoneIsv"
              onClick={() => providerUseEditIsvProfilCtr?.goToProfil()}
            >
              Done editing
            </a>

            <div
              className="guest-company-logo"
              onClick={(event) => {
                if (!providerGuest?.loaderUpdate) {
                  providerGuest?.handleRef();
                }
              }}
            >
              <img
                src={
                  providerGuest.selectedFile
                    ? URL.createObjectURL(providerGuest?.selectedFile)
                    : fileUrl(providerGuest?.userState?.guestCompany?.logo)
                }
                alt=""
              />
              <a className="validate editItem ">
                Edit your Company logo
                <input
                  type="file"
                  id="getFile"
                  accept="image/png, image/jpeg"
                  ref={providerGuest.refCompanyProfil}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    providerGuest.changeHandlerProfil(event)
                  }
                  style={{ display: "none" }}
                />
                {providerGuest?.loaderUpdate ? (
                  <Loader />
                ) : (
                  <span className="icon-edit-full" />
                )}
              </a>
            </div>
          </div>
        </div>
        <div className="row otherInfoProfil m-0 justify-content-between">
          {/* <div className="col-12 infoInvestoronly">
            <span className="icon-locked"></span>
            Only investors and technology partners can view this information.
            Your personal details will never be published publicly.
          </div> */}

          <div className="col-lg-8 col-12 contact-info">
            <div className="boxEditProfil mb-2">
              <a
                className="editItem"
                onClick={() =>
                  providerUseEditIsvProfilCtr?.setContactShow(true)
                }
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <h3 className="title">Contact information</h3>
              <ul className="ulListInfo smallLabel">
                <li>
                  <div className="label">Primary email</div>
                  <div className="infoLabel">
                    {providerUseEditIsvProfilCtr?.userState?.email}
                  </div>
                </li>

                <li>
                  <div className="label">Phone number</div>
                  <div className="infoLabel">
                    {dialCode === "" ? <></> : <>{"(" + dialCode + ") "}</>}
                    {refaCtoNullKey(
                      providerUseEditIsvProfilCtr?.userState?.phone,
                      "-"
                    )}
                  </div>
                </li>
                <li>
                  <div className="label">LinkedIn</div>
                  <div className="infoLabel">
                    <a
                      target="_blank"
                      href={
                        "https://www.linkedin.com/in/" +
                        refaCtoNullKey(
                          providerUseEditIsvProfilCtr?.userState?.linkedin,
                          "-"
                        )
                      }
                    >
                      {refaCtoNullKey(
                        providerUseEditIsvProfilCtr?.userState?.linkedin,
                        "-"
                      )}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-12 contactInfo">
            <div className="boxEditProfil">
              <a
                className="editItem"
                onClick={() =>
                  providerUseEditIsvProfilCtr?.setShowGuestCompany(true)
                }
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <h3 className="title">Company details</h3>
              <ul className="ulListInfo smallLabel">
                <li>
                  <div className="label">Company name</div>
                  <div className="infoLabel">
                    {providerUseEditIsvProfilCtr?.userState?.company_name}
                  </div>
                </li>
                <li>
                  <div className="label">Website</div>
                  <div className="infoLabel">
                    {refaCtoNullKey(
                      providerUseEditIsvProfilCtr?.userState?.website
                    )}
                  </div>
                </li>
                <li>
                  <div className="label">Location</div>
                  <div className="infoLabel">
                    {concatString(
                      providerUseEditIsvProfilCtr?.userState?.country
                        ?.country_name,
                      providerUseEditIsvProfilCtr?.userState?.city
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-8 col-12 contactInfo mt-4">
            <div className="boxEditProfil">
              <a
                className="editItem"
                onClick={() => providerUseEditIsvProfilCtr?.setLgShow(true)}
              >
                Edit
                <span className="icon-edit-full"></span>
              </a>
              <h2 className="title">About</h2>
              <p>
                {refaCtoNullKey(
                  providerUseEditIsvProfilCtr?.userState?.plain_text,
                  ""
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ModalEdit.ModalEditContactUser
        contactShow={providerUseEditIsvProfilCtr?.contactShow}
        setContactShow={() =>
          providerUseEditIsvProfilCtr?.setContactShow(false)
        }
        cancelChange={() => providerUseEditIsvProfilCtr?.cancelChange()}
        dataSource={providerUseEditIsvProfilCtr}
      />
      <ModalEdit.ModalEditCompanyPageGuest
        showEditModal={providerUseEditIsvProfilCtr?.showGuestCompany}
        cancelChange={() => providerUseEditIsvProfilCtr?.cancelChange()}
        isLoaderShow={providerUseEditIsvProfilCtr?.isLoaderShow}
        submitCompanyGuest={providerUseEditIsvProfilCtr?.submitCompanyGuest}
      />
      <ModalEdit.ModalEditInfoUser
        cancelChange={() => providerUseEditIsvProfilCtr?.cancelChange()}
        isLoaderShow={providerUseEditIsvProfilCtr?.isLoaderShow}
        onSub={providerUseEditIsvProfilCtr?.onSub}
        showEditModal={providerUseEditIsvProfilCtr?.Show}
      />
      <ModalEdit.ModalEditAudience dataSource={providerUseEditIsvProfilCtr} />
      <ModalEdit.ModalEditAbout dataSource={providerUseEditIsvProfilCtr} />

      {/* Edit Photo de profil */}
      <ModalPhoto
        changeHandler={(p: any) =>
          providerUseEditIsvProfilCtr?.changeHandler(p)
        }
        isShowImage={providerUseEditIsvProfilCtr?.profilUser}
        hideSelectedFile={() => providerUseEditIsvProfilCtr?.hideSelectedFile()}
        fileUrl={providerUseEditIsvProfilCtr?.userState.file_name}
        selectedFile={providerUseEditIsvProfilCtr?.selectedFile}
        handleBtn={() => providerUseEditIsvProfilCtr?.handleBtn()}
        fileRef={providerUseEditIsvProfilCtr?.fileRef}
        updateUserDetail={(p: any) =>
          providerUseEditIsvProfilCtr?.updateUserDetail(null, p)
        }
        isLoaderShow={providerUseEditIsvProfilCtr?.isLoaderShow}
        setShowImage={(p: boolean) =>
          providerUseEditIsvProfilCtr?.setProfilUser(p)
        }
        restorePhotoChange={() =>
          providerUseEditIsvProfilCtr?.restorePhotoChange()
        }
      />
      <Footer />
    </div>
  );
}
