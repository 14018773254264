import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

export default function ManageFeedsCtr() {
  const history = useHistory();

  const goToNotification = () => {
    history?.push('/settings', {
      tabToShow: 'notifications',
    });
  };
  return { goToNotification };
}
