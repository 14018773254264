/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent } from "react";
import CheckIcon from "@material-ui/icons/Check";

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from "../../../../widget/header-logo-center/header-logo-center";
import UseCompleteInfoReseller from "../../../../../controller/account-ctr/complete-create-account-reseller/use-complete-info-reseller";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { isEmail } from "../../../../../utils";

export default function CompleteInfoReseller() {
  const {
    checkIfAlreadyEmailExist,
    register,
    handleSubmit,
    errors,
    onSubmitForm,
    loader,
    txtEmailError,
    errorEmail,
    safeEmail,
    refSubmit,
  } = UseCompleteInfoReseller();
  return (
    <div>
      <Header />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="getstartedContainer">
          <div className="head-getstarted">
            <div className="stepInscription">
              <div className="stepItem done"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
            </div>
            <h1>Create your account. {safeEmail?.toString()}</h1>
          </div>
          <div className="contentBorder border-top row pb-4">
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label htmlFor="first-name required">
                First name
                {errors.firstNameReseller && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...register("firstNameReseller", {
                  required: true,
                })}
              />
            </div>
            <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label htmlFor="first-name required">
                Last name
                {errors.lastNameReseller && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...register("lastNameReseller", {
                  required: true,
                })}
              />
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              {safeEmail === "ok" && <CheckIcon className="mailcheckOK" />}

              <label htmlFor="first-name required">
                Business email
                {errors.emailReseller?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                {errors.emailReseller?.type === "validate" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      Email address is not valide
                    </span>
                  </div>
                )}
                {errorEmail && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">{txtEmailError}</span>
                  </div>
                )}
              </label>

              <input
                type="text"
                className="input-text form-control"
                {...register("emailReseller", {
                  required: true,
                  validate: (email: string) => isEmail(email),
                })}
                onBlur={(e: ChangeEvent<HTMLInputElement>): Promise<void> =>
                  checkIfAlreadyEmailExist(e.target.value)
                }
              />
            </div>
            <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label htmlFor="first-name required">
                Password
              </label>{" "}
                {errors.passWordReseller?.type == "minLength" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                    password length must be 12 or more characters
                    </span>
                  </div>
                )}
                {errors.passWordReseller?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                {errors?.passWordReseller?.type == "pattern" && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">
                      password must contain at least one uppercase, lowercase, special character and one number
                    </span>
                    </div>
                )}
              <input
                type="password"
                className="input-text form-control"
                {...register("passWordReseller", {
                  required: true,
                  minLength: 12,
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{12,}$/
                })}
              />
            </div>
          </div>
          <div className="contentBorder border-top row py-3 align-items-stretch">
            <div className="form-group form-check col-xl-6 col-md-6 col-sm-12">
              <input
                type="checkbox"
                className="form-check-input"
                {...register("agreeTermsReseller", {
                  required: true,
                })}
              />
              <label htmlFor="" className="form-check-label termsLink">
                I agree to Devinsider’s&nbsp;
                <a onClick={() => window.open("/term-of-use", "_blank")}>
                  terms
                </a>{" "}
                and &nbsp;
                <a onClick={() => window.open("/privacy-policy", "_blank")}>
                  privacy policy
                </a>
                .
              </label>
              {errors.agreeTermsReseller && (
                <div className="container-error pl-2 d-inline">
                  {/* change d-inline on d-none */}
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 d-flex align-items-center pr-0">
              <button
                className="btn btn-devinsider large containerBtnBtn"
                type="submit"
                disabled={loader}
              >
                {loader ? <Loader /> : "Continue"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
