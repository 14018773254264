import React, { ChangeEvent, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootState } from "../../../../redux/reducers";

type Iprops = {
  modalPublishEvent: boolean;
  closePublishModal: () => void;
  chargingPlanDetails: boolean;
  statePublishEvent: {
    number_left: number;
    number_max: number;
    next_grant_at: string;
  };
  handleChoise: (p: string) => void;
  choiseValue: string | undefined;
  continuePublish: () => void;
};
export default function UnlockInvestor(props: Iprops) {
  const avaibleSubscription = props.statePublishEvent?.number_left;

  const isActiveBtn =
    avaibleSubscription > 0 && props.choiseValue ? true : false;

  return (
    <Modal
      show={props?.modalPublishEvent}
      className="createCompanypopup small forStep5custom"
      style={{ height: "100%" }}
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Publish event</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={() => props?.closePublishModal()}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="row forAds">
          <div className="border radius col-12 d-flex">
            <input
              type="radio"
              name="choice"
              value="PAY"
              disabled={props.statePublishEvent?.number_left < 1}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                props.handleChoise(e.target.value)
              }
            />
            <h5>
              {props.statePublishEvent?.number_left} out of{" "}
              {props.statePublishEvent?.number_max} credits available
            </h5>
            {/* <label htmlFor="">
              Next grant: upon contract renewal on{" "}
              {props?.statePublishEvent?.next_grant_at}
            </label> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props.closePublishModal()}
          >
            Cancel
          </button>

          <button
            className="btn btn-devinsider px-5 save"
            disabled={isActiveBtn ? false : true}
            onClick={() => props.continuePublish()}
          >
            Continue
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
