/* eslint-disable jsx-a11y/anchor-is-valid */
//Bug #47415 Visibility setting display in community
import './Discussion.scss';
import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../widget/index';
import Dropdown from 'react-bootstrap/Dropdown';
import UseDiscussionCtr from '../../../../controller/community-ctr/forum/discussion-ctr/discussion-ctr';
import moment from 'moment';
import {
  concatString,
  convertCommentFromJSONToHTML,
  fileUrl,
  getDescriptionEditorState,
  getUserType,
  isModerator,
  refaCtoNullKey,
} from '../../../../utils';
import Reply from '../../../widget/reply-wysiwig/index';
import FixedPost from './bloc-discussion/fixed-post';
import TitleHeader from './bloc-discussion/tittle-header';
import ParentPost from './bloc-discussion/parent-post';
import { ReactComponent as Loader } from '../../../../resources/images/Rolling-white.svg';

import {
  ItemChildreen,
  ItemsReplyPost,
} from '../../../../redux/types/community-interface';
import Pagination from '../../../widget/use-pagination/pagination';
import { LoginModal } from '../../../widget/login/login';
import RelatedMoreFeatured from './bloc-discussion/bottom-related-more-featured';
import Footer from '../../../widget/footer/footer';
import { Link } from 'react-router-dom';
export default function ForumDiscusssion() {
  const providerReply = { ...UseDiscussionCtr() };

  function createMarkup(html: string) {
    return {
      __html: html,
    };
  }

  const urlPath = window.location.href;
  const currentPost =
    providerReply?.communityReducer?.curentDiscutionReplyPost?.currentPost;

  const isCreatedPost =
    currentPost?.user?.id?.toString() ===
      providerReply?.userReducer?.id?.toString() && !currentPost?.isLocked;

  let nbrReply = currentPost?.numberOfReplies || '';

  const [rectPost, setrectPost] = useState<boolean>(true);
  const replieTopic = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const rect: number =
      replieTopic?.current?.getBoundingClientRect()?.top || 0;
    if (rect > 0) {
      return setrectPost(true);
    }
    setrectPost(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isEmptyAllReplyPost =
    providerReply?.communityReducer?.curentDiscutionReplyPost?.allReplies
      ?.length < 1;

  const isPostProprio = (idUser: string) =>
    idUser === providerReply?.userReducer?.id?.toString();

  const filterShowParam = (userId: string, roles: any, isDelete: boolean) => {
    return (isPostProprio(userId) || isModerator(roles)) && !isDelete;
  };

  const isShowReply = (idUser: string, isDelete: boolean) => {
    return (
      providerReply?.idReplyShowWysiwyg !== idUser &&
      !isModerator(providerReply.userReducer.roles) &&
      !isDelete
    );
  };

  const isShowReplyChildren = (postItemId: string): boolean => {
    return (
      providerReply?.idReplyShowWysiwyg === postItemId &&
      !providerReply.isParentReply
    );
  };

  const renderForVisibilityProfilPicture = () => {
    if (providerReply?.userReducer?.user_community_profil === 'ANONYMOUS') {
      return 'assets/images/users/default_logo/avatar.png';
    }
    return providerReply?.userReducer?.file_name;
  };

  const renderForVisibilityUsername = () => {
    if (providerReply?.userReducer?.user_community_profil === 'SEMI-VISIBLE') {
      return (
        providerReply?.userReducer?.first_name +
        ' ' +
        providerReply?.userReducer?.last_name[0].toUpperCase()
      );
    }
    if (providerReply?.userReducer?.user_community_profil === 'ANONYMOUS') {
      return providerReply?.userReducer?.encoded_username;
    }
    return (
      providerReply?.userReducer?.first_name +
      ' ' +
      providerReply?.userReducer?.last_name
    );
  };

  const renderForVisibilityJobTitle = () => {
    if (
      providerReply?.userReducer?.user_community_profil === 'SEMI-VISIBLE' ||
      providerReply?.userReducer?.user_community_profil === 'ANONYMOUS'
    ) {
      return '';
    }
    return providerReply?.userReducer?.job_title;
  };

  const renderForVisibilityCompanyName = () => {
    if (
      providerReply?.userReducer?.user_community_profil === 'SEMI-VISIBLE' ||
      providerReply?.userReducer?.user_community_profil === 'ANONYMOUS'
    ) {
      return '';
    }
    return providerReply?.userReducer?.company_name;
  };

  return (
    <div>
      <Header />

      <FixedPost
        provider={currentPost}
        classOfPostFix={`discussion-fixed ${
          rectPost ? "" : "showDiscussionFixed"
        }`}
        postReply={() => providerReply?.clickReply(currentPost.id, true)}
        loaderFavorite={providerReply?.loaderFavorite}
        addToFavorite={() => providerReply?.addToFavorite()}
        likedPost={() =>
          !isModerator(providerReply.userReducer.roles) &&
          providerReply?.likedPost(currentPost?.id)
        }
        loaderLike={providerReply?.loaderLike}
        showActivity={(user: any) => providerReply?.showActivity(user)}
        isProtectedReply={providerReply?.isProtectedReply}
        communityReducer={providerReply?.communityReducer}
      />
      <section className="discussion">
        <div className="container mx-auto">
          <TitleHeader
            provider={currentPost}
            goHome={() => providerReply?.goHome()}
            goDiscussionCategorie={() => providerReply?.goDiscussionCategorie()}
            goFilter={() => providerReply?.goFilter()}
            categories={providerReply?.communityReducer?.discussionCategories}
            filterSearchText={providerReply?.filterSearchText}
            handleFilterTxt={providerReply?.handleFilterTxt}
            searchText={providerReply?.inputText}
            handleCateGory={providerReply?.handleCateGory}
          />
          <div className="contentTopic">
            <ParentPost
              //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
              currentUser={providerReply?.userReducer}
              mention={providerReply?.mentions}
              updateMention={providerReply?.updateMention}
              renderForVisibility={{
                renderForVisibilityCompanyName,
                renderForVisibilityJobTitle,
                renderForVisibilityProfilPicture,
                renderForVisibilityUsername,
              }}
              cancelReply={providerReply?.cancelReply}
              idReplyShowWysiwyg={providerReply?.idReplyShowWysiwyg}
              isParentReply={providerReply.isParentReply}
              loader={providerReply?.loader}
              postReply={
                providerReply?.isEditingParent
                  ? providerReply?.updateParentPost
                  : providerReply?.postReply
              }
              setEditorStatePost={(p: any) =>
                providerReply?.setEditorStatePost(p)
              }
              setidReplyParentDiscution={() =>
                providerReply?.clickReply(currentPost?.id, true)
              }
              stateFormWysywig={providerReply?.stateFormWysywig}
              loaderFavorite={providerReply?.loaderFavorite}
              loaderFirstReply={providerReply?.loaderFirstGetReply}
              addToFavorite={() => providerReply?.addToFavorite()}
              likedPost={() => providerReply?.likedPost(currentPost.id)}
              loaderLike={providerReply?.loaderLike}
              isShowTools={isCreatedPost}
              editParentPost={(p: string) =>
                providerReply?.clickEditParentPost(p)
              }
              isParentEditMode={providerReply?.isEditingParent}
              showActivity={(user: any) => providerReply?.showActivity(user)}
              isProtectedReply={providerReply?.isProtectedReply}
              goToLabelCategory={(
                idCategory: string,
                idLabel: string,
                postType: string
              ) =>
                providerReply?.goToLabelCategory(idCategory, idLabel, postType)
              }
              removeParentPost={(idToRemove: string) =>
                providerReply?.removeParentPost(idToRemove)
              }
              handleDeletedMessage={providerReply.handleDeletedMessage}
              // Dev #47878 [Moderator account] Il faut qu'il y ait 2 options de suppression de post.
              totalRemoveParentPost={(idToRemove: string) =>
                providerReply?.totalRemoveParentPost(idToRemove)
              }
              pinParentPost={(idToPin: string) =>
                providerReply?.pinParentPost(idToPin)
              }
              lockParentPost={(idToLock: string) =>
                providerReply?.lockParentPost(idToLock)
              }
            />

            <div className="replyTopic" id="replyTopicId" ref={replieTopic}>
              <div className="nbrReplyandPagination">
                {!isEmptyAllReplyPost && (
                  <h2 className="title">{nbrReply} Replies</h2>
                )}

                <div className="pagination">
                  <Pagination
                    className="pagination"
                    currentPage={providerReply?.currentPage}
                    totalCount={
                      providerReply?.communityReducer?.curentDiscutionReplyPost
                        ?.total
                    }
                    pageSize={providerReply?.limitDiscussion}
                    onPageChange={(page: any) =>
                      providerReply?.handlePageClick(page)
                    }
                  />
                </div>
              </div>
              {providerReply?.communityReducer?.curentDiscutionReplyPost?.allReplies?.map(
                (el: ItemsReplyPost, index: number) => (
                  <div key={index}>
                    <div className="topicMaster reply" key={index}>
                      <div className="badgeSubject">
                        <div className="badgePoster">
                          <div className="withPost">
                            <div className="imageAffiliatedcompany">
                              <img
                                src={fileUrl(el?.user?.file_name)}
                                alt={el?.user?.file_name}
                              />
                            </div>
                            <div
                              className={
                                "nameOfPost " +
                                getUserType(el?.user?.roles?.[0])
                              }
                            />
                          </div>
                          <div className="infoAffiliated">
                            <h3
                              className="title"
                              onClick={() =>
                                providerReply?.showActivity(el?.user)
                              }
                            >
                              {concatString(
                                el?.user?.first_name,
                                el?.user?.last_name,
                                " "
                              )}
                            </h3>
                            <div className="post">{el?.user?.job_title}</div>
                            <b className="post">{el?.user?.company_name}</b>
                          </div>
                        </div>
                        {/*Miaro ticket 47378<span className="btm">Subject</span>*/}
                      </div>
                      <div className="topicSubject">
                        {filterShowParam(
                          el?.user?.id?.toString(),
                          providerReply.userReducer.roles,
                          el?.isDeleted
                        ) && (
                          <div className="menudrop">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <span className="icon-inprogress"></span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    providerReply?.clickEditReply(el)
                                  }
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    providerReply?.removePost(
                                      el?.id?.toString(),
                                      false,
                                      ""
                                    )
                                  }
                                >
                                  Remove
                                </Dropdown.Item>
                                {/* Dev #48290 Dev #47878: [Moderator account] Il faut qu'il y ait 2 options de suppression de post. Moderator suppression message */}
                                {isModerator(
                                  providerReply?.userReducer?.roles
                                ) && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      providerReply?.totalRemoveReply(
                                        el?.id?.toString(),
                                        false,
                                        ""
                                      )
                                    }
                                  >
                                    Totally remove
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}

                        <div className="title">
                          <h2>{currentPost?.title}</h2>
                          <div className="date">
                            {el?.created_at &&
                              moment(new Date(el?.created_at)).format(
                                "MMM D, YYYY h:mm a"
                              )}
                          </div>
                        </div>
                        <div className="topic format-wysiwyg">
                          {el?.content && !el?.isDeleted && (
                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                convertCommentFromJSONToHTML(
                                  getDescriptionEditorState(el?.content)
                                )
                              )}
                            />
                          )}
                        </div>
                        {el?.isDeleted && (
                          <div className="deleted">
                            <span className="icon-warning alerticon"></span>
                            {providerReply.handleDeletedMessage(el.deleted_by)}
                          </div>
                        )}
                        {el?.best_answer && !el?.isDeleted && (
                          <div className="best-answer">
                            <span className="icon-check alerticon"></span>
                            Best answer!
                          </div>
                        )}

                        <div className="likeAndBtn">
                          <div
                            className="like"
                            onClick={() =>
                              // !isModerator(providerReply.userReducer.roles) &&
                              providerReply?.likedChildreen(el?.id)
                            }
                          >
                            <Link
                              onClick={(e) => e?.preventDefault()}
                              to="#"
                              className={`${el?.liked ? "active" : ""}`}
                            >
                              <span
                                className={
                                  el?.liked ? "icon-like-full" : "icon-like"
                                }
                              />
                              like
                            </Link>
                            <b>{el.numberOfLikes}</b> Likes
                          </div>
                          <div className="btnContentSubject">
                            {currentPost?.post_type?.type !== "Article" &&
                              isCreatedPost &&
                              !el?.best_answer && (
                                <Link
                                  to="#"
                                  onClick={() =>
                                    providerReply?.markAsBestAnswer(el?.id)
                                  }
                                  className="bestAnswer"
                                >
                                  Best answer
                                </Link>
                              )}
                            {isShowReply(el?.id?.toString(), el.isDeleted) && (
                              <Link
                                to="#"
                                onClick={() =>
                                  providerReply?.clickReply(el?.id, false)
                                }
                              >
                                Reply
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {isShowReplyChildren(el?.id?.toString()) && (
                      <div className="replyContentUser child">
                        <div className="badgePoster">
                          <div className="withPost">
                            <div className="imageAffiliatedcompany">
                              <img
                                src={fileUrl(
                                  renderForVisibilityProfilPicture()
                                )}
                                alt=""
                              />
                            </div>
                            <div
                              className={
                                "nameOfPost " +
                                getUserType(
                                  providerReply?.userReducer?.roles?.[0]
                                )
                              }
                            />
                          </div>
                          <div className="infoAffiliated">
                            <h3
                              className="title"
                              onClick={() =>
                                providerReply?.showActivity(
                                  providerReply?.userReducer
                                )
                              }
                            >
                              {renderForVisibilityUsername()}
                            </h3>
                            <div className="post">
                              {refaCtoNullKey(renderForVisibilityJobTitle())}
                            </div>
                            <b className="post">
                              {" "}
                              {refaCtoNullKey(renderForVisibilityCompanyName())}
                            </b>
                          </div>
                        </div>
                        <Reply
                          cancelPost={providerReply?.cancelReply}
                          sendPost={() =>
                            providerReply?.isEditReply
                              ? providerReply?.updateReplyPost(
                                  el?.id?.toString(),
                                  false,
                                  ""
                                )
                              : providerReply?.replyChild()
                          }
                          editorState={providerReply?.stateFormWysywig}
                          loader={providerReply?.loader}
                          setEditorState={providerReply?.setEditorStatePost}
                          //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
                          mention={providerReply?.mentions}
                          updateMention={providerReply?.updateMention}
                        />
                      </div>
                    )}

                    <div
                      className={`replyOfReply ${
                        providerReply?.allListeBlocOpen?.find(
                          (id: string) => id === el?.id?.toString()
                        )
                          ? " open"
                          : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className="realclick"
                        onClick={(e) => {
                          e?.preventDefault();
                          providerReply?.infoAbout(el?.id);
                        }}
                      />
                      {el?.numberOfReplies > 0 && (
                        <div className="openChild">
                          <div className="closeReplies">
                            <div className="badgePoster">
                              <div className="withPost">
                                <div className="imageAffiliatedcompany empty">
                                  <img
                                    src={fileUrl(
                                      el?.last_activity_by?.file_name
                                    )}
                                    alt=""
                                  />
                                </div>
                                <div
                                  className={
                                    "nameOfPost " +
                                    getUserType(
                                      el?.last_activity_by?.roles?.[0]
                                    )
                                  }
                                ></div>
                              </div>
                              <div className="infoAffiliated replyContent">
                                @{el?.last_activity_by?.first_name}{" "}
                                {el?.numberOfReplies} Replies
                              </div>
                              {providerReply?.loaderGetChildreen &&
                                providerReply?.parentIdTofetch ===
                                  el?.id?.toString() && <Loader />}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Dev #48290 Moderator suppression message */}
                      {el?.numberOfPagination > 0 && (
                        <Link
                          to="#"
                          onClick={(e) => providerReply?.infoAbout(el?.id)}
                          className="hideReply"
                        >
                          Hide {el?.numberOfPagination}
                        </Link>
                      )}

                      {el?.childreen?.map(
                        (tempElement: ItemChildreen, index: number) => (
                          <div className="contentReplyofreply" key={index}>
                            <div className="topicMaster reply">
                              <div className="badgeSubject">
                                <div className="badgePoster">
                                  <div className="withPost">
                                    <div className="imageAffiliatedcompany empty">
                                      <img
                                        src={fileUrl(
                                          tempElement?.user?.file_name
                                        )}
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      className={
                                        "nameOfPost " +
                                        getUserType(
                                          tempElement?.user?.roles?.[0]
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="infoAffiliated">
                                    <h3
                                      className="title"
                                      onClick={() =>
                                        providerReply?.showActivity(
                                          tempElement?.user
                                        )
                                      }
                                    >
                                      {concatString(
                                        tempElement.user?.first_name,
                                        tempElement?.user?.last_name,
                                        " "
                                      )}
                                    </h3>
                                    <div className="post">
                                      {tempElement?.user?.job_title}
                                    </div>
                                    <b className="post">
                                      {tempElement?.user?.company_name}
                                    </b>
                                  </div>
                                </div>
                                {/*Miaro ticket 47378<span className="btm">Subject</span>*/}
                              </div>
                              <div className="topicSubject">
                                {/* Dev #48290 Moderator suppression message */}
                                {(tempElement?.user?.id?.toString() ===
                                  providerReply?.userReducer?.id?.toString() ||
                                  isModerator(
                                    providerReply?.userReducer?.roles
                                  )) && (
                                  <div className="menudrop">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <span className="icon-inprogress"></span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            providerReply?.clickEditReply(
                                              tempElement,
                                              true
                                            )
                                          }
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        {!tempElement?.isDeleted && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              providerReply?.removePost(
                                                el?.id?.toString(),
                                                true,
                                                tempElement?.id?.toString()
                                              )
                                            }
                                          >
                                            Remove
                                          </Dropdown.Item>
                                        )}
                                        {
                                          //* Dev #48290 Moderator suppression message
                                          isModerator(
                                            providerReply?.userReducer?.roles
                                          ) && (
                                            <Dropdown.Item
                                              onClick={() =>
                                                providerReply?.totalRemoveReply(
                                                  el?.id?.toString(),
                                                  true,
                                                  tempElement?.id?.toString()
                                                )
                                              }
                                            >
                                              Totally remove
                                            </Dropdown.Item>
                                          )
                                        }
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                )}

                                <div className="title">
                                  <h2>
                                    {
                                      providerReply?.communityReducer
                                        ?.curentDiscutionReplyPost?.currentPost
                                        ?.user?.job_title
                                    }
                                  </h2>
                                  <div className="date">
                                    {tempElement?.created_at &&
                                      moment(
                                        new Date(tempElement?.created_at)
                                      ).format("MMM D, YYYY h:mm a")}
                                  </div>
                                </div>
                                <div className="topic">
                                  {/*Miaro ticket 47623<a
                                    href="javascript:;"
                                    className="nameOfReply"
                                    onClick={() =>
                                      providerReply?.showActivity(
                                        tempElement?.userToReply
                                      )
                                    }
                                  >
                                    @
                                    {concatString(
                                      tempElement?.userToReply?.first_name,
                                      tempElement?.userToReply?.last_name
                                    )}
                                    </a>*/}
                                  {tempElement?.content &&
                                    !tempElement?.isDeleted && (
                                      <div
                                        dangerouslySetInnerHTML={createMarkup(
                                          convertCommentFromJSONToHTML(
                                            getDescriptionEditorState(
                                              tempElement?.content
                                            )
                                          )
                                        )}
                                      />
                                    )}

                                  {tempElement?.isDeleted && (
                                    <div className="deleted">
                                      <span className="icon-warning alerticon"></span>
                                      <h3 className="title"></h3>
                                      {providerReply.handleDeletedMessage(
                                        tempElement.deleted_by
                                      )}
                                    </div>
                                  )}
                                  {tempElement?.best_answer &&
                                    !tempElement?.isDeleted && (
                                      <div className="best-answer">
                                        <span className="icon-check alerticon"></span>
                                        Best answer!
                                      </div>
                                    )}
                                </div>
                                <div className="likeAndBtn">
                                  <div
                                    className="like"
                                    onClick={() =>
                                      providerReply?.likedChildreen(
                                        el?.id,
                                        tempElement?.id,
                                        true
                                      )
                                    }
                                  >
                                    <a onClick={(e) => e?.preventDefault()}>
                                      <span
                                        className={
                                          tempElement?.liked
                                            ? "icon-like-full"
                                            : "icon-like"
                                        }
                                      />
                                      liked
                                    </a>
                                    <b>{tempElement?.numberOfLikes}</b> Likes
                                  </div>
                                  <div className="btnContentSubject">
                                    {isCreatedPost &&
                                      !tempElement?.best_answer && (
                                        <Link
                                          to="#"
                                          className="bestAnswer"
                                          onClick={() =>
                                            providerReply?.markAsBestAnswer(
                                              el?.id,
                                              true,
                                              tempElement?.id?.toString()
                                            )
                                          }
                                        >
                                          Best answer
                                        </Link>
                                      )}
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        providerReply?.clickReply(
                                          tempElement?.id,
                                          false
                                        )
                                      }
                                    >
                                      Reply
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Bug #47625 Visibility settings applied when creating/replying to a post */}
                            {isShowReplyChildren(
                              tempElement?.id?.toString()
                            ) && (
                              <div className="topicMaster reply">
                                <div className="badgeSubject">
                                  <div className="badgePoster">
                                    <div className="withPost">
                                      <div className="imageAffiliatedcompany empty">
                                        <img
                                          src={fileUrl(
                                            renderForVisibilityProfilPicture()
                                          )}
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        className={
                                          "nameOfPost " +
                                          getUserType(
                                            tempElement?.user?.roles?.[0]
                                          )
                                        }
                                      ></div>
                                    </div>
                                    <div className="infoAffiliated">
                                      <h3 className="title">
                                        {renderForVisibilityUsername()}
                                      </h3>
                                      <div className="post">
                                        {renderForVisibilityJobTitle()}
                                      </div>
                                      <div className="post">
                                        {renderForVisibilityCompanyName()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="topicSubject">
                                  <Reply
                                    //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
                                    mention={providerReply?.mentions}
                                    updateMention={providerReply?.updateMention}
                                    cancelPost={providerReply?.cancelReply}
                                    sendPost={() =>
                                      providerReply?.isEditChildreen
                                        ? providerReply?.updateReplyPost(
                                            el?.id?.toString(),
                                            true,
                                            tempElement?.id?.toString()
                                          )
                                        : providerReply?.replyChild(
                                            tempElement?.id?.toString(),
                                            el?.id?.toString()
                                          )
                                    }
                                    editorState={
                                      providerReply?.stateFormWysywig
                                    }
                                    loader={providerReply?.loader}
                                    setEditorState={
                                      providerReply?.setEditorStatePost
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      )}

                      {el?.childreen?.length < el?.numberOfReplies && (
                        <Link to="#" className="seeMoreList">
                          See more
                        </Link>
                      )}
                    </div>
                  </div>
                )
              )}

              <div className="pagginationCategory">
                <Pagination
                  className="pagination"
                  currentPage={providerReply?.currentPage}
                  totalCount={
                    providerReply?.communityReducer?.curentDiscutionReplyPost
                      ?.total
                  }
                  pageSize={providerReply?.limitDiscussion}
                  onPageChange={(page: any) =>
                    providerReply?.handlePageClick(page)
                  }
                />
              </div>
            </div>
          </div>

          <RelatedMoreFeatured
            loaderRelatedContent={providerReply?.loaderRelated}
            relatedContents={providerReply?.allRelatedContent}
            loaderMorePost={providerReply?.loaderMore}
            morePostsUser={providerReply?.moreUserPost}
            showAllCategorie={() => providerReply?.showAllPostCategorie()}
            showActivityUser={() => providerReply?.showActivityUser()}
            userName={concatString(
              currentPost?.user?.first_name,
              currentPost?.user?.last_name,
              " "
            )}
            showClassicAdds={providerReply?.showClassicAdds}
            goToLabelCategory={(
              categoryId: string,
              labelId: string,
              postType: string
            ) =>
              providerReply?.goToLabelCategory(categoryId, labelId, postType)
            }
            moreRelatedAds={providerReply.moreRelatedAds}
            loaderRelatedAds={providerReply.loaderRelatedAds}
            showAllAds={() => providerReply.showAllAds()}
          />
        </div>
      </section>

      <LoginModal
        urlType={urlPath.indexOf("community") != -1 ? "community" : ""}
        show={providerReply?.showLogin}
        setShow={providerReply?.setShowLogin}
      />
      <Footer />
    </div>
  );
}
