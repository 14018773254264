import './header-community/Header.scss';
import React, { useState, useEffect } from 'react';
import Header from '../../screen/widget/header/header';
import HeaderInvestor from '../../screen/widget/header-investor/header';
import HeaderReseller from '../../screen/widget/header-reseller/header';
import HeaderGest from '../../screen/widget/header-guest/index';
import SecondHeaderCommunity from '../widget/header-community/second-header';
import SecondHeaderIsv from './header-isv/second-header';
import SecondHeaderEvents from '../widget/header-events/header';
import SecondHeaderProgram from '../widget/header-program/header-findPrograms';
import SecondHeaderClassified from '../widget/header-classified-ads/header';
import { rootState } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import HeaderOemProgram from '../widget/header-program/header';
import HeaderExpert from '../widget/header-expert/header';
import ErrorPlan from '../widget/payement-oem/publish-event/modal-error-subscription-event';

import SecondHeaderInvestor from '../widget/header-investor/second-header';
import ModalStartPost from '../../screen/container/community/modal/modal-start-post';
import UseHeaderCtr from '../../../src/controller/community-ctr/header-ctr';
import { LoginModal } from './login/login';
import ModalIsvPayementAdds from './payement-isv/publish-adds';
import ModalAGuestPayementAdds from './payement-guest/payement-adds';
import ModalResellerPayementAdds from './payement-reseller/publish-adds/index';
import ModalInvestorPayementAdds from './payement-investors/publish-adds/index';
import ModalPostAdds from './modal-adds/ModalPostAdds';

import {
  INVESTOR,
  RESELLER,
  GUEST,
  OEM_PROGRAM,
  CONTRIBUTOR,
  ISV,
} from '../../service/constant';
import { Link } from 'react-router-dom';
export type IPropsHeader = {
  listCategories?: Array<{ id: number; label: string }>;
  notFound?: boolean;
  searchText?: string;
  handleChange?: (a: string) => void;
  handleClick?: () => void;
  reloadFilter?: () => void;
  startAds?: () => void;
  unreadeTypeNbr?: any;
};
const CustomHeader = (props: IPropsHeader) => {
  let unread = -1;

  if (props.unreadeTypeNbr) {
    const { COMMUNITY, CLASSIFIED_ADS, FIND_PROGRAM, FIND_INVESTOR, FIND_ISV } =
      props.unreadeTypeNbr;

    const totalUnread =
      (COMMUNITY || 0) +
      (CLASSIFIED_ADS || 0) +
      (FIND_PROGRAM || 0) +
      (FIND_INVESTOR || 0) +
      (FIND_ISV || 0);
    unread = totalUnread;
  }

  const classifiedAdsReducer = useSelector(
    (state: rootState) => state?.classifiedAdsReducer
  );

  const providerHeaderProps = { ...UseHeaderCtr() };

  const urlPath = window.location.href;
  var valueofSubmenu: any;
  urlPath.indexOf('community') !== -1
    ? (valueofSubmenu = 'community')
    : urlPath.indexOf('events') !== -1
    ? (valueofSubmenu = 'events')
    : //to fix a header in profil locked
    urlPath.indexOf('/isv/profil-locked/') !== -1
    ? (valueofSubmenu = 'neant')
    : urlPath.indexOf('isv') !== -1
    ? (valueofSubmenu = 'isv')
    : urlPath.indexOf('programs') !== -1
    ? (valueofSubmenu = 'programs')
    : urlPath.indexOf('investor') !== -1 &&
      urlPath.indexOf('profil/investor') === -1
    ? (valueofSubmenu = 'investor')
    : urlPath.indexOf('classified-ads') !== -1
    ? (valueofSubmenu = 'classified')
    : (valueofSubmenu = 'neant');

  const [stateBody, setStateBody] = useState(false);

  const userState = useSelector((state: rootState) => state.userReducer);

  const openMenu = () => {
    if (stateBody === false) {
      setStateBody(true);
      document.body.classList.add('openMenu');
    } else {
      setStateBody(false);
      document.body.classList.remove('openMenu');
    }
  };

  useEffect(() => {
    setStateBody(false);
    document.body.classList.remove('openMenu');
  }, []);

  return (
    <header
      className={`dynamicMenu ${
        valueofSubmenu === 'neant' ? 'firstFix' : 'secondFix'
      }`}
    >
      <Link to='#' className='menuMobile' onClick={openMenu}>
        <p>
          <span />
        </p>
      </Link>
      <div className='containerMenuForMobile'>
        {userState?.roles?.[0]?.toString() === INVESTOR ? (
          <HeaderInvestor unread={unread} />
        ) : userState?.roles?.[0]?.toString() === RESELLER ? (
          <HeaderReseller unread={unread} />
        ) : userState?.roles?.[0]?.toString() === GUEST ? (
          <HeaderGest unread={unread} />
        ) : userState?.roles?.[0]?.toString() === OEM_PROGRAM ? (
          <HeaderOemProgram unread={unread} />
        ) : userState?.roles?.[0]?.toString() === CONTRIBUTOR ? (
          <HeaderExpert unread={unread} />
        ) : userState?.roles?.[0]?.toString() === ISV ? (
          <Header unread={unread} />
        ) : (
          <Header unread={unread} />
        )}
        {!props?.notFound &&
          (valueofSubmenu === 'community' ? (
            <SecondHeaderCommunity
              createPost={() => providerHeaderProps?.createPost()}
              setShowLogin={providerHeaderProps?.setShowLogin}
              role={userState?.roles}
            />
          ) : valueofSubmenu === 'events' ? (
            <SecondHeaderEvents />
          ) : valueofSubmenu === 'isv' ? (
            <SecondHeaderIsv reloadFilter={props?.reloadFilter} />
          ) : valueofSubmenu === 'programs' ? (
            <SecondHeaderProgram />
          ) : valueofSubmenu === 'investor' ? (
            <SecondHeaderInvestor />
          ) : valueofSubmenu === 'classified' ? (
            <SecondHeaderClassified
              show={providerHeaderProps?.showAds}
              setShow={providerHeaderProps?.setShowAds}
              activeCategory={providerHeaderProps?.activeAds}
              setActiveCategory={providerHeaderProps?.toogleActiveAds}
              startAds={providerHeaderProps?.createAds} //providerHeaderProps?.createAds()
              setShowLogin={providerHeaderProps?.toogleLoginModal}
              searchText={props?.searchText}
              handleChange={props?.handleChange}
              handleClick={props?.handleClick}
              role={userState?.roles}
              setShowAdsModalP={providerHeaderProps.setShowAdsModalP}
              showAdsModalP={providerHeaderProps.showAdsModalP}
              allText={providerHeaderProps.allText}
            />
          ) : (
            <div></div>
          ))}
      </div>

      <ModalStartPost
        activeCategorie={providerHeaderProps?.activeCategorie}
        setShow={(p: boolean) => providerHeaderProps?.toogleModal(p)}
        setactiveCategorie={(p: number) =>
          providerHeaderProps?.toogleActiveCategorie(p)
        }
        show={providerHeaderProps?.show}
        createPost={(p: number) => providerHeaderProps?.createDiscution(p)}
        role={userState?.roles}
      />

      <ErrorPlan
        closeModalErroPlan={providerHeaderProps.closeModalErroPlan}
        modalErroPlan={providerHeaderProps.modalErroPlan}
      />

      <LoginModal
        urlType={urlPath.indexOf('community') !== -1 ? 'community' : ''}
        show={providerHeaderProps?.showLogin}
        setShow={providerHeaderProps?.setShowLogin}
      />

      {providerHeaderProps?.isGuest && (
        <ModalAGuestPayementAdds
          {...providerHeaderProps.providerUsePayementGuest}
          publishAdds={() => providerHeaderProps.continueTopublishAdds()}
        />
      )}

      {providerHeaderProps?.isIsv && (
        <ModalIsvPayementAdds
          {...providerHeaderProps.providerUsePayementAdds}
          publishAdds={() => providerHeaderProps.continueTopublishAdds()}
        />
      )}

      {providerHeaderProps?.isReseller && (
        <ModalResellerPayementAdds
          {...providerHeaderProps.providerUsePayementReseller}
          publishAdds={() => providerHeaderProps.continueTopublishAdds()}
        />
      )}

      {providerHeaderProps?.isInvestor && (
        <ModalInvestorPayementAdds
          {...providerHeaderProps.providerUsePayementInvestor}
          publishAdds={() => providerHeaderProps.continueTopublishAdds()}
        />
      )}

      <ModalPostAdds
        show={providerHeaderProps.showAds}
        handleModal={() =>
          providerHeaderProps.setShowAds(!providerHeaderProps.showAds)
        }
        classifiedAdsOptionList={classifiedAdsReducer.categories}
        goToPostAds={(p) => {
          providerHeaderProps.goToPostAds(p);
        }}
      />
    </header>
  );
};
export default React.memo(CustomHeader);
