import React from 'react';
import { Redirect } from 'react-router-dom';

//ROUTES FOR CATEGORIES
import { routesIsv } from './isv-routes';
import { routesReseller } from './reseller-routes';
import { routesExpert } from './expert-routes';
import { routesGuest } from './guest-routes';
import { routesProfils } from './profils-routes';
import { routesCommunity } from './community-routes';
import { routesEvents } from './events-routes';
import { routesMA } from './mergers-acquisitions-routes';
import { routesSponsors } from './sponsors-routes';

// Route Views
import Welcome from '../screen/container/welcome';
import Signin from '../screen/container/acount/signin/signin';
import AccountChoise from '../screen/container/acount/account-choix/account-choise';
import PartnerProgram from '../screen/container/acount/complete-create-account-partner-program/partner-program';
import Privacy from '../screen/container/term-privacy/privacy';
import ForgotPassword from '../screen/container/acount/forgot-password/forgot-password';
import ResetPassword from '../screen/container/acount/reset-password/reset-password';
import CompleteAffiliated from '../screen/container/acount/create-affiliated-account/index';

import { routesInvestors } from './investor-routes';
import { discussionRoute } from './discussion';
import { routeSettings } from './settings-routes';
import { routesCompany } from './company-routes';
import { routesBillingCenter } from './billing-center';
import { routesOEM } from './oem-routes';
import { routesClassifiedAds } from './classified-ads';
import { routesPrograms } from './programs-routes';
import { routesContact } from './contact-routes';
import { aboutRoute } from './about-routes';
import DashboardPage from '../screen/container/dashboard/dashboard';
import NotFound from '../screen/container/not-found/not-found';
import CommunityGuidelines from '../screen/container/static-page/community-guidelines';
import TermOfUse from '../screen/container/static-page/terms-of-use';
import PrivacyPolicy from '../screen/container/static-page/privacy-policy';
import { routesNotifications } from './notifications-routes';


// public routes
import { unsubscribeContact } from './public-routes';
import { ISV, OEM_PROGRAM, RESELLER } from '../service/constant';
import { CONTRIBUTOR } from '../service/constant';
import { INVESTOR } from '../service/constant';
import { MODERATOR } from '../service/constant';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/',
    exact: true,
    component: Welcome,
    guard: 'public',
  },
  {
    path: '/community-guidelines',
    exact: true,
    component: CommunityGuidelines,
    guard: 'public',
  },
  {
    path: '/term-of-use',
    exact: true,
    component: TermOfUse,
    guard: 'public',
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
    guard: 'public',
  },
  {
    path: '/signin',
    guard: 'public',
    exact: true,
    component: Signin,
  },
  {
    path: '/account-choices',
    guard: 'public',
    exact: true,
    component: AccountChoise,
  },
  {
    // path: '/affiliated-info',
    // guard: 'private',
    // exact: true,
    // component: CompleteAffiliated,
    // allowedRole: [ISV, OEM_PROGRAM],
    path: '/affiliated-info',
    guard: 'public',
    exact: true,
    component: CompleteAffiliated,
  },
  {
    path: '/become_verified',
    guard: 'private',
    allowedRole: [ISV],
    exact: true,
    component: CompleteAffiliated,
  },
  ...routesIsv,
  ...routesReseller,
  ...routesInvestors,
  ...routesExpert,
  ...routesGuest,
  ...routesProfils,
  ...routeSettings,
  ...routesCompany,
  ...routesBillingCenter,
  ...routesOEM,
  ...routesCommunity,
  ...routesEvents,
  ...routesClassifiedAds,
  ...routesPrograms,
  ...discussionRoute,
  ...aboutRoute,
  ...routesContact,
  ...routesNotifications,
  ...unsubscribeContact,
  ...routesMA,
  ...routesSponsors,

  // Route to partner program registration
  {
    path: '/partner-program-registration',
    guard: 'not-logged-page',
    exact: true,
    component: PartnerProgram,
  },

  {
    path: '/forgot-password',
    guard: 'not-logged-page',
    exact: true,
    component: ForgotPassword,
  },

  {
    path: '/password_recovery',
    guard: 'public',
    exact: true,
    component: ResetPassword,
  },
  {
    path: '/privacy',
    guard: 'public',
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: '/dashboard',
    guard: 'private',
    component: DashboardPage,
    exact: true,
    allowedRole: [ISV, RESELLER, INVESTOR, CONTRIBUTOR, MODERATOR, OEM_PROGRAM],
  },

  {
    path: '/profil-not-found',
    guard: 'public',
    exact: true,
    component: NotFound,
  },
];
