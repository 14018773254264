import { useEffect, useState } from "react";
import { convertToRaw, EditorState } from "draft-js";
import { ItemsDataSource } from "../../../screen/container/investor/profil-investor/types";
import { useDispatch, useSelector } from "react-redux";
import { updateDraftWysiwygAction } from "../../../redux/actions/oem-program-action";
import { OemProgram, OemProgramDraft } from "../type";
import { rootState } from "../../../redux/reducers";
import UseWysiwygList from "../../../screen/widget/wysiwyg-list/use-wysiwyg-list";

type IProps = {
  categories: string;
};

export default function UseOemGettingStarted() {
  const [initDraft, setInitDraft] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [inialData, setInitialData] = useState<Array<any>>([]);

  const oemProgram: OemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const [isEditClick, setIsEditClick] = useState<boolean>(false);

  const tempData = oemProgram?.oemProgramStarted?.map((el) => {
    return {
      ...el,
      file_location: el?.oemProgramStartedImages?.[0]?.file_location,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setInitialData(tempData);
    return () => {};
  }, [oemProgram?.oemProgramStarted]);

  const clickCancel = () => {
    setLoader(false);
    setIsEditClick(false);
    setInitialData(tempData);
  };

  const {
    setEditorState,
    initialeDataSource,
    choseDisposition,
    addNewElement,
    removeElement,
    setFileExpertise,
    removeFile,
    isHaveError,
    idItemRemove,
    setIdItemRemove,
  } = UseWysiwygList({ initialData: inialData });

  const handleSubmit = () => {
    saveAllDataForm();
  };

  const cbUpdateDraft = (data: any) => {
    setLoader(false);
    setIsEditClick(!isEditClick);
  };
  const saveAllDataForm = () => {
    setLoader(true);
    let allFile = initialeDataSource?.map(
      (el: ItemsDataSource) => el?.dataFile
    );
    /**
     *get data change
     */
    //Dev #46323 V1 [OEM/Ecosystem user interface] Bug avec le bouton "Done editing" (dans certains cas)
    let stringChange = initialeDataSource?.map((el: any, index: number) => {
      if (el?.isChange == true) {
        return {
          id: el?.id || "",
          urlImage: (() => {
            if (el?.dataFile === "" && el?.fileUrl === "") return "NONE";
            if (el?.dataFile === "" && el?.fileUrl !== "") return "";
            return index?.toString();
          })(),
          html: JSON.stringify(
            convertToRaw(el?.editorState?.getCurrentContent())
          ),
          images: [],
          idForm: el?.itemIndex ?? 0,
          draft: true,
          className: el?.disposition ?? "textPhoto",
        };
      }
    });
    //remove undefined data array
    let dataStringToSend = stringChange?.filter((el: any) => el != undefined);
    let formatRemoveForm = idItemRemove?.map((el: any) => {
      return {
        id: el,
        urlImage: "",
        html: "",
        images: [],
        idForm: 0,
        draft: true,
        className: "",
      };
    });
    let dataToSend = JSON.stringify([...dataStringToSend, ...formatRemoveForm]);
    dispatch(
      updateDraftWysiwygAction(
        { oemProgramStarted: dataToSend, ...allFile },
        "oemProgramStarted",
        oemProgram.id,
        cbUpdateDraft
      )
    );
  };
  return {
    handleSubmit,
    removeElement,
    addNewElement,
    setEditorState,
    initialeDataSource,
    removeFile,
    initDraft,
    loader,
    choseDisposition,
    setFileExpertise,
    isHaveError,
    idItemRemove,
    setIdItemRemove,
    isEditClick,
    setIsEditClick,
    saveAllDataForm,
    clickCancel,
  };
}
