/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import * as T from "../../../../../../../controller/company-ctr/types/types-company-page";
import ReactFlagsSelect from "react-flags-select";
import { contact } from "../../../../../../../utils/country_phone";
import { ReactComponent as Loader } from "../../../../../../../resources/images/Rolling.svg";
import { isValidPhoneNumber } from "react-phone-number-input";
export default function FormContactUs(props: T.InputPropsContactUs) {
  return (
    <div>
      {/* Request step 2 */}

      <Modal
        show={props.isShowContactUs}
        className="createCompanypopup smallPopup forStep5custom"
      >
        <form onSubmit={props?.handleSubmit(props?.submit)}>
          <Modal.Header>
            <div className="headerpopupCompany noStep">
              <h2 className="title">Get in touch </h2>
              <a
                href="javascript:;"
                className="close"
                onClick={() => props.setCloseContactUsModal()}
              >
                <span className="icon-close-pop"></span>
              </a>
            </div>
          </Modal.Header>
          <Modal.Body className="withMinHeight">
            <div className="bodyCreateCompany">
              <span className="descGetintouch">
              Get in touch with Devinsider to become a verified professional without a professional work email address (such as gmail, outlook, yahoo etc.). <br/><br/>
              Please describe your project and why you don't have a professional email address.
              </span>
              <div className="contentBorder row">
                <div className="form-group required col-12">
                  <label htmlFor="name">
                    Name
                    {props.errors.name && (
                      <div className="container-error pl-2 d-inline">
                        {/* change d-inline on d-none */}
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    )}
                  </label>
                  <input
                    type="text"
                    className="input-text form-control"
                    {...props?.register("name", {
                      required: props?.required,
                    })}
                  />
                </div>
                <div className="form-group required col-12">
                  <label htmlFor="organisation required">
                    Phone number
                    {props.errors.phoneNumber?.type === "required" && (
                      <div className="container-error pl-2 d-inline">
                        {/* change d-inline on d-none */}
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    )}
                    {props.errors.phoneNumber?.type === "validate" && (
                      <div className="container-error pl-2 d-inline">
                        {/* change d-inline on d-none */}
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          Phone number is not valid
                        </span>
                      </div>
                    )}
                  </label>
                  <div className="numberphone" style={{ position: "relative" }}>
                    <input
                      type="number"
                      className="phonenumber"
                      style={{
                        textIndent:
                          contact?.find((el: any) => el.code == props?.selected)
                            ?.dial_code?.length === 2
                            ? 35
                            : contact?.find(
                                (el: any) => el.code == props?.selected
                              )?.dial_code?.length === 3
                            ? 40
                            : contact?.find(
                                (el: any) => el.code == props?.selected
                              )?.dial_code?.length === 4
                            ? 50
                            : 60,
                        paddingBottom: 5,
                      }}
                      {...props?.register("phoneNumber", {
                        required: props?.required,
                        validate: (phone: string) =>
                          isValidPhoneNumber(
                            contact &&
                              contact.find(
                                (el: any) => el.code == props?.selected
                              )?.dial_code + phone
                          ),
                      })}
                    />
                    <span style={{ position: "absolute", top: 5, left: 60 }}>
                      (
                      {contact &&
                        contact.find((el: any) => el.code == props?.selected)
                          ?.dial_code}
                      )
                    </span>
                    <ReactFlagsSelect
                      className="selectFlag"
                      selected={props?.selected}
                      onSelect={(code) => props?.setSelected(code)}
                      searchable={true}
                      showSelectedLabel={false}
                      placeholder=" "
                    />
                  </div>
                </div>
                <div className="form-group required col-12">
                  <label htmlFor="name">
                    Email address
                    {props.errors.emailAdress && (
                      <div className="container-error pl-2 d-inline">
                        {/* change d-inline on d-none */}
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    )}
                  </label>
                  <input
                    type="text"
                    className="input-text form-control"
                    {...props?.register("emailAdress", {
                      required: props?.required,
                    })}
                  />
                </div>
                <div className="form-group required col-12">
                  <label htmlFor="name">
                    Message
                    {props.errors.textMessage && (
                      <div className="container-error pl-2 d-inline">
                        {/* change d-inline on d-none */}
                        <span className="error-red"> error </span>
                        <span className="txt-error">
                          This field is required
                        </span>
                      </div>
                    )}
                  </label>
                  <textarea
                    className="input-text form-control messageClaim"
                    {...props?.register("textMessage", {
                      required: props?.required,
                    })}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="contentBorder formForEmail text-right">
              <button
                className="btn btn-devinsider cancel"
                onClick={() => props?.setCloseContactUsModal()}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-devinsider" type="submit">
                {props?.loaderContactUs ? <Loader /> : "Submit"}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      {/* end Request  step 2*/}
    </div>
  );
}
