import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useEventListener from "../../../hooks/event-handler";

export interface IListTarget {
  id: number;
  name: string;
}
export default function UseCompleteIndistrueInvestor() {
  const history = useHistory();

  const [companyTarget, setcompanyTarget] = useState<Array<IListTarget>>([]);
  const [companyTargetSecound, setcompanyTargetSecond] = useState<
    Array<IListTarget>
  >([]);
  const [lastCompanyTarget, setLastcompanyTargetSecond] = useState<
    Array<IListTarget>
  >([]);

  const [loader, setLoaderState] = useState<boolean>(true);
  const [companyTargetSelected, setCompanyTargetSelected] = useState<
    Array<IListTarget>
  >(JSON.parse(sessionStorage.getItem("company_industry_investor") || "[]"));
  const [errorData, setErrorData] = useState<boolean>(false);
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );
  const navigateToCompletePublishInvestor = () => {
    if (companyTargetSelected.length > 0) {
      sessionStorage.setItem(
        "company_industry_investor",
        JSON.stringify(companyTargetSelected)
      );
      history.push("/complete-publish-investor");
    } else {
      setErrorData(true);
    }
  };

  useEffect(() => {
    async function getTargetCompany() {
      // let response: any = await getCompanyTarget();
      let industrieData = dataCompleted?.targetedIndustry;
      // if (response && response.status == 200 && response.data) {

      let divider = Math.floor(industrieData.length / 3);
      let firstColumn = industrieData.slice(0, divider);
      let secondColumn = industrieData.slice(divider, divider * 2);
      let lastComumn = industrieData.slice(divider * 2);
      setcompanyTarget(firstColumn);
      setcompanyTargetSecond(secondColumn);
      setLastcompanyTargetSecond(lastComumn);
      setLoaderState(false);

      // }
      //setLoaderState(false);
    }
    getTargetCompany();
    return () => {};
  }, []);

  const addTargetCompany = (dataMake: IListTarget) => {
    let found = companyTargetSelected?.find(
      (el: IListTarget) => el.id == dataMake.id
    );
    if (!found) {
      setCompanyTargetSelected([...companyTargetSelected, dataMake]);
    } else {
      setCompanyTargetSelected(
        companyTargetSelected?.filter(
          (elem: IListTarget) => elem.id != dataMake.id
        )
      );
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateToCompletePublishInvestor();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    navigateToCompletePublishInvestor,
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    loader,
    companyTargetSelected,
    errorData,
  };
}
