import CompleteInfoGuest from '../screen/container/acount/complete-create-account-guest/complete-info-guest/complete-info-guest';
import ConfirmAccountGuest from '../screen/container/acount/complete-create-account-guest/confirm-account-guest/confirm-account-guest';
import NotFound from '../screen/container/not-found/not-found';
import ProfilEdit from '../screen/container/profil/user-profil/profil-edit/profil-edit-guest/guest-profil-edit';
import { GUEST } from '../service/constant';

export const routesGuest = [
  {
    path: '/complete-info-guest',
    exact: true,
    guard: 'not-logged-page',
    component: CompleteInfoGuest,
  },
  {
    path: '/confirm-account-guest',
    exact: true,
    component: NotFound, //ConfirmAccountGuest,
    guard: 'public',
  },
  {
    path: '/edit-guest-profil',
    exact: true,
    component: ProfilEdit,
    guard: 'private',
    allowedRole: [GUEST],
  },
];
