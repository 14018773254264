import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as BController from "../../../../../controller/company-ctr/profil/block-profil-company-controller/index";
import { rootState } from "../../../../../redux/reducers";

export type IProps = {
  isShowEdit: () => void;
};
export default function Index(props: IProps) {
  const providerUseSoftwareSolutionCtr = {
    ...BController.UseSoftwareSolutionCtr(),
  };

  const params: any = useParams();

  let companyPageObject = useSelector((state: rootState) =>
    params?.id ? state?.otherUserCompanyIsvReducer : state.companyPageReducer
  );

  let segment_details = companyPageObject?.segment_details.filter(
    (segment, index) =>
      index ===
      companyPageObject?.segment_details.findIndex(
        (other) =>
          segment.name === other.name
      )
  );

  const lengthSegmentDetail = segment_details.length;

  return (
    <div className="containerLeftItem py-3">
      <a className="linkEditLeftCompany" onClick={() => props?.isShowEdit()}>
        Edit
        <span className="icon-edit-full"></span>
      </a>
      <h2 className="title">Software solution</h2>
      <ul className="listnamelabel pl-3">
        {segment_details
          .filter((el: { id: number; name: string }, index: number) => {
            return index < providerUseSoftwareSolutionCtr?.index1;
          })
          ?.map((element: { id: number; name: string }, index: number) => (
            <li key={index}>
              <div className="name">{element?.name}</div>
            </li>
          ))}

        {lengthSegmentDetail > 6 && (
          <li className="link">
            <a
              onClick={(evt) => {
                evt.preventDefault();
                providerUseSoftwareSolutionCtr?.setindex1(
                  providerUseSoftwareSolutionCtr?.index1 === lengthSegmentDetail
                    ? 6
                    : lengthSegmentDetail
                );
              }}
            >
              See{" "}
              {providerUseSoftwareSolutionCtr?.index1 === lengthSegmentDetail
                ? "less"
                : "more"}
              {providerUseSoftwareSolutionCtr?.index1 === lengthSegmentDetail
                ? ""
                : `(${
                    lengthSegmentDetail - providerUseSoftwareSolutionCtr?.index1
                  })`}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
}
