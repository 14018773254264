/* eslint-disable jsx-a11y/anchor-is-valid */
//Dev #48042,V2.1 [Investor] Preview Mode of an ISV company profile
import React, { ChangeEvent } from 'react';
import logoDev from '../../../../../resources/images/logodev.png';
import './pitch.scss';
import * as T from '../../../../../controller/company-ctr/types/types-company-page';
import ModalEditPichDoc from './modal/modal-edit-pich-doc';
import ModalEditPich from './modal/modal-edit-pitch';
import ModalEditPainPoint from './modal/modal-edit-pain-point';
import ModalEditKeyCompetitive from './modal/modal-edit-key-competitive';
import ModalTeamDifferenciator from './modal/modal-team-differenciator';
import ReactHtmlParser from 'react-html-parser';

import { bolderedText, checkIfExist, fileUrl } from '../../../../../utils';
import { useParams } from 'react-router-dom';

export default function Pitch(props: T.IpropsPitchEdit) {
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;

  const isCompleted = props?.totalProgress >= 99;

  return (
    <div className='pitchcontainer'>
      {isCompleted && !isOtherUser && (
        <div
          className='logodev'
          onClick={() =>
            !isCompleted
              ? props?.showErrorNotCompleted()
              : props.setIsShowPitch(true)
          }
        >
          <a
            className='linkEditLeftCompany'
            onClick={() => props.setIsShowPitch(true)}
          >
            Edit
            <span className='icon-edit-full'></span>
          </a>

          <>
            <img src={logoDev} alt='' />

            {!props.valueOfPitch && (
              <p className='text-center'>
                <a onClick={() => props.setIsShowPitch(true)}>
                  To grab the attention of investors, write your Devinsider
                  pitch.
                </a>
              </p>
            )}
          </>
          <a>{props.valueOfPitch}</a>
        </div>
      )}

      {isOtherUser && (
        <>
          <h3 className='text-center font-weight-600 mt-5'>Pitch </h3>
          {checkIfExist(props.valueOfPitch) && (
            <>
              <h5 className='title'>Devinsider pitch</h5>
              {ReactHtmlParser(bolderedText(props.valueOfPitch))}
            </>
          )}
        </>
      )}

      <div className='pitch'>
        <div className='development'>
          <a
            className='linkEditLeftCompany'
            onClick={() => props.setIsShowPitchPoint(true)}
          >
            Edit
            <span className='icon-edit-full'></span>
          </a>

          {isOtherUser && checkIfExist(props.valueOfPitchPoint) && (
            <>
              <h5 className='title'>What paint point are you solving ?</h5>
              {ReactHtmlParser(bolderedText(props.valueOfPitchPoint))}
            </>
          )}

          {!isOtherUser && (
            <>
              <h5 className='title'>What paint point are you solving ?</h5>
              {checkIfExist(props.valueOfPitchPoint) ? (
                props.valueOfPitchPoint
              ) : (
                <div className='fauxText'>
                  <div className='l100'></div>
                  <div className='l100'></div>
                  <div className='l100'></div>
                  <div className='l50'></div>
                </div>
              )}
            </>
          )}
        </div>
        <div className='strategy'>
          <a
            className='linkEditLeftCompany'
            onClick={() => props.setIsShowKeyCompetitive(true)}
          >
            Edit
            <span className='icon-edit-full'></span>
          </a>

          {isOtherUser && checkIfExist(props.valueOfKeyCompetitive) && (
            <>
              <h5 className='title'>
                What are your key competitive differentiator(s)?
              </h5>
              {ReactHtmlParser(bolderedText(props.valueOfKeyCompetitive))}
            </>
          )}

          {!isOtherUser && (
            <>
              <h5 className='title'>
                What are your key competitive differentiator(s)?
              </h5>
              {checkIfExist(props.valueOfKeyCompetitive) ? (
                props.valueOfKeyCompetitive
              ) : (
                <div className='fauxText'>
                  <div className='l100'></div>
                  <div className='l100'></div>
                  <div className='l100'></div>
                  <div className='l50'></div>
                </div>
              )}
            </>
          )}
        </div>
        <div className='strategy'>
          <a
            className='linkEditLeftCompany'
            onClick={() => props.setIsShowTeamUnique(true)}
          >
            Edit
            <span className='icon-edit-full'></span>
          </a>

          {isOtherUser && checkIfExist(props.valueOfTeamUnique) && (
            <>
              <h5 className='title'>What makes your team unique ?</h5>
              {ReactHtmlParser(bolderedText(props.valueOfTeamUnique))}
            </>
          )}

          {!isOtherUser && (
            <>
              <h5 className='title'>What makes your team unique ?</h5>

              {checkIfExist(props.valueOfTeamUnique) ? (
                props.valueOfTeamUnique
              ) : (
                <div className='fauxText'>
                  <div className='l100'></div>
                  <div className='l100'></div>
                  <div className='l100'></div>
                  <div className='l50'></div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className='upload'>
        <a
          className='linkEditLeftCompany'
          onClick={() => props.setIsShowModalFile(true)}
        >
          Edit
          <span className='icon-edit-full'></span>
        </a>
        <div>
          <div>
            {props.pitchFile && (
              <p
                onClick={() => window.open(fileUrl(props.pitchFile), '_blank')}
              >
                {props.pitchFile?.split('/')?.pop()}
              </p>
            )}
            {!props.pitchFile && !isOtherUser && (
              <p>Upload your PowerPoint or PDF pitch deck.</p>
            )}
          </div>
        </div>
      </div>
      <ModalEditPichDoc
        isShowModalFile={props.isShowModalFile}
        setIsShowModalFile={(p: boolean) => props.setIsShowModalFile(p)}
        fileRef={props.fileRef}
        changeHandler={(p: any) => props.changeHandler(p)}
        handleBtn={() => props.handleBtn()}
        isFilePicked={props.isFilePicked}
        selectedFile={props.selectedFile}
        fileName={props.fileName}
        updatePicth={() => props.updatePicth()}
        loaderUpdateDoc={props.loaderUpdateDoc}
        handleDrop={(p: any) => props.handleDrop(p)}
        cancelChangeFile={() => props?.cancelChangeFile()}
      />

      <ModalEditPich
        isShowPitch={props.isShowPitch}
        loader={props.loader}
        valueOfPitch={props.valueOfPitch}
        setIsShowPitch={(p: boolean) => props.setIsShowPitch(p)}
        setValueOfPitchForm={(p: string) => props.setValueOfPitchForm(p)}
        valueOfPitchForm={props.valueOfPitchForm}
        updatePicth={(key?: string) => props.updatePicth(key)}
      />

      {/* Popup Pain point */}
      <ModalEditPainPoint
        isShowPitchPoint={props.isShowPitchPoint}
        loader={props.loader}
        pitchPointValueForm={props.pitchPointValueForm}
        setIsShowPitchPoint={(p: boolean) => props.setIsShowPitchPoint(p)}
        setPitchPointValueForm={(txt: string) =>
          props.setPitchPointValueForm(txt)
        }
        updatePicth={() => props.updatePicth()}
      />

      {/* Popup Competitive */}
      <ModalEditKeyCompetitive
        isShowKeyCompetitive={props.isShowKeyCompetitive}
        loader={props.loader}
        setIsShowKeyCompetitive={(p: boolean) =>
          props.setIsShowKeyCompetitive(p)
        }
        setValueOfKeyCompetitiveForm={(t: string) =>
          props.setValueOfKeyCompetitiveForm(t)
        }
        valueOfKeyCompetitiveForm={props.valueOfKeyCompetitiveForm}
        updatePicth={() => props.updatePicth()}
      />

      <ModalTeamDifferenciator
        isShowTeamUnique={props.isShowTeamUnique}
        loader={props.loader}
        setIsShowTeamUnique={(p: boolean) => props.setIsShowTeamUnique(p)}
        setValueOfTeamUniqueForm={(t: string) =>
          props.setValueOfTeamUniqueForm(t)
        }
        updatePicth={() => props.updatePicth()}
        valueOfTeamUniqueForm={props.valueOfTeamUniqueForm}
      />
    </div>
  );
}
