import React, { useState } from "react";
import { useHistory } from "react-router";
import useEventListener from "../../../hooks/event-handler";

export default function UseCompletePostExpert() {
  const history = useHistory();
  const [postExpert, setPostExpert] = useState<string>(
    sessionStorage.getItem("postExpert")
      ? sessionStorage.getItem("postExpert") || ""
      : ""
  );
  const navigateCountryExpert = () => {
    if (postExpert) {
      sessionStorage.setItem("postExpert", postExpert);
    }
    history.push("/complete-country-expert");
  };

  // const keyEnter = ({ key }: any) => {
  //   if (key == "Enter") {
  //     navigateCountryExpert();
  //   } else return;
  // };
  // useEventListener("keydown", keyEnter);

  return { postExpert, setPostExpert, navigateCountryExpert };
}
