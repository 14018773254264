/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { ChangeEvent, EventHandler, HtmlHTMLAttributes } from "react";
import Checkbox from "@material-ui/core/Checkbox";

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import "./style-forgot-password.css";
import LogoRound from "../../../../resources/images/logo-round.svg";
import { BtnNext } from "../../../widget/bouton/btn-next";
import Alert from "react-bootstrap/Alert";
import useForgotPasswordCtr from "../../../../controller/account-ctr/forgot-password/use-forgot-password-ctr";
import { useHistory } from "react-router-dom";
export default function ForgotPassword() {
  const history = useHistory();
  const {
    email,
    emailTemp,
    showLoader,
    emailSent,
    emailNotExist,
    setEmail,
    setEmailTemp,
    resendMailForgotPassword,
  } = useForgotPasswordCtr();
  return (
    <div>
      <div className="loginScreen">
        <div className="loginContent">
          <img 
          src={LogoRound} 
          alt=""
          style={{cursor:'pointer'}}
          onClick = { () => history.push('/')}
           />
          <h1 className="title">Forgot your password ?</h1>
          {emailSent && (
            <Alert variant="success">
              {/* Design & Integration #47540 Change text Password reset */}
              email for reset password was successfully sent
            </Alert>
          )}
          {!emailSent && (
            <form action="" className="loginForm">
              {emailNotExist && (
                <Alert variant="danger">this email address doesn't exist</Alert>
              )}

              <div className="form-group">
                <input
                  type="text"
                  name=""
                  value={emailTemp}
                  id=""
                  onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                    const val = event.target.value;
                    setEmailTemp(val);
                    let i =0;
                    while(val[i] === " ") {
                      i++;
                    }
                    const vraiVal = val.slice(i);
                    setEmail(vraiVal);
                  }}
                  placeholder="Email address"
                />
                <div className="mt-3 btn btn-devinsider large containerBtnBtn">
                  <BtnNext
                    text="Next"
                    click={() => resendMailForgotPassword()}
                    showLoader={showLoader}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
