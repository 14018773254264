/* eslint-disable jsx-a11y/anchor-is-valid */
import '../OneCategory.scss';
import React, { useEffect } from 'react';
import Header from '../../../../widget/index';
import Select from 'react-select';
import UseOneCategorie from '../../../../../controller/community-ctr/bloc-community-ctr/use-filter-categorie-discussion-ctr';
import { ItemDiscussion } from '../../../../../redux/types/community-interface';
import {
  concatString,
  dateDiff,
  fileUrl,
  getUserType,
  isModerator,
  refaCtoNullKey,
} from '../../../../../utils';
import moment from 'moment';
import { LoadingArticle } from '../../../../widget/loader';
import Pagination from '../../../../widget/use-pagination/pagination';
import TopLabel from './bloc-filter-discussion/top-label';
import View from './bloc-filter-discussion/views';
import { IpropsAuthor } from '../../../../../controller/community-ctr/home-page/top-liked-author';
import { IPropsBestAnswer } from '../../../../../controller/community-ctr/home-page/top-best-answer-author';
import SearchForm from '../../../../widget/community/search/index';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import Footer from '../../../../widget/footer/footer';
import Locked from "../../../../../resources/images/locked.svg";
import Pin from "../../../../../resources/images/pin.svg";

export default function OneCategory() {
  const providerFilter = { ...UseOneCategorie() };

  const offSet = Math.ceil(
    providerFilter?.totalLabel / providerFilter?.limitTopLabel
  );

  const isUserPost = (idUser: string) =>
  idUser === providerFilter?.userReducer?.id?.toString();

  const pinnedAndLocked = (userId: string, roles: any, isDelete: boolean) => {
    return (isUserPost(userId) || isModerator(roles)) && !isDelete;
  };

  return (
    <div>
      <Header />

      <section className="discussion">
        <div className="container mx-auto">
          <ul className="breadcrumbPerso">
            <li onClick={() => providerFilter?.goHome()}>
              <a href="javascript:;" onClick={(e: any) => e?.preventDefault()}>
                Community
              </a>
            </li>
            <li>{providerFilter?.titleCategories}</li>
          </ul>
          <div className="searchTopic">
            <SearchForm
              categories={
                providerFilter?.communityReducer?.discussionCategories
              }
              filterSearchText={providerFilter?.filterSearchText}
              handleFilterTxt={providerFilter?.handleFilterTxt}
              searchText={providerFilter?.inputText}
              handleCateGory={providerFilter?.handleCateGory}
            />
          </div>

          <div className="rightCommunityForum allWidth oneCateg">
            <div className="labelLeft">
              <TopLabel
                allNotSelectedLabel={providerFilter?.allNotSelectedLabel}
                useSelected={providerFilter?.useSelected}
                allSelectedLabel={providerFilter?.allSelectedLabel}
                clikPrev={(p: any) => providerFilter?.clikPrev(p)}
                nextPage={(p: any) => providerFilter?.nextPage(p)}
                currentPage={providerFilter?.currentPage}
                labels={providerFilter?.communityReducer?.topLabel?.labels}
                limitTopLabel={providerFilter?.limitTopLabel}
                offSet={offSet}
                totalLabel={providerFilter?.totalLabel}
                setLabelsFilter={(p: any) =>
                  providerFilter?.communityReducer?.successFetchPaginate &&
                  providerFilter?.setLabelsFilter(p)
                }
                loaderTopLabel={providerFilter?.loaderTopLabel}
              />
              <View
                allViews={providerFilter?.allViews}
                view={providerFilter?.view}
                resetFilter={providerFilter?.resetFilter}
                handleFilterView={(p: any) =>
                  providerFilter?.communityReducer?.successFetchPaginate &&
                  providerFilter?.handleFilterView(p)
                }
              />
            </div>
            <div className="containerCategory">
              <div className="titleCategory">
                <h2>
                  <span className="icon-Sales"></span>
                  {providerFilter?.titleCategories}
                  {providerFilter?.isUserConnected && (
                    <>
                      <button
                        className={`followFeed ${
                          providerFilter?.follow ? "" : "active"
                        }`}
                        onClick={() => providerFilter?.followCategory()}
                      >
                        {providerFilter?.followLoader ? (
                          <Loader />
                        ) : providerFilter?.follow ? (
                          "Following"
                        ) : (
                          "Follow"
                        )}
                      </button>
                    </>
                  )}
                </h2>

                <div className="tri">
                  <div className="labelSelect">
                    <label>Show:</label>
                    <Select
                      className="triNumber"
                      isClearable={false}
                      options={[
                        { value: "10", label: "10" },
                        { value: "20", label: "20" },
                        { value: "30", label: "30" },
                      ]}
                      classNamePrefix="tri"
                      defaultValue={{
                        value: providerFilter?.limitDiscution?.toString(),
                        label: providerFilter?.limitDiscution?.toString(),
                      }}
                      value={{
                        value: providerFilter?.limitDiscution?.toString(),
                        label: providerFilter?.limitDiscution?.toString(),
                      }}
                      onChange={(params: any) => {
                        providerFilter?.setPaginateDataFilter(params?.value);
                      }}
                      onInputChange={(p: any) => {
                        return "";
                      }}
                      autoFocus={false}
                      isSearchable={false}
                    />
                  </div>

                  <div className="sortedby labelSelect">
                    <label>Sorted by : </label>
                    <Select
                      className="triOreder"
                      isClearable={false}
                      options={[
                        { value: "post_date", label: "Post date" },
                        { value: "first_activity", label: "First activity" },
                        { value: "last_activity", label: "Latest activity" },
                        { value: "replies", label: "Replies" },
                        { value: "views", label: "Views" },
                      ]}
                      classNamePrefix="tri"
                      defaultValue={{
                        value: providerFilter?.sortedBy,
                        label: "Post date",
                      }}
                      onChange={(params: any) =>
                        providerFilter?.setSortedate(params?.value)
                      }
                    />
                  </div>
                </div>
              </div>
              {!providerFilter?.communityReducer?.successFetchPaginate ? (
                <LoadingArticle />
              ) : (
                <>
                  <ul className="feedList categoryList">
                    {providerFilter?.communityReducer?.paginate_discussions?.discussions?.map(
                      (el: ItemDiscussion, index: number) => (
                        <li
                          key={index}
                          className={`${el?.post_type?.id == 5 ? "pin" : ""}`}
                        >
                          {/* ADD BANNER LOCKED OR PINNED */}
                          {el?.isLocked && pinnedAndLocked(el?.user?.id?.toString(), providerFilter.userReducer.roles, el?.isDeleted) && (
                            <img className="pinned-item" src={Locked} alt="" />
                          )}
                          {el?.isPinned && !el?.isLocked && (
                            <img className="pinned-item" src={Pin} alt="" />
                          )}
                          {el?.isLocked && el?.isPinned && pinnedAndLocked(el?.user?.id?.toString(), providerFilter.userReducer.roles, el?.isDeleted) && (
                            <img className="pinned-item" src={Locked} alt="" />
                          )}
                          {el?.isLocked && el?.isPinned && !pinnedAndLocked(el?.user?.id?.toString(), providerFilter.userReducer.roles, el?.isDeleted) && (
                            <img className="pinned-item" src={Pin} alt="" />
                          )}
                          {el?.solved && (
                            <div className="solved">
                              <div className="contentSolved">
                                <span className="icon-check" />
                              </div>
                            </div>
                          )}

                          <div className="infoAbout">
                            <div className="withPost">
                              <div className="imageAffiliatedcompany empty">
                                {el?.user?.file_name && (
                                  <img
                                    src={fileUrl(el?.user?.file_name)}
                                    alt=""
                                  />
                                )}
                              </div>
                              <div
                                className={`nameOfPost ${getUserType(
                                  el?.user?.roles?.[0]
                                )}`}
                              />
                            </div>
                            <div className="infoFeed">
                              <div
                                className="titlePost"
                                onClick={() =>
                                  providerFilter?.goToreplayPost(
                                    el.id?.toString(),
                                    el?.post_type?.id
                                  )
                                }
                              >
                                {(el?.post_type?.id?.toString() === "2" ||
                                  el?.post_type?.id?.toString() === "5") && (
                                  <span className="article">Article</span>
                                )}
                                <span className="title">{el?.title}</span>
                              </div>

                              <div className="postBy">
                                by{" "}
                                <a
                                  href="javascript:;"
                                  onClick={(e: any) => {
                                    e?.preventDefault();
                                    providerFilter?.goActivity(
                                      el?.user?.id?.toString()
                                    );
                                  }}
                                >
                                  {concatString(
                                    el?.user?.first_name,
                                    el?.user?.last_name,
                                    " "
                                  )}
                                </a>{" "}
                                on{" "}
                                {moment(new Date(el?.created_at)).format(
                                  "MMM D, YYYY h:mm a"
                                )}
                              </div>
                              <div className="postBy">
                                Latest activity {dateDiff(el?.updated_at)} by
                                <a
                                  href="javascript:;"
                                  onClick={(e: any) => {
                                    e?.preventDefault();
                                    // Dev #47708V1 [Community] Bug lorsque je clique sur le pseudo d'un utilisateur
                                    providerFilter?.goActivity(
                                      el?.last_activity_by?.id?.toString()
                                    );
                                  }}
                                >
                                  {" "}
                                  {concatString(
                                    el?.last_activity_by?.first_name,
                                    el?.last_activity_by?.last_name,
                                    " "
                                  )}
                                </a>
                              </div>
                              <div className="subCategory">
                                {el?.labels?.map(
                                  (element: any, index: number) => (
                                    <span
                                      key={index}
                                      onClick={() =>
                                        providerFilter?.goToLabelCategory(
                                          element?.category?.id,
                                          element?.id
                                        )
                                      }
                                    >
                                      {element?.title}
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="replyInfo">
                            <b>{el?.numberOfReplies}</b>Replies
                          </div>
                          <div className="viewInfo">
                            <b>{el?.numberOfViews}</b>views
                          </div>
                          {el?.post_type?.id == 5 && (
                            <div className="pinit">
                              <span className="icon-pushpin"></span>
                            </div>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                  {providerFilter?.communityReducer?.paginate_discussions
                    ?.total > 10 && (
                    <div className="pagginationCategory">
                      <div className="labelSelect">
                        <label>Show: </label>
                        <Select
                          className="triNumber"
                          isClearable={false}
                          options={[
                            { value: "10", label: "10" },
                            { value: "20", label: "20" },
                            { value: "30", label: "30" },
                          ]}
                          classNamePrefix="tri"
                          defaultValue={{
                            value: providerFilter?.limitDiscution?.toString(),
                            label: providerFilter?.limitDiscution?.toString(),
                          }}
                          value={{
                            value: providerFilter?.limitDiscution?.toString(),
                            label: providerFilter?.limitDiscution?.toString(),
                          }}
                          onChange={(params: any) =>
                            providerFilter?.setPaginateDataFilter(params?.value)
                          }
                        />
                      </div>
                      <div className="pagination">
                        <Pagination
                          className="pagination"
                          currentPage={providerFilter?.currentDiscutionPage}
                          totalCount={
                            providerFilter?.communityReducer
                              ?.paginate_discussions?.total
                          }
                          pageSize={providerFilter?.limitDiscution}
                          onPageChange={(page: any) =>
                            providerFilter?.handlePageClick(page)
                          }
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="leftCommunityForum allWidth">
            {providerFilter?.topLikedContributions?.length > 0 && (
              <div className="block">
                <h2 className="title">Top liked contributions</h2>
                <ul className="separateElement">
                  {providerFilter?.topLikedContributions?.map(
                    (el: any, index: number) => (
                      <li key={index}>
                        <div className="leftItem txtLabel">
                          <h3
                            className="title"
                            onClick={() =>
                              providerFilter?.goToreplayPost(
                                el.id?.toString(),
                                el?.post_type?.id
                              )
                            }
                          >
                            {el?.title}
                          </h3>
                          {el?.labels?.map((label: any, index: number) => (
                            <span
                              className="topic"
                              key={index}
                              onClick={() =>
                                providerFilter?.goToLabelCategory(
                                  el?.category?.id,
                                  label.id
                                )
                              }
                            >
                              {label?.title}
                            </span>
                          ))}
                        </div>
                        <div className="rightItem likeContent">
                          <span className="icon-like-full"></span>
                          {el?.numberOfLikes}
                        </div>
                      </li>
                    )
                  )}

                  {providerFilter?.topLikedContributions?.length > 0 && (
                    <li
                      className="linkForLi"
                      onClick={() => providerFilter?.viewAllContributor()}
                    >
                      <a
                        href="javascript"
                        className="viewAll"
                        onClick={(e) => e?.preventDefault()}
                      >
                        View all
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}

            {providerFilter?.topLikedAuthor?.length > 0 && (
              <div className="block">
                <h2 className="title">Top liked authors</h2>
                <ul className="separateElement">
                  {providerFilter?.topLikedAuthor?.map(
                    (author: IpropsAuthor, index: number) => (
                      <li key={index}>
                        <div
                          className="leftItem itemAuthor"
                          onClick={() =>
                            providerFilter.goActivity(author?.user_to_like_id)
                          }
                        >
                          <div className="imageAffiliatedcompany empty">
                            <img src={fileUrl(author?.profil_picture)} />
                          </div>
                          <div className="infoAffiliated">
                            <h3 className="title">
                              {refaCtoNullKey(author?.user_name)}
                            </h3>
                            <div className="post">
                              {refaCtoNullKey(author?.job_title)}
                            </div>
                          </div>
                        </div>
                        <div className="rightItem likeContent">
                          <span className="icon-like-full"></span>
                          {refaCtoNullKey(author?.number_of_like)}
                        </div>
                      </li>
                    )
                  )}
                  {providerFilter?.topLikedAuthor?.length > 0 && (
                    <li
                      className="linkForLi"
                      onClick={() => providerFilter?.viewAllAuthor()}
                    >
                      <a
                        href="javascript"
                        className="viewAll"
                        onClick={(e) => e?.preventDefault()}
                      >
                        View all
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}

            {providerFilter?.topBestAnswerAuthor?.length > 0 && (
              <div className="block">
                <h2 className="title">Top best answer authors</h2>
                <ul className="separateElement">
                  {providerFilter?.topBestAnswerAuthor?.map(
                    (element: IPropsBestAnswer, index: number) => (
                      <li key={index}>
                        <div
                          className="leftItem itemAuthor"
                          onClick={() => providerFilter.goActivity(element?.id)}
                        >
                          <div className="imageAffiliatedcompany empty">
                            <img src={fileUrl(element.profil_picture)} />
                          </div>
                          <div className="infoAffiliated">
                            <h3 className="title">
                              {refaCtoNullKey(element?.user_name)}
                            </h3>
                            <div className="post">
                              {refaCtoNullKey(element?.job_title)}
                            </div>
                          </div>
                        </div>
                        <div className="rightItem solutionContent">
                          <b>{element.number_of_like}</b>{" "}
                          {parseInt(element.number_of_like) > 1
                            ? " solutions"
                            : " solution"}
                        </div>
                      </li>
                    )
                  )}
                  {providerFilter?.topBestAnswerAuthor?.length > 0 && (
                    <li className="linkForLi">
                      <a
                        onClick={() => providerFilter.goToTopBestAwnser()}
                        className="viewAll"
                      >
                        View all
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
