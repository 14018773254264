import React, { useState, useEffect } from "react";
import { Label } from "../../../interface/interface-acount/index";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as accountAction from "../../../redux/actions/acounts-action/index";
import useEventListener from "../../../hooks/event-handler";

export default function UseCompleteInterestedExpert() {
  const [dataInterested, setDataInterested] = useState<Array<Label>>([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const callback = (data: Array<Label>) => {
    setDataInterested(data);
    setLoader(false);
  };
  const [dataChecked, setdataChecked] = useState<Array<number>>(
    JSON.parse(sessionStorage.getItem("labelsExpert") || "[]") || []
  );
  const checkedRadio = (data: number) => {
    let tempData;
    if (dataChecked?.find((el) => el == data)) {
      tempData = dataChecked?.filter((e) => e != data);
      setdataChecked(tempData);
    } else {
      setdataChecked([...dataChecked, data]);
    }
  };
  useEffect(() => {
    dispatch(accountAction.getInterestedAction(callback));
    return () => {};
  }, []);

  const navigateCompleteProfile = () => {
    sessionStorage.setItem("labelsExpert", JSON.stringify(dataChecked));
    history.push("/complete-picture-expert");
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateCompleteProfile();
    } else return;
  };
  useEventListener("keydown", keyEnter);

  return {
    dataInterested,
    dataChecked,
    loader,
    checkedRadio,
    navigateCompleteProfile,
  };
}
