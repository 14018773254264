import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredEvent } from "../../../redux/actions/find-program-action";
import { rootState } from "../../../redux/reducers";

const BlocLeft = () => {
  const dispatch = useDispatch();
  const findProgramReducer = useSelector(
    (state: rootState) => state?.findProgramReducer
  );

  const [limitType, setLimitType] = useState<number>(6);
  const [limitTag, setLimitTag] = useState<number>(6);

  const [selectedType, setSelectedType] = useState<Array<number>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<number>>([]);

  const cb = (data: any) => {
    if (data?.status === 200) {
    }
  };

  useEffect(() => {
    dispatch(getFilteredEvent(cb));
  }, []);

  const expandOrNot = (type: string, option: number = 1) => {
    switch (type) {
      case "EVENT_TYPE":
        option === 2
          ? setLimitType(6)
          : setLimitType(findProgramReducer?.event_type?.length);
        return;
      case "TAGS":
        option === 2
          ? setLimitTag(6)
          : setLimitTag(findProgramReducer?.top_tags?.length);
        return;
    }
  };

  const handleType = (string: number) => {
    selectedType?.find((el) => el === string)
      ? setSelectedType(selectedType?.filter((el) => el !== string))
      : setSelectedType([...selectedType, string]);
  };

  const handleTags = (string: number) => {
    selectedTags?.find((el) => el === string)
      ? setSelectedTags(selectedTags?.filter((el) => el !== string))
      : setSelectedTags([...selectedTags, string]);
  };

  const clearFilter = () => {
    setSelectedType([]);
    setSelectedTags([]);
  };

  return {
    findProgramReducer,
    limitTag,
    limitType,
    expandOrNot,
    handleTags,
    handleType,
    selectedTags,
    selectedType,
    clearFilter,
  };
};

export default BlocLeft;
