import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import usePays from "../../../screen/widget/pays/use-Pays";

import useEventListener from "../../../hooks/event-handler";

interface ItemsPays {
  id: number;
  country_name: string;
}
interface IobJectResponse {
  id: number;
  name: string;
  countries: Array<ItemsPays>;
}
interface IdataMake {
  idContinent: string;
  countrId: string;
  countryName: string;
}
export default function UseCompleteCompanyPaysReseller() {
  const history = useHistory();
  const [loader, setLoaderState] = useState<boolean>(false);

  const [valuePays, setDefaultValuePays] = useState<
    Array<{ idContinent: string; countrId: string; countryName: string }>
  >(JSON.parse(sessionStorage.getItem("pays_company") || "[]"));

  //pays
  const { addPaysInArray, paysChecked, allContinents, allContinentSelected } =
    usePays(valuePays);

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );
  const [errorData, seterrorData] = useState<boolean>(false);

  const navigateCompleteSolutionOffert = () => {
    if (paysChecked?.length > 0) {
      sessionStorage.setItem("pays_company", JSON.stringify(paysChecked));
      history.push("/complete-company-solution-offert-reseller");
    } else {
      seterrorData(true);
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return {
    loader,
    allContinents,
    paysChecked,
    addPaysInArray,
    allContinentSelected,
    goBack,
    errorData,
    navigateCompleteSolutionOffert,
  };
}
