import React, { ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import { concatString, isURL, refaCtoNullKey } from '../../../utils';

export type IpropsModal = {
  txt: string;
  isShowModal: boolean;
  closeModal: () => void;
  changeTxt: (txt: string) => void;
  cancelChange: () => void;
  updateTextArea: () => void;
  isLoaderShow: boolean;
};
export default function ModalEditAbout(props: IpropsModal) {
  return (
    <Modal
      show={props?.isShowModal}
      onHide={() => props?.closeModal()}
      className='modalDevinsider large'
    >
      <Modal.Header closeButton>
        <Modal.Title>About</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='contentBorder row m-0 p-0'>
          <div className='col-12 descCompany'>
            <textarea
              name=''
              value={refaCtoNullKey(props?.txt, '')}
              onChange={(evt: ChangeEvent<HTMLTextAreaElement>) =>
                props?.changeTxt(evt.target.value)
              }
              maxLength={450}
            />
            {props?.txt && props?.txt != "null" && (
              <div className='caractercount'>
                {`${0 + (props?.txt.length || 0)} / 450`}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='cancelbtn' onClick={() => props?.cancelChange()}>
          Cancel
        </Button>
        <Button
          className='validate'
          onClick={() => props?.updateTextArea()}
          disabled={props?.isLoaderShow}
        >
          {props?.isLoaderShow ? <Loader /> : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
