import UseBlocLeftEvent from "../../../../controller/program-ctr/left-program-ctr/event-bloc-left-ctr";
import { paginate } from "../../../../utils";

type IProps = {
  filter: (selectedTags: any, selectedType: any) => void;
  resetFilter: any;
};

const BlocLeftEvent = (props: IProps) => {
  const providerLeft = UseBlocLeftEvent();

  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  return (
    <div className="filterFindIsv">
      <div className="contentFilter">
        <div className="containerBtn block firstBlock">
          <button
            className="btn btn-devinsider cancel"
            onClick={() =>
              props?.filter(
                providerLeft.selectedTags,
                providerLeft.selectedType
              )
            }
          >
            Filter
          </button>
          <a
            href="javascript:;"
            className="resetFilter"
            onClick={() => {
              providerLeft.clearFilter();
              props?.resetFilter();
            }}
          >
            <span className="icon-refresh" />
            Reset filter
          </a>
        </div>
        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Event types
          </h3>
          <div className="listCheck">
            {paginate(
              providerLeft?.findProgramReducer?.event_type,
              providerLeft?.limitType
            )?.map(
              (
                el: {
                  id: number;
                  name: string;
                },
                index: number
              ) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={index}
                >
                  <input
                    type="checkbox"
                    id={"fr" + index}
                    className="custom-control-input"
                    checked={
                      providerLeft?.selectedType?.find((e) => e === el?.id)
                        ? true
                        : false
                    }
                    onChange={() => providerLeft?.handleType(el?.id)}
                  />
                  <label
                    htmlFor={"fr" + index}
                    className="custom-control-label"
                  >
                    {el?.name}
                  </label>
                </div>
              )
            )}
            {providerLeft?.findProgramReducer?.event_type?.length >
            providerLeft?.limitType ? (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() => providerLeft?.expandOrNot("EVENT_TYPE")}
              >
                See more(
                {providerLeft?.findProgramReducer?.event_type?.length -
                  providerLeft?.limitType}
                )
              </a>
            ) : (
              providerLeft?.findProgramReducer?.event_type?.length <=
                providerLeft?.limitType &&
              providerLeft?.findProgramReducer?.event_type?.length > 6 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() => providerLeft?.expandOrNot("EVENT_TYPE", 2)}
                >
                  See less
                </a>
              )
            )}
          </div>
        </div>
        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Top tag
          </h3>
          <div className="listCheck">
            {paginate(
              providerLeft?.findProgramReducer?.top_tags,
              providerLeft?.limitTag
            )?.map(
              (
                el: {
                  id: number;
                  event_number: number;
                  title: string;
                },
                index: number
              ) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={index}
                >
                  <input
                    type="checkbox"
                    id={"ai" + index}
                    className="custom-control-input"
                    checked={
                      providerLeft?.selectedTags?.find((e) => e === el?.id)
                        ? true
                        : false
                    }
                    onChange={() => providerLeft?.handleTags(el?.id)}
                  />
                  <label
                    htmlFor={"ai" + index}
                    className="custom-control-label"
                  >
                    {el?.title} ({el?.event_number})
                  </label>
                </div>
              )
            )}
            {providerLeft?.findProgramReducer?.top_tags?.length >
            providerLeft?.limitTag ? (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() => providerLeft?.expandOrNot("TAGS")}
              >
                See more(
                {providerLeft?.findProgramReducer?.top_tags?.length -
                  providerLeft?.limitTag}
                )
              </a>
            ) : (
              providerLeft?.findProgramReducer?.top_tags?.length <=
                providerLeft?.limitTag &&
              providerLeft?.findProgramReducer?.top_tags?.length > 6 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() => providerLeft?.expandOrNot("TAGS", 2)}
                >
                  See less
                </a>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlocLeftEvent;
