import moment from "moment";
import React from "react";
import {
  activeFilterContributor,
  ItemAllTopContributor,
} from "../../../../../controller/community-ctr/bloc-community-ctr/use-view-all-top-author-contributor";
import {
  concatString,
  dateDiff,
  fileUrl,
  getUserType,
} from "../../../../../utils";
import { LoadingArticle } from "../../../../widget/loader";
import Pagination from "../../../../widget/use-pagination/pagination";

export type IPropsTopContributor = {
  activeFilter:
    | "last-day"
    | "last-week"
    | "last-month"
    | "last-six-mounth"
    | "last-year"
    | "all-time";
  allContributors: Array<ItemAllTopContributor>;
  setFilter: (p: activeFilterContributor) => void;
  loaderContributor: boolean;
  totalData: number;
  handlePageClick: (p: number) => void;
  currentPage: number;
  categorie: string;
  showActivityUser: (p: string) => void;
  goToreplayPost: (idCategory: string, idPost: string) => void;
  goToLabelCategory: (
    idCategory: string,
    idLabel: string,
    postType: string
  ) => void;
};
export default function ContributionsTab(props: IPropsTopContributor) {
  const style = { cursor: "pointer" };
  return (
    <div>
      <div className="timeRange">
        <p>Time range: </p>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-day" ? "active" : ""}
          onClick={() => props?.setFilter("last-day")}
        >
          Last day (24 Hours)
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-week" ? "active" : ""}
          onClick={() => props?.setFilter("last-week")}
        >
          Last week (7 Days)
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-month" ? "active" : ""}
          onClick={() => props?.setFilter("last-month")}
        >
          Last months (30 Days)
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-six-mounth" ? "active" : ""}
          onClick={() => props?.setFilter("last-six-mounth")}
        >
          Last 6 months{" "}
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "last-year" ? "active" : ""}
          onClick={() => props?.setFilter("last-year")}
        >
          Last year
        </a>
        <a
          href="javascript:;"
          className={props?.activeFilter === "all-time" ? "active" : ""}
          onClick={() => props?.setFilter("all-time")}
        >
          All time
        </a>
      </div>
      <h3 className="title">Top liked contributions in {props?.categorie}</h3>
      <div className="blockOfList">
        {props?.loaderContributor ? (
          <LoadingArticle />
        ) : (
          <ul className="feedList categoryList">
            {props?.allContributors?.map(
              (el: ItemAllTopContributor, index: number) => (
                <li key={index}>
                  <span className="rankPub">{index + 1}</span>
                  {el?.solve && (
                    <div className="solved">
                      <div className="contentSolved">
                        <span className="icon-check" />
                      </div>
                    </div>
                  )}

                  <div className="infoAbout">
                    <div className="withPost">
                      <div className="imageAffiliatedcompany empty">
                        <img src={fileUrl(el?.user?.file_name)} />
                      </div>
                      <div
                        className={`nameOfPost ${getUserType(
                          el?.user?.roles?.[0]
                        )}`}
                      />
                    </div>
                    <div className="infoFeed">
                      <div
                        className="titlePost"
                        onClick={() =>
                          props.goToreplayPost(el?.category?.id, el?.id)
                        }
                      >
                        {el?.post_type?.id == 2 && (
                          <span className="article">Article</span>
                        )}
                        <span className="title">{el?.title}</span>
                      </div>
                      <div className="postBy">
                        by{" "}
                        <a
                          style={style}
                          href="javascript:;"
                          onClick={(e: any) => {
                            props?.showActivityUser(el?.user?.id?.toString());
                          }}
                        >
                          {concatString(
                            el?.user?.first_name,
                            el?.user?.last_name,
                            " "
                          )}
                        </a>{" "}
                        on{" "}
                        {moment(new Date(el?.created_at)).format(
                          "MMM D, YYYY h:mm a"
                        )}
                      </div>
                      <div className="postBy">
                        Latest activity {dateDiff(el?.updated_at)} by{" "}
                        <a
                          style={style}
                          href="javascript:;"
                          onClick={(e: any) => {
                            props?.showActivityUser(el?.last_activity_by?.id);
                          }}
                        >
                          {concatString(
                            el?.last_activity_by?.first_name,
                            el?.last_activity_by?.last_name
                          )}
                        </a>
                      </div>
                      <div className="subCategory">
                        {el?.labels?.map((label: any, index: number) => (
                          <span
                            key={index}
                            onClick={() =>
                              props?.goToLabelCategory(
                                el?.category?.id,
                                label.id,
                                el?.post_type?.id?.toString()
                              )
                            }
                          >
                            {label?.title}
                          </span>
                        ))}

                        {el?.tags?.map((tag: any, index: number) => (
                          <span
                            key={index}
                            onClick={() =>
                              props?.goToLabelCategory(
                                el?.category?.id,
                                tag.id,
                                el?.post_type?.id?.toString()
                              )
                            }
                          >
                            {tag?.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="replyInfo">
                    <b>{el?.numberOfLikes}</b>Likes
                  </div>
                  <div className="viewInfo">
                    <b>{el?.numberOfViews}</b>views
                  </div>
                </li>
              )
            )}
          </ul>
        )}

        <div className="pagination">
          <Pagination
            className="pagination"
            currentPage={props?.currentPage}
            totalCount={props?.totalData}
            pageSize={10}
            onPageChange={(page: any) => props?.handlePageClick(page)}
          />
        </div>
      </div>
    </div>
  );
}
