import { RawDraftContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import { IPropsPost } from './type';

export default function index(props: IPropsPost) {
  return (
    <div className='editorContent'>
      <Editor
        //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
        mention={{
          separator: ' ',
          trigger: '@',
          suggestions: props?.mention,
        }}
        editorState={props?.editorState}
        wrapperClassName='wrapper-class'
        editorClassName='editor-class'
        toolbarClassName='toolbar-class'
        onEditorStateChange={(editorState: any) => {
          props.setEditorState(editorState);
        }}
        onChange={(value: RawDraftContentState) => {
          //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
          props?.updateMention(value);
        }}
        toolbar={{
          options: [
            'inline',
            // 'fontSize',
            // "fontFamily",
            'list',
            'history',
            // 'image',
          ].concat(props?.isExpert ? ['image'] : []),
          image: {
            uploadCallback: props.uploadPostType ? props?.uploadCallBack : (p: any) => {
              let uploadedImages = [];

              const imageObject = {
                file: p,
                localSrc: URL.createObjectURL(p),
              };
              uploadedImages.push(imageObject);
              // We need to return a promise with the image src
              // the img src we will use here will be what's needed
              // to preview it in the browser. This will be different than what
              // we will see in the index.md file we generate.
              return new Promise((resolve, reject) => {
                resolve({ data: { link: imageObject.localSrc } });
              });
            },
          },
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          /*image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: undefined,
            previewImage: false,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "auto",
            },
          },*/
        }}
      />
      <div className='contentBorder formForEmail text-left'>
        <button
          className='btn btn-devinsider px-5 save'
          onClick={() => props?.sendPost()}
        >
          {props?.loader ? <Loader /> : 'Post'}
        </button>

        <button
          className='btn btn-devinsider cancel'
          onClick={() => props?.cancelPost()}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
