import moment from "moment";
import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";

type Iprops = {
  setTabNavHistory: (txt: string) => void;
};

export default function HeaderTab(props: Iprops) {
  return (
    <>
      <div className="menuTransactionTab borderAll">
        <Nav variant="pills">
          <Nav.Item className="col-lg-6 border-right">
            <Nav.Link
              className="transaction"
              eventKey="history_list"
              onClick={() => props?.setTabNavHistory("history_list")}
            >
              Transactions
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="col-lg-6">
            <Nav.Link
              eventKey="receipts_list"
              onClick={() => props?.setTabNavHistory("receipts_list")}
            >
              Receipts
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </>
  );
}
