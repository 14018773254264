import React, { ChangeEvent } from "react";
import { IPropsForm } from "./types";
export default function index(props: IPropsForm) {
  return (
    <div>
      <form action="">
        <select
          name="Categories"
          onChange={(evt: any) => {
            props?.handleCateGory(evt?.target?.value);
          }}
        >
          <option value="">Search categories</option>
          {props?.categories?.map((el: any, index: number) => (
            <option value={el?.id} key={index}>
              {el?.title}
            </option>
          ))}
        </select>
        <div className="inputAndbtn">
          <button type="button" onClick={() => props?.handleFilterTxt()}>
            <span className="icon-search"></span>
          </button>
          <input
            type="text"
            value={props?.searchText}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              props?.filterSearchText(evt?.target?.value);
            }}
          />
        </div>
      </form>
    </div>
  );
}
