import { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  constante,
  filterInvestors,
  likeInvestor,
  unlockInvestor,
} from '../../../redux/actions/find-investor-action';
import {
  IAction,
  IInvestor,
  IInvestorMatching,
} from '../../../redux/types/find-investor-interface';
import { conversationType } from '../../../utils';
import UseFilterControl from './bloc-filter-find-investor/use-filter-control-find-investor';
import UseUnlockInvestor from '../../../screen/widget/payement-isv/unlock-investor/use-unlock-investor';
import { toast } from 'react-toastify';
import { ISV } from '../../../service/constant';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';

const initialState: IInvestorMatching = {
  results: [],
  total_to_paginate: 0,
};

const treatFavorite = (id: number, array: Array<IInvestor>) => {
  return array?.map((el) => {
    if (el?.investor?.id === id) {
      return {
        ...el,
        is_favorite: !el?.is_favorite,
      };
    } else {
      return el;
    }
  });
};

const treatUnlocked = (object: any, array: Array<IInvestor>) => {
  return array?.map((el) => {
    if (object?.investor?.id === el?.investor?.id) {
      return object;
    } else {
      return el;
    }
  });
};

const findInvestorReducer = (
  state = initialState,
  action: IAction
): IInvestorMatching => {
  switch (action?.type) {
    case constante?.FILTER:
      return {
        ...state,
        ...action?.payload,
      };
    case constante?.ADD_TO_FAVORITE:
      return {
        ...state,
        results: treatFavorite(action?.payload, state?.results),
      };
    case constante?.UNLOCKED_ACTION:
      return {
        ...state,
        results: treatUnlocked(action?.payload, state?.results),
      };
    default:
      return state;
  }
};

export default function UseFindInvestor() {
  const [investors, dispatch] = useReducer<
    (state: IInvestorMatching, action: IAction) => IInvestorMatching
  >(findInvestorReducer, initialState);
  let userObject = useSelector((state: rootState) => state.userReducer);

  const providerModalUseUnlockInvestor = UseUnlockInvestor();
  const {
    match,
    selectedHqLocation,
    selectedIndustry,
    selectedSolution,
    selectedType,
    setMatch,
    setSelectedHqLocation,
    setSelectedIndustry,
    setSelectedSolution,
    setSelectedType,
    size,
    resetFilter,
  } = UseFilterControl();

  const [orderBy, setOrderBy] = useState('DESC');
  const [sortBy, setSortBy] = useState<
    'last_update' | 'relevance' | 'last_unlocked'
  >('relevance');
  const [search_name, setSearch_name] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState<number>(9);

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [isPitchValidate, setIsPitchValidate] = useState<boolean>(false);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      search: '',
    },
  });

  const history = useHistory();

  const filtered = [
    {
      value: 'relevance',
      label: 'Relevance',
    },
    {
      value: 'last_update ',
      label: 'Last Update',
    },
    {
      value: 'last_unlocked',
      label: 'Last Unlocked',
    },
  ];

  const callback = (response: any) => {
    if (response?.status === 200) {
      setLoader(false);
      if (!response?.data?.is_validate) {
        setIsPitchValidate(true);
      }
    }
  };

  const filterInvestor = async (dataToChange: any = {}) => {
    setLoader(true);
    isPitchValidate && setIsPitchValidate(false);
    let params = {
      institutionalInvestorType: selectedType?.join(),
      minimumMatch: match[0] ? match[0] : '',
      maximumMatch: match[1] ? match[1] : '',
      hqlocation: selectedHqLocation?.join(),
      solutionSpecialization: selectedSolution?.join(),
      industrySpecialization: selectedIndustry?.join(),
      sortBy,
      orderBy,
      search_name,
      page: page,
      ...dataToChange,
    };
    await filterInvestors(params, dispatch, callback);
  };

  useEffect(() => {
    (async () => {
      await filterInvestor();
    })();
    return () => {};
  }, []);

  const resetTri = () => {
    setPage(1);
    setOrderBy('DESC');

    return {
      page: 1,
      orderBy: 'DESC',
    };
  };

  const handleResetFilter = async () => {
    resetFilter();
    setSearch_name('');
    setOrderBy('DESC');
    setPage(1);

    let params = {
      institutionalInvestorType: '',
      minimumMatch: 70,
      maximumMatch: 100,
      hqlocation: '',
      solutionSpecialization: '',
      industrySpecialization: '',
      orderBy: 'DESC',
      search_name: '',
      page: 1,
    };

    await filterInvestor(params);
  };

  const filter = async () => {
    await filterInvestor(resetTri());
  };

  const onSubmitSearch = async (data: any) => {
    setSearch_name(data?.search);
    setPage(1);
    filterInvestor({
      page: 1,
      search_name: data?.search,
    });
  };

  const loadMoreProgram = async (page: number) => {
    setPage(page);
    let paramsChange = {
      page,
    };
    await filterInvestor(paramsChange);
  };

  const handleFilter = async (params: any) => {
    setSortBy(params?.value);
    let paramsChange = {
      sortBy: params?.value,
      page: 1,
    };
    await filterInvestor(paramsChange);
  };

  const addToFavorites = async (id: any) => {
    likeInvestor(id, dispatch, () => {});
  };

  const contactIvestor = (id: number) => {
    history.push('/contact/discussion', {
      userId: id,
      discussionType: conversationType.isv_to_investor,
    });
  };

  const addToUnlocked = async (id: any) => {
    if (loading) {
      setLoading(false);
    }
    providerModalUseUnlockInvestor.showModalUnlock(id);
  };

  const cb = (result: boolean) => {
    if (result) {
      providerModalUseUnlockInvestor.closeUlocModal();
      toast('Unlocked successfully ');
    } else {
      toast('An Error Occured');
    }
    setLoading(false);
  };

  const unlockedInvestor = () => {
    setLoading(true);
    const params = {
      id: providerModalUseUnlockInvestor.idInvestorPage,
      choice: providerModalUseUnlockInvestor.choiseValue,
    };
    unlockInvestor(params, dispatch, cb);
  };

  const isIsv = userObject?.roles?.indexOf(ISV) !== -1;

  return {
    loader,
    investors,
    match,
    selectedHqLocation,
    selectedIndustry,
    selectedSolution,
    selectedType,
    size,
    setMatch,
    setSelectedHqLocation,
    setSelectedIndustry,
    setSelectedSolution,
    setSelectedType,
    resetFilter: handleResetFilter,
    filter,
    register,
    handleSubmit,
    onSubmitSearch,
    loadMoreProgram,
    handleFilter,
    filtered,
    page,
    limit,
    sortBy,
    addToFavorites,
    addToUnlocked,
    contactIvestor,
    providerModalUseUnlockInvestor,
    unlockedInvestor,
    loading,
    isPitchValidate,
    isIsv,
  };
}
