import { useForm } from "react-hook-form";
import useNavigation from "../../../hooks/use-navigate";
import { useCompleteDataStorageOem } from "./use-complete-data-storage-oem";

interface StepData {
  jobTitleOem: string;
  companyNameOem: string;
}

export default function useCompleteOemCompany() {
  const { navigate } = useNavigation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { dataOem, addDataOem } = useCompleteDataStorageOem();

  const onSubmit = (data: StepData) => {
    addDataOem({
      jobTitle: data.jobTitleOem,
      companyName: data.companyNameOem,
    });
    //navigate("/complete-website-location-oem");
    navigate("/complete-profil-oem-interest");
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    dataOem,
  };
}
