/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import { IpictureSelect } from "./type";
import Button from "react-bootstrap/Button";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";
import { fileUrl } from "../../../utils";

export default function ModalPhoto(props: IpictureSelect) {
  return (
    <>
      {/* Edit Photo de profil */}

      <Modal
        show={props.isShowImage}
        onHide={() => {
          props.hideSelectedFile();
        }}
        className={`${props.largeDesign ? "largePhoto" : ""} modalDevinsider`}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit {props?.largeDesign ? "background" : "photo"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0">
            <div
              className={`imageProfil mx-auto ${
                props.fileUrl ? "overnoBg" : ""
              }`}
            >
              <a onClick={() => props.setShowImage(true)}>
                <img
                  src={
                    props.selectedFile
                      ? URL.createObjectURL(props.selectedFile)
                      : `${fileUrl(props.fileUrl)} `
                  }
                  alt=""
                />
              </a>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="sameBtn">
          <Button className="validate " onClick={() => props.handleBtn()}>
            Select photo
            <input
              type="file"
              id="getFile"
              accept="image/png, image/jpeg"
              ref={props.fileRef}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                props.changeHandler(event)
              }
              style={{ display: "none" }}
            />
          </Button>
          {/* {props.selectedFile && ( */}
          <>
            <Button
              className="validate"
              onClick={() => props.updateUserDetail("photo")}
              disabled={props.isLoaderShow || !props.selectedFile}
            >
              {props.isLoaderShow ? <Loader /> : "Save"}
            </Button>
            <Button
              className="cancelbtn"
              onClick={() => props?.restorePhotoChange()}
            >
              {props.processingRemovePhotoProfile ? <Loader /> : "Delete photo"}
            </Button>
          </>
          {/* )} */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
