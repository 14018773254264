/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  Taffilliated,
  TitemSentInvitation,
  TitemSentAaffiliated,
} from "./type";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling-white.svg";

import Alert from "react-bootstrap/Alert";
import { fileUrl, uppercaseFirstLetter } from "../../../../utils/index";

export default function AffiliatedInsider(props: Taffilliated) {
  return (
    <div className="bodyCreateCompany">
      <div className="contentBorder row border-bottom affiliatedPopup">
        {props.showMessage && (
          <Alert variant={props.typeMessage ? "success" : "danger"}>
            {props?.txtMessage}
          </Alert>
        )}
        <div className="col-12">
          <label htmlFor="">
            Invite new insiders to join your company page
          </label>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12 col-12 form-group pr-3">
          <input
            type="text"
            className="form-control"
            value={props.emailInvite}
            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
              props.setEmailInvite(evt.target.value)
            }
            placeholder="email address"
          />
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12 col-12 form-group d-flex align-items-center">
          <select
            name=""
            id="size"
            className="custom-select mr-3"
            onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
              props.setTypeAffiliated(evt.target.value);
            }}
          >
            {props?.affiliatedType?.map(
              (el: { id: number; label: string; value: string }) => (
                <option
                  value={el?.value}
                  selected={el?.value == props.typeAffiliated}
                >
                  {el?.label}
                </option>
              )
            )}
          </select>
          {props?.typeAffiliated === "MEMBER" && (
            <OverlayTrigger
              overlay={
                <Tooltip id="trade">
                  This role “Member” does not grant access to the edit mode of
                  the company page.
                </Tooltip>
              }
            >
              <span className="icon-aide"></span>
            </OverlayTrigger>
          )}
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12 col-12 text-right">
          <button
            className="btn btn-devinsider px-5"
            onClick={() => {
              props.sendInvitation();
            }}
            disabled={
              props.loaderAffiliated || !props?.emailInvite ? true : false
            }
          >
            {props.loaderAffiliated ? <Loader /> : "Send"}
          </button>
        </div>
      </div>
      <div className="invitationSent py-3">
        <h3 className="title">
          {
            props.invitation?.filter(
              (el: TitemSentInvitation) => el.status === "PENDING"
            )?.length
          }{" "}
          Invitation sent
        </h3>
        <div className="contentBorder borderAll affiliatedPopup p-0">
          {props.invitation
            ?.filter((el: TitemSentInvitation) => el.status === "PENDING")
            ?.map((el: TitemSentInvitation) => (
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center p-3">
                  <div className="profilImg empty"></div>
                  <div className="infoAffiliated">{el?.email}</div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center p-3">
                  {uppercaseFirstLetter(el.role)}
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center justify-content-end p-3 "
                  onClick={() => props.removeInvitation(el?.id)}
                >
                  <a className="cancelInvitation linkAffiliated">
                    {props?.idRemove == el?.id.toString() ? "" : "Cancel"}
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="invitationSent py-3">
        <h3 className="title">
          {
            props.invitation?.filter(
              (el: TitemSentInvitation) => el.status === "REQUEST"
            )?.length
          }{" "}
          Pending request
        </h3>
        <div className="contentBorder borderAll affiliatedPopup p-0">
          {props.invitation
            ?.filter((el: TitemSentInvitation) => el.status === "REQUEST")
            ?.map((el: TitemSentInvitation) => (
              <div className="row" key={el?.id}>
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center p-3">
                  <div className="profilImg empty">
                    <img src={fileUrl(el?.photo_profil)} alt="" />
                  </div>
                  <div className="infoAffiliated">
                    <a>{el?.first_name}</a>
                    {el?.job_title}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center p-3">
                  <div className="form-group m-0 p-0">
                    <div className="form-group m-0 p-0">
                      {/* <Loader /> */}
                      <select
                        name=""
                        id=""
                        className="custom-select"
                        onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                          props?.setroleOfAccepteRequest(evt?.target?.value);
                        }}
                        defaultValue={
                          props?.affiliatedType?.filter(
                            (element) => element?.label === "Admin"
                          )[0]?.value
                        }
                      >
                        {props?.affiliatedType?.map(
                          (element: {
                            id: number;
                            label: string;
                            value: string;
                          }) => (
                            <option
                              value={element?.value}
                              selected={
                                element?.value == props?.roleOfAccepteRequest
                              }
                            >
                              {element?.label}
                            </option>
                          )
                        )}
                      </select>
                      {el?.role === "MEMBER" && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="trade">
                              This role “Member” does not grant access to the
                              Edit mode of the company page.
                            </Tooltip>
                          }
                        >
                          <span className="icon-aide"></span>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center justify-content-end p-3 ">
                  <a
                    className="linkAffiliated cancelInvitation "
                    onClick={() =>
                      props?.validRequest({
                        invitationId: el?.id,
                        event: "MEMBER_INVITATION_DENY",
                      })
                    }
                  >
                    Cancel
                  </a>
                  <a
                    className="approveInvitation linkAffiliated"
                    onClick={() =>
                      props?.validRequest({
                        invitationId: el?.id,
                        event: "MEMBER_INVITATION_APPROVE",
                      })
                    }
                  >
                    Approve
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="invitationSent py-3">
        <h3 className="title">
          {props?.affiliatedList?.length} Affiliated insiders
        </h3>
        <div className="contentBorder borderAll affiliatedPopup p-0">
          {props?.affiliatedList?.map((el: TitemSentAaffiliated) => (
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center p-3">
                <div className="profilImg empty">
                  <img src={fileUrl(el?.user?.file_name)} alt="" />
                </div>
                <div className="infoAffiliated">
                  <span className="noEvent">{el?.user?.first_name}</span>
                  {el?.user?.job_title}
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center p-3">
                {el?.user?.id.toString() != props.currentId && (
                  <div className="form-group m-0 p-0">
                    <select
                      name=""
                      id=""
                      className="custom-select"
                      onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                        props.setRoleAffiliated(
                          evt?.target?.value,
                          el?.user?.id?.toString()
                        );
                      }}
                    >
                      {props?.affiliatedType?.map(
                        (element: {
                          id: number;
                          label: string;
                          value: string;
                        }) => (
                          <option
                            value={element?.value}
                            selected={element?.value == el?.role}
                          >
                            {element?.label}
                          </option>
                        )
                      )}
                    </select>
                    {props.roleAffiliated?.idUser?.toString() ===
                      el?.user?.id?.toString() &&
                      props.loaderUpdateRole && <Loader />}

                    {el?.role === "MEMBER" && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="trade">
                            This role “Member” does not grant access to the Edit
                            Mode of the company page.
                          </Tooltip>
                        }
                      >
                        <span className="icon-aide"></span>
                      </OverlayTrigger>
                    )}
                  </div>
                )}
              </div>
              {el?.user?.id.toString() != props.currentId && (
                <div className="col-xl-4 col-lg-4 col-md-12 col-12 d-flex align-items-center justify-content-end p-3 ">
                  <a
                    onClick={() => props.removeRole(el?.user?.id)}
                    className="linkAffiliated cancelInvitation "
                  >
                    Remove
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
