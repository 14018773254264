/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from "../../../../../../resources/images/Rolling.svg";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
import DragAndDrop from "../../../../../widget/drag-and-drop/index";

export default function ModalEditPichDoc(props: T.IeditDoc) {
  return (
    <div>
      {/* Popup Drag file */}
      <Modal
        show={props.isShowModalFile}
        className="createCompanypopup large forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Document</h2>
            <a className="close" onClick={() => props.cancelChangeFile()}>
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="contentBorder row forPitch">
            <div className="upload">
              <ul className="saveFile" onClick={() => props.handleBtn()}>
                Select one file :
                <button className="favorite styled" type="button">
                  Choose file
                </button>
              </ul>

              <ul className="saveFile"></ul>
              <div>
                <DragAndDrop
                  handleDrop={(p: any) => props.handleDrop(p)}
                  urlValue="application/pdf,
                  application/vnd.ms-powerpoint,
                  application/vnd.openxmlformats-officedocument.presentationml.slideshow,
                  application/vnd.openxmlformats-officedocument.presentationml.presentation,
                  .pps"
                >
                  {props.isFilePicked && props.fileName ? (
                    <p>{props.fileName}</p>
                  ) : (
                    <p>Drop the files here ...</p>
                  )}
                </DragAndDrop>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right forPitch">
            <button
              className="btn btn-devinsider cancel"
              // onClick={handleCloseRequestBasicInformation}
              onClick={() => props.cancelChangeFile()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-devinsider px-5"
              onClick={() => props.updatePicth()}
            >
              {props.loaderUpdateDoc ? <Loader /> : "Save"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <input
        type="file"
        id="getFile"
        accept="application/vnd.ms-powerpoint,application/pdf"
        ref={props.fileRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          props.changeHandler(event)
        }
        style={{ display: "none !important" }}
      />
    </div>
  );
}
