import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { rootState } from '../../../../redux/reducers';
import {
  OEM_PROGRAM,
  OEM_PROGRAM_EDITOR,
  OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
} from '../../../../service/constant';
import { checkType, fileUrl } from '../../../../utils';
type IProps = {
  showProfil: () => void;
  goToSettings: () => void;
  logOut: () => void;
  userState: any;
  goToBillings: () => void;
  goToSubscriptionPlan: () => void;
};

const renderMenuBillingOemProgram = (
  user: any,
  currentOemProgram: any
): boolean => {
  let roleUserInOemProgram = (() => {
    if (user?.roles?.indexOf(OEM_PROGRAM) !== -1) {
      let roleInCurrentProgram = user?.oemProgramMembers?.filter(
        (role: any) => role?.oem_program?.id === currentOemProgram.id
      )[0];
      return roleInCurrentProgram?.role;
    }
    return '';
  })();
  //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
  return !checkType([roleUserInOemProgram], []);
};

export default function BlocAccountProfil(props: IProps) {
  const currentOemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  return (
    <div className='item'>
      <Link to='#' className='parent linkHeader'>
        <div className={`profilImg`}>
          <img alt='Profile' src={`${fileUrl(props.userState.file_name)}`} />
        </div>
        <span className='mobile'>My account</span>
      </Link>
      <div className='submenu'>
        <Link
          to='#'
          onClick={(e: any) => {
            e?.preventDefault();
            props.showProfil();
          }}
        >
          Profile
        </Link>
        <Link
          to='#'
          onClick={(e: any) => {
            e?.preventDefault();
            props.goToSettings();
          }}
        >
          Account settings
        </Link>
        {
          //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor"
          renderMenuBillingOemProgram(props.userState, currentOemProgram) ===
          true ? (
            <>
              {/* <Link
                to='#'
                onClick={(e: any) => {
                  e?.preventDefault();
                  props.goToBillings();
                }}
              >
                Billing
              </Link> */}
            </>
          ) : (
            <></>
          )
          /*
        <a
          href="javascript:;"
          onClick={(e: any) => {
            e?.preventDefault();
            props.goToSubscriptionPlan();
          }}
        >
          Purchase subscriptions
        </a>*/
        }
        <Link
          to='#'
          onClick={(e) => {
            e?.preventDefault();
            props.logOut();
          }}
        >
          Sign out
        </Link>
      </div>
    </div>
  );
}
