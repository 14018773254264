import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import ManageCategoriesAndLabels from "../../../../controller/community-ctr/manage-feed-ctr/categories-labels-ctr";
import {
  IDiscutionCategorie,
  ITemDetailsDiscutionCategorie,
} from "../../../../redux/types/community-interface";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";

export default function CategoriesLables() {
  const providerCategoriesLabels = ManageCategoriesAndLabels();

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id: any) {
    if (activeId == id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }
  return (
    <div className="accountSettings contentRightsettings">
      <h1 className="title">Manage your Feed</h1>
      <Accordion className="accordionForSetting" defaultActiveKey={activeId}>
        {providerCategoriesLabels?.communityReducer?.detailsDiscutionCategorie?.map(
          (el: ITemDetailsDiscutionCategorie, key: number) => (
            <div className="lineAccordion">
              <Accordion.Toggle
                eventKey={`${key}`}
                className={`btnAccordion ${
                  activeId === `${key}` ? "active" : ""
                }`}
                onClick={() => {
                  toggleActive(`${key}`);
                }}
              >
                <div className="categFeed">
                  <div className="title">
                    <span className={el?.icon}></span>
                    <div className="info">
                      <b>{el?.title}</b>
                      {el?.contribution} contributions
                    </div>
                  </div>
                  {providerCategoriesLabels?.loaderFollow[key] ? (
                    <button className="followFeed disabled">
                      <Loader />
                    </button>
                  ) : el?.is_followed ? (
                    <button
                      className="followFeed"
                      onClick={(e) => {
                        providerCategoriesLabels?.followOrUnfollow(
                          el?.id,
                          el?.is_followed,
                          key
                        );
                      }}
                    >
                      Following
                    </button>
                  ) : (
                    <button
                      className="followFeed active"
                      onClick={() =>
                        providerCategoriesLabels?.followOrUnfollow(
                          el?.id,
                          el?.is_followed,
                          key
                        )
                      }
                    >
                      Follow
                    </button>
                  )}
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={`${key}`}>
                <div className="panel-body">
                  <ul className="listFollow">
                    {el?.labels?.map(
                      (label: { id: number; title: string }, index: number) => (
                        <li>
                          <div className="title">{label?.title}</div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </Accordion.Collapse>
            </div>
          )
        )}
      </Accordion>
    </div>
  );
}
