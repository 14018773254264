import React from "react";
import url from "../../../../service/constant/index";
import { fileUrl, uppercaseFirstLetter } from "../../../../utils";

const MemberCard = (props: any) => {
  if (props?.members?.length === 0) {
    return <li className="empty">No affiliation</li>;
  }
  return props?.members?.map((member: any) => {
    return (
      <li className="item" key={Math.random()}>
        <div className="imageAffiliated">
          <img src={`${fileUrl(member?.user?.file_name)} `} alt="" />
        </div>
        <div className="infoAffiliated">
          <h3 className="title">
            {member?.user?.first_name} {member?.user?.last_name}
          </h3>
          <p>{member?.user?.job_title}</p>
          <b>{props.company_page}</b>
        </div>
        <div className="role">{uppercaseFirstLetter(member?.role)}</div>
      </li>
    );
  });
};

export default MemberCard;
