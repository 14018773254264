/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "react-bootstrap/Modal";
import SettingPageInvestor from "../popup/page-settings/index";
import { IPropsEditSettingPage } from "../types";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export default function EditSettingPage(props: IPropsEditSettingPage) {
  return (
    <>
      <Modal
        show={props.showPageSettings}
        className="createCompanypopup large "
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Page settings</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => props.setShowPageSettings()}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>

        <Modal.Body>
          <SettingPageInvestor
            changeSelect={(p: boolean) => {
              props?.changeSelect(p);
            }}
            wantToShow={props?.wantToShow}
            companySize={props?.companySize}
            companySizeSlide={props?.companySizeSlide}
            setCompanySize={(evt: Array<number>) => props?.setCompanySize(evt)}
            customSetCompanySize={(min: number, max: number) =>
              props?.customSetCompanySize(min, max)
            }
            customSetAnnualSize={(min: number, max: number) =>
              props?.customSetAnnualSize(min, max)
            }
            annualTurnoverSlide={props?.annualTurnoverSlide}
            annualTurnover={props?.annualTurnover}
            unitTurnover={props?.unitTurnover}
            unitTurnoverMax={props?.unitTurnoverMax}
            setAnnualTurnover={props?.setAnnualTurnover}
            foundingRound={props?.foundingRound}
            setFoundingRound={(data: any) => props?.setFoundingRound(data)}
            solutionLeftColumnData={props?.solutionLeftColumnData}
            solutionRigthColumnData={props?.solutionRigthColumnData}
            addSolutionCheched={(p: { id: number; name: string }) =>
              props?.addSolutionCheched(p)
            }
            addSolutionChechedFeatured={(p: { id: number; name: string }) =>
              props?.addSolutionChechedFeatured(p)
            }
            featuredSolution={props?.featuredSolution}
            solutionChecked={props?.solutionChecked}
            solutionCheckedFeatures={props?.solutionCheckedFeatures}
            fundraisingGoal={props?.fundraisingGoal}
            customFundraisingGoalSlide={(min: number, max: number) =>
              props?.customFundraisingGoalSlide(min, max)
            }
            fundraisingGoalSlide={props?.fundraisingGoalSlide}
            setFundraisingGoal={(data: any) => props?.setFundraisingGoal(data)}
            unitFundraisingGoal={props?.unitFundraisingGoal}
            unitFundraisingGoalMax={props?.unitFundraisingGoalMax}
            addPaysInArray={(data: any) => props?.addPaysInArray(data)}
            allContinentSelected={props?.allContinentSelected}
            allContinents={props?.allContinents}
            paysChecked={props?.paysChecked}
            addTargetCompany={(p: any) => {
              props.addTargetCompany(p);
            }}
            companyTarget={props?.companyTarget}
            companyTargetSecound={props?.companyTargetSecound}
            companyTargetSelected={props?.companyTargetSelected}
            lastCompanyTarget={props?.lastCompanyTarget}
            funMin={props?.funMin}
            funMax={props?.funMax}
            filterSolutionChecked={props?.filterSolutionChecked}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => props.setShowPageSettings()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-devinsider px-5"
              onClick={() => props?.investorValideUpdate()}
            >
              {props?.loader ? <Loader /> : "Save"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
