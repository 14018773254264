import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

type IProps = {
  show: boolean;
  handleModal: () => void;
  classifiedAdsOptionList: Array<any>;
  goToPostAds: (p: any) => void;
};

export default function ModalPostAdds(props: IProps) {
  const [activeCategory, setActiveCategory] = useState<any>();
  return (
    <Modal
      show={props.show}
      className='createCompanypopup large forStep5custom'
    >
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>Post classified ad</h2>
          <a
            href='javascript:;'
            className='close'
            onClick={() => props.handleModal()}
          >
            <span className='icon-close-pop'></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className=''>
        <div className='contentBorder row forPitch forAds'>
          <p>
            Classified ads on Devinsider help ISVs to establish strategic
            partnerships with channel partners, investors, industry experts and
            other ISVs.
          </p>
          <label htmlFor=''>Select a type of ad : </label>
          <div className='listPost'>
            {props?.classifiedAdsOptionList.map((el: any, key: number) => (
              <a
                href='javascript:;'
                className={`choicePost ${activeCategory === key && 'active'}`}
                key={key}
                onClick={(e: any) => {
                  e?.preventDefault();
                  setActiveCategory(key);
                }}
              >
                <span className={el?.icon}></span>
                {el?.type}
              </a>
            ))}
          </div>
          {
            (activeCategory === 0 || activeCategory === null || activeCategory === undefined) && (
              <div className='postAdcomment'>
                Establish sales & marketing partnerships with local/global channel
                partners.
              </div>
            )
          }

          {
            activeCategory === 1 && (
              <div className='postAdcomment'>
                Post a job ad to fill a strategic position in your team.
              </div>
            )
          }

          {
            activeCategory === 2 && (
              <div className='postAdcomment'>
                Find technology partners to enhance your product.
              </div>
            )
          }

          {
            activeCategory === 3 && (
              <div className='postAdcomment'>
                Find a partner that can help you with Funding, Exit, IP/Asset Sale or Restructuring of your company.
              </div>
            )
          }
          
          <div className='messageMarketPlace'>
            Insiders get <b>2 free advertisement credits</b> during Devinsider’s
            soft launch. These advertisements will remain visible in the
            marketplace until Devinsider officially launches (July 2023) and the
            marketplace ads will be replaced with new features. You will receive
            a notification before Devinsider officially launches and your
            advertisement(s) will be taken down.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='contentBorder formForEmail text-right'>
          <button
            className='btn btn-devinsider cancel'
            onClick={() => props.handleModal()}
          >
            Cancel
          </button>
          <button
            onClick={() =>
              activeCategory !== -1 &&
              props?.goToPostAds(props?.classifiedAdsOptionList[activeCategory])
            }
            className='btn btn-devinsider px-5 save'
          >
            Next
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
