import React, { useEffect, useState } from "react";
import Header from "../../widget/index";
import Footer from "../../widget/footer/footer";
import "./static-page.scss";
import { getDataDrupal, urlDrupal } from "../../../service/constant";
import ReactHtmlParser from "react-html-parser";

export default function PrivacyPolicy() {
  const [txtPrivacy, setTxtPrivacy] = useState({
    body: "",
    title: "",
  });

  useEffect(() => {
    async function getData() {
      let result = await getDataDrupal(urlDrupal.privacypolicy);

      if (result && result.data) {
        setTxtPrivacy(result.data?.[0]);
      }
    }
    getData();
  }, []);

  return (
    <div>
      <Header />
      <section className="terms-privacy">
        <div className="container mx-auto">
          {txtPrivacy && ReactHtmlParser(txtPrivacy && txtPrivacy.body)}
        </div>
      </section>
      <Footer />
    </div>
  );
}
