import React from 'react';
import { ReactComponent as Loader } from '../../../../resources/images/loader-chargement.svg';
import Header from '../../../widget/header-logo-center/header-logo-center';
import UseAffilliated from '../../../../controller/account-ctr/create-affiliated-account/use-affilliated-ctr';
import { checkIfExist } from '../../../../utils';
import { Redirect } from 'react-router-dom';

export default function index() {
  const { queryParameter } = UseAffilliated();

  if (!checkIfExist(queryParameter.tokenInvitation)) {
    return <Redirect to='/profil-not-found' />;
  }

  return (
    <div>
      <Header />
      <div className='activate-loader'>
        <Loader />
      </div>
    </div>
  );
}
