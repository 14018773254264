import React from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../redux/reducers';
import { INVESTOR, ISV, OEM_PROGRAM } from '../service/constant';
import { Redirect } from 'react-router-dom';
import { isNotNull } from '../utils';
import { checkIfExist } from '../utils';

export default function CompletedAccount(props: any) {
  const currentUser = useSelector((state: rootState) => state.userReducer);

  const isvCompanyPage = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const oemProgram = useSelector((state: rootState) => state.oemProgramReducer);

  const investorPage = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );

  const isIsv = currentUser.roles.includes(ISV);

  const isOem = currentUser.roles.includes(OEM_PROGRAM);

  const isInvestor = currentUser.roles.includes(INVESTOR);

  const isIsvCompletedCompanyIsv =
    currentUser?.isvInCompanyPage?.company_page?.completed &&
    checkIfExist(currentUser?.isvInCompanyPage?.company_page.id);

  const isCompletedOemProgram =
    oemProgram.program?.validate_by_devinsider && oemProgram.program.status;

  const isInvestorCompletedCompanyIsv =
    investorPage.validate_by_devinsider && investorPage.status;

  if (isIsv) {
    if (!isIsvCompletedCompanyIsv) {
      return <Redirect to={'/profil-not-found'} />;
    }
  }

  if (isOem) {
    if (!isCompletedOemProgram) {
      return <Redirect to={'/profil-not-found'} />;
    }
  }

  if (isInvestor) {
    if (!isInvestorCompletedCompanyIsv) {
      return <Redirect to={'/profil-not-found'} />;
    }
  }
  return <div>{props.children}</div>;
}
