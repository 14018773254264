import {
  ClassifiedAdsCategories,
  IClassifiedAds,
} from "../../../../redux/types/classified-ads-interface";
import { addToArray, paginate } from "../../../../utils";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFilters } from "../../../../redux/actions/classified-ads-action";

export type ISubMenu = {
  category?: ClassifiedAdsCategories;
  classifiedAdsReducer: IClassifiedAds;
  selectedType: Array<string>;
  setSelectedType: any;
  selectedLocation: Array<string>;
  setSelectedLocation: any;
  selectedSolution: Array<string>;
  setSelectedSolution: any;
  selectedIndustry: Array<string>;
  setSelectedIndustry: any;
  resetFilter: () => void;
  filter: () => void;
};

export default function ClassifiedAdsSubMenu(props: ISubMenu) {
  const dispatch = useDispatch();

  const {
    category,
    classifiedAdsReducer,
    selectedType,
    selectedLocation,
    selectedSolution,
    selectedIndustry,
    resetFilter,
    filter,
  } = props;

  const setSelectedType = (element: any) =>
    props?.setSelectedType(addToArray(props?.selectedType, element));
  const setSelectedLocation = (element: number) =>
    props?.setSelectedLocation(addToArray(props?.selectedLocation, element));
  const setSelectedSolution = (element: number) =>
    props?.setSelectedSolution(addToArray(props?.selectedSolution, element));
  const setSelectedIndustry = (element: number) =>
    props?.setSelectedIndustry(addToArray(props?.selectedIndustry, element));

  const [limit, setLimit] = useState(6);
  const [limitLocation, setLimitLocation] = useState(6);
  const [limitSolution, setLimitSolution] = useState(6);
  const [limitIndustry, setLimitIndustry] = useState(6);

  /// Initializing classified ad filters
  const cb = (data: any) => {
    if (data?.status === 200) {
    }
  };

  useEffect(() => {
    let data: any = {
      ads_type: "",
      favorite: "0",
      listing: "0",
    };
    dispatch(getFilters(data, cb));
  }, []);

  const seeMoreOrLess = (
    array: Array<any>,
    action: "0" | "1",
    type: string
  ) => {
    switch (type) {
      case "HQ_LOCATION":
        setLimitLocation(action === "0" ? array?.length : 6);
        return;
      case "SOFTWARE_SOLUTION":
        setLimitSolution(action === "0" ? array?.length : 6);
        return;
      case "INDUSTRY":
        setLimitIndustry(action === "0" ? array?.length : 6);
        return;
      default:
        setLimit(action === "0" ? array?.length : 6);
    }
  };

  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  return (
    <div className="filterFindIsv">
      <div className="contentFilter">
        <div className="containerBtn block infoProgramContainer">
          <h2 className="title">
            Marketplace: {category ? category?.type : "Favorites"}
          </h2>
          <button className="btn btn-devinsider cancel" onClick={filter}>
            Filter
          </button>
          <a href="javascript:;" className="resetFilter" onClick={resetFilter}>
            <span className="icon-refresh"></span>
            Reset filter
          </a>
        </div>
        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Company type
          </h3>
          <div className="listCheck">
            {paginate(classifiedAdsReducer?.sub_menu?.company_type, limit)?.map(
              (el: any, key: number) => (
                <div
                  className="custom-control custom-checkbox orange"
                  key={key}
                >
                  <input
                    type="checkbox"
                    id={`type${key}`}
                    className="custom-control-input"
                    checked={
                      selectedType?.find(
                        (selected: any) =>
                          selected === el?.libelle?.toLowerCase()
                      )
                        ? true
                        : false
                    }
                    onChange={() => setSelectedType(el?.libelle?.toLowerCase())}
                  />
                  <label
                    htmlFor={`type${key}`}
                    className="custom-control-label"
                  >
                    {el?.libelle}
                  </label>
                </div>
              )
            )}
            {classifiedAdsReducer?.sub_menu?.company_type?.length > 6 &&
            classifiedAdsReducer?.sub_menu?.company_type?.length === limit ? (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() =>
                  seeMoreOrLess(
                    classifiedAdsReducer?.sub_menu?.company_type,
                    "1",
                    "TYPE"
                  )
                }
              >
                See less
              </a>
            ) : (
              classifiedAdsReducer?.sub_menu?.company_type?.length > 6 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() =>
                    seeMoreOrLess(
                      classifiedAdsReducer?.sub_menu?.company_type,
                      "0",
                      "TYPE"
                    )
                  }
                >
                  See more(
                  {classifiedAdsReducer?.sub_menu?.company_type?.length - limit}
                  )
                </a>
              )
            )}
          </div>
        </div>
        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Headquarters location
          </h3>
          <div className="listCheck">
            {paginate(
              classifiedAdsReducer?.sub_menu?.hq_location,
              limitLocation
            )?.map((el: any, key: number) => (
              <div className="custom-control custom-checkbox orange" key={key}>
                <input
                  type="checkbox"
                  id={`location${key}`}
                  className="custom-control-input"
                  checked={
                    selectedLocation?.find(
                      (selected: any) => selected === el?.country_id
                    )
                      ? true
                      : false
                  }
                  onChange={() => setSelectedLocation(el?.country_id)}
                />
                <label
                  htmlFor={`location${key}`}
                  className="custom-control-label"
                >
                  {el?.country_name}
                </label>
              </div>
            ))}
            {classifiedAdsReducer?.sub_menu?.hq_location?.length > 6 &&
            classifiedAdsReducer?.sub_menu?.hq_location?.length ===
              limitLocation ? (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() =>
                  seeMoreOrLess(
                    classifiedAdsReducer?.sub_menu?.hq_location,
                    "1",
                    "HQ_LOCATION"
                  )
                }
              >
                See less
              </a>
            ) : (
              classifiedAdsReducer?.sub_menu?.hq_location?.length > 6 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() =>
                    seeMoreOrLess(
                      classifiedAdsReducer?.sub_menu?.hq_location,
                      "0",
                      "HQ_LOCATION"
                    )
                  }
                >
                  See more(
                  {classifiedAdsReducer?.sub_menu?.hq_location?.length - limit})
                </a>
              )
            )}
          </div>
        </div>
        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Software solution
          </h3>
          <div className="listCheck">
            {paginate(
              classifiedAdsReducer?.sub_menu?.software_solution,
              limitSolution
            )?.map((el: any, key: number) => (
              <div className="custom-control custom-checkbox orange" key={key}>
                <input
                  type="checkbox"
                  id={`sol${key}`}
                  className="custom-control-input"
                  checked={
                    selectedSolution?.find(
                      (selected: any) => selected === el?.id
                    )
                      ? true
                      : false
                  }
                  onChange={() => setSelectedSolution(el?.id)}
                />
                <label htmlFor={`sol${key}`} className="custom-control-label">
                  {el?.name}
                </label>
              </div>
            ))}
            {classifiedAdsReducer?.sub_menu?.software_solution?.length > 6 &&
            classifiedAdsReducer?.sub_menu?.software_solution?.length ===
              limitSolution ? (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() =>
                  seeMoreOrLess(
                    classifiedAdsReducer?.sub_menu?.software_solution,
                    "1",
                    "SOFTWARE_SOLUTION"
                  )
                }
              >
                See less
              </a>
            ) : (
              classifiedAdsReducer?.sub_menu?.software_solution?.length > 6 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() =>
                    seeMoreOrLess(
                      classifiedAdsReducer?.sub_menu?.software_solution,
                      "0",
                      "SOFTWARE_SOLUTION"
                    )
                  }
                >
                  See more(
                  {classifiedAdsReducer?.sub_menu?.software_solution?.length -
                    limit}
                  )
                </a>
              )
            )}
          </div>
        </div>
        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Targeted industry
          </h3>
          <div className="listCheck">
            {paginate(
              classifiedAdsReducer?.sub_menu?.targeted_industry,
              limitIndustry
            )?.map((el: any, key: number) => (
              <div className="custom-control custom-checkbox orange" key={key}>
                <input
                  type="checkbox"
                  id={`targ${key}`}
                  className="custom-control-input"
                  checked={
                    selectedIndustry?.find(
                      (selected: any) => selected === el?.id
                    )
                      ? true
                      : false
                  }
                  onChange={() => setSelectedIndustry(el?.id)}
                />
                <label htmlFor={`targ${key}`} className="custom-control-label">
                  {el?.name}
                </label>
              </div>
            ))}
            {classifiedAdsReducer?.sub_menu?.targeted_industry?.length > 6 &&
            classifiedAdsReducer?.sub_menu?.targeted_industry?.length ===
              limitIndustry ? (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() =>
                  seeMoreOrLess(
                    classifiedAdsReducer?.sub_menu?.targeted_industry,
                    "1",
                    "INDUSTRY"
                  )
                }
              >
                See less
              </a>
            ) : (
              classifiedAdsReducer?.sub_menu?.targeted_industry?.length > 6 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() =>
                    seeMoreOrLess(
                      classifiedAdsReducer?.sub_menu?.targeted_industry,
                      "0",
                      "INDUSTRY"
                    )
                  }
                >
                  See more(
                  {classifiedAdsReducer?.sub_menu?.targeted_industry?.length -
                    limit}
                  )
                </a>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
