import { split, join } from "lodash";
import React from "react";
import getDiscussionAboutCommunity from "../../../../../controller/community-ctr/home-page/discussion-about-community";
const AboutTheCommunity = () => {
  const aboutCommunity = { ...getDiscussionAboutCommunity() };
  const renderLastActivity = (last_activity: string) => {
    const last_splitted = split(last_activity, " ");
    if (parseInt(last_splitted[0]) > 1) last_splitted[1] += "s";
    return join(last_splitted, " ");
  };
  return (
    <>
      <h2 className="title">About the Community</h2>
      <ul className="listBlockright categDiscussion">
        <li className="title">
          <div className="itemBlock">Subject</div>
          <div className="itemBlock">Contributions</div>
          <div className="itemBlock">Last activity</div>
        </li>
        {aboutCommunity?.discussionAboutCommunity?.map(
          (discussion: any, index: number) => (
            <li
              key={index}
              onClick={() =>
                aboutCommunity?.showAllAboutCommunity(discussion?.id)
              }
            >
              <div className="itemBlock txtLabel">
                <h3 className="title">{discussion.title}</h3>
              </div>
              <div className="itemBlock contributionItem">
                {discussion.number_of_activity}
              </div>
              <div className="itemBlock">
                <span className="time">
                  {renderLastActivity(discussion.last_activity)}
                </span>
              </div>
            </li>
          )
        )}
      </ul>
    </>
  );
};

export default AboutTheCommunity;
