import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../redux/reducers';
import {
  getInfoNotification,
  updateNotification,
} from '../../redux/actions/settings-action/index';
import {
  updateInfoUserAction,
  updateEmailUser,
  logout,
} from '../../redux/actions/acounts-action/index';
import store from '../../redux/store';
import {
  updatePassword,
  sendDigitCode,
  checkPasswordUser,
} from '../../service/applicatif/acount';
import {
  checkIfEmailExist,
  clauseAccountService,
} from '../../service/applicatif/acount';
import { isRequired, isEmail } from '../../utils/index';
import { INVESTOR, ISV, OEM_PROGRAM, url } from '../../service/constant';
import { toast } from 'react-toastify';

export default function useSettingsisv() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const userReducer = useSelector((state: rootState) => state.userReducer);

  const userIsv = userReducer.roles.indexOf(ISV) !== -1;
  const userOem = userReducer.roles.indexOf(OEM_PROGRAM) !== -1;
  const [passWord, setPassWord] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [primaryMail, setPrimaryMail] = useState<string>(userReducer.email);
  const [workedEmail, setWorkedEmail] = useState<string>(
    userReducer.primary_email
  );
  const [succedUpdate, setSuccedUpdate] = useState<string>('');
  const [isEmailUpdated, setIsEmailUpdated] = useState<string>('');
  const [passWordToVerifie, setPassWordToVerifie] = useState<string>('');
  const [digitCode, setDigitCode] = useState<string>('');
  const [editEmail, setEditEmail] = useState('');
  const [emailSent, setEmailSent] = useState<boolean>(true);
  const [isLoadingRadio, setisLoadingRadio] = useState('');
  const [showErrorOldPassword, setShowErrorOldPassword] = useState(false);
  const [emailNotSafe, setEmailNotSafe] = useState<string>('');
  const [emailTxtError, setTxtEmailError] = useState<string>('Loading...');
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const [isShowLoaderUpdateEmail, setIsShowLoaderUpdateEmail] =
    useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const settingsNotification = useSelector(
    (state: rootState) => state.settingsReducer
  );
  const [isShowFormClauseAccount, setIsShowFormClauseAccount] =
    useState<boolean>(false);

  const [raisonClauseAcount, setRaisonClauseAcount] = useState<string>('');
  const [feedBackClauseAccount, setFeedBackClauseAccount] =
    useState<string>('');

  const [loaderClauseAcount, setLoaderClauseAcount] = useState<boolean>(false);
  const [showModalConfirmClauseAccount, setShowModalConfirmClauseAccount] =
    useState<boolean>(false);

  const [modalWithoutCompany, setShowModalWithoutCompany] =
    useState<boolean>(false);

  const currentOemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer
  );

  const companyPageReducer = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const [tabToShow, setTabToShow] = useState(
    location?.state?.tabToShow || 'd-account'
  );

  const [showIdenticalPassWordsError, setShowIdenticalPassWordsError] =
    useState<boolean>(false);

  console.log('location?.state', location?.state);

  const callback = (data: any) => {
    setisLoadingRadio('');
  };

  useEffect(() => {
    dispatch(getInfoNotification(callback));
    return () => {};
  }, []);

  const editInfoNotification = (data: any, key: string) => {
    setisLoadingRadio(key);
    const sameUpdateKey =
      key === 'community_email_notifications_by_insider_i_follow' ||
      key === 'community_email_notifications_categories_labels_i_follow'
        ? true
        : false;

    const customParams =
      sameUpdateKey === true
        ? {
            community_email_notifications_by_insider_i_follow: data,
            community_email_notifications_categories_labels_i_follow: data,
          }
        : { [key]: data };
    let params = {
      ...settingsNotification,
      ...customParams,
    };
    dispatch(updateNotification(callback, params));
  };

  const cb = (resposeStatus: boolean) => {
    if (resposeStatus) {
    } else {
      //error
    }
  };

  const updateUserDetail = (key: string) => {
    let data = {
      userCommunityProfil: key?.toString(),
    };
    dispatch(updateInfoUserAction(data, cb));
  };

  const editProfil = () => {
    history.push('/profil/edit');
  };

  const navigateToCompanyPage = (type = '') => {
    const haveCompany = userReducer?.isvInCompanyPage;
    const params =
      type === 'edit'
        ? {
            editProfil: true,
          }
        : {
            showModal: true,
          };
    if (haveCompany) {
      history.push({
        pathname: '/company/profil',
        state: params,
      });
    } else {
      let role = store?.getState()?.userReducer?.roles[0];
      switch (role) {
        case INVESTOR:
          history.push('/profil/investor');
          break;
        case OEM_PROGRAM:
          history.push('/OEM/profil/' + currentOemProgram?.program?.id);
          break;
        default:
          history.push('/company/create');
      }
    }
  };

  const updatePasswordInfo = async () => {
    (async () => {
      try {
        let response = await checkPasswordUser(
          url.acount.check_user_password,
          {
            password: passWord,
          },
          userReducer?.token
        );
        if (response?.status === 200) {
          let data = {
            old_password: passWord,
            new_password: newPassword,
          };

          try {
            if (newPassword === passWord) {
              setShowIdenticalPassWordsError(true);
            } else {
              if (newPassword === confirmNewPassword) {
                let response = await updatePassword(
                  data,
                  store.getState().userReducer?.token
                );
                if (response && response?.status === 200) {
                  setSuccedUpdate('ok');
                  setPassWord('');
                  setNewPassword('');
                  setConfirmNewPassword('');
                  toast('Password updated successfully!');
                  dispatch(logout(() => history.push('/signin')));
                }
              }
            }
          } catch (error) {
            setSuccedUpdate('error');
            setPassWord('');
            setNewPassword('');
            setConfirmNewPassword('');
          }
          setIsLoaderShow(false);
        } else {
          setIsLoaderShow(false);
          setShowErrorOldPassword(true);
        }
      } catch (error: any) {}
    })();
  };

  const removeTxtAfterUpdate = () => {
    if (succedUpdate !== '') {
      setSuccedUpdate('');
    }
  };

  const confirmSendDigit = async () => {
    setIsShowLoaderUpdateEmail(true);
    setIsEmailUpdated('');
    setTxtEmailError('Loading...');
    setEmailNotSafe('show-txt');

    let data = {
      type: editEmail === 'primary' ? 'primary-email' : 'work-email',
      email: editEmail === 'primary' ? primaryMail : workedEmail,
    };
    let checkIfEmailUse = await checkIfEmailExist(
      editEmail === 'primary' ? primaryMail : workedEmail,
      editEmail === 'primary' ? true : false
    );

    if (checkIfEmailUse?.status === 200 && checkIfEmailUse?.data?.details) {
      if (
        checkIfEmailUse?.status === 200 &&
        checkIfEmailUse?.data?.reason === 'invalid domain'
      ) {
        setTxtEmailError('Professional email is required');
      } else {
        setTxtEmailError('This email address is already  used');
      }
      setIsShowLoaderUpdateEmail(false);
      return;
    } else {
      let response = await sendDigitCode(
        data,
        store.getState().userReducer.token
      );
      if (response && response.status == 200) {
        setIsShowLoaderUpdateEmail(false);
        setEmailSent(false);
        setEmailNotSafe('show-form');
      }
    }
    return;
  };

  //Valid Email exist or with a format valid
  const sentEmailToVerifieDigitCode = async () => {
    if (editEmail === 'primary' && validEmail(primaryMail)) {
      confirmSendDigit();
    } else if (editEmail === 'work' && validEmail(workedEmail)) {
      confirmSendDigit();
    } else if (editEmail === 'primary' && !isRequired(primaryMail)) {
      setErrorEmail(true);
      setTxtEmailError('This field is required');
    } else if (editEmail === 'primary' && !validEmail(primaryMail)) {
      setErrorEmail(true);
      setTxtEmailError('your email address is not valid');
    } else if (editEmail === 'work' && !isRequired(workedEmail)) {
      setErrorEmail(true);
      setTxtEmailError('This field is required');
    } else if (editEmail === 'work' && !validEmail(workedEmail)) {
      setErrorEmail(true);
      setTxtEmailError('your email address is not valid');
    }
  };

  const cbLogout = (params: boolean) => {
    history.push('/closing-account', {
      clausing: true,
    });
  };

  const cbLogoutUpdateMail = (params: boolean) => {
    history.push('/signin');
  };

  const cbEmail = (params: any) => {
    setIsShowLoaderUpdateEmail(false);
    setDigitCode('');
    setPassWordToVerifie('');
    setEmailNotSafe('show-txt');
    setTxtEmailError('');
    if (params) {
      setIsEmailUpdated('ok');
      // if (editEmail === 'primary') {
      dispatch(logout(cbLogoutUpdateMail));
      // }
    }
    if (!params) {
      setIsEmailUpdated('error');
      setIsShowLoaderUpdateEmail(false);
    }
  };

  const updateEmail = async () => {
    let data = {
      email: editEmail === 'primary' ? primaryMail : workedEmail,
      token: digitCode,
      password: passWordToVerifie,
    };

    setIsShowLoaderUpdateEmail(true);
    dispatch(updateEmailUser(data, cbEmail));
  };

  useEffect(() => {
    setEmailNotSafe('');
    setErrorEmail(false);
    setPrimaryMail(userReducer?.email);
    setWorkedEmail(userReducer?.primary_email);
    setIsEmailUpdated('');

    return () => {};
  }, [editEmail]);

  useEffect(() => {
    if (isEmailUpdated === 'ok' || isEmailUpdated === 'error')
      return () => {
        setIsEmailUpdated('');
      };
  }, [passWordToVerifie, digitCode]);

  /*
   * Valid email To update
   * params string
   * return boolean
   */
  const validEmail = (email: string): boolean => {
    if (isEmail(email) && isRequired(email)) {
      return true;
    } else {
      setErrorEmail(true);
      return false;
    }
  };

  const clauseAccount = async () => {
    setShowModalConfirmClauseAccount(!showModalConfirmClauseAccount);
  };

  const validClauseAccount = async () => {
    let data = {
      reason: raisonClauseAcount,
      feedback: feedBackClauseAccount,
    };
    setLoaderClauseAcount(true);
    let response = await clauseAccountService(
      data,
      store.getState().userReducer.token
    );
    setLoaderClauseAcount(false);
    if (response && response?.status === 200) {
      dispatch(logout(cbLogout));
    }
  };

  const manageCompany = () => {
    setShowModalConfirmClauseAccount(!showModalConfirmClauseAccount);

    if (userIsv) {
      const haveCompany =
        companyPageReducer.id !== '0' && companyPageReducer.id !== '';
      if (haveCompany) {
        history.push('/company/profil');
      } else {
        setShowModalWithoutCompany(true);
      }
    }
  };

  return {
    userReducer,
    passWord,
    newPassword,
    confirmNewPassword,
    primaryMail,
    succedUpdate,
    isLoaderShow,
    workedEmail,
    passWordToVerifie,
    isEmailUpdated,
    isLoadingRadio,
    emailNotSafe,
    emailTxtError,
    isShowLoaderUpdateEmail,
    emailSent,
    editEmail,
    digitCode,
    settingsNotification,
    errorEmail,
    isShowFormClauseAccount,
    feedBackClauseAccount,
    raisonClauseAcount,
    loaderClauseAcount,
    clauseAccount,
    setRaisonClauseAcount,
    setFeedBackClauseAccount,
    editProfil,
    editInfoNotification,
    updateUserDetail,
    setPassWord,
    setNewPassword,
    setConfirmNewPassword,
    updatePasswordInfo,
    setSuccedUpdate,
    removeTxtAfterUpdate,
    setPrimaryMail,
    setWorkedEmail,
    setPassWordToVerifie,
    setDigitCode,
    setEditEmail,
    sentEmailToVerifieDigitCode,
    updateEmail,
    setIsShowFormClauseAccount,
    navigateToCompanyPage,
    manageCompany,
    //is the current admin of the current oem program page
    roleUserIncurrentProgram: () => {
      if (userReducer?.roles?.indexOf(OEM_PROGRAM) !== -1) {
        let roleInCurrentProgram = userReducer?.oemProgramMembers?.filter(
          (role: any) => role?.oem_program?.id === currentOemProgram.program.id
        )[0];
        return roleInCurrentProgram?.role;
      }
      return '';
    },
    tabToShow,
    setTabToShow,
    showModalConfirmClauseAccount,
    setShowModalConfirmClauseAccount,
    modalWithoutCompany,
    setShowModalWithoutCompany,
    validClauseAccount,
    showErrorOldPassword,
    setShowErrorOldPassword,
    setIsLoaderShow,
    showIdenticalPassWordsError,
    setShowIdenticalPassWordsError,
  };
}
