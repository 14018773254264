import React, { useState, useEffect } from 'react';
import useSF from '../../../screen/widget/software-solution/use-SF';
import * as T from '../../../screen/widget/software-solution-with-details/type';
import { rootState } from '../../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInvestorAttributes,
  updateCPInvestorAction,
} from '../../../redux/actions/company-page-investor-action/my-investor-page-action';
import { useParams } from 'react-router';

export default function UseEditInfoSolutionIndustrieCtr() {
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;

  const CP_Investor = useSelector((state: rootState) =>
    !isOtherUser
      ? state.companyInvestorPageReducer
      : state?.otherCompanyInvestorPageReducer
  );
  const [showIndustry, setShowIndustry] = useState<boolean>(false);
  const [valuesolutionChecked, setValuesolutionChecked] = useState<
    Array<{ id: number; name: string }>
  >([]);
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [index1, setindex1] = useState<number>(6);
  const [valueFeaturedsolutionChecked, setValueFeaturedsolutionChecked] =
    useState<Array<{ id: number; name: string }>>([]);

  useEffect(() => {
    setValuesolutionChecked(CP_Investor?.segments);
    setValueFeaturedsolutionChecked(CP_Investor?.segment_details);
    return () => {};
  }, [CP_Investor?.segments, CP_Investor?.segment_details, params?.id]);

  const {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    backToDefault,
  } = useSF({
    defaultValue: valuesolutionChecked,
    defaultValueFeatured: valueFeaturedsolutionChecked,
  });

  const cbGetCP_Investor = () => {};

  const cb = (data: any) => {
    setLoader(false);
    setShowIndustry(false);
    dispatch(getInvestorAttributes('segments', 'segments', () => {}));
    dispatch(
      getInvestorAttributes('segment_details', 'segmentDetails', () => {})
    );
  };

  const saveSolutionChecked = () => {
    setLoader(true);
    let dataToSend = {
      segmentDetails: JSON.stringify(
        solutionCheckedFeatures.map((el: any) => el?.id)
      ),
      segments: JSON.stringify(solutionChecked?.map((el: any) => el?.id)),
    };
    dispatch(updateCPInvestorAction(CP_Investor?.id, dataToSend, cb));
  };

  const cancelModal = () => {
    backToDefault();
    setShowIndustry(false);
  };

  return {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    showIndustry,
    setShowIndustry,
    saveSolutionChecked,
    loader,
    index1,
    setindex1,
    cancelModal,
  };
}
