import React from "react";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling-white.svg";
import moment from "moment";
import { Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import { formatAMPM } from "../../../../../utils";
import { Link } from "react-router-dom";
//Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
import UseUnpublishProgram from "../../../../../controller/oem-ctr/block-component/use-unpublish-program-ctr";
import { BtnNext } from "../../../../widget/bouton/btn-next";
import warning from "../../../../../resources/images/warning.svg";

export type IPropsOemControle = {
  switchEditMode: () => void;
  showPreview: () => void;
  setShowPageSettings: () => void;
  setShowManagePage: () => void;
  editMode: boolean;
  oemProgram: any;
  loader: boolean;
  txtBtn: string;
  publishOrUpdate: () => void;
  isOtherUser: boolean;
  matching_value: number | string;
  goBackMatchingPanel: () => void;
  addFavorite: () => void;
  isFavorite: boolean;
  contactOem: () => void;
  isPreview: boolean;
  isOnEditWysiwyg: boolean;
  showBtnManage: boolean;
  showBtnEditPage: boolean;
  showBtnPublish: boolean;
};
export default function oemManaging(props: IPropsOemControle) {
  const unpublishProgramCtr = UseUnpublishProgram();
  //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page

  const closeAndShowPopup = () => {
    unpublishProgramCtr.setShowBtnUnpublish(false);
    unpublishProgramCtr.setShowPopupUnpublish(true);
  };

  const gearUnpublish = () => {
    unpublishProgramCtr.setShowBtnUnpublish(true)
    unpublishProgramCtr.setGearClick(unpublishProgramCtr.gearClick+1)
  }

  const cancelPopUp = () => {
    unpublishProgramCtr.setShowPopupUnpublish(false)
    unpublishProgramCtr.setGearClick(unpublishProgramCtr.gearClick+1)
  }

  const isEditMode = props?.editMode;
  const isNotEditMode =
    !isEditMode && !props?.oemProgram?.validate_by_devinsider;

  const isPublished = !isEditMode && props?.oemProgram?.validate_by_devinsider;
  //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
  const renderStatus = () => {
    if (props?.editMode) return <b>Draft</b>;
    else {
      if (
        props?.oemProgram?.status === true &&
        props?.oemProgram?.validate_by_devinsider === true
      )
        return <b>Published</b>;
      return <b>Draft (unpublished)</b>;
    }
  };

  const previewOtherUser = props?.isOtherUser && !props?.isPreview;

  const showManaging = !props?.isOtherUser && !props?.isPreview;

  return (
    <>
      {previewOtherUser && (
        <div className="cont oem">
          <div
            className={`matchValue ${
              props.matching_value < 60 ? "m60" : "m90"
            }`}
          >
            {props.matching_value < 60 && (
              <>
                <span className="icon-nextbtn" /> 60 % Match
              </>
            )}
            {props.matching_value > 60 && props.matching_value + "% Match"}
          </div>

          <div className="contact">
            {props.matching_value > 60 && (
              <Link
                onClick={() => {
                  props.contactOem();
                }}
                to="#"
                className="btn btn-devinsider px-5 save"
              >
                Contact
              </Link>
            )}
          </div>

          <span className="separator justForProfil"></span>
          <button
            className="btnSetting justForProfil"
            onClick={() => props?.addFavorite()}
          >
            <span className={"icon-star-on star"} /> Favorites
          </button>
          <button
            className="btnSetting justForProfil"
            onClick={() => props?.goBackMatchingPanel()}
          >
            Back to the Results panel
          </button>
        </div>
      )}

      {props?.isPreview && !props?.isOtherUser && (
        <div className="cont oem">
          <div className={`matchValue m90`}>92 % Match</div>

          <div className="contact">
            <Link
              onClick={(e) => {
                e.preventDefault();
              }}
              to="#"
              className="btn btn-devinsider px-5 save"
            >
              Contact
            </Link>
          </div>

          <span className="separator justForProfil"></span>
          <button className="btnSetting justForProfil">
            <span
              className={
                props?.isFavorite ? "icon-star-on star" : "icon-star-off star"
              }
            />{" "}
            Favorites
          </button>
          <button className="btnSetting justForProfil">
            Back to the Results panel
          </button>
        </div>
      )}
      {showManaging && (
        <>
          <div className="editShow ">
            {!props.isOnEditWysiwyg && (
              <button
                className="btnforEdit doneEdit"
                onClick={props?.switchEditMode}
              >
                Done editing
              </button>
            )}

            <button className="btnforEdit preview" onClick={props?.showPreview}>
              Preview
            </button>
          </div>
          {/* Dev #46417 V2.1 OEM - Désactivation membre d'un program */}
          {props?.oemProgram?.uuid === "" ? (
            <></>
          ) : (
            <>
              <button
                className="btnSetting justForProfil"
                onClick={() => props?.setShowPageSettings()}
              >
                Program targeting
              </button>
            </>
          )}
          {/* Dev #46417 V2.1 OEM - Désactivation membre d'un program */}
          {props?.oemProgram?.uuid !== "" && props?.showBtnManage ? (
            <>
              <button
                className="btnSetting justForProfil"
                onClick={() => props?.setShowManagePage()}
              >
                Manage program
              </button>
            </>
          ) : (
            <></>
          )}
          <span className="separator justForProfil"></span>
          {props?.oemProgram?.uuid !== "" && props?.showBtnEditPage ? (
            <>
              <button
                className="btnSetting justForProfil"
                onClick={props?.switchEditMode}
              >
                {/* Dev #46417 V2.1 OEM - Désactivation membre d'un program */}
                {props?.oemProgram?.uuid === "" ? "" : "Edit page"}
              </button>
            </>
          ) : (
            <></>
          )}
          <div className="infoPublication position-relative">
            <div className="infoItem">
              <span className="icon-mini-key"></span>
              <label>Status: </label>
              {renderStatus()}
              {
                //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
                props?.oemProgram?.validate_by_devinsider === true &&
                  props?.oemProgram?.status === true &&
                  props?.editMode === false &&
                  props?.oemProgram?.uuid !== "" && (
                    <span
                      className="icon-settings ml-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => gearUnpublish()}
                    ></span>
                  )
              }
            </div>
            <div className="infoItem">
              <span className="icon-calendar"></span>
              <label>Last update: </label>
              {unpublishProgramCtr.showBtnUnpublish && props?.showBtnPublish &&
                unpublishProgramCtr.gearClick % 2 === 0 && (
                  <div
                    className="btnUnpublish"
                    onClick={() => closeAndShowPopup()}
                  >
                    Unpublish
                  </div>
                )}
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id="tooltip-disabled">
                    <b>
                      {props?.oemProgram?.updated_at &&
                        moment(new Date(props?.oemProgram?.updated_at)).format(
                          "MMM D, YYYY h:mm A"
                        )}
                    </b>
                  </Tooltip>
                }
                defaultShow={false}
              >
                <b>
                  {props?.oemProgram?.updated_at &&
                    moment(new Date(props?.oemProgram?.updated_at)).format(
                      "MMM D, YYYY [at] h:mm A"
                    )}{" "}
                </b>
              </OverlayTrigger>
            </div>
          </div>

          <div className="cttbtnUpdate justForProfil">
            {
              //Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
              props?.oemProgram?.uuid === "" ? (
                "Program deactivated"
              ) : (
                <>
                  {props?.showBtnPublish && <button
                    onClick={() => props?.publishOrUpdate()}
                    disabled={props?.loader}
                  >
                    {props?.loader ? <Loader /> : props?.txtBtn}{" "}
                  </button>}
                </>
              )
            }
          </div>
        </>
      )}

      {/* MODAL CONFIRMATION FOR UNPUBLISHING PROGRAM */}
      {/* //Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page */}
      <Modal
        show={unpublishProgramCtr.showPopupUnpublish}
        className="createCompanypopup large forStep5custom forUnpublish"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Unpublish</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => cancelPopUp()}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="contentBorder">
            <img src={warning} alt="" />
            <p className="mb-5 mt-5 text-center">
              Are you sure you want to unpublish your page?{" "}
            </p>
            <p>
              When you unpublish your page, the status will be changed to draft
              and your page will be removed from the matchmaking results. <br />
              You can always re-publish your page at a later point.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => cancelPopUp()}
            >
              Cancel
            </button>
            <button
              className="btn btn-devinsider px-5 save"
              onClick={() => unpublishProgramCtr.handleUnpublish()}
            >
              <BtnNext
                text="Confirm"
                showLoader={unpublishProgramCtr.processing}
              />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
