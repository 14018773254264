import AllNotification from '../screen/container/notification/seeAllNotification';

export const routesNotifications = [
    {
        component : AllNotification,
        exact : true,
        path : "/notifications",
        guard : "public",
    },
]
