import React, { useState, useEffect } from "react";
import { EditorState } from "draft-js";
import { updateDraftDataAction } from "../../../redux/actions/company-page-investor-action/my-investor-page-action";
import { rootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { customDataStore, getSStorage } from "../../../utils";
import { useParams } from "react-router-dom";
export default function useEditFundingRoundCtr() {
  const [loader, setLoader] = useState<boolean>(false);
  const [itemsClick, setItemsClick] = useState<number>(0);
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;

  const CP_Investor = useSelector((state: rootState) =>
    !isOtherUser
      ? state.companyInvestorPageReducer
      : state?.otherCompanyInvestorPageReducer
  );
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  let CP_DRAFT_DATA = JSON.parse(CP_Investor.investor_page_draft || "{}");
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const dispatch = useDispatch();
  const defaultValueIfNull = {
    id: 5,
    name: "I am not looking for funding",
  };

  const [investorfundingRound, setInvestorFundingRound] = useState(
    CP_DRAFT_DATA?.investor_funding_round
      ? CP_DRAFT_DATA?.investor_funding_round
      : CP_Investor?.investor_funding_round
  );

  const cbUpdateDraft = (data: any) => {
    setLoader(false);
    setIsShowModal(false);
  };

  useEffect(() => {
    setInvestorFundingRound(
      CP_DRAFT_DATA?.investor_funding_round
        ? CP_DRAFT_DATA?.investor_funding_round
        : CP_Investor?.investor_funding_round
    );
    return () => {};
  }, [CP_Investor?.investor_funding_round, params?.id]);

  const saveClickFunding = (element: number) => {
    let dataSelected = dataCompleted?.fundingRound?.find(
      (el: { id: number; name: string }) => el?.id == element
    );
    let tempValue = customDataStore(investorfundingRound, dataSelected);
    setInvestorFundingRound(tempValue);
  };

  const closeEditFunding = () => {
    setIsShowModal(false);
    setLoader(false);
    setInvestorFundingRound(
      CP_DRAFT_DATA?.investor_funding_round
        ? CP_DRAFT_DATA?.investor_funding_round
        : CP_Investor?.investor_funding_round
    );
  };

  const showModalEdit = () => {
    setIsShowModal(true);
  };

  const saveFundingRound = () => {
    setLoader(true);
    let dataToSend = {
      ...JSON.parse(CP_Investor?.investor_page_draft || "{}"),
      investor_funding_round:
        investorfundingRound?.length <= 0
          ? [defaultValueIfNull]
          : investorfundingRound,
    };
    dispatch(
      updateDraftDataAction(
        { investorPageDraft: JSON.stringify(dataToSend) },
        CP_Investor.id,
        cbUpdateDraft
      )
    );
  };

  return {
    saveClickFunding,
    loader,
    itemsClick,
    isShowModal,
    closeEditFunding,
    showModalEdit,
    saveFundingRound,
    investorfundingRound,
  };
}
