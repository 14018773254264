import { useEffect, useReducer, useState } from "react";
import {
  APPEND_MY_FEED,
  getMyFeedWithOffset,
  getMyFeedWithoutOffset,
  markAsFavoriteAction,
  likePost,
  SET_MY_FEED,
  SET_MY_FEED_OFFSET,
  ADD_FAVORY,
  LIKE_POST,
} from "../../../redux/actions/isv-action";
import { randomChoice } from "../../../utils";
import { IAction } from "../../../redux/types/type-action";
import { IsvComponentReducer } from "../../../redux/types/isv-interface";
import { useHistory } from "react-router";

const initialState: IsvComponentReducer = {
  myfeed: {
    results: [],
    total_result_to_paginate: 0,
    currentOffset: 0,
  },
};

const helperLiked = (items: any, idParent: number, nbrLike: number) => {
  const tempfeeds = items?.map((el: any) => {
    const found = el?.feeds?.find(
      (i: any) => i?.id?.toString() === idParent?.toString()
    );
    if (found) {
      return {
        ...el,
        feeds: el?.feeds?.map((f: any) => {
          if (f.id?.toString() === idParent?.toString()) {
            return {
              ...f,
              numberOfLikes: nbrLike,
              liked: !f?.liked,
            };
          } else {
            return f;
          }
        }),
      };
    } else {
      return el;
    }
  });

  return tempfeeds;
};

const reducer = (
  state: IsvComponentReducer,
  action: IAction
): IsvComponentReducer => {
  switch (action.type) {
    case SET_MY_FEED:
      return {
        ...state,
        myfeed: {
          ...state.myfeed,
          results: [action.payload.feeds],
          total_result_to_paginate: action.payload.count,
        },
      };
    case SET_MY_FEED_OFFSET:
      return {
        ...state,
        myfeed: { ...state.myfeed, currentOffset: action.payload },
      };

    case LIKE_POST: {
      return {
        ...state,
        myfeed: {
          ...state.myfeed,
          results: helperLiked(
            state.myfeed.results,
            action.payload?.idPost,
            action.payload?.numberLike
          ),
        },
      };
    }

    case APPEND_MY_FEED:
      const results = [...state.myfeed.results];
      results.push(action.payload);

      return {
        ...state,
        myfeed: {
          ...state.myfeed,
          results,
        },
      };

    default:
      return state;
  }
};

export default function useDashboardMyFeed() {
  const [myFeedList, dispatch] = useReducer<
    (state: IsvComponentReducer, action: IAction) => IsvComponentReducer
  >(reducer, initialState);

  const [hasMore, setHasMore] = useState<boolean>(true);
  const history = useHistory();

  const callback = () => {
    setHasMore(false);
  };

  const loadMoreFeed = async () => {
    let random = randomChoice([3, 4, 5]);

    await getMyFeedWithOffset(
      random,
      dispatch,
      myFeedList.myfeed.currentOffset,
      callback
    );
  };

  useEffect(() => {
    (async () => {
      await getMyFeedWithoutOffset(dispatch, callback);
    })();
  }, []);

  const goToManageMyFeed = () => {
    history.push("/manage-feed");
  };

  const cbLiked = (params: any) => {};

  const handleLiked = async (idParent: string) => {
    await likePost(idParent, cbLiked, dispatch);
  };

  return {
    myFeedList,
    loadMoreFeed,
    hasMore,
    goToManageMyFeed,
    handleLiked,
  };
}
