/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IPropsIndustrySpecialization } from "../types";

export default function InfoIndustrySpecialization(
  props: IPropsIndustrySpecialization
) {
  const lengthSpecialisation = props?.specialisationList?.length;

  return (
    <>
      <ul className="investorInfoList moreInfo">
        <li>
          <a
            className="linkEditLeftCompany"
            onClick={() => props?.editIndustrySpecialization()}
          >
            Edit<span className="icon-edit-full"></span>
          </a>
          <label>Industry specialization</label>
          <div className="listOf">
            {/* {props?.specialisationList?.map(
              (el: { id: number; name: string }, index: number) => (
                <p key={index}>{el?.name}</p>
              )
            )}
            <a href="javascript" className="seeall">
              See more (4)
            </a> */}

            {props?.specialisationList
              ?.filter((el: any, index: number) => {
                return index < props?.index2;
              })
              ?.map((element: any, index: number) => (
                <p key={index}>{element?.name}</p>
              ))}

            {lengthSpecialisation > 6 && (
              <a
                className="seeall"
                onClick={(evt) => {
                  evt.preventDefault();
                  props?.setIndex2(
                    props?.index2 === lengthSpecialisation
                      ? 6
                      : lengthSpecialisation
                  );
                }}
              >
                See {props?.index2 === lengthSpecialisation ? "less" : "more"}
                {props?.index2 === lengthSpecialisation
                  ? ""
                  : `(${lengthSpecialisation - props?.index2})`}
              </a>
            )}
          </div>
        </li>
      </ul>
    </>
  );
}
