import React, { useState, useEffect } from "react";
import useSliderCS from "../slider-company-size-slider/use-slider-company-size-slider";
import { rootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import useSliderAT from "../slider-annual-turnover/use-slider-annual-turnover";
import usePays from "../pays/use-Pays";
import useEditModalSF from "../software-solution/use-SF";
import useIL from "../industries-list/use-IL";
import {
  getOemAttributte,
  updateOemPageSettings,
} from "../../../redux/actions/oem-program-action";
import { oem_roles } from "../../../utils";
import UsePersmission from "../permission-oem/use-persmission";
import { ISV, OEM_PROGRAM } from "../../../service/constant";

export default function UseEditSettingsCtr() {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const oemProgram: any = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const { handLePersmission, showPermission } = UsePersmission();

  const userReducer = useSelector((state: rootState) => state.userReducer);

  const [defaultValueCompanySize, setDefaultValueCompanySize] = useState<
    Array<number>
  >([]);

  const [defaultValueAnnualTurnover, setDefaultValueAnnualTurnover] = useState<
    Array<any>
  >([]);

  const [valuePays, setDefaultValuePays] = useState<
    Array<{ idContinent: string; countrId: string; countryName: string }>
  >([{ idContinent: "", countrId: "", countryName: "" }]);

  const [valueFeaturedsolutionChecked, setValueFeaturedsolutionChecked] =
    useState<Array<{ id: number; name: string }>>([]);
  const [keyUpdate, setKeyUpdate] = useState<string>("");
  const [valueIL, setValueIL] = useState<
    Array<{
      id: number;
      name: string;
    }>
  >([]);

  const setShowPageSettings = () => {
    setIsShowModal(true);
  };

  //company size
  const {
    companySizeSlide,
    setCompanySizeSlide,
    customSetCompanySize,
    companySize,
    setCompanySize,
  } = useSliderCS({ defaultValue: defaultValueCompanySize });

  const [valuesolutionChecked, setValuesolutionChecked] = useState<
    Array<{ id: number; name: string }>
  >([]);

  /**
   * Initialise company size
   */
  useEffect(() => {
    setDefaultValueCompanySize([
      oemProgram?.min_company_size,
      oemProgram?.max_company_size,
    ]);
    return () => {};
  }, [oemProgram?.min_company_size, oemProgram?.max_company_size]);

  //annual turnover
  const {
    annualTurnover,
    annualTurnoverSlide,
    unitTurnover,
    unitTurnoverMax,
    setAnnualTurnoverSlide,
    setAnnualTurnover,
    customSetAnnualSize,
  } = useSliderAT({ defaultValue: defaultValueAnnualTurnover });

  /**
   * Initialise annual turnover
   */
  useEffect(() => {
    setDefaultValueAnnualTurnover([
      oemProgram?.min_annual_turnover,
      oemProgram?.max_annual_turnover,
    ]);
    return () => {};
  }, [oemProgram?.min_annual_turnover, oemProgram?.max_annual_turnover]);

  //pays
  const { addPaysInArray, paysChecked, allContinents, allContinentSelected } =
    usePays(valuePays);

  /**
   * Initialise default pays
   */
  useEffect(() => {
    setDefaultValuePays(
      oemProgram?.targeted_country?.map((el: any) => ({
        idContinent: el?.idContinent?.toString(),
        countrId: el?.countryId?.toString(),
        countryName: el?.countryName,
      }))
    );
    return () => {};
  }, [oemProgram?.targeted_country]);

  //Solution software
  const {
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
  } = useEditModalSF({
    defaultValue: valuesolutionChecked,
    defaultValueFeatured: valueFeaturedsolutionChecked,
  });
  /**
   * Initialise segment details
   */

  const initialiseSolution = () => {
    setValuesolutionChecked(oemProgram?.segments);
    let featuredSelected = oemProgram?.segment_details?.filter(
      (el: any) => el?.segment?.toString() === "9999"
    );
    setValueFeaturedsolutionChecked(featuredSelected);
  };
  useEffect(() => {
    initialiseSolution();
    return () => {};
  }, [oemProgram?.segments, oemProgram?.segment_details]);

  const {
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
  } = useIL({ defaultSelected: valueIL });

  /**
   * Initialise target industrie
   */
  useEffect(() => {
    setValueIL(oemProgram?.targeted_industry);
    return () => {};
  }, [oemProgram?.targeted_industry]);

  const cancelUpdate = () => {
    setIsShowModal(false);
    setDefaultValueCompanySize([
      oemProgram?.min_company_size,
      oemProgram?.max_company_size,
    ]);
    setValueIL(oemProgram?.targeted_industry);
    setDefaultValueAnnualTurnover([
      oemProgram?.min_annual_turnover,
      oemProgram?.max_annual_turnover,
    ]);
    setDefaultValuePays(
      oemProgram?.targeted_country?.map((el: any) => ({
        idContinent: el?.idContinent?.toString(),
        countrId: el?.countryId?.toString(),
        countryName: el?.countryName,
      }))
    );
    initialiseSolution();
  };

  const customDataToSend = (params: string) => {
    switch (params) {
      case "company_size":
        return {
          minCompanySize: companySizeSlide[0],
          maxCompanySize: companySizeSlide[1],
        };
      case "annual":
        return {
          minAnnualTurnover:
            unitTurnover === "K USD"
              ? annualTurnoverSlide[0] * 1000
              : annualTurnoverSlide[0] * 1000000,
          maxAnnualTurnover:
            unitTurnoverMax === "K USD"
              ? annualTurnoverSlide[1] * 1000
              : annualTurnoverSlide[1] * 1000000,
        };
      case "country":
        return {
          targetedCountry: JSON.stringify(
            paysChecked?.map((el: any) => el?.countrId)
          ),
        };
      case "segments":
        return {
          segmentDetails: JSON.stringify(
            solutionCheckedFeatures.map((el: any) => el?.id)
          ),
          segments: JSON.stringify(solutionChecked?.map((el: any) => el?.id)),
        };
      case "industrie":
        return {
          targetedIndustry: JSON.stringify(
            companyTargetSelected?.map((el: any) => el?.id)
          ),
        };
    }
  };

  const cbUpdate = (data: any) => {
    setLoader(false);
    const completeURLSegmentDetails = oemProgram.id + "/segmentDetails";
    const completeURLSegments = oemProgram.id + "/segments";
    const completeURL = oemProgram.id + "/targetedIndustry";
    dispatch(getOemAttributte("segment_details", completeURLSegmentDetails));
    dispatch(getOemAttributte("segments", completeURLSegments));
    dispatch(getOemAttributte("targeted_industry", completeURL));
    setKeyUpdate("");
  };

  const validateUpdate = (keyUpdate: string) => {
    setKeyUpdate(keyUpdate);
    setLoader(true);
    const dataToSend = customDataToSend(keyUpdate);
    dispatch(updateOemPageSettings(oemProgram?.id, dataToSend, cbUpdate));
  };

  const validateEditMatching = (): boolean | any => {
    let usertype =
      userReducer?.oemProgramMembers?.[0]?.role === oem_roles?.editor ||
      userReducer?.oemProgramMembers?.[0]?.role ===
        oem_roles?.outreach_representative;
    if (usertype) {
      handLePersmission();
      return;
    } else {
      return true;
    }
  };

  return {
    isShowModal,
    setShowPageSettings,
    cancelUpdate,
    loader,
    validateUpdate,
    companySizeSlide,
    setCompanySizeSlide,
    customSetCompanySize,
    companySize,
    setCompanySize,
    annualTurnover,
    annualTurnoverSlide,
    unitTurnover,
    unitTurnoverMax,
    setAnnualTurnoverSlide,
    setAnnualTurnover,
    customSetAnnualSize,
    addPaysInArray,
    paysChecked,
    allContinents,
    allContinentSelected,
    solutionLeftColumnData,
    solutionRigthColumnData,
    featuredSolution,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    keyUpdate,
    handLePersmission,
    showPermission,
    validateEditMatching,
  };
}
