import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../redux/reducers";
import { ISV, RESELLER, GUEST, CONTRIBUTOR } from "../../service/constant";
import {
  getCompanyResellerAction,
  getCompanyResellerAttributeAction,
} from "../../redux/actions/company-page-reseller";
export default function UseIsvProfilCtr() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userState = useSelector((state: rootState) => state.userReducer);
  const companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const companyResellerPage = useSelector(
    (state: rootState) => state.resellerCompanyReducer
  );
  const companyPageInvestor = useSelector(
    (state: rootState) => state?.companyInvestorPageReducer
  );

  const role = userState?.roles?.filter(
    (role: string) => role !== "ROLE_USER"
  )[0];

  const editProfile = () => {
    if (role === GUEST) {
      history.push("/edit-guest-profil");
      return;
    }
    if (role === RESELLER) {
      history.push("/reseller/profile/edit");
      return;
    }
    if (role === CONTRIBUTOR) {
      history.push("/expert/profile/edit");
      return;
    }
    history.push("/profil/edit");
  };

  const goSettings = () => {
    history.push("/settings");
  };

  const goToCompanyPage = () => {
    history.push("/company/create");
  };

  const cb = () => {};

  useEffect(() => {
    if (role === RESELLER) {
      dispatch(
        getCompanyResellerAction(userState?.resellerCompanies[0]?.id, cb)
      );
    }
  }, [userState]);

  useEffect(() => {
    if (role === RESELLER) {
      dispatch(getCompanyResellerAttributeAction("targetedCountry", cb));
    }
  }, [userState]);

  useEffect(() => {
    if (role === RESELLER) {
      dispatch(getCompanyResellerAttributeAction("targetedIndustry", cb));
    }
  }, [userState]);

  useEffect(() => {
    if (role === RESELLER) {
      dispatch(getCompanyResellerAttributeAction("segmentDetails", cb));
    }
  }, [userState]);

  const urlAction = (function () {
    if (companyPageObject.completed == false) {
      return { targetUrl: "/company/create", text: "Create a Company Page" };
    } else {
      let text = userState?.isvInCompanyPage ? "" : "View your company page";
      return { targetUrl: "/company/profil", text: text };
    }
  })();

  const removeAllRouteBack = () => {
    history.go(1);
  };

  useEffect(() => {
    window.addEventListener("popstate", removeAllRouteBack);
    return () => {
      window.removeEventListener("popstate", removeAllRouteBack);
    };
  }, []);

  return {
    userState,
    editProfile,
    ...urlAction,
    goSettings,
    companyPageObject,
    goToCompanyPage,
    companyResellerPage,
    companyPageInvestor,
  };
}
