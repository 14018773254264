import React from "react";
import { usePagination, DOTS } from "./use-pagination";
import classnames from "classnames";
import "./paginate.scss";
import { Link } from "react-scroll";

export type IProps = {
  onPageChange: (p: number | string) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className: string;
  idContainer?: string;
};
const Pagination = (props: IProps) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    idContainer,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || (paginationRange && paginationRange?.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange?.[paginationRange.length - 1];
  return (
    <ul
      className={classnames("pagination-container", { [className]: className })}
    >
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <Link
          onClick={onPrevious}
          //onClick={(e) => e?.preventDefault()}
          smooth="true"
          to={props?.idContainer || "root"}
        >
          <span className="icon-nextbtn"></span>
        </Link>
      </li>

      {paginationRange?.map((pageNumber, index: number) => {
        if (pageNumber === DOTS) {
          return (
            <li className="pagination-item space dots" key={index}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={index}
            className={classnames("pagination-item", {
              active: pageNumber === currentPage,
            })}
          >
            <Link
              to={props?.idContainer || "root"}
              smooth="true"
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </Link>
          </li>
        );
      })}
      <li
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
      >
        <Link onClick={onNext} to={props?.idContainer || "root"} smooth="true">
          <span className="icon-prevbtn"></span>
        </Link>
      </li>
    </ul>
  );
};

export default Pagination;
