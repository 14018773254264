import "../OneCategory.scss";
import React, { useEffect } from "react";
import Header from "../../../../widget/index";
import Select from "react-select";
import UsePartnerProgramReview from "../../../../../controller/community-ctr/bloc-community-ctr/use-filter-partner-program-review-ctr";
import {
  LoadingArticle,
  LoadingLabel,
} from "../../../../../screen/widget/loader";
import {
  concatString,
  dateDiff,
  fileUrl,
  getUserType,
  paginate,
  refaCtoNullKey,
} from "../../../../../utils";
import moment from "moment";
import { ItemDiscussion } from "../../../../../redux/types/community-interface";
import Pagination from "../../../../widget/use-pagination/pagination";
import SearchForm from "../../../../widget/community/search/index";
import Footer from "../../../../widget/footer/footer";
import { IpropsAuthor } from "../../../../../controller/community-ctr/home-page/top-liked-author";

export default function PartnerProgram() {
  const providerProgram = { ...UsePartnerProgramReview() };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <section className="discussion">
        <div className="container mx-auto">
          <ul className="breadcrumbPerso">
            <li>
              <a href="javascript:;">Community</a>
            </li>
            <li>
              <a href="javascript:;">Partner program review</a>
            </li>
          </ul>
          <div className="searchTopic">
            <SearchForm
              categories={
                providerProgram?.communityReducer?.discussionCategories
              }
              filterSearchText={providerProgram?.filterSearchText}
              handleFilterTxt={providerProgram?.handleFilterTxt}
              searchText={providerProgram?.inputText}
              handleCateGory={providerProgram?.handleCateGory}
            />
          </div>
          <div className="rightCommunityForum allWidth oneCateg">
            <div className="labelLeft">
              <div className="block">
                <h2 className="title">Organizations</h2>
                <ul className="labelList">
                  {providerProgram?.loaderOrganization && <LoadingLabel />}
                  {paginate(
                    providerProgram?.communityReducer?.topOrganizations?.labels,
                    providerProgram?.limitTopOrganization,
                    providerProgram?.currentPage
                  )?.map(
                    (
                      el: {
                        program: string;
                        number_post: number;
                        id: string;
                      },
                      index: number
                    ) => {
                      return (
                          <li
                              key={index}
                              className={
                                providerProgram?.organizations?.find(
                                    (selectedLabel: string) => selectedLabel == el?.id
                                )
                                    ? ""
                                    : "active"
                              }
                              onClick={() =>
                                  providerProgram?.communityReducer
                                      ?.successFetchPaginate &&
                                  providerProgram?.handleOrganizations(el?.id)
                              }
                          >
                            {el?.program}
                            {el?.number_post >= 0 && <span>{el?.number_post}</span>}
                          </li>
                      )
                    }
                  )}

                  {providerProgram?.totalOrganization >
                    providerProgram?.limitTopOrganization && (
                    <li className="linkForLi nextPrev">
                      <a
                        className={
                          providerProgram?.currentPage === 1 ? "inactive" : ""
                        }
                        onClick={() =>
                          providerProgram?.currentPage !== 1 &&
                          providerProgram?.clikPrev(
                            providerProgram?.currentPage
                          )
                        }
                      >
                        Previous
                      </a>
                      <span>
                        {providerProgram?.currentPage} of{" "}
                        {Math.ceil(
                          providerProgram?.totalOrganization /
                            providerProgram?.limitTopOrganization
                        )}
                      </span>
                      <a
                        className={
                          providerProgram?.currentPage ===
                          Math.ceil(
                            providerProgram?.totalOrganization /
                              providerProgram?.limitTopOrganization
                          )
                            ? "inactive"
                            : ""
                        }
                        onClick={() =>
                          providerProgram?.currentPage !==
                            Math.ceil(
                              providerProgram?.totalOrganization /
                                providerProgram?.limitTopOrganization
                            ) &&
                          providerProgram?.nextPage(
                            providerProgram?.currentPage + 1
                          )
                        }
                      >
                        Next
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="block">
                <h2 className="title">View</h2>
                <ul className="labelList">
                  {providerProgram?.allViews?.map((el: any, index: number) => (
                    <li
                      className={
                        providerProgram?.view === el?.key ? "active" : ""
                      }
                      key={index}
                      onClick={() =>
                        providerProgram?.communityReducer
                          ?.successFetchPaginate &&
                        providerProgram?.handleFilterView(el?.key)
                      }
                    >
                      {el?.title}
                      {<span>{el?.total}</span>}
                    </li>
                  ))}
                  <li
                    className="linkForLi reset"
                    onClick={() => providerProgram?.resetFilter()}
                  >
                    <a href="javascript:;" className="resetFilter">
                      <span className="icon-refresh"></span>
                      Reset filter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="containerCategory">
              <div className="titleCategory">
                <h2>
                  <span className="icon-partner mr-3"></span>
                  Partner program review
                </h2>
                <div className="tri">
                  <div className="labelSelect">
                    <label>Show:</label>
                    <Select
                      className="triNumber"
                      isClearable={false}
                      options={[
                        { value: "10", label: "10" },
                        { value: "20", label: "20" },
                        { value: "30", label: "30" },
                      ]}
                      classNamePrefix="tri"
                      defaultValue={{
                        value: providerProgram?.limitDiscution?.toString(),
                        label: providerProgram?.limitDiscution?.toString(),
                      }}
                      value={{
                        value: providerProgram?.limitDiscution?.toString(),
                        label: providerProgram?.limitDiscution?.toString(),
                      }}
                      onChange={(params: any) =>
                        providerProgram?.setPaginateDataFilter(params?.value)
                      }
                      onInputChange={(p: any) => {
                        return "";
                      }}
                      autoFocus={false}
                      isSearchable={false}
                    />
                  </div>

                  <div className="sortedby labelSelect">
                    <label>Sorted by :</label>
                    <Select
                      className="triOreder"
                      isClearable={false}
                      options={[
                        { value: "post_date", label: "Post date" },
                        { value: "last_activity", label: "Latest activity" },
                        { value: "likes", label: "Likes" },
                        { value: "views", label: "Views" },
                      ]}
                      classNamePrefix="tri"
                      defaultValue={{
                        value: providerProgram?.sortedBy,
                        label: "Post date",
                      }}
                      onChange={(params: any) =>
                        providerProgram?.setSortedate(params?.value)
                      }
                    />
                  </div>
                </div>
              </div>
              {!providerProgram?.communityReducer?.successFetchPaginate ? (
                <LoadingArticle />
              ) : (
                <>
                  <ul className="feedList categoryList">
                    {providerProgram?.communityReducer?.paginate_program_review?.discussions?.map(
                      (el: ItemDiscussion, index: number) => (
                        <li key={index}>
                          {el?.solved && (
                            <div className="solved">
                              <div className="contentSolved">
                                <span className="icon-check"></span>
                              </div>
                            </div>
                          )}
                          <div className="infoAbout">
                            <div
                              className="withPost"
                              onClick={() =>
                                providerProgram.goToProfil(el?.user?.id)
                              }
                            >
                              <div className={`imageAffiliatedcompany empty`}>
                                {el?.user?.file_name && (
                                  <img
                                    src={fileUrl(el?.user?.file_name)}
                                    alt="profile"
                                  />
                                )}
                              </div>
                              <div
                                className={`nameOfPost ${getUserType(
                                  el?.user?.roles?.[0]
                                )}`}
                              />
                            </div>
                            <div className="infoFeed">
                              <div
                                className="titlePost"
                                onClick={() =>
                                  providerProgram?.goToreplayPost(el)
                                }
                              >
                                {el?.title}
                              </div>
                              <div className="postBy">
                                by{" "}
                                <a
                                  href="javascript:;"
                                  onClick={() =>
                                    providerProgram.goToProfil(el?.user?.id)
                                  }
                                >
                                  {concatString(
                                    el?.user?.first_name,
                                    el?.user?.last_name,
                                    " "
                                  )}
                                </a>{" "}
                                on{" "}
                                {moment(new Date(el?.created_at)).format(
                                  "MMM D, YYYY h:mm a"
                                )}
                              </div>
                              <div className="postBy">
                                Latest activity {dateDiff(el?.updated_at)} by
                                {/* // Dev #47708V1 [Community] Bug lorsque je clique sur le pseudo d'un utilisateur */}
                                <a
                                  href="javascript:;"
                                  onClick={() =>
                                    providerProgram.goToProfil(
                                      parseInt(el?.last_activity_by?.id) ?? 0
                                    )
                                  }
                                >
                                  {" "}
                                  {concatString(
                                    el?.last_activity_by?.first_name,
                                    el?.last_activity_by?.last_name,
                                    " "
                                  )}
                                </a>
                              </div>
                              <div className="subCategory">
                                {el?.labels?.map(
                                  (element: any, index: number) => (
                                    <span key={index}>{element?.title}</span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="replyInfo">
                            <b>{el?.numberOfLikes}</b>Likes
                          </div>
                          <div className="viewInfo">
                            <b>{el?.numberOfViews}</b>views
                          </div>
                        </li>
                      )
                    )}
                  </ul>

                  {providerProgram?.communityReducer?.paginate_program_review
                    ?.total > 10 && (
                    <div className="pagginationCategory">
                      <div className="labelSelect">
                        <label>Show:</label>
                        <Select
                          className="triNumber"
                          isClearable={false}
                          options={[
                            { value: "10", label: "10" },
                            { value: "20", label: "20" },
                            { value: "30", label: "30" },
                          ]}
                          classNamePrefix="tri"
                          defaultValue={{
                            value: providerProgram?.limitDiscution?.toString(),
                            label: providerProgram?.limitDiscution?.toString(),
                          }}
                          value={{
                            value: providerProgram?.limitDiscution?.toString(),
                            label: providerProgram?.limitDiscution?.toString(),
                          }}
                          onChange={(params: any) =>
                            providerProgram?.setPaginateDataFilter(
                              params?.value
                            )
                          }
                        />
                      </div>
                      <div className="pagination">
                        <Pagination
                          pageSize={providerProgram?.limitDiscution}
                          onPageChange={providerProgram?.handlePageClick}
                          className="pagination"
                          currentPage={providerProgram?.currentDiscutionPage}
                          totalCount={
                            providerProgram?.communityReducer
                              ?.paginate_program_review?.total
                          }
                        />
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="leftCommunityForum allWidth">
                {providerProgram?.topLikeProgramReview?.length > 0 && (
                  <div className="block">
                    <h2 className="title">Partner program review</h2>
                    <ul className="separateElement">
                      {providerProgram?.topLikeProgramReview?.map(
                        (el: any, index: number) => (
                          <li key={index}>
                            <div className="leftItem txtLabel">
                              <h3 className="title">{el?.title}</h3>
                              {el?.labels?.map((el: any, index: number) => (
                                <span className="topic" key={index}>
                                  {el?.title}
                                </span>
                              ))}
                            </div>
                            <div className="rightItem likeContent">
                              <span className="icon-like-full"></span>
                              {el?.numberOfLikes}
                            </div>
                          </li>
                        )
                      )}

                      {providerProgram?.topLikeProgramReview?.length > 0 && (
                        <li
                          className="linkForLi"
                          onClick={() => providerProgram?.viewAllContributor()}
                        >
                          <a
                            href="javascript"
                            onClick={(e) => e.preventDefault()}
                            className="viewAll"
                          >
                            View all
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}

                {providerProgram?.topLikedAuthor?.length > 0 && (
                  <div className="block">
                    <h2 className="title">Top liked authors</h2>
                    <ul className="separateElement">
                      {providerProgram?.topLikedAuthor?.map(
                        (author: IpropsAuthor, index: number) => (
                          <li key={index}>
                            <div className="leftItem itemAuthor">
                              <div className="imageAffiliatedcompany empty">
                                {author?.profil_picture && (
                                  <img src={fileUrl(author?.profil_picture)} />
                                )}
                              </div>
                              <div className="infoAffiliated">
                                <h3 className="title">
                                  {refaCtoNullKey(author?.user_name)}
                                </h3>
                                <div className="post">
                                  {refaCtoNullKey(author?.job_title)}
                                </div>
                              </div>
                            </div>
                            <div className="rightItem likeContent">
                              <span className="icon-like-full"></span>
                              {refaCtoNullKey(author?.number_of_like)}
                            </div>
                          </li>
                        )
                      )}
                      {providerProgram?.topLikedAuthor?.length > 0 && (
                        <li
                          className="linkForLi"
                          onClick={() => providerProgram?.viewAllAuthor()}
                        >
                          <a
                            className="viewAll"
                            onClick={(e) => e?.preventDefault()}
                          >
                            View all
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
