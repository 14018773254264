/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "react-bootstrap/Modal";

export type TSuccessContactUs = {
  isShowSuccessContactUs: boolean;
  handleShowSuccessContactUs: (p: boolean) => void;
};
export default function SuccessContactUs(props: TSuccessContactUs) {
  return (
    <div>
      {/* Request step 3 */}
      <Modal
        show={props?.isShowSuccessContactUs}
        className="createCompanypopup smallPopup forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Thank you!</h2>
            <a
              className="close"
              onClick={(p: any) => {
                p?.preventDefault();
                props.handleShowSuccessContactUs(false);
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight">
          <div className="bodyCreateCompany ">
          <div className="finalpopup success">
              <h3 className="title">We will get back to you!</h3>
              We will evaluate your submission and will notify you once we 
              <br />
              approve or deny you as a verified professional on Devinsider.
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
