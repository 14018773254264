export type AdsDetail = {
  classifiedAdsFiles: Array<any>;
  classified_ads_type: { id: string; type: string; icon: string };
  content: string;
  created_at: string;
  display_author_name: boolean;
  display_company_name: boolean;
  id: string;
  liked: boolean;
  title: string;
  user: {
    id: string;
    country: any;
    file_name: string;
    first_name: string;
    job_title: string;
    last_name: string;
    roles: Array<string>;
  };
  page: CompanyPage;
};

export type CompanyPage = {
  id: number;
  company_name: string;
  city?: string;
  country_name?: string;
  photo_profil?: string;
};

export enum AdsEntityTypeEnum {
  COMPANY_PAGE = "COMPANY_PAGE",
  INVESTOR_PAGE = "INVESTOR_PAGE",
  RESELLER_COMPANY = "RESELLER_COMPANY",
}

export enum AdsAttributeEnum {
  SEGMENT_DETAILS = "segmentDetails",
  TARGET_INDUSTRY = "targetedIndustry",
}
