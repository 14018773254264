import { convertToRaw } from 'draft-js';
import { EditorState } from 'draft-js';

import { useEffect, useState, useReducer, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import { rootState } from '../../../../redux/reducers';
import {
  ItemDiscussion,
  ItemsReplyPost,
  RelatedAdds,
} from '../../../../redux/types/community-interface';
import UseReply from '../../../../screen/widget/reply-wysiwig/use-reply';
import {
  getAllReplyes,
  getPaginateChildPost,
  likePost,
  likeReply,
  markAsBestAnswerAction,
  markAsFovoriteAction,
  removeParentPostAction,
  removePostAction,
  replayChildPost,
  replayPost,
  restoreCommentPost,
  updateParentPostAction,
  updateReplyAction,
  lockPostAction,
  pinPostAction,
} from '../../../../redux/actions/community-action';
import {
  CONTRIBUTOR,
  INVESTOR,
  MODERATOR,
  OEM_PROGRAM,
  RESELLER,
  url,
} from '../../../../service/constant';
import { postJsonDataService } from '../../../../service/applicatif/back-end-service';
import UseSearch from '../../../../screen/widget/community/search/use-search';
import { toast } from 'react-toastify';
import { getData, postData } from '../../../../service/api';

export default function UseDiscussionCtr() {
  const history = useHistory();

  const query: { categoryId: string; postId: string } = useParams();
  const dispatch = useDispatch();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderGetChildreen, setLoaderGetChildreen] = useState<boolean>(false);
  const [initialWysiwygTemplate, setInitialWysiwygTemplate] = useState('');
  const { setEditorStatePost, stateFormWysywig } = UseReply({
    initialWysiwygTemplate: initialWysiwygTemplate,
  });
  const [idReplyShowWysiwyg, setidReplyShowWysiwyg] = useState<
    string | undefined
  >('');
  const [isParentReply, setIsParentReply] = useState<boolean>(false);
  const [isEditingParent, setIsEditingParent] = useState<boolean>(false);
  const [isEditReply, setIsEditReply] = useState<boolean>(false);
  const [isEditChildreen, setIsEditChildreen] = useState<boolean>(false);
  const [allRelatedContent, setAllRelatedContent] = useState<Array<any>>([]);
  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();
  const [loaderFavorite, setLoaderFavorite] = useState<boolean>(false);
  const [loaderMore, setLoaderMore] = useState<boolean>(false);
  const [moreUserPost, setmoreUserPost] = useState<Array<ItemDiscussion>>([]);
  const [loaderFirstGetReply, setloaderFirstGetReply] =
    useState<boolean>(false);
  const [moreRelatedAds, setMoreRelatedAds] = useState<Array<RelatedAdds>>([]);
  const [loaderRelatedAds, setLoaderRelatedAds] = useState<boolean>(false);
  const oemProgramId = useSelector(
    (state: rootState) => state?.oemProgramReducer.program.id
  );
  const [allListeBlocOpen, setallListeBlocOpen] = useState<Array<string>>([]);
  /**
   * For parent discussion
   *
   */
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [limitDiscussion, setLimitDiscussion] = useState<number>(10);

  const [parentIdTofetch, setParentIdTofetch] = useState<string>('');

  const cb = () => {
    setloaderFirstGetReply(false);
  };

  const getResponsePaginate = (p: number, isFirst: boolean = false) => {
    if (isFirst) {
      setloaderFirstGetReply(true);
    }
    dispatch(getAllReplyes({ idPost: query?.postId, pageId: p }, cb));
  };

  const [loaderRelated, setloaderRelated] = useState<boolean>(false);
  const [loaderLike, setloaderLike] = useState<boolean>(false);

  const [showLogin, setShowLogin] = useState<boolean>(false);

  //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
  const [mentions, setMentions] = useState<
    Array<{ text: string; value: string; url: string }>
  >([]);
  const [registeredMentions, setRegisteredMentions] = useState<Array<string>>(
    []
  );

  /**
   *
   * Get paginate discution of data
   */
  useEffect(() => {
    /**
     * if current page is inferieur to state current page , get new topLabel
     */
    let currentPageResponse =
      communityReducer?.curentDiscutionReplyPost?.currentPage;
    if (currentPageResponse < currentPage) {
      getResponsePaginate(currentPage, true);
    }
    return () => {
      dispatch(restoreCommentPost(() => {}));
    };
  }, []);

  useEffect(() => {
    getResponsePaginate(currentPage, true);
    return () => {
      dispatch(restoreCommentPost(() => {}));
    };
  }, [query]);

  /**
   *
   * Get more post by user
   */
  useEffect(() => {
    const userPostId =
      communityReducer?.curentDiscutionReplyPost.currentPost?.user?.id;
    const postId =
      communityReducer?.curentDiscutionReplyPost.currentPost?.post_type?.id;

    async function getMorePost() {
      const params = {
        idUser:
          communityReducer?.curentDiscutionReplyPost.currentPost?.user?.id, // location?.state?.params?.user?.id,
        postTypes: postId,
        limit: 4,
        page: 1,
        sortBy: 'post_date',
        orderBy: 'DESC',
      };
      setLoaderMore(true);
      let response = await postJsonDataService(
        url?.community?.activity?.get_all_post_by_user,
        params,
        userReducer?.token
      );
      if (response?.status === 200) {
        setLoaderMore(false);
        setmoreUserPost(response?.data?.results);
      }
    }
    if (userPostId && postId) {
      getMorePost();
    }
  }, [communityReducer?.curentDiscutionReplyPost?.currentPost]);

  const cbReply = (response: any) => {
    if (response?.status === 200) {
      setLoader(false);
      setidReplyShowWysiwyg('');
      setIsParentReply(false);
      setEditorStatePost(EditorState?.createEmpty());
      setcurrentPage(1);
    }
  };

  const postReply = () => {
    let content = JSON.stringify(
      convertToRaw(stateFormWysywig?.getCurrentContent())
    );
    setLoader(true);
    dispatch(
      replayPost(
        {
          content,
          post: query?.postId,
          replyParent: '',
        },
        cbReply
      )
    );
    //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
    let postId = query?.postId;
    (async () => {
      await postData(
        url?.community?.mention + 's/register',
        {
          postId: postId,
          postType: 'FORUM_DISCUSSION',
          userId: registeredMentions,
        },
        userReducer.token
      );
    })();
  };

  const handlePageClick = (data: any) => {
    setcurrentPage(data);
    dispatch(restoreCommentPost(() => getResponsePaginate(data)));
  };

  const cancelReply = () => {
    if (loader) {
      setLoader(false);
    }
    if (isEditingParent) {
      setIsEditingParent(false);
    }
    isParentReply && setIsParentReply(false);
    setidReplyShowWysiwyg('');

    if (initialWysiwygTemplate != '') {
      setInitialWysiwygTemplate('');
    }
  };

  /**
   *
   * Clause parent or other wysiwyg when comment post
   */

  const cbAfterMarkFavorite = (params: any) => {
    setLoaderFavorite(false);
  };

  const addToFavorite = useCallback(async () => {
    if (userReducer?.token) {
      setLoaderFavorite(true);
      dispatch(
        markAsFovoriteAction(
          communityReducer?.curentDiscutionReplyPost?.currentPost?.id,
          cbAfterMarkFavorite
        )
      );
    } else {
      setShowLogin(true);
    }
  }, [communityReducer?.curentDiscutionReplyPost?.currentPost?.id]);

  const replyChild = (id?: string, idParentDeepReply?: string) => {
    let content = JSON.stringify(
      convertToRaw(stateFormWysywig?.getCurrentContent())
    );
    setLoader(true);
    dispatch(
      replayChildPost(
        {
          content,
          post: query?.postId,
          replyParent: id || idReplyShowWysiwyg,
          idDeepReply: idParentDeepReply,
        },
        cbReply
      )
    );
    //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
    let postId = query?.postId;
    (async () => {
      let response = await postData(
        url?.community?.mention + 's/register',
        {
          postId: postId,
          postType: 'FORUM_DISCUSSION',
          userId: registeredMentions,
        },
        userReducer.token
      );
    })();
  };

  const cbAfterGetChildreen = (data: any, idParent: string) => {
    if (data?.status === 200) {
      setLoaderGetChildreen(false);
      setallListeBlocOpen([...allListeBlocOpen, idParent?.toString()]);
    }
  };

  const infoAbout = async (idParent: number) => {
    let isAlreadyOpen = allListeBlocOpen?.find(
      (el: string) => el?.toString() === idParent?.toString()
    );
    if (isAlreadyOpen) {
      let removeInTempListOpen = allListeBlocOpen?.filter(
        (el: string) => el?.toString() !== idParent?.toString()
      );
      setallListeBlocOpen(removeInTempListOpen);
    } else {
      let parentClick =
        communityReducer?.curentDiscutionReplyPost?.allReplies?.find(
          (el: ItemsReplyPost) => el?.id == idParent
        );

      const checkIfChildreenExist =
        parentClick &&
        parentClick?.childreen?.length < 1 &&
        parentClick?.numberOfReplies > 0;

      if (checkIfChildreenExist) {
        setParentIdTofetch(idParent?.toString());
        setLoaderGetChildreen(true);
        dispatch(
          getPaginateChildPost(
            { idParent: idParent, curretPage: 1 },
            (response: any) =>
              cbAfterGetChildreen(response, idParent?.toString())
          )
        );
      } else {
        setallListeBlocOpen([...allListeBlocOpen, idParent?.toString()]);
      }
    }
  };

  const cbAfterLike = (response: any) => {
    if (response?.status === 200) {
      setloaderLike(false);
    }
  };

  const likedPost = async (id: number | undefined) => {
    if (userReducer?.token) {
      setloaderLike(true);
      let params = {
        post: id,
      };
      dispatch(likePost(params, cbAfterLike));
    } else {
      setShowLogin(true);
    }
  };

  const likedChildreen = async (
    id: number | undefined,
    idChildreen?: number,
    isChildreen?: boolean
  ) => {
    if (userReducer?.token) {
      let params = {
        idToLike: id,
        idChildreen,
        isChildreen,
      };
      dispatch(likeReply(params, cbAfterLike));
    } else {
      setShowLogin(true);
    }
  };

  const clickReply = useCallback(
    (id: number | undefined, isParentReply: boolean) => {
      const isConnectedUser = userReducer?.token;
      if (isConnectedUser) {
        if (initialWysiwygTemplate != '') {
          setInitialWysiwygTemplate('');
        }
        isParentReply && setIsParentReply(true);
        setidReplyShowWysiwyg(id?.toString());
      } else {
        setShowLogin(true);
      }
    },
    []
  );

  const showAllPostCategorie = () => {
    const categoryId =
      communityReducer?.curentDiscutionReplyPost.currentPost?.category.id;
    const postType =
      communityReducer?.curentDiscutionReplyPost.currentPost?.post_type.id;

    const urlPr = '/community/press-releases/0';
    const urlPartener = '/community/partner-program';
    const urlDiscussion = '/community/one-category/' + categoryId + '/0';
    const urlArticle = '/community/article-category/' + categoryId + '/0';

    const url =
      postType === 1
        ? urlDiscussion
        : postType === 2
        ? urlArticle
        : postType === 3
        ? urlPr
        : urlPartener;

    history.push(url);
  };

  const showActivityUser = () => {
    history.push(
      '/community/activity-user/' +
        communityReducer?.curentDiscutionReplyPost?.currentPost?.user?.id //location?.state?.params?.user?.id
    );
  };

  const showClassicAdds = () => {
    history.push('/classified-ads/home');
  };

  useEffect(() => {
    postJsonDataService(
      url?.community?.views_post + query?.postId,
      {},
      userReducer?.token || ''
    );
  }, []);

  const clickEditParentPost = (p: string) => {
    setInitialWysiwygTemplate(
      communityReducer?.curentDiscutionReplyPost?.currentPost?.description
    );
    setIsEditingParent(true);
    setidReplyShowWysiwyg(p);
  };

  const cbRemoveParentPost = (p: any) => {};

  //Dev #47878 [Moderator account] Il faut qu'il y ait 2 options de suppression de post.
  const cbTotalRemoveParentPost = (p: any) => {
    toast('The post has been totally removed from Devinsider');
    history.push('/community/home');
  };

  const totalRemoveParentPost = (p: string) => {
    dispatch(removeParentPostAction(p, cbTotalRemoveParentPost, true));
  };

  const removeParentPost = (p: string) => {
    dispatch(removeParentPostAction(p, cbRemoveParentPost));
  };

  const cbUpdateParentPost = (params: any) => {
    if (params?.status === 200) {
      setidReplyShowWysiwyg('');
      isParentReply && setIsParentReply(false);
      if (isEditingParent) {
        setIsEditingParent(false);
      }
      if (initialWysiwygTemplate !== '') {
        setInitialWysiwygTemplate('');
      }
    }
    setLoader(false);
  };

  const updateParentPost = () => {
    let description = JSON.stringify(
      convertToRaw(stateFormWysywig?.getCurrentContent())
    );
    let params = {
      idParent: idReplyShowWysiwyg?.toString(),
      description,
      category:
        communityReducer?.curentDiscutionReplyPost?.currentPost?.category?.id,
      postType:
        communityReducer?.curentDiscutionReplyPost?.currentPost?.category?.id,
    };
    setLoader(true);
    dispatch(updateParentPostAction(params, cbUpdateParentPost));
    //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
    let postId = idReplyShowWysiwyg?.toString();
    (async () => {
      let response = await postData(
        url?.community?.mention + 's/register',
        {
          postId: postId,
          postType: 'FORUM_DISCUSSION',
          userId: registeredMentions,
        },
        userReducer.token
      );
    })();
  };

  const cbAfterMarkAsBestAnswer = (response: any, idParent: any) => {
    setallListeBlocOpen([idParent]);
  };

  const markAsBestAnswer = (
    idParent: string | number,
    isChild: boolean = false,
    idChild: string = ''
  ) => {
    dispatch(
      markAsBestAnswerAction(
        idParent?.toString(),
        isChild,
        idChild,
        (response: any) =>
          cbAfterMarkAsBestAnswer(response, idParent?.toString())
      )
    );
  };

  const cbAfterRemoveReply = (response: any) => {};
  //* Dev #48290 Moderator suppression message
  const cbAfterTotalRemoveReply = (response: any) => {
    //update redux
    dispatch(
      getAllReplyes({ idPost: query?.postId, pageId: 1 }, () =>
        toast('The reply has been successfully removed from Devinsider')
      )
    );
  };

  const removePost = (
    idParent: string,
    isChildreen: boolean = false,
    idChildreen: string = ''
  ) => {
    dispatch(
      removePostAction(
        idParent,
        isChildreen,
        idChildreen,
        false,
        cbAfterRemoveReply
      )
    );
  };

  //* Dev #48290 Moderator suppression message
  const totalRemoveReply = (
    idParent: string,
    isChildreen: boolean = false,
    idChildreen: string = ''
  ) => {
    dispatch(
      removePostAction(
        idParent,
        isChildreen,
        idChildreen,
        true,
        cbAfterTotalRemoveReply
      )
    );
  };

  const goDiscussionCategorie = () => {
    history?.push('/community/discussions-categories');
  };

  const goHome = () => {
    history?.push('/community/home');
  };

  const clickEditReply = (p: any, isChildreen: boolean = false) => {
    setInitialWysiwygTemplate(p?.content);
    if (isChildreen) {
      setIsEditChildreen(true);
    } else {
      setIsEditReply(true);
    }
    setidReplyShowWysiwyg(p?.id?.toString());
  };

  // Close wysywyg and set id reply is empty
  const cbUpdateReply = (response: any) => {
    if (response?.status === 200) {
      setidReplyShowWysiwyg('');
      if (isEditReply) {
        setIsEditReply(false);
      }
      if (isEditChildreen) {
        setIsEditChildreen(false);
      }
      if (initialWysiwygTemplate !== '') {
        setInitialWysiwygTemplate('');
      }
    }
    setLoader(false);
  };

  const updateReplyPost = (
    idParent: string,
    isChildreen: boolean = false,
    idChildreen: string = ''
  ) => {
    let description = JSON.stringify(
      convertToRaw(stateFormWysywig?.getCurrentContent())
    );
    setLoader(true);
    dispatch(
      updateReplyAction(
        idParent,
        isChildreen,
        idChildreen,
        description,
        cbUpdateReply
      )
    );
    //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
    let postId = idParent;
    (async () => {
      let response = await postData(
        url?.community?.mention + 's/register',
        {
          postId: postId,
          postType: 'FORUM_DISCUSSION',
          userId: registeredMentions,
        },
        userReducer.token
      );
    })();
  };

  const goFilter = () => {
    let idCategory =
      communityReducer?.curentDiscutionReplyPost?.currentPost?.category?.id;
    let idPostType =
      communityReducer?.curentDiscutionReplyPost?.currentPost?.post_type?.id;

    let isDiscussion = idPostType?.toString() === '1';
    let isArtycle = idPostType?.toString() === '2';

    /*if (isDiscussion) {
      history?.push("/community/one-category/" + idCategory);
    }
    if (isArtycle) {
      history?.push("/community/article-category");
    }*/
    history.push('/community/view-all-posts/' + idPostType);
  };
  /**
   *
   * Get related content data
   */
  useEffect(() => {
    const postType =
      communityReducer?.curentDiscutionReplyPost?.currentPost?.post_type?.id;

    const categoryId =
      communityReducer?.curentDiscutionReplyPost?.currentPost?.category?.id;

    const params = {
      categories: categoryId,
      views: '',
      labels: '',
      postTypes: postType,
      limit: 4,
      page: 1,
      sortBy: 'post_date',
      orderBy: 'DESC',
      tags: '',
      notIdlabels: '',
      oem_program: '',
    };
    async function getRelated() {
      setloaderRelated(true);
      //Bug #48623, related content
      let response = await getData(
        url?.community?.related_content +
          '?i=' +
          communityReducer?.curentDiscutionReplyPost?.currentPost.id +
          '&cat=' +
          communityReducer?.curentDiscutionReplyPost?.currentPost.category?.id,
        userReducer?.token
      );
      if (response?.status === 200) {
        setloaderRelated(false);
        setAllRelatedContent(response?.data);
      }
    }

    if (postType) {
      getRelated();
    }
  }, [communityReducer?.curentDiscutionReplyPost?.currentPost]);

  const getFreshFind = async () => {
    let params = {
      limit: 0,
      number: 4,
      oem_program: oemProgramId,
      category_id: '',
    };
    let response = await postJsonDataService(
      url?.classified_ads?.get_fresh_finds,
      params,
      userReducer?.token
    );
    if (response?.status === 200) {
      setMoreRelatedAds(response.data);
    }
    setLoaderRelatedAds(false);
  };

  const showAllAds = () => {
    history.push('/classified-ads/home');
  };

  useEffect(() => {
    setLoaderRelatedAds(true);
    getFreshFind();
  }, []);

  const showActivity = (user: any) => {
    history.push('/community/activity-user/' + user?.id);
  };

  const checkIpProtected = (): boolean => {
    let checkIfProgramReviewOrPressRelease =
      communityReducer?.curentDiscutionReplyPost?.currentPost?.post_type?.id ===
        3 ||
      communityReducer?.curentDiscutionReplyPost?.currentPost?.post_type?.id ===
        4;

    if (query?.categoryId === '12') {
      return true;
    }

    if (
      userReducer?.roles?.[0] === OEM_PROGRAM &&
      checkIfProgramReviewOrPressRelease
    ) {
      return true;
    }

    if (
      userReducer?.roles?.[0] === RESELLER &&
      checkIfProgramReviewOrPressRelease
    ) {
      return true;
    }

    if (
      userReducer?.roles?.[0] === INVESTOR &&
      checkIfProgramReviewOrPressRelease
    ) {
      return true;
    }

    if (
      userReducer?.roles?.[0] === CONTRIBUTOR &&
      checkIfProgramReviewOrPressRelease
    ) {
      return true;
    }

    return false;
  };

  const goToLabelCategory = (
    categoryId: string,
    labelId: string,
    postType: string
  ) => {
    postType === '1' &&
      history.replace('/community/one-category/' + categoryId + '/' + labelId);
    postType === '2' &&
      history.replace(
        '/community/article-category/' + categoryId + '/' + labelId
      );
    postType === '3' && history.replace('/community/press-releases/' + labelId);
  };

  const handleDeletedMessage = (userInfo: any) => {
    return userInfo?.roles?.indexOf(MODERATOR) != -1
      ? "This content has been deleted because it doesn't follow our community guidelines."
      : 'This content has been removed.';
  };

  const cbAfterPinAndLockPost = (response: any) => {
    toast(response?.data);
  };

  const lockParentPost = (idParent: string) => {
    dispatch(lockPostAction(idParent, cbAfterPinAndLockPost));
  };

  const pinParentPost = (idParent: string) => {
    dispatch(pinPostAction(idParent, cbAfterPinAndLockPost));
  };

  return {
    postReply,
    cancelReply,
    setEditorStatePost,
    stateFormWysywig,
    loader,
    communityReducer,
    currentPage,
    handlePageClick,
    limitDiscussion,
    idReplyShowWysiwyg,
    setidReplyShowWysiwyg,
    loaderFavorite,
    addToFavorite,
    replyChild,
    infoAbout,
    loaderGetChildreen,
    parentIdTofetch,
    showLogin,
    setShowLogin,
    likedPost,
    clickReply,
    loaderRelated,
    allRelatedContent,
    loaderLike,
    loaderMore,
    moreUserPost,
    showAllPostCategorie,
    likedChildreen,
    userReducer,
    showActivityUser,
    clickEditParentPost,
    updateParentPost,
    isEditingParent,
    markAsBestAnswer,
    removePost,
    totalRemoveReply,
    goDiscussionCategorie,
    goHome,
    updateReplyPost,
    clickEditReply,
    isEditReply,
    isEditChildreen,
    goFilter,
    loaderFirstGetReply,
    showActivity,
    isProtectedReply: checkIpProtected(),
    allListeBlocOpen,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    showClassicAdds,
    goToLabelCategory,
    removeParentPost,
    //Dev #47878 [Moderator account] Il faut qu'il y ait 2 options de suppression de post.
    totalRemoveParentPost,
    isParentReply,
    moreRelatedAds,
    loaderRelatedAds,
    showAllAds,
    handleDeletedMessage,
    pinParentPost,
    lockParentPost,
    //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
    mentions,
    updateMention: async (value: any) => {
      let keyWord =
        value?.blocks[0]?.text.includes('@') &&
        value?.blocks[0]?.text?.split('@');
      let keyWordText = keyWord[keyWord.length - 1]?.trim() ?? '';
      if (keyWordText !== '') {
        let response = await postData(
          url?.community.mention + '/create',
          {
            postType: '',
            keyWord: keyWordText,
          },
          userReducer.token
        );
        if (response?.status === 200) {
          setMentions(
            response?.data?.map((rep: any) => {
              return {
                text: rep?.first_name + ' ' + rep?.last_name,
                value: rep?.first_name + ' ' + rep?.last_name,
                url: '/community/activity-user/' + rep?.id,
              };
            })
          );
        }
      }

      //register current mention
      let mentionsKeyArray = Object?.keys(value?.entityMap);
      let mentionsData: Array<any> = [];
      mentionsKeyArray.map((key: string) => {
        if (value?.entityMap[key]?.type === 'MENTION') {
          mentionsData.push(value?.entityMap[key]?.data?.url?.split('/').pop());
        }
      });
      setRegisteredMentions(mentionsData);
    },
  };
}
