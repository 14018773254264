/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { IPropsPortfolio, PropsPortFolio } from '../types';
import { fileUrl, refaCtoNullKey, renderLogo } from '../../../../../utils';
import ModalEditPortfolio from '../modal-edit/edit-portfolio';
import { ModalDetailPortFolio } from '../modal-edit/detail-portFolio';

import right from '../../../../../resources/images/arrow-right-solid.svg';

export type IinvestorPortfolio = {
  id: number;
  name: string;
  logo: string;
  url: string;
};

export default function InfoPortfolio(props: IPropsPortfolio) {
  // Dev #46476 V1 [Investor] Ameliorations concernant les texts explicatifs de la 'investor page'

  const [isShowFindMore, setIsShowFindMore] = useState<boolean>(false);

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const havePortfolioRegistered =
    props?.initialPortFolio?.filter(
      (portfolio: any) => portfolio?.name !== '' && portfolio?.url !== ''
    ).length > 0
      ? true
      : false;

  const isHaveUrlOrLogo = (data: any, index: number): boolean => {
    return data?.[index]?.logo !== '' || data?.[index]?.url !== ''
      ? true
      : false;
  };

  const isHaveUr = (data: any, index: number): boolean => {
    return data?.[index]?.url !== '' ? true : false;
  };

  const isPreviewMode = props.isPreview && havePortfolioRegistered;

  const clauseDetailPortFolio = () => {
    setIsShowFindMore(false);
  };

  const showDetailPortFolio = (portfolio: any) => {
    setSelectedItem(portfolio);
    setIsShowFindMore(true);
  };

  const showWebPage = (data: any, index: number) => {
    if (isHaveUr(data, index)) {
      const item = data[index];
      window.open(item && item.url);
    }
  };

  const isFindMore = (item: any) => {
    const isHaveLocation = item?.hqLocation?.id;
    const isHaveIndustry = item?.hqLocation?.length > 0;
    const isHaveSegment = item?.industry?.length > 0;
    const isHaveSegmentDetails = item?.hqLocation?.length > 0;
    return (
      isHaveLocation || isHaveIndustry || isHaveSegment || isHaveSegmentDetails
    );
  };

  const filterdEmptyPreview = () => {
    return props.initialPortFolio.filter((element) => {
      return element.url !== '' || element.name !== '';
    });
  };

  const PortFolioPage = () => (
    <article className='investorArticle'>
      <div className='mx-auto container cont3'>
        <div className='contentLeftInvestor'>
          <a
            className='linkEditLeftCompany'
            onClick={() => props?.showModalEdit()}
          >
            Edit<span className='icon-edit-full'></span>
          </a>

          <h3 className='title'>Portfolio </h3>
          <p>
            {/* // Dev #46476 V1 [Investor] Ameliorations concernant les texts explicatifs de la 'investor page' */}
            {!props?.isPreview &&
              (props?.isEditMode || havePortfolioRegistered === false) && (
                <>
                  Add the software vendors you have invested in to your
                  portfolio.
                </>
              )}
          </p>
          <br />
          <ul className='listSquareItem portfolio listPort'>
            {
            
              ((props.isPreview || props.isOtherUser) && havePortfolioRegistered !== false) ? filterdEmptyPreview()?.map(
                (portfolio: PropsPortFolio, index: number) => {
                  return (
                    <li>
                      {props?.initialPortFolio && (
                        <a
                          className={
                            !isHaveUrlOrLogo(props?.initialPortFolio, index)
                              ? 'empty'
                              : ''
                          }
                        >
                          <div className='logo'>
                            {isHaveUrlOrLogo(props?.initialPortFolio, index) && (
                              <img
                                src={
                                  props?.initialPortFolio?.[index]?.logo
                                    ? fileUrl(
                                        props?.initialPortFolio?.[index]?.logo
                                      )
                                    : renderLogo(
                                        props?.initialPortFolio?.[index]?.url
                                      )
                                }
                                alt=''
                                onClick={() =>
                                  showWebPage(props?.initialPortFolio, index)
                                }
                              />
                            )}
                          </div>
                          <div className='namePortfolio'>
                            {refaCtoNullKey(
                              props?.initialPortFolio?.[index]?.name,
                              ''
                            )}
                          </div>
                          {isFindMore(portfolio) && (
                            <div className='findOutMore'>
                              <span
                                onClick={() => showDetailPortFolio(portfolio)}
                              >
                                Find out more <img src={right} alt='' />
                              </span>
                            </div>
                          )}
                        </a>
                      )}
                    </li>
                  );
                  // }
                }
              ) : props?.initialPortFolio?.map(
                (portfolio: PropsPortFolio, index: number) => {
                  return (
                    <li>
                      {props?.initialPortFolio && (
                        <a
                          className={
                            !isHaveUrlOrLogo(props?.initialPortFolio, index)
                              ? 'empty'
                              : ''
                          }
                        >
                          <div className='logo'>
                            {isHaveUrlOrLogo(props?.initialPortFolio, index) && (
                              <img
                                src={
                                  props?.initialPortFolio?.[index]?.logo
                                    ? fileUrl(
                                        props?.initialPortFolio?.[index]?.logo
                                      )
                                    : renderLogo(
                                        props?.initialPortFolio?.[index]?.url
                                      )
                                }
                                alt=''
                                onClick={() =>
                                  showWebPage(props?.initialPortFolio, index)
                                }
                              />
                            )}
                          </div>
                          <div className='namePortfolio'>
                            {refaCtoNullKey(
                              props?.initialPortFolio?.[index]?.name,
                              ''
                            )}
                          </div>
                          {isFindMore(portfolio) && (
                            <div className='findOutMore'>
                              <span
                                onClick={() => showDetailPortFolio(portfolio)}
                              >
                                Find out more <img src={right} alt='' />
                              </span>
                            </div>
                          )}
                        </a>
                      )}
                    </li>
                  );
                  // }
                }
              )
            
            }
          </ul>
        </div>
        <ModalEditPortfolio {...props} />
        <ModalDetailPortFolio
          showModalDetail={isShowFindMore}
          clauseDetailPortFolio={() => clauseDetailPortFolio()}
          selectedItem={selectedItem}
        />
      </div>
    </article>
  );

  const UnclockedPortFolio = () => (
    <article className='investorArticle'>
      <div className='mx-auto container cont3'>
        <div className='contentLeftInvestor'>
          <h3 className='title'>Portfolio </h3>
          <br />
          <ul className='listSquareItem portfolio listPort'>
            {props?.initialPortFolio
              .filter((pf: any) => pf.name !== '' && pf.url !== '')
              .map((portfolio, index) => {
                return <li className='portfolioLocked'> </li>;
              })}
          </ul>
        </div>
      </div>
    </article>
  );

  // if (props.isLockedPage) {
  //   return (

  //   );
  // }
  return (
    <>
      {props.isPreview && props?.isLockedPage ? (
        UnclockedPortFolio()
      ) : props.isPreview && !props?.isLockedPage ? (
        PortFolioPage()
      ) : (
        <></>
      )}
      {!props.isPreview && PortFolioPage()}
    </>
  );
}
