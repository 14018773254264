import React from "react";
import Select from "react-select";

type IProps = {
  handleLimit: (p: any) => void;
  handleSorted: (p: any) => void;
};
export default function FilterSelect(props: IProps) {
  return (
    <div className="titleCategory">
      <h2>
        <span className="icon-press mr-3"></span>
        Press releases
      </h2>
      <div className="tri">
        <div className="labelSelect">
          <label>Show:</label>
          <Select
            className="triNumber"
            isClearable={false}
            options={[
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "30", label: "30" },
            ]}
            classNamePrefix="tri"
            defaultValue={{ value: "10", label: "10" }}
            onChange={(params: any) => props?.handleLimit(params?.value)}
            onInputChange={(p: any) => {
              return "";
            }}
            autoFocus={false}
            isSearchable={false}
          />
        </div>

        <div className="sortedby labelSelect">
          <label>Sorted by :</label>
          <Select
            className="triOreder"
            isClearable={false}
            options={[
              { value: "date", label: "Post date" },
              { value: "activity", label: "Latest activity" },
              { value: "replies", label: "Replies" },
              { value: "view", label: "Views" },
            ]}
            classNamePrefix="tri"
            defaultValue={{
              value: "activity",
              label: "Latest activity",
            }}
            onChange={(params: any) => props?.handleSorted(params?.value)}
          />
        </div>
      </div>
    </div>
  );
}
