/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
import { ReactComponent as Loader } from "../../../../../../resources/images/Rolling.svg";

export default function ModalEditFundingRoundAndAmount(
  props: T.IpropEditFundingRoundAandAmount
) {
  return (
    <>
      {/* Popup Annual Funding */}
      <Modal
        show={props.isShowModalFundingRoundAndAmount}
        className="createCompanypopup smallPopup forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Funding</h2>
            <a
              className="close"
              onClick={() => props.setIsShowModalFundingRoundAndAmount(false)}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight">
          <div className="bodyCreateCompany">
            <div className="contentBorder row">
              <div className="col-12 idea">
                Devinsider host a pool of specialized investors.
                <br />
                Expose your company by indicating your funding needs. Only investors registered on Devinsider will be able to view this information.
              </div>
              <div className="form-group required col-12">
                <label htmlFor="">Funding round</label>
                <select
                  className="custom-select"
                  onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                    props.setFundingRound(evt.target.value);
                  }}
                >
                  <option value="">Select an option</option>

                  {props.fundingRound?.map(
                    (el: T.IFoundingRound, index: number) => (
                      <option
                        value={el.id}
                        key={index}
                        selected={el.id.toString() == props.fundingRoundValue}
                      >
                        {el.name}
                      </option>
                    )
                  )}
                </select>
              </div>
              {props?.fundingRoundValue !== "5" && (<div className="form-group required col-12">
                <label htmlFor="">Amount you woud like to raise</label>

                <select
                  className="custom-select"
                  onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                    props.setAmountWouldRiseSelected(evt.target.value);
                  }}
                >
                  <option defaultValue="{}">Select an option</option>
                  {props.amontWouldRise &&
                    props.amontWouldRise.map((el: any, index: number) => {
                      return (
                        <option
                          value={JSON.stringify(el)}
                          key={JSON.stringify(el)}
                          selected={
                            JSON.stringify(el) == props.amountWouldRiseSelected
                              ? true
                              : false
                          }
                        >
                          {Object.keys(el)}
                        </option>
                      );
                    })}
                </select>
              </div>)}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => props.setIsShowModalFundingRoundAndAmount(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-devinsider px-5 save"
              onClick={() => props.updateAnnualTurnOver()}
              disabled={props.loaderFinancial}
            >
              {props.loaderFinancial ? <Loader /> : "Save"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
