import React, { ChangeEvent, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Link } from 'react-router-dom';
import { IPropsDetailPortfolio } from '../types';
import { fileUrl, getSStorage, renderLogo } from '../../../../../utils';

export const ModalDetailPortFolio = (props: IPropsDetailPortfolio) => {
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage('completed_account') || '{}')
  );

  const hqName = (id: number) => {
    const foundLocation = dataCompleted?.country?.find(
      (el: { id: number; country_name: string }) =>
        el.id?.toString() === id?.toString()
    );
    if (foundLocation) {
      return foundLocation?.country_name;
    } else {
      return '';
    }
  };

  const targetUrl = (url: string) => {
    props?.clauseDetailPortFolio();
    window.open(url, '_blank');
  };
  return (
    <>
      <Modal
        show={props.showModalDetail}
        className='createCompanypopup large forStep5custom bgGris'
      >
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Portfolio</h2>
            <Link
              to='#'
              className='close'
              onClick={() => props?.clauseDetailPortFolio()}
            >
              <span className='icon-close-pop'></span>
            </Link>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='contentBorder row mx-auto modalInvestor'>
            <div className='logoDetails'>
              <label htmlFor='' title='Your logo'>
                Logo
              </label>
              {/* <img src={imgLogo} alt='' className='mb-5' /> */}
              {(props.selectedItem?.logo || props.selectedItem?.url) && (
                <img
                  src={
                    props.selectedItem?.logo
                      ? fileUrl(props.selectedItem?.logo)
                      : renderLogo(props.selectedItem?.url)
                  }
                  alt=''
                  // onClick={() => showWebPage(props?.initialPortFolio, index)}
                />
              )}
            </div>
            <div>
              <ul className='investorInfoList more findOut'>
                <li>
                  <label>Company name</label>
                  {props.selectedItem?.name}
                  <label className='wsite'>Website</label>
                  <span
                    className='sit'
                    onClick={() => targetUrl(props.selectedItem?.url)}
                  >
                    {' '}
                    {props.selectedItem?.url}
                  </span>
                </li>
                {hqName(props.selectedItem?.hqLocation) && (
                  <li>
                    <label>HQ Location</label>{' '}
                    {hqName(props.selectedItem?.hqLocation)}
                  </li>
                )}

                {/* {props.selectedItem?.industry.length > 0 && (
                  <li className='d-flex ind'>
                    <label>Solution(s)</label>
                    <div>{props.selectedItem?.industry?.map((el, index) => (
                      <span className='topic' key={index}>
                        {el.name}
                      </span>
                    ))}
                    </div>
                  </li>
                )} */}

                {props.selectedItem?.solution.length > 0 && (
                  <li className='d-flex ind'>
                    <label>Solution(s)</label>
                    <div>{props.selectedItem?.solution?.map((el, index) => (
                      <span className='topic' key={index}>
                        {el.name}
                      </span>
                    ))}
                    </div>
                  </li>
                )}

                {/* {props.selectedItem?.solution.length > 0 && (
                  <li className='d-flex ind'>
                    <label>Industry(ies)</label>
                    <div>{props.selectedItem?.solution?.map((el, index) => (
                      <span className='topic' key={index}>
                        {el.name}
                      </span>
                    ))}
                    </div>
                  </li>
                )} */}

                {props.selectedItem?.industry.length > 0 && (
                  <li className='d-flex ind'>
                    <label>Industry(ies)</label>
                    <div>{props.selectedItem?.industry?.map((el, index) => (
                      <span className='topic' key={index}>
                        {el.name}
                      </span>
                    ))}
                    </div>
                  </li>
                )}

              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
