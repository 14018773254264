import React, { useState } from "react";
import { IPropsAbout } from "../type";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";

export default function BlockGeographyTarget(props: IPropsAbout) {
  const [activeId, setActiveId] = useState<any>("");
  const [expendAll, setExpendAll] = useState<boolean>(false);

  const flatArrayWithSameId = (data: any): Array<any> => {
    const arrayHashmap = data?.reduce((obj: any, item: any) => {
      obj[item.idContinent]
        ? obj[item.idContinent].push(item)
        : (obj[item.idContinent] = [item]);
      return obj;
    }, {});
    const mergedArray = Object.values(arrayHashmap);
    return mergedArray;
  };

  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }

  return (
    <div className="col-lg-4 col-12 contactInfo mt-4">
      <div className="boxEditProfil">
        <a
          className="editItem"
          onClick={() => props?.dataSource?.setGeographyShow(true)}
        >
          Edit
          <span className="icon-edit-full"></span>
        </a>
        <h3 className="title">Geographic target market</h3>
        <Accordion defaultActiveKey={activeId}>
          {flatArrayWithSameId(
            props?.dataSource?.companyResellerState?.targetedCountry
          )?.map((items: any, index: number) => {
            return (
              <div
                className={
                  activeId === index ? "panel-wrap active-panel" : "panel-wrap"
                }
                key={index}
              >
                <div className="panel-header geo">
                  <Accordion.Toggle
                    onClick={() => toggleActive(index)}
                    className={"panel-toggle"}
                    eventKey={index.toString()}
                  >
                    {items && items[0].nameContinent}
                    {`(${items?.length})`}{" "}
                    {items && items[0].idContinent == "6"}
                  </Accordion.Toggle>
                </div>
                <Accordion.Collapse
                  eventKey={index.toString()}
                  className={expendAll ? "show" : ""}
                >
                  <div className="panel-body">
                    {items &&
                      items?.map((pays: any, index: number) => {
                        return (
                          <Form.Check
                            key={index}
                            custom
                            disabled
                            label={pays.countryName}
                            id={pays.id}
                          />
                        );
                      })}
                  </div>
                </Accordion.Collapse>
              </div>
            );
          })}
          <a href="javascript:;" onClick={() => setExpendAll(!expendAll)}>
            {!expendAll ? "Expand all" : "Collapse content"}
          </a>
        </Accordion>
      </div>
    </div>
  );
}
