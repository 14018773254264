import Modal from "react-bootstrap/Modal";
import React from "react";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { IPropsProgramDetails } from "../../../../../controller/oem-ctr/type";
import { regexValidation } from "../../../../../utils";

export default function OemProgramDetail(props: IPropsProgramDetails) {
  return (
    <Modal
      show={props.modal}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Program details</h2>
          <a className="close" onClick={props.closeModal}>
            <span className="icon-close-pop" />
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row p-5">
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label htmlFor="company required">
              Company name
              {props.errors["companyName"] && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </label>
            <input
              type="text"
              className="input-text form-control"
              defaultValue={props?.defaultValue?.companyName}
              {...props.register("companyName", { required: true })}
            />
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label htmlFor="jobtitle required">
              Website
              {props.errors["website"]?.type === "required" && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              {props.errors["website"]?.type === "pattern" && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">
                    Begin with http:// or https:// or www
                  </span>
                </div>
              )}
            </label>
            <input
              type="text"
              className="input-text form-control"
              placeholder={"Begin with http:// or https:// or www"}
              defaultValue={props?.defaultValue?.website}
              {...props.register("website", {
                required: true,
                pattern: regexValidation.url,
              })}
            />
          </div>
          <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label htmlFor="hqlocation">
              HQ location
              {props.errors["country"] && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </label>
            <select
              className="custom-select"
              {...props.register("country", { required: true })}
              defaultValue={props?.defaultValue?.country || 0}
            >
              <option value="">Country...</option>
              {props.dataCompleted?.country?.map((el: any) => (
                <option value={el.id} key={el.id}>
                  {el.country_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
            <label className="text-white">City</label>
            <input
              type="text"
              className="input-text form-control"
              placeholder={"City..."}
              defaultValue={props?.defaultValue?.city}
              {...props.register("city")}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right forPitch">
          <button
            className="btn btn-devinsider cancel"
            onClick={props.closeModal}
            type="button"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-devinsider px-5"
            onClick={props.handleSubmit(props.onSubmit)}
          >
            {props.loading ? <Loader /> : "Save"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
