import React from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { IPropsModalStrategic } from "../types";

export default function EditStrategicPartener(props: IPropsModalStrategic) {
  return (
    <Modal
      show={props.showModalStrategic}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title"> Edit strategic partnerships</h2>
          <a className="close" onClick={() => props?.closeEditStrategic()}>
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row mx-auto modalInvestor">
        <div className="form-group col-12">
        <ul className="listSquareItem strategic">
          <li onClick={() => props?.clickStrategic(1)}>
            <a
              className={
                props?.listStrategyId?.find((e) => e.id == 1) ? "active" : ""
              }
            >
              <span className="icon-strategic-alliance"></span>
              strategic alliance
            </a>
          </li>
          <li onClick={() => props?.clickStrategic(2)}>
            <a
              className={
                props?.listStrategyId?.find((e) => e.id == 2) ? "active" : ""
              }
            >
              <span className="icon-mergers-acquisitions"></span>
              Mergers & acquisitions
            </a>
          </li>
          <li onClick={() => props?.clickStrategic(3)}>
            <a
              className={
                props?.listStrategyId?.find((e) => e.id == 3) ? "active" : ""
              }
            >
              <span className="icon-stock-buybacks"></span>
              Stock buybacks
            </a>
          </li>
          <li onClick={() => props?.clickStrategic(4)}>
            <a
              className={
                props?.listStrategyId?.find((e) => e.id == 4) ? "active" : ""
              }
            >
              <span className="icon-IP-sales"></span>
              IP sale
            </a>
          </li>
        </ul>
        </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right forPitch">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props?.closeEditStrategic()}
            type="button"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-devinsider px-5"
            onClick={() => props?.saveStrategic()}
          >
            {props?.loader ? <Loader /> : "Save"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
