import { dataWithToken, postPhoto } from "../api";
import { url } from "../constant";

export const updateInfoReseller = async (
    id : any,
    data: any,
    token: string
  ): Promise<any> => {
    let params: dataWithToken = {
      url: url.reseller.update_reseller + "" + id,
      data: data,
    };
    let response = await postPhoto(params, token);
    return response;
  };