import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useEventListener from "../../../hooks/event-handler";
import { useDataContext } from "../../../context/index";
import { useCompleteDataStorageOem } from "./use-complete-data-storage-oem";

export default function UseCompleteProfilPicture() {
  const history = useHistory();
  let params: any = useLocation();
  const { setFilePictureOem, filePictureOem } = useDataContext();
  const { dataOem, addDataOem } = useCompleteDataStorageOem();
  const [fileSizeError, setFileSizeError] = useState(false);

  const [selectedFile, setSelectedFile] = useState<File>(filePictureOem);
  const [lastClicked, setLastClicked] = useState<File>();

  const [isFilePicked, setIsFilePicked] = useState(
    filePictureOem ? true : false
  );
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const fileRef: React.RefObject<HTMLInputElement> = useRef(null);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      if (event?.target?.files[0]) {
        if(event?.target?.files[0].size > 2 * 1024 * 1024) {
          setFileSizeError(true);
        } else {
          setFileSizeError(false);
          setSelectedFile(event?.target?.files[0]);
          setIsFilePicked(true);
        }
      }
    }
  };

  const handleBtn = () => {
    if (fileRef) {
      fileRef?.current?.click();
    }
    setFileSizeError(false);
  };

  const validateIsvInscription = () => {
    
    setFilePictureOem(selectedFile);
    history.push("./complete-website-location-oem");

  };

  const skipvalidateIsvInscription = () => {
    setFilePictureOem(selectedFile);
    history.push("./complete-website-location-oem");
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      validateIsvInscription();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    isFilePicked,
    selectedFile,
    fileRef,
    isLoaderShow,
    dataOem,
    error,
    params,
    history,
    skipvalidateIsvInscription,
    setError,
    setIsLoaderShow,
    changeHandler,
    handleBtn,
    validateIsvInscription,
    lastClicked,
    fileSizeError,
    setFileSizeError,
  };
}
