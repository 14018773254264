import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { useForm, SubmitHandler } from "react-hook-form";
import { postInfoEom } from "../../../service/applicatif/acount";
import { toast } from "react-toastify";

export type formDataQuestions = {
  firstName: string;
  lastName: string;
  region: string;
  city: string;
  companyName: string;
  jobTitle: string;
  email: string;
  phone: string;
  acceptCgu: boolean;
};
export default function UseGetStartedInvestor() {
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const history = useHistory();
  const userState = useSelector((state: rootState) => state.userReducer);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<formDataQuestions>({ mode: "onSubmit" });

  const onSub: SubmitHandler<formDataQuestions> = async (data) => {
    const params = {
      firstname: data.firstName,
      lastname: data.lastName,
      jobtitle: data.jobTitle,
      company: data.companyName,
      country: data.region,
      email: data.email,
      phone: data.phone,
      type: "investor_get_started",
      information: true,
      message
    };
    setLoader(true);
    let response = await postInfoEom(params);
    if (response && response.status == 200) {
      toast("Support contacted successfully");
      history.push("/complete-info-investor");
    } else {
      toast("An error occurred.");
    }
    setLoader(false);
  };

  const createAccountInvestor = () => {
    history.push("/complete-info-investor");
  };

  // redirect to basic account creation
  const goToGetStarted = () => {
    history.push("/account-choices");
  };

  // redirect to isv account creation
  const goToIsvCreation = () => {
    history.push('/complete-info');
  }

  // redirect to community home page
  const goToCommunity = () =>{
    history.push('/community/home');
  }

  //redirect to browse program
  const goToBrowseProgram = () =>{
    history.push('/programs/home');
  }

   //redirect to browse program
   const goToFindInvestor = () =>{
    history.push('/signin');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    userState,
    dataCompleted,
    loader,
    errors,
    createAccountInvestor,
    register,
    handleSubmit,
    onSub,
    goToGetStarted,
    goToIsvCreation,
    goToCommunity,
    goToBrowseProgram,
    goToFindInvestor,
    message,
    setMessage
  };
}
