/**
 ***********************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { useState, useEffect, useRef, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { getCompletedAcccount } from './service/applicatif/acount';
// @ts-ignore
import { EventSourcePolyfill } from 'event-source-polyfill';
// @ts-ignore
import sound from './resources/images/notif_message.wav';

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import './App.css';
import './style.scss';
import PrivateRoute from './hooks/private-route';
import NotAllowedConnectedPage from './hooks/notAllowedConnectedPage';

import store, { persistor } from './redux/store';
import routes from './routes/index';
import { DataContext } from './context';
import { baseUrlMercure } from './service/constant';
import { Imessage, ItemDiscussion } from './controller/tchat-ctr/type';
import { topicTypeStream } from './utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingAllPage } from './screen/widget/loader';

function App() {
  const [filePictureReseller, setFilePictureReseller] = useState<File>();
  const [fileLogoreReseller, setFileLogoReseller] = useState<File>();
  const [filePictureOem, setFilePictureOem] = useState<File>();
  //all topics need
  const [newConversation, setNewConversation] = useState<ItemDiscussion>();
  const [newStreamMessage, setNewMessage] = useState<Imessage>();
  const [newParticipants, setNewParticipants] = useState<any>();
  const [appLoaded, setAppLoaded] = useState<boolean>(false);
  const [geoRestricted, setGeoRestricted] = useState<boolean>();
  const [refreshMessageNotif, setRefreshMessageNotif] =
    useState<boolean>(false);
  const [newFilesInConversation, setNewFilesInConversation] = useState<{
    file: any;
    conversation_id: number;
  }>();

  const handleConversation = (conv: any | null) => {
    setNewConversation(conv);
  };

  const handleMessage = (message: any | null) => {
    setNewMessage(message);
    setRefreshMessageNotif(true);
  };

  const handleNewParticipantOem = (representatif: any) => {
    setNewParticipants(representatif);
  };

  const handleNewFiles = (p: { file: any; conversation_id: number }) => {
    setNewFilesInConversation(p);
  };

  var evtSource: any = null;

  const refAudio: any = useRef();

  const getData = async () => {
    let result: any = await getCompletedAcccount();
    if (result && result?.data) {
      sessionStorage.setItem('completed_account', JSON.stringify(result.data));
    }

    return result;
  };

  useEffect(() => {
    getData()
      .then(() => setGeoRestricted(false))
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 403 &&
            error.response.data === 'Geo restricted'
          ) {
            setGeoRestricted(true);
          } else {
            setGeoRestricted(false);
          }
        } else {
          setGeoRestricted(false);
        }
      })
      .finally(() => {
        setAppLoaded(true);
      });

    return () => {};
  }, []);

  const startEvtSource = () => {
    let objectUrl = new URL(baseUrlMercure);

    const allTopics = store?.getState().userReducer?.token_notification || [];

    for (const topic of allTopics) {
      objectUrl.searchParams.append('topic', topic);
    }

    evtSource = new EventSourcePolyfill(objectUrl, {
      headers: {
        Authorization: 'Bearer ' + store?.getState().userReducer?.token_mercure,
      },
    });

    evtSource.onmessage = (event: { data: string }) => {
      const pathName = window?.location?.pathname;

      const isInMessageScreen = pathName === '/contact/discussion';

      let stream: { payload: any; type: string; conversation_id: number } =
        JSON.parse(event?.data);

      if (
        stream?.type === topicTypeStream?.newConversation &&
        isInMessageScreen
      ) {
        let dataTopics = JSON.parse(stream.payload);
        let sender = dataTopics?.last_message?.[0].sender?.id;
        if (
          dataTopics?.is_muted === false &&
          sender !== store?.getState().userReducer?.id
        ) {
          startAudio();
        }
        handleConversation(dataTopics || null);
      }

      console.log('topicTypeStream', topicTypeStream, stream);

      if (stream?.payload) {
        if (stream?.type === topicTypeStream.newMessage && isInMessageScreen) {
          handleMessage(JSON.parse(stream.payload) || null);
        }

        if (
          stream?.type === topicTypeStream.add_particapant_oem &&
          isInMessageScreen
        ) {
          handleNewParticipantOem(JSON.parse(stream.payload) || null);
        }
        if (
          stream?.type ===
            topicTypeStream?.new_file_add_in_current_conversation &&
          isInMessageScreen
        ) {
          let file = JSON.parse(stream.payload) || [];
          let idConv = stream?.conversation_id || 0;
          handleNewFiles({ file, conversation_id: idConv });
        }
      }
    };

    evtSource.onopen = () => {
      let hasToken = store?.getState().userReducer.token;
      if (hasToken === '' || !hasToken) {
        evtSource.close();
      }
    };

    evtSource.onerror = () => {
      let hasToken = store?.getState()?.userReducer?.token;
      if (hasToken === '' || !hasToken) {
        evtSource.close();
      }
    };
  };

  const stopEvtSource = () => {
    if (evtSource) {
      evtSource && evtSource.close();
    }
  };

  const startAudio = () => {
    if (refAudio.current) {
      refAudio.current.volume = 0.2;
      const playPromise = refAudio?.current?.play();
      if (playPromise !== undefined) {
        playPromise.then((_: any) => {}).catch(() => {});
      }
    }
  };

  useEffect(() => {
    if (store.getState().userReducer?.token && evtSource === null) {
      startEvtSource();
    }
    return () => {};
  }, []);

  return (
    <>
      {!appLoaded && <LoadingAllPage />}
      {appLoaded && !geoRestricted && (
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ToastContainer autoClose={2000} />
            <DataContext.Provider
              value={{
                filePictureReseller: filePictureReseller,
                fileLogoreReseller: fileLogoreReseller,
                filePictureOem: filePictureOem,
                name: '',
                email: '',
                setFilePictureReseller,
                setFileLogoReseller,
                setFilePictureOem,
                newStreamConv: newConversation,
                handleConversation,
                setRefreshMessageNotif,
                refreshMessageNotif,
                newStreamMessage,
                handleMessage,
                handleNewParticipantOem,
                newParticipants,
                handleNewFiles,
                newFilesInConversation,
                stopEvtSource: stopEvtSource,
                startEvtSource: startEvtSource,
                evtSource: evtSource,
              }}
            >
              <div style={{ display: 'none' }}>
                <audio ref={refAudio} src={sound} />
              </div>

              <Router>
                <Switch>
                  {routes?.map((route) =>
                    route.guard === 'public' ? (
                      <Route
                        key={route.path}
                        path={route.path}
                        component={route.component}
                        exact={route.exact}
                      />
                    ) : route.guard === 'not-logged-page' ? (
                      <NotAllowedConnectedPage
                        key={route.path}
                        path={route.path}
                        component={route.component}
                        exact={route.exact}
                      />
                    ) : (
                      <PrivateRoute
                        key={route.path}
                        path={route.path}
                        component={route.component}
                        exact={route.exact}
                        allowedRole={route.allowedRole}
                      />
                    )
                  )}
                </Switch>
              </Router>
            </DataContext.Provider>
          </PersistGate>
        </Provider>
      )}
      {appLoaded && geoRestricted && (
        <>
          <h1>Forbidden</h1>
          This site is not available in your country.
        </>
      )}
    </>
  );
}

export default App;
//https://medium.com/jspoint/typescript-interfaces-4a2af07c8070
