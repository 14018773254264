import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { rootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  getDiscutionCategorie,
  restoreDiscussions,
} from "../../../redux/actions/community-action";
import { ItemDiscussion } from "../../../redux/types/community-interface";
import UseSearch from "../../../screen/widget/community/search/use-search";

export default function UseFilterOtherCategorie() {
  const history = useHistory();
  const params: any = useParams();
  const location: any = useLocation();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const userReducer = useSelector(
    (state: rootState) => state.userReducer
  );
  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();
  const [titleCategories, setTitleCategories] = useState<string>(
    location?.state?.categorie?.title
  );

  const [lastIdCategorie, setlastIdCategorie] = useState<number>(params?.id);

  const [limitDiscution, setlimitDiscution] = useState<number>(10);

  const [sortedBy, setsortedBy] = useState<
    "post_date" | "first_activity" | "last_activity" | "replies" | "views"
  >("post_date");

  const [currentDiscutionPage, setCurrentDiscutionPage] = useState<number>(1);

  const [orderBy, setOrderBy] = useState("DESC");

  const [loader, setLoader] = useState<boolean>(false);

  const dispatch = useDispatch();

  const goDiscussionCategorie = () => {
    history?.push("/community/discussions-categories");
  };

  const goHome = () => {
    history?.push("/community/home");
  };

  const goActivity = (id: string) => {
    history.push("/community/activity-user/" + id);
  };

  const cb = () => {};

  const getNewDiscutionAfterUpdate = (
    key: string,
    value: any,
    isRestorePaginate: boolean = false
  ) => {
    let checkIfResetCurrentPage = currentDiscutionPage;
    if (isRestorePaginate) {
      setCurrentDiscutionPage(1);
    }
    let data = {
      categories: params?.id,
      views: "",
      labels: "",
      postTypes: "",
      limit: limitDiscution,
      page: checkIfResetCurrentPage,
      sortBy: sortedBy,
      orderBy: orderBy,
      notIdlabels: "",
      [key]: value,
    };
    dispatch(getDiscutionCategorie(data, cb));
  };

  useEffect(() => {
    let paramsDiscution = {
      categories: lastIdCategorie,
      views: "",
      labels: "",
      postTypes: "",
      limit: limitDiscution,
      page: currentDiscutionPage,
      sortBy: sortedBy,
      orderBy: "DESC",
      notIdlabels: "",
    };
    /**
     * if current page is inferieur to state current page , get new topLabel
     */

    let discussions = communityReducer?.paginate_discussions;
    let checkPageStateInstore = discussions?.currentPage < currentDiscutionPage;

    if (checkPageStateInstore) {
      dispatch(getDiscutionCategorie(paramsDiscution, cb));
    }
    return () => {
      //remove top label when leave page
      dispatch(restoreDiscussions(() => {}));
    };
  }, []);

  const cbChangeCategorie = (data: any) => {
    setlastIdCategorie(params?.id);
    setLoader(false);
  };

  const getNewDiscution = () => {
    let data = {
      categories: params?.id,
      views: "",
      labels: "",
      postTypes: "",
      limit: limitDiscution,
      page: currentDiscutionPage,
      sortBy: sortedBy,
      orderBy: "DESC",
      notIdlabels: "",
    };
    dispatch(getDiscutionCategorie(data, cbChangeCategorie));
  };

  /**
   *
   * Remove discution when categorie page change
   */
  useEffect(() => {
    if (lastIdCategorie != params?.id) {
      setCurrentDiscutionPage(1);
      dispatch(restoreDiscussions(() => getNewDiscution()));
    }
    return () => {};
  }, [params?.id]);

  /**
   * Change limite page
   */
  const setPaginateDataFilter = (p: number) => {
    setlimitDiscution(p);
    setLoader(true);
    dispatch(
      restoreDiscussions(() => getNewDiscutionAfterUpdate("limit", p, true))
    );
  };

  /**
   *
   * Update sorted date
   */
  const setSortedate = (sorted: any) => {
    setsortedBy(sorted);
    setLoader(true);
    dispatch(
      restoreDiscussions(() =>
        getNewDiscutionAfterUpdate("sortBy", sorted, true)
      )
    );
  };

  /**
   *
   * Click next page
   */
  const handlePageClick = (data: any) => {
    setCurrentDiscutionPage(data);
    setLoader(true);
    dispatch(
      restoreDiscussions(() => getNewDiscutionAfterUpdate("page", data))
    );
  };

  const goToreplayPost = (currentPost: ItemDiscussion) => {
    history.push("/community/reply-post/" + params?.id + "/" + currentPost?.id);
  };

  return {
    goDiscussionCategorie,
    goHome,
    goActivity,
    titleCategories,
    limitDiscution,
    setPaginateDataFilter,
    sortedBy,
    setSortedate,
    communityReducer,
    currentDiscutionPage,
    handlePageClick,
    goToreplayPost,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    userReducer,
  };
}
