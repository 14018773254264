import React from "react";
import Header from "../../widget";
import UseClassifiedAdsResearch from "../../../controller/classified-ads-ctr/recherche-ctr";
import { IAd } from "../../../redux/types/classified-ads-interface";
import AdsItem from "../../widget/ads";
import { LoaderCommon } from "../../widget/loader";
import Footer from "../../widget/footer/footer";

export default function ClassifiedAdsResult() {
  const providerSearch = UseClassifiedAdsResearch();

  return (
    <div>
      <Header
        searchText={providerSearch?.searchText}
        handleChange={providerSearch?.handleChange}
        handleClick={providerSearch?.handleClick}
      />
      <section className="classified">
        <div className="freshfinds">
          <h2 className="title">Results on : Classified ads </h2>
          {providerSearch?.loader && providerSearch?.page === 1 ? (
            <LoaderCommon />
          ) : (
            <div className="listFresh">
              {providerSearch?.ads?.map((el: IAd, key: number) => (
                <AdsItem
                  key={key}
                  ads={el}
                  setShowLogin={providerSearch?.setShowLogin}
                  goToCategory={(idCategory: string) =>
                    providerSearch?.goToCategoryPage(idCategory)
                  }
                />
              ))}
              {providerSearch?.ads.length < providerSearch?.total && (
                <div className="linkView">
                  {providerSearch?.loader ? (
                    <LoaderCommon />
                  ) : (
                    <a
                      href="javascript:;"
                      className={`viewAll ${
                        providerSearch?.loader && `disabled`
                      }`}
                      onClick={providerSearch?.handleViewMore}
                    >
                      {providerSearch?.loader ? <LoaderCommon /> : "View more"}
                    </a>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}
