import React from "react";
import { makeClass, paginate } from "../../../../../../utils/index";
import { LoadingLabel } from "../../../../../widget/loader";

export type Iprops = {
  labels: Array<{
    label: string;
    number_post: number;
    label_id: number;
  }>;
  limitTopLabel: number;
  currentPage: number;
  allSelectedLabel: Array<any>;
  allNotSelectedLabel: Array<any>;
  setLabelsFilter: (id: any) => void;
  totalLabel: number;
  clikPrev: (p: any) => void;
  nextPage: (p: any) => void;
  offSet: number;
  loaderTopLabel: boolean;
  useSelected: string;
};
export default function topLabel(props: Iprops) {
  return (
    <div className="block">
      <h2 className="title">Top labels</h2>

      {props?.loaderTopLabel ? (
        <LoadingLabel />
      ) : (
        <ul className="labelList">
          {paginate(
            props?.labels,
            props?.limitTopLabel,
            props?.currentPage
          )?.map(
            (
              el: {
                label: string;
                number_post: number;
                label_id: number;
              },
              index: number
            ) => (
              <li
                key={index}
                className={makeClass(
                  props?.allSelectedLabel,
                  props.allNotSelectedLabel,
                  el?.label_id,
                  props.useSelected
                )}
                onClick={() => props?.setLabelsFilter(el?.label_id)}
              >
                <span className="nameOfCategory">{el?.label}</span>
                <span>{el?.number_post}</span>
              </li>
            )
          )}

          {props?.totalLabel > props?.limitTopLabel && (
            <li className="linkForLi nextPrev">
              <a
                className={props?.currentPage === 1 ? "inactive" : ""}
                onClick={() =>
                  props?.currentPage !== 1 &&
                  props?.clikPrev(props?.currentPage)
                }
              >
                Previous
              </a>
              <span>
                {props?.currentPage} of {props?.offSet}
              </span>
              <a
                className={
                  props?.currentPage === props?.offSet ? "inactive" : ""
                }
                onClick={() =>
                  props?.currentPage !== props?.offSet &&
                  props?.nextPage(props?.currentPage + 1)
                }
              >
                Next
              </a>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}
