import React, { ChangeEvent } from "react";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import UseCompletePublishIvestor from "../../../../../controller/account-ctr/complete-create-account-investor/use-complete-publish-ivestor";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import useGoBack from "../../../../../hooks/use-navigate";

export default function CompletePublishInvestor() {
  const { wantToShow, loader, setWantToShow, valideAcount } =
    UseCompletePublishIvestor();
  const { goBack } = useGoBack();
  return (
    <div>
      <Header />
      <div className="getstartedContainer ">
        <div className="head-getstarted">
          <a onClick={goBack} className="link-back">
            <span className="icon-back"></span>
          </a>
          <h1>Create your Investor page</h1>
          <ul className="stepInvestor">
            <li className="stepInprogress done ">
              <div className="cttSpan">
                <span className="icon-done"></span>
              </div>
              Investor page
            </li>
            <li className="stepInprogress done">
              <div className="cttSpan">
                <span className="icon-done"></span>
              </div>
              ISV target
            </li>
            <li className="stepInprogress done here">
              <div className="cttSpan">
                <span className="icon-inprogress"></span>
              </div>
              ISV outreach
            </li>
          </ul>
        </div>
        <div className="contentBorder border-top row px-0">
          <label htmlFor="" className="pb-5 col-12 px-0 fontNormal">
            Choose how you want to publish your Investor page. <br/>

            These settings will be applied when matchmaking features will be launched in July 2023 and can be changed at any time.

          </label>
          <div
            className="custom-control custom-radio col-12 lastStepInvestorRadio"
            onClick={() => {
              setWantToShow(true);
            }}
          >
            <input
              type="radio"
              className="custom-control-input"
              checked={wantToShow}
              onChange={() => null}
            />
            <label className="custom-control-label" htmlFor="publish1">
              I want ISVs to find me and receive inbound inqueries.
              <br />
              <span className="fontNormal">
                Your investor page will be listed in the matchmaking and can be
                viewed by ISV members.
                <br />
                Only relevant ISVs that closely match with your defined criteria
                are able to contact you.
              </span>
            </label>
          </div>

          <div
            onClick={() => {
              setWantToShow(false);
            }}
            className="custom-control custom-radio col-12 lastStepInvestorRadio"
          >
            <input
              type="radio"
              className="custom-control-input"
              checked={!wantToShow}
              onChange={() => null}
            />
            <label className="custom-control-label" htmlFor="publish2">
              I want to remain invisible and don't want ISVs to find me.
              <br />
              <span className="fontNormal">
                Your investor page will not be visible to ISV members.
              </span>
            </label>
          </div>
        </div>
        <div className="contentBorder border-top row align-items-stretch">
          <button
            className="btn btn-devinsider large"
            onClick={() => valideAcount()}
            disabled={loader ? true : false}
          >
            {loader ? <Loader /> : "Done"}
          </button>
        </div>
      </div>
    </div>
  );
}
