import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { concatString, isURL, refaCtoNullKey } from "../../../../../utils";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { GUEST } from "../../../../../service/constant";
import UseEditProfilCtr from "../../../../../controller/profil-ctr/use-edit-profil-ctr";

// export type IpropsModal = {
//   dataSource: any;
// };

export type IpropsModal = {
  showEditModal: boolean;
  cancelChange: () => void;
  isLoaderShow: boolean;
  onSub: any;
};

export default function ModalEditInfoUser(props: IpropsModal) {
  const { userState, handleSubmit, errors, register, dataCompleted } =
    UseEditProfilCtr();

  const role = userState?.roles?.filter(
    (role: string) => role !== "ROLE_USER"
  )[0];
  return (
    <Modal
      show={props?.showEditModal}
      onHide={() => props?.cancelChange()}
      className="modalDevinsider medium"
    >
      <form onSubmit={handleSubmit(props?.onSub)}>
        <Modal.Header closeButton>
          <Modal.Title>User details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0">
            <div className="col-12 p-0 form-group required">
              <label htmlFor="first-name">First name</label>
              {errors.firstName && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}

              <input
                type="text"
                className="input-text form-control"
                {...register("firstName", {
                  required: true,
                })}
              />
            </div>
            <div className="col-12 p-0 form-group required">
              <label htmlFor="last-name">Last name</label>
              {errors.lastName && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <input
                type="text"
                className="input-text form-control"
                {...register("lastName", {
                  required: true,
                })}
              />
            </div>
            <div className="col-6 form-group required p-0 pr-1">
              <label htmlFor="location">Location</label>
              {errors.location && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <select
                className="custom-select"
                {...register("location", {
                  required: true,
                })}
              >
                {dataCompleted?.country?.map((element: any, index: number) => (
                  <option
                    key={index}
                    value={element?.country_name}
                    selected={
                      element?.country_name == userState?.country?.country_name
                    }
                  >
                    {`${element?.country_name}`}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6 form-group p-0 pl-1">
              <label htmlFor="first-name">&nbsp;</label>
              <input
                type="text"
                className="input-text form-control"
                placeholder="City"
                {...register("city")}
              />
            </div>
            {role !== GUEST && (
              <div className="col-12 p-0 form-group required">
                <label htmlFor="company">Company name</label>
                {errors.companyName && (
                  <div className={`container-error pl-2 "d-inline`}>
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                <input
                  type="text"
                  className="input-text form-control"
                  {...register("companyName", {
                    required: role !== GUEST ? true : false,
                  })}
                />
              </div>
            )}

            <div className="col-12 p-0 form-group required">
              <label htmlFor="company">Job title</label>
              {errors.jobTitle && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <input
                type="text"
                className="input-text form-control"
                {...register("jobTitle", {
                  required: true,
                })}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancelbtn" onClick={() => props?.cancelChange()}>
            Cancel
          </Button>
          <Button
            className="validate"
            type="submit"
            disabled={props?.isLoaderShow}
          >
            {props?.isLoaderShow ? <Loader /> : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
