import { ICommunity, IAction } from '../../types/community-interface';
import { constante } from '../../actions/community-action/index';
import { initialState } from './initialState';
import {
  followCategory,
  helperFindReply,
  helperLatest,
  helperSetChildPost,
  helperSetDeepChild,
  helperMarkBestAnswer,
  helperRemovePost,
  helperCheckStateFollow,
  helperUpdateReply,
} from './helper-reducers';

export const communityReducer = (
  state = initialState,
  action: IAction
): ICommunity => {
  switch (action.type) {
    case constante?.GET_FEATURED_LABEL:
      return {
        ...state,
        featuredLabel: action?.payload?.featured_label,
        discussionCategories: action?.payload?.discussion_categories,
        category_about_community: action?.payload?.category_about_community,
        postType: action?.payload?.post_type,
      };

    case constante?.GET_LATEST_POST:
      return {
        ...state,
        latestPost: helperLatest(action?.payload),
      };

    case constante?.GET_TOP_LABEL:
      return {
        ...state,
        [action?.payload?.postTypes?.toString() === "2"
          ? "topLabelArticle"
          : action?.payload?.postTypes?.toString() === "3"
          ? "topTags"
          : action?.payload?.postTypes?.toString() === "4"
          ? "topOrganizations"
          : "topLabel"]: {
          labels:
            action?.payload?.postTypes?.toString() === "2"
              ? [
                  ...state?.topLabelArticle?.labels,
                  ...action?.payload?.data?.details,
                ]
              : action?.payload?.postTypes?.toString() === "3"
              ? [...state?.topTags?.labels, ...action?.payload?.data?.details]
              : action?.payload?.postTypes?.toString() === "4"
              ? [
                  ...state?.topOrganizations?.labels,
                  ...action?.payload?.data?.details,
                ]
              : [...state?.topLabel?.labels, ...action?.payload?.data?.details],

          total: action?.payload?.data?.total,
          currentPage: action?.payload?.numberPage,
          totaldataFetch:
            action?.payload?.numberPage *
            action?.payload?.data?.details?.length,
        },
      };

    case constante?.RESTORE_TOP_LABEL:
      return {
        ...state,
        topLabel: {
          labels: [],
          total: 0,
          currentPage: 0,
          totaldataFetch: 0,
        },
        topLabelArticle: {
          labels: [],
          total: 0,
          currentPage: 0,
          totaldataFetch: 0,
        },
        topTags: {
          labels: [],
          total: 0,
          currentPage: 0,
          totaldataFetch: 0,
        },
        topOrganizations: {
          labels: [],
          total: 0,
          currentPage: 0,
          totaldataFetch: 0,
        },
      };

    case constante?.GET_DISCUSSION:
      return {
        ...state,
        [action?.payload?.postTypes?.toString() === "1" ||
        action?.payload?.postTypes?.toString() === "1,2"
          ? "paginate_discussions"
          : action?.payload?.postTypes?.toString() === "2"
          ? "paginate_article"
          : action?.payload?.postTypes?.toString() === "3"
          ? "paginate_press_release"
          : action?.payload?.postTypes?.toString() === "4"
          ? "paginate_program_review"
          : "paginate_about_community"]: {
          discussions:
            action?.payload?.postTypes?.toString() === "2"
              ? [
                  ...state?.paginate_article?.discussions,
                  ...action?.payload?.discussions,
                ]
              : action?.payload?.postTypes?.toString() === "3"
              ? [
                  ...state?.paginate_press_release?.discussions,
                  ...action?.payload?.discussions,
                ]
              : action?.payload?.postTypes?.toString() === "4"
              ? [
                  ...state?.paginate_program_review?.discussions,
                  ...action?.payload?.discussions,
                ]
              : [
                  ...state?.paginate_about_community?.discussions,
                  ...action?.payload?.discussions,
                ],
          total: action?.payload?.total,
          currentPage: action?.payload?.numberPage,
          totaldataFetch:
            action?.payload?.numberPage * action?.payload?.discussions?.length,
        },
        successFetchPaginate: true,
      };

    case constante?.RESTORE_DISCUSSIONS:
      return {
        ...state,
        paginate_discussions: {
          discussions: [],
          total: 0,
          currentPage: 0,
          totaldataFetch: 0,
        },
        paginate_article: {
          discussions: [],
          total: 0,
          currentPage: 0,
          totaldataFetch: 0,
        },
        paginate_press_release: {
          discussions: [],
          total: 0,
          currentPage: 0,
          totaldataFetch: 0,
        },
        paginate_program_review: {
          discussions: [],
          total: 0,
          currentPage: 0,
          totaldataFetch: 0,
        },
        paginate_about_community: {
          discussions: [],
          total: 0,
          currentPage: 0,
          totaldataFetch: 0,
        },
        successFetchPaginate: false,
      };
    case constante.GET_DETAILS_DISCUTION_CATEGORIES:
      return {
        ...state,
        detailsDiscutionCategorie: action.payload,
      };
    case constante.GET_DETAILS_ARTICLE:
      return {
        ...state,
        detailsArticle: action.payload,
      };

    case constante?.GET_RECENT_DISCUSSION:
      return {
        ...state,
        on_going_discussion: action.payload,
      };

    case constante?.GET_COMMENT_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          allReplies: action.payload.data,
          currentPage: action.payload.numberPage,
          currentPost: action?.payload?.currentPost,
          total: action.payload.totalData,
          totaldataFetch: action.payload.data?.length,
        },
      };

    case constante?.RESTORE_COMMENT_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          currentPost: null,
          allReplies: [],
          currentPage: 0,
          total: 0,
          totaldataFetch: 0,
        },
      };
    case constante.COMMENT_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          currentPost: action?.payload?.post,
          allReplies: action.payload.listReplye,
          currentPage: action.payload.numberPage,
          total: action.payload.total,
          totaldataFetch: 10,
        },
      };

    case constante.COMMENT_CHILD_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          currentPost: action?.payload?.post,
          allReplies: helperSetChildPost(
            action?.payload?.listReplyePost,
            action?.payload?.childReplye,
            action?.payload?.parentComment,
            state?.curentDiscutionReplyPost?.allReplies
          ),
          currentPage: action?.payload?.currentPagePost,
          total: action?.payload?.totalPaginatePost,
          totaldataFetch: 10,
        },
      };

    case constante.GET_CURRENT_CHILD_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          allReplies: helperSetDeepChild(
            state?.curentDiscutionReplyPost?.allReplies,
            action?.payload?.child,
            action?.payload?.idParent,
            action?.payload?.currentChildPage
          ),
          total: action?.payload?.numberChild,
        },
      };

    case constante.UPDATE_PARENT_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          currentPost: {
            ...state?.curentDiscutionReplyPost?.currentPost,
            description: action?.payload?.description,
          },
        },
      };

    case constante.REMOVE_PARENT_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          currentPost: {
            ...state.curentDiscutionReplyPost.currentPost,
            isDeleted: true,
            deleted_by: action.payload.userToRemove,
          },
        },
      };

    case constante.UPDATE_REPLY:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          allReplies: helperUpdateReply(
            state?.curentDiscutionReplyPost?.allReplies,
            action?.payload?.idParent,
            action?.payload?.isChildreen,
            action?.payload?.idChildreen,
            action?.payload?.content
          ),
        },
      };

    case constante.GET_FAVORITE:
      return {
        ...state,
        favorite_discussions: {
          ...state?.favorite_discussions,
          ...action?.payload,
          successDataFetched: true,
        },
      };

    case constante.ADD_FAVORY:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          currentPost: {
            ...state?.curentDiscutionReplyPost?.currentPost,
            isFavorited: true,
          },
        },
      };

    case constante.RESTORE_FAVORITE:
      return {
        ...state,
        favorite_discussions: {
          discussions: [],
          currentPage: 0,
          total: 0,
          totaldataFetch: 0,
          successDataFetched: false,
        },
      };

    case constante.LIKE_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          currentPost: {
            ...state?.curentDiscutionReplyPost?.currentPost,
            numberOfLikes: action?.payload?.number_likes,
            liked: !state?.curentDiscutionReplyPost?.currentPost?.liked,
          },
        },
      };

    case constante.LIKE_REPLY:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          allReplies: helperFindReply(
            state?.curentDiscutionReplyPost?.allReplies,
            action?.payload?.number_likes,
            action?.payload?.idToLike,
            action?.payload?.isChildreen,
            action?.payload?.idChildreen
          ),
        },
      };

    case constante.GET_CATEGORY_FEED:
      return {
        ...state,
        detailsDiscutionCategorie: action?.payload,
      };

    case constante.FOLLOW_CATEGORY:
      return {
        ...state,
        detailsDiscutionCategorie: followCategory(
          state?.detailsDiscutionCategorie,
          action?.payload
        ),
      };

    case constante.GET_FOLLOWED_USERS:
      return {
        ...state,
        communityAuthors: action?.payload?.data,
      };

    case constante.MARK_AS_BEST_ANSWER:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          allReplies: helperMarkBestAnswer(
            action?.payload?.listReplyePost,
            action?.payload?.parentComment,
            action?.payload?.isChild,
            action?.payload?.idChild,
            action?.payload?.childReplye
          ),
        },
      };

    case constante.REMOVE_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          allReplies: helperRemovePost(
            state?.curentDiscutionReplyPost?.allReplies,
            action?.payload?.idParent,
            action?.payload?.isChildreen,
            action?.payload?.idChildreen,
            action?.payload?.userToRemove
          ),
        },
      };

    case constante.FOLLOW_UNFOLLOW_USERS:
      return {
        ...state,
        communityAuthors: {
          ...state?.communityAuthors,
          user_followed: helperCheckStateFollow(
            state?.communityAuthors?.user_followed,
            action?.payload
          ),
        },
      };

    case constante.PIN_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          currentPost: {
            ...state?.curentDiscutionReplyPost?.currentPost,
            isPinned: !state?.curentDiscutionReplyPost?.currentPost?.isPinned,
          },
        },
      };
    case constante.LOCK_POST:
      return {
        ...state,
        curentDiscutionReplyPost: {
          ...state?.curentDiscutionReplyPost,
          currentPost: {
            ...state?.curentDiscutionReplyPost?.currentPost,
            isLocked: !state?.curentDiscutionReplyPost?.currentPost?.isLocked,
          },
        },
      };
    default:
      return state;
  }
};
