import React, { ChangeEvent } from 'react';
import { ItemDiscussion } from '../../../../controller/tchat-ctr/type';

import { concatString, conversationType, fileUrl } from '../../../../utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { LoadingArticle } from '../../../widget/loader/index';

type Idiscussion = {
  allDiscussion: Array<ItemDiscussion>;
  currentIdConversation: number;
  handleClickConversation: (
    type: string,
    userId: string,
    idConversation: number
  ) => void;
  fetchMoreConversation: () => void;
  txtFilter: string;
  handleFilter: (evt: string) => void;
  focusSeachInput: () => void;
  clausedSearch: () => void;
  isSearch: boolean;
  hasMore: boolean;
  refConv: any;
  searchForMessage: () => void;
  isLoadingConversationMessage: boolean;
};

const checkIfCurrentConversation = (
  current: number,
  selectedToActive: number
): boolean => {
  if (current !== -1) {
    return current === selectedToActive;
  }
  return false;
};

const filterProfilType = (element: ItemDiscussion) => {
  return element?.type_conversation === conversationType?.community
    ? element?.user_to_send?.file_name
    : element?.company_to_send?.photo_profil;
};

const filterName = (element: ItemDiscussion) => {
  return element?.type_conversation === conversationType?.community
    ? concatString(
        element?.user_to_send?.first_name +
          ' ' +
          element?.user_to_send?.last_name
      )
    : element?.company_to_send?.company_name;
};

const filterId = (element: ItemDiscussion) => {
  return element?.type_conversation === conversationType?.community
    ? element.user_to_send?.id?.toString()
    : element.company_to_send?.id?.toString();
};

const Item = (
  index: number,
  element: ItemDiscussion,
  isActive: boolean,
  handleClickConversation: (
    type: string,
    userId: string,
    idConversation: number
  ) => void,
  isSearch: boolean,
  isRoundedProfil: boolean = true,
  isLoadingConversationMessage: boolean = false,
) => (
  <div
    className={`one-user ${isActive && 'active'} ${isLoadingConversationMessage && !isActive && 'disabled'} ${
      (!element?.is_seen || element?.is_unread) && 'unread'
    }`}
    onClick={() =>
      handleClickConversation(
        element.type_conversation,
        filterId(element),
        element.id_conversation
      )
    }
    key={index}
  >
    <div className={`photo-user ${isRoundedProfil && 'round'}`}>
      <img src={fileUrl(filterProfilType(element))} alt='' />
    </div>
    <div className='message-user'>
      <span className='name-user'>{filterName(element)}</span>
      <span className='message-preview'>
        {isSearch
          ? element?.match_count + ' matched messages'
          : element?.last_message?.[0]?.message}
      </span>
    </div>
    <div className='date-message'>
      {moment(element?.last_message?.[0]?.created_at).format('DD-MMM')}
    </div>
  </div>
);

export default function ConversationListe(props: Idiscussion) {
  return (
    <div className='user-col tab-content' id='parent-conv' ref={props.refConv}>
      <InfiniteScroll
        dataLength={props.allDiscussion?.length}
        next={props.fetchMoreConversation}
        hasMore={props.hasMore}
        loader={<LoadingArticle />}
        scrollableTarget='parent-conv'
      >
        <div className='tab-pane fade show active' id='community-tab'>
          <div
            className={`search-box form-group has-search ${
              props.isSearch ? 'input-cursor' : ''
            } `}
          >
            <span className='icon-search-oem form-control-feedback' />
            <input
              type='text'
              className='form-control search-msg'
              placeholder={'Search message'}
              value={props?.txtFilter}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                props?.handleFilter(evt.target.value)
              }
              onFocus={() => props?.focusSeachInput()}
            />
            {props?.isSearch && (
              <button
                className='icon-close'
                onClick={() => props?.clausedSearch()}
              />
            )}
            {/* {props?.isSearch && props?.txtFilter.length > 0 && (
              <button className="" onClick={() => props?.searchForMessage()}>
                Search messages for "{props?.txtFilter}"
              </button>
            )} */}
          </div>
          {props.allDiscussion?.map((el: ItemDiscussion, index: number) =>
            Item(
              index,
              el,
              checkIfCurrentConversation(
                props?.currentIdConversation,
                el.id_conversation
              ),
              props.handleClickConversation,
              props.isSearch,
              false,
                props.isLoadingConversationMessage
            )
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
}
