import React from "react";
import Modal from "react-bootstrap/Modal";

type IProps = {
  show: boolean;
  setShow: (p: boolean) => void;
  eventObject: any;
  activeCategorie: number;
  setShowNext: (p: boolean) => void;
  setEventTypeSelected: (p: string) => void;
};
export default function ModalChoiseEvent(props: IProps) {
  return (
    <Modal
      show={props?.show}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Create a new event</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={() => props?.setShow(false)}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="contentBorder row forPitch">
          <div className="listPost">
            {props?.eventObject?.event_type?.map((el: any, key: number) => (
              <a
                href="javascript:;"
                className={`choicePost radius ${
                  props?.activeCategorie === key ? "active" : ""
                }`}
                key={key}
                onClick={(e: any) => {
                  e?.preventDefault();
                  props?.setShow(false);
                  props?.setShowNext(true);
                  props?.setEventTypeSelected(el?.id?.toString());
                }}
              >
                <div className="radius">
                  <span className={el?.icon}></span>
                </div>
                {el?.name}
              </a>
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
