import "../OneCategory.scss";
import React, { useEffect } from "react";
import Header from "../../../../widget/index";
import Select from "react-select";
import UseFilterOtherCatgorie from "../../../../../controller/community-ctr/bloc-community-ctr/use-filter-other-categorie";
import { ItemDiscussion } from "../../../../../redux/types/community-interface";
import {
  concatString,
  dateDiff,
  fileUrl,
  getUserType,
  isModerator,
} from "../../../../../utils";
import moment from "moment";
import { LoadingArticle } from "../../../../widget/loader";
import Pagination from "../../../../widget/use-pagination/pagination";
import SearchForm from "../../../../widget/community/search/index";
import Footer from "../../../../widget/footer/footer";
import Locked from "../../../../../resources/images/locked.svg";
import Pin from "../../../../../resources/images/pin.svg";

export default function FilterOtherCategorie() {
  const providerFilter = { ...UseFilterOtherCatgorie() };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isUserPost = (idUser: string) =>
  idUser === providerFilter?.userReducer?.id?.toString();

  const pinnedAndLocked = (userId: string, roles: any, isDelete: boolean) => {
    return (isUserPost(userId) || isModerator(roles)) && !isDelete;
  };
  
  return (
    <div>
      <Header />

      <section className="discussion">
        <div className="container mx-auto">
          <div className="searchTopic">
            <SearchForm
              categories={
                providerFilter?.communityReducer?.discussionCategories
              }
              filterSearchText={providerFilter?.filterSearchText}
              handleFilterTxt={providerFilter?.handleFilterTxt}
              searchText={providerFilter?.inputText}
              handleCateGory={providerFilter?.handleCateGory}
            />
          </div>

          <div className="rightCommunityForum allWidth oneCateg">
            <div className="containerCategory mx-auto">
              <div className="titleCategory">
                <h2>{providerFilter?.titleCategories}</h2>
                <div className="tri">
                  <div className="labelSelect">
                    <label>Show:</label>
                    <Select
                      className="triNumber"
                      isClearable={false}
                      options={[
                        { value: "10", label: "10" },
                        { value: "20", label: "20" },
                        { value: "30", label: "30" },
                      ]}
                      classNamePrefix="tri"
                      defaultValue={{
                        value: providerFilter?.limitDiscution?.toString(),
                        label: providerFilter?.limitDiscution?.toString(),
                      }}
                      value={{
                        value: providerFilter?.limitDiscution?.toString(),
                        label: providerFilter?.limitDiscution?.toString(),
                      }}
                      onChange={(params: any) => {
                        providerFilter?.setPaginateDataFilter(params?.value);
                      }}
                      onInputChange={(p: any) => {
                        return "";
                      }}
                      autoFocus={false}
                      isSearchable={false}
                    />
                  </div>

                  <div className="sortedby labelSelect">
                    <label>Sorted by : </label>
                    <Select
                      className="triOreder"
                      isClearable={false}
                      options={[
                        { value: "post_date", label: "Post date" },
                        { value: "first_activity", label: "First activity" },
                        { value: "last_activity", label: "Latest activity" },
                        { value: "replies", label: "Replies" },
                        { value: "views", label: "Views" },
                      ]}
                      classNamePrefix="tri"
                      defaultValue={{
                        value: providerFilter?.sortedBy,
                        label: "Post date",
                      }}
                      onChange={(params: any) =>
                        providerFilter?.setSortedate(params?.value)
                      }
                    />
                  </div>
                </div>
              </div>
              {!providerFilter?.communityReducer?.successFetchPaginate ? (
                <LoadingArticle />
              ) : (
                <>
                  <ul className="feedList categoryList">
                    {providerFilter?.communityReducer?.paginate_about_community?.discussions?.map(
                      (el: ItemDiscussion, index: number) => (
                        <li key={index}>
                          {el?.solved && (
                            <div className="solved">
                              <div className="contentSolved">
                                <span className="icon-check"></span>
                              </div>
                            </div>
                          )}

                          <div className="infoAbout">
                            <div className="withPost">
                              <div className="imageAffiliatedcompany empty">
                                {el?.user?.file_name && (
                                  <img
                                    src={fileUrl(el?.user?.file_name)}
                                    alt=""
                                  />
                                )}
                              </div>
                              <div
                                className={`nameOfPost ${getUserType(
                                  el?.user?.roles?.[0]
                                )}`}
                              ></div>
                            </div>
                            <div className="infoFeed">
                              <div
                                className="titlePost"
                                onClick={() =>
                                  providerFilter?.goToreplayPost(el)
                                }
                              >
                                {el?.title}
                              </div>
                              <div className="postBy">
                                by{" "}
                                <a
                                  href="javascript:;"
                                  onClick={(e: any) => {
                                    e?.preventDefault();
                                    providerFilter?.goActivity(
                                      el?.user?.id?.toString()
                                    );
                                  }}
                                >
                                  {concatString(
                                    el?.user?.first_name,
                                    el?.user?.last_name,
                                    " "
                                  )}
                                </a>{" "}
                                on{" "}
                                {moment(new Date(el?.created_at)).format(
                                  "MMM D, YYYY h:mm a"
                                )}
                              </div>
                              <div className="postBy">
                                Latest activity {dateDiff(el?.updated_at)} by
                                <a
                                  href="javascript:;"
                                  onClick={(e: any) => {
                                    e?.preventDefault();
                                    // // Dev #47708V1 [Community] Bug lorsque je clique sur le pseudo d'un utilisateur
                                    providerFilter?.goActivity(
                                      el?.last_activity_by?.id?.toString()
                                    );
                                  }}
                                >
                                  {" "}
                                  {concatString(
                                    el?.last_activity_by?.first_name,
                                    el?.last_activity_by?.last_name,
                                    " "
                                  )}
                                </a>
                              </div>
                              <div className="subCategory">
                                {el?.labels?.map(
                                  (element: any, index: number) => (
                                    <span key={index}>{element?.title}</span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="replyInfo">
                            <b>{el?.numberOfReplies}</b>Replies
                          </div>
                          <div className="viewInfo">
                            <b>{el?.numberOfViews}</b>views
                          </div>
                          {/* ADD BANNER LOCKED OR PINNED */}
                          {el?.isLocked && pinnedAndLocked(el?.user?.id?.toString(), providerFilter.userReducer.roles, el?.isDeleted) && (
                            <img className="pinned-item" src={Locked} alt="" />
                          )}
                          {el?.isPinned && !el?.isLocked && (
                            <img className="pinned-item" src={Pin} alt="" />
                          )}
                          {el?.isLocked && el?.isPinned && pinnedAndLocked(el?.user?.id?.toString(), providerFilter.userReducer.roles, el?.isDeleted) && (
                            <img className="pinned-item" src={Locked} alt="" />
                          )}
                          {el?.isLocked && el?.isPinned && !pinnedAndLocked(el?.user?.id?.toString(), providerFilter.userReducer.roles, el?.isDeleted) && (
                            <img className="pinned-item" src={Pin} alt="" />
                          )}
                        </li>
                      )
                    )}
                  </ul>
                  {providerFilter?.communityReducer?.paginate_discussions
                    ?.total > 10 && (
                    <div className="pagginationCategory">
                      <div className="labelSelect">
                        <label>Show: </label>
                        <Select
                          className="triNumber"
                          isClearable={false}
                          options={[
                            { value: "10", label: "10" },
                            { value: "20", label: "20" },
                            { value: "30", label: "30" },
                          ]}
                          classNamePrefix="tri"
                          defaultValue={{
                            value: providerFilter?.limitDiscution?.toString(),
                            label: providerFilter?.limitDiscution?.toString(),
                          }}
                          value={{
                            value: providerFilter?.limitDiscution?.toString(),
                            label: providerFilter?.limitDiscution?.toString(),
                          }}
                          onChange={(params: any) =>
                            providerFilter?.setPaginateDataFilter(params?.value)
                          }
                        />
                      </div>
                      <div className="pagination">
                        <Pagination
                          className="pagination"
                          currentPage={providerFilter?.currentDiscutionPage}
                          totalCount={
                            providerFilter?.communityReducer
                              ?.paginate_discussions?.total
                          }
                          pageSize={providerFilter?.limitDiscution}
                          onPageChange={(page: any) =>
                            providerFilter?.handlePageClick(page)
                          }
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
