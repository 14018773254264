import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  getCPInvestorAction,
  updateAndPublishDraftAction,
  getInvestorAttributes,
} from '../../redux/actions/company-page-investor-action/my-investor-page-action';
import {
  getOtherCPInvestorAction,
  getOtherInvestorAttributes,
  restoreOtherInvestorPage,
} from '../../redux/actions/company-page-investor-action/other-investor-page-action';
import { rootState } from '../../redux/reducers';
import {
  patchDataService,
  postJsonDataService,
} from '../../service/applicatif/back-end-service';
import { url } from '../../service/constant';
import { checkValidation, conversationType, isInObject } from '../../utils';

export default function Index() {
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;
  const history = useHistory();
  const [editMode, setEditMode] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const [loadingPage, setLoadingPage] = useState(false);
  const [isLockedPage, setIsLockedPage] = useState(isOtherUser ? true : false);

  //Dev #48044, V2.1 [ISV] Preview Mode of an investor page
  const [isPreview, setIsPreview] = useState<boolean>(isOtherUser === true);

  const [loader, setLoader] = useState<boolean>(false);

  const CP_Investor = useSelector((state: rootState) =>
    !isOtherUser
      ? state.companyInvestorPageReducer
      : state?.otherCompanyInvestorPageReducer
  );

  const [configOtherCompany, setConfigOtherCompany] = useState<{
    is_favorite: boolean;
    matching: number | string;
    user: {
      id: number;
      first_name: string;
      last_name: string;
    };
  }>({
    is_favorite: false,
    matching: '',
    user: {
      first_name: '',
      id: 0,
      last_name: '',
    },
  });

  let CP_DRAFT_DATA = JSON.parse(CP_Investor.investor_page_draft || '{}');

  const cbGetCP_Investor = (response: any) => {
    const isForbiden = response?.status === 403;
    setLoadingPage(false);
    if (isForbiden) {
      history.push('/profil-not-found');
    }
  };

  const callBackInfoOtherInvestorPage = (infoCompany: any) => {
    const isNotForbiden =
      infoCompany?.email !== '[hidden information]' ||
      infoCompany?.organization_name !== '[hidden information]';
    setLoadingPage(false);

    if (isNotForbiden) {
      setIsLockedPage(false);
    } else {
      setIsLockedPage(true);
    }
  };

  const isInIndividuelType = CP_Investor?.investor_type?.id === 2;

  useEffect(() => {
    !loadingPage && setLoadingPage(true);
    if (!isOtherUser) {
      if (userReducer?.investor_members?.investor_page?.id) {
        dispatch(
          getCPInvestorAction(
            userReducer?.investor_members?.investor_page?.id,
            cbGetCP_Investor
          )
        );
      }
    } else {
      dispatch(
        getOtherCPInvestorAction(params?.id, callBackInfoOtherInvestorPage)
      );
    }
    return () => {
      //Remove temp investor data info
      if (isOtherUser) {
        dispatch(restoreOtherInvestorPage());
      }
    };
  }, [userReducer?.investor_members?.investor_page, params?.id]);

  useEffect(() => {
    const attribut = 'investorPageExpertises';
    if (!isOtherUser) {
      dispatch(getInvestorAttributes(attribut, attribut, cbGetCP_Investor));
    } else if (isOtherUser) {
      dispatch(
        getOtherInvestorAttributes(
          params?.id,
          attribut,
          attribut,
          cbGetCP_Investor
        )
      );
    }
    return () => {};
  }, [params?.id]);

  useEffect(() => {
    const targeted_industry = 'targeted_industry';
    const completeUrl = 'targetedIndustry';
    if (!isOtherUser) {
      dispatch(
        getInvestorAttributes(targeted_industry, completeUrl, cbGetCP_Investor)
      );
    } else {
      dispatch(
        getOtherInvestorAttributes(
          params?.id,
          targeted_industry,
          completeUrl,
          cbGetCP_Investor
        )
      );
    }
    return () => {};
  }, [params?.id]);

  useEffect(() => {
    const key = 'investorPageResources';
    if (!isOtherUser) {
      dispatch(getInvestorAttributes(key, key, cbGetCP_Investor));
    } else {
      dispatch(
        getOtherInvestorAttributes(params?.id, key, key, cbGetCP_Investor)
      );
    }
    return () => {};
  }, [params?.id]);

  useEffect(() => {
    const key = 'segments';
    if (!isOtherUser)
      dispatch(getInvestorAttributes(key, key, cbGetCP_Investor));
    else
      dispatch(
        getOtherInvestorAttributes(params?.id, key, key, cbGetCP_Investor)
      );
    return () => {};
  }, [params?.id]);

  useEffect(() => {
    if (!isOtherUser)
      dispatch(
        getInvestorAttributes(
          'targeted_country',
          'targetedCountry',
          cbGetCP_Investor
        )
      );
    else
      dispatch(
        getOtherInvestorAttributes(
          params?.id,
          'targeted_country',
          'targetedCountry',
          cbGetCP_Investor
        )
      );
    return () => {};
  }, [params?.id]);

  useEffect(() => {
    if (!isOtherUser) {
      dispatch(
        getInvestorAttributes(
          'segment_details',
          'segmentDetails',
          cbGetCP_Investor
        )
      );
    } else {
      dispatch(
        getOtherInvestorAttributes(
          params?.id,
          'segment_details',
          'segmentDetails',
          cbGetCP_Investor
        )
      );
    }
    return () => {};
  }, [params?.id]);

  useEffect(() => {
    if (!isOtherUser) {
      dispatch(
        getInvestorAttributes(
          'investorPagePortfolios',
          'investorPagePortfolios',
          cbGetCP_Investor
        )
      );
    } else {
      dispatch(
        getOtherInvestorAttributes(
          params?.id,
          'investorPagePortfolios',
          'investorPagePortfolios',
          cbGetCP_Investor
        )
      );
    }
    return () => {};
  }, [params?.id]);

  const doneEditing = () => {
    setEditMode(!editMode);
  };

  const sameInvestorInfo = {
    fundraisingMin:
      CP_Investor?.min_fundraising_goal?.toString() ||
      CP_DRAFT_DATA?.min_fundraising_goal?.toString(),
    fundraisingMax:
      CP_Investor?.max_fundraising_gol?.toString() ||
      CP_DRAFT_DATA?.max_fundraising_gol?.toString(),
    location:
      CP_Investor?.country?.country_name ||
      CP_DRAFT_DATA?.country?.country_name,
    //city: CP_Investor?.location || CP_DRAFT_DATA?.location,
    //webSite: CP_Investor?.website || CP_DRAFT_DATA?.website,
    email: CP_Investor?.email || CP_DRAFT_DATA?.email,
    phoneNumber:
      CP_Investor?.phone_number?.toString() ||
      CP_DRAFT_DATA?.phone_number?.toString(),
    industrySpecialisation: CP_Investor?.targeted_industry,

    fundingRound:
      CP_Investor?.investor_funding_round?.length > 0
        ? CP_Investor?.investor_funding_round
        : CP_DRAFT_DATA?.investor_funding_round,
  };

  const investorCompleted = isInIndividuelType
    ? {
        firstName: userReducer?.first_name || CP_DRAFT_DATA?.first_name,
        lastName: userReducer?.last_name || CP_DRAFT_DATA?.last_name,
        jobTitle: CP_Investor?.job_title || CP_DRAFT_DATA?.job_title,
        ...sameInvestorInfo,
      }
    : {
        InvestorType:
          CP_Investor?.investor_type?.name ||
          CP_DRAFT_DATA?.investor_type?.name,
        organisationName:
          CP_Investor.organization_name || CP_DRAFT_DATA?.organization_name,
        //linkDean: CP_Investor?.linkedin || CP_DRAFT_DATA?.linkedin,
        ...sameInvestorInfo,
      };

  const cbUpdateDraft = (data: any) => {
    setLoader(false);
  };

  const updateDraft = () => {
    setLoader(true);
    dispatch(updateAndPublishDraftAction(CP_Investor.id, cbUpdateDraft));
  };

  useEffect(() => {
    async function getInfoConfigCompany() {
      const dataToSend = {
        operation: 'ISV_FIND_INVESTOR',
        source: companyPageObject?.id,
        target: params.id,
      };
      const response = await postJsonDataService(
        url.company.get_other_company_config,
        dataToSend,
        userReducer.token
      );
      if (response?.status === 200) {
        setConfigOtherCompany(response?.data?.details);
      }
    }
    if (params.id) {
      getInfoConfigCompany();
    }
  }, [params.id]);

  const addFavorite = async () => {
    if (params?.id) {
      const paramsId = params?.id;
      let urlFavorite = url?.find_investor?.favorite_action + paramsId;
      let result: any = await patchDataService(
        urlFavorite,
        {},
        userReducer?.token
      );
      if (result?.status === 200) {
        setConfigOtherCompany({
          ...configOtherCompany,
          is_favorite: !configOtherCompany?.is_favorite,
        });
      }
    }
  };

  const contactIvestor = (id: number) => {
    if (params?.id) {
      history.push('/contact/discussion', {
        userId: id,
        discussionType: conversationType.isv_to_investor,
      });
    }
  };

  const checkIfCompleted = () => {
    const primaryDataValidated = checkValidation({
      ...investorCompleted,
    });
    console.log('investorCompleted', investorCompleted);
    //check expertise
    const isValidExpertise = (() => {
      const currentExpertise = CP_Investor?.investorPageExpertises;
      let validExpertise = [];
      let isTheExpertiseValid = false;
      currentExpertise.forEach((expertise: any) => {
        if (expertise?.html !== '') {
          validExpertise = JSON.parse(expertise?.html)?.blocks?.filter(
            (item: any) => {
              return item?.text !== '';
            }
          );
          if (validExpertise.length > 0) isTheExpertiseValid = true;
        }
      });
      return isTheExpertiseValid;
    })();

    //check funding
    const isValidFunding = (() => {
      let currentFunding = JSON.parse(CP_Investor?.investor_page_draft || '[]');
      if (currentFunding.length === 0)
        currentFunding = {
          investor_funding_round: CP_Investor.investor_funding_round,
        };
      const validFunding =
        currentFunding?.investor_funding_round?.filter((item: any) => {
          return item?.id !== 5;
        }) || CP_Investor.investor_funding_round;
      return validFunding && validFunding.length > 0;
    })();

    console.log(
      'checkIfCompleted',
      primaryDataValidated,
      isValidExpertise,
      isValidFunding
    );
    return primaryDataValidated && isValidExpertise && isValidFunding;
  };

  const handLeStatus = () => {
    const isAnonym = !CP_Investor.isv_find_me;
    const isValidate = CP_Investor.validate_by_devinsider;
    if (isAnonym) {
      if (editMode) {
        return 'Draft';
      } else {
        if (isValidate) {
          return 'Active';
        } else {
          return 'Inactive';
        }
      }
    } else {
      if (editMode) {
        return 'Draft (unpublished)';
      } else {
        if (isValidate) {
          return 'Published';
        } else {
          return 'Draft (unpublished)';
        }
      }
    }
  };

  return {
    userReducer,
    CP_Investor,
    editMode,
    setEditMode,
    status: handLeStatus(),
    email: CP_DRAFT_DATA?.email ? CP_DRAFT_DATA?.email : CP_Investor?.email,

    country_name: isInObject(CP_DRAFT_DATA, 'country')
      ? CP_DRAFT_DATA?.country?.country_name
      : CP_Investor?.country?.country_name,

    location: isInObject(CP_DRAFT_DATA, 'location')
      ? CP_DRAFT_DATA?.location
      : CP_Investor?.location,
    organization_name: isInObject(CP_DRAFT_DATA, 'organization_name')
      ? CP_DRAFT_DATA?.organization_name
      : CP_Investor?.organization_name,
    phone_number: CP_DRAFT_DATA?.phone_number
      ? CP_DRAFT_DATA?.phone_number
      : CP_Investor?.phone_number,
    phone_number_code: CP_DRAFT_DATA?.phone_number_code
      ? CP_DRAFT_DATA?.phone_number_code
      : CP_Investor?.phone_number_code,

    min_fundraising_goal: CP_DRAFT_DATA?.min_fundraising_goal
      ? CP_DRAFT_DATA?.min_fundraising_goal
      : CP_Investor?.min_fundraising_goal,
    max_fundraising_gol: CP_DRAFT_DATA?.max_fundraising_gol
      ? CP_DRAFT_DATA?.max_fundraising_gol
      : CP_Investor?.max_fundraising_gol,
    investor_type: CP_DRAFT_DATA?.investor_type
      ? CP_DRAFT_DATA?.investor_type?.name
      : CP_Investor?.institutional_investor_type?.name,
    website: CP_DRAFT_DATA?.website
      ? CP_DRAFT_DATA?.website
      : CP_Investor?.website,
    linkedin: isInObject(CP_DRAFT_DATA, 'linkedin')
      ? CP_DRAFT_DATA?.linkedin
      : CP_Investor?.linkedin,
    number_of_members: isInObject(CP_DRAFT_DATA, 'number_of_members')
      ? CP_DRAFT_DATA?.number_of_members
      : CP_Investor?.number_of_members,

    segments: CP_Investor?.segments,

    segment_details: CP_Investor?.segment_details,

    targeted_industry: CP_Investor?.targeted_industry,

    job_title: CP_DRAFT_DATA?.job_title
      ? CP_DRAFT_DATA?.job_title
      : CP_Investor?.job_title,

    investor_funding_round: CP_DRAFT_DATA?.investor_funding_round
      ? CP_DRAFT_DATA?.investor_funding_round
      : CP_Investor?.investor_funding_round,

    investor_strategic_partnership:
      CP_DRAFT_DATA?.investor_strategic_partnership
        ? CP_DRAFT_DATA?.investor_strategic_partnership
        : CP_Investor?.investor_strategic_partnership,

    first_name: isInObject(CP_DRAFT_DATA, 'first_name')
      ? CP_DRAFT_DATA?.first_name
      : userReducer?.first_name,
    last_name: isInObject(CP_DRAFT_DATA, 'last_name')
      ? CP_DRAFT_DATA?.last_name
      : userReducer?.last_name,

    isIndividuelCp:
      CP_Investor?.investor_type?.id === 2 || CP_Investor?.id === params?.id,
    nameAndFirstName: userReducer?.first_name + ' ' + userReducer?.last_name,
    isAnnonym: !CP_Investor?.isv_find_me,
    doneEditing,
    loader,
    updateDraft,
    isOtherUser,
    configOtherCompany,
    addFavorite,
    contactIvestor,
    checkIfCompleted,
    isLockedPage,
    loadingPage,
    //Dev #48044, V2.1 [ISV] Preview Mode of an investor page
    isPreview,
  };
}
