import React, { useEffect, useState } from "react";
import { getUserActivity } from "../../../service/applicatif/acount";
import store from "../../../redux/store";
import { url } from "../../../service/constant";
import { useHistory, useParams } from "react-router";

export default function LikesGiven(userId: number) {
  const history = useHistory();
  const params: any = useParams();
  const [likesGiven, setLikesGiven] = useState<Array<any>>([]);

  const getLikeGiven = async (userId: number) => {
    const response = await getUserActivity(
      url.community.activity.get_likes_given,
      userId,
      store.getState().userReducer.token
    );
    if (response?.status === 200) {
      setLikesGiven(response?.data);
    }
  };
  useEffect(() => {
    //get user contribution
    if (userId) {
      getLikeGiven(userId);
    }
  }, [userId]);

  const goToLikeGivenViewAll = () => {
    history.push("/community/activity-user/like", {
      type: "like_given",
      userId: params?.id,
    });
  };

  return {
    userId,
    likesGiven,
    goToLikeGivenViewAll,
  };
}
