import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getFollowedCommunityUsers,
  followUnfollowUserAction,
} from "../../../redux/actions/community-action";
import { rootState } from "../../../redux/reducers";

export default function ManageCommunityAuthors() {
  const dispatch = useDispatch();
  const history = useHistory();
  const communityReducer = useSelector(
    (state: rootState) => state?.communityReducer
  );

  const [currentCommunityAuthorsPage, setCurrentCommunityAuthorsPage] =
    useState<number>(1);
  const [limitUsers, setlimitUsers] = useState<number>(10);
  const [loader, setLoader] = useState<boolean>(false);
  const [confirmFollow, setConfirmFollow] = useState<boolean>(false);
  const [currentElement, setCurrentElement] = useState<number>(1);

  const cb = (data: any) => {
    if (data?.status === 200) {
    }
  };

  useEffect(() => {
    dispatch(getFollowedCommunityUsers(currentCommunityAuthorsPage, cb));
  }, []);

  const cbhandleUnFollowClick = (data: any) => {
    if (data?.status === 200) {
      handlePageClick(currentCommunityAuthorsPage);
      setLoader(false);
      setConfirmFollow(false);
    }
  };

  const handleUnFollowClick = (data: any, key: number) => {
    setCurrentElement(key);
    setConfirmFollow(true);
  };

  const handlePageClick = (data: any) => {
    setCurrentCommunityAuthorsPage(data);
    setLoader(true);
    dispatch(getFollowedCommunityUsers(currentCommunityAuthorsPage, cb));
  };

  const cancelFollowClick = () => {
    setConfirmFollow(false);
  };

  const submitFollowClick = (data: any) => {
    setLoader(true);
    let communityAuthorData = { user: data };
    dispatch(
      followUnfollowUserAction(communityAuthorData, cbhandleUnFollowClick)
    );
  };

  const goActivityUser = (userId: number) => {
    history.push("/community/activity-user/" + userId);
  };

  return {
    communityReducer,
    handlePageClick,
    limitUsers,
    currentCommunityAuthorsPage,
    handleUnFollowClick,
    loader,
    confirmFollow,
    cancelFollowClick,
    submitFollowClick,
    currentElement,
    goActivityUser,
  };
}
