import React, { ChangeEvent, useEffect } from 'react';
import './classifiedAds.scss';
import Header from '../../widget/index';
import {
  concatString,
  extractRoleName,
  fileUrl,
  isModerator,
} from '../../../utils';
import usePostAdsCtr from '../../../controller/classified-ads-ctr/post-ads-ctr';
import FormWysiwyg from '../../widget/post-wysiwig/index';
import Footer from '../../widget/footer/footer';
import ModalIsvPayementAdds from '../../widget/payement-isv/publish-adds';
import ModalAGuestPayementAdds from '../../widget/payement-guest/payement-adds';
import ModalResellerPayementAdds from '../../widget/payement-reseller/publish-adds/index';
import ModalInvestorPayementAdds from '../../widget/payement-investors/publish-adds/index';

import DefaultLoGoCompany from '../../../resources/images/defaultCompany.png';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import CompletedAccount from '../../../hooks/CompletedAccount';
import { Redirect } from 'react-router-dom';

export default function PostClassifiedAds() {
  const postClassified = usePostAdsCtr();

  const currentUser = useSelector((state: rootState) => state.userReducer);

  useEffect(() => {
    const root = document.getElementsByClassName('rdw-option-wrapper');
    const foontSizeWrapper = document.getElementsByClassName(
      'rdw-fontsize-wrapper'
    );
    const fontFamillyWrapper = document.getElementsByClassName(
      'rdw-fontfamily-wrapper'
    );

    const alignWrapper = document.getElementsByClassName(
      'rdw-text-align-wrapper'
    );

    const fontblockWrapper =
      document.getElementsByClassName('rdw-block-wrapper');

    const monospace = root[3];
    const subsciprt = root[4];
    const font = root[5];
    const puiss = root[6];
    if (monospace) {
      monospace.className = 'd-none';
      subsciprt.className = 'd-none';
      font.className = 'd-none';
      puiss.className = 'd-none';
    }

    if (foontSizeWrapper.length > 0) {
      foontSizeWrapper[0].className = 'd-none';
    }

    if (fontFamillyWrapper.length > 0) {
      fontFamillyWrapper[0].className = 'd-none';
    }

    if (alignWrapper.length > 0) {
      alignWrapper[0].className = 'd-none';
    }

    if (fontblockWrapper.length > 0) {
      fontblockWrapper[0].className = 'd-none';
    }
  }, []);

  if (postClassified?.type === undefined) {
    return <Redirect to={'/classified-ads/home'} />;
  }

  return (
    <CompletedAccount>
      <Header
        startAds={() => {
          console.log('evt first');
          postClassified?.postAdds();
        }}
      />

      {postClassified?.isGuest && (
        <ModalAGuestPayementAdds
          {...postClassified.providerUsePayementGuest}
          postAdsFromUser={(methode: string, paymentIntentId: string) =>
            postClassified.postAdsFromUser(methode, paymentIntentId)
          }
          publishAdds={() => postClassified.publishAdds()}
          loading={postClassified.loader}
        />
      )}

      {postClassified?.isIsv && (
        <ModalIsvPayementAdds
          {...postClassified.providerUsePayementAdds}
          loading={postClassified.loader}
          publishAdds={() => postClassified.publishAdds()}
        />
      )}

      {postClassified?.isReseller && (
        <ModalResellerPayementAdds
          {...postClassified.providerUsePayementReseller}
          publishAdds={() => postClassified.publishAdds()}
          loading={postClassified.loader}
        />
      )}

      {postClassified?.isInvestor && (
        <ModalInvestorPayementAdds
          {...postClassified.providerUsePayementInvestor}
          publishAdds={() => postClassified.publishAdds()}
          loading={postClassified.loader}
        />
      )}

      <section className='classified details'>
        <div className='container mx-auto'>
          <div className='leftSection'>
            <div className='block'>
              <div className='leftItem itemAuthor'>
                <div className={`imageAffiliatedcompany empty`}>
                  <img
                    src={`${
                      postClassified?.displayAuthorName
                        ? fileUrl(postClassified?.user?.file_name)
                        : ''
                    } `}
                    alt=''
                  />
                </div>
                <div className=''>
                  <div className='post'>Author:</div>
                  {postClassified?.displayAuthorName ? (
                    <>
                      <h3 className='title'>
                        {concatString(
                          postClassified?.user?.first_name,
                          postClassified?.user?.last_name,
                          ' '
                        )}
                      </h3>
                      <div>{postClassified?.user?.job_title}</div>
                    </>
                  ) : (
                    <h3 className='title'>
                      {postClassified?.user?.first_name}
                    </h3>
                  )}
                </div>
              </div>
            </div>

            <div className='block infoCompanyAds'>
              <div
                className={`imgclassified ${
                  postClassified?.user?.isvInCompanyPage && !postClassified.displayCompanyName ? 'empty' : 'imgSv'
                }`}
              >
                <img
                  src={
                    postClassified?.displayCompanyName
                      ? fileUrl(postClassified?.companyPageObject?.photo_profil)
                      : DefaultLoGoCompany
                  }
                  alt=''
                />
              </div>

              <div className='infoAds'>
                Company:
                <h3 className='title'>
                  {postClassified?.displayCompanyName
                    ? postClassified?.companyPageObject?.company_name
                    : 'Company confidential'}
                </h3>
                <div className='role'>
                  {extractRoleName(postClassified?.user?.roles)}
                </div>
                <div className='contry'>
                  {concatString(
                    postClassified?.user?.country?.country_name,
                    postClassified?.user?.city
                  )}
                </div>
              </div>
            </div>

            {!isModerator(currentUser?.roles) && (
              <div className='block'>
                <div className='slidecheck'>
                  <div className='custom-checkbox'>
                    <input
                      id='hideAuthor'
                      type='checkbox'
                      className='custom-control-input'
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        postClassified.setDisplayAuthorName(
                          event.target.checked
                        )
                      }
                      checked={postClassified.displayAuthorName}
                    />
                    <label
                      htmlFor='hideAuthor'
                      className='custom-control-label'
                    >
                      Display author name
                    </label>
                  </div>

                  <div className='custom-checkbox'>
                    <input
                      id='hideAuthor1'
                      type='checkbox'
                      className='custom-control-input'
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        postClassified.setDisplayCompanyName(
                          event.target.checked
                        )
                      }
                      checked={postClassified.displayCompanyName}
                    />
                    <label
                      htmlFor='hideAuthor1'
                      className='custom-control-label'
                    >
                      Display company name
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='rightSection newAdsPost'>
            <h2 className='title'>
              {postClassified?.edit ? 'Edit' : 'New'} Classified ad in “{' '}
              {postClassified?.type?.type}”
              {postClassified?.isErrorObject && (
                <div className={`container-error pl-2  d-inline`}>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>Object is required</span>
                </div>
              )}
            </h2>
            <FormWysiwyg
              //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
              mention={postClassified?.mentions}
              updateMention={postClassified?.updateMention}
              user={postClassified?.user}
              editorState={postClassified?.editorState}
              cancelPost={postClassified?.cancelAds}
              loader={false}
              setEditorState={postClassified?.setEditorState}
              sendPost={
                !postClassified?.edit
                  ? postClassified?.publishAdds
                  : postClassified?.editPost
              }
              object={postClassified?.title}
              setObject={postClassified?.setTitle}
              minify={true}
              showPreview={() => null}
              isShowPreview='not-showing'
            />
          </div>
        </div>
      </section>
      <Footer />
    </CompletedAccount>
  );
}
