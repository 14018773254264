/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
import { ReactComponent as Loader } from "../../../../../../resources/images/Rolling.svg";

export default function ModalEditAnnualTurnover(
  props: T.IpropsModalEditAnnual
) {
  return (
    <Modal
      show={props.isShowFinancialAnnual}
      className="createCompanypopup smallPopup forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Annual revenue</h2>
          <a
            className="close"
            onClick={() => props.setIsShowFinancialAnnual(false)}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="withMinHeight">
        <div className="contentBorder row">
          <div className="form-group col-12">
            <label htmlFor="">Select your annual revenue bracket</label>
            <select
              className="custom-select"
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                props.setAnnulTurnover(event.target.value);
              }}
            >
              <option value="">Select an option</option>
              {props.annualTurnoverData &&
                props.annualTurnoverData.map((el: any, index: number) => (
                  <option
                    value={JSON.stringify(el)}
                    key={index}
                    selected={
                      JSON.stringify(el) == props.annualTurnOverSelected
                        ? true
                        : false
                    }
                  >
                    {Object.keys(el)}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props.setIsShowFinancialAnnual(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-devinsider px-5 save"
            onClick={() => props.updateAnnualTurnOver()}
          >
            {props.loaderFinancial ? <Loader /> : "Save"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
