import React from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import TargetIndustry from "../edit-basic-information/target-industry";

type IProps = {
  show: boolean;
  cancelUpdataTargeted: () => void;
  updateTargetIndustrie: () => void;
  loaderTargetIndustrie: boolean;
  providerUseTargetIndustrieCtr: any;
};
export default function EditTargetIndustry(props: IProps) {
  return (
    <Modal
      show={props.show}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Basic information - Targeted industry</h2>
          <a className="close" onClick={() => props?.cancelUpdataTargeted()}>
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="withMinHeight">
        <TargetIndustry {...props.providerUseTargetIndustrieCtr} />
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props?.cancelUpdataTargeted()}
          >
            Cancel
          </button>
          <button
            className="btn btn-devinsider px-5 save"
            onClick={() => props?.updateTargetIndustrie()}
            disabled={props?.loaderTargetIndustrie}
          >
            {props?.loaderTargetIndustrie ? <Loader /> : "Save"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
