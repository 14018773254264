import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as BController from "../../../../../controller/company-ctr/profil/block-profil-company-controller/index";
import { rootState } from "../../../../../redux/reducers";

export type IProps = {
  setShowEdit: () => void;
};
export default function Index(props: IProps) {
  const providerUseTargetIndustrieCtr = {
    ...BController.UseTargetIndustrieCtr(),
  };

  const params: any = useParams();

  let companyPageObject = useSelector((state: rootState) =>
    params?.id ? state?.otherUserCompanyIsvReducer : state.companyPageReducer
  );

  const lengthTargetedIndustry = companyPageObject?.targeted_industry?.length;

  return (
    <div className="containerLeftItem py-3">
      <a className="linkEditLeftCompany" onClick={() => props?.setShowEdit()}>
        Edit
        <span className="icon-edit-full"></span>
      </a>
      <h2 className="title">Targeted industry</h2>
      <ul className="listnamelabel pl-3">
        {companyPageObject?.targeted_industry
          .filter((el: { id: number; name: string }, index: number) => {
            return index < providerUseTargetIndustrieCtr?.index2;
          })
          .map((el: { id: number; name: string }, index: number) => (
            <li key={index}>
              <div className="name">{el.name}</div>
            </li>
          ))}
        {lengthTargetedIndustry > 6 && (
          <li className="link">
            <a
              onClick={(evt) => {
                evt.preventDefault();
                providerUseTargetIndustrieCtr?.setindex2(
                  providerUseTargetIndustrieCtr?.index2 ===
                    lengthTargetedIndustry
                    ? 6
                    : lengthTargetedIndustry
                );
              }}
            >
              See{" "}
              {providerUseTargetIndustrieCtr?.index2 === lengthTargetedIndustry
                ? "less"
                : "more"}
              {providerUseTargetIndustrieCtr?.index2 === lengthTargetedIndustry
                ? ""
                : `(${
                    lengthTargetedIndustry -
                    providerUseTargetIndustrieCtr?.index2
                  })`}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
}
