import Header from '../../../../widget/header-logo-center/header-logo-center';
import StepperOem from '../../../../widget/stepper/StepperOem';
import React from 'react';
import useCompleteWebsiteLocation from '../../../../../controller/account-ctr/create-oem-account/use-complete-website-location';
import {
  checkIfExist,
  getSStorage,
  regexValidation,
} from '../../../../../utils';
import { Redirect } from 'react-router-dom';

export default function CompleteWebSiteLocationOem() {
  const {
    dataCompleted,
    errors,
    handleSubmit,
    register,
    onSubmit,
    dataOem,
    userState,
  } = useCompleteWebsiteLocation();
  const sess: any = getSStorage('oemProject');

  if (!checkIfExist(sess)) {
    return <Redirect to='/partner-program-registration' />;
  }

  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <StepperOem step={2} />
        <div className='contentBorder border-top row pb-4'>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='company required'>
              Company name
              {errors['companyNameOem'] && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
            </label>
            <input
              type='text'
              className='input-text form-control'
              // disabled={userState?.company_name ? true : false}
              defaultValue={dataOem?._companyName || dataOem?.companyName}
              {...register('companyNameOem', { required: true })}
            />
          </div>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='jobtitle required'>
              Website
              {errors['webSiteOem']?.type === 'required' && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
              {errors['webSiteOem']?.type === 'pattern' && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>
                    Begin with http:// or https:// or www
                  </span>
                </div>
              )}
            </label>
            <input
              type='text'
              className='input-text form-control'
              placeholder={'Begin with http:// or https:// or www'}
              defaultValue={dataOem?.website}
              {...register('webSiteOem', {
                required: true,
                pattern: regexValidation.url,
              })}
            />
          </div>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='hqlocation'>
              HQ location
              {errors['selectCountryOem'] && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
            </label>
            <select
              className='custom-select'
              {...register('selectCountryOem', { required: true })}
              defaultValue={dataOem?.country}
            >
              <option value=''>Country...</option>
              {dataCompleted?.country?.map((el: any) => (
                <option value={el.id} key={el.id}>
                  {el.country_name}
                </option>
              ))}
            </select>
          </div>
          <div className='form-group col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label className='text-white'>City</label>
            <input
              type='text'
              className='input-text form-control'
              placeholder={'City...'}
              defaultValue={dataOem?.city}
              {...register('cityOem')}
            />
          </div>
          <div className='contentBorder border-top row align-items-stretch'>
            <button
              type='submit'
              className='btn btn-devinsider large'
              onClick={handleSubmit(onSubmit)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
