import { useEffect, useReducer, useState } from 'react';

import { IAction } from '../../../redux/types/type-action';
import { postJsonDataService } from '../../../service/applicatif/back-end-service';
import { url } from '../../../service/constant/index';
import { rootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { ItemDiscussion } from '../../../redux/types/community-interface';
import { useHistory, useParams } from 'react-router-dom';
import UseSearch from '../../../screen/widget/community/search/use-search';

const APPEND_POST = 'APPEND_POST';

type TypeInitialState = {
  posts: Array<ItemDiscussion>;
  totalData: number;
  totalDataFetch: number;
  currentPage: number;
};
const initialState: TypeInitialState = {
  posts: [],
  totalData: 0,
  totalDataFetch: 0,
  currentPage: 0,
};

const reducer = (
  state: TypeInitialState,
  action: IAction
): TypeInitialState => {
  switch (action.type) {
    case APPEND_POST:
      return {
        ...state,
        posts: [...state?.posts, ...action.payload?.posts],
        totalData: action?.payload?.count,
        totalDataFetch:
          state.totalDataFetch + action?.payload?.posts?.length * 1,
        currentPage: action?.payload?.currentPage,
      };
    default:
      return state;
  }
};

export default function useViewAllCommunity() {
  const [allPosts, dispatch] = useReducer<
    (state: TypeInitialState, action: IAction) => TypeInitialState
  >(reducer, initialState);

  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const userObject = useSelector((state: rootState) => state?.userReducer);
  const communityReducer = useSelector(
    (state: rootState) => state?.communityReducer
  );
  const [isFecth, setIsFecth] = useState<boolean>(false);
  const history = useHistory();
  const callback = () => {
    setHasMore(false);
  };

  const params: any = useParams();

  let communityType = params?.id;
  /**
   * Use by useReducer not redux
   *
   * @param dispatch
   * @param {() => void} callback
   * @returns {Promise<boolean>}
   */
  const getAllPosts = async (
    params: any,
    dispatch: any,
    callback: () => void
  ) => {
    const res = await postJsonDataService(
      url?.community?.get_posts,
      params,
      userObject?.token
    );

    const result = {
      posts: res?.data?.results,
      count: res?.data.total,
      currentPage: params?.page * 1 + 1,
    };

    if (res?.data.results.length < 1) {
      callback();
      return true;
    }

    dispatch({ type: APPEND_POST, payload: result });
  };

  const loadMorePosts = async () => {
    if (!isFecth) {
      let params = {
        categories: '',
        labels: '',
        limit: 10,
        notIdlabels: '',
        orderBy: '',
        page: allPosts?.currentPage,
        postTypes: communityType,
        sortBy: '',
        views: '',
        tags: '',
        oem_program: '',
      };
      setIsFecth(true);
      await getAllPosts(params, dispatch, callback);
      setIsFecth(false);
    }
  };

  useEffect(() => {
    let params = {
      categories: '',
      labels: '',
      limit: 10,
      notIdlabels: '',
      orderBy: '',
      page: 1,
      postTypes: communityType,
      sortBy: '',
      views: '',
      tags: '',
      oem_program: '',
    };
    (async () => {
      setIsFecth(true);
      await getAllPosts(params, dispatch, callback);
      setIsFecth(false);
    })();
  }, []);

  const goToLabelCategory = (categoryId: string, label_id: string) => {
    history.push('/community/one-category/' + categoryId + '/' + label_id);
  };

  const goToreplayPost = (categoryId: string, postId: string) => {
    history.push('/community/reply-post/' + 0 + '/' + postId);
  };

  const goActivity = (id: string) => {
    history.push('/community/activity-user/' + id);
  };

  return {
    allPosts,
    loadMorePosts,
    hasMore,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    communityReducer,
    goToLabelCategory,
    goToreplayPost,
    goActivity,
    communityType,
  };
}
