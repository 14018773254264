import Header from '../../../../widget/header-logo-center/header-logo-center';
import StepperOem from '../../../../widget/stepper/StepperOem';
import React from 'react';
import useCompleteProgramTypeOem from '../../../../../controller/account-ctr/create-oem-account/use-complete-program-type-oem';
import Select from 'react-select';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { checkIfExist, getSStorage } from '../../../../../utils';
import { Redirect } from 'react-router-dom';

export default function CompleteProgramTypeOem() {
  const {
    handleTypeChange,
    type,
    typeError,
    handleSubmit,
    onSubmit,
    errors,
    register,
    categories,
    dropDownItems,
    dataOem,
    segments,
    setSegments,
    segmentError,
  } = useCompleteProgramTypeOem();

  const sess: any = getSStorage('oemProject');

  if (!checkIfExist(sess)) {
    return <Redirect to='/partner-program-registration' />;
  }

  return (
    <div>
      <Header />
      <div className='getstartedContainer'>
        <StepperOem step={2} />
        <div className='contentBorder border-top row py-4'>
          <div className='form-group required col-xl-12 col-md-12 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='type required'>
              What type of program are you offering to ISVs?
              {typeError && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
            </label>
            <div className='row'>
              <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
                <button
                  className={`btn d-block w-100 ${
                    type === categories.embedded
                      ? 'btn-devinsider'
                      : 'btn-light'
                  }`}
                  onClick={() => handleTypeChange(categories.embedded)}
                >
                  Software OEM
                  <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                      <Tooltip id='tooltip-disabled'>
                        Software vendors (ISVs) that offer an embedded or white
                        labeled solution that becomes an integral part of
                        another vendors's software, resulting in a strategic
                        partnership.
                      </Tooltip>
                    }
                    defaultShow={false}
                  >
                    <span className='icon-help-circle programType'></span>
                  </OverlayTrigger>
                </button>
              </div>
              <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
                <button
                  className={`btn d-block w-100 ${
                    type === categories.ecoSystem
                      ? 'btn-devinsider'
                      : 'btn-light'
                  }`}
                  onClick={() => handleTypeChange(categories.ecoSystem)}
                >
                  Software ecosystem
                  <OverlayTrigger
                    placement={'bottom'}
                    overlay={
                      <Tooltip id='tooltip-disabled'>
                        Software ecosystems that provide software vendors (ISVs)
                        with tools, platforms, technologies they can build
                        solutions on top of.
                      </Tooltip>
                    }
                    defaultShow={false}
                  >
                    <span className='icon-help-circle programType'></span>
                  </OverlayTrigger>
                </button>
              </div>
            </div>
          </div>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='programName required'>
              Program name
              {errors['programName'] && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
            </label>
            <input
              type='text'
              className='input-text form-control'
              defaultValue={dataOem.programName}
              {...register('programName', { required: true })}
            />
          </div>
          <div className='form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0'>
            <label htmlFor='programName required'>
              Program segment(s)
              {segmentError && (
                <div className='container-error pl-2 d-inline'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>This field is required</span>
                </div>
              )}
            </label>
            <Select
              noOptionsMessage={() => 'Please select a program type'}
              placeholder={'Select all that apply...'}
              options={dropDownItems?.map((el: any) => ({
                id: el.id,
                label: el.name,
                value: el.name,
              }))}
              value={segments}
              isMulti={true}
              className=''
              onChange={(data) => setSegments(data)}
            />
          </div>
          <div className='contentBorder border-top row align-items-stretch'>
            <button
              type='submit'
              className='btn btn-devinsider large'
              onClick={handleSubmit(onSubmit)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
