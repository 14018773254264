import { constante } from "../../actions/classified-ads-action";
import {
  IAction,
  IAd,
  IClassifiedAds,
} from "../../types/classified-ads-interface";

const initialState: IClassifiedAds = {
  categories: [],
  freshFinds: {
    freshFinds: [],
    dataFetchSuccess: false,
  },
  sub_menu: {
    targeted_industry: [],
    company_type: [],
    software_solution: [],
    hq_location: [],
  },
  my_classified_ads: {
    list: [],
    total: 0,
  },
};

export const classifiedAdsReducer = (
  state = initialState,
  action: IAction
): IClassifiedAds => {
  switch (action?.type) {
    case constante.GET_CATEGORIES:
      return {
        ...state,
        categories: action?.payload,
      };
    case constante.GET_FRESH_FINDS:
      return {
        ...state,
        freshFinds: {
          freshFinds: action?.payload?.data,
          dataFetchSuccess: true,
        },
      };
    case constante.GET_ERROR_FRESH_FINDS:
      return {
        ...state,
        freshFinds: {
          freshFinds: [],
          dataFetchSuccess: false,
        },
      };
    case constante.GET_MORE_FRESH_FINDS:
      return {
        ...state,
        freshFinds: {
          freshFinds: [...state?.freshFinds?.freshFinds, ...action?.payload],
          dataFetchSuccess: true,
        },
      };
    case constante.GET_FILTERS:
      return {
        ...state,
        sub_menu: action?.payload,
      };
    case constante.LISTING_MY_CLASSIFIED_ADS:
      return {
        ...state,
        my_classified_ads: {
          list: action?.payload?.results,
          total: action?.payload?.total_result_to_paginate,
        },
      };
    case constante.MORE_LISTING:
      return {
        ...state,
        my_classified_ads: {
          list: [
            ...state?.my_classified_ads?.list,
            ...action?.payload?.results,
          ],
          total: action?.payload?.total_result_to_paginate,
        },
      };
    default:
      return state;
  }
};
