import MA from '../screen/container/mergers-acquisitions/home-page/index';
import NotFound from '../screen/container/not-found/not-found';

export const routesMA = [
  {
    component: NotFound, // MA,
    exact: true,
    path: '/mergers-acquisitions',
    guard: 'public',
  },
];
