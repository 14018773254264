/* eslint-disable jsx-a11y/anchor-is-valid */
/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React from "react";
/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import Header from "../../../widget/header-logo-center/header-logo-center";
import UseAccountChoixCtr from "../../../../controller/account-ctr/account-choix/use-account-choix-ctr";

export default function AccountChoix() {
  const {
    goLogin,
    navigateCompleteInfo,
    programRegistration,
    completResellerAcount,
    navigateCompleteInvestor,
  } = UseAccountChoixCtr();

  return (
    <div>
      <Header />
      <div className="getstartedContainer">
        <div className="head-getstarted">
          <h1>
            Getting started with devinsider is fast and free
            <span>Which role describes your best?</span>
          </h1>
        </div>
        <div className="contentBorder border-top border-bottom">
          <a
            onClick={() => navigateCompleteInfo()}
            className="linkgetstarted large gris"
          >
            Software publisher (ISV)
          </a>
        </div>
        <div className="contentBorder border-bottom">
          {/* <a className="linkgetstarted" onClick={() => completResellerAcount()}>
            Reseller
          </a> */}
          <a
            className="linkgetstarted"
            onClick={() => navigateCompleteInvestor()}
          >
            Institutional investor
          </a>
          <a className="linkgetstarted" onClick={() => programRegistration()}>
            Software ecosystem / OEM
          </a>
        </div>
        <div className="contentBorder alredyhaveaccount text-center">
          Already have an account?&nbsp;
          <a onClick={() => goLogin()}>Log in</a>
        </div>
      </div>
    </div>
  );
}
