import React, { useEffect, useRef } from 'react';

export default function useEventListener(
  eventName: string,
  handler: any,
  element = window
) {
  const savedHandler: any = useRef();

  useEffect(() => {
    savedHandler.current = handler;
    return () => {};
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListene
      const isSupported = element?.addEventListener;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref
      const eventListener = (event: any) => savedHandler?.current(event);
      // Add event listener
      element.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}
