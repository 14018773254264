import React from "react";
import { Link, useHistory } from "react-router-dom";

import url from "../../../../../../service/constant/index";
import { fileUrl } from "../../../../../../utils";

const CompanyCardInvestor = (props: any) => {
  const history = useHistory();
  const renderRole = (role: string) => {
    if (role === "PROGRAM_OWNER") return "Program owner";
    if (role === "ADMIN") return "Admin";
    if (role === "Editor") return "Editor";
    if (role === "OUTREACH_REPRESENTATIVE") return "Outreach representative";
    return "";
  };
  return (
    <div className="contentISV">
      <h2 className="title">Investor page</h2>
      <ul className="svList">
        <li>
          <div
            className="imgSv"
            onClick={() => history.push("/profil/investor")}
            style={{ cursor: "pointer" }}
          >
            <img
              src={fileUrl(
                props.userState?.investor_members?.investor_page?.photo_profil
              )}
              alt="Investor page logo"
            />
          </div>
          <div className="infoSV">
            <>
              <h3 className="title">
                {
                  props?.userState?.investor_members?.investor_page
                    .organization_name
                }
              </h3>
              <div className="role">
                {renderRole(props?.userState?.investor_members?.role)}
              </div>
            </>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CompanyCardInvestor;
