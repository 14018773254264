import React from "react";
import { Link, useHistory } from "react-router-dom";
import { fileUrl } from "../../../../../../utils";

const CompanyCardReseller = (props: any) => {
  const history = useHistory();
  return (
    <div className="contentISV">
      <h2 className="title">Sofware reseller</h2>
      <ul className="svList">
        <li>
          <div className="imgSv" style={{ cursor: "pointer" }}>
            <img
              src={fileUrl(props?.companyResellerPage?.photo_profil)}
              alt=""
            />
          </div>
          <div className="infoSV">
            <>
              <h3 className="title">{props?.userState?.company_name}</h3>
            </>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CompanyCardReseller;
