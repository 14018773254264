import { EditorState, RawDraftContentState } from 'draft-js';
import { ChangeEvent } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import { asyncPostPhoto } from '../../../service/api';
import constants, { CONTRIBUTOR, url } from "../../../service/constant";
import { IPropsPost } from './type';

export default function Index(props: IPropsPost) {

  const userReducer = useSelector((state: rootState) => state.userReducer);

  const uploadCallback = (file: string | Blob) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      asyncPostPhoto(
          formData,
          url.post.upload_file_url + props.uploadPostType?.replace(/\s/g, '_').replace(/\W/g, '').toLowerCase(),
          userReducer.token
      ).then((response: { data: string; }) => {
        resolve({ data: { link: constants.baseUrl + response.data } });
      }).catch( (error: any) => {
        if (error.response) {
          const errorMessage = `Error ${error.response.status}: ${error.response.data}`;
          reject(errorMessage);
        } else if (error.request) {
          const errorMessage = "No response";
          reject(errorMessage);
        } else {
          const errorMessage = `Request error: ${error.message}`;
          reject(errorMessage);
        }
      });
    });
  }

  return (
    <div className='relative'>
      <input
        type='text'
        placeholder='Enter a subject...'
        className='form-control my-4'
        value={props?.object}
        onChange={(evt: ChangeEvent<HTMLInputElement>) =>
          props?.setObject(evt?.target?.value)
        }
      />
      {/* {props.isShowPreview !== 'not-showing' && (
        <Link className='p-wysiwyg' to='#' onClick={() => props.showPreview()}>
          Preview
        </Link>
      )} */}

      <Editor
        mention={{
          separator: ' ',
          trigger: '@',
          suggestions: props?.mention,
        }}
        editorState={props?.editorState}
        wrapperClassName='wrapper-class'
        editorClassName='editor-class'
        toolbarClassName='toolbar-class'
        onEditorStateChange={(editorState: EditorState) => {
          props.setEditorState(editorState);
        }}
        onChange={(value: RawDraftContentState) => {
          //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
          props?.updateMention(value);
        }}
        stripPastedStyles={true}
        toolbar={{
          options: [
            'inline',
            'fontSize',
            'fontFamily',
            'list',
            'history',
            // 'image',
            // 'link',
            'remove',
            'blockType',
            'textAlign',
          ].concat(userReducer.roles.includes(CONTRIBUTOR) ? ['image'] : []),
          image: {
            uploadCallback: props.uploadPostType ? uploadCallback : (p: any) => {
              let uploadedImages = [];

              const imageObject = {
                file: p,
                localSrc: URL.createObjectURL(p),
              };
              uploadedImages.push(imageObject);
              // We need to return a promise with the image src
              // the img src we will use here will be what's needed
              // to preview it in the browser. This will be different than what
              // we will see in the index.md file we generate.
              return new Promise((resolve, reject) => {
                resolve({ data: { link: imageObject.localSrc } });
              });
            },
          },
        }}
      />
      <div className='contentBorder formForEmail text-left'>
        <button
          className='btn btn-devinsider px-5 save'
          //onClick={() => props?.sendPost()}
          onClick={() => props?.sendPost()}
        >
          {props?.loader ? <Loader /> : 'Post'}
        </button>

        <button
          className='btn btn-devinsider cancel'
          onClick={() => props?.cancelPost()}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
