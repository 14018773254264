import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { dateDiff, fileUrl } from "../../../../../../utils";

type IProps = {
  userPost: any;
  getBadge: any;
  showDiscussion: any;
  showLabel: (categoryId: string, labelId: string, postType: number) => void;
  goToreplayPost: (idPost: number, categoryId: number) => void;
};
export default function ItemRender(props: IProps) {
  return (
    <>
      {props?.userPost?.map((post: any) => (
        <li key={post?.id}>
          <div className="infoAbout">
            <div className="withPost">
              <div className="imageAffiliatedcompany empty">
                {post?.user?.file_name && (
                  <img src={fileUrl(post?.user?.file_name)} alt="" />
                )}
              </div>
              <div
                className={
                  "nameOfPost " + props?.getBadge(post?.user?.roles)?.badge
                }
              ></div>
            </div>
            <div className="infoFeed">
              <div
                className="titlePost"
                onClick={() =>
                  props?.goToreplayPost(post?.id, post?.category?.id || 0)
                }
              >
                {post?.solve ? <span className="icon-check"></span> : <></>}
                {post?.post_type?.id?.toString() === "2" && (
                  <span className="article">Article</span>
                )}
                <span className="title">{post?.title} </span>
              </div>
              <div className="postBy">
                by{" "}
                <Link to={"/community/activity-user/" + post?.user?.id}>
                  {post?.user?.first_name + " " + post?.user?.last_name}
                </Link>{" "}
                on {moment(post?.created_at).format("MM-DD-Y hh:mm A")}
              </div>
              <div className="postBy">
                Latest activity {dateDiff(post?.updated_at)} by
                <Link
                  to={"/community/activity-user/" + post?.last_activity_by?.id}
                >
                  {" "}
                  {post?.last_activity_by?.first_name +
                    " " +
                    post?.last_activity_by?.last_name}
                </Link>
              </div>
              <div className="subCategory">
                {post?.labels?.map((label: any) => (
                  <span
                    key={label.id}
                    onClick={() =>
                      props?.showLabel(
                        post?.category?.id?.toString(),
                        label.id,
                        post?.post_type?.id
                      )
                    }
                  >
                    {" "}
                    {label.title}{" "}
                  </span>
                ))}
                {post?.tags?.map((tag: any) => (
                  <span
                    key={tag.id}
                    onClick={() =>
                      props?.showLabel("0", tag.id, post?.post_type?.id)
                    }
                  >
                    {tag.name}{" "}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="replyInfo">
            <b> {post?.numberOfReplies} </b>
            {parseInt(post?.numberOfReplies) > 1 ? "Replies" : "Reply"}
          </div>
          <div className="viewInfo">
            <b> {post?.numberOfViews} </b>
            {parseInt(post?.numberOfViews) > 1 ? "Views" : "View"}
          </div>
        </li>
      ))}
    </>
  );
}
