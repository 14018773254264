import React from "react";
import { fileUrl } from "../../../../../../utils";
type Irepresentatif = {
  profil: string;
  isvName: string;
  job_title: string;
  companyName: string;
};
export default function IsvInvestorRepresentative(props: Irepresentatif) {
  return (
    <div className="one-program">
      <div className="photo">
        <img src={fileUrl(props.profil)} alt="" />
      </div>
      <div className="program-info">
        <span className="name">{props.isvName}</span>
        <span className="isv-title">{props.job_title}</span>
        <span className="isv-company">{props.companyName}</span>
      </div>
    </div>
  );
}
