import React, { useEffect } from 'react';
import './static-page.scss';
import Header from '../../widget/header-logo-center/header-logo-center';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { checkIfExist } from '../../../utils';

export default function ClauseAccount() {
  const location: any = useLocation();
  const history = useHistory();

  const removeAllRouteBack = () => {
    history.go(1);
  };

  useEffect(() => {
    window.addEventListener('popstate', removeAllRouteBack);
    return () => {
      window.removeEventListener('popstate', removeAllRouteBack);
    };
  }, []);

  if (!checkIfExist(location?.state?.clausing)) {
    return <Redirect to='/profil-not-found' />;
  }

  return (
    <div className='emailConfirm'>
      <Header />
      <div className='container mx-auto mt-5 pt-5'>
        <h3 className='text-center'>We're sad to see you go...</h3>
        <div className='textConfirm mt-5'>
          Your Devinsider account, including your personal data has been
          permanently deleted. <br />
          You can create a{' '}
          <a onClick={() => history.push('/account-choices')}>
            new Devinsider account
          </a>{' '}
          should you want to participate in our community in the future.
        </div>
      </div>
    </div>
  );
}
