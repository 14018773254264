import { Dispatch } from '../../types/type-action';
import store from '../../store';
import { postData } from './../../../service/api';
// import { updateOemDraft } from "../../../service/applicatif/oem";
import {
  postDataFormDataService,
  getDataService,
  postJsonDataService,
  deleteMethode,
  patchDataService,
} from "../../../service/applicatif/back-end-service";
import { url } from '../../../service/constant/index';
import { deleteWithToken } from '../../../service/api';
import { postFollowCategory } from '../../../service/applicatif/community-service';
export const constante = {
  GET_FEATURED_LABEL: "GET_FEATURED_LABEL",
  GET_LATEST_POST: "GET_LATEST_POST",
  GET_TOP_LABEL: "GET_TOP_LABEL",
  RESTORE_TOP_LABEL: "RESTORE_TOP_LABEL",
  GET_DISCUSSION: "GET_DISCUSSION",
  RESTORE_DISCUSSIONS: "RESTORE_DISCUSSIONS",
  GET_DETAILS_DISCUTION_CATEGORIES: "GET_DETAILS_DISCUTION_CATEGORIES",
  GET_DETAILS_ARTICLE: "GET_DETAILS_ARTICLE",
  GET_RECENT_DISCUSSION: "GET_RECENT_DISCUSSION",
  GET_COMMENT_POST: "GET_COMMENT_POST",
  RESTORE_COMMENT_POST: "RESTORE_COMMENT_POST",
  COMMENT_POST: "COMMENT_POST",
  COMMENT_CHILD_POST: "COMMENT_CHILD_POST",
  GET_CURRENT_CHILD_POST: "GET_CURRENT_CHILD_POST",
  GET_FAVORITE: "GET_FAVORITE",
  RESTORE_FAVORITE: "RESTORE_FAVORITE",
  LIKE_POST: "LIKE_POST",
  LIKE_REPLY: "LIKE_REPLY",
  REMOVE_POST: "REMOVE_POST",
  GET_CATEGORY_FEED: "GET_CATEGORY_FEED",
  FOLLOW_CATEGORY: "FOLLOW_CATEGORY",
  GET_FOLLOWED_USERS: "GET_FOLLOWED_USERS",
  UPDATE_PARENT_POST: "UPDATE_PARENT_POST",
  REMOVE_PARENT_POST: "REMOVE_PARENT_POST",
  MARK_AS_BEST_ANSWER: "MARK_AS_BEST_ANSWER",
  UPDATE_REPLY: "UPDATE_REPLY",
  ADD_FAVORY: "ADD_FAVORY",
  FOLLOW_UNFOLLOW_USERS: "FOLLOW_UNFOLLOW_USERS",
  PIN_POST: "PIN_POST",
  LOCK_POST: "LOCK_POST",
};

/**
 * @function get all feautured label and categori label
 *
 */
export const getLabelCommunity = (callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url?.community?.get_label,
        {},
        store?.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        dispatch({
          type: constante?.GET_FEATURED_LABEL,
          payload: result?.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * @function get all feautured label and categori label
 *
 */
export const getAllLatestPost = (params: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        //Dev #48422,Remove post par le moderator
        url?.community?.get_latest + '/latest_post',
        params,
        store?.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        dispatch({
          type: constante?.GET_LATEST_POST,
          payload: result?.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * @function get all top label
 *
 */
export const getTopLabel = (
  params: { idCategorie: number; postType: number; numberPage: number },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url?.community?.get_top_labels +
          params?.idCategorie +
          '/' +
          params?.postType +
          '/' +
          params?.numberPage,
        {},
        store?.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        dispatch({
          type: constante?.GET_TOP_LABEL,
          payload: {
            data: result?.data,
            numberPage: params?.numberPage,
            postTypes: params.postType,
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * @funtion get all tags
 *
 */
export const getTopTags = (
  params: { postType: number; numberPage: number },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url?.community?.get_top_tags +
          params?.postType +
          '/' +
          params?.numberPage,
        params,
        store?.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        dispatch({
          type: constante?.GET_TOP_LABEL,
          payload: {
            data: result?.data,
            numberPage: params?.numberPage,
            postTypes: params?.postType,
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const restoreTopLabel = (cb: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: constante?.RESTORE_TOP_LABEL,
        payload: {},
      });
      cb();
      return;
    } catch (error: any) {}
  };
};

/**
 * @function get all discution label
 *
 */
export const getDiscutionCategorie = (
  params: {
    categories?: any;
    views: string;
    labels?: string;
    postTypes: number | string;
    limit: number;
    page: number;
    sortBy: string;
    orderBy: string;
    tags?: string;
    notIdlabels: string;
    oem_program?: string;
  },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postJsonDataService(
        url?.community?.get_discution_categorie,
        params,
        store?.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        dispatch({
          type: constante?.GET_DISCUSSION,
          payload: {
            discussions: result?.data?.results,
            total: result?.data?.total,
            numberPage: params?.page,
            postTypes: params.postTypes,
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const restoreDiscussions = (cb: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: constante?.RESTORE_DISCUSSIONS,
        payload: {},
      });
      cb();
      return;
    } catch (error: any) {}
  };
};

export const getDetailsDiscutionCategorie = (cb: (p: any) => void) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url.community.discussion_category,
        {},
        store?.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        dispatch({
          type: constante?.GET_DETAILS_DISCUTION_CATEGORIES,
          payload: result?.data,
        });
        cb(result);
        return;
      }
    } catch (error: any) {
      cb(error?.response);
    }
  };
};

export const getDetailsArticle = (cb: (p: any) => void) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url.community.get_details_article,
        {},
        store?.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        dispatch({
          type: constante?.GET_DETAILS_ARTICLE,
          payload: result?.data,
        });
        cb(result);
        return;
      }
    } catch (error: any) {
      cb(error?.response);
    }
  };
};

export const getOnGoingDiscussions = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: constante?.GET_RECENT_DISCUSSION,
        payload: data,
      });
    } catch (error: any) {}
  };
};

/**
 * @function get partner program
 */
export const getPartnerProgram = (
  params: { postType: number; numberPage: number },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url?.community?.get_partner_program +
          params?.postType +
          '/' +
          params?.numberPage,
        {},
        store?.getState()?.userReducer?.token
      );
      if (result?.status === 200) {
        dispatch({
          type: constante?.GET_TOP_LABEL,
          payload: {
            data: result?.data,
            numberPage: params?.numberPage,
            postTypes: params?.postType,
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * @funtion get all replyes post
 *
 */
export const getAllReplyes = (
  params: { idPost: number | undefined | string; pageId: number },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await getDataService(
        url?.community?.get_all_reply_post +
          params?.idPost +
          '/' +
          params?.pageId,
        {},
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.GET_COMMENT_POST,
          payload: {
            data: response?.data?.results,
            numberPage: params?.pageId,
            totalData: response?.data?.total_reply_to_paginate,
            currentPost: response?.data?.post,
          },
        });
        callback(response);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const restoreCommentPost = (cb: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: constante?.RESTORE_COMMENT_POST,
        payload: {},
      });
      cb();
      return;
    } catch (error: any) {}
  };
};

export const replayPost = (
  params: {
    post: number | string;
    content: string;
    replyParent: string;
  },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await postJsonDataService(
        url?.community?.replay_comment,
        params,
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.COMMENT_POST,
          payload: {
            post: response?.data?.post,
            total: response?.data?.replies_post?.total_reply_to_paginate,
            listReplye: response?.data?.replies_post?.results,
            numberPage: 1,
          },
        });
        callback(response);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const replayChildPost = (
  params: {
    post: number | string;
    content: string;
    replyParent: string | undefined;
    idDeepReply: string | undefined;
  },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await postJsonDataService(
        url?.community?.replay_comment,
        params,
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.COMMENT_CHILD_POST,
          payload: {
            post: response?.data?.post,
            totalPaginatePost:
              response?.data?.replies_post?.total_reply_to_paginate,
            listReplyePost: response?.data?.replies_post?.results,
            currentPagePost: 1,
            childReplye: response?.data?.replies_sisters?.results,
            totalSeeMoreChild:
              response?.data?.replies_sisters?.total_reply_to_paginate,
            currentChildPage: 1,
            parentComment: params?.idDeepReply || params?.replyParent,
          },
        });
        callback(response);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const getPaginateChildPost = (
  params: {
    idParent: number | string;
    curretPage: number;
  },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await getDataService(
        url?.community?.get_child_post +
          params?.idParent +
          '/' +
          params?.curretPage,
        {},
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.GET_CURRENT_CHILD_POST,
          payload: {
            child: response?.data?.results,
            currentChildPage: params?.curretPage,
            idParent: params?.idParent,
            numberChild: response?.data?.total_reply_to_paginate,
          },
        });
        callback(response);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const getFavorite = (
  params: {
    limit: number;
    page: number;
    sortBy: string;
    orderBy: string;
  },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await postJsonDataService(
        url?.community?.favorite?.get_favorite,
        params,
        store?.getState()?.userReducer?.token
      );

      if (response?.status === 200) {
        dispatch({
          type: constante.GET_FAVORITE,
          payload: {
            discussions: response?.data?.favorites,
            total: response?.data?.total_favorites_to_paginate,
            currentPage: params?.page,
          },
        });
        callback(response);
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const restoreFavorite = (callback: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: constante.RESTORE_FAVORITE,
        payload: {},
      });
      callback();
      return;
    } catch (error: any) {}
  };
};

export const removeFavorite = (
  params: { posts: string },
  callback: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await deleteWithToken({
        url: url?.community?.favorite?.remove_favorite,
        data: params,
        token: store && store?.getState()?.userReducer?.token,
      });
      if (response?.status === 200) {
        dispatch({
          type: constante?.RESTORE_FAVORITE,
          payload: {},
        });
        callback();
      }
    } catch (error: any) {}
  };
};

export const likePost = (
  params: { post: number | undefined },
  callback: (p: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.community?.like_post,
        params,
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.LIKE_POST,
          payload: response?.data,
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};

export const likeReply = (params: any, callback: (p: any) => void) => {
  return async (dispatch: Dispatch) => {
    try {
      let dataToSent = {
        reply: params?.isChildreen ? params?.idChildreen : params?.idToLike,
      };
      let response = await postJsonDataService(
        url?.community?.like_reply,
        dataToSent,
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.LIKE_REPLY,
          payload: {
            idToLike: params?.idToLike,
            number_likes: response?.data?.number_likes,
            isChildreen: params?.isChildreen,
            idChildreen: params?.idChildreen,
          },
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};

// Dev #48290 Moderator suppression message
export const removePostAction = (
  idParent: string,
  isChildreen: boolean,
  idChildreen: string,
  totalRemove: boolean,
  callback: (p: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await deleteMethode(
        url?.community?.delete_post +
          (isChildreen ? idChildreen : idParent) +
          '/' +
          (totalRemove ? 1 : 0),
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.REMOVE_POST,
          payload: {
            idParent,
            isChildreen,
            idChildreen,
            userToRemove: store.getState().userReducer,
          },
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};

export const getCategoriesFeed = (callback: (p: any) => void) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await getDataService(
        url?.community?.get_categorie_feed,
        {},
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.GET_CATEGORY_FEED,
          payload: response?.data,
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};

export const followOrUnfollowCategory = (
  params: {
    idCategory: number;
    state: boolean;
    key: number;
  },
  callback: (p: any, key: number) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postFollowCategory(
        params?.idCategory,
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 201) {
        dispatch({
          type: constante?.FOLLOW_CATEGORY,
          payload: params,
        });
        callback(response, params?.key);
        return;
      }
      callback(response, params?.key);
    } catch (error: any) {}
  };
};

export const getFollowedCommunityUsers = (
  page: any,
  callback: (p: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await getDataService(
        url?.community?.get_followed_users + '/' + page,
        {},
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.GET_FOLLOWED_USERS,
          // payload: response ?.data,
          payload: {
            data: response?.data,
          },
        });
        callback(response);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

export const updateParentPostAction = (
  params: {
    idParent: string | undefined;
    description: string;
    category: string;
  },
  callback: (p: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postDataFormDataService(
        {
          description: params?.description,
          category: params?.category,
        },
        url?.community?.updateParentPost + params?.idParent,
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.UPDATE_PARENT_POST,
          payload: response?.data,
        });
      }
      callback(response);
    } catch (error: any) {}
  };
};
//Dev #47878 [Moderator account] Il faut qu'il y ait 2 options de suppression de post.
export const removeParentPostAction = (
  parentId: string,
  callback: (p: any) => void,
  totalRemove: boolean = false
) => {
  return async (dispatch: Dispatch) => {
    try {
      if (totalRemove === false) {
        let response = await deleteMethode(
          url?.community?.delete_parent_post + parentId,
          store?.getState()?.userReducer?.token
        );
        if (response?.status === 204) {
          dispatch({
            type: constante?.REMOVE_PARENT_POST,
            payload: {
              parentId,
              userToRemove: store.getState().userReducer,
            },
          });
        }
        callback(response);
      } else {
        let response = await postData(
          url?.community?.get_latest + '/total_remove/' + parentId,
          {},
          store?.getState()?.userReducer?.token
        );

        console.log('response ====>', response);

        callback(response);
      }
    } catch (error: any) {}
  };
};

export const markAsBestAnswerAction = (
  idParent: string,
  isChild: boolean,
  idChild: string,
  callback: (p: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.community?.mark_as_best_anwer + (isChild ? idChild : idParent),
        {},
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.MARK_AS_BEST_ANSWER,
          payload: {
            post: response?.data?.post,
            totalPaginatePost:
              response?.data?.replies_post?.total_reply_to_paginate,
            listReplyePost: response?.data?.replies_post?.results,
            currentPagePost: 1,
            childReplye: response?.data?.replies_sisters?.results,
            totalSeeMoreChild:
              response?.data?.replies_sisters?.total_reply_to_paginate,
            currentChildPage: 1,
            parentComment: idParent,
            isChild,
          },
        });
      }
      callback(response);
    } catch (error: any) {}
  };
};

export const updateReplyAction = (
  idParent: string,
  isChildreen: boolean,
  idChildreen: string,
  content: string,
  callback: (p: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.community?.update_reply + (isChildreen ? idChildreen : idParent),
        {
          content,
        },
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.UPDATE_REPLY,
          payload: {
            idParent,
            isChildreen,
            idChildreen,
            content,
          },
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};

export const markAsFovoriteAction = (
  idParent: any,
  callback: (p: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await postJsonDataService(
        url?.community?.favorite?.add_favorite + idParent,
        {},
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.ADD_FAVORY,
          payload: {
            data: response?.data,
          },
        });
      }
      callback(response);
    } catch (error: any) {}
  };
};

export const followUnfollowUserAction = (
  data: { user: any },
  callback: (p: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = {
        id: data?.user?.id,
      };
      let response = await postData(
        url?.community?.follow_unfollow_user,
        params,
        store && store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.FOLLOW_UNFOLLOW_USERS,
          payload: data?.user,
        });
        callback(response);
        return;
      }
    } catch (error: any) {}
  };
};

export const lockPostAction = (
  post: string | undefined,
  callback: (p: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await patchDataService(
        url?.community?.lock_post + post,
        {},
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.LOCK_POST,
          payload: response?.data,
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};

export const pinPostAction = (
  post: string | undefined,
  callback: (p: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await patchDataService(
        url?.community?.pin_post + post,
        {},
        store?.getState()?.userReducer?.token
      );
      if (response?.status === 200) {
        dispatch({
          type: constante?.PIN_POST,
          payload: response?.data,
        });
        callback(response);
      }
    } catch (error: any) {}
  };
};