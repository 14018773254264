import React from "react";
import { fileUrl } from "../../../../../../utils";

type Iprops = {
  profil: string;
  companyName: string;
  roleDiscussion: string;
};
export default function isvToOem(props: Iprops) {
  return (
    <div className="d-flex align-items-center">
      <div className="photo-title">
        <img src={fileUrl(props.profil)} alt="" />
      </div>
      <div className="info-title">
        <div className="company-title">
          <a href="">{props?.companyName}</a>
        </div>
        <div className="company-type">{props?.roleDiscussion}</div>
      </div>
    </div>
  );
}
