import { userInfo } from "os";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { getInfoUserAction } from "../../../redux/actions/acounts-action";
import store from "../../../redux/store";
import { updateInfoUser } from "../../../service/applicatif/acount";

export default function AboutUser() {
  const location: any = useLocation();

  const dispatch = useDispatch();
  const [updatingAbout, setUpdatingAbout] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(
    location?.state?.openModalAboutEdit ? true : false
  );
  const [about, setAbout] = useState<string>("");

  const updateAboutUser = (about: string) => {
    (async () => {
      setUpdatingAbout(true);
      const response = await updateInfoUser(
        { plainText: about },
        store.getState()?.userReducer?.token
      );
      //dispatch update
      dispatch(getInfoUserAction(() => {}));
      setUpdatingAbout(false);
      setShowModal(false);
    })();
  };

  return {
    updatingAbout,
    updateAboutUser,
    showModal,
    setShowModal,
    about,
    setAbout,
  };
}
