import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDetailsDiscutionCategorie } from "../../../redux/actions/community-action";
import { rootState } from "../../../redux/reducers";
import UseContributor from "../home-page/top-liked-contributions";
import UseTopBestAnswer from "../home-page/top-best-answer-author";
import UseTopBestAuthor from "../home-page/top-liked-author";
import UseSearch from "../../../screen/widget/community/search/use-search";

export default function UseHomePageDiscussionCategories() {
  const dispatch = useDispatch();
  const { topLikedContributions, loaderContributor } = { ...UseContributor() };
  const { topBestAnswerAuthor, loaderBestAnswer } = { ...UseTopBestAnswer() };
  const { topLikedAuthor, loaderAuthor } = { ...UseTopBestAuthor() };
  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();
  const history = useHistory();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );

  const cb = () => {};

  useEffect(() => {
    //scrool page on top
    window.scrollTo(0, 0);
    dispatch(getDetailsDiscutionCategorie(cb));
    return () => {};
  }, []);

  const goToLabelCategory = (idCategory: any, labelId: string) => {
    history.push("/community/one-category/" + idCategory + "/" + labelId);
  };

  const goToreplayPost = (idCategorie: string, postId: string) => {
    history.push("/community/reply-post/" + idCategorie + "/" + postId);
  };

  const viewAllContributor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "contributions",
    });
  };

  const viewAllAuthor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "author",
    });
  };

  const goHomeCommunity = () => {
    history?.push("/community/home", {
      activeKey: "author",
    });
  };

  const goActivity = (id: string) => {
    history.push("/community/activity-user/" + id);
  };

  const viewAllBestAnswerAuthor = () => {
    history?.push("/community/best-answer");
  };

  return {
    communityReducer,
    goToLabelCategory,
    topLikedContributions,
    topBestAnswerAuthor,
    topLikedAuthor,
    viewAllContributor,
    viewAllAuthor,
    loaderContributor,
    loaderAuthor,
    loaderBestAnswer,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    goHomeCommunity,
    goToreplayPost,
    goActivity,
    viewAllBestAnswerAuthor,
  };
}
