import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { resetPassword } from "../../../service/applicatif/acount";
const queryString = require("query-string");

export default function useResetPassword() {
  let params: any = useLocation();
  const history = useHistory();
  const [tokenId, setTokenId] = useState(queryString.parse(params?.search));
  const [password, setpassword] = useState<string>("");
  const [loader, setloader] = useState<boolean>(false);
  const [errorResetPassword, setErrorResetPassword] = useState<boolean>(false);

  const resetPasswordUser = async () => {
    setloader(true);
    const data = {
      password,
    };
    let response = await resetPassword(data, tokenId?.id);

    if (response && response.status == 200) {
      setloader(false);
      history.push("/signin");
    } else {
      setloader(false);
      setErrorResetPassword(true);
    }
  };
  return {
    tokenId,
    password,
    loader,
    errorResetPassword,
    setpassword,
    resetPasswordUser,
  };
}
