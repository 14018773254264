import UseProgramSegment from "../../../controller/oem-ctr/block-component/use-program-segment";
import { constante } from "../../actions/find-program-action";
import {
  IAction,
  IFindProgram,
  IProgram,
} from "../../types/find-program-interface";

export const initialState: IFindProgram = {
  hq_location: [],
  oem_program_type: [],
  program_solution: [],
  directory_programs: {
    programs: [],
    total: 0,
  },
  event_type: [],
  top_tags: [],
  program_events: {
    events: [],
    total: 0,
  },
  company_page_completed: false,
};

export const findProgramReducer = (
  state = initialState,
  action: IAction
): IFindProgram => {
  switch (action?.type) {
    case constante.GET_FIND_PROGRAMS_MENU:
      return {
        ...state,
        ...action?.payload,
      };
    case constante.GET_DIRECTORY_PROGRAMS:
      return {
        ...state,
        directory_programs: {
          programs: action?.payload?.results,
          total: action?.payload?.total,
        },
      };
    case constante.SHOW_MORE:
      return {
        ...state,
        directory_programs: {
          ...state.directory_programs,
          programs: [
            ...state.directory_programs.programs.map((e) => {
              if (e?.id === action?.payload?.program?.id) {
                return {
                  ...e,
                  ...action?.payload?.data,
                  information_fetched: true,
                };
              } else return e;
            }),
          ],
        },
      };
    case constante.GET_FILTERED_EVENT:
      return {
        ...state,
        ...action?.payload,
      };
    case constante.GET_EVENTS:
      return {
        ...state,
        program_events: {
          events: action?.payload?.results,
          total: action?.payload?.total,
        },
      };

    default:
      return state;
  }
};
