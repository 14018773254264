/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import * as T from "../../../../../../../controller/company-ctr/types/types-company-page";
import defaultCompany from "../../../../../../../resources/images/defaultCompany.png";

export default function FormBecomeVerified(props: T.TBecomeVerified) {
  return (
    <div>
      {/* Become a verified profesionnal */}
      <Modal
        show={props?.isShowBecomeVerified}
        className="createCompanypopup smallPopup forStep5custom"
      >
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Hold on!</h2>
            <a href="javascript:;" className="close">
              <span
                className="icon-close-pop"
                onClick={() => props?.setIsBecomeVerified()}
              ></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight forBecomeVerified">
          <div className="contentBorder">
            <div className="col-12 center">
              <img src={defaultCompany} alt="" />
              <p>
                <b>Become a verified professional</b>
              </p>
              <p>To access this page you must verify your work mail.</p>
              <div className="contentBorder formForEmail text-center">
                <button
                  className="btn btn-devinsider px-5"
                  onClick={() => props?.showFormSendEmail()}
                >
                  Let's Go
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end Become a verified profesionnal*/}
    </div>
  );
}
