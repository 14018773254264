import './Post.scss';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Select from 'react-select';
import Header from '../../../widget/index';
import FormPost from '../../../widget/post-wysiwig/index';
import { IPropsPost } from '../../../widget/post-wysiwig/type';
import usePostCtr from '../../../../controller/community-ctr/bloc-community-ctr/use-post-discution-ctr';
import Footer from '../../../widget/footer/footer';
import ModalFeaturedArticle from '../../../widget/payement-oem/post-featured-article/index';
import { Link, Redirect, useLocation } from 'react-router-dom';
import ModalPreview from '../../../widget/preview/index';
export type IPropsDiscution = IPropsPost & {};
export default function PostDiscutions() {
  const providerPost = usePostCtr();
  const [modalPreview, setModalPreview] = useState<boolean>(false);
  const typeDiscussion = providerPost?.communityReducer?.postType?.find(
    (el: any) => el?.id?.toString() === providerPost?.postType?.toString()
  )?.id;
  const location = useLocation();

  console.log('location', location);

  const isDiscussion = typeDiscussion?.toString() === '1';

  const isArticle = typeDiscussion?.toString() === '2';

  const txtPost = isDiscussion ? 'Community' : isArticle ? 'Article' : '';

  useEffect(() => {
    const root = document.getElementsByClassName('rdw-option-wrapper');
    const foontSizeWrapper = document.getElementsByClassName(
      'rdw-fontsize-wrapper'
    );
    const fontFamillyWrapper = document.getElementsByClassName(
      'rdw-fontfamily-wrapper'
    );

    const alignWrapper = document.getElementsByClassName(
      'rdw-text-align-wrapper'
    );

    const fontblockWrapper =
      document.getElementsByClassName('rdw-block-wrapper');

    const monospace = root[3];
    const subsciprt = root[4];
    const font = root[5];
    const puiss = root[6];
    if (monospace) {
      monospace.className = 'd-none';
      subsciprt.className = 'd-none';
      font.className = 'd-none';
      puiss.className = 'd-none';
    }

    if (foontSizeWrapper.length > 0) {
      foontSizeWrapper[0].className = 'd-none';
    }

    if (fontFamillyWrapper.length > 0) {
      fontFamillyWrapper[0].className = 'd-none';
    }

    if (alignWrapper.length > 0 && isDiscussion) {
      alignWrapper[0].className = 'd-none';
    }

    if (fontblockWrapper.length > 0) {
      if (isDiscussion) {
        fontblockWrapper[0].className = 'd-none';
      }
    }
  }, []);

  const handleModal = () => {
    setModalPreview(!modalPreview);
  };

  if (!isDiscussion && !isArticle) {
    return <Redirect to='/community/home' />;
  }

  return (
    <div>
      <Header />
      <ModalFeaturedArticle
        {...providerPost.providerUsePostFeturedArticle}
        continuePostFeaturedArticle={providerPost.continuePostFeaturedArticle}
        loading={providerPost.loader}
      />
      <ModalPreview
        showModal={modalPreview}
        handleModal={handleModal}
        content={providerPost?.stateFormWysywig}
      />
      <section className='discussion'>
        <div className='container mx-auto'>
          <ul className='breadcrumbPerso'>
            <li>
              <Link to={'#'}>Community</Link>
            </li>
            <li>New Forum Discussion</li>
          </ul>
          <div className='rightCommunityForum allWidth oneCateg postContainer'>
            <div className='labelLeft'>
              <div className='block'>
                <h2 className='title'>
                  Categories{' '}
                  {providerPost.isNotCategorySelected && (
                    <div className='container-error pl-2 d-inline'>
                      <span className='error-red'> error </span>
                      <span className='txt-error'>
                        You will need to select one category
                      </span>
                    </div>
                  )}
                </h2>
                <Select
                  classNamePrefix='categories'
                  options={providerPost?.categorieListeTransorm}
                  onChange={(el: any) => {
                    providerPost?.setselectedCategorie(el?.value);
                  }}
                  placeholder='Select a category ...'
                />
              </div>
              {providerPost?.selectedCategorie?.toString() !== '0' && (
                <>
                  <div className='block'>
                    <h2 className='title'>Labels</h2>
                    <label htmlFor=''>
                      Choose a label:
                      {providerPost?.labelError && (
                        <div className='container-error pl-2 d-inline'>
                          <span className='error-red'> error </span>
                          <span className='txt-error'>
                            You need to select at least 1 label and no more than
                            3 labels.
                          </span>
                        </div>
                      )}
                    </label>
                    <div className='listOfTopic'>
                      {/* providerPost?.categorieList */}
                      {providerPost
                        ?.getLables()
                        ?.map(
                          (
                            el: { id: number; title: string },
                            index: number
                          ) => (
                            <span
                              className={`topic ${
                                providerPost?.dataListe?.find(
                                  (element: string) =>
                                    element.trim() === el?.title.trim()
                                )
                                  ? 'active'
                                  : ''
                              } `}
                              key={index}
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                providerPost?.handleSelectedLabels(
                                  ' ' + el?.title?.toString()
                                )
                              }
                            >
                              {el?.title}
                            </span>
                          )
                        )}
                    </div>
                    <div className='form-control relative'>
                      <input
                        type='text'
                        className='form-control search-msg lab'
                        placeholder=''
                        value={providerPost?.txtFilter}
                        onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                          providerPost?.setTxtFilter(evt.target.value)
                        }
                      />

                      <div
                        className={`listOfChoice ${
                          providerPost?.dataLabelFilter?.length < 1
                            ? 'd-none'
                            : ''
                        }`}
                      >
                        <ul>
                          {providerPost?.dataLabelFilter?.map((el: any) => (
                            <li
                              onClick={() =>
                                providerPost.handleFilterSelect(el?.value)
                              }
                            >
                              {el?.value}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <label htmlFor=''>
                      <b className='miniBold'>Use commas between labels</b>
                    </label>
                  </div>
                  <div className='block'>
                    <div className='custom-control custom-checkbox orangecheck'>
                      <input
                        type='checkbox'
                        id='ifreplies'
                        className='custom-control-input'
                        onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                          providerPost?.setEmailMe(evt?.target?.checked)
                        }
                        checked={providerPost?.emailMe}
                      />
                      <label
                        htmlFor='ifreplies'
                        className='custom-control-label'
                      >
                        Email me when someone replies
                      </label>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className='containerCategory'>
              <h2 className='title'>
                {`New discussion in '${txtPost}'`}{' '}
                {providerPost?.titleError && (
                  <div className='container-error pl-2 d-inline'>
                    <span className='error-red'> error </span>
                    <span className='txt-error'>Subject is required</span>
                  </div>
                )}
              </h2>
              <FormPost
                editorState={providerPost?.stateFormWysywig}
                setEditorState={providerPost?.setEditorStatePost}
                cancelPost={() => providerPost?.cancelPostDuscussion()}
                sendPost={() => providerPost?.postDiscussion()}
                object={providerPost?.object}
                setObject={(evt: string) => providerPost?.setTxtObject(evt)}
                loader={
                  providerPost.isArticleAndOem ? false : providerPost?.loader
                }
                //Dev #47720 V2 [Community bug] Je ne parviens pas a tager le pseudo d'un utilisateur
                mention={providerPost?.mentions}
                updateMention={providerPost?.updateMention}
                showPreview={() => handleModal()}
                uploadPostType={txtPost}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer postType={providerPost?.postType} />
    </div>
  );
}
