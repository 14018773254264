import React, { ChangeEvent } from 'react';
import Header from '../../../../widget/header-already/header';
import CheckIcon from '@material-ui/icons/Check';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import UseCompleteOemAccount from '../../../../../controller/account-ctr/create-oem-account/use-complete-oem-account';
import { Redirect } from 'react-router-dom';
import { checkIfExist } from '../../../../../utils';

export default function CompleteProfilOem() {
  const {
    navigateCompleteOemInformation,
    firstNameOem,
    setFirstName,
    lastNameOem,
    setLastName,
    emailOem,
    setEmail,
    passwordOem,
    setPassword,
    confirmPasswordOem,
    setConfirmPassword,
    acceptCguOem,
    setAcceptCgu,
    checkIfAlreadyEmailExist,
    errorFirstNameOem,
    errorLastNameOem,
    errorEmailOem,
    errorPasswordOem,
    errorConfirmPasswordOem,
    errorAcceptCguOem,
    txtEmailError,
    txtPasswordError,
    loader,
    emailNotSafe,
    emailSafe,
    tokenId,
  } = UseCompleteOemAccount();

  if (!checkIfExist(tokenId)) {
    return <Redirect to='/partner-program-registration' />;
  }

  return (
    <div className='layoutinvestor'>
      <Header />
      <section className='investor'>
        <div className='formInvestor'>
          <h1 className='title'>
            OEM/Ecosystem partner program registration
            <span>Getting started with Devinsider is fast and free.</span>
          </h1>
          <div className='formInfo'>
            <div className='form-group required pb-2'>
              <label htmlFor=''>First name </label>
              <div
                className={`container-error pl-2 ${
                  errorFirstNameOem ? 'd-inline' : 'd-none'
                }`}
              >
                <span className='error-red'> error </span>
                <span className='txt-error'>This field is required</span>
              </div>
              <input
                type='text'
                className='input-text form-control'
                id='firstname'
                value={firstNameOem}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  setFirstName(evt.target.value)
                }
              />
            </div>
            <div className='form-group required pb-2'>
              <label htmlFor=''>Last name </label>
              <div
                className={`container-error pl-2 ${
                  errorLastNameOem ? 'd-inline' : 'd-none'
                }`}
              >
                <span className='error-red'> error </span>
                <span className='txt-error'>This field is required</span>
              </div>
              <input
                type='text'
                className='input-text form-control'
                id='lastname'
                value={lastNameOem}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  setLastName(evt.target.value)
                }
              />
            </div>
            <div className='form-group required pb-2 safeMail'>
              <label htmlFor='' className={'labelForEmailgetStarted '}>
                {emailSafe && <CheckIcon className='mailcheckOK' />}
                Email
              </label>

              {errorEmailOem && (
                <div className='container-error d-inline pl-2'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>{txtEmailError}</span>
                </div>
              )}
              {emailNotSafe && (
                <div className='container-error d-inline pl-2'>
                  <span className='error-red'> error </span>
                  <span className='txt-error'>{txtEmailError}</span>
                </div>
              )}
              <input
                type='email'
                className='input-text form-control'
                id='email'
                value={emailOem}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  setEmail(evt.target.value)
                }
                onBlur={(e: ChangeEvent<HTMLInputElement>): Promise<void> =>
                  checkIfAlreadyEmailExist(e.target.value)
                }
              />
            </div>
            <div className='form-group required pb-2'>
              <label htmlFor=''>Password</label>{' '}
              <div
                className={`container-error pl-2 ${
                  errorPasswordOem ? 'd-inline' : 'd-none'
                }`}
              >
                <span className='error-red'> error </span>
                <span className='txt-error'>{txtPasswordError}</span>
              </div>
              <input
                type='password'
                className='input-text form-control'
                id='password'
                value={passwordOem}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  setPassword(evt.target.value)
                }
              />
            </div>
            <div className='form-group required pb-2'>
              <label htmlFor=''>Verify password</label>
              <div
                className={`container-error pl-2 ${
                  errorConfirmPasswordOem ? 'd-inline' : 'd-none'
                }`}
              >
                <span className='error-red'> error </span>
                <span className='txt-error'>passwords do not match</span>
              </div>
              <input
                type='password'
                className='input-text form-control'
                id='vpassword'
                value={confirmPasswordOem}
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(evt.target.value)
                }
              />
            </div>
          </div>
          <div className='form-group form-check'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={acceptCguOem}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                setAcceptCgu(evt.target.checked)
              }
            />
            <label htmlFor='' className='form-check-label termsLink'>
              I agree to Devinsider’s&nbsp;
              <a onClick={() => window.open('/term-of-use', '_blank')}>
                terms
              </a>{' '}
              and &nbsp;
              <a onClick={() => window.open('/privacy-policy', '_blank')}>
                privacy policy
              </a>
              .
            </label>
            <div
              className={`container-error pl-2 ${
                errorAcceptCguOem ? '' : 'd-none'
              }`}
            >
              <span className='error-red'> error </span>
              <span className='txt-error'>This field is required</span>
            </div>
          </div>
          <div className='pt-3'>
            <button
              className='btn btn-devinsider large containerBtnBtn'
              onClick={() => navigateCompleteOemInformation()}
              disabled={loader}
            >
              {loader ? <Loader /> : ' Register'}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
