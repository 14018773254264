import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { rootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  getDiscutionCategorie,
  getLabelCommunity,
  getTopLabel,
  restoreDiscussions,
  restoreTopLabel,
} from "../../../redux/actions/community-action";
import { getDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import { IPropsContributor } from "../home-page/top-liked-contributions";
import { IpropsAuthor } from "../home-page/top-liked-author";
import { IPropsBestAnswer } from "../home-page/top-best-answer-author";
import { postFollowCategory } from "../../../service/applicatif/community-service";
import UseSearch from "../../../screen/widget/community/search/use-search";
const queryString = require("query-string");

export default function UseFilterCategorieDiscution() {
  const history = useHistory();
  const params: any = useParams();
  const location = useLocation();

  const idCategorie = params?.id_category;

  const idLabel =
    params?.label_id && params?.label_id?.toString() !== "0"
      ? params?.label_id
      : null;

  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [titleCategories, setTitleCategories] = useState<string>("");
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [limitTopLabel, setLimitTopLabel] = useState<number>(10);
  const [lastIdCategorie, setlastIdCategorie] = useState<number>(idCategorie);
  const isAllPostType: { article: number } = queryString.parse(
    location?.search
  );

  const [topLikedContributions, setTopLikedContributions] = useState<
    Array<IPropsContributor>
  >([]);
  const [topLikedAuthor, setTopLikedAuthor] = useState<Array<IpropsAuthor>>([]);
  /* get top like contributions */
  const [topBestAnswerAuthor, setTopBestAnswerAuthor] = useState<
    Array<IPropsBestAnswer>
  >([]);
  const [follow, setFollow] = useState<boolean>(false);
  const [followLoader, setFollowLoader] = useState<boolean>(false);

  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();

  const isUserConnected = userReducer?.token;

  /**
   * All view in Service
   */
  const [allViews, setAllViews] = useState<Array<any>>([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  /**
   *
   * state for all discutions
   */
  const [limitDiscution, setlimitDiscution] = useState<number>(10);
  /**
   * View selected
   */
  const [view, setView] = useState<string>("");
  const [currentDiscutionPage, setCurrentDiscutionPage] = useState<number>(1);
  const [sortedBy, setsortedBy] = useState<
    "post_date" | "first_activity" | "last_activity" | "replies" | "views"
  >("post_date");
  const [orderBy, setOrderBy] = useState("DESC");

  const [allNotSelectedLabel, setAllNotSelectedLabel] = useState<Array<number>>(
    []
  );

  const [allSelectedLabel, setAllSelectedLabel] = useState<Array<number>>([]);

  const [loaderTopLabel, setLoaderTopLabel] = useState<boolean>(false);

  //Get  all top and best
  useEffect(() => {
    async function getData() {
      const result = await getDataService(
        url.community.get_top_liked_contribution + "/" + idCategorie + "/" + 1,
        {},
        userReducer?.token
      );
      if (result?.status === 200) {
        setTopLikedContributions(result?.data);
      }
    }

    async function getTopLikeAythor() {
      const result = await getDataService(
        url.community.get_top_liked_author + "/" + idCategorie + "/" + 1,
        {},
        userReducer?.token
      );
      if (result?.status === 200) {
        setTopLikedAuthor(result?.data);
      }
    }

    async function getBestAnswer() {
      const result = await getDataService(
        url.community.get_top_best_answer_author,
        {},
        userReducer?.token
      );
      if (result?.status === 200) {
        setTopBestAnswerAuthor(result?.data);
      }
    }

    getData();
    getTopLikeAythor();
    getBestAnswer();
  }, [idCategorie]);

  const cbChangeCategorie = (data: any) => {
    setlastIdCategorie(idCategorie);
    setLoaderTopLabel(false);
  };

  const getNewTopLabel = () => {
    setLoaderTopLabel(true);
    dispatch(
      getTopLabel(
        {
          idCategorie: idCategorie,
          postType: isAllPostType?.article ? -1 : 1,
          numberPage: 1,
        },
        cbChangeCategorie
      )
    );
  };

  useEffect(() => {
    const extractTitle =
      communityReducer?.discussionCategories?.find(
        (el: any) => el?.id?.toString() === idCategorie?.toString()
      )?.title || "";

    setTitleCategories(extractTitle);
    /**
     * update top label when categorie change
     * if last idCategories is diffirent of new idCategories
     * remove last topLabel and get new Label
     */
    if (lastIdCategorie != idCategorie) {
      setcurrentPage(1);
      dispatch(restoreTopLabel(getNewTopLabel));
    }

    const checkFollowing = communityReducer.discussionCategories.filter(
      (e) => e.id.toString() === idCategorie?.toString()
    )?.[0]?.is_followed;

    setFollow(checkFollowing || false);

    return () => {};
  }, [idCategorie]);

  /**
   *
   * Get all filtered views
   */
  useEffect(() => {
    async function getFiltredView() {
      let response = await getDataService(
        url?.community?.get_filtred_view + idCategorie + "/" + 1,
        {},
        userReducer?.token
      );

      if (response?.status == 200) {
        let tempArrayResult = response?.data;
        setAllViews(tempArrayResult);
      }
    }
    getFiltredView();
    return () => {};
  }, [idCategorie]);

  const cb = (data: any) => {
    setLoaderTopLabel(false);
  };

  /**
   *
   * Get top label if current index top label is inferieur of current page
   */
  useEffect(() => {
    /**
     * if current page is inferieur to state current page , get new topLabel
     */
    let topLabelStore = communityReducer?.topLabel;
    if (topLabelStore?.currentPage < currentPage) {
      setLoaderTopLabel(true);

      dispatch(
        getTopLabel(
          {
            idCategorie: idCategorie,
            postType: isAllPostType?.article ? -1 : 1,
            numberPage: currentPage,
          },
          cb
        )
      );
    }
    return () => {
      //remove top label when leave page
      dispatch(restoreTopLabel(() => {}));
    };
  }, []);

  const cbNext = () => {
    setcurrentPage(currentPage + 1);
    setLoaderTopLabel(false);
  };

  const nextPage = (nextPage: number) => {
    //Check if allData in store is inferieur of nextPage * limit
    let checkLoadNextData =
      communityReducer?.topLabel?.currentPage < nextPage ? true : false;
    //if we must getData  , then we get a new topLabel
    if (checkLoadNextData) {
      setLoaderTopLabel(true);
      dispatch(
        getTopLabel(
          {
            idCategorie: idCategorie,
            postType: isAllPostType?.article ? -1 : 1,
            numberPage: nextPage,
          },
          cbNext
        )
      );
    } else {
      setcurrentPage(currentPage + 1);
    }
  };

  const clikPrev = (index: number) => {
    setcurrentPage(currentPage - 1);
  };

  /**
   *
   * Get paginate discution of data
   */
  useEffect(() => {
    const labelId =
      params?.label_id && params?.label_id?.toString() !== "0"
        ? params?.label_id
        : null;

    setAllSelectedLabel(labelId ? [labelId] : []);

    let paramsDiscution = {
      categories: lastIdCategorie,
      views: view,
      labels: labelId ? labelId : "",
      postTypes: isAllPostType?.article ? "1,2" : "1",
      limit: limitDiscution,
      page: currentDiscutionPage,
      sortBy: sortedBy,
      orderBy: "DESC",
      notIdlabels: allNotSelectedLabel?.join(),
    };
    /**
     * if current page is inferieur to state current page , get new topLabel
     */

    let discussions = communityReducer?.paginate_discussions;
    let checkPageStateInstore = discussions?.currentPage < currentDiscutionPage;

    if (checkPageStateInstore) {
      dispatch(getDiscutionCategorie(paramsDiscution, cb));
    }
    return () => {
      //remove top label when leave page
      dispatch(restoreDiscussions(() => {}));
    };
  }, [params?.label_id]);

  const getNewDiscution = () => {
    let data = {
      categories: idCategorie,
      views: view,
      labels: allSelectedLabel?.join(),
      postTypes: isAllPostType?.article ? "1,2" : "1",
      limit: limitDiscution,
      page: currentDiscutionPage,
      sortBy: sortedBy,
      orderBy: "DESC",
      notIdlabels:
        lastIdCategorie != idCategorie ? "" : allNotSelectedLabel?.join(),
    };
    dispatch(getDiscutionCategorie(data, cbChangeCategorie));
  };

  /**
   *
   * Remove discution when categorie page change
   */
  useEffect(() => {
    if (lastIdCategorie != idCategorie) {
      setCurrentDiscutionPage(1);
      dispatch(restoreDiscussions(() => getNewDiscution()));
    }
    return () => {};
  }, [idCategorie]);

  const getNewDiscutionAfterUpdate = (
    key: string,
    value: any,
    isRestorePaginate: boolean = false
  ) => {
    let checkIfResetCurrentPage = currentDiscutionPage;

    if (isRestorePaginate) {
      setCurrentDiscutionPage(1);
    }
    let data = {
      categories: idCategorie,
      views: view,
      labels: allSelectedLabel?.join(),
      postTypes: isAllPostType?.article ? "1,2" : "1",
      limit: limitDiscution,
      page: checkIfResetCurrentPage,
      sortBy: sortedBy,
      orderBy: orderBy,
      notIdlabels: allNotSelectedLabel?.join(),
      [key]: value,
    };
    dispatch(getDiscutionCategorie(data, cb));
  };

  const setLabelsFilter = (labelId: any) => {
    setLoader(true);
    const isSelectedFirst = idLabel;

    let tempLabel = isSelectedFirst
      ? allSelectedLabel?.find((el: number) => el == labelId)
      : allNotSelectedLabel?.find((el: number) => el == labelId);

    if (tempLabel) {
      isSelectedFirst
        ? setAllSelectedLabel(
            allSelectedLabel?.filter((el: number) => el != labelId)
          )
        : setAllNotSelectedLabel(
            allNotSelectedLabel?.filter((el: number) => el != labelId)
          );
      dispatch(
        restoreDiscussions(() =>
          getNewDiscutionAfterUpdate(
            isSelectedFirst ? "labels" : "notIdlabels",
            isSelectedFirst
              ? allSelectedLabel?.filter((el: number) => el != labelId)?.join()
              : allNotSelectedLabel
                  ?.filter((el: number) => el != labelId)
                  ?.join(),
            true
          )
        )
      );
    } else {
      isSelectedFirst
        ? setAllSelectedLabel([...allSelectedLabel, parseInt(labelId)])
        : setAllNotSelectedLabel([...allNotSelectedLabel, parseInt(labelId)]);

      dispatch(
        restoreDiscussions(() =>
          getNewDiscutionAfterUpdate(
            isSelectedFirst ? "labels" : "notIdlabels",
            isSelectedFirst
              ? [...allSelectedLabel, parseInt(labelId)]?.join()
              : [...allNotSelectedLabel, parseInt(labelId)]?.join(),
            true
          )
        )
      );
    }
  };

  /**
   * Change limite page
   */
  const setPaginateDataFilter = (p: number) => {
    setlimitDiscution(p);
    setLoader(true);
    dispatch(
      restoreDiscussions(() => getNewDiscutionAfterUpdate("limit", p, true))
    );
  };

  /**
   *
   * Update sorted date
   */
  const setSortedate = (sorted: any) => {
    setsortedBy(sorted);
    setLoader(true);
    dispatch(
      restoreDiscussions(() =>
        getNewDiscutionAfterUpdate("sortBy", sorted, true)
      )
    );
  };

  const goToreplayPost = (postId: string, postType: number) => {
    const url = "/community/reply-post/" + idCategorie + "/" + postId;
    history.push(url);
  };

  /**
   *
   * Click next page
   */
  const handlePageClick = (data: any) => {
    setCurrentDiscutionPage(data);
    setLoader(true);
    dispatch(
      restoreDiscussions(() => getNewDiscutionAfterUpdate("page", data))
    );
  };

  const handleFilterView = (p: string) => {
    let tempWiew: string;
    if (view === p) {
      setView("");
      tempWiew = "";
    } else {
      setView(p);
      tempWiew = p;
    }
    setLoader(true);
    dispatch(
      restoreDiscussions(() =>
        getNewDiscutionAfterUpdate("views", tempWiew, true)
      )
    );
  };

  const getNewDefaultDiscution = () => {
    let data = {
      categories: idCategorie,
      views: "",
      labels: "",
      postTypes: isAllPostType?.article ? "1,2" : "1",
      limit: 10,
      page: 1,
      sortBy: "post_date",
      orderBy: "DESC",
      notIdlabels: "",
    };
    dispatch(getDiscutionCategorie(data, cb));
  };

  const resetFilter = () => {
    setLoader(true);
    setAllNotSelectedLabel([]);
    setAllSelectedLabel([]);
    setView("");
    setCurrentDiscutionPage(1);
    setsortedBy("post_date");
    setlimitDiscution(10);
    dispatch(restoreDiscussions(() => getNewDefaultDiscution()));
  };

  const viewAllContributor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "contributions",
      postType: "1",
      categorie: idCategorie,
    });
  };

  const viewAllAuthor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "author",
      postType: "1",
      categorie: idLabel,
    });
  };

  const followCategory = async () => {
    setFollowLoader(true);
    const res = await postFollowCategory(idCategorie, userReducer.token);
    const data: { followed: boolean } = res?.data;
    setFollow(data?.followed);
    dispatch(getLabelCommunity(() => {}));
    setFollowLoader(false);
  };

  const goDiscussionCategorie = () => {
    history?.push("/community/discussions-categories");
  };

  const goHome = () => {
    history?.push("/community/home");
  };

  const goActivity = (id: string) => {
    history.push("/community/activity-user/" + id);
  };

  const goToLabelCategory = (idCategory: any, labelId: string) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (labelId?.toString() !== idLabel?.toString()) {
      dispatch(restoreDiscussions(() => {}));
      history.replace("/community/one-category/" + idCategory + "/" + labelId);
    }
  };

  const goToTopBestAwnser = () => history.push("/community/best-answer");

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  return {
    titleCategories,
    communityReducer,
    currentPage,
    nextPage,
    limitTopLabel,
    totalLabel: communityReducer?.topLabel?.total,
    clikPrev,
    limitDiscution,
    sortedBy,
    setlimitDiscution,
    setsortedBy,
    setLabelsFilter,
    allNotSelectedLabel,
    setPaginateDataFilter,
    setSortedate,
    goToreplayPost,
    allViews,
    view,
    loader,
    handlePageClick,
    handleFilterView,
    resetFilter,
    currentDiscutionPage,
    loaderTopLabel,
    topLikedContributions,
    topLikedAuthor,
    topBestAnswerAuthor,
    viewAllContributor,
    viewAllAuthor,
    followCategory,
    follow,
    goDiscussionCategorie,
    goHome,
    goActivity,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    followLoader,
    isUserConnected,
    allSelectedLabel,
    useSelected: idLabel,
    goToLabelCategory,
    goToTopBestAwnser,
    userReducer,
  };
}
