import "./classifiedAds.scss";
import React from "react";
import Header from "../../widget/index";
import ClassifiedAdsSubMenu from "./sub-menu";
import useFavoriteCtr from "../../../controller/classified-ads-ctr/favorite-ctr";
import AdsItem from "../../widget/ads";
import { IAd } from "../../../redux/types/classified-ads-interface";
import { LoaderCommon } from "../../widget/loader";
import Footer from "../../widget/footer/footer";

export default function FavoriteClassifiedAds() {
  const favoriteCtr = useFavoriteCtr();
  return (
    <div>
      <Header />
      <section className="classified">
        <ClassifiedAdsSubMenu
          classifiedAdsReducer={favoriteCtr.classifiedAdsReducer}
          resetFilter={favoriteCtr?.resetFilter}
          filter={favoriteCtr?.filter}
          selectedIndustry={favoriteCtr?.selectedIndustry}
          setSelectedIndustry={favoriteCtr?.setSelectedIndustry}
          selectedLocation={favoriteCtr?.selectedLocation}
          setSelectedLocation={favoriteCtr?.setSelectedLocation}
          selectedSolution={favoriteCtr?.selectedSolution}
          setSelectedSolution={favoriteCtr?.setSelectedSolution}
          selectedType={favoriteCtr?.selectedType}
          setSelectedType={favoriteCtr?.setSelectedType}
        />
        <div className="container mx-auto minHeightwin">
          <div className="listOfIsv classifiedAdsScroll">
            <div className="scrollContainer">
              {favoriteCtr?.loader && <LoaderCommon />}
              <div className="listFresh">
                {favoriteCtr?.ads?.map((e: IAd, i: number) => (
                  <AdsItem
                    key={i}
                    ads={e}
                    setShowLogin={() => {}}
                    selectedAds={favoriteCtr?.filterRemoveFavorite}
                    goToCategory={(idCategory: string) =>
                      favoriteCtr.goToCategoryPage(idCategory)
                    }
                  />
                ))}
                {favoriteCtr?.ads?.length < parseInt(favoriteCtr?.totalAds) && (
                  <div className="linkView">
                    {favoriteCtr?.viewMoreLoader ? (
                      <LoaderCommon />
                    ) : (
                      <a
                        href="javascript:;"
                        className={`viewAll ${
                          favoriteCtr?.loader && `disabled`
                        }`}
                        onClick={favoriteCtr?.handleViewMore}
                      >
                        View more
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
