import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import { postJsonDataService } from '../../../../service/applicatif/back-end-service';
import { INVESTOR, ISV, OEM_PROGRAM, url } from '../../../../service/constant';
import { IPropsReceipt } from './transaction-type';
type IProps = {
  tabType: string;
};
export default function UseTransactionHistory(props: IProps) {
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const companyIsv = useSelector(
    (state: rootState) => state.companyPageReducer
  );

  const [loaderReceipt, setLoaderReceipt] = useState<boolean>(false);

  const investorPageObject = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );

  const oemProgramObject = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const resellerPage = useSelector(
    (state: rootState) => state.resellerCompanyReducer
  );
  const userObject = useSelector((state: rootState) => state.userReducer);

  const userType = userObject.roles?.filter((el) => el !== 'USER_ROLE')?.[0];

  let companyId: string | null =
    userType === ISV
      ? companyIsv.id
      : userType === OEM_PROGRAM
      ? oemProgramObject.id
      : userType === INVESTOR
      ? investorPageObject.id
      : resellerPage.id;

  const [receipt, setReceipt] = useState<{
    items: Array<IPropsReceipt>;
    meta: {
      nb_item: number;
      pages: number;
      pagination: number;
      total_count: number;
    };
  }>({
    items: [],
    meta: { nb_item: 0, pages: 1, total_count: 0, pagination: 0 },
  });
  const currentYear = new Date().getFullYear();
  const [paramsReceipt, setParamsReceipt] = useState<{
    begin: string;
    end: string;
    page: number;
    limit: number;
    companyId: string | null;
  }>({
    begin: currentYear + '-01-01',
    end: moment().format('YYYY-MM-DD'),
    page: 1,
    limit: 10,
    companyId: companyId,
  });

  const getReceipt = async (p: any) => {
    setLoaderReceipt(true);
    const response: any = await postJsonDataService(
      url.settings.receipt,
      p,
      userReducer.token
    );
    setLoaderReceipt(false);
    return response;
  };

  useEffect(() => {
    async function getReceiptInfo() {
      const response = await getReceipt(paramsReceipt);
      if (response?.status === 200) {
        setReceipt({
          items: response?.data?.object_list,
          meta: {
            ...response?.data?.meta,
            pages: 1,
          },
        });
      }
    }
    props?.tabType === 'receipts_list' && getReceiptInfo();
    return () => {};
  }, [props?.tabType]);

  const handleClickReceipt = (page: number) => {
    const params = {
      ...paramsReceipt,
      page,
    };
    setReceipt({
      items: receipt.items,
      meta: {
        ...receipt.meta,
        pages: page,
      },
    });
    getReceipt(params);
  };

  return {
    handleClickReceipt,
    loaderReceipt,
    receipt,
  };
}
