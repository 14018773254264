import { ClassifiedPost } from '../../controller/classified-ads-ctr/post-ads-ctr/type';
import {
  dataWithToken,
  getData,
  getDataWithoutToken,
  postData,
  postPhoto,
  postWithoutToken,
} from '../api';
import { GUEST, INVESTOR, ISV, OEM_PROGRAM, RESELLER, url } from '../constant';
import {
  AdsAttributeEnum,
  AdsEntityTypeEnum,
  CompanyPage,
} from '../../controller/classified-ads-ctr/detail-ads-ctr/type';
import { getUserType } from '../../utils';
import { deleteMethode, postJsonDataService } from './back-end-service';
import { AdsResult } from '../../controller/classified-ads-ctr/favorite-ctr';
import store from '../../redux/store';
import { useSelector } from 'react-redux';
import { rootState } from '../../redux/reducers';

export const postClassifiedAds = async (
  data: ClassifiedPost,
  token: string
) => {
  const dataToSend: dataWithToken = {
    data,
    url: url?.classified_ads.post,
  };
  try {
    const res = await postPhoto(dataToSend, token);
    return res;
  } catch (e) {}
};

export const editClassifiedAds = async (
  idPost: number,
  data: ClassifiedPost,
  token: string
) => {
  const dataToSend: dataWithToken = {
    data,
    url: url?.classified_ads?.post + '/' + idPost,
  };
  try {
    const res = await postPhoto(dataToSend, token);
    if (res?.status === 200) {
      return res?.data;
    }
    alert(res?.data);
  } catch (e) {}
};

export const getClassifiedAdsDetail = async (
  id: number,
  token: string = ''
) => {
  try {
    const res = await getData(url?.classified_ads.detail + id, token);
    if (res.status === 200) {
      const data = { ...res?.data };
      let page: CompanyPage = {
        id: 0,
        company_name: '',
      };

      const userRoles = data?.user?.roles?.[0];

      switch (userRoles) {
        case ISV:
          page.id = data?.user?.isvInCompanyPage?.company_page?.id;
          page.company_name =
            data?.user?.isvInCompanyPage?.company_page?.company_name;
          page.city = data?.user?.isvInCompanyPage?.company_page?.city;
          page.country_name =
            data?.user?.isvInCompanyPage?.company_page?.country?.country_name;
          page.photo_profil =
            data?.user?.isvInCompanyPage?.company_page?.photo_profil;
          break;
        case INVESTOR:
          page.id = data?.user?.investor_members?.investor_page?.id;
          page.company_name =
            data?.user?.investor_members?.investor_page?.organization_name;
          page.photo_profil =
            data?.user?.investor_members?.investor_page?.photo_profil;
          break;
        case RESELLER:
          page.id = data?.user?.resellerCompanies?.[0]?.id;
          page.company_name =
            data?.user?.resellerCompanies?.[0]?.company_trade_name;
          page.photo_profil = data?.user?.resellerCompanies[0]?.photo_profil;
          break;
        case GUEST:
          page.id = data?.user?.resellerCompanies?.[0]?.id;
          page.company_name = data?.user?.company_name;
          page.photo_profil = data?.user?.guestCompany?.logo;
      }

      return {
        ...data,
        page,
      };
    }

    return [];
  } catch (e) {}
};

export const getAdsSegmentDetails = async (
  id: number,
  entityType: AdsEntityTypeEnum,
  attribute: AdsAttributeEnum
) => {
  try {
    return await postWithoutToken(url?.classified_ads?.attribut, {
      id,
      entityType,
      attribute,
    });
  } catch (e) {}
};

export const adsFavorite = async (
  id: string,
  token: string,
  idOem: string | null = null
) => {
  try {
    const res = await postJsonDataService(
      url?.classified_ads?.favorite,
      {
        classifiedAds: id,
        oem_program: idOem,
      },
      token
    );

    if (res?.status === 200) {
      return res?.data;
    }

    return false;
  } catch (e) {}
};

export const getFilteredClassifiedAds = async (
  params: {
    type: number;
    companyType: string;
    location: string;
    softwareSolution: string;
    targetedIndustry: string;
    page: number;
    favorite: string;
    search: string;
    oem_program: string;
  },
  token: string
) => {
  try {
    const response = await postJsonDataService(
      url?.classified_ads?.filter_classified_ads,
      params,
      token
    );
    if (response?.status === 200) {
      return response?.data;
    }
    return false;
  } catch (error: any) {}
};

export const resetFavoriteAds = async (
  token: string,
  setAds: any,
  setTotalAds: any
) => {
  const data: AdsResult = await getFilteredClassifiedAds(
    {
      favorite: '1',
      page: 1,
      type: 0,
      location: '',
      targetedIndustry: '',
      search: '',
      companyType: '',
      softwareSolution: '',
      oem_program: '0',
    },
    token
  );

  setAds(data?.results);
  setTotalAds(data?.total_result_to_paginate);
};

export const getSearchResults = async (params: any) => {
  const oemId = store?.getState().oemProgramReducer?.program?.id || '0';
  const data: AdsResult = await getFilteredClassifiedAds(
    {
      favorite: '0',
      page: params?.page,
      type: 0,
      location: '',
      targetedIndustry: '',
      companyType: '',
      softwareSolution: '',
      search: params?.search,
      oem_program: oemId,
    },
    store && store?.getState()?.userReducer?.token
  );
  if (data) {
    return data;
  }
};

export const deleteAds = async (id: string, callback: any) => {
  try {
    if (id === '-1') {
      callback(false);
      return;
    }
    let data = await deleteMethode(
      url?.classified_ads?.delete + id,
      store && store?.getState()?.userReducer?.token
    );
    if (data?.status === 204) {
      callback(data);
      return;
    }
    callback(false);
  } catch (error) {}
};

export const getAdsDetails = async () => {
  let response = await getData(
    url?.classified_ads?.get_classified_ads_details,
    store?.getState()?.userReducer?.token
  );
  return response.data;
};

// cancel payment intent
export const cancelPaymentIntent = async (paymentIntent: string) =>
  await postData(
    url?.settings?.billingCenter + '/cancel/payment_intent/' + paymentIntent,
    {
      pi: paymentIntent,
    },
    store?.getState()?.userReducer?.token
  );
