import { constante } from "../../actions/notification-action";
import { INotification } from "../../types/notification-interface";
import { IAction } from "../../types/type-action";

const initialState: INotification = {
  totalNumberUnread: 0,
  data: [],
};

export default function notificationReducer(
  state = initialState,
  action: IAction
) {
  switch (action.type) {
    case constante.MARK_AS_READ:
      console.log("reducer", action);
      return {
        ...state,
        totalNumberUnread: state.totalNumberUnread - 1,
      };
    case constante.ADD_NOTIFICATION:
      return {
        ...state,
        totalNumberUnread: action.payload.numberUnread,
        data: action.payload.notifications,
      };
    default:
      return state;
  }
}
