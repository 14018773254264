import { convertToRaw, EditorState } from 'draft-js';
import React, { ChangeEvent, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useHistory } from 'react-router-dom';
import {
  convertCommentFromJSONToHTML,
  createMarkup,
  getDescriptionEditorState,
} from '../../../utils';

type Iprops = {
  showModal: boolean;
  handleModal: () => void;
  content?: EditorState;
};
export default function ModalPreview(props: Iprops) {
  const data =
    props.content &&
    JSON.stringify(convertToRaw(props.content?.getCurrentContent()));

  return (
    <Modal
      show={props?.showModal}
      className='createCompanypopup small forStep5custom forPreview'
      style={{ height: '100%' }}
      size='xl'
    >
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>Preview</h2>
          <Link to={'#'} className='close' onClick={() => props?.handleModal()}>
            <span className='icon-close-pop'></span>
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body className=''>
        {props.content && (
          <div
            dangerouslySetInnerHTML={createMarkup(
              convertCommentFromJSONToHTML(getDescriptionEditorState(data))
            )}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
