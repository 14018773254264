import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  isRequired,
  setSessionStorageObject,
  isURL,
  getSStorage,
} from "../../../utils/index";
import useEventListener from "../../../hooks/event-handler";

export default function UseCompleteInvestorTypeof() {
  const history = useHistory();

  const [isInstitutional, setInstitutional] = useState(
    JSON.parse(getSStorage("toogleActive") || "")
  );

  const [stateLinkDean, setstateLinkDean] = useState<boolean>(false);

  const [investorType, setInvestorType] = useState<string>(
    getSStorage("investorType") ? getSStorage("investorType") || "" : ""
  );

  const [webSiteInvestor, setWebSiteInvestor] = useState<string>(
    getSStorage("webSiteInvestor") ? getSStorage("webSiteInvestor") || "" : ""
  );

  const [urlLinkDeanInvestor, setUrlLinkDeanInvestor] = useState<string>(
    getSStorage("urlLinkDeanInvestor")
      ? getSStorage("urlLinkDeanInvestor") || ""
      : ""
  );

  const [locationInvestor, setLocationInvestor] = useState<string>(
    getSStorage("locationInvestor") ? getSStorage("locationInvestor") || "" : ""
  );

  const [cityInvestor, setCityInvestor] = useState<string>(
    getSStorage("cityInvestor") ? getSStorage("cityInvestor") || "" : ""
  );

  const [strategicPartenerInvestor, setStrategicPartenerInvestor] = useState(
    getSStorage("strategicPartenerInvestor")
      ? JSON.parse(getSStorage("strategicPartenerInvestor") || "[]")
      : []
  );

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );

  const [errorInvestorType, setErrorInvestorType] = useState<boolean>(false);

  const [errorWebSiteInvestor, setErrorWebSiteInvestor] =
    useState<boolean>(false);
  const [errorUrlLinkDeanInvestor, setErroroUrlLinkDeanInvestor] =
    useState<boolean>(false);
  const [errorLocationInvestor, setErroLocationInvestor] =
    useState<boolean>(false);
  const [errorCityInvestor, setErrorCityInvestor] = useState<boolean>(false);

  /*
   * Valid Investor type
   * params string
   * return boolean
   */
  const validInvestorType = (txt: string): boolean => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErrorInvestorType(true);
      return false;
    }
  };

  /*
   * Valid WebSite
   * params string
   * return boolean
   */
  const validWebsite = (txt: string): boolean => {
    if (isRequired(txt) && isURL(txt)) {
      return true;
    } else {
      setErrorWebSiteInvestor(true);
      return false;
    }
  };

  /*
   * Valid Location
   * params string
   * return boolean
   */
  const validLocation = (txt: string): boolean => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErroLocationInvestor(true);
      return false;
    }
  };

  useEffect(() => {
    setErrorWebSiteInvestor(false);
    return () => {};
  }, [webSiteInvestor]);

  useEffect(() => {
    setErroLocationInvestor(false);
    return () => {};
  }, [locationInvestor]);

  const navigate = () => {
    setSessionStorageObject({
      investorType,

      webSiteInvestor,
      urlLinkDeanInvestor,
      locationInvestor,
      cityInvestor,
      strategicPartenerInvestor: JSON.stringify(strategicPartenerInvestor),
    });
    history.push("/complete-contry-investor");
  };

  const navigateToCountryInvestor = () => {
    if (
      isInstitutional &&
      !errorUrlLinkDeanInvestor &&
      validInvestorType(investorType) &&
      validWebsite(webSiteInvestor) &&
      validLocation(locationInvestor)
    ) {
      navigate();
    }

    if (
      !isInstitutional &&
      !errorUrlLinkDeanInvestor &&
      validLocation(locationInvestor)
    ) {
      navigate();
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateToCountryInvestor();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    navigateToCountryInvestor,
    investorType,
    setInvestorType,

    webSiteInvestor,
    setWebSiteInvestor,
    urlLinkDeanInvestor,
    setUrlLinkDeanInvestor,
    locationInvestor,
    setLocationInvestor,
    cityInvestor,
    setCityInvestor,
    strategicPartenerInvestor,
    setStrategicPartenerInvestor,
    errorInvestorType,

    errorWebSiteInvestor,
    errorUrlLinkDeanInvestor,
    setErroroUrlLinkDeanInvestor,
    errorLocationInvestor,
    errorCityInvestor,

    isInstitutional,
    dataCompleted,
  };
}
