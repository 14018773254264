import React, { useState } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import UseProfileCompanyPageCtr from '../../../../../controller/company-ctr/profil/use-profile-company-page-ctr';

export default function Index() {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const providerUseProfileCompanyPageCtr = {
    ...UseProfileCompanyPageCtr(),
  };

  const customClass = (
    rull: number,
    valueCompare: number,
    returnClass: string
  ) => (rull >= valueCompare ? returnClass : '');

  const reformepercentForDesign =
    providerUseProfileCompanyPageCtr?.companyPageObject?.totalProgress === 99
      ? 95
      : providerUseProfileCompanyPageCtr?.companyPageObject?.totalProgress;

  const pitchTxt =
    providerUseProfileCompanyPageCtr?.companyPageObject?.progressPitch === 75
      ? 99
      : providerUseProfileCompanyPageCtr?.companyPageObject?.progressPitch;

  const isCompleted =
    providerUseProfileCompanyPageCtr?.companyPageObject?.totalProgress >= 99;

  return (
    <div className='containerLeftItem progressCompany'>
      <div
        className={
          hovered || isCompleted
            ? 'showInfobule contentInfobulle'
            : 'contentInfobulle'
        }
      >
        <label htmlFor=''>Complete your page</label>
        <ul className='listOfDone'>
          <li className='titleList'>Page completion</li>
          <li
            className={`item ${customClass(
              providerUseProfileCompanyPageCtr?.companyPageObject
                ?.progressBasicInfo,
              100,
              'done'
            )}`}
          >
            Basic info :{' '}
            {
              providerUseProfileCompanyPageCtr?.companyPageObject
                ?.progressBasicInfo
            }
            %
          </li>
          <li
            className={`item ${customClass(
              providerUseProfileCompanyPageCtr?.companyPageObject
                ?.progressOverview,
              100,
              'done'
            )}`}
          >
            {' '}
            Overview :{' '}
            {
              providerUseProfileCompanyPageCtr?.companyPageObject
                ?.progressOverview
            }
            %
          </li>
          <li
            className={`item ${customClass(
              providerUseProfileCompanyPageCtr?.companyPageObject
                ?.progressStrategy,
              100,
              'done'
            )}`}
          >
            {' '}
            Strategy :{' '}
            {
              providerUseProfileCompanyPageCtr?.companyPageObject
                ?.progressStrategy
            }
            %
          </li>
          <li
            className={`item ${customClass(
              providerUseProfileCompanyPageCtr?.companyPageObject
                ?.progressFinancial,
              100,
              'done'
            )}`}
          >
            {' '}
            Financial :{' '}
            {
              providerUseProfileCompanyPageCtr?.companyPageObject
                ?.progressFinancial
            }
            %
          </li>
          <li className={`item ${customClass(pitchTxt, 99, 'done')}`}>
            {' '}
            Pitch : {pitchTxt}%
          </li>
        </ul>
      </div>
      <div
        className='containerGraph'
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        {/* Dev #47338 Correction concernant le Devinsider Pitch */}
        <CircularProgressbarWithChildren
          className='progressgraph'
          value={reformepercentForDesign}
          styles={buildStyles({
            pathColor: '#ff6600',
            trailColor: '#e5e5e5',
          })}
        >
          <div className='labelProgressBar'>
            <b>
              {
                providerUseProfileCompanyPageCtr?.companyPageObject
                  ?.totalProgress
              }
              %
            </b>
            progress
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
}
