import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateExperInfoCompany } from "../../../../../../../../redux/actions/acounts-action";
import { rootState } from "../../../../../../../../redux/reducers";

export default function UseEditAbout() {
  const userState = useSelector((state: rootState) => state.userReducer);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [companyOverview, handleCompanyOverview] = useState<string>(
    userState?.expertCompany?.company_overview || ""
  );
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);

  const dispatch = useDispatch();

  const cancelChange = () => {
    setShowModal(false);
  };

  const cbUpdate = (data: any) => {
    setIsLoaderShow(false);
    setShowModal(false);
  };

  const updateInfoOverview = () => {
    setIsLoaderShow(true);
    let data = {
      companyOverview: companyOverview,
    };
    dispatch(updateExperInfoCompany(data, cbUpdate));
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (showModal) {
      handleCompanyOverview(userState?.expertCompany?.company_overview || "");
      isLoaderShow && setIsLoaderShow(false);
    }
  }, [showModal]);

  return {
    showModal,
    setShowModal,
    companyOverview,
    handleCompanyOverview,
    isLoaderShow,
    cancelChange,
    updateInfoOverview,
    handleShowModal,
  };
}
