/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { IPropsEditSettingPage } from "./type";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";
import Accordion from "react-bootstrap/Accordion";
import CompanySizePopup from "../slider-company-size-slider/index";
import AnnualTurnover from "../slider-annual-turnover/index";
import RegionContry from "../pays/index";
import SofTwareSolution from "../software-solution/index";
import Form from "react-bootstrap/Form";
import { IListTarget } from "../industries-list/type";
import ModalPermission from "../../widget/permission-oem/index";

export default function EditSettingPage(props: IPropsEditSettingPage) {
  const [activeId, setActiveId] = useState("");
  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId("");
    } else {
      setActiveId(id);
    }
  }

  return (
    <>
      <Modal show={props?.isShowModal} className="createCompanypopup large ">
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Program targeting</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => {
                setActiveId("");
                props.cancelUpdate();
              }}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div>
            <Accordion className="accordionForSetting settingPageAccordion">
              <div className="lineAccordion">
                <Accordion.Toggle
                  eventKey="1"
                  className={`btnAccordion ${activeId === "1" ? "active" : ""}`}
                  onClick={() => toggleActive("1")}
                >
                  Company size:
                  <div className="explication">
                    {props?.companySizeSlide?.[0]} to{" "}
                    {props?.companySizeSlide?.[1]} employees
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div className="col-xl-6 col-lg-8 col-md-12 col-12  p-0 pb-5 m-0">
                    <CompanySizePopup
                      companySize={props?.companySize}
                      companySizeSlide={props?.companySizeSlide}
                      setCompanySize={(evt: Array<number>) => {
                        props?.setCompanySize(evt);
                      }}
                      customSetCompanySize={(min: number, max: number) => {
                        props?.customSetCompanySize(min, max);
                      }}
                    />
                    <div className="contentBorder formForEmail text-left">
                      <button
                        className="btn btn-devinsider cancel"
                        onClick={() => {
                          setActiveId("");
                          props?.cancelUpdate();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-devinsider px-5"
                        onClick={() => {
                          if (props?.validateEditMatching()) {
                            props?.validateUpdate("company_size");
                          }
                        }}
                      >
                        {props?.loader &&
                        props?.keyUpdate === "company_size" ? (
                          <Loader />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
              <div className="lineAccordion">
                <Accordion.Toggle
                  eventKey="2"
                  className={`btnAccordion ${activeId === "2" ? "active" : ""}`}
                  onClick={() => toggleActive("2")}
                >
                  Annual revenue:
                  <div className="explication">
                    {props?.annualTurnoverSlide?.[0]}
                    {props?.unitTurnover} to {props?.annualTurnoverSlide?.[1]}
                    {props?.unitTurnoverMax}{" "}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <div className="col-xl-6 col-lg-8 col-md-12 col-12  p-0 pb-5 m-0">
                    <AnnualTurnover
                      annualTurnoverSlide={props?.annualTurnoverSlide}
                      annualTurnover={props?.annualTurnover}
                      unitTurnover={props?.unitTurnover}
                      unitTurnoverMax={props?.unitTurnoverMax}
                      setAnnualTurnover={(evt: Array<number>) => {
                        props?.setAnnualTurnover(evt);
                      }}
                      customSetAnnualSize={(min: number, max: number) =>
                        props?.customSetAnnualSize(min, max)
                      }
                    />
                    <div className="contentBorder formForEmail text-left">
                      <button
                        className="btn btn-devinsider cancel"
                        onClick={() => {
                          setActiveId("");
                          props?.cancelUpdate();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-devinsider px-5"
                        onClick={() => {
                          if (props?.validateEditMatching()) {
                            props?.validateUpdate("annual");
                          }
                        }}
                      >
                        {props?.loader && props?.keyUpdate === "annual" ? (
                          <Loader />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>

              {/* Pays */}
              <div className="lineAccordion">
                <Accordion.Toggle
                  eventKey="8"
                  className={`btnAccordion ${activeId === "8" ? "active" : ""}`}
                  onClick={() => toggleActive("8")}
                >
                  Region(s) / Country(ies):
                  <div className="explication">
                    {props?.paysChecked?.length} countries selected
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8">
                  <div className="col-xl-6 col-lg-8 col-md-12 col-12  p-0 pb-5 m-0">
                    <RegionContry {...props} />
                    <div className="contentBorder formForEmail text-left">
                      <button
                        className="btn btn-devinsider cancel"
                        onClick={() => {
                          setActiveId("");
                          props?.cancelUpdate();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-devinsider px-5"
                        onClick={() => {
                          if (props?.validateEditMatching()) {
                            props?.validateUpdate("country");
                          }
                        }}
                      >
                        {props?.loader && props?.keyUpdate === "country" ? (
                          <Loader />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
              <div className="lineAccordion">
                <Accordion.Toggle
                  eventKey="3"
                  className={`btnAccordion ${activeId === "3" ? "active" : ""}`}
                  onClick={() => toggleActive("3")}
                >
                  What ISV solution Category(ies) are you interested in ? :
                  <div className="explication">
                    {props?.solutionChecked.length +
                      props?.solutionCheckedFeatures.length +
                      " "}
                    solutions selected
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <div>
                    <SofTwareSolution
                      solutionChecked={props?.solutionChecked}
                      addSolutionCheched={(p: { id: number; name: string }) =>
                        props?.addSolutionCheched(p)
                      }
                      addSolutionChechedFeatured={(p: {
                        id: number;
                        name: string;
                      }) => props?.addSolutionChechedFeatured(p)}
                      featuredSolution={props?.featuredSolution}
                      solutionCheckedFeatures={props?.solutionCheckedFeatures}
                      solutionLeftColumnData={props?.solutionLeftColumnData}
                      solutionRigthColumnData={props?.solutionRigthColumnData}
                    />
                    <div className="contentBorder formForEmail text-left">
                      <button
                        className="btn btn-devinsider cancel"
                        onClick={() => {
                          setActiveId("");
                          props?.cancelUpdate();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-devinsider px-5"
                        onClick={() => {
                          if (props?.validateEditMatching()) {
                            props?.validateUpdate("segments");
                          }
                        }}
                      >
                        {props?.loader ? <Loader /> : "Save"}
                      </button>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
              <div className="lineAccordion">
                <Accordion.Toggle
                  eventKey="7"
                  className={`btnAccordion ${activeId === "7" ? "active" : ""}`}
                  onClick={() => toggleActive("7")}
                >
                  Define which industries the ISV should target to be qualified
                  for your program:
                  <div className="explication">
                    {props?.companyTargetSelected?.length} industries selected
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                  <div className="getstartedContainer largeContainer">
                    <div className="contentTargetIndustry">
                      <div className="col4">
                        {props?.companyTarget?.map(
                          (element: IListTarget, index: number) => (
                            <div
                              onClick={() => {
                                props?.addTargetCompany(element);
                              }}
                              key={index}
                            >
                              <Form.Check
                                key={index}
                                custom
                                checked={
                                  props?.companyTargetSelected?.find(
                                    (ele: IListTarget) => ele.id == element.id
                                  )
                                    ? true
                                    : false
                                }
                                defaultChecked={false}
                                label={element.name}
                                id={element.id.toString()}
                              />
                            </div>
                          )
                        )}
                      </div>
                      <div className="col4">
                        {props?.companyTargetSecound?.map(
                          (element: IListTarget, index: number) => (
                            <div
                              onClick={() => {
                                props?.addTargetCompany(element);
                              }}
                              key={index}
                            >
                              <Form.Check
                                key={index}
                                custom
                                defaultChecked={false}
                                checked={
                                  props?.companyTargetSelected?.find(
                                    (ele: IListTarget) => ele.id == element.id
                                  )
                                    ? true
                                    : false
                                }
                                label={element.name}
                                id={element.id.toString()}
                                onChange={() => null}
                              />
                            </div>
                          )
                        )}
                      </div>
                      <div className="col4">
                        {props?.lastCompanyTarget?.map(
                          (element: IListTarget, index: number) => (
                            <div
                              onClick={() => {
                                props?.addTargetCompany(element);
                              }}
                              key={index}
                            >
                              <Form.Check
                                key={index}
                                custom
                                defaultChecked={false}
                                onChange={() => null}
                                checked={
                                  props?.companyTargetSelected?.find(
                                    (ele: IListTarget) => ele.id == element.id
                                  )
                                    ? true
                                    : false
                                }
                                label={element.name}
                                id={element.id.toString()}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="contentBorder formForEmail text-left">
                      <button
                        className="btn btn-devinsider cancel"
                        onClick={() => {
                          setActiveId("");
                          props?.cancelUpdate();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-devinsider px-5"
                        onClick={() => {
                          if (props?.validateEditMatching()) {
                            props?.validateUpdate("industrie");
                          }
                        }}
                      >
                        {props?.loader && props?.keyUpdate === "industrie" ? (
                          <Loader />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
            </Accordion>
          </div>
        </Modal.Body>
      </Modal>
      <ModalPermission
        handleSkhow={props?.handLePersmission}
        show={props?.showPermission}
      />
      ;
    </>
  );
}
