export const mockRegion = [
  {
    id: 1,
    ccIso: "AF",
    country_name: "Afghanistan",
  },
  {
    id: 2,
    ccIso: "AX",
    country_name: "Åland Islands",
  },
  {
    id: 3,
    ccIso: "AL",
    country_name: "Albania",
  },
  {
    id: 4,
    ccIso: "DZ",
    country_name: "Algeria",
  },
  {
    id: 5,
    ccIso: "AS",
    country_name: "American Samoa",
  },
  {
    id: 6,
    ccIso: "AD",
    country_name: "AndorrA",
  },
  {
    id: 7,
    ccIso: "AO",
    country_name: "Angola",
  },
  {
    id: 8,
    ccIso: "AI",
    country_name: "Anguilla",
  },
  {
    id: 9,
    ccIso: "AQ",
    country_name: "Antarctica",
  },
  {
    id: 10,
    ccIso: "AG",
    country_name: "Antigua and Barbuda",
  },
  {
    id: 11,
    ccIso: "AR",
    country_name: "Argentina",
  },
  {
    id: 12,
    ccIso: "AM",
    country_name: "Armenia",
  },
  {
    id: 13,
    ccIso: "AW",
    country_name: "Aruba",
  },
  {
    id: 14,
    ccIso: "AU",
    country_name: "Australia",
  },
  {
    id: 15,
    ccIso: "AT",
    country_name: "Austria",
  },
  {
    id: 16,
    ccIso: "AZ",
    country_name: "Azerbaijan",
  },
  {
    id: 17,
    ccIso: "BS",
    country_name: "Bahamas",
  },
  {
    id: 18,
    ccIso: "BH",
    country_name: "Bahrain",
  },
  {
    id: 19,
    ccIso: "BD",
    country_name: "Bangladesh",
  },
  {
    id: 20,
    ccIso: "BB",
    country_name: "Barbados",
  },
  {
    id: 21,
    ccIso: "BY",
    country_name: "Belarus",
  },
  {
    id: 22,
    ccIso: "BE",
    country_name: "Belgium",
  },
  {
    id: 23,
    ccIso: "BZ",
    country_name: "Belize",
  },
  {
    id: 24,
    ccIso: "BJ",
    country_name: "Benin",
  },
  {
    id: 25,
    ccIso: "BM",
    country_name: "Bermuda",
  },
  {
    id: 26,
    ccIso: "BT",
    country_name: "Bhutan",
  },
  {
    id: 27,
    ccIso: "BO",
    country_name: "Bolivia",
  },
  {
    id: 28,
    ccIso: "BA",
    country_name: "Bosnia and Herzegovina",
  },
  {
    id: 29,
    ccIso: "BW",
    country_name: "Botswana",
  },
  {
    id: 30,
    ccIso: "BV",
    country_name: "Bouvet Island",
  },
  {
    id: 31,
    ccIso: "BR",
    country_name: "Brazil",
  },
  {
    id: 32,
    ccIso: "IO",
    country_name: "British Indian Ocean Territory",
  },
  {
    id: 33,
    ccIso: "BN",
    country_name: "Brunei Darussalam",
  },
  {
    id: 34,
    ccIso: "BG",
    country_name: "Bulgaria",
  },
  {
    id: 35,
    ccIso: "BF",
    country_name: "Burkina Faso",
  },
  {
    id: 36,
    ccIso: "BI",
    country_name: "Burundi",
  },
  {
    id: 37,
    ccIso: "KH",
    country_name: "Cambodia",
  },
  {
    id: 38,
    ccIso: "CM",
    country_name: "Cameroon",
  },
  {
    id: 39,
    ccIso: "CA",
    country_name: "Canada",
  },
  {
    id: 40,
    ccIso: "CV",
    country_name: "Cape Verde",
  },
  {
    id: 41,
    ccIso: "KY",
    country_name: "Cayman Islands",
  },
  {
    id: 42,
    ccIso: "CF",
    country_name: "Central African Republic",
  },
  {
    id: 43,
    ccIso: "TD",
    country_name: "Chad",
  },
  {
    id: 44,
    ccIso: "CL",
    country_name: "Chile",
  },
  {
    id: 45,
    ccIso: "CN",
    country_name: "China",
  },
  {
    id: 46,
    ccIso: "CX",
    country_name: "Christmas Island",
  },
  {
    id: 47,
    ccIso: "CC",
    country_name: "Cocos (Keeling) Islands",
  },
  {
    id: 48,
    ccIso: "CO",
    country_name: "Colombia",
  },
  {
    id: 49,
    ccIso: "KM",
    country_name: "Comoros",
  },
  {
    id: 50,
    ccIso: "CG",
    country_name: "Congo",
  },
  {
    id: 51,
    ccIso: "CD",
    country_name: "Congo, The Democratic Republic of the",
  },
  {
    id: 52,
    ccIso: "CK",
    country_name: "Cook Islands",
  },
  {
    id: 53,
    ccIso: "CR",
    country_name: "Costa Rica",
  },
  {
    id: 54,
    ccIso: "CI",
    country_name: "Cote D'Ivoire",
  },
  {
    id: 55,
    ccIso: "HR",
    country_name: "Croatia",
  },
  {
    id: 56,
    ccIso: "CU",
    country_name: "Cuba",
  },
  {
    id: 57,
    ccIso: "CY",
    country_name: "Cyprus",
  },
  {
    id: 58,
    ccIso: "CZ",
    country_name: "Czech Republic",
  },
  {
    id: 59,
    ccIso: "DK",
    country_name: "Denmark",
  },
  {
    id: 60,
    ccIso: "DJ",
    country_name: "Djibouti",
  },
  {
    id: 61,
    ccIso: "DM",
    country_name: "Dominica",
  },
  {
    id: 62,
    ccIso: "DO",
    country_name: "Dominican Republic",
  },
  {
    id: 63,
    ccIso: "EC",
    country_name: "Ecuador",
  },
  {
    id: 64,
    ccIso: "EG",
    country_name: "Egypt",
  },
  {
    id: 65,
    ccIso: "SV",
    country_name: "El Salvador",
  },
  {
    id: 66,
    ccIso: "GQ",
    country_name: "Equatorial Guinea",
  },
  {
    id: 67,
    ccIso: "ER",
    country_name: "Eritrea",
  },
  {
    id: 68,
    ccIso: "EE",
    country_name: "Estonia",
  },
  {
    id: 69,
    ccIso: "ET",
    country_name: "Ethiopia",
  },
  {
    id: 70,
    ccIso: "FK",
    country_name: "Falkland Islands (Malvinas)",
  },
  {
    id: 71,
    ccIso: "FO",
    country_name: "Faroe Islands",
  },
  {
    id: 72,
    ccIso: "FJ",
    country_name: "Fiji",
  },
  {
    id: 73,
    ccIso: "FI",
    country_name: "Finland",
  },
  {
    id: 74,
    ccIso: "FR",
    country_name: "France",
  },
  {
    id: 75,
    ccIso: "GF",
    country_name: "French Guiana",
  },
  {
    id: 76,
    ccIso: "PF",
    country_name: "French Polynesia",
  },
  {
    id: 77,
    ccIso: "TF",
    country_name: "French Southern Territories",
  },
  {
    id: 78,
    ccIso: "GA",
    country_name: "Gabon",
  },
  {
    id: 79,
    ccIso: "GM",
    country_name: "Gambia",
  },
  {
    id: 80,
    ccIso: "GE",
    country_name: "Georgia",
  },
  {
    id: 81,
    ccIso: "DE",
    country_name: "Germany",
  },
  {
    id: 82,
    ccIso: "GH",
    country_name: "Ghana",
  },
  {
    id: 83,
    ccIso: "GI",
    country_name: "Gibraltar",
  },
  {
    id: 84,
    ccIso: "GR",
    country_name: "Greece",
  },
  {
    id: 85,
    ccIso: "GL",
    country_name: "Greenland",
  },
  {
    id: 86,
    ccIso: "GD",
    country_name: "Grenada",
  },
  {
    id: 87,
    ccIso: "GP",
    country_name: "Guadeloupe",
  },
  {
    id: 88,
    ccIso: "GU",
    country_name: "Guam",
  },
  {
    id: 89,
    ccIso: "GT",
    country_name: "Guatemala",
  },
  {
    id: 90,
    ccIso: "GG",
    country_name: "Guernsey",
  },
  {
    id: 91,
    ccIso: "GN",
    country_name: "Guinea",
  },
  {
    id: 92,
    ccIso: "GW",
    country_name: "Guinea-Bissau",
  },
  {
    id: 93,
    ccIso: "GY",
    country_name: "Guyana",
  },
  {
    id: 94,
    ccIso: "HT",
    country_name: "Haiti",
  },
  {
    id: 95,
    ccIso: "HM",
    country_name: "Heard Island and Mcdonald Islands",
  },
  {
    id: 96,
    ccIso: "VA",
    country_name: "Holy See (Vatican City State)",
  },
  {
    id: 97,
    ccIso: "HN",
    country_name: "Honduras",
  },
  {
    id: 98,
    ccIso: "HK",
    country_name: "Hong Kong",
  },
  {
    id: 99,
    ccIso: "HU",
    country_name: "Hungary",
  },
  {
    id: 100,
    ccIso: "IS",
    country_name: "Iceland",
  },
  {
    id: 101,
    ccIso: "IN",
    country_name: "India",
  },
  {
    id: 102,
    ccIso: "ID",
    country_name: "Indonesia",
  },
  {
    id: 103,
    ccIso: "IR",
    country_name: "Iran, Islamic Republic Of",
  },
  {
    id: 104,
    ccIso: "IQ",
    country_name: "Iraq",
  },
  {
    id: 105,
    ccIso: "IE",
    country_name: "Ireland",
  },
  {
    id: 106,
    ccIso: "IM",
    country_name: "Isle of Man",
  },
  {
    id: 107,
    ccIso: "IL",
    country_name: "Israel",
  },
  {
    id: 108,
    ccIso: "IT",
    country_name: "Italy",
  },
  {
    id: 109,
    ccIso: "JM",
    country_name: "Jamaica",
  },
  {
    id: 110,
    ccIso: "JP",
    country_name: "Japan",
  },
  {
    id: 111,
    ccIso: "JE",
    country_name: "Jersey",
  },
  {
    id: 112,
    ccIso: "JO",
    country_name: "Jordan",
  },
  {
    id: 113,
    ccIso: "KZ",
    country_name: "Kazakhstan",
  },
  {
    id: 114,
    ccIso: "KE",
    country_name: "Kenya",
  },
  {
    id: 115,
    ccIso: "KI",
    country_name: "Kiribati",
  },
  {
    id: 116,
    ccIso: "KP",
    country_name: "Korea, Democratic People'S Republic of",
  },
  {
    id: 117,
    ccIso: "KR",
    country_name: "Korea, Republic of",
  },
  {
    id: 118,
    ccIso: "KW",
    country_name: "Kuwait",
  },
  {
    id: 119,
    ccIso: "KG",
    country_name: "Kyrgyzstan",
  },
  {
    id: 120,
    ccIso: "LA",
    country_name: "Lao People'S Democratic Republic",
  },
  {
    id: 121,
    ccIso: "LV",
    country_name: "Latvia",
  },
  {
    id: 122,
    ccIso: "LB",
    country_name: "Lebanon",
  },
  {
    id: 123,
    ccIso: "LS",
    country_name: "Lesotho",
  },
  {
    id: 124,
    ccIso: "LR",
    country_name: "Liberia",
  },
  {
    id: 125,
    ccIso: "LY",
    country_name: "Libyan Arab Jamahiriya",
  },
  {
    id: 126,
    ccIso: "LI",
    country_name: "Liechtenstein",
  },
  {
    id: 127,
    ccIso: "LT",
    country_name: "Lithuania",
  },
  {
    id: 128,
    ccIso: "LU",
    country_name: "Luxembourg",
  },
  {
    id: 129,
    ccIso: "MO",
    country_name: "Macao",
  },
  {
    id: 130,
    ccIso: "MK",
    country_name: "Macedonia, The Former Yugoslav Republic of",
  },
  {
    id: 131,
    ccIso: "MG",
    country_name: "Madagascar",
  },
  {
    id: 132,
    ccIso: "MW",
    country_name: "Malawi",
  },
  {
    id: 133,
    ccIso: "MY",
    country_name: "Malaysia",
  },
  {
    id: 134,
    ccIso: "MV",
    country_name: "Maldives",
  },
  {
    id: 135,
    ccIso: "ML",
    country_name: "Mali",
  },
  {
    id: 136,
    ccIso: "MT",
    country_name: "Malta",
  },
  {
    id: 137,
    ccIso: "MH",
    country_name: "Marshall Islands",
  },
  {
    id: 138,
    ccIso: "MQ",
    country_name: "Martinique",
  },
  {
    id: 139,
    ccIso: "MR",
    country_name: "Mauritania",
  },
  {
    id: 140,
    ccIso: "MU",
    country_name: "Mauritius",
  },
  {
    id: 141,
    ccIso: "YT",
    country_name: "Mayotte",
  },
  {
    id: 142,
    ccIso: "MX",
    country_name: "Mexico",
  },
  {
    id: 143,
    ccIso: "FM",
    country_name: "Micronesia, Federated States of",
  },
  {
    id: 144,
    ccIso: "MD",
    country_name: "Moldova, Republic of",
  },
  {
    id: 145,
    ccIso: "MC",
    country_name: "Monaco",
  },
  {
    id: 146,
    ccIso: "MN",
    country_name: "Mongolia",
  },
  {
    id: 147,
    ccIso: "MS",
    country_name: "Montserrat",
  },
  {
    id: 148,
    ccIso: "MA",
    country_name: "Morocco",
  },
  {
    id: 149,
    ccIso: "MZ",
    country_name: "Mozambique",
  },
  {
    id: 150,
    ccIso: "MM",
    country_name: "Myanmar",
  },
  {
    id: 151,
    ccIso: "NA",
    country_name: "Namibia",
  },
  {
    id: 152,
    ccIso: "NR",
    country_name: "Nauru",
  },
  {
    id: 153,
    ccIso: "NP",
    country_name: "Nepal",
  },
  {
    id: 154,
    ccIso: "NL",
    country_name: "Netherlands",
  },
  {
    id: 155,
    ccIso: "AN",
    country_name: "Netherlands Antilles",
  },
  {
    id: 156,
    ccIso: "NC",
    country_name: "New Caledonia",
  },
  {
    id: 157,
    ccIso: "NZ",
    country_name: "New Zealand",
  },
  {
    id: 158,
    ccIso: "NI",
    country_name: "Nicaragua",
  },
  {
    id: 159,
    ccIso: "NE",
    country_name: "Niger",
  },
  {
    id: 160,
    ccIso: "NG",
    country_name: "Nigeria",
  },
  {
    id: 161,
    ccIso: "NU",
    country_name: "Niue",
  },
  {
    id: 162,
    ccIso: "NF",
    country_name: "Norfolk Island",
  },
  {
    id: 163,
    ccIso: "MP",
    country_name: "Northern Mariana Islands",
  },
  {
    id: 164,
    ccIso: "NO",
    country_name: "Norway",
  },
  {
    id: 165,
    ccIso: "OM",
    country_name: "Oman",
  },
  {
    id: 166,
    ccIso: "PK",
    country_name: "Pakistan",
  },
  {
    id: 167,
    ccIso: "PW",
    country_name: "Palau",
  },
  {
    id: 168,
    ccIso: "PS",
    country_name: "Palestinian Territory, Occupied",
  },
  {
    id: 169,
    ccIso: "PA",
    country_name: "Panama",
  },
  {
    id: 170,
    ccIso: "PG",
    country_name: "Papua New Guinea",
  },
  {
    id: 171,
    ccIso: "PY",
    country_name: "Paraguay",
  },
  {
    id: 172,
    ccIso: "PE",
    country_name: "Peru",
  },
  {
    id: 173,
    ccIso: "PH",
    country_name: "Philippines",
  },
  {
    id: 174,
    ccIso: "PN",
    country_name: "Pitcairn",
  },
  {
    id: 175,
    ccIso: "PL",
    country_name: "Poland",
  },
  {
    id: 176,
    ccIso: "PT",
    country_name: "Portugal",
  },
  {
    id: 177,
    ccIso: "PR",
    country_name: "Puerto Rico",
  },
  {
    id: 178,
    ccIso: "QA",
    country_name: "Qatar",
  },
  {
    id: 179,
    ccIso: "RE",
    country_name: "Reunion",
  },
  {
    id: 180,
    ccIso: "RO",
    country_name: "Romania",
  },
  {
    id: 181,
    ccIso: "RU",
    country_name: "Russian Federation",
  },
  {
    id: 182,
    ccIso: "RW",
    country_name: "RWANDA",
  },
  {
    id: 183,
    ccIso: "SH",
    country_name: "Saint Helena",
  },
  {
    id: 184,
    ccIso: "KN",
    country_name: "Saint Kitts and Nevis",
  },
  {
    id: 185,
    ccIso: "LC",
    country_name: "Saint Lucia",
  },
  {
    id: 186,
    ccIso: "PM",
    country_name: "Saint Pierre and Miquelon",
  },
  {
    id: 187,
    ccIso: "VC",
    country_name: "Saint Vincent and the Grenadines",
  },
  {
    id: 188,
    ccIso: "WS",
    country_name: "Samoa",
  },
  {
    id: 189,
    ccIso: "SM",
    country_name: "San Marino",
  },
  {
    id: 190,
    ccIso: "ST",
    country_name: "Sao Tome and Principe",
  },
  {
    id: 191,
    ccIso: "SA",
    country_name: "Saudi Arabia",
  },
  {
    id: 192,
    ccIso: "SN",
    country_name: "Senegal",
  },
  {
    id: 193,
    ccIso: "CS",
    country_name: "Serbia and Montenegro",
  },
  {
    id: 194,
    ccIso: "SC",
    country_name: "Seychelles",
  },
  {
    id: 195,
    ccIso: "SL",
    country_name: "Sierra Leone",
  },
  {
    id: 196,
    ccIso: "SG",
    country_name: "Singapore",
  },
  {
    id: 197,
    ccIso: "SK",
    country_name: "Slovakia",
  },
  {
    id: 198,
    ccIso: "SI",
    country_name: "Slovenia",
  },
  {
    id: 199,
    ccIso: "SB",
    country_name: "Solomon Islands",
  },
  {
    id: 200,
    ccIso: "SO",
    country_name: "Somalia",
  },
  {
    id: 201,
    ccIso: "ZA",
    country_name: "South Africa",
  },
  {
    id: 202,
    ccIso: "GS",
    country_name: "South Georgia and the South Sandwich Islands",
  },
  {
    id: 203,
    ccIso: "ES",
    country_name: "Spain",
  },
  {
    id: 204,
    ccIso: "LK",
    country_name: "Sri Lanka",
  },
  {
    id: 205,
    ccIso: "SD",
    country_name: "Sudan",
  },
  {
    id: 206,
    ccIso: "SR",
    country_name: "Suriname",
  },
  {
    id: 207,
    ccIso: "SJ",
    country_name: "Svalbard and Jan Mayen",
  },
  {
    id: 208,
    ccIso: "SZ",
    country_name: "Swaziland",
  },
  {
    id: 209,
    ccIso: "SE",
    country_name: "Sweden",
  },
  {
    id: 210,
    ccIso: "CH",
    country_name: "Switzerland",
  },
  {
    id: 211,
    ccIso: "SY",
    country_name: "Syrian Arab Republic",
  },
  {
    id: 212,
    ccIso: "TW",
    country_name: "Taiwan, Province of China",
  },
  {
    id: 213,
    ccIso: "TJ",
    country_name: "Tajikistan",
  },
  {
    id: 214,
    ccIso: "TZ",
    country_name: "Tanzania, United Republic of",
  },
  {
    id: 215,
    ccIso: "TH",
    country_name: "Thailand",
  },
  {
    id: 216,
    ccIso: "TL",
    country_name: "Timor-Leste",
  },
  {
    id: 217,
    ccIso: "TG",
    country_name: "Togo",
  },
  {
    id: 218,
    ccIso: "TK",
    country_name: "Tokelau",
  },
  {
    id: 219,
    ccIso: "TO",
    country_name: "Tonga",
  },
  {
    id: 220,
    ccIso: "TT",
    country_name: "Trinidad and Tobago",
  },
  {
    id: 221,
    ccIso: "TN",
    country_name: "Tunisia",
  },
  {
    id: 222,
    ccIso: "TR",
    country_name: "Turkey",
  },
  {
    id: 223,
    ccIso: "TM",
    country_name: "Turkmenistan",
  },
  {
    id: 224,
    ccIso: "TC",
    country_name: "Turks and Caicos Islands",
  },
  {
    id: 225,
    ccIso: "TV",
    country_name: "Tuvalu",
  },
  {
    id: 226,
    ccIso: "UG",
    country_name: "Uganda",
  },
  {
    id: 227,
    ccIso: "UA",
    country_name: "Ukraine",
  },
  {
    id: 228,
    ccIso: "AE",
    country_name: "United Arab Emirates",
  },
  {
    id: 229,
    ccIso: "GB",
    country_name: "United Kingdom",
  },
  {
    id: 230,
    ccIso: "US",
    country_name: "United States",
  },
  {
    id: 231,
    ccIso: "UM",
    country_name: "United States Minor Outlying Islands",
  },
  {
    id: 232,
    ccIso: "UY",
    country_name: "Uruguay",
  },
  {
    id: 233,
    ccIso: "UZ",
    country_name: "Uzbekistan",
  },
  {
    id: 234,
    ccIso: "VU",
    country_name: "Vanuatu",
  },
  {
    id: 235,
    ccIso: "VE",
    country_name: "Venezuela",
  },
  {
    id: 236,
    ccIso: "VN",
    country_name: "Viet Nam",
  },
  {
    id: 237,
    ccIso: "VG",
    country_name: "Virgin Islands, British",
  },
  {
    id: 238,
    ccIso: "VI",
    country_name: "Virgin Islands, U.S.",
  },
  {
    id: 239,
    ccIso: "WF",
    country_name: "Wallis and Futuna",
  },
  {
    id: 240,
    ccIso: "EH",
    country_name: "Western Sahara",
  },
  {
    id: 241,
    ccIso: "YE",
    country_name: "Yemen",
  },
  {
    id: 242,
    ccIso: "ZM",
    country_name: "Zambia",
  },
  {
    id: 243,
    ccIso: "ZW",
    country_name: "Zimbabwe",
  },
];
