import React from "react";
import Form from "react-bootstrap/Form";
import { IListTarget, IProps } from "./type";
export default function CompleteIndistrueInvestor(props: IProps) {
  return (
    <div className="getstartedContainer largeContainer">
      <div className="contentTargetIndustry">
        <div className="col4">
          {props?.companyTarget?.map((element: IListTarget, index: number) => (
            <Form.Check
              key={index}
              custom
              checked={
                props?.companyTargetSelected?.find(
                  (ele: IListTarget) =>
                    ele.id?.toString() === element.id?.toString()
                )
                  ? true
                  : false
              }
              defaultChecked={false}
              label={element.name}
              id={element.id.toString()}
              onChange={() => props?.addTargetCompany(element)}
            />
          ))}
        </div>
        <div className="col4">
          {props?.companyTargetSecound?.map(
            (element: IListTarget, index: number) => (
              <Form.Check
                key={index}
                custom
                defaultChecked={false}
                checked={
                  props?.companyTargetSelected?.find(
                    (ele: IListTarget) =>
                      ele.id?.toString() === element.id?.toString()
                  )
                    ? true
                    : false
                }
                label={element.name}
                id={element.id.toString()}
                onClick={() => {
                  props?.addTargetCompany(element);
                }}
                onChange={() => null}
              />
            )
          )}
        </div>
        <div className="col4">
          {props?.lastCompanyTarget?.map(
            (element: IListTarget, index: number) => (
              <Form.Check
                key={index}
                custom
                defaultChecked={false}
                onChange={() => null}
                checked={
                  props?.companyTargetSelected?.find(
                    (ele: IListTarget) =>
                      ele.id?.toString() === element.id?.toString()
                  )
                    ? true
                    : false
                }
                label={element.name}
                id={element.id.toString()}
                onClick={() => {
                  props?.addTargetCompany(element);
                }}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}
