/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import * as T from "../../../../../../../controller/company-ctr/types/types-company-page";
import { fileUrl, getDomainWebSite } from "../../../../../../../utils";

export default function ClaimStep1(props: T.TFormAddEmailClaimOwnerShip) {
  return (
    <div className="bodyCreateCompany">
      Your claim for the ownership will grant you the admin access to the
      current company page :
      <ul className="listCompanyPagePopup">
        <li>
          <div className="imgctt">
            <img src={fileUrl(props.logoCompany)} alt="" />{" "}
          </div>
          <div className="info">
            <b>{props?.companyName}</b>
            <p>{props?.pays}</p>
            <a href="javascript:;">{props?.webSite}</a>
          </div>
        </li>
      </ul>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="acceptit"
          checked={props?.acceptTerm}
          onChange={() =>
            props?.toogleAcceptTerm &&
            props.toogleAcceptTerm(!props?.acceptTerm)
          }
        />
        <label htmlFor="acceptit" className="custom-control-label mini-font">
          I verify that I am authorized representative of {props?.companyName}{" "}
          and have the right to act on its behalf in the management of this page
        </label>
      </div>
      {props?.acceptTerm && (
        <div className="workMail row my-5">
          <div className="form-group required col-12 proMail">
            <label htmlFor="workmail ">Work email</label>
            {props?.errorDiffWorkEmail && (
              <div className="container-error pl-2 d-inline">
                {/* change d-inline on d-none */}
                <span className="error-red"> error </span>
                <span className="txt-error">
                  Sorry! your work email is different to the domain of the
                  company page{" "}
                  <a onClick={props?.showContactUs}>Please contact us</a>
                </span>
              </div>
            )}
            <div className="relative">
              <span className="sufixProMail">
                @{getDomainWebSite(props?.webSite)}
              </span>
              <input
                type="text"
                className="input-text form-control"
                placeholder="Username"
                value={props?.emailRequest}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
