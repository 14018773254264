/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
//import base url for profil_picture
import baseUrl, {
  HIDDEN_INFORMATION,
} from "../../../../../../service/constant/index";
import { bolderedText, checkIfExist, fileUrl } from "../../../../../../utils";
import ReactHtmlParser from "react-html-parser";

const LeadershipTeam = (props: any) => {
  // Dev #48816, V2.1- Affichage d'un ISV(unlocked) vue par un OEM

  const isLocked = props.name && props.name.includes(HIDDEN_INFORMATION);

  return (
    <div className="item">
      <div className="photo">
        <img src={fileUrl(props.photo_profil)} alt="profile picture" />
        <p className="linkedin_link">
          {isLocked ? <></> : props.name + " "}
          {/* Bug #47387 Leadership team display on company page */}
          {checkIfExist(props.linkedin) && !isLocked && (
            <a
              href={"https://linkedin.com/in/" + props.linkedin}
              target="_blank"
            >
              <span className="icon-linkedin-square"></span>
            </a>
          )}
        </p>
        <span>
          {isLocked
            ? ReactHtmlParser(bolderedText(props.job_title))
            : props.job_title}
        </span>
      </div>
      <div className="desc" style={{ whiteSpace: "break-spaces" }}>
        {props?.description ? props?.description : props?.descriptionFauxTexte}
      </div>
    </div>
  );
};

export default LeadershipTeam;
