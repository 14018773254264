import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { rootState } from "../../../redux/reducers";
import store from "../../../redux/store";
import { postJsonDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import UseSearch from "../../../screen/widget/community/search/use-search";

export type activeFilterContributor =
  | "last-day"
  | "last-week"
  | "last-month"
  | "last-six-mounth"
  | "last-year"
  | "all-time";

export type ItemUser = {
  nbre_likes: number;
  profil_picture: string;
  name: string;
  job_title: string;
  user_id: string;
  order_rank: string;
};
export default function UseViewAllLiked() {
  const location: any = useLocation();
  const history = useHistory();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const [defaultActive, setdefaultActive] = useState<
    "like_given" | "like_from"
  >(location?.state?.type || "like_given");

  const [userId, setuserId] = useState<string>(location?.state?.userId);

  const [loaderLikeFron, setloaderLikeFron] = useState<boolean>(false);
  const [loaderLikeGiven, setLoaderLikeGiven] = useState<boolean>(false);

  const [dataAuthorLikeGive, setDataAuthorLikeGive] = useState<{
    allData: Array<ItemUser>;
    totalData: number;
    topThree: Array<ItemUser>;
  }>({ allData: [], totalData: 0, topThree: [] });

  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();

  const [dataAuthorLikeFrom, setDataAuthorLikeFrom] = useState<{
    allData: Array<ItemUser>;
    totalData: number;
    topThree: Array<ItemUser>;
  }>({ allData: [], totalData: 0, topThree: [] });

  const [filter, setFilter] = useState<activeFilterContributor>("all-time");
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(18);

  const defaultParams = {
    page: 1,
    limit: limit,
    idUser: userId,
    filter: "all-time",
  };

  const getTopLike = async (
    page: number,
    limit: number,
    idUser: string,
    filter: string,
    type: "like_given" | "like_from"
  ) => {
    let params = {
      page: page,
      limit: limit,
      filter: filter,
      idUser: idUser,
    };

    if (type === "like_from") {
      setloaderLikeFron(true);
    }
    if (type === "like_given") {
      setLoaderLikeGiven(true);
    }

    const result = await postJsonDataService(
      type == "like_from"
        ? url?.community?.likes_from
        : url?.community?.likes_given,
      params,
      store?.getState().userReducer?.token
    );
    if (result?.status === 200) {
      if (type === "like_from") {
        let responseProvider = {
          allData: result?.data?.likes_from,
          totalData: result?.data?.total_result_to_paginate,
          topThree: result?.data?.top_three,
        };
        setDataAuthorLikeFrom(responseProvider);
      } else {
        let responseProvider = {
          allData: result?.data?.likes_given_to,
          totalData: result?.data?.total_result_to_paginate,
          topThree: result?.data?.top_three,
        };
        setDataAuthorLikeGive(responseProvider);
      }
    }
    if (type === "like_from") {
      setloaderLikeFron(false);
    }
    if (type === "like_given") {
      setLoaderLikeGiven(false);
    }
  };

  useEffect(() => {
    getTopLike(currentPage, limit, userId, filter, defaultActive);

    return () => {};
  }, []);

  const handlePageClickLikeFrom = (params: number) => {};

  const setFilterLikeFrom = (type: string) => {};

  const showActivityUser = (userId: string) => {};

  const handlePageClickLike = (params: number) => {
    setcurrentPage(params);
    getTopLike(params, limit, userId, filter, defaultActive);
  };

  const setFilterLike = (type: activeFilterContributor) => {
    setcurrentPage(1);
    setFilter(type);
    getTopLike(1, limit, userId, type, defaultActive);
  };

  const handleTypeSelected = (type: "like_from" | "like_given") => {
    setdefaultActive(type);
    setcurrentPage(1);
    setFilter("all-time");
    getTopLike(1, limit, userId, "all-time", type);
  };
  return {
    defaultActive,
    setdefaultActive,
    loaderLikeFron,
    loaderLikeGiven,
    dataAuthorLikeGive,
    dataAuthorLikeFrom,
    filter,
    handlePageClickLikeFrom,
    currentPage,

    setFilterLikeFrom,
    showActivityUser,
    handlePageClickLike,
    setFilterLike,
    handleTypeSelected,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    communityReducer,
  };
}
