/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import * as T from "../../../controller/company-ctr/types/types-company-page";
import { IPropsSF } from "./type";
export default function SofTwareSolution(props: IPropsSF) {
  const [activeId, setActiveId] = useState("0");
  function toggleActive(id: any) {
    if (activeId == id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }
  let arrayMerge = [...props.solutionChecked, ...props.solutionCheckedFeatures];
  if (props?.filterSolutionChecked)
    arrayMerge = props?.filterSolutionChecked(arrayMerge);

  const removeItem = (el: any) => {
    if(props?.removeItemSelected) props.removeItemSelected(el);
  }

  return (
    <div className="getstartedContainer largeContainer">
      <div className="contentCheckboxsolution ">
        <h2 className="title">{props?.featuredSolution?.name}</h2>
        <div className="solutionChecked">
          {arrayMerge?.map((el: any) => (
            <div
              className="item"
              onClick={() => removeItem(el)}
              key={el.id}
            >
              {el.name}
              <span className="icon-close"></span>
            </div>
          ))}
        </div>
        <div className="flex-checkbox ">
          {props?.featuredSolution?.segmentDetails?.map(
            (el: T.ItemsSolution, index: number) => (
              <Form.Check
                custom
                key={index}
                checked={
                  props?.solutionCheckedFeatures?.find(
                    (element: any) => element.id == el.id
                  )
                    ? true
                    : false
                }
                label={el.name}
                id={"featured-" + el.id.toString()}
                onClick={() => props?.addSolutionChechedFeatured(el)}
                onChange={() => null}
              />
            )
          )}
        </div>
        <div className="listAccordionSolution">
          <Accordion defaultActiveKey={activeId}>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 pr-xl-2 pr-lg-2 p-0">
                {props?.solutionLeftColumnData?.map(
                  (solution: T.ISolutionListe, index: number) => {
                    return (
                      <div
                        className={"panel-wrap active-panel"}
                        onClick={() => props?.addSolutionCheched(solution)}
                        key={index}
                      >
                        <div className="panel-header">
                          <Accordion.Toggle
                            onClick={() => toggleActive(solution.id)}
                            className={`panel-toggle noicon ${
                              props?.solutionChecked?.find(
                                (el: any) => el?.id == solution.id
                              )
                                ? "colored"
                                : ""
                            } `}
                            eventKey={solution.id.toString()}
                          >
                            {solution && solution.name}
                          </Accordion.Toggle>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 pl-xl-2 pl-lg-2 p-0">
                {props?.solutionRigthColumnData?.map(
                  (solution: T.ISolutionListe, index: number) => {
                    return (
                      <div
                        className={"panel-wrap active-panel"}
                        onClick={() => props?.addSolutionCheched(solution)}
                        key={index}
                      >
                        <div className="panel-header">
                          <Accordion.Toggle
                            onClick={() => toggleActive(solution.id)}
                            className={`panel-toggle noicon ${
                              props?.solutionChecked?.find(
                                (el: any) => el?.id == solution.id
                              )
                                ? "colored"
                                : ""
                            } `}
                            eventKey={solution?.id?.toString()}
                          >
                            {solution && solution.name}
                          </Accordion.Toggle>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
