import React, { useState } from "react";
import { Myfeed } from "../../../interface/isv";
import {
  concatString,
  convertCommentFromJSONToHTML,
  createMarkup,
  dateDiff,
  fileUrl,
  generateTextFromEditor,
  getDescriptionEditorState,
  getUserType,
} from "../../../utils";
import { useHistory, useParams } from "react-router";

interface Iprops {
  feed: Myfeed;
  handleLiked: (idParent: string) => void;
}

export default function Feeds(props: Iprops) {
  const { feed } = props;

  const history = useHistory();

  const [show, setShow] = useState<boolean>(false);

  const goToreplayPost = () => {
    const categorieId = feed?.category?.id;
    const postId = feed?.id;
    history.push("/community/reply-post/" + categorieId + "/" + postId);
  };

  const goActivity = (id: string) => {
    history.push("/community/activity-user/" + id);
  };

  const goToLabelCategory = (items: any, idLabelTags: string) => {
    const postType = items?.post_type?.id;

    if (postType?.toString() === "1" || postType?.toString() === "2") {
      const labels = items.labels?.find(
        (el: any) => el?.id?.toString() === idLabelTags
      );
      history.push(
        "/community/one-category/" + labels?.category?.id + "/" + idLabelTags
      );
    }
  };

  return (
    <li>
      <div className="withPost">
        <div
          className={
            feed?.user?.file_name
              ? "imageAffiliatedcompany empty"
              : "imageAffiliatedcompany"
          }
        >
          {feed?.user?.file_name && (
            <img src={fileUrl(feed?.user?.file_name)} alt="" />
          )}
        </div>
        <div className={"nameOfPost " + getUserType(feed?.user?.roles?.[0])} />
      </div>
      <div className="infoFeed">
        <div className="postBy">
          <a
            href="javascript:;"
            onClick={() => goActivity(feed?.user?.id.toString())}
          >
            {concatString(feed?.user?.first_name, feed?.user?.last_name, " ")}{" "}
          </a>
          posted new discussion
        </div>
        <div className="titlePost" onClick={goToreplayPost}>
          {feed?.post_type?.id?.toString() === "2" && (
            <span className="article">Article</span>
          )}
          {feed?.solve && <span className="icon-check" />}
          <span className="title">{feed?.title}</span>
        </div>
        <div
          className={`textArticle ${show ? "" : "hideTxt"}`}
          onClick={() => setShow((c) => !c)}
        >
          {feed?.description && (
            <div
              dangerouslySetInnerHTML={createMarkup(
                convertCommentFromJSONToHTML(
                  getDescriptionEditorState(feed?.description)
                )
              )}
            />
          )}
        </div>
        <div className="categContainer">
          <div className="category">{feed?.category?.title}</div>
          <div className="subCategory">
            {feed?.labels.map((e, i) => (
              <span
                key={i}
                onClick={() => goToLabelCategory(feed, e.id?.toString())}
              >
                {e?.title}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="infoAboutPost">
        <div className="time">{dateDiff(feed?.created_at)}</div>
        <div className="likeandComment">
          <div className="comment">
            <span className="icon-conversation" />
            {feed?.numberOfReplies}
          </div>
          <div
            className="nbrLike"
            onClick={() => props?.handleLiked(feed?.id?.toString())}
          >
            {feed?.liked ? (
              <span className="icon-like-full" />
            ) : (
              <span className="icon-like" />
            )}
            {feed?.numberOfLikes}
          </div>
          <div
            className={`linkDrop ${show ? "open" : ""}`}
            onClick={() => setShow((c) => !c)}
          />
        </div>
      </div>
    </li>
  );
}
