import React, { ChangeEvent } from "react";
import Header from "../../../../widget/header-logo-center/header-logo-center";
import UseCompleteInfoExpert from "../../../../../controller/account-ctr/complete-create-acount-expert-contributor/use-complete-info-expert";
import useGoBack from "../../../../../hooks/use-navigate";
import CheckIcon from "@material-ui/icons/Check";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { isEmail } from "../../../../../utils";

export default function CompleteInfoExepert() {
  const {
    register,
    handleSubmit,
    errors,
    emailNotSafe,
    loader,
    txtEmailError,
    checkIfAlreadyEmailExist,
    onSubmit,
  } = UseCompleteInfoExpert();
  return (
    <div>
      <Header />
      <form className="getstartedContainer" onSubmit={handleSubmit(onSubmit)}>
        <div className="getstartedContainer">
          <div className="head-getstarted">
            <div className="stepInscription">
              <div className="stepItem done"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
              <div className="stepItem"></div>
            </div>
            <h1>Create your account.</h1>
            {/* <a onClick={goBack} className="link-back">
            <span className="icon-back"></span>
          </a> */}
          </div>
          <div className="contentBorder border-top row py-3">
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label htmlFor="first-name required">
                First name
                {errors?.firstNameExpert && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...register("firstNameExpert", {
                  required: true,
                })}
              />
            </div>
            <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label htmlFor="first-name required">
                Last name
                {errors?.lastNameExpert && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...register("lastNameExpert", {
                  required: true,
                })}
              />
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              {emailNotSafe === "false" && (
                <CheckIcon className="mailcheckOK" />
              )}
              <label htmlFor="first-name required">
                Business email
                {errors?.businessEmailExpert?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                {errors.businessEmailExpert?.type === "validate" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className='error-red'> error </span>
                    <span className='txt-error'>
                      Email address is not valid
                    </span>
                  </div>
                )}
                {emailNotSafe === "true" && (
                  <div className="container-error d-inline pl-3">
                    <span className="error-red"> error </span>
                    <span className="txt-error">{txtEmailError}</span>
                  </div>
                )}
              </label>

              <input
                type="text"
                className="input-text form-control"
                {...register("businessEmailExpert", {
                  required: true,
                  validate: (email: string) => {
                    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;                    ;
                    return emailRegex.test(email);
                  },
                })}
                onBlur={(evt: ChangeEvent<HTMLInputElement>) =>
                  checkIfAlreadyEmailExist(evt.target.value)
                }
                autoComplete="off"
              />
            </div>
            <div className="required form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label htmlFor="first-name required">
                Password
                <span className="d-inline">(12 or more characters )</span>
                {errors?.passwordExpert?.type == "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
                {errors?.passwordExpert?.type == "minLength" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                    password length must be 12 or more characters
                    </span>
                  </div>
                )}
                {errors?.passwordExpert?.type == "pattern" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      password must contain at least one uppercase, lowercase, special character and one number
                    </span>
                  </div>
                )}
              </label>
              <input
                type="password"
                className="input-text form-control"
                {...register("passwordExpert", {
                  required: true,
                  minLength: 12,
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{12,}$/
                })}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="contentBorder border-top row py-3 align-items-stretch">
            <div className="form-group form-check col-xl-6 col-md-6 col-sm-12">
              <input
                type="checkbox"
                className="form-check-input"
                {...register("acceptTermsExpert", {
                  required: true,
                })}
              />
              <label htmlFor="" className="form-check-label termsLink">
                I agree to Devinsider’s&nbsp;
                <a onClick={() => window.open("/term-of-use", "_blank")}>
                  terms
                </a>{" "}
                and &nbsp;
                <a onClick={() => window.open("/privacy-policy", "_blank")}>
                  privacy policy
                </a>
                .
              </label>

              {errors?.acceptTermsExpert && (
                <div className="container-error pl-2 d-inline">
                  {/* change d-inline on d-none */}
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 d-flex align-items-center pr-0">
              <button
                className="btn btn-devinsider large containerBtnBtn"
                type="submit"
              >
                {loader ? <Loader /> : "Continue"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
