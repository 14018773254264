import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from "../../../resources/images/Rolling.svg";
import { IDataResource, IPropsModalRessource } from "./type";

export default function EditRessource(props: IPropsModalRessource) {
  const getExtensionFileName = (filename: string) => {
    const file = filename.split(".").pop()?.toLowerCase();
    return file === "pdf"
      ? "icon-file-pdf"
      : file?.includes("doc") || file?.includes("docx")
      ? "icon-file-word"
      : "icon-empty-file";
  };

  return (
    <Modal
      show={props.isShowRessource}
      className="createCompanypopup large forResources bgGris"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title"> Edit resources</h2>
          <a className="close" onClick={() => props?.closeRessourceModal()}>
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row mx-auto modalInvestor">
          <div className="form-group col-12">
            <ul className="listSquareItem resource grisbg">
              {props?.fileRessourceEdit?.map(
                (el: IDataResource, index: number) => (
                  <li key={index}>
                    {el?.file_name && (
                      <a
                        className="removeImg"
                        onClick={() => props.removeFile(el?.id, index)}
                      >
                        <span className="icon-close-pop" />
                      </a>
                    )}

                    <label>
                      <input
                        type="file"
                        accept=".doc,.docx,.pdf"
                        style={{ display: "none !important", opacity: 0 }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          props.setFileResource(event, el?.id);
                        }}
                        disabled={el?.file_name != ""}
                      />
                      <a
                        target="_blank"
                        className={`${el?.file_name ? "" : "empty"} `}
                      >
                        <div className="typeDoc">
                          <span
                            className={getExtensionFileName(el.file_name)}
                          ></span>
                        </div>
                        <div className="titleDoc">{el?.file_name}</div>
                      </a>
                      {!el.file_name && (
                        <a
                          target="_blank"
                          className={`${el?.file_name ? "" : "addResource"} `}
                        >
                          Add resource
                        </a>
                      )}
                    </label>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right forPitch">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props?.closeRessourceModal()}
            type="button"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-devinsider px-5"
            onClick={() => props?.saveUpdate()}
          >
            {props?.loader ? <Loader /> : "Save"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
