import React, { useEffect } from "react";
import Header from "../../../../widget/index";
import Select from "react-select";
import { LoadingArticle } from "../../../../widget/loader";
import { concatString, fileUrl, getUserType } from "../../../../../utils";
import Pagination from "../../../../widget/use-pagination/pagination";
import UseUnanswered, {
  ItemUnanswered,
} from "../../../../../controller/community-ctr/bloc-community-ctr/use-view-all-unanswered";
import moment from "moment";
import SearchForm from "../../../../widget/community/search/index";
import Footer from "../../../../widget/footer/footer";

export default function Index() {
  const providerUnanswered = UseUnanswered();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="container mx-auto">
        <ul className="breadcrumbPerso">
          <li>
            <a href="javascript:;">Community</a>
          </li>
          <li>Discussion categories</li>
        </ul>
        <div className="searchTopic">
          <SearchForm
            categories={
              providerUnanswered?.communityReducer?.discussionCategories
            }
            filterSearchText={providerUnanswered?.filterSearchText}
            handleFilterTxt={providerUnanswered?.handleFilterTxt}
            searchText={providerUnanswered?.inputText}
            handleCateGory={providerUnanswered?.handleCateGory}
          />
        </div>
        <div className="rightCommunityForum topContainer">
          <div className="containerCategory">
            <div className="titleCategory">
              <h2>Discussions without replies</h2>
              <div className="tri">
                <div className="labelSelect">
                  <label>Show:</label>
                  <Select
                    className="triNumber"
                    isClearable={false}
                    options={[
                      { value: "10", label: "10" },
                      { value: "20", label: "20" },
                      { value: "30", label: "30" },
                    ]}
                    classNamePrefix="tri"
                    defaultValue={{
                      value: providerUnanswered?.limit?.toString(),
                      label: providerUnanswered?.limit?.toString(),
                    }}
                    value={{
                      value: providerUnanswered?.limit?.toString(),
                      label: providerUnanswered?.limit?.toString(),
                    }}
                    onChange={(params: any) =>
                      providerUnanswered?.setPaginateDataFilter(params?.value)
                    }
                  />
                </div>

                <div className="sortedby labelSelect">
                  <label>Sorted by : </label>
                  <Select
                    className="triOreder"
                    isClearable={false}
                    options={[
                      { value: "post_date", label: "Post date" },
                      { value: "first_activity", label: "First activity" },
                      { value: "last_activity", label: "Latest activity" },
                      { value: "replies", label: "Replies" },
                      { value: "views", label: "Views" },
                    ]}
                    classNamePrefix="tri"
                    defaultValue={{
                      value: providerUnanswered?.sortedBy,
                      label: "Post date",
                    }}
                    onChange={(params: any) =>
                      providerUnanswered?.setSortedate(params?.value)
                    }
                  />
                </div>
              </div>
            </div>

            <>
              {providerUnanswered?.loader ? (
                <LoadingArticle />
              ) : (
                <>
                  <ul className="feedList categoryList">
                    {providerUnanswered?.allData?.map(
                      (el: ItemUnanswered, index: number) => (
                        <li key={index}>
                          {el?.solve && (
                            <div className="solved">
                              <div className="contentSolved">
                                <span className="icon-check"></span>
                              </div>
                            </div>
                          )}

                          <div className="infoAbout">
                            <div className="withPost">
                              <div className="imageAffiliatedcompany empty">
                                {el?.user?.file_name && (
                                  <img
                                    src={fileUrl(el?.user?.file_name)}
                                    alt=""
                                  />
                                )}
                              </div>
                              <div
                                className={`nameOfPost ${getUserType(
                                  el?.user?.roles?.[0]
                                )}`}
                              ></div>
                            </div>{" "}
                            <div className="infoFeed">
                              <div
                                className="titlePost"
                                onClick={() =>
                                  providerUnanswered?.goToreplayPost(
                                    el.category.id?.toString(),
                                    el.id?.toString()
                                  )
                                }
                              >
                                {el?.post_type?.id?.toString() === "2" && (
                                  <span className="article">Article</span>
                                )}
                                <span className="title">{el?.title}</span>
                              </div>
                              <div className="postBy">
                                by{" "}
                                <a
                                  href="javascript:;"
                                  onClick={(e: any) => {
                                    e?.preventDefault();
                                    providerUnanswered?.showActivityUser(
                                      el?.user?.id?.toString()
                                    );
                                  }}
                                >
                                  {concatString(
                                    el?.user?.first_name,
                                    el?.user?.last_name,
                                    " "
                                  )}
                                </a>{" "}
                                on{" "}
                                {moment(new Date(el?.created_at)).format(
                                  "MMM D, YYYY h:mm a"
                                )}
                              </div>

                              <div className="subCategory">
                                {el?.labels?.map(
                                  (label: any, index: number) => (
                                    <span
                                      key={index}
                                      onClick={() =>
                                        providerUnanswered.goToLabelCategory(
                                          el?.category?.id?.toString(),
                                          label?.id,
                                          el.post_type?.id?.toString()
                                        )
                                      }
                                    >
                                      {label?.title}
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="replyInfo">
                            <b>{el?.numberOfLikes}</b>Likes
                          </div>
                          <div className="viewInfo">
                            <b>{el?.numberOfViews}</b>views
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                  <div className="pagginationCategory">
                    <div className="labelSelect">
                      <label>Show: </label>
                      <Select
                        className="triNumber"
                        isClearable={false}
                        options={[
                          { value: "10", label: "10" },
                          { value: "20", label: "20" },
                          { value: "30", label: "30" },
                        ]}
                        classNamePrefix="tri"
                        defaultValue={{
                          value: providerUnanswered?.limit?.toString(),
                          label: providerUnanswered?.limit?.toString(),
                        }}
                        value={{
                          value: providerUnanswered?.limit?.toString(),
                          label: providerUnanswered?.limit?.toString(),
                        }}
                        onChange={(params: any) =>
                          providerUnanswered?.setPaginateDataFilter(
                            params?.value
                          )
                        }
                      />
                    </div>
                    <div className="pagination">
                      <Pagination
                        className="pagination"
                        currentPage={providerUnanswered?.currentPage}
                        totalCount={providerUnanswered?.dataLength}
                        pageSize={providerUnanswered?.limit}
                        onPageChange={(page: any) =>
                          providerUnanswered?.handlePageClick(page)
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
