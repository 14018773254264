import React from 'react';
import useOverview from '../../../../../controller/oem-ctr/block-component/use-oem-overview-ctr';
import TemplateWebsite from '../../../../widget/template-website';
import { ItemsDataSource } from '../../../investor/profil-investor/types';
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import { fileUrl, isWysiwygEmpty } from '../../../../../utils';
import {
  convertCommentFromJSONToHTML,
  customClass,
} from '../../../../../utils';

type IProps = {
  isDoneEditing: boolean;
  isPreview: boolean;
  initialeDataSource: Array<ItemsDataSource>;
  isEditClick: boolean;
  setIsEditClick: (p: boolean) => void;
  clickCancel: () => void;
  saveAllDataForm: () => void;
  loader: boolean;
  choseDisposition: (p: string, index: number) => void;
  setEditorState: (p: any, e: any) => void;
  removeElement: (index: number) => void;
  setFileExpertise: (p: any, index: number) => void;
  removeFile: (index: number) => void;
  isHaveError: boolean;
  addNewElement: () => void;
};
export default function OemOverview(props: IProps) {
  // const ctr = useOverview();

  function createMarkup(html: string) {
    return {
      __html: html,
    };
  }

  const isShowParsedWisiwyg =
    (props?.initialeDataSource?.length > 0 && !props?.isEditClick) ||
    !props.isDoneEditing;

  const isHaveTxtContent =
    props?.initialeDataSource[0]?.editorState
      ?.getCurrentContent()
      .getPlainText() === '' && props?.initialeDataSource?.length === 1;

  const isShowPlaceholder = props?.isDoneEditing || isHaveTxtContent;

  return (
    <article className='investorArticle bgGris'>
      <div className='mx-auto container cont3'>
        <div className='contentLeftInvestor'>
          {!props.isEditClick && (
            <a
              className={`linkEditLeftCompany ${
                props.isEditClick ? 'editShow' : ''
              }`}
              onClick={() => props.setIsEditClick(true)}
            >
              Edit
              <span className='icon-edit-full' />
            </a>
          )}

          <h3 className='title'>Overview</h3>
          {isShowPlaceholder && (
            <p>Outline a brief overview of your program. </p>
          )}

          {isShowParsedWisiwyg &&
            props?.initialeDataSource?.map((el: any, index: number) => (
              <div
                className={`${customClass(
                  el?.fileUrl ? el?.disposition : ' test-only'
                )} elementDisposition`}
                key={index}
              >
                <div className='imageElement'>
                  <img src={fileUrl(el?.fileUrl)} alt='' />
                </div>
                <div className='textElement format-wysiwyg'>
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      convertCommentFromJSONToHTML(el?.editorState)
                    )}
                  />
                </div>
              </div>
            ))}

          {isShowPlaceholder && !props.isPreview && (
            <div className='fauxText justForProfil'>
              <div className='l100'></div>
              <div className='l100'></div>
              <div className='l100'></div>
              <div className='l100'></div>
              <div className='l25'></div>
            </div>
          )}

          {props?.isEditClick && (
            <>
              <div className='formForEmail companyInvestorbtnctt editShow'>
                <button
                  className='btn btn-devinsider cancel'
                  onClick={() => props?.clickCancel()}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-devinsider px-5'
                  onClick={() => props?.saveAllDataForm()}
                >
                  {props?.loader ? <Loader /> : 'Save'}
                </button>
              </div>

              <div className={`editShow expertise`}>
                {props?.initialeDataSource?.map(
                  (element: ItemsDataSource, index: number) => (
                    <TemplateWebsite
                      key={index}
                      editorState={element?.editorState}
                      setEditorState={(p: any) =>
                        props?.setEditorState(p, index)
                      }
                      disposition={element?.disposition}
                      fileUrl={element?.fileUrl}
                      dataFile={element?.dataFile}
                      choseDisposition={(p: string) =>
                        props?.choseDisposition(p, index)
                      }
                      indexOfTemplate={element?.itemIndex}
                      removeElement={() => props?.removeElement(index)}
                      setFileExpertise={(p: any) =>
                        props?.setFileExpertise(p, index)
                      }
                      removeFile={() => props?.removeFile(index)}
                      isHaveError={props?.isHaveError}
                    />
                  )
                )}
              </div>
              {props.isDoneEditing && (
                <a
                  className={`addElement editShow`}
                  onClick={() => props?.addNewElement()}
                >
                  <span>Element</span>
                </a>
              )}
            </>
          )}
        </div>
      </div>
    </article>
  );
}
