import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { IPropsSliderFundraising } from "./type";
export default function SliderCompanySize(props: IPropsSliderFundraising) {
  const createSliderWithTooltip = Slider.Range;
  const Range = createSliderWithTooltip;
  return (
    <div className="row m-0 p-0">
      <div className="col-xl-6 col-lg-8 col-md-12 col-12  p-0 pb-5 m-0">
        <Range
          className="targetInvestorrange"
          min={0}
          max={110}
          step={10}
          value={props?.fundraisingGoal}
          marks={{
            0:
              //Dev #47947, V2.1 [Investor] Ajouter le $ pour les champs "Annual revenue" et "Fundraising goal"
              (props?.fundraisingGoalSlide[0] == 0
                ? "$ 0 USD"
                : "$ " + props?.fundraisingGoalSlide[0]) +
              props?.unitFundraisingGoal,
            10: "",
            20: "",
            30: "",
            40: "",
            50: "",
            60: "",
            70: "",
            80: "",
            90: "",
            100: "",
            110:
              //Dev #47947, V2.1 [Investor] Ajouter le $ pour les champs "Annual revenue" et "Fundraising goal"
              props?.fundraisingGoalSlide[1] == 60
                ? "> $ 50" + props?.unitFundraisingGoalMax
                : props?.fundraisingGoalSlide[1] == 110
                ? "> $ 50" + props?.unitFundraisingGoalMax
                : "$ " +
                  props?.fundraisingGoalSlide[1] +
                  props?.unitFundraisingGoalMax,
          }}
          onChange={(event) => {
            props?.setFundraisingGoal(event);
            props?.customFundraisingGoalSlide(event[0], event[1]);
          }}
        />
      </div>
    </div>
  );
}
