import React from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { IPropsModalFunding } from "../types";
import alternativeFinancing from "../../../../../resources/images/Alternative_financing.png"

export default function EditFundingRound(props: IPropsModalFunding) {
  return (
    <Modal
      show={props.showModalFunding}
      className="createCompanypopup large forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Funding</h2>
          <a className="close" onClick={() => props?.closeEditFunding()}>
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row mx-auto modalInvestor">
          <div className="form-group col-12">
            <ul className="listSquareItem">
              <li onClick={() => props?.clickFunding(1)}>
                <a
                  className={
                    props?.listFundingId?.find((e) => e.id == 1) ? "active" : ""
                  }
                >
                  <span className="icon-seed-money"></span>
                  Seed money
                  {props?.itemsClick == 1 && <Loader />}
                </a>
              </li>
              <li onClick={() => props?.clickFunding(2)}>
                <a
                  className={
                    props?.listFundingId?.find((e) => e.id == 2) ? "active" : ""
                  }
                >
                  <span className="icon-series-money"></span>
                  Series a money
                  {props?.itemsClick == 2 && <Loader />}
                </a>
              </li>
              <li onClick={() => props?.clickFunding(3)}>
                <a
                  className={
                    props?.listFundingId?.find((e) => e.id == 3) ? "active" : ""
                  }
                >
                  <span className="icon-growth-money"></span>
                  Growth money
                  {props?.itemsClick == 3 && <Loader />}
                </a>
              </li>
              <li className="alternative" onClick={() => props?.clickFunding(4)}>
                <a
                  className={
                    props?.listFundingId?.find((e) => e.id == 4) ? "active" : ""
                  }
                >
                  <div className="forFinancing">
                    <img className="alternativeFinancing" src={alternativeFinancing} alt="" />
                  </div>
                  Alternative financing
                  {props?.itemsClick == 4 && <Loader />}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right forPitch">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props?.closeEditFunding()}
            type="button"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-devinsider px-5"
            onClick={() => props?.saveFundingRound()}
          >
            {props?.loader ? <Loader /> : "Save"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
