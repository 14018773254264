import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useEventListener from "../../../hooks/event-handler";
import { getSStorage } from "../../../utils";

export interface ItemsSolution {
  id: number;
  name: string;
}
export interface ISolutionListe {
  id: number;
  name: string;
  segmentDetails: Array<ItemsSolution>;
}

export interface IdataMakeSolution {
  idSolutionOffert: number;
}
export default function UseCompleteCategoryInvestor() {
  const history = useHistory();
  const [allResellerSolution, setAllResellerSolution] = useState<
    Array<ISolutionListe>
  >([]);

  const [solutionLeftColumnData, setSolutionLeftColumnData] = useState<
    Array<ISolutionListe>
  >([]);

  const [solutionRigthColumnData, setSolutionRigthColumnData] = useState<
    Array<ISolutionListe>
  >([]);
  const [loader, setLoaderState] = useState<boolean>(true);

  const [solutionChecked, setSolutionChecked] = useState<Array<number>>(
    JSON.parse(getSStorage("solution_company_investor") || "[]")
  );

  const [solutionCheckedFeatures, setSolutionCheckedFeatured] = useState<
    Array<number>
  >(JSON.parse(getSStorage("solutionCheckedFeatures") || "[]"));

  const [featuredSolution, setFeaturedSolution] = useState<ISolutionListe>();
  const [errorData, seterrorData] = useState<boolean>(false);
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const navigateToIndustrieInvestor = () => {
    const isChecked =
      solutionChecked.length > 0 || solutionCheckedFeatures.length > 0;

    if (isChecked) {
      sessionStorage.setItem(
        "solution_company_investor",
        JSON.stringify(solutionChecked)
      );
      sessionStorage.setItem(
        "solutionCheckedFeatures",
        JSON.stringify(solutionCheckedFeatures)
      );
      history.push("/complete-indistrue-investor");
    } else {
      seterrorData(true);
    }
  };

  useEffect(() => {
    let lengthSolution = allResellerSolution.length;
    if (lengthSolution % 2 === 0) {
      setSolutionLeftColumnData(
        allResellerSolution.slice(0, lengthSolution / 2)
      );
      setSolutionRigthColumnData(
        allResellerSolution.slice(lengthSolution / 2, lengthSolution + 1)
      );
    } else {
      setSolutionLeftColumnData(
        allResellerSolution.slice(0, (lengthSolution - 1) / 2 + 1)
      );
      setSolutionRigthColumnData(
        allResellerSolution.slice(
          (lengthSolution - 1) / 2 + 1,
          lengthSolution + 1
        )
      );
    }
    return () => {};
  }, [allResellerSolution]);

  useEffect(() => {
    async function getSolution() {
      let featured = dataCompleted?.segments?.find((el: any) => el.id === 9999);
      setFeaturedSolution(featured);
      setAllResellerSolution(
        dataCompleted?.segments?.filter((el: any) => el.id !== 9999)
      );
      setLoaderState(false);
    }
    getSolution();
    return () => {};
  }, []);

  const addSolutionCheched = (id: number) => {
    let found = solutionChecked?.find((el: number) => el === id);
    if (!found) {
      setSolutionChecked([...solutionChecked, id]);
    } else {
      setSolutionChecked(
        solutionChecked?.filter((elem: number) => elem !== id)
      );
    }
  };

  const addSolutionChechedFeatured = (id: number) => {
    let found = solutionCheckedFeatures?.find((el: number) => el === id);
    if (!found) {
      setSolutionCheckedFeatured([...solutionCheckedFeatures, id]);
    } else {
      setSolutionCheckedFeatured(
        solutionCheckedFeatures?.filter((elem: number) => elem !== id)
      );
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key === "Enter") {
      navigateToIndustrieInvestor();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    solutionLeftColumnData,
    solutionRigthColumnData,
    loader,
    featuredSolution,
    errorData,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    navigateToIndustrieInvestor,
    setSolutionChecked,
  };
}
