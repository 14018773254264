import { getData, postData } from "../api";
import { url } from "../constant";

export const getUpcomingEvent = (token: string): Promise<any> => {
  return getData(`${url.isv.upcoming_event}`, token);
};

export const getSubscribedDiscussion = (
  limit: number,
  token: string
): Promise<any> => {
  return postData(
    url.isv.subscribe_discuss,
    { limit, page: 1, sortBy: "unread", orderBy: "DESC" },
    token
  );
};

export const getMyFeed = (offset: number, limit: number, token: string) => {
  return postData(url.isv.my_feed, { offset, limit }, token);
};
