import { constante } from "../../actions/event-action";
import { EventObject, IAction } from "../../types/event-interface";
import { filterEventDelete, updateEventHelper } from "./helper-events";
export const initialState: EventObject = {
  event_type: [],
  top_tags: [],
  all_events: {
    results: [],
    total: 0,
    current_page: 0,
  },
  my_events: {
    results: [],
    total: 0,
  },
};

export const eventReducer = (
  state = initialState,
  action: IAction
): EventObject => {
  switch (action?.type) {
    case constante.GET_EVENT_FILTERS:
      return {
        ...state,
        ...action?.payload,
      };
    case constante.GET_ALL_EVENTS:
      return {
        ...state,
        all_events: action?.payload,
      };
    case constante.APPEND_ALL_EVENTS:
      return {
        ...state,
        all_events: {
          results: [...state.all_events.results, ...action?.payload?.results],
          total: action?.payload?.total,
          current_page: action?.payload?.current_page,
        },
      };
    case constante.GET_MY_EVENTS:
      return {
        ...state,
        my_events: action?.payload,
      };
    case constante.APPEND_MY_EVENTS:
      return {
        ...state,
        my_events: {
          results: [...state?.my_events?.results, ...action?.payload?.results],
          total: action?.payload?.total,
        },
      };
    case constante.DELETE_EVENTS:
      return {
        ...state,
        my_events: {
          ...state?.my_events,
          results: filterEventDelete(
            state?.my_events?.results,
            action?.payload
          ),
        },
      };
    case constante?.UPDATE_MY_EVENTS:
      return {
        ...state,
        my_events: {
          ...state?.my_events,
          results: updateEventHelper(
            state?.my_events?.results,
            action?.payload
          ),
        },
      };
    default:
      return state;
  }
};
