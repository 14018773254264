import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { isEmail, setSessionStorageObject } from "../../../utils/index";
import { checkIfEmailExist } from "../../../service/applicatif/acount";
import useEventListener from "../../../hooks/event-handler";
import { useForm } from "react-hook-form";
export type InputInfoReseller = {
  firstNameReseller: string;
  lastNameReseller: string;
  emailReseller: string;
  passWordReseller: string;
  agreeTermsReseller: boolean;
};
export default function UseCompleteInfoReseller() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<InputInfoReseller>({
    defaultValues: {
      firstNameReseller: sessionStorage.getItem("firstNameReseller") || "",
      lastNameReseller: sessionStorage.getItem("lastNameReseller") || "",
      emailReseller: sessionStorage.getItem("emailReseller") || "",
      passWordReseller: sessionStorage.getItem("passWordReseller") || "",
      agreeTermsReseller: sessionStorage.getItem("agreeTermsReseller")
        ? true
        : false,
    },
  });
  const emailWatch = watch("emailReseller");
  const firstNameWatch = watch("firstNameReseller");
  const lastNameWatch = watch("lastNameReseller");
  const passWordRWatch = watch("passWordReseller");
  const agreeTermsReseller = watch("agreeTermsReseller");

  const refSubmit: React.RefObject<HTMLInputElement> = useRef(null);

  const [loader, setloader] = useState<boolean>(false);
  /*
   *Error form Validation
   */
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [txtEmailError, setTxtEmailError] = useState<string>("");
  const [safeEmail, setSafeEmail] = useState<string>("");
  const [infoCompleted, setinfoCompleted] = useState<boolean>(false);
  /*
   * Check if an email already use
   * paarams email adress string
   */
  const checkIfAlreadyEmailExist = async (email: string) => {
    if (isEmail(email)) {
      setloader(true);
      let response = await checkIfEmailExist(email);
      if (response?.status == 200 && response?.data?.details) {
        setErrorEmail(true);
        if (
          response?.status == 200 &&
          response?.data?.reason == "invalid domain"
        ) {
          setTxtEmailError("Professional email is required");
        } else {
          setTxtEmailError("This email address is already  used");
        }
        setloader(false);
      } else {
        setloader(false);
        setErrorEmail(false);
        setSafeEmail("ok");
        if (
          Object.values(errors)?.length < 1 &&
          emailWatch != "" &&
          lastNameWatch !== "" &&
          firstNameWatch !== "" &&
          passWordRWatch !== "" &&
          agreeTermsReseller
        ) {
          setSessionStorageObject({
            firstNameReseller: firstNameWatch,
            lastNameReseller: lastNameWatch,
            emailReseller: emailWatch,
            passWordReseller: passWordRWatch,
            agreeTermsReseller: agreeTermsReseller,
          });

          history.push("/complete-adresse-reseller");
        }
      }
    }
  };

  useEffect(() => {
    if (errorEmail || safeEmail == "ok") {
      setErrorEmail(false);
      setTxtEmailError("");
      setSafeEmail("");
      setinfoCompleted(false);
    }
    return () => {};
  }, [emailWatch]);

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      handleBtn();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  const onSubmitForm = async (data: InputInfoReseller) => {
    if (safeEmail == "") {
      await checkIfAlreadyEmailExist(data?.emailReseller);
    }
    const dataObject = { ...data };
    setSessionStorageObject(dataObject);
    history.push("/complete-adresse-reseller");
  };

  const handleBtn = () => {};

  return {
    errorEmail,
    loader,
    register,
    handleSubmit,
    errors,
    onSubmitForm,
    checkIfAlreadyEmailExist,
    txtEmailError,
    safeEmail,
    refSubmit,
  };
}
