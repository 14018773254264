import { IProgramFilter } from "../../../../controller/program-ctr/type";
import UseBlocLeft from "../../../../controller/program-ctr/left-program-ctr/directory-bloc-left-ctr";
import { paginate } from "../../../../utils";

const BlocLeftDirectory = (props: IProgramFilter) => {
  const providerBlocLeft = UseBlocLeft(props);

  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains("open")) {
      elementH3.classList.remove("open");
    } else {
      elementH3.classList.add("open");
    }
  }

  return (
    <div className="filterFindIsv">
      <div className="contentFilter">
        <div className="containerBtn block">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => providerBlocLeft?.filter()}
          >
            Filter
          </button>
          <a
            href="javascript:;"
            className="resetFilter"
            onClick={() => providerBlocLeft?.resetFilter()}
          >
            <span className="icon-refresh"></span>
            Reset filter
          </a>
        </div>
        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Program type
          </h3>
          <div className="listCheck">
            {paginate(
              providerBlocLeft?.findProgramReducer?.oem_program_type,
              providerBlocLeft?.limitProgram
            )?.map((el: { id: number; name: string }, key: number) => (
              <div className="custom-control custom-checkbox orange" key={key}>
                <input
                  type="checkbox"
                  id={"type" + key}
                  className="custom-control-input"
                  checked={
                    providerBlocLeft?.selectedProgram?.find(
                      (value) => value === el?.id
                    )
                      ? true
                      : false
                  }
                  value={el?.id}
                  onClick={() => providerBlocLeft?.handleProgram(el?.id)}
                />
                <label htmlFor={"type" + key} className="custom-control-label">
                  {el?.name}
                </label>
              </div>
            ))}
            {providerBlocLeft?.findProgramReducer?.oem_program_type?.length >
            providerBlocLeft?.limitProgram ? (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() => providerBlocLeft?.expandOrNot("SOLUTION")}
              >
                See more(
                {providerBlocLeft?.findProgramReducer?.oem_program_type
                  ?.length - providerBlocLeft?.limitProgram}
                )
              </a>
            ) : (
              providerBlocLeft?.findProgramReducer?.oem_program_type?.length <=
                providerBlocLeft?.limitProgram &&
              providerBlocLeft?.findProgramReducer?.oem_program_type?.length >
                6 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() => providerBlocLeft?.expandOrNot("SOLUTION", 2)}
                >
                  See less
                </a>
              )
            )}
          </div>
        </div>
        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Headquarters location
          </h3>
          <div className="listCheck">
            {paginate(
              providerBlocLeft?.findProgramReducer?.hq_location,
              providerBlocLeft?.limitLocation
            )?.map((el: { id: number; country_name: string }, key: number) => (
              <div className="custom-control custom-checkbox orange" key={key}>
                <input
                  type="checkbox"
                  id={"location" + key}
                  className="custom-control-input"
                  checked={
                    providerBlocLeft?.selectedHqLocation?.find(
                      (value) => value === el?.id
                    )
                      ? true
                      : false
                  }
                  onClick={() => providerBlocLeft?.handleLocation(el?.id)}
                />
                <label
                  htmlFor={"location" + key}
                  className="custom-control-label"
                >
                  {el?.country_name}
                </label>
              </div>
            ))}
            {providerBlocLeft?.findProgramReducer?.hq_location?.length >
            providerBlocLeft?.limitLocation ? (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() => providerBlocLeft?.expandOrNot("LOCATION")}
              >
                See more(
                {providerBlocLeft?.findProgramReducer?.hq_location?.length -
                  providerBlocLeft?.limitLocation}
                )
              </a>
            ) : (
              providerBlocLeft?.findProgramReducer?.hq_location?.length <=
                providerBlocLeft?.limitLocation &&
              providerBlocLeft?.findProgramReducer?.hq_location?.length > 6 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() => providerBlocLeft?.expandOrNot("LOCATION", 2)}
                >
                  See less
                </a>
              )
            )}
          </div>
        </div>
        <div className="block">
          <h3 className="title withChild" onClick={(e) => openBlock(e)}>
            Program solution
          </h3>
          <div className="listCheck">
            {paginate(
              providerBlocLeft?.findProgramReducer?.program_solution,
              providerBlocLeft?.limitSolution
            )?.map((el: { id: number; name: string }, key: number) => (
              <div className="custom-control custom-checkbox orange" key={key}>
                <input
                  type="checkbox"
                  id={"ps" + key}
                  className="custom-control-input"
                  checked={
                    providerBlocLeft?.selectedSolution?.find(
                      (value) => value === el?.id
                    )
                      ? true
                      : false
                  }
                  onClick={() => providerBlocLeft?.handleSolution(el?.id)}
                />
                <label htmlFor={"ps" + key} className="custom-control-label">
                  {el?.name}
                </label>
              </div>
            ))}
            {providerBlocLeft?.findProgramReducer?.program_solution?.length >
            providerBlocLeft?.limitSolution ? (
              <a
                href="javascript:;"
                className="seemore"
                onClick={() => providerBlocLeft?.expandOrNot("SOLUTION")}
              >
                See more(
                {providerBlocLeft?.findProgramReducer?.program_solution
                  ?.length - providerBlocLeft?.limitSolution}
                )
              </a>
            ) : (
              providerBlocLeft?.findProgramReducer?.program_solution?.length <=
                providerBlocLeft?.limitSolution &&
              providerBlocLeft?.findProgramReducer?.program_solution?.length >
                6 && (
                <a
                  href="javascript:;"
                  className="seemore"
                  onClick={() => providerBlocLeft?.expandOrNot("SOLUTION", 2)}
                >
                  See less
                </a>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlocLeftDirectory;
