import React from "react";
import baseUrl from "../../../../../service/constant";
import UserInformationCtr from "../../../../../controller/community-ctr/activity-user/user-information";
import { concatString, fileUrl, refaCtoNullKey } from "../../../../../utils";
import { BtnAction } from "../../../../widget/bouton/btn-action";
import { BtnConfirm } from "./../../../../widget/bouton/btn-confirm";

import LoginModal from "./../../../../widget/login/check-password/login-direct-check-password";

const UserInformation = (props: any) => {
  const {
    companyInfos,
    loader,
    handleUnFollowClick,
    isCurrentUser,
    confirmFollow,
    cancelFollowClick,
    submitFollowClick,
    isConnectedUser,
    isFollowedUser,
    sendMessage,
    isModeratorUser,
    showConfirmPasswordModerator,
    setShowConfirmPasswordModerator,
    banUserProcess,
  } = { ...UserInformationCtr(props?.user, props?.userId) };

  return (
    <div className="blockProfil">
      {isModeratorUser && !companyInfos?.account_already_banned && (
        <LoginModal
          messageText={"In order to continue, you need to enter your password!"}
          show={showConfirmPasswordModerator}
          setShow={setShowConfirmPasswordModerator}
          callBackFunction={banUserProcess}
        />
      )}
      <h2 className="title">{props?.user?.role}</h2>
      <div className="profilContent">
        <div className="imgProfil empty">
          <img src={fileUrl(props?.user?.photo)} />
        </div>
        <div className="name text-center">
          {/* Dev #48290 Moderator suppression message */}
          {props?.user?.user_name + " "}
          {isModeratorUser &&
            !companyInfos?.account_already_banned &&
            !props?.user?.has_closed_account && (
              <BtnAction
                text={"Ban user"}
                showLoader={false}
                classes="followFeed"
                click={() => setShowConfirmPasswordModerator(true)}
              />
            )}
        </div>
        <div className="post text-center">
          {concatString(props?.user?.country_name, props?.user?.city, " ")}
        </div>
        <div className="post text-center">
          {refaCtoNullKey(props?.user?.job_title)}

          <div className="showpopOverPerso">
            {refaCtoNullKey(props?.user?.company_name)}
            <div className="popoverPerso">
              <div className="popovoverContent">
                <div className="infoAbout">
                  <div className="imageAffiliatedcompany">
                    <img src={fileUrl(companyInfos?.logo)} />
                  </div>
                  <div className="infoFeed">
                    <div className="titlePost">
                      {refaCtoNullKey(companyInfos?.company_name)}
                    </div>
                  </div>
                </div>
                <p>{refaCtoNullKey(companyInfos?.description)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="post btnAndConfirm text-center">
          {/* Dev #48290 Moderator suppression message */}
          {!isCurrentUser &&
            isConnectedUser &&
            !props?.user?.has_closed_account && (
              <BtnAction
                text={"Send a Message"}
                showLoader={loader}
                classes="followFeed"
                click={() => sendMessage(props?.user?.id)}
              />
            )}
        </div>
        <div className="post btnAndConfirm text-center">
          {/* Dev #48290 Moderator suppression message */}
          {!isCurrentUser &&
            isConnectedUser &&
            !props?.user?.has_closed_account &&
              isFollowedUser !== undefined && (
              <BtnAction
                text={isFollowedUser ? "Followed" : "Follow"}
                showLoader={loader}
                classes="followFeed"
                click={(data: any) => handleUnFollowClick(data)}
              />
            )}
          {!isCurrentUser && isConnectedUser && confirmFollow && (
            <BtnConfirm
              cancelClick={() => cancelFollowClick()}
              submitClick={() => submitFollowClick(props?.user)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
