import React from "react";
import Header from "../../../widget/index";
import Footer from "../../../widget/footer/footer";
import PostResller from "../../../../resources/images/postReseller.png";
import Joinbg from "../../../../resources/images/join.png";
import UseGetStartedReseller from "../../../../controller/get-started-ctr/reseller/use-get-started-reseller";
export default function GetStartedReseller() {
  const { createAccountReseller,joinTheConversation } = UseGetStartedReseller();
  return (
    <div>
      <Header />
      <section className="joinHome reseller">
        <div className="container mx-auto">
          <h1 className="title">Join Devinsider as a reseller</h1>
          <a onClick={createAccountReseller} className="getStartedLink">
            Get started
          </a>
        </div>
      </section>
      <section className="invOrange">
        <div className="container mx-auto">
        Devinsider is a global community dedicated to helping disruptive B2B software vendors solve their business challenges <br />
        and establish relevant connections with resellers, technology partners, and investors.
        </div>
      </section>
      <section className="inclinereseller postAndBrowse">
        <div className="container mx-auto">
          <img className="post" src={PostResller} alt="" />
          <div className="texte">
            <h1 className="title">
            Post and browse reseller partnership 
            invitations on the marketplace
            {" "}
            </h1>
            <p className="my-4">
            Create exposure for your organization and your services. Initiate <br/>
            partnerships with fast-growing, innovative software vendors to enrich <br/>
            your portfolio.
            </p>
            <a onClick={createAccountReseller} className="getStartedLink mt-4">
              Get started
            </a>
          </div>
        </div>
      </section>
      <section className="inclinereseller gris globalCo">
        <div className="container mx-auto">
          <div className="texte">
            <h1 className="title">
              Tap into a global community of software vendors
            </h1>
            <p className="mb-5">
              Learn about the experiences of software vendors, ask questions and
              share your expertise with Devinsider community members. Stay on
              top of the latest challenges and innovations in the software
              industry
            </p>
            <a 
            onClick={(e) => {
              e.preventDefault();
              joinTheConversation();
            }}
            className="getStartedLink">
              Join the community
            </a>
          </div>
          <img className="global" src={Joinbg} alt="" />
        </div>
      </section>
      <Footer />
    </div>
  );
}
