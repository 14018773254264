import { postData, getData } from "../api";
import { url } from "../constant";

export const getInfoNotificationSettings = async (
  token: string
): Promise<any> => {
  let response: any = await getData(url.settings.get_info_notification, token);
  return response;
};

export const updateInfoNotificationSettings = async (
  data: any,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url.settings.update_info_notification,
    data,
    token
  );
  return response;
};

export const getPaymentIntent = async (
  data: any,
  token: string
): Promise<any> => {
  let response: any = await postData(
    url.settings.billingCenter + "/pay",
    data,
    token
  );
  return response;
};
