import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isEmail, setSessionStorageObject } from "../../../utils/index";
import { checkIfEmailExist } from "../../../service/applicatif/acount";
import useEventListener from "../../../hooks/event-handler";
import { useForm } from "react-hook-form";
import { url } from "../../../service/constant";
import { postJsonDataService } from "../../../service/applicatif/back-end-service";
import { useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";

export type IPropsInputInvestor = {
  firstNameInvestor: string;
  lastNameInvestor: string;
  emailInvestor: string;
  passwordIvestor: string;
  confirmPasswordInvestor: string;
  acceptCguInvestor: boolean;
};

export default function UseCompleteInfoInvestor() {
  const history = useHistory();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<IPropsInputInvestor>({
    defaultValues: {
      firstNameInvestor: sessionStorage?.getItem("firstNameInvestor") || "",
      lastNameInvestor: sessionStorage?.getItem("lastNameInvestor") || "",
      emailInvestor: sessionStorage?.getItem("emailInvestor") || "",
      passwordIvestor: sessionStorage?.getItem("passwordIvestor") || "",
      confirmPasswordInvestor:
        sessionStorage?.getItem("confirmPasswordInvestor") || "",
      acceptCguInvestor: JSON.parse(
        sessionStorage?.getItem("acceptCguInvestor") || "false"
      ),
    },
  });

  /*
   * Loader Email check
   */
  const [loader, setloader] = useState<boolean>(false);

  /*
   *Error email alreasy use
   */
  const [emailSafe, setEmailSafe] = useState<string>("");

  /*
   * Email false to show
   */
  const [emailNotSafe, setEmailNotSafe] = useState<string>("");

  /*
   * Text message error
   */
  const [txtEmailError, setTxtEmailError] = useState("");
  const [txtPasswordError, setTxtPasswordError] = useState("");

  /*
   * Check if an email already use
   * paarams email adress string
   */

  const checkIfAlreadyEmailExist = async (email: string) => {
    if (isEmail(email)) {
      setloader(true);
      try {
        let response = await checkIfEmailExist(email, true);
        if (response?.status === 200 && response?.data?.details) {
          if (
            response?.status === 200 &&
            response?.data?.reason === "invalid domain"
          ) {
            setTxtEmailError("Professional email is required");
          } else {
            setTxtEmailError("This email address is already  used");
          }
          setloader(false);
          setEmailNotSafe("true");
          return;
        } else if (response?.status === 200) {
          setEmailNotSafe("false");
          setloader(false);
        } else {
          setloader(false);
          setEmailNotSafe("true");
          setTxtEmailError("An error occurred");
        }
      } catch (error: any) {
      }
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
    } else return;
  };

  useEventListener("keydown", keyEnter);

  const onSubmit = async (data: IPropsInputInvestor) => {
    setloader(true);
    /**
     * Check if email not safe is empty (business email is not checking)
     * Check if email not is true
     */
    if (emailNotSafe === "" || emailNotSafe === "true") {
      let checkEmailBusiness = await checkIfAlreadyEmailExist(
        data?.emailInvestor
      );
    }
    /**
     * email not safe == false , is business email
     */
    if (emailNotSafe == "false") {
      setSessionStorageObject({
        firstNameInvestor: data?.firstNameInvestor,
        lastNameInvestor: data?.lastNameInvestor,
        emailInvestor: data?.emailInvestor,
        passwordIvestor: data?.passwordIvestor,
        confirmPasswordInvestor: data?.confirmPasswordInvestor,
        acceptCguInvestor: data?.acceptCguInvestor,
      });
      history.push("/complete-page-investor");
    }
    setloader(false);
  };

  return {
    loader,
    onSubmit,
    register,
    handleSubmit,
    errors,
    getValues,
    watch,
    checkIfAlreadyEmailExist,
    txtEmailError,
    txtPasswordError,
    emailNotSafe,
    emailSafe,
  };
}
