/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import * as T from "../../../controller/company-ctr/types/types-company-page";
import { getSStorage } from "../../../utils";
import { IPropsSF } from "./type";
export default function SofTwareSolution(props: IPropsSF) {
  const [activeId, setActiveId] = useState("0");
  function toggleActive(id: any) {
    if (activeId == id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }

  const renameOther = (sel: T.IdataMakeSolution) => {
    if (sel.nameSegment.toLowerCase() == "other") {
      const dataSource = JSON.parse(getSStorage("completed_account") || "[]");
      const category = dataSource?.segments?.find(
        (el: any) => el.id?.toString() === sel.idSolutionOffert?.toString()
      );
      return sel.nameSegment + "-" + category?.name;
    } else {
      return sel.nameSegment;
    }
  };
  return (
    <>
      <div className="solutionChecked">
        {props?.solutionChecked?.map((el: T.IdataMakeSolution) => (
          <div
            className="item"
            onClick={() => props?.removeItemSelected(el.idSegment)}
            key={el?.idSegment}
          >
            {renameOther(el)}

            <span className="icon-close"></span>
          </div>
        ))}
      </div>
      <div className="contentCheckboxsolution">
        {props?.featuredSolution && (
          <h2 className="title">
            {props?.featuredSolution?.name}{" "}
            {props.solutionChecked?.filter(
              (el: any) => el.idSolutionOffert == 9999
            ).length > 0
              ? `(${
                  props.solutionChecked?.filter(
                    (el: any) => el.idSolutionOffert == 9999
                  ).length
                }  selected )`
              : ""}
          </h2>
        )}
        <div className="flex-checkbox">
          {props?.featuredSolution?.segmentDetails?.map(
            (el: T.ItemsSolution) => (
              <Form.Check
                key={el.id}
                custom
                label={el.name}
                id={el.id.toString()}
                checked={
                  props?.solutionChecked?.find((t: any) => t.idSegment == el.id)
                    ? true
                    : false
                }
                onClick={() => {
                  props?.selectSolution({
                    idSolutionOffert: props?.featuredSolution?.id,
                    idSegment: el.id,
                    nameSegment: el.name,
                  });
                }}
                onChange={() => null}
              />
            )
          )}
        </div>
        <div className="listAccordionSolution">
          <Accordion defaultActiveKey={activeId}>
            <div className="row mx-0">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 pr-xl-2 pr-lg-2 p-0">
                {props?.solutionLeftColumnData?.map(
                  (solution: T.ISolutionListe) => {
                    return (
                      <div
                        className={
                          activeId == solution.id.toString()
                            ? "panel-wrap active-panel"
                            : "panel-wrap"
                        }
                        key={solution.id}
                      >
                        <div className="panel-header">
                          <Accordion.Toggle
                            onClick={() => toggleActive(solution.id)}
                            className={`panel-toggle ${
                              props?.solutionChecked?.filter(
                                (el: any) => el.idSolutionOffert == solution.id
                              ).length > 0
                                ? "colored round"
                                : ""
                            }`}
                            eventKey={solution.id.toString()}
                          >
                            {solution?.name}{" "}
                            {props?.solutionChecked?.filter(
                              (el: any) => el.idSolutionOffert == solution.id
                            ).length > 0
                              ? `(${
                                  props?.solutionChecked?.filter(
                                    (el: any) =>
                                      el.idSolutionOffert == solution.id
                                  ).length
                                }  selected )`
                              : ""}
                          </Accordion.Toggle>
                        </div>
                        <Accordion.Collapse eventKey={solution.id.toString()}>
                          <div className="panel-body">
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                                props?.selectSolution({
                                  idSolutionOffert: solution.id,
                                  solutionOffert: solution.segmentDetails,
                                });
                              }}
                            >
                              <Form.Check
                                custom
                                label="Select all"
                                id="all"
                                checked={
                                  props?.allSolutionSellected?.find(
                                    (el: any) => el == solution.id
                                  )
                                    ? true
                                    : false
                                }
                                disabled={true}
                                onClick={() => null}
                                onChange={() => null}
                              />
                            </div>
                            {solution?.segmentDetails?.map(
                              (solutionItems: T.ItemsSolution) => {
                                return (
                                  <>
                                    <Form.Check
                                      key={solutionItems.id}
                                      custom
                                      label={solutionItems.name}
                                      id={solutionItems.id.toString()}
                                      checked={
                                        props?.solutionChecked?.find(
                                          (t: any) =>
                                            t.idSegment == solutionItems.id
                                        )
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        props?.selectSolution({
                                          idSolutionOffert: solution.id,
                                          idSegment: solutionItems.id,
                                          nameSegment: solutionItems.name,
                                        });
                                      }}
                                      onChange={() => null}
                                    />
                                    {solutionItems.name?.toLocaleLowerCase() ===
                                      "other" &&
                                      props.solutionChecked?.find(
                                        (t: any) =>
                                          t.idSegment == solutionItems.id
                                      ) && (
                                        <input
                                          type="text"
                                          name=""
                                          id=""
                                          placeholder="other"
                                          value={
                                            props.allOtherValue?.find(
                                              (tempTxt) =>
                                                tempTxt.idSegment.toString() ===
                                                solutionItems.id.toString()
                                            )?.otherTxt || ""
                                          }
                                          onChange={(evt: any) =>
                                            props.setOtherValueTxt(
                                              {
                                                idSolutionOffert: solution.id,
                                                idSegment: solutionItems.id,
                                                nameSegment: solutionItems.name,
                                              },

                                              evt.target.value
                                            )
                                          }
                                        />
                                      )}
                                  </>
                                );
                              }
                            )}
                          </div>
                        </Accordion.Collapse>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 pl-xl-2 pl-lg-2 p-0">
                {props?.solutionRigthColumnData?.map(
                  (solution: T.ISolutionListe) => {
                    return (
                      <div
                        className={
                          activeId == solution.id.toString()
                            ? "panel-wrap active-panel"
                            : "panel-wrap"
                        }
                        key={solution.id}
                      >
                        <div className="panel-header">
                          <Accordion.Toggle
                            onClick={() => toggleActive(solution.id.toString())}
                            className={`panel-toggle ${
                              props?.solutionChecked?.filter(
                                (el: any) => el.idSolutionOffert == solution.id
                              ).length > 0
                                ? "colored round"
                                : ""
                            }`}
                            eventKey={solution.id.toString()}
                          >
                            {solution?.name}{" "}
                            {props?.solutionChecked?.filter(
                              (el: any) => el.idSolutionOffert == solution.id
                            ).length > 0
                              ? `(${
                                  props?.solutionChecked?.filter(
                                    (el: any) =>
                                      el.idSolutionOffert == solution.id
                                  ).length
                                }  selected )`
                              : ""}
                          </Accordion.Toggle>
                        </div>
                        <Accordion.Collapse eventKey={solution?.id?.toString()}>
                          <div className="panel-body">
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                                props?.selectSolution({
                                  idSolutionOffert: solution.id,
                                  solutionOffert: solution.segmentDetails,
                                });
                              }}
                            >
                              <Form.Check
                                custom
                                label="Select all"
                                id="all"
                                checked={
                                  props?.allSolutionSellected?.find(
                                    (el: any) => el == solution.id
                                  )
                                    ? true
                                    : false
                                }
                                disabled={true}
                                onClick={() => null}
                                onChange={() => null}
                              />
                            </div>

                            {solution?.segmentDetails?.map(
                              (solutionItems: T.ItemsSolution) => {
                                return (
                                  <>
                                    <Form.Check
                                      key={solutionItems.id}
                                      custom
                                      label={solutionItems.name}
                                      id={solutionItems.id.toString()}
                                      checked={
                                        props?.solutionChecked?.find(
                                          (t: any) =>
                                            t.idSegment == solutionItems.id
                                        )
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        props?.selectSolution({
                                          idSolutionOffert: solution.id,
                                          idSegment: solutionItems.id,
                                          nameSegment: solutionItems.name,
                                        });
                                      }}
                                      onChange={() => null}
                                    />
                                    {solutionItems.name?.toLocaleLowerCase() ===
                                      "other" &&
                                      props.solutionChecked?.find(
                                        (t: any) =>
                                          t.idSegment == solutionItems.id
                                      ) && (
                                        <input
                                          type="text"
                                          name=""
                                          id=""
                                          placeholder="other"
                                          value={
                                            props.allOtherValue?.find(
                                              (tempTxt) =>
                                                tempTxt.idSegment.toString() ===
                                                solutionItems.id.toString()
                                            )?.otherTxt || ""
                                          }
                                          onChange={(evt: any) =>
                                            props.setOtherValueTxt(
                                              {
                                                idSolutionOffert: solution.id,
                                                idSegment: solutionItems.id,
                                                nameSegment: solutionItems.name,
                                              },

                                              evt.target.value
                                            )
                                          }
                                        />
                                      )}
                                  </>
                                );
                              }
                            )}
                          </div>
                        </Accordion.Collapse>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
}
