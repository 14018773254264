import React from "react";
import Select from "react-select";

type IProps = {
  handleChangeShow: (e: any) => void;
  limit: string;
};
export default function ShowTri(props: IProps) {
  return (
    <Select
      onChange={(e: any) => props.handleChangeShow(e)}
      className="triNumber"
      isClearable={false}
      options={[
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
      ]}
      classNamePrefix="tri"
      defaultValue={{
        value: props.limit,
        label: props.limit,
      }}
      value={{
        value: props.limit,
        label: props.limit,
      }}
    />
  );
}
