import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  isRequired,
  isURL,
  setSessionStorageObject,
} from '../../../utils/index';
import useEventListener from '../../../hooks/event-handler';

export default function UseCompleteCompanyExpert() {
  const history = useHistory();
  const location = useLocation();

  console.log('Location', history, location);

  const [companyNameExpert, setCompanyNameExpert] = useState<string>(
    sessionStorage.getItem('companyNameExpert')
      ? sessionStorage.getItem('companyNameExpert') || ''
      : ''
  );
  const [jobTitleExpert, setJobTitleExpert] = useState<string>(
    sessionStorage.getItem('jobTitleExpert')
      ? sessionStorage.getItem('jobTitleExpert') || ''
      : ''
  );
  const [webSiteExpert, setWebSiteExpert] = useState<string>(
    sessionStorage.getItem('webSiteExpert')
      ? sessionStorage.getItem('webSiteExpert') || ''
      : ''
  );

  // Error value required
  const [errorCompanyNameExpert, setErrorCompanyNameExpert] =
    useState<boolean>(false);
  const [errorJobTitleExpert, setErrorJobTitleExpert] =
    useState<boolean>(false);
  const [errorWebSiteExpert, setErrorWebSiteExpert] = useState<boolean>(false);
  const [txtWebsiteError, setTxtWebsiteError] = useState('');

  useEffect(() => {
    setErrorCompanyNameExpert(false);
    return () => {};
  }, [companyNameExpert]);

  useEffect(() => {
    setErrorJobTitleExpert(false);
    return () => {};
  }, [jobTitleExpert]);

  useEffect(() => {
    setErrorWebSiteExpert(false);
    return () => {};
  }, [webSiteExpert]);

  const validCompanyNameExpert = (txt: string): boolean => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErrorCompanyNameExpert(true);
      return false;
    }
  };

  const validJobTitleExpert = (txt: string): boolean => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErrorJobTitleExpert(true);
      return false;
    }
  };

  const validWebSiteExpert = (txt: string): boolean => {
    if (isRequired(txt)) {
      if (isURL(txt)) {
        return true;
      }
      setErrorWebSiteExpert(true);
      return false;
    }
    return true;
  };

  const navigateCompleteAboutExpert = () => {
    if (
      validCompanyNameExpert(companyNameExpert) &&
      // validJobTitleExpert(jobTitleExpert) &&
      validWebSiteExpert(webSiteExpert)
    ) {
      setSessionStorageObject({
        companyNameExpert,
        jobTitleExpert,
        webSiteExpert,
      });
      history.push('/complete-about-expert');
    } else {
      if (!validWebSiteExpert(webSiteExpert)) {
        setTxtWebsiteError('your website url is not a valid');
      }
      if (!isRequired(webSiteExpert)) {
        setTxtWebsiteError('This field is required');
      }
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key == 'Enter') {
      navigateCompleteAboutExpert();
    } else return;
  };

  useEventListener('keydown', keyEnter);

  return {
    companyNameExpert,
    setCompanyNameExpert,
    jobTitleExpert,
    setJobTitleExpert,
    webSiteExpert,
    setWebSiteExpert,
    errorCompanyNameExpert,
    errorJobTitleExpert,
    errorWebSiteExpert,
    txtWebsiteError,
    navigateCompleteAboutExpert,
  };
}
