import { Dispatch } from "../../types/settings-interface";
import {
  getInfoNotificationSettings,
  updateInfoNotificationSettings,
} from "../../../service/applicatif/settings";
import store from "../../store";

export const GET_INFO_NOTIFICATION = "GET_INFO_NOTIFICATION";
export const UPDATE_INFO_NOTIFICATION = "UPDATE_INFO_NOTIFICATION";

const getInfoNotification = (callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getInfoNotificationSettings(
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: GET_INFO_NOTIFICATION,
          payload: result && result.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error.response);
    }
  };
};

const updateNotification = (callback: any, data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await updateInfoNotificationSettings(
        data,
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: UPDATE_INFO_NOTIFICATION,
          payload: result && result.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error.response);
    }
  };
};

export { getInfoNotification, updateNotification };
