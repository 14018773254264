import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateInfoUserAction } from "../../redux/actions/acounts-action";
import {
  getCompanyResellerAction,
  getCompanyResellerAttributeAction,
  updateCompanyResellerAction,
} from "../../redux/actions/company-page-reseller";
import { rootState } from "../../redux/reducers";
import { UserObject } from "../../redux/types/acount-interface";
import { contact } from "../../utils/country_phone";
import * as T from "./types/index";
import * as CompanyResellerController from "./modif-reseller";

export default function useEditResellerProfileEdit() {
  const history = useHistory();
  //redux , object user and object reseller page
  const userState = useSelector((state: rootState) => state.userReducer);
  const companyResellerState = useSelector(
    (state: rootState) => state.resellerCompanyReducer
  );
  //loader
  const [overviewShow, setOverviewShow] = useState(false);
  const [difShow, setDifShow] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [companyDetailsShow, setCompanyDetailsShow] = useState<boolean>(false);
  const [solutionShow, setSolutionShow] = useState<boolean>(false);
  const [industryShow, setIndustryShow] = useState<boolean>(false);
  const [geographyShow, setGeographyShow] = useState<boolean>(false);
  const [logoShow, setLogoShow] = useState<boolean>(false);
  //data local use for show static value
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );

  //all info user to edit
  const [infoUserToEdit, setInfoUserToEdit] = useState<UserObject>({
    ...userState,
  });
  const [infoCompanyToEdit, setInfoCompanyToEdit] = useState({
    ...companyResellerState,
  });

  const fileRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [profilUser, setProfilUser] = useState<boolean>(false);

  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileLogo, setSelectedFileLogo] = useState<File>();
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const [codeFlags, setcodeFlags] = useState(
    infoUserToEdit?.phone_number_code || "US"
  );
  const [stateLinkDean, setstateLinkDean] = useState<boolean>(false);
  const [statePhone, setstatePhone] = useState<boolean>(true);
  const [isErrorLocked, setIsErrorLocked] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<T.formDataEdit>({
    mode: "onChange",
    defaultValues: {
      firstName: userState?.first_name,
      lastName: userState?.last_name,
      companyName: userState?.company_name,
      location: userState?.country?.country_name,
      city: userState?.city,
    },
  });

  const {
    register: resellerRegister,
    handleSubmit: resellerHandleSubmit,
    setValue: setResellerValue,
    getValues: getResellerValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      legalName: companyResellerState?.company_legal_name,
      tradeName: companyResellerState?.company_trade_name,
      website: companyResellerState?.website,
      city: companyResellerState?.city,
      location: companyResellerState?.country?.country_name,
    },
  });

  //for pick  image
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      setSelectedFile(event?.target?.files[0]);
      //  setIsFilePicked(true);
    }
  };
  const changeLogoHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      setSelectedFileLogo(event?.target?.files[0]);
      //  setIsFilePicked(true);
    }
  };

  //event click bouton and lunch pick image in type image , fileRef is ref of img
  const handleBtn = () => {
    if (fileRef) {
      fileRef?.current?.click();
      //  setIsFilePicked(false);
    }
  };

  const unshow = () => {
    if (overviewShow) {
      setOverviewShow(false);
    }
    if (contactShow) {
      setContactShow(false);
    }
    if (companyDetailsShow) {
      setCompanyDetailsShow(false);
    }
    if (difShow) {
      setDifShow(false);
    }
    if (solutionShow) {
      setSolutionShow(false);
    }
    if (industryShow) {
      setIndustryShow(false);
    }
    if (geographyShow) {
      setGeographyShow(false);
    }
    if (logoShow) {
      setLogoShow(false);
    }
  };

  const cbResponse = (result: any) => {};

  //Callbck after update infoUser
  const callBack = (resposeStatus: boolean) => {
    if (resposeStatus) {
      unshow();
      setIsLoaderShow(false);
    } else {
      setIsLoaderShow(false);
    }
  };

  const callBackSegment = (resposeStatus: boolean) => {
    if (resposeStatus) {
      dispatch(getCompanyResellerAttributeAction("segmentDetails", cbResponse));
      callBack(resposeStatus);
    } else {
      setIsLoaderShow(false);
    }
  };
  const callBackCountry = (resposeStatus: boolean) => {
    if (resposeStatus) {
      dispatch(
        getCompanyResellerAttributeAction("targetedCountry", cbResponse)
      );
      callBack(resposeStatus);
    } else {
      setIsLoaderShow(false);
    }
  };
  const callBackIndustry = (resposeStatus: boolean) => {
    if (resposeStatus) {
      dispatch(
        getCompanyResellerAttributeAction("targetedIndustry", cbResponse)
      );
      callBack(resposeStatus);
    } else {
      setIsLoaderShow(false);
    }
  };

  //Callbck after update Audience
  const cb = (resposeStatus: boolean) => {
    if (resposeStatus) {
      setContactShow(true);
      setIsLoaderShow(false);
    } else {
      setIsLoaderShow(false);
    }
  };

  //Callbck after update Photo
  const cbPhoto = (resposeStatus: boolean) => {
    if (resposeStatus) {
      setProfilUser(false);
      setLogoShow(false);
      setIsLoaderShow(false);
    } else {
      setIsLoaderShow(false);
    }
  };

  const cbLogo = (responseStatus: boolean) => {
    if (responseStatus) {
      dispatch(getCompanyResellerAction(infoCompanyToEdit?.id, cbResponse));
      setLogoShow(false);
      setIsLoaderShow(false);
    } else {
      setIsLoaderShow(false);
    }
  };

  // update infoUser
  const onSub: SubmitHandler<T.formDataEdit> = (data) => {
    updateUserDetail();
  };

  const submitCompanyGuest: SubmitHandler<T.formDataEdit> = (data) => {
    updateUserDetail();
  };

  //update info User with redux
  const updateUserDetail = (type: string = "") => {
    setIsLoaderShow(true);
    let data =
      type !== "photo"
        ? {
            firstName: getValues("firstName")?.trim(),
            lastName: getValues("lastName")?.trim(),
            companyName: getValues("companyName")?.trim(),
            website: getValues("website")?.trim(),
            jobTitle: infoUserToEdit?.job_title?.trim(),
            plainText: infoUserToEdit?.plain_text?.trim(),
            city: getValues("city")?.trim(),
            country: dataCompleted?.country?.find(
              (el: any) => el.country_name == getValues("location")?.trim()
            ).id,
            linkedin: infoUserToEdit?.linkedin?.trim(),
            phone: infoUserToEdit?.phone?.trim(),
          }
        : { fileName: selectedFile ? selectedFile : null };

    dispatch(
      updateInfoUserAction(
        data,
        type == "audience" ? cb : type == "photo" ? cbPhoto : callBack
      )
    );
  };

  //update info reseller company page
  const updateCompanyResellerDetails = (type: string = "") => {
    setIsLoaderShow(true);

    //Remove other data to setString value
    const idSegmentDetail = UseSolutionOffert.solutionChecked?.map(
      (el) => el.idSegment
    );
    //get txt in other value
    const valueOfOther = UseSolutionOffert.allOtherValue?.map((el) => ({
      id: el.idSolutionOffert,
      other: el.otherTxt?.trim(),
    }));
    let data: any = {
      overview: infoCompanyToEdit?.overview?.trim(),
      keyCompetitive: infoCompanyToEdit?.key_competitive?.trim(),
      companyTradeName: getResellerValue("tradeName").trim(),
      companyLegalName: getResellerValue("legalName").trim(),
      website: getResellerValue("website")?.trim(),
      country: dataCompleted?.country?.find(
        (el: any) => el.country_name == getResellerValue("location")?.trim()
      ).id,
      city: getResellerValue("city")?.trim(),
      segmentDetails: JSON.stringify(idSegmentDetail),
      otherSegmentDetails: JSON.stringify(valueOfOther),
      targetedIndustry:
        "[" +
        UseTargetedIndustry?.companyTargetSelected?.map(
          (industry: any) => industry?.id
        ) +
        "]",
      targetedCountry:
        "[" +
        UseGeographyTarget?.paysChecked?.map((pays: any) => pays?.countrId) +
        "]",
    };
    data =
      type === "photo"
        ? { ...data, photoProfil: selectedFileLogo ? selectedFileLogo : null }
        : data;
    dispatch(
      updateCompanyResellerAction(
        infoCompanyToEdit?.id,
        data,
        type === "photo"
          ? cbLogo
          : type === "solution"
          ? callBackSegment
          : type === "industry"
          ? callBackIndustry
          : type === "country"
          ? callBackCountry
          : callBack
      )
    );
  };

  //Valid phone number if exist
  const validPhoneNumber = (): boolean => {
    if (infoUserToEdit.phone?.trim()) {
      if (
        isValidPhoneNumber(
          contact?.find((el: any) => el.code == codeFlags)?.dial_code +
            infoUserToEdit.phone?.trim()
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  //Lunch function to valid update contact detail
  const validAfterUpdate = () => {
    if (!validPhoneNumber()) {
      setstatePhone(false);
    }
    if (validPhoneNumber()) {
      updateUserDetail();
    }
  };

  //hide error text on change linkDean or phone number
  useEffect(() => {
    if (stateLinkDean || statePhone == false) {
      setstateLinkDean(false);
      setstatePhone(true);
    }
    return () => {};
  }, [infoUserToEdit]);

  const cancelResellerChange = () => {
    unshow();
    setResellerValue("city", companyResellerState.city);
    setResellerValue("location", companyResellerState.country.country_name);
    setResellerValue("tradeName", companyResellerState.company_trade_name);
    setResellerValue("legalName", companyResellerState.company_legal_name);
    setResellerValue("website", companyResellerState.website);
    setInfoCompanyToEdit({ ...companyResellerState });
  };

  //restore  info user detain on Cancel
  const cancelChange = () => {
    unshow();
    setValue("firstName", userState.first_name);
    setValue("lastName", userState.last_name);
    setValue("location", userState.country.country_name);
    setValue("companyName", userState.company_name);
    setValue("jobTitle", userState.job_title);
    setValue("city", userState.city);
    setValue("website", userState.website);
    setInfoUserToEdit({
      ...userState,
    });
    cancelResellerChange();
  };

  const hideSelectedFile = () => {
    setSelectedFile(undefined);
    setLogoShow(false);
    setProfilUser(false);
  };

  const hideLogoSelected = () => {
    setSelectedFileLogo(undefined);
    setLogoShow(false);
    setProfilUser(false);
  };

  const goToProfil = () => {
    history.push("/profil");
  };

  const restorePhotoChange = () => {
    setSelectedFile(undefined);
  };

  // Software solution
  const [solution] = useState({
    defaultValue: companyResellerState?.segmentDetails?.map((solution: any) => {
      return {
        idSolutionOffert: solution?.segment,
        idSegment: solution?.id,
        nameSegment: solution?.name,
      };
    }),
  });
  const UseSolutionOffert =
    CompanyResellerController.UseSolutionOffert(solution);

  // Targeted industry
  const UseTargetedIndustry = CompanyResellerController.UseTargetedIndustry({
    defaultSelected: companyResellerState?.targetedIndustry,
  });

  // Country target market
  const [pays] = useState(
    companyResellerState?.targetedCountry.map((pays) => {
      return {
        idContinent: pays?.idContinent,
        countrId: pays?.countryId,
        countryName: pays?.countryName,
      };
    })
  );
  const UseGeographyTarget = CompanyResellerController.UseGeographyTarget(pays);

  return {
    userState,
    companyResellerState,
    codeFlags,
    overviewShow,
    contactShow,
    dataCompleted,
    infoUserToEdit,
    profilUser,
    selectedFile,
    fileRef,
    isLoaderShow,
    errors,
    stateLinkDean,
    statePhone,
    isErrorLocked,
    setInfoUserToEdit,
    updateUserDetail,
    cancelChange,
    setcodeFlags,
    setOverviewShow,
    setContactShow,
    setProfilUser,
    changeHandler,
    handleBtn,
    hideSelectedFile,
    goToProfil,
    register,
    handleSubmit,
    onSub,
    validAfterUpdate,
    restorePhotoChange,
    setstateLinkDean,
    companyDetailsShow,
    setCompanyDetailsShow,
    submitCompanyGuest,
    difShow,
    setDifShow,
    infoCompanyToEdit,
    setInfoCompanyToEdit,
    updateCompanyResellerDetails,
    resellerRegister,
    resellerHandleSubmit,
    setResellerValue,
    getResellerValue,
    solutionShow,
    setSolutionShow,
    UseSolutionOffert,
    industryShow,
    setIndustryShow,
    UseTargetedIndustry,
    geographyShow,
    setGeographyShow,
    UseGeographyTarget,
    logoShow,
    setLogoShow,
    selectedFileLogo,
    setSelectedFileLogo,
    changeLogoHandler,
    hideLogoSelected,
  };
}
