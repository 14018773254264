import React, { useState, useEffect } from 'react';
import useIL from '../../../screen/widget/industries-list/use-IL';
import { rootState } from '../../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInvestorAttributes,
  updateCPInvestorAction,
} from '../../../redux/actions/company-page-investor-action/my-investor-page-action';
import { useParams } from 'react-router-dom';

export default function UseEditInfoSpecializationCtr() {
  const [showSpecialization, setShowspecialization] = useState<boolean>(false);

  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;

  const CP_Investor = useSelector((state: rootState) =>
    !isOtherUser
      ? state.companyInvestorPageReducer
      : state?.otherCompanyInvestorPageReducer
  );
  const [index2, setIndex2] = useState<number>(6);
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [valueIndustrieSelected, setValueIndustrieSelected] = useState<
    Array<{
      id: number;
      name: string;
    }>
  >(CP_Investor?.targeted_industry || []);

  const {
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    backToDefault,
  } = useIL({ defaultSelected: valueIndustrieSelected });

  useEffect(() => {
    setValueIndustrieSelected(CP_Investor?.targeted_industry);
    return () => {};
  }, [CP_Investor?.targeted_industry, params?.id]);

  const cbGetCP_Investor = () => {};

  const cbUpdateDraft = (data: any) => {
    setLoader(false);
    setShowspecialization(false);
    dispatch(
      getInvestorAttributes(
        'targeted_industry',
        'targetedIndustry',
        cbGetCP_Investor
      )
    );
  };

  const saveSelectedIndustrie = () => {
    setLoader(true);
    let dataToSend = {
      targetedIndustry: JSON.stringify(
        companyTargetSelected?.map((el: any) => el?.id)
      ),
    };
    dispatch(updateCPInvestorAction(CP_Investor.id, dataToSend, cbUpdateDraft));
  };

  const cancelModal = () => {
    backToDefault();
    setShowspecialization(false);
  };
  return {
    showSpecialization,
    setShowspecialization,
    addTargetCompany,
    companyTarget,
    companyTargetSecound,
    lastCompanyTarget,
    companyTargetSelected,
    saveSelectedIndustrie,
    loader,
    index2,
    setIndex2,
    cancelModal,
  };
}
