import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as T from "../../../screen/container/investor/profil-investor/types";
import { getSStorage, isInObject } from "../../../utils";
import { contact } from "../../../utils/country_phone";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { updateDraftDataAction } from "../../../redux/actions/company-page-investor-action/my-investor-page-action";
import { useParams } from "react-router-dom";

export default function UseEditIndividualInfoDetailRigth() {
  const [selected, setSelected] = useState<string>("US");
  const [errorFundraising, setErrorFundraising] = useState<boolean>(false);
  const params : {id : any} = useParams();
  const isOtherUser = params?.id? true : false;

  const CP_Investor = useSelector(
    (state: rootState) => !isOtherUser? state.companyInvestorPageReducer : state?.otherCompanyInvestorPageReducer
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const [showIndividualRigth, setShowIndividualRigth] =
    useState<boolean>(false);
  let CP_DRAFT_DATA = JSON.parse(CP_Investor.investor_page_draft || "{}");
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm<T.FormIndividualRigth>({
    mode: "onSubmit",
  });

  /**
   * Initialise foundraising goal
   */
  useEffect(() => {
    setValue(
      "city",
      isInObject(CP_DRAFT_DATA, "location")
        ? CP_DRAFT_DATA?.location
        : CP_Investor?.location
    );
    setValue(
      "email",
      CP_DRAFT_DATA?.email ? CP_DRAFT_DATA?.email : CP_Investor?.email
    );
    setValue(
      "phoneNumber",
      CP_DRAFT_DATA?.phone_number
        ? Number(CP_DRAFT_DATA?.phone_number)
        : Number(CP_Investor?.phone_number)
    );

    setValue(
      "location",
      isInObject(CP_DRAFT_DATA, "country")
        ? CP_DRAFT_DATA?.country?.id
        : CP_Investor?.country?.id
    );

    setValue(
      "website",
      isInObject(CP_DRAFT_DATA, "website")
        ? CP_DRAFT_DATA?.website
        : CP_Investor?.website
    );
    setSelected(
      CP_DRAFT_DATA?.phone_number_code
        ? CP_DRAFT_DATA?.phone_number_code
        : CP_Investor?.phone_number_code
    );
    return () => {};
  }, [CP_Investor, params?.id]);

  const cbUpdateDraft = (data: any) => {
    setLoader(false);
    setShowIndividualRigth(false);
  };

  const submitForm = async (data: T.FormIndividualRigth) => {
    setLoader(true);

    let lastValue = JSON.parse(CP_Investor?.investor_page_draft || "{}");
    let newValue = {
      website: data?.website,
      country: {
        id: data?.location,
        country_name: dataCompleted?.country?.find(
          (el: any) => el?.id == data?.location
        )?.country_name,
      },
      location: data?.city,
      email: data?.email,
      phone_number: data?.phoneNumber,
      phone_number_code: selected,
    };

    dispatch(
      updateDraftDataAction(
        {
          investorPageDraft: JSON.stringify(Object.assign(lastValue, newValue)),
        },
        CP_Investor.id,
        cbUpdateDraft
      )
    );
  };

  return {
    submitForm,
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    errorFundraising,
    errors,
    selected,
    setSelected,
    showIndividualRigth,
    setShowIndividualRigth,
    loader,
  };
}
