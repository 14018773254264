import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
type IProps = {
  lowDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
};
export default function TimeRange(props: IProps) {
  const maxDateLow = `${new Date().getFullYear()}-${
    new Date().getMonth() + 1
  }-01`;

  return (
    <div className="timeRange">
      <div className="titre">Time range:</div>
      <div className="date d-flex">
        <DatePicker
          selected={props.lowDate}
          onChange={(date: Date) => props.setStartDate(date)}
          selectsStart
          startDate={props.lowDate}
          dateFormat="MMM d, yyyy"
          maxDate={new Date(maxDateLow)}
        />
        <DatePicker
          selected={props.endDate}
          onChange={(date: Date) => props.setEndDate(date)}
          selectsEnd
          startDate={props.endDate}
          dateFormat="MMM d, yyyy"
          minDate={props.lowDate}
        />
      </div>
    </div>
  );
}
