import React from "react";

export default function Index(props: { isvFindMe: boolean }) {
  return props?.isvFindMe ? (
    <>
      {/* Dev #47945 V2.1 [Investor] modification du text de l'encadré bleu de la "investor page" */}
      <b>{props?.isvFindMe} Welcome to Devinsider!</b> The last step in sign-up
      process is to complete your investor page. On this page you describe your
      specialties and what you have to offer to ISVs. Once you complete and
      publish your investor page it is visible to ISVs and the matchmaking
      starts!
      <br />
      If you choose to remain invisible ISVs will not be able to view your page
      unless you proactively contact them.
    </>
  ) : (
    <>
      <b>Welcome to Devinsider!</b> You have chosen to keep your investor page
      anonymous. To receive pitches from relevant
      <br />
      ISVs, change your visibility in the page settings.
    </>
  );
}
