/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { isURL, isRequired } from "../../../../../../utils/index";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
//Type formulaire for edit user
import Modal from "react-bootstrap/Modal";
import { FormHeaderAbout } from "../../header/Headerform";
const validCompanyWebSite = (txt: string): boolean => {
  if (isRequired(txt)) {
    if (isURL(txt)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
export const CreateCompanyForm1 = (props: T.InputPropsForms1) => (
  <Modal show={props?.showStep1} className="createCompanypopup smallPopup">
    <form onSubmit={props?.handleSubmit(props?.submit)}>
      <Modal.Header>
        <FormHeaderAbout />
        <a
          href="javascript:;"
          className="close"
          onClick={props?.handleCloseStep1}
        >
          <span className="icon-close-pop"></span>
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="bodyCreateCompany">
          <div className="contentBorder row">
            {/*Miaro 59701 <div className="col-12 d-flex align-items-center infoForCompany pb-3">
              <span className="icon-locked"></span>
              <p>
                Only investors and technology partners can view this content.
                Your page is not, and will never be published publicly. Other
                software vendors can't access your company information
              </p>
            </div>*/}
            <div className="form-group required col-12">
              <div className="aideContent">
                <label htmlFor="">
                  Company "Trade" Name{" "}
                  {props.errors.companyTradeName?.type == "required" && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>

                <OverlayTrigger
                  overlay={
                    <Tooltip id="trade">
                      Please enter your company’s trade name, or the name
                      commonly used for public reference (commonly exludes
                      “Inc.”, “LLC”, etc…)
                    </Tooltip>
                  }
                >
                  <span className="icon-aide"></span>
                </OverlayTrigger>
              </div>

              <input
                type="text"
                className="form-control"
                {...props.register("companyTradeName", {
                  required: props.required,
                })}
                autoComplete="false"
              />
            </div>
            <div className="form-group required col-12">
              <div className="aideContent">
                <label htmlFor="">
                  Company "Legal" Name
                  {props.errors.companyLegalName?.type == "required" && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>

                <OverlayTrigger
                  overlay={
                    <Tooltip id="trade">
                      Please enter your company’s legal name (commonly ending in
                      “Inc”, “LLC”, etc…) This is the name that is registered
                      with your state of incorporation/registration.
                    </Tooltip>
                  }
                >
                  <span className="icon-aide"></span>
                </OverlayTrigger>
              </div>
              <input
                type="text"
                className="form-control"
                {...props.register("companyLegalName", {
                  required: props.required,
                })}
              />
            </div>
            <div className="form-group col-12">
              <label htmlFor="">
                Website
                {props.errors.webSite?.type == "validate" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      your website url is not a valid
                    </span>
                  </div>
                )}
              </label>

              <input
                type="text"
                className="form-control"
                placeholder="Begin with http:// or www"
                {...props.register("webSite", {
                  validate: (txt: string) => validCompanyWebSite(txt),
                })}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder">
          <button
            className="btn btn-devinsider large containerBtnBtn"
            type="submit"
          >
            Next
          </button>
        </div>
      </Modal.Footer>
    </form>
  </Modal>
);
