import React, { useState, useEffect } from 'react';
import logoLettre from '../../../resources/images/logo-lettre.svg';
import { useHistory } from 'react-router';
import useWelcomeCtr from '../../../controller/use-welcom-ctr';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import { logout } from '../../../redux/actions/acounts-action';
import { fileUrl } from '../../../utils';
import { useDataContext } from '../../../context/index';
import BlockNotif from '../../widget/block-notif/index';
import { BlockMessage } from '../bloc-message-notif';

export default function Header(props: any) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [show, setShow] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const { stopEvtSource } = useDataContext();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const userState = useSelector((state: rootState) => state.userReducer);
  const { getStarted, goLogin } = useWelcomeCtr();
  const history = useHistory();
  const dispatch = useDispatch();

  const goToClassifiedAds = () => {
    history.push('/classified-ads/home');
  };

  const showProfil = () => {
    history.push('/profil');
  };

  const goToSettings = () => {
    history.push('/settings');
  };

  const cb = (params: boolean) => {
    if (params) {
      history.push('/signin');
    }
  };
  const logOut = () => {
    stopEvtSource();
    dispatch(logout(cb));
  };
  const goDashBoard = () => {
    history.push('/dashboard');
  };
  const showDiscussion = () => {
    history.push('/contact/discussion');
  };

  return (
    <div className="principalMenu">
      <div className="container mx-auto">
        <div className="leftItemHeader">
          <div className="logoHeader">
            <img
              src={logoLettre}
              alt=""
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="menuDynamic tl">
            <div className="item">
              <a href="javascript:;" className="linkHeader parent">
                Marketplace
                {/*<span className="puissance">Beta</span>*/}
              </a>
              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span onClick={() => goToClassifiedAds()}>Marketplace</span>
                    Identify partnership opportunities with resellers, investors
                    and other professionals working in the software industry.
                  </div>
                </div>
                <div className="container mx-auto">
                  <a
                    href="javascript:;"
                    onClick={() => goToClassifiedAds()}
                    className="linkSubMenu linkHeader"
                  >
                    <span className="name">Marketplace</span>
                    <p>
                      Post an advertisement to find strategic business partners
                      to grow your software business.
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rightItemHeader">
          {userState && userState.enable ? (
            <div className="menuDynamic tr">
              <div
                className="item"
                onClick={(e: any) => {
                  showDiscussion();
                }}
              >
                <BlockMessage unread={props.unread} />
              </div>
              <BlockNotif />
              <div className="item">
                <a href="javascript:;" className="parent linkHeader">
                  <div className={`profilImg`}>
                    <img
                      alt="Profile"
                      src={`${fileUrl(userState.file_name)}`}
                    />
                  </div>
                  <span className="mobile">My account</span>
                </a>
                <div className="submenu">
                  <a href="javascript:;" onClick={() => showProfil()}>
                    Profile
                  </a>
                  <a href="javascript:;" onClick={() => goToSettings()}>
                    Account settings
                  </a>
                  <a href="javascript:;" onClick={() => logOut()}>
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="menuDynamic tr notConnected">
              <div className="item">
                <a
                  href="javascript:;"
                  className="signinHeader"
                  onClick={() => goLogin()}
                >
                  Sign in
                </a>
              </div>
              <div className="item">
                <a
                  href="javascript:;"
                  className="getstartedHeader"
                  onClick={() => getStarted()}
                >
                  Get started
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
