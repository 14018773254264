/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useState } from "react";
import { IFoundingRound } from "../../../../../../controller/company-ctr/types/types-company-page";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
import Modal from "react-bootstrap/Modal";
import { FormHeaderFunding } from "../../header/Headerform";
import { ReactComponent as Loader } from "../../../../../../resources/images/Rolling.svg";

export default function CreateCompanyForm6(props: T.TForm8) {
  const [fundingRoundSelected, setFundingRoundSelected] = useState<string>("");
  return (
    <Modal show={props?.showStep8} className="createCompanypopup smallPopup">
      <Modal.Header>
        <FormHeaderFunding />
        <a className="close back" onClick={props?.handleBackStep7}>
          &nbsp;
        </a>
        <a
          href="javascript:;"
          className="close"
          onClick={props?.handleCloseStep8}
        >
          <span className="icon-close-pop"></span>
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="bodyCreateCompany">
          <div className="contentBorder row">
            <div className="col-12 idea">
            Devinsider host a pool of specialized investors.
              <br />
              Expose your company by indicating your funding needs. Only investors registered on Devinsider will be able to view this information.
            </div>
            <div className="form-group required col-12">
              <label htmlFor="">Funding round</label>
              {props.isShowErrorFoundindRound && (
                <div className="container-error pl-2 d-inline">
                  <span className="error-red"> error </span>
                  <span className="txt-error">
                    select al least one Funding round
                  </span>
                </div>
              )}
              <select
                className="custom-select"
                onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                  props.setFundingRound(evt.target.value);
                  setFundingRoundSelected(evt.target.value);
                }}
              >
                <option value="">Select an option</option>

                {props.fundingRound?.map(
                  (el: IFoundingRound, index: number) => (
                    <option
                      value={el.id}
                      key={index}
                      selected={el.id.toString() == props.fundingRoundValue}
                    >
                      {el.name}
                    </option>
                  )
                )}
              </select>
            </div>

            {fundingRoundSelected !== "5" &&  (
              <div className="form-group required col-12">
                <label htmlFor="">Amount you woud like to raise</label>
                {props.isShowAMountWouldLike && (
                  <div className="container-error pl-2 d-inline">
                    <span className="error-red"> error </span>
                    <span className="txt-error">
                      select at least one Amount
                    </span>
                  </div>
                )}
                <select
                  className="custom-select"
                  onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                    props.setAmountWouldLike(evt.target.value);
                  }}
                >
                  <option defaultValue="{}">Select an option</option>
                  {props.amontWouldRise?.map((el: any, index: number) => (
                    <option value={JSON.stringify(el)} key={JSON.stringify(el)}>
                      {Object.keys(el)}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder m-0">
          <button
            className="btn btn-devinsider large containerBtnBtn"
            onClick={props?.completeCreateCompanyPage}
            disabled={props?.loader}
          >
            {props?.loader ? <Loader /> : "Next"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
