import { Dispatch } from "../../types/type-action";
import store from "../../store";
// import { updateOemDraft } from "../../../service/applicatif/oem";
import { handleDataDraftSubmit } from "../../../utils";
import {
  postDataFormDataService,
  getDataService,
  postJsonDataService,
} from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant/index";
import * as Service from "../../../service/applicatif/oem";
import { toast } from "react-toastify";
import { asyncPostData } from "../../../service/api";

export const SET_OEM_PROGRAM = "SET_OEM_PROGRAM";
export const UPDATE_OEM_PROGRAM_CARD_INFORMATION =
  "UPDATE_OEM_PROGRAM_CARD_INFORMATION";
export const SET_OEM_PROGRAM_DRAFT = "SET_OEM_PROGRAM_DRAFT";
export const INITIALISE_DRAFT = "INITIALISE_DRAFT";
export const SET_OEM_EDIT_MODE = "SET_OEM_EDIT_MODE";
export const UPDATE_DRAFT_RESSOURCE_DATA = "UPDATE_DRAFT_RESSOURCE_DATA";
export const UPDATE_DRAFT_WYSIWYG_DATA = "UPDATE_DRAFT_WYSIWYG_DATA";
export const UPDATE_OEM_SETTINGS = "UPDATE_OEM_SETTINGS";
export const GET_OEM_ATTRIBUT = "GET_OEM_ATTRIBUT";
export const SEND_INVITATION_ROLE = "SEND_INVITATION_ROLE";
export const PUPBLISH_OR_UPDATE = "PUPBLISH_OR_UPDATE";
export const CREATE_NEW_PROGRAM = "CREATE_NEW_PROGRAM";
export const UPDATE_BILLING_DETAIL = "UPDATE_BILLING_DETAIL";
export const UPDATE_UNLOCK_BILLING_DETAIL = "UPDATE_UNLOCK_BILLING_DETAIL";
export const CANCEL_INVITATION_AFFILIATED = "CANCEL_INVITATION_AFFILIATED";
export const UPDATE_ROLE_MEMBER_IN_OEM_PROGRAM =
  "UPDATE_ROLE_MEMBER_IN_OEM_PROGRAM";
export const UPDATE_OEM_PROGRAM_BILLING_DETAILS =
  "UPDATE_OEM_PROGRAM_BILLING_DETAILS";
export const REMOVE_OEM_ROLE = "REMOVE_OEM_ROLE";
export const REMOVE_LOGO_OEM_PROGRAM = "REMOVE_LOGO_OEM_PROGRAM";
export const REMOVE_COUVERTURE_OEM_PROGRAM = "REMOVE_COUVERTURE_OEM_PROGRAM";
// Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
export const UPDATE_STATUS_OEM_PROGRAM = "UPDATE_STATUS_OEM_PROGRAM";
//Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
export const UNPUBLISH_PROGRAM = "UNPUBLISH_PROGRAM";
//Dev #45972,V2.1 [OEM/Ecosystem user interface] le pop-up "change billing owner" n'est pas du tout comme sur le mockup
export const UDPTADE_BILLING_OWNER_PROGRAM = "UDPTADE_BILLING_OWNER_PROGRAM";

export const UPDATE_PLAN_DETAIL_OEM_PROGRAM = "UPDATE_PLAN_DETAIL_OEM_PROGRAM";
//Dev #45972,V2.1 [OEM/Ecosystem user interface] le pop-up "change billing owner" n'est pas du tout comme sur le mockup
export const updateBillingCenterOwnerProgram = (billingOwnerProgram: any) => {
  return {
    type: UDPTADE_BILLING_OWNER_PROGRAM,
    payload: billingOwnerProgram,
  };
};

//Dev #48046,V2.1 [OEM/Ecosystem et Investor] Ajouter la possibilité de "Unpublish" la program/investor page
export const unpublishOemProgram = () => {
  return {
    type: UNPUBLISH_PROGRAM,
    payload: false,
  };
};

// Bug #47396 V1 Deactivate program button does not work - Software Ecosystem/ OEM profile
export const updateStatusOemProgram = (uuid: string, status: boolean) => {
  return {
    type: UPDATE_STATUS_OEM_PROGRAM,
    payload: {
      uuid: uuid,
      status: status,
    },
  };
};

export const remove_logo_oem_program = (payload: string) => {
  return {
    type: REMOVE_LOGO_OEM_PROGRAM,
    payload: payload,
  };
};

export const remove_couverture_oem_program = (payload: string) => {
  return {
    type: REMOVE_COUVERTURE_OEM_PROGRAM,
    payload: payload,
  };
};

export const updateOemProgramBillingDetails = (
  oem_program_billing_details: any
) => {
  return {
    type: UPDATE_OEM_PROGRAM_BILLING_DETAILS,
    payload: oem_program_billing_details,
  };
};

export const updateBillingCardOemInformation = (payment_method: string) => {
  return {
    type: UPDATE_OEM_PROGRAM_CARD_INFORMATION,
    payload: payment_method,
  };
};

export const getOemProfileProgram = (id: number, cb: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url.oem.get_program + id,
        {},
        store.getState().userReducer.token
      );
      if (result?.status === 200) {
        dispatch({ type: SET_OEM_PROGRAM, payload: result.data });
        const draft =
          result?.data?.oem_program_draft !== "" &&
          JSON.parse(result?.data?.oem_program_draft);
        dispatch({ type: INITIALISE_DRAFT, payload: draft });
        cb(result);
      }
    } catch (error) {
      cb(false);
    }
  };
};

// BILLING CENTER UPDATE CARD INFORMATION
export const updateOemProgramCardInformation = (payment: string) => {
  return {
    type: UPDATE_OEM_PROGRAM_CARD_INFORMATION,
    payload: payment,
  };
};
/**
 * Save update oem draft
 * @param data
 * @param {number} id
 * @param callback
 * @returns {(dispatch: Dispatch) => Promise<void>}
 */
export const updateOemProgramDraft = (
  data: any,
  id: string,
  callback: () => void
) => {
  return async (dispatch: Dispatch) => {
    const allDraft = {
      ...store.getState().oemProgramReducer.draft,
      ...data,
    };
    const dataToSend = handleDataDraftSubmit(allDraft);
    const result = await postDataFormDataService(
      { oemProgramDraft: JSON.stringify(dataToSend) },
      url.oem.update_draft + id,
      store.getState().userReducer.token
    );
    if (result?.status === 200) {
      dispatch({
        type: SET_OEM_PROGRAM_DRAFT,
        payload: {
          draft: allDraft,
          exist_draft: result?.data?.exist_draft,
          updated_at: result?.data?.updated_at,
        },
      });
    }
    callback();
  };
};

export const setEditModeOem = (data: boolean) => {
  return (dispatch: Dispatch) =>
    dispatch({ type: SET_OEM_EDIT_MODE, payload: data });
};

export const updateOemProgramDraftImages = (draft: string, data: any) => {
  return async (dispatch: Dispatch) => {
    const key =
      draft === "background" ? "photo_couverture_draft" : "photo_profil_draft";
    let filterData =
      draft === "background"
        ? data?.photo_couverture_draft
        : data?.photo_profil_draft;
    dispatch({
      type: SET_OEM_PROGRAM,
      payload: {
        [key]: filterData,
        exist_draft: data?.exist_draft,
        updated_at: data?.updated_at,
      },
    });
  };
};

/**
 * Update draft resource
 */
export const updateDrafResourceAction = (
  params: any,
  idCompany: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postDataFormDataService(
        params,
        url?.oem?.updateDraftResourceDraft + idCompany,
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: UPDATE_DRAFT_RESSOURCE_DATA,
          payload: result?.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * Update draft resource
 */
export const updateDraftWysiwygAction = (
  params: any,
  key: string,
  idCompany: string,
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postDataFormDataService(
        params,
        url?.oem?.update_draft_wysiwyg + idCompany,
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: UPDATE_DRAFT_WYSIWYG_DATA,
          payload: { data: result?.data, key },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * @param id number , id company page
 * @callback function  (params:any) {
   return response company age
 }
 */
export const updateOemPageSettings = (id: string, data: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let result = await postDataFormDataService(
        data,
        url?.oem?.update_oem_settings + id,
        store?.getState()?.userReducer?.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: UPDATE_OEM_SETTINGS,
          payload: result && result.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * publish and update draft data
 */
export const getOemAttributte = (
  key: string,
  completed_url: string,
  cb?: (params: any) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await getDataService(
        url?.oem?.get_attribute + completed_url,
        {},
        store.getState().userReducer.token
      );
      if (result && result.status == 200) {
        dispatch({
          type: GET_OEM_ATTRIBUT,
          payload: { data: result?.data, key: key },
        });
        return;
      }
      cb && cb(result);
    } catch (error: any) {
      cb && cb(false);
    }
  };
};

/**
 * Update send invitation role
 */
export const sendInvitationRolesOem = (params: any, callback: any) => {
  return async (dispatch: Dispatch) => {
      asyncPostData(
        url?.oem?.send_invitation,
        params,
        store.getState().userReducer.token
      ).then((result: any) => {
        if (result && result.status === 201) {
          dispatch({
            type: SEND_INVITATION_ROLE,
            payload: result && result.data,
          });
          callback(result);
          return;
        }
      })
      .catch((error: any) => {
        error.response.status = 400;
        callback(error?.response);
      });
      
  };
};

/**
 *Cancel invitation data
 */
export const cancelInvitationAction = (params: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postJsonDataService(
        url?.oem?.cancel_invitation + params,
        {},
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: CANCEL_INVITATION_AFFILIATED,
          payload: {
            id: params,
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * Publish post data
 */
export const publishOrUpdateAction = (id: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postJsonDataService(
        url?.oem?.publishOrUpdate + id,
        {},
        store.getState().userReducer.token
      );
      if (result?.status === 200) {
        dispatch({
          type: PUPBLISH_OR_UPDATE,
          payload: result?.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * Create new programs oem if user already have a program
 *
 */
export const createProgramePage = (data: any, callback: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postDataFormDataService(
        data,
        url?.oem?.create_new_program_page,
        store.getState().userReducer?.token
      );
      if (result && result?.status === 200) {
        dispatch({
          type: CREATE_NEW_PROGRAM,
          payload: result?.data,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};

/**
 * Update Billing data
 */
export const updateBillingDetails = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UPDATE_BILLING_DETAIL,
        payload: data,
      });
      return;
    } catch (error: any) {}
  };
};

/**
 * Update Billing data
 */
export const handleBillingUnlockIsv = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UPDATE_UNLOCK_BILLING_DETAIL,
        payload: data,
      });
      return;
    } catch (error: any) {}
  };
};

/*****************************************
 *
 * Update role  affilliated
 */
export const updateRoleAffiliatedAction = (
  params: {
    role?: string;
    user?: string;
    oemProgram: string | undefined;
    newOemProgramOwner: string | undefined;
  },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await Service.updateRoleAffiliated(
        params,
        store.getState().userReducer.token
      );
      if (
        (result && result.status === 201) ||
        (result && result.status === 200)
      ) {
        toast("Updated successfully!");
        const { role, status, user } = result.data;
        const payload = {
          role,
          status,
          user,
        };
        dispatch({
          type: UPDATE_ROLE_MEMBER_IN_OEM_PROGRAM,
          payload: {
            data: payload,
            idDowngrad: store.getState().userReducer.id,
          },
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      toast("An error occured!");
      callback(error?.response);
    }
  };
};

export const removeOemRoleAction = (
  params: { user_id: string; oem_id: string },
  callback: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await postJsonDataService(
        url.oem.remove_oem_member,
        params,
        store.getState().userReducer.token
      );
      if (result && result.status === 200) {
        dispatch({
          type: REMOVE_OEM_ROLE,
          payload: params,
        });
        callback(result);
        return;
      }
    } catch (error: any) {
      callback(error?.response);
    }
  };
};


export const handleUpdatePlanDetail= (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UPDATE_PLAN_DETAIL_OEM_PROGRAM,
        payload: data,
      });
      return;
    } catch (error: any) {}
  };
};