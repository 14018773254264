import HomePageCommunity from '../screen/container/community';
import DiscussionCategories from '../screen/container/community/discussion-categories/home-page-discussion-categories';
import OneCategory from '../screen/container/community/community-categies-filter/filter-discussion/filter-categorie-discussion';
import PartnerProgram from '../screen/container/community/community-categies-filter/filter-program-review/partner-program-review';
import PressReleases from '../screen/container/community/community-categies-filter/filter-press-release/press-releases';
import ArticleCategory from '../screen/container/community/community-categies-filter/filter-article/filter-category-article';
import ForumDiscusssion from '../screen/container/community/forum/discussion';
import FavoriteDiscussion from '../screen/container/community/favorite/favorite';
import ActivityUser from '../screen/container/community/activity-user/activity-user';
import PostDiscution from '../screen/container/community/post/post-discutions';
import StartPressRelease from '../screen/container/community/post/press-release';
import StartProgramReview from '../screen/container/community/post/partner-program-review';
import AuthorContribution from '../screen/container/community/view-all-top/author-contributor/index';
import BestAnswer from '../screen/container/community/view-all-top/best-answer/best-answer';
import ViewAllWithourReply from '../screen/container/community/view-all-top/unanswered/index';
import ViewAllCommunity from '../screen/container/community/view-all-top/view-all-community/index';
import OtherCatgory from '../screen/container/community/community-categies-filter/filter-other-categories';
import ResultSearch from '../screen/container/community/search-result/index';
import LikesViewAll from '../screen/container/community/view-all-top/view-all-like/view-all';
import DashboardPage from '../screen/container/dashboard/dashboard';
import { INVESTOR, ISV, OEM_PROGRAM } from '../service/constant';

export const routesCommunity = [
  {
    component: HomePageCommunity,
    exact: true,
    path: '/community/home',
    guard: 'public',
  },
  {
    component: DashboardPage,
    exact: true,
    path: '/community/oem-feeds',
    guard: 'private',
    allowedRole: [OEM_PROGRAM],
  },
  {
    component: DashboardPage,
    exact: true,
    path: '/community/investor-feeds',
    guard: 'private',
    allowedRole: [INVESTOR],
  },
  {
    component: ViewAllWithourReply,
    exact: true,
    path: '/community/all-without-replies',
    guard: 'public',
  },

  {
    component: AuthorContribution,
    exact: true,
    path: '/community/all-top-authors-contributor',
    guard: 'public',
  },
  {
    component: BestAnswer,
    exact: true,
    path: '/community/best-answer',
    guard: 'public',
  },
  {
    component: DiscussionCategories,
    exact: true,
    path: '/community/discussions-categories',
    guard: 'public',
  },
  {
    component: PartnerProgram,
    exact: true,
    path: '/community/partner-program',
    guard: 'public',
  },
  {
    component: LikesViewAll,
    exact: true,
    path: '/community/activity-user/like',
    guard: 'public',
  },
  {
    component: OneCategory,
    exact: true,
    path: '/community/one-category/:id_category/:label_id',
    guard: 'public',
  },
  {
    component: PressReleases,
    exact: true,
    path: '/community/press-releases/:tags_id',
    guard: 'public',
  },
  {
    component: ArticleCategory,
    exact: true,
    path: '/community/article-category/:id_category/:label_id',
    guard: 'public',
  },
  {
    component: ForumDiscusssion,
    exact: true,
    path: '/community/reply-post/:categoryId/:postId',
    guard: 'public',
  },
  {
    component: FavoriteDiscussion,
    exact: true,
    path: '/community/favorite-discussions',
    guard: 'private',
  },
  {
    component: ActivityUser,
    exact: true,
    path: '/community/activity-user/:id',
    guard: 'public',
  },
  {
    component: PostDiscution,
    exact: true,
    path: '/community/create-discution',
    guard: 'private',
  },
  {
    component: PostDiscution,
    exact: true,
    path: '/community/create-article',
    guard: 'private',
  },
  {
    component: StartPressRelease,
    exact: true,
    path: '/community/post/press-release',
    guard: 'private',
  },
  {
    component: StartProgramReview,
    exact: true,
    path: '/community/post/program-review',
    guard: 'private',
    allowedRole: [ISV],
  },
  {
    component: ResultSearch,
    exact: true,
    path: '/community/result',
    guard: 'public',
  },
  {
    component: ViewAllCommunity,
    exact: true,
    path: '/community/view-all-posts/:id',
    guard: 'public',
  },

  {
    component: OtherCatgory,
    exact: true,
    path: '/community/view-other-category/:id',
    guard: 'public',
  },
];
