import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFreshFinds,
  getMoreFreshFinds,
  likeAnAd,
} from "../../../redux/actions/classified-ads-action";
import {
  getCompanyResellerAction,
  getCompanyResellerAttributeAction,
} from "../../../redux/actions/company-page-reseller";
import { rootState } from "../../../redux/reducers";
import { RESELLER } from "../../../service/constant";

export default function UseHomepage() {
  const dispatch = useDispatch();

  const classifiedAdsReducer = useSelector(
    (state: rootState) => state?.classifiedAdsReducer
  );
  const userReducer = useSelector((state: rootState) => state?.userReducer);
  const oemProgramId = useSelector(
    (state: rootState) => state?.oemProgramReducer.program.id
  );

  const [loader, setLoader] = useState<boolean>(false);
  const [limit] = useState<number>(4);
  const [begin, setBegin] = useState<number>(0);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [viewMoreLoader, setViewMoreLoader] = useState<boolean>(false);
  const [showViewMore, setShowViewMore] = useState<boolean>(true);
  const role = userReducer?.roles?.filter(
    (role: string) => role !== "ROLE_USER"
  )[0];

  const callback = (data: any) => {
    if (data?.status === 200) {
      setLoader(false);
      setViewMoreLoader(false);

      if (data?.data?.length < limit) {
        setShowViewMore(false);
      }
    }
  };

  const getFreshFind = (key: string, value: any) => {
    setLoader(true);
    let params = {
      limit: begin,
      number: limit,
      oem_program: oemProgramId,
      category_id: "",
      [key]: value,
    };
    dispatch(getFreshFinds(params, callback));
  };

  useEffect(() => {
    setLoader(true);
    getFreshFind("limit", 0);
  }, []);

  const handleViewMore = () => {
    setViewMoreLoader(true);
    setBegin(begin + limit);
    let params = {
      limit: begin + limit,
      number: limit,
      oem_program: oemProgramId,
      category_id: "",
    };
    dispatch(getMoreFreshFinds(params, callback));
  };

  const handleLike = (idAds: number, status: number) => {
    if (userReducer?.enable) {
      let params = {
        idAds: idAds,
        status: status === 1 ? 0 : 1,
      };
      dispatch(likeAnAd(params, callback));
    } else {
      setShowLogin(true);
    }
  };

  const cb = () => {};

  useEffect(() => {
    if (role === RESELLER) {
      dispatch(
        getCompanyResellerAction(userReducer?.resellerCompanies[0]?.id, cb)
      );
    }
  }, []);

  useEffect(() => {
    if (role === RESELLER) {
      dispatch(getCompanyResellerAttributeAction("targetedCountry", cb));
    }
  }, []);

  useEffect(() => {
    if (role === RESELLER) {
      dispatch(getCompanyResellerAttributeAction("targetedIndustry", cb));
    }
  }, []);

  useEffect(() => {
    if (role === RESELLER) {
      dispatch(getCompanyResellerAttributeAction("segmentDetails", cb));
    }
  }, []);

  return {
    classifiedAdsReducer,
    loader,
    limit,
    handleViewMore,
    handleLike,
    showLogin,
    setShowLogin,
    viewMoreLoader,
    showViewMore,
  };
}
