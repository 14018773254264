import React, { useState, useEffect } from "react";
import store from "../../../redux/store";

export default function UseEditInfoManageUserCtr() {
  //get information about investor page
  const [investorPageName, setInvestorPageName] =
    useState<string>("Loading...");
  const [showManageUser, setShowManageUser] = useState<boolean>(false);

  useEffect(() => {
    //get investor name from store
    setInvestorPageName(
      store.getState().companyInvestorPageReducer.organization_name
    );
  }, [store.getState().companyInvestorPageReducer.id]);
  return { showManageUser, setShowManageUser, investorPageName };
}
