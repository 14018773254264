import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { isURL } from "../../../../../../../../utils";
import { ReactComponent as Loader } from "../../../../../../../../resources/images/Rolling.svg";
import { IPropsEditCompanyInfo } from "./type";
export default function ModalEditGuestCompany(props: IPropsEditCompanyInfo) {
  return (
    <Modal
      show={props?.companyDetailsShow}
      onHide={() => props?.setCompanyDetailsShow(false)}
      className="modalDevinsider medium"
    >
      <form onSubmit={props?.handleSubmit(props?.submit)}>
        <Modal.Header closeButton>
          <Modal.Title>Company details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0">
            <div className="col-12 p-0 form-group required">
              <label htmlFor="company">Company trade name</label>
              {props?.errors.companyTradeName?.type === "required" && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <input
                type="text"
                className="input-text form-control"
                {...props?.register("companyTradeName", {
                  required: true,
                })}
              />
            </div>

            <div className="col-12 p-0 form-group required">
              <label htmlFor="company">Company legal name</label>
              {props?.errors.companyLegaName?.type === "required" && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <input
                type="text"
                className="input-text form-control"
                {...props?.register("companyLegaName", {
                  required: true,
                })}
              />
            </div>

            <div className="col-12 p-0 form-group required">
              <label htmlFor="company">Website</label>
              {props?.errors.website?.type === "required" && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              {props?.errors.website?.type === "validate" && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">Website url is not valide</span>
                </div>
              )}
              <input
                type="text"
                className="input-text form-control"
                {...props?.register("website", {
                  required: true,
                  validate: (txt: string) => (txt ? isURL(txt) : true),
                })}
              />
            </div>

            <div className="col-6 form-group required p-0 pr-1">
              <label htmlFor="location">Location</label>
              {props?.errors.location?.type === "required" && (
                <div className={`container-error pl-2 "d-inline`}>
                  <span className="error-red"> error </span>
                  <span className="txt-error">This field is required</span>
                </div>
              )}
              <select
                className="custom-select"
                {...props?.register("location", {
                  required: true,
                })}
              >
                {props?.dataCompleted?.country?.map(
                  (element: any, index: number) => (
                    <option
                      key={index}
                      value={element.country_name}
                      selected={
                        element.country_name == props?.getValues("location")
                      }
                    >
                      {`${element.country_name}`}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="col-6 form-group p-0 pl-1">
              <label htmlFor="first-name">&nbsp;</label>
              <input
                type="text"
                className="input-text form-control"
                {...props?.register("city")}
                placeholder="City"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancelbtn" onClick={() => props?.cancelChange()}>
            Cancel
          </Button>
          <Button
            className="validate"
            type="submit"
            // disabled={props?.dataSource?.isLoaderShow}
          >
            {props?.isLoaderShow ? <Loader /> : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
