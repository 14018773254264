/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import Modal from "react-bootstrap/Modal";
import { IPropsEditIndividualInfoLeft } from "../types";
import FundRaisingGoal from "../../../../widget/slider-fundraising/index";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

const createSliderWithTooltip = Slider.Range;
export default function EditIndividualInfoDetailLeft(
  props: IPropsEditIndividualInfoLeft
) {
  return (
    <Modal
      show={props.showIndividualLeft}
      className="createCompanypopup large forStep5custom"
    >
      <form onSubmit={props?.handleSubmit(props?.submit)}>
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Investor page Details</h2>
            <a className="close" onClick={() => props?.closeIndividualLeft()}>
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row forPitch">
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label>
                First name
                {props.errors.firstName && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...props?.register("firstName", {
                  required: props?.required,
                })}
              />
            </div>
            <div className="form-group required col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2 numberphone">
              <label>
                Last name
                {props.errors.lastName?.type === "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...props?.register("lastName", {
                  required: props?.required,
                })}
              />
            </div>

            <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0">
              <label>
                Job title
                {props.errors.jobTitle?.type === "required" && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <input
                type="text"
                className="input-text form-control"
                {...props?.register("jobTitle", {
                  required: props?.required,
                })}
              />
            </div>

            <div className="form-group  col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label>Name of The organization</label>
              <input
                type="text"
                className="input-text form-control"
                {...props?.register("organisationName", {})}
              />
            </div>

            <div className="form-group required col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
              <label>
                Investment size
                {props.errorFundraising && (
                  <div className="container-error pl-2 d-inline">
                    {/* change d-inline on d-none */}
                    <span className="error-red"> error </span>
                    <span className="txt-error">This field is required</span>
                  </div>
                )}
              </label>
              <FundRaisingGoal {...props} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right forPitch">
            <button
              className="btn btn-devinsider cancel"
              onClick={() => props?.closeIndividualLeft()}
              type="button"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-devinsider px-5">
              {props?.loader ? <Loader /> : "Save"}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
