import React, { useEffect, useState } from "react";
import Header from "../../widget/index";
import Footer from "../../widget/footer/footer";
import "./static-page.scss";
import { getDataDrupal, urlDrupal } from "../../../service/constant";
import ReactHtmlParser from 'react-html-parser';

/*export default function TermOfUse(){
    return(
        <div>
            <Header/>
            <section className="term-of-use">
                <div className="container mx-auto">
                    Lorem ipsum
                </div>
            </section>
            <Footer/>
        </div>
    );
}*/
export default function TermOfUse(){
   
    const [txtTermOfUse, setTermOfUse] = useState({
        body:"",
        title :""
    })

    useEffect(()=> {
        async function getData() {
            let result = await getDataDrupal(urlDrupal.termsofuse);

            if (result && result.data) {
                setTermOfUse(result.data?.[0]);
            }
          }
          getData();
    },[])


    return(
        <div>
            <Header/>
            <section className="term-of-use">
                <div className="container mx-auto">
                    {
                     txtTermOfUse &&  ReactHtmlParser(txtTermOfUse && txtTermOfUse.body)
                    }
                </div>
            </section>
            <Footer/>
        </div>
    );
}