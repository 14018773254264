import React from "react";
import { Link } from "react-router-dom";
const BreadCrumbsUser = (props: any) => {
  return (
    <ul className="breadcrumbPerso">
      <li>{props?.user?.user_name}</li>
    </ul>
  );
};
export default BreadCrumbsUser;
