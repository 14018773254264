import React, { useEffect, useState } from "react";
import { getUserActivity } from "../../../service/applicatif/acount";
import store from "../../../redux/store";
import { url } from "../../../service/constant";

export default function PostByUser(userId: number) {
  const [informations, setInformations] = useState<any>(null);

  const getUserInfoActivity = async (userId: number) => {
    const response = await getUserActivity(
      url.community.activity.get_number_information,
      userId,
      store.getState().userReducer.token
    );
    if (response?.status === 200) {
      setInformations(response?.data);
    }
  };

  useEffect(() => {
    //get user contribution
    getUserInfoActivity(userId);
  }, [userId]);
  return {
    userId,
    informations,
  };
}
