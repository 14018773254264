/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IPropsFunding } from "../types";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling-white.svg";
import ModalEditFundingRound from "../modal-edit/edit-funding-round";
import alternativeFinancing from "../../../../../resources/images/Alternative_financing.png"
/**
 * Block click if loader show and not mode edit
 * show edit if mode draft
 */
export default function InfoFunding(props: IPropsFunding) {
  return (
    <>
      <article className="investorArticle">
        <div className="mx-auto container cont3">
          <div className="contentLeftInvestor">
            <a
              className="linkEditLeftCompany"
              onClick={() => props?.showModalEdit()}
            >
              Edit<span className="icon-edit-full"></span>
            </a>
            <h3 className="title">
              {/* Dev #46477 V1 [Investor] Ameliorations concernant le "preview mode" */}
              Funding{" "}
              {!props?.isEditMode ? (
                <>
                  <span style={{ color: "red" }}>
                    {!props?.isPreview ? "*" : ""}
                  </span>
                </>
              ) : (
                <></>
              )}
            </h3>
            {props?.isEditMode && (
              <>
                <p>Select the funding stages you focus on.</p>
              </>
            )}
            <ul className="listSquareItem">
              <li>
                <a
                  className={
                    props?.fundingListe?.find((e) => e.id == 1) ? "active" : ""
                  }
                >
                  <span className="icon-seed-money"></span>
                  Seed money
                </a>
              </li>
              <li>
                <a
                  className={
                    props?.fundingListe?.find((e) => e.id == 2) ? "active" : ""
                  }
                >
                  <span className="icon-series-money"></span>
                  Series a money
                </a>
              </li>
              <li>
                <a
                  className={
                    props?.fundingListe?.find((e) => e.id == 3) ? "active" : ""
                  }
                >
                  <span className="icon-growth-money"></span>
                  Growth money
                </a>
              </li>
              <li className="alternative">
                <a
                  className={
                    props?.fundingListe?.find((e) => e.id == 4) ? "active" : ""
                  }
                >
                  <div className="forFinancing">
                    <img className="alternativeFinancing" src={alternativeFinancing} alt="" />
                  </div>
                  Alternative financing
                </a>
              </li>
            </ul>
            <ModalEditFundingRound {...props} />
          </div>
        </div>
      </article>
    </>
  );
}
