import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as T from "../../../screen/container/investor/profil-investor/types";
import { getSStorage, isInObject } from "../../../utils";
import { contact } from "../../../utils/country_phone";
import useSliderFD from "../../../screen/widget/slider-fundraising/use-slider-fundraising";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { updateDraftDataAction } from "../../../redux/actions/company-page-investor-action/my-investor-page-action";
import { useParams } from "react-router-dom";

export default function UseEditIndividualInfoDetailLeft() {
  const [showIndividualLeft, setShowIndividualLeft] = useState<boolean>(false);
  const [errorFundraising, setErrorFundraising] = useState<boolean>(false);
  const [valueFundraising, setValueFundraising] = useState<Array<number>>([]);
  const params : {id : any} = useParams();
  const isOtherUser = params?.id? true : false;

  const CP_Investor = useSelector(
    (state: rootState) => !isOtherUser? state.companyInvestorPageReducer : state?.otherCompanyInvestorPageReducer
  );
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const User_Investor = useSelector((state: rootState) => state.userReducer);
  let CP_DRAFT_DATA = JSON.parse(CP_Investor.investor_page_draft || "{}");

  //Fundraising
  const {
    unitFundraisingGoal,
    unitFundraisingGoalMax,
    fundraisingGoal,
    customFundraisingGoalSlide,
    fundraisingGoalSlide,
    setFundraisingGoal,
  } = useSliderFD({ defaultValue: valueFundraising });

  /**
   * Initialise foundraising goal
   */
  useEffect(() => {
    setValueFundraising([
      CP_DRAFT_DATA?.min_fundraising_goal
        ? CP_DRAFT_DATA?.min_fundraising_goal
        : CP_Investor?.min_fundraising_goal,

      CP_DRAFT_DATA?.max_fundraising_gol
        ? CP_DRAFT_DATA?.max_fundraising_gol
        : CP_Investor?.max_fundraising_gol,
    ]);
    setValue(
      "firstName",
      isInObject(CP_DRAFT_DATA, "first_name")
        ? CP_DRAFT_DATA?.first_name
        : User_Investor?.first_name
    );
    setValue(
      "jobTitle",
      isInObject(CP_DRAFT_DATA, "job_title")
        ? CP_DRAFT_DATA?.job_title
        : CP_Investor?.job_title
    );
    setValue(
      "lastName",
      isInObject(CP_DRAFT_DATA, "last_name")
        ? CP_DRAFT_DATA?.last_name
        : User_Investor?.last_name
    );
    setValue(
      "organisationName",
      isInObject(CP_DRAFT_DATA, "organization_name")
        ? CP_DRAFT_DATA?.organization_name
        : CP_Investor?.organization_name
    );
  }, [CP_Investor?.min_fundraising_goal, CP_Investor?.max_fundraising_gol, params?.id]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm<T.FormIndividualLeft>({
    mode: "onSubmit",
  });

  const cbUpdateDraft = (data: any) => {
    setLoader(false);
    setShowIndividualLeft(false);
  };

  const submitForm = async (data: T.FormIndividualLeft) => {
    setLoader(true);
    let lastValue = JSON.parse(CP_Investor?.investor_page_draft || "{}");

    let newValue = {
      first_name: data?.firstName,
      last_name: data?.lastName,
      organization_name: data?.organisationName,
      job_title: data?.jobTitle,
      min_fundraising_goal:
        unitFundraisingGoal == "K USD"
          ? fundraisingGoalSlide[0] * 1000
          : fundraisingGoalSlide[0] * 1000000,
      max_fundraising_gol:
        unitFundraisingGoalMax == "K USD"
          ? fundraisingGoalSlide[1] * 1000
          : fundraisingGoalSlide[1] * 1000000,
    };

    dispatch(
      updateDraftDataAction(
        {
          investorPageDraft: JSON.stringify(Object.assign(lastValue, newValue)),
        },
        CP_Investor.id,
        cbUpdateDraft
      )
    );
  };

  return {
    showIndividualLeft,
    setShowIndividualLeft,
    submitForm,
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    errorFundraising,
    errors,
    unitFundraisingGoal,
    unitFundraisingGoalMax,
    fundraisingGoal,
    customFundraisingGoalSlide,
    fundraisingGoalSlide,
    setFundraisingGoal,
    loader,
  };
}
