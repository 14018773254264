import React, { useEffect, useState, useCallback } from "react";
import store from "../../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { getDetailsDiscutionCategorie } from "../../../../redux/actions/community-action";
import { useHistory } from "react-router";
export default function Index() {
  const dispatch = useDispatch();
  const history = useHistory();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );

  const cb = () => {};

  useEffect(() => {
    dispatch(getDetailsDiscutionCategorie(cb));
    return () => {};
  }, []);

  const goToLabelCategory = (idCategory: any, labelId: string) => {
    history.push("/community/one-category/" + idCategory + "/" + labelId);
  };

  return {
    communityReducer,
    goToLabelCategory,
  };
}
