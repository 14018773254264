import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useEventListener from "../../../hooks/event-handler";

export default function UseCompleteCompanyDescriptionReseller() {
  const history = useHistory();
  const [description, setDescription] = useState<string>(
    sessionStorage.getItem("company_description")
      ? sessionStorage.getItem("company_description") || ""
      : ""
  );

  const navigateToKeyCompetitive = (skip: boolean = false) => {
    if (!skip) {
      sessionStorage.setItem("company_description", description);
    }
    history.push("/complete-company-key-competitive");
  };

  const goBack = () => {
    history.goBack();
  };

  const keyEnter = ({ key }: any) => {
    if (key == "Enter") {
      navigateToKeyCompetitive();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return { navigateToKeyCompetitive, description, setDescription, goBack };
}
