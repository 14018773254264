import React from 'react';
import { fileUrl } from '../../../../../utils';
import { useHistory } from 'react-router';

type Iprops = {
  profil: string;
  companyName: string;
  roleDiscussion: string;
  profil_current_user?: string;
  companyName_current_user: string;
  roleDiscussion_current: string;
  ads_id: number;
};
export default function ClassificAddsHeaderMessage(props: Iprops) {
  const history = useHistory();
  const goToAds = () => {
    history.push("/classified-ads/details/" + props?.ads_id);
  }

  return (
    <>
      <div className="d-flex mr-5">
        <div className="photo-title">
          <img src={fileUrl(props.profil)} alt="" />
        </div>
        <div className="info-title">
          <div className="company-title">
            <a href="javascript:;">{props?.companyName}</a>
          </div>
          <div className="company-type">{props?.roleDiscussion}</div>
          <a className="read_add" onClick={goToAds}>
            {"» Read the ad"}
          </a>
        </div>
      </div>
      {/* <div className='d-flex mr-5 lef'>
        <div className='photo-title'>
          <img src={fileUrl(props.profil_current_user)} alt='' />
        </div>
        <div className='info-title'>
          <div className='company-title'>
            <a href='javascript:;'>{props?.companyName_current_user}</a>
          </div>
          <div className='company-type'>{props?.roleDiscussion_current}</div>
        </div>
      </div> */}
    </>
  );
}
