/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import TabPanHeader from './tab-pan-company-header';
import AffiliatedIsv from './roles/affiliated-insider';
import UseAffiliated from './roles/use-affilliated';
import AffiliatedOem from './roles-oem/oem-manage-roles';

import UseAffilliatedOem from './roles-oem/use-manage-roles';
import {
  TPropsPayementDetail,
} from './type';
import {
  ISV,
  OEM_PROGRAM,
} from '../../../service/constant';
import * as TabTransaction from './transaction-history/index';

const PlanDetails = (props: TPropsPayementDetail) => {
  const isIsv = props?.role?.indexOf(ISV) !== -1;
  const isOem = props?.role.indexOf(OEM_PROGRAM) !== -1;
  const providerUseAffiliated = UseAffiliated();
  const providerUseAffilliatedOem = UseAffilliatedOem();

  return (
    <Modal
      show={props?.showBillingCenter}
      className='createCompanypopup large forBillingCenter'
    >
      {/* smallPopup */}
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>
            {isOem ? 'Manage program' : 'Manage company'}
          </h2>
          <a className='close' onClick={() => props?.closeBillingCenter(true)}>
            <span className='icon-close-pop'></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='contentBorder forBillingCenter'>
          <Tab.Container
            id='left-tabs-example'
            defaultActiveKey='role'
            activeKey={props?.tabNavigate}
          >
            <TabPanHeader
              setTabNavigate={(s: string) => props?.setTabNavigate(s)}
              setShowBillingCenter={() => props?.closeBillingCenter()}
              companyName={props?.companyName}
              role={props.role}
            />
            <div className='contentPlanTab'>
              <Tab.Content>
                <Tab.Pane eventKey='role'>
                  {isIsv && (
                    <AffiliatedIsv
                      {...providerUseAffiliated}
                      setRoleAffiliated={(role: string, idUser: string) =>
                        providerUseAffiliated?.setRoleAffiliated({
                          role,
                          idUser,
                        })
                      }
                    />
                  )}
                  {isOem && <AffiliatedOem {...providerUseAffilliatedOem} />}
                </Tab.Pane>

                <Tab.Pane eventKey='transaction'>
                  <div className='contentBorder forBillingCenter'>
                    <Tab.Container
                      id='left-tabs-example'
                      defaultActiveKey='history_list'
                      activeKey={props?.tabNavHistory}
                    >
                      <TabTransaction.HeaderTab
                        setTabNavHistory={(txt: string) =>
                          props.setTabNavHistory(txt)
                        }
                      />

                      <div className='contentPlanTab'>
                        <Tab.Content>
                          <TabTransaction.TabPanTransaction
                            tabType={props.tabNavHistory}
                          />
                          <TabTransaction.TabPanReceipts
                            tabType={props.tabNavHistory}
                          />
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='contentBorder formForEmail text-right' />
      </Modal.Footer>
    </Modal>
  );
};

export default PlanDetails;
