import React, { ChangeEvent, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootState } from "../../../../redux/reducers";

type Iprops = {
  modalErroPlan: boolean;
  closeModalErroPlan: () => void;
};
export default function UnlockInvestor(props: Iprops) {
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const oemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const history = useHistory();
  const getMe = oemProgram.oemProgramMembers?.find(
    (el: any) => el?.user?.id === userReducer.id
  );

  const isProgramOwner = getMe?.role === "PROGRAM_OWNER";

  const upgradeSubscription = () => {
    history.push("/billing-center/subscription-plan");
  };

  return (
    <Modal
      show={props?.modalErroPlan}
      className="createCompanypopup small forStep5custom"
      style={{ height: "100%" }}
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Upgrade subscription</h2>
          <a
            href="javascript:;"
            className="close"
            onClick={() => props?.closeModalErroPlan()}
          >
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="row forAds">
          <div className="border radius col-12 d-flex">
            {isProgramOwner && (
              <h5>
                {/* Design & Integration #45442 OEM upgrade subscription pop up edit */}
                Unfortunately, your current subscription does not allow access to this feature.
                {isProgramOwner
                  ? " Please upgrade your subscription"
                  : " Contact your Program owner for an upgrade"}
              </h5>
            )}
            {isProgramOwner && (
              <div className="payC">
                <p onClick={upgradeSubscription}>
                  <a href="javascript:;">Upgrade subscription</a>
                </p>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
