import React, { useState, useEffect } from 'react';
import { IDataResource } from '../../../screen/container/investor/profil-investor/types';
import { useDispatch, useSelector } from 'react-redux';
import { updateDrafResourceAction } from '../../../redux/actions/company-page-investor-action/my-investor-page-action';
import { rootState } from '../../../redux/reducers';
import UseEditRessource from '../../../screen/widget/modal-edit-ressources/use-edit-ressource';
import { useParams } from 'react-router-dom';
export default function UseEditResourceCtr() {
  // const [isShowRessource, setIsShowRessource] = useState<boolean>(false);
  const [dataResource, setDataResource] = useState<Array<IDataResource>>([]);
  const dispatch = useDispatch();
  const params: { id: any } = useParams();
  const isOtherUser = params?.id ? true : false;

  const CP_Investor = useSelector((state: rootState) =>
    !isOtherUser
      ? state.companyInvestorPageReducer
      : state?.otherCompanyInvestorPageReducer
  );

  const cbUpdateDraft = (data: any) => {
    setLoader(false);
    setIsShowRessource(false);
  };

  const validData = (params: any) => {
    dispatch(updateDrafResourceAction(params, CP_Investor.id, cbUpdateDraft));
  };

  const {
    isEdit,
    setisEdit,
    saveUpdate,
    fileDataSource,
    setFileResource,
    loader,
    removeFile,
    setLoader,
    setIsShowRessource,
    isShowRessource,
    showModalEditRessource,
    closeRessourceModal,
  } = UseEditRessource({
    initialProps: dataResource,
    validate: validData,
  });

  useEffect(() => {
    setDataResource(CP_Investor?.investorPageResources);
    return () => {};
  }, [CP_Investor?.investorPageResources, params?.id]);

  return {
    isEdit,
    setisEdit,
    saveUpdate,
    fileDataSource,
    setFileResource,
    loader,
    removeFile,
    isShowRessource,
    showModalEditRessource,
    closeRessourceModal,
    otherRessource: isOtherUser ? CP_Investor.investorPageResources : [],
  };
}
