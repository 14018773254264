import ProfilIndex from '../screen/container/company/profil/index';
import CreateCompany from '../screen/container/company/create-company/index';
import ProfilIndexLocked from '../screen/container/company/profil-locked/index';
import { ISV } from '../service/constant';

export const routesCompany = [
  {
    component: ProfilIndex,
    exact: true,
    path: '/company/profil',
    guard: 'private',
  },

  {
    component: ProfilIndex,
    exact: true,
    path: '/company/company-unlocked/:id',
    guard: 'private',
  },

  {
    component: CreateCompany,
    exact: true,
    path: '/company/create',
    guard: 'private',
    allowedRole: [ISV],
  },
  {
    //Dev #48042,V2.1 [Investor] Preview Mode of an ISV company profile
    component: ProfilIndex,
    exact: true,
    path: '/isv/profil-locked/:id',
    guard: 'private',
  },
];
