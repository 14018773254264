import { String } from "lodash";
import React, { useState } from "react";

export default function UseModalWarning(initialString: Array<string>) {
  const [show, setShow] = useState<boolean>(false);
  const [allTxt, setAllTxt] = useState<Array<string>>(initialString);
  const hanleShow = () => {
    setShow(!show);
  };
  return {
    show,
    hanleShow,
    allTxt,
  };
}
