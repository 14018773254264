import { stubFalse } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  logout,
  updateOemProgramList,
} from "../../../../redux/actions/acounts-action";
import { updateStatusOemProgram } from "../../../../redux/actions/oem-program-action";
import { rootState } from "../../../../redux/reducers";
import { postData, postPhoto } from "../../../../service/api";
import { url } from "../../../../service/constant";
import { clearSessionObject, getSStorage } from "../../../../utils";

export default function useDeactivateOemProgram() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentState = useSelector((state: rootState) => state);

  const [processingDeactivateProgram, setProcessingDeactivateProgram] =
    useState<boolean>(false);
  const [showModalDeactivateProgram, setShowDeactivateProgram] =
    useState<boolean>(false);
  const handleSubmitDeactivateProgram = () => {
    setProcessingDeactivateProgram(true);
    (async () => {
      // const response = await postPhoto(
      //   {
      //     url:
      //       url.oem.get_program + currentState?.oemProgramReducer?.program?.id,
      //     data: { uuid: "", status: 0 },
      //     token: currentState?.userReducer?.token,
      //   },
      //   currentState?.userReducer?.token
      // );
      const response = await postData(
        url.oem.update_oem_settings +
          "delete/" +
          currentState?.oemProgramReducer?.program?.id,
        {},
        currentState?.userReducer?.token
      );
      if (response?.status === 200) {
        //update redux
        dispatch(
          updateStatusOemProgram("", false)
        );
        //update list programs header oem
        dispatch(updateOemProgramList());

        const activePrograms =
          currentState?.userReducer?.oemProgramMembers?.filter(
            (program) =>
              program.oem_program?.uuid != "" &&
              program.oem_program?.id.toString() !=
                currentState?.oemProgramReducer?.program?.id
          );

        //update program active header oem
        const activeProgram = activePrograms[0];

        //stop loader
        setProcessingDeactivateProgram(false);
        toast("Your program has been successfully deactivated!");

        //check if the user has only one program
        if (activePrograms.length == 0) {
          // const closeProgram = await postData(
          //   url.oem.update_oem_settings +
          //     "delete/" +
          //     currentState?.userReducer?.oemProgramMembers[0].oem_program.id,
          //   {},
          //   currentState?.userReducer?.token
          // );
          // if (closeProgram.status === 200) {
            dispatch(logout(() => history.push("/signin")));
          // }
        } else {
          history?.push("/OEM/profil/" + activeProgram?.oem_program?.id);
        }
      }
      setProcessingDeactivateProgram(false);
      setShowDeactivateProgram(false);       
    })();
  };

  return {
    handleSubmitDeactivateProgram,
    processingDeactivateProgram,
    showModalDeactivateProgram,
    handleModal: () => setShowDeactivateProgram(!showModalDeactivateProgram),
  };
}
