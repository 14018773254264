import '../header-classified-ads/Header.scss';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import {
  GUEST,
  OEM_PROGRAM,
  CONTRIBUTOR,
} from '../../../service/constant/index';
import Modal from 'react-bootstrap/Modal';
import { getCategories } from '../../../redux/actions/classified-ads-action';
import store from '../../../redux/store';
import UseHeaderClassifiedAds from './header-ctr';
import { isModerator } from '../../../utils';

type IPropsHeader = {
  role: string[];
  show: boolean;
  setShow: (a: boolean) => void;
  activeCategory: number;
  setActiveCategory: (a: number) => void;
  startAds?: () => void;
  setShowLogin: (p: boolean) => void;
  searchText?: string;
  handleChange?: (a: string) => void;
  handleClick?: () => void;
  showAdsModalP: boolean;
  setShowAdsModalP: (p: boolean) => void;
  allText: Array<string>;
};

const urlType = {
  categories: '/category',
  favorites: '/favorite',
  listing: '/listing',
};

export default function SecondHeaderClassified(props: IPropsHeader) {
  const url = window.location.href;

  const {
    role,
    show,
    setShow,
    activeCategory,
    setActiveCategory,
    startAds,
    setShowLogin,
    showAdsModalP,
    allText,
    setShowAdsModalP,
  } = props;
  const dispatch = useDispatch();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const classifiedAdsReducer = useSelector(
    (state: rootState) => state?.classifiedAdsReducer
  );

  useEffect(() => {
    dispatch(getCategories((data: any) => {}));
    return () => {};
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const history = useHistory();

  const goToCategoriesAds = (id: number) => {
    history.push('/classified-ads/category/' + id);
  };
  const goToPostAds = (category: any) => {
    history.push('/classified-ads/post', {
      params: category,
    });
    setShow(false);
  };

  const goToAdsHome = () => {
    history.push('/classified-ads/home');
  };

  const goToFavoriteAds = () => {
    store?.getState()?.userReducer && store?.getState()?.userReducer?.enable
      ? history.push('/classified-ads/favorite')
      : setShowLogin(true);
  };

  const goToListingeAds = () => {
    store?.getState()?.userReducer && store?.getState()?.userReducer?.enable
      ? history.push('/classified-ads/listing')
      : setShowLogin(true);
  };

  const providerHeader = UseHeaderClassifiedAds(props);

  const renderActive = (type: string) => {
    return url?.includes(type) ? 'active' : '';
  };

  return (
    <>
      <div
        className={`secondMenu ${scrollPosition < 57 ? '' : 'fixedSecondMenu'}`}
      >
        <div className='container mx-auto'>
          <div className='leftItemHeader'>
            <h2 className='title' onClick={() => goToAdsHome()}>
              Marketplace
            </h2>
            <div className='menuDynamic bl'>
              <div className='item'>
                <a
                  href='javascript:;'
                  className={`parent ${renderActive(urlType?.categories)}`}
                >
                  Categories
                </a>
                <div className='submenu'>
                  {classifiedAdsReducer?.categories?.map(
                    (el: any, key: number) => (
                      <a
                        href='javascript:;'
                        onClick={() => goToCategoriesAds(el?.id)}
                        key={key}
                      >
                        {el?.type}
                      </a>
                    )
                  )}
                </div>
              </div>
              {!isModerator(userReducer?.roles) && (
                <div className='item'>
                  <a
                    href='javascript:;'
                    onClick={() => goToFavoriteAds()}
                    className={renderActive(urlType?.favorites)}
                  >
                    Favorites
                  </a>
                </div>
              )}

              {role[0]?.toString() !== OEM_PROGRAM &&
                role[0]?.toString() !== CONTRIBUTOR &&
                !isModerator(userReducer?.roles) && (
                  <div className='item'>
                    <a
                      href='javascript:;'
                      onClick={() => goToListingeAds()}
                      className={renderActive(urlType?.listing)}
                    >
                      Listing
                    </a>
                  </div>
                )}
              <div className='item'>
                <div className='searchWord classified'>
                  <input
                    type='text'
                    className='keyword'
                    value={providerHeader?.searchText}
                    onChange={(evt) =>
                      providerHeader?.handleChange(evt?.target?.value)
                    }
                  />
                  <button onClick={providerHeader?.handleClick}>
                    <span className='icon-search'></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {role[0]?.toString() !== OEM_PROGRAM &&
            role[0]?.toString() !== CONTRIBUTOR &&
            !isModerator(userReducer?.roles) && (
              <div className='rightItemHeader'>
                <div className='item'>
                  <span
                    // href='javascript:;'
                    className='startPost'
                    onClick={(e) => {
                      e.preventDefault();
                      startAds && startAds();
                    }}
                  >
                    Post ad
                  </span>
                </div>
              </div>
            )}
        </div>
      </div>

      {/* <Modal show={show} className='createCompanypopup large forStep5custom'>
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Post classified ad</h2>
            <a
              href='javascript:;'
              className='close'
              onClick={() => setShow(false)}
            >
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <div className='contentBorder row forPitch forAds'>
            <p>
              Classified ads on Devinsider help ISVs to establish strategic
              partnerships with channel partners, investors, industry experts
              and other ISVs.
            </p>
            <label htmlFor=''>Select a type of Ad</label>
            <div className='listPost'>
              {classifiedAdsReducer?.categories?.map((el: any, key: number) => (
                <a
                  href='javascript:;'
                  className={`choicePost ${activeCategory === key && 'active'}`}
                  key={key}
                  onClick={(e: any) => {
                    e?.preventDefault();
                    setActiveCategory(key);
                  }}
                >
                  <span className={el?.icon}></span>
                  {el?.type}
                </a>
              ))}
            </div>
            <div className='postAdcomment'>
              Etablish sales & marketing partnerships with local/global channel
              partners.
            </div>
            <div className='messageMarketPlace'>
              Insiders get <b>2 free advertisement credits</b> during
              Devinsider’s soft launch. These advertisements will remain visible
              in the marketplace until Devinsider officially launches (June
              2023) and the marketplace ads will be replaced with new features.
              You will receive a notification before Devinsider officially
              launches and your advertisement(s) will be taken down.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='contentBorder formForEmail text-right'>
            <button
              className='btn btn-devinsider cancel'
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
            <button
              onClick={() =>
                activeCategory !== -1 &&
                goToPostAds(classifiedAdsReducer?.categories[activeCategory])
              }
              className='btn btn-devinsider px-5 save'
            >
              Next
            </button>
          </div>
        </Modal.Footer>
      </Modal> */}

      {/*Popup Permission*/}
      <Modal show={showAdsModalP} className='small forDesactivate'>
        <Modal.Header>
          <div className='headerpermission noStep'>
            <h2 className='title'>{allText?.[0]}</h2>
            <a
              href='javascript:;'
              className='close'
              onClick={() => setShowAdsModalP(false)}
            >
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='contentBorder row m-0 p-0 desactivepopup'>
            <h3 className='title withIcon'>
              <span className='icon-info'></span>
              {allText?.[1]}
            </h3>

            <p>{allText?.[2]}</p>
            <p>{allText?.[3]}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
