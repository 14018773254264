import { useState } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../redux/reducers";

export default function UseProgram(props: string) {
  /**
   * Program Directory
   * @param selectedType
   * @param selectedLocation
   * @param selectedSolution
   */
  const [type, setType] = useState<Array<number>>([]);
  const [location, setLocation] = useState<Array<number>>([]);
  const [solution, setSolution] = useState<Array<number>>([]);

  const filter = (
    selectedType: Array<number>,
    selectedLocation: Array<number>,
    selectedSolution: Array<number>
  ) => {
    setType(selectedType);
    setLocation(selectedLocation);
    setSolution(selectedSolution);
  };

  const resetFilter = () => {
    setType([]);
    setLocation([]);
    setSolution([]);
  };

  /**
   * Program events
   * @param selectedType
   * @param selectedTags
   */
  const [eventType, setEventType] = useState<Array<number>>([]);
  const [tags, setTags] = useState<Array<number>>([]);
  const [clickFilterEvent, setClickFilterEvent] = useState<boolean>(false);

  const filterEvent = (
    selectedType: Array<number>,
    selectedTags: Array<number>
  ) => {
    setEventType(selectedType);
    setTags(selectedTags);
    setClickFilterEvent(true);
  };

  const resetFilterEvent = () => {
    setEventType([]);
    setTags([]);
  };

  /**
   * Program matchmaking
   */
  const companyPageReducer = useSelector(
    (state: rootState) => state?.userReducer?.isvInCompanyPage?.company_page
  );
  const findProgramReducer = useSelector(
    (state: rootState) => state?.findProgramReducer
  );

  return {
    companyPageReducer,
    findProgramReducer,

    type,
    location,
    solution,
    eventType,
    clickFilterEvent,
    tags,
    filter: props === "EVENT" ? filterEvent : filter,
    resetFilter: props === "EVENT" ? resetFilterEvent : resetFilter,
  };
}
