import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { listingClassifiedAds } from "../../../redux/actions/classified-ads-action";
import { rootState } from "../../../redux/reducers";

export default function UseListing() {
  const dispatch = useDispatch();

  const classifiedAdsReducer = useSelector(
    (state: rootState) => state?.classifiedAdsReducer
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  const history = useHistory();
  const cb = (data: any) => {
    if (data?.status === 200) {
      setLoader(false);
      setDataFetched(true);
    }
  };

  useEffect(() => {
    setDataFetched(false);
    setLoader(true);
    dispatch(listingClassifiedAds(page, cb));
  }, []);

  const handleViewMore = () => {
    setLoader(true);
    setPage(page + 1);
    dispatch(listingClassifiedAds(page + 1, cb));
  };

  const goToCategoryPage = (idCategory: any) => {
    history.push("/classified-ads/category/" + idCategory);
  };

  return {
    classifiedAdsReducer,
    loader,
    handleViewMore,
    setShowLogin,
    dataFetched,
    goToCategoryPage,
  };
}
