import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import * as T from "../../types/types-company-page";
import { rootState } from "../../../../redux/reducers";
import { isEmail } from "../../../../utils";
import { checkIfEmailExist } from "../../../../service/applicatif/acount";
import { sendValidWorkEmailService } from "../../../../service/applicatif/company-page-isv";
export default function UseBecomeVerifiedCtr() {
  const [isShowBecomeVerified, setIsBecomeVerified] = useState<boolean>(false);
  const [verifieEmail, handleVerifieEmail] = useState<boolean>(false);
  const [isShowSuccess, setIsShowSuccess] = useState<boolean>(false);
  const [isCheched, setIsCheched] = useState<boolean>(false);
  const [workEmailTovalid, setWorkEmailTovalid] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [emailErrorTxt, setEmailErrorTxt] = useState<string>("");
  const userObject = useSelector((state: rootState) => state.userReducer);
  const [loader, setLoader] = useState<boolean>(false);

  /**
   * send request if email is work email
   */
  const sendRequest = async () => {
    if (isEmail(workEmailTovalid?.trim())) {
      setLoader(true);
      let response: any = await checkIfEmailExist(
        workEmailTovalid?.trim(),
        true
      );
      if (response?.status === 200 && response?.data?.details) {
        setErrorEmail(true);
        if (
          response?.status === 200 &&
          response?.data?.reason == "invalid domain"
        ) {
          setEmailErrorTxt("Professional email is required");
        } else {
          setEmailErrorTxt("This email address is already  used");
        }
        setLoader(false);
      } else {
        let responseValidEmail = await sendValidWorkEmailService(
          workEmailTovalid?.trim(),
          userObject?.token
        );
        setLoader(false);
        if (responseValidEmail?.status === 200) {
          setLoader(false);
          setIsShowSuccess(true);
          handleVerifieEmail(false);
        } else {
          setEmailErrorTxt(
            "An error occured ,please check your network connection"
          );
        }
      }
    } else {
      setErrorEmail(true);
      setEmailErrorTxt("your email address is not valid");
    }
  };

  /**
   * restore input work email and txt error
   */
  useEffect(() => {
    if (errorEmail) {
      setErrorEmail(false);
      setEmailErrorTxt("");
    }
  }, [workEmailTovalid]);

  useEffect(() => {
    if (!verifieEmail) {
      setIsCheched(false);
    }
    return () => {};
  }, [verifieEmail]);

  useEffect(() => {
    if (isShowBecomeVerified) {
      setWorkEmailTovalid("");
    }
    return () => {};
  }, [isShowBecomeVerified]);

  return {
    isShowBecomeVerified,
    setIsBecomeVerified,
    verifieEmail,
    handleVerifieEmail,
    isShowSuccess,
    setIsShowSuccess,
    isCheched,
    setIsCheched,
    workEmailTovalid,
    setWorkEmailTovalid,
    sendRequest,
    errorEmail,
    setErrorEmail,
    emailErrorTxt,
    loader,
    setLoader,
  };
}
