import { OemProgram, OemProgramDraft } from "../type";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import useCompleteProgramTypeOem from "../../account-ctr/create-oem-account/use-complete-program-type-oem";
import { useEffect, useState } from "react";
import { getSStorage, isRequired } from "../../../utils";
import { updateOemProgramDraft } from "../../../redux/actions/oem-program-action";

export default function useProgramInfoCtr() {
  const oemProgram: OemProgram = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );
  const oemProgramDraft: OemProgramDraft = useSelector(
    (state: rootState) => state.oemProgramReducer.draft
  );
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [defaultValue, setDefaultValue] = useState<any>({});
  const [errorsProgramName, setErrorsProgramName] = useState<boolean>(false);
  const [segmentError, setSegmentError] = useState<boolean>(false);
  const [typeError, setTypeError] = useState<boolean>(false);
  const [programName, setprogramName] = useState<string>("");
  const [typeProgram, setTypeProgram] = useState("");

  const [dataSelected, setDataSelected] = useState<Array<any>>(
    typeProgram === "1"
      ? [oemProgram?.segment_details, oemProgram?.segments]
      : oemProgram?.targeted_industry
  );

  const [dropDownItems, setDropDownItems] = useState<Array<object>>([]);

  useEffect(() => {
    getDefaultValueTypeAndSegment();
  }, [modal]);

  useEffect(() => {
    setTypeProgram(
      oemProgramDraft?.oemProgramType ||
        oemProgram?.oem_program_type?.id.toString()
    );
    setprogramName(oemProgramDraft?.programName || oemProgram?.program_name);
  }, [oemProgram?.oem_program_type, modal]);

  const initialProgramType = () => {
    let type = typeProgram || oemProgram?.oem_program_type?.id.toString();
    const isEmbeded = type === "1";
    const isSoftware = type === "2";

    if (isEmbeded) {
      setDropDownItems(dataCompleted?.oem_program_type?.["Embedded Software"]);
    }

    if (isSoftware) {
      setDropDownItems(dataCompleted?.oem_program_type?.["Software Ecosystem"]);
    }
  };
  useEffect(() => {
    initialProgramType();
    getDefaultValueTypeAndSegment();
  }, [typeProgram]);

  /**
   * Handle submit and catch error
   * Send error in completeProgramTypeCtr controller to be handle in modal
   * @param {{program_name: string}} data
   */

  const onSubmit = () => {
    if (!isRequired(programName)) {
      setErrorsProgramName(true);
      return;
    }
    if (dataSelected?.length < 1) {
      setSegmentError(true);
      return;
    }
    let data = {
      programName,
      oemProgramType: typeProgram,
      oemProgramTypeCategory: dataSelected,
    };
    setLoading(true);
    dispatch(
      updateOemProgramDraft(data, oemProgram.id, () => {
        setLoading(false);
        setModal(false);
      })
    );
  };

  const closeModal = () => {
    setModal(false);
    setprogramName(oemProgramDraft?.programName || oemProgram?.program_name);
    initialProgramType();
  };

  const openModal = () => {
    setModal(true);
  };

  /**
   * Get type and segment default value
   * Assign default value for type and segment dropdown
   */
  const getDefaultValueTypeAndSegment = () => {
    const type =
      oemProgramDraft?.oemProgramType ||
      oemProgram?.oem_program_type.id.toString();

    let tempData =
      oemProgramDraft?.oemProgramTypeCategory ||
      oemProgram?.oem_program_type_category;

    let tempCategory = tempData?.map((el: any) => ({
      id: el.id,
      label: el?.name || el?.value,
      value: el?.name || el?.value,
    }));
    setDataSelected(type == typeProgram ? tempCategory : []);
  };
  return {
    oemProgram,
    onSubmit,
    closeModal,
    openModal,
    modal,
    defaultValue,
    loading,
    typeProgram,
    dataSelected,
    setTypeProgram,
    dropDownItems,
    setDataSelected,
    errorsProgramName,
    segmentError,
    typeError,
    programName,
    setprogramName,
    oemProgramDraft,
    programType:
      typeProgram == "1" ? "Software OEM" : "Software ecosystem",
  };
}
