import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateExperInfoCompany } from "../../../../../../../../redux/actions/acounts-action";
import { rootState } from "../../../../../../../../redux/reducers";
import { IpropsFomCompany } from "./type";

export default function UseEditCompanyDetailExpert() {
  const userState = useSelector((state: rootState) => state.userReducer);
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const [companyDetailsShow, setCompanyDetailsShow] = useState<boolean>(false);

  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(sessionStorage.getItem("completed_account") || "{}")
  );
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IpropsFomCompany>({
    mode: "onChange",
    defaultValues: {
      city: userState?.expertCompany?.city || "",
      companyTradeName: userState?.expertCompany?.company_trade_name || "",
      companyLegaName: userState?.expertCompany?.company_legal_name || "",
      location: userState?.expertCompany?.country?.country_name || "",
      website: userState?.expertCompany?.website || "",
    },
  });

  const cbUpdate = (data: any) => {
    setIsLoaderShow(false);
    setCompanyDetailsShow(false);
    if (data) {
      toast("Successfully updated");
    }
  };

  const submit = (data: IpropsFomCompany) => {
    setIsLoaderShow(true);
    const countryId = data?.location
      ? dataCompleted?.country?.find(
          (el: any) => el.country_name == data?.location?.trim()
        )?.id
      : dataCompleted?.country?.find(
          (el: any) => el.country_name == userState?.country?.country_name
        )?.id;

    const params = {
      companyTradeName: data.companyTradeName,
      companyLegalName: data.companyLegaName,
      website: data.website,
      country: countryId,
      city: data.city,
    };

    dispatch(updateExperInfoCompany(params, cbUpdate));
  };

  const cancelChange = () => {
    setCompanyDetailsShow(false);
    isLoaderShow && setIsLoaderShow(false);
  };

  useEffect(() => {
    if (companyDetailsShow) {
      setValue("city", userState?.expertCompany?.city || "");
      setValue(
        "companyLegaName",
        userState?.expertCompany?.company_legal_name || ""
      );

      setValue(
        "companyTradeName",
        userState?.expertCompany?.company_trade_name || ""
      );
      setValue(
        "location",
        userState?.expertCompany?.country?.country_name || ""
      );
      setValue("website", userState?.expertCompany?.website || "");
    }
  }, [companyDetailsShow]);

  return {
    submit,
    register,
    handleSubmit,
    getValues,
    cancelChange,
    companyDetailsShow,
    setCompanyDetailsShow,
    dataCompleted,
    errors,
    isLoaderShow,
  };
}
