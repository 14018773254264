/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  checkIfExist,
  convertUnitFundraising,
  refaCtoNullKey,
} from '../../../../../utils';
import { IPropsInfoCP } from '../types';
import Tooltip from '@material-ui/core/Tooltip';
import { contact } from '../../../../../utils/country_phone';
import { HIDDEN_INFORMATION } from '../../../../../service/constant';

export default function InfoCompany(props: IPropsInfoCP) {
  const isHaveMinInvestement = parseInt(props?.minInvestement) > 0;
  const isHaveMaxInvestement = parseInt(props?.maxInvestement) > 0;
  const renderDialCode = (code: string) =>
    contact.filter((element) => code === element.code)[0].dial_code;

  const renderHiddenInformation = (data: string) => {
    data = refaCtoNullKey(data, '-');
    if (data && data.indexOf(HIDDEN_INFORMATION) !== -1) return <b>{data}</b>;
    return data;
  };

  return (
    <>
      <div className='generalInfo' id='about'>
        {/* INSTITUT AND NOT MODE EDIT COLUMN LEFT */}
        {!props?.isIndividualInvestor && !props?.isEditMode && (
          <ul className='investorInfoList half'>
            <li>
              <label>Investment size</label>
              {isHaveMinInvestement && isHaveMaxInvestement ? (
                <>
                  {convertUnitFundraising(props?.minInvestement)} to{' '}
                  {convertUnitFundraising(props?.maxInvestement)}
                </>
              ) : (
                '-'
              )}
            </li>

            <li>
              <label>Investor type </label>{' '}
              {refaCtoNullKey(props?.investorType, '-')}
            </li>

            {/*Miaro checkIfExist(props?.investorMember) &&
              props?.investorType != "Venture Capital" && (
                <li>
                  <label>Members</label>
                  {props?.investorMember} Members
                </li>
              )*/}

            {checkIfExist(props?.phoneNumber) && (
              <li>
                <label>Phone number</label>
                {'(' + renderDialCode(props?.phoneNumberCode) + ') '}
                {renderHiddenInformation(props?.phoneNumber.toString())}
              </li>
            )}
          </ul>
        )}

        {/* INDIVIDUAL AND NOT  MODE EDIT COLUMN LEFT*/}
        {props?.isIndividualInvestor && !props?.isEditMode && (
          <ul className='investorInfoList half'>
            {checkIfExist(props?.jobTitle) && (
              <li>
                <label>Job title</label>
                {refaCtoNullKey(props?.jobTitle, '-')}
              </li>
            )}
            <li>
              <label>Location</label>
              {refaCtoNullKey(props?.location, '-')}
            </li>
            {checkIfExist(props?.phoneNumber) && (
              <li>
                <label>Phone number</label>
                {'(' + renderDialCode(props?.phoneNumberCode) + ') '}
                {renderHiddenInformation(props?.phoneNumber)}
              </li>
            )}
          </ul>
        )}

        {/* INSTITUT AND NOT MODE EDIT COLUMN RIGTH */}
        {!props?.isIndividualInvestor && !props?.isEditMode && (
          <ul className='investorInfoList half'>
            <li>
              <label>Location</label>
              {refaCtoNullKey(props?.location, '-')}
            </li>
            {checkIfExist(props?.webSite) && (
              <li>
                <label>Website</label>
                <a href={props?.webSite} style={{ color: '#1897c8' }}>
                  {refaCtoNullKey(props?.webSite, '-')}
                </a>
              </li>
            )}
            <li>
              <label>Email address</label>
              <Tooltip title={props?.emailAddres}>
                <span>{renderHiddenInformation(props?.emailAddres)}</span>
              </Tooltip>
            </li>
          </ul>
        )}

        {/* INDIVIDUAL AND NOT MODE EDIT COLUMN RIGTH */}
        {props?.isIndividualInvestor && !props?.isEditMode && (
          <ul className='investorInfoList half'>
            <li>
              <li>
                <label>Investment size</label>
                {isHaveMinInvestement && isHaveMaxInvestement ? (
                  <>
                    {convertUnitFundraising(props?.minInvestement)} to{' '}
                    {convertUnitFundraising(props?.maxInvestement)}
                  </>
                ) : (
                  '-'
                )}
              </li>
            </li>
            <li>
              <label>Email address</label>
              <Tooltip title={props?.emailAddres}>
                <span>{renderHiddenInformation(props?.emailAddres)}</span>
              </Tooltip>
            </li>
          </ul>
        )}

        {/* MODE EDIT AND  INSTITUE COLUMN LEFT */}
        {props?.isEditMode && !props?.isIndividualInvestor && (
          <ul className='investorInfoList half'>
            <a
              className='linkEditLeftCompany'
              onClick={() => props?.setShowEditeModal()}
            >
              Edit<span className='icon-edit-full'></span>
            </a>
            <li>
              <label>
                Name of the <br /> Organization
              </label>
              {props?.companyName}
            </li>

            <li>
              <li>
                <label>Investment size</label>
                {isHaveMinInvestement && isHaveMaxInvestement ? (
                  <>
                    {convertUnitFundraising(props?.minInvestement)} to{' '}
                    {convertUnitFundraising(props?.maxInvestement)}
                  </>
                ) : (
                  '-'
                )}
              </li>
            </li>
            <li>
              <label>Email address</label>
              {renderHiddenInformation(props?.emailAddres)}
            </li>

            {checkIfExist(props?.phoneNumber) && (
              <li>
                <label>Phone number</label>
                {'(' +
                  renderDialCode(props?.phoneNumberCode) +
                  ') ' +
                  refaCtoNullKey(props?.phoneNumber, '-')}
              </li>
            )}
          </ul>
        )}

        {/* MODE EDIT AND  individual COLUMN LEFT */}
        {props?.isEditMode && props?.isIndividualInvestor && (
          <ul className='investorInfoList half'>
            <a
              className='linkEditLeftCompany'
              onClick={() => props?.setShowEditeModal()}
            >
              Edit<span className='icon-edit-full'></span>
            </a>
            <li>
              <label>Investor name</label>
              {props?.nameAndFirstName}
            </li>

            <li>
              <label>Job title</label>
              {props?.jobTitle}
            </li>

            {checkIfExist(props?.companyName) && (
              <li>
                <label>Name of the Organization</label>
                {props?.companyName}
              </li>
            )}
            <li>
              <li>
                <label>Investment size</label>
                {isHaveMinInvestement && isHaveMaxInvestement ? (
                  <>
                    {convertUnitFundraising(props?.minInvestement)} to{' '}
                    {convertUnitFundraising(props?.maxInvestement)}
                  </>
                ) : (
                  '-'
                )}
              </li>
            </li>
          </ul>
        )}

        {/* INSTITUT AND  MODE EDIT COLUMN RIGTH */}

        {!props?.isIndividualInvestor && props?.isEditMode && (
          <ul className='investorInfoList half'>
            <a
              className='linkEditLeftCompany'
              onClick={() => props?.setShowInfoRigth()}
            >
              Edit<span className='icon-edit-full'></span>
            </a>
            <li>
              <label>Investor type</label> {props?.investorType}
            </li>

            {/*Miaro checkIfExist(props?.investorMember) &&
              props?.investorType != "Venture Capital" && (
                <li>
                  <label>Members</label>
                  {props?.investorMember} Members
                </li>
              )*/}
            {checkIfExist(props?.webSite) && (
              <li>
                <label>Website</label>
                {refaCtoNullKey(props?.webSite, '-')}
              </li>
            )}
            {checkIfExist(props?.lindkeDin) && (
              <li>
                <label>LinkedIn</label>
                {refaCtoNullKey(props?.lindkeDin, '-')}
              </li>
            )}
            {checkIfExist(props?.location) && (
              <li>
                <label>Location</label>
                {refaCtoNullKey(props?.location, '-')}
              </li>
            )}
          </ul>
        )}
        {/* INDIVIDUAL AND  MODE EDIT COLUMN RIGTH */}
        {props?.isIndividualInvestor && props?.isEditMode && (
          <ul className='investorInfoList half'>
            <a
              className='linkEditLeftCompany'
              onClick={() => props?.setShowInfoRigth()}
            >
              Edit<span className='icon-edit-full'></span>
            </a>
            {checkIfExist(props?.location) && (
              <li>
                <label>Location</label>
                {refaCtoNullKey(props?.location, '-')}
              </li>
            )}
            {checkIfExist(props?.webSite) && (
              <li>
                <label>Website</label>
                {refaCtoNullKey(props?.webSite, '-')}
              </li>
            )}
            {checkIfExist(props?.emailAddres) && (
              <li>
                <label>Email</label>
                {refaCtoNullKey(props?.emailAddres, '-')}
              </li>
            )}
            {checkIfExist(props?.phoneNumber) && (
              <li>
                <label>Phone number</label>
                {'(' +
                  renderDialCode(props?.phoneNumberCode) +
                  ') ' +
                  refaCtoNullKey(props?.phoneNumber, '-')}
              </li>
            )}
            {checkIfExist(props?.lindkeDin) && (
              <li>
                <label>LinkedIn</label>
                {refaCtoNullKey(props?.lindkeDin, '-')}
              </li>
            )}
          </ul>
        )}
      </div>
    </>
  );
}
