import React from 'react';
import './classifiedAds.scss';
import Header from '../../widget/index';
import useDetailAds from '../../../controller/classified-ads-ctr/detail-ads-ctr';
import DefaultCompany from '../../../resources/images/defaultCompany.png';
import {
  concatString,
  convertCommentFromJSONToHTML,
  createMarkup,
  extractRoleName,
  fileUrl,
  getDescriptionEditorState,
  isModerator,
} from '../../../utils';
import { GUEST, INVESTOR, ISV, OEM_PROGRAM } from '../../../service/constant';
import { LoaderCommon, LoadingAllPage, LoadingFeed } from '../../widget/loader';
import { LoginModal } from '../../widget/login/login';
import LoginModalAuth from '../../widget/login/check-password/login-direct-check-password';
import Footer from '../../widget/footer/footer';
import { BtnAction } from '../../widget/bouton/btn-action';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import { RESELLER } from '../../../service/constant';
import { CONTRIBUTOR } from '../../../service/constant';

export default function DetailsClassifiedAds() {
  const detailAds = useDetailAds();

  const currentUser = useSelector((state: rootState) => state.userReducer);

  const isIsv = currentUser.roles.includes(ISV);
  const isOem = currentUser.roles.includes(OEM_PROGRAM);
  const isInvestor = currentUser.roles.includes(INVESTOR);
  const isGuest = currentUser.roles.includes(GUEST);
  const isResseler = currentUser.roles.includes(RESELLER);
  const isContributor = currentUser.roles.includes(CONTRIBUTOR);

  const isIsvCompletedCompanyIsv =
    currentUser?.isvInCompanyPage?.company_page?.completed;

  // const isAcceptedToContact =
  //   (isIsv && isIsvCompletedCompanyIsv) ||
  //   isOem ||
  //   isInvestor ||
  //   isModerator(detailAds.userReducer?.roles) ||
  //   isGuest ||
  //   isResseler ||
  //   isContributor;

  return (
    <>
      {detailAds?.isUserModerator &&
        !detailAds?.companyInfos?.account_already_banned && (
          <LoginModalAuth
            messageText={
              'In order to continue, you need to enter your password!'
            }
            show={detailAds?.showConfirmPasswordModerator}
            setShow={detailAds?.setShowConfirmPasswordModerator}
            callBackFunction={detailAds?.banUserProcess}
          />
        )}
      <Header />

      <section className='classified details'>
        <div className='container mx-auto'>
          <div className='leftSection'>
            <div className='block'>
              <div className='leftItem itemAuthor'>
                <div className='imageAffiliatedcompany empty'>
                  <img
                    src={
                      detailAds?.ads?.display_author_name &&
                      fileUrl(detailAds?.ads?.user?.file_name)
                    }
                  />
                </div>
                <div className='infoAffiliated'>
                  <div className='post'>Author:</div>
                  {detailAds?.ads?.display_author_name ? (
                    <>
                      <h3 className='title' style={{ cursor: 'auto' }}>
                        {concatString(
                          detailAds.ads?.user?.first_name,
                          detailAds.ads?.user?.last_name + ' ',
                          ' '
                        )}
                      </h3>
                      <div>{detailAds?.ads?.user?.job_title}</div>
                    </>
                  ) : (
                    <h3 className='title' style={{ cursor: 'auto' }}>
                      {detailAds?.ads?.user?.first_name}
                    </h3>
                  )}
                  {detailAds?.isUserModerator &&
                    !detailAds?.companyInfos?.account_already_banned && (
                      <BtnAction
                        text={'Ban user'}
                        showLoader={false}
                        classes='followFeed'
                        click={() =>
                          detailAds?.setShowConfirmPasswordModerator(true)
                        }
                      />
                    )}
                </div>
              </div>
            </div>

            <div className='block infoCompanyAds'>
              {detailAds.loadingAdds ? (
                <LoaderCommon />
              ) : (
                <>
                  <div
                    className={`imgclassified ${
                      !detailAds?.ads?.display_company_name ? 'empty' : 'imgSv'
                    }`}
                  >
                    <img
                      alt={detailAds?.ads?.page?.photo_profil}
                      src={
                        detailAds?.ads?.display_company_name
                          ? fileUrl(detailAds?.ads?.page?.photo_profil)
                          : DefaultCompany
                      }
                    />
                  </div>
                  <div className='infoAds'>
                    Company:
                    <h3 className='title'>
                      {detailAds?.ads?.display_company_name
                        ? detailAds?.ads?.page?.company_name
                        : 'Company confidential'}
                    </h3>
                    <div className='role'>
                      {extractRoleName(detailAds?.ads?.user?.roles)}
                    </div>
                    <div className='contry'>
                      {concatString(
                        detailAds?.ads?.page?.country_name,
                        detailAds?.ads?.page?.city
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            {detailAds?.adsUserRole !== GUEST && (
              <>
                <div className='block'>
                  <h2 className='title'>Software solution</h2>
                  {detailAds?.softwareLoader && <LoaderCommon />}
                  <ul className='listItemAds'>
                    {detailAds?.software
                      ?.filter((e, i: number) => {
                        return i < detailAds?.softwareCount;
                      })
                      ?.map((e: { id: number; name: string }, i: number) => (
                        <li key={i}>{e.name}</li>
                      ))}
                    <li>
                      <a
                        href='javascript:;'
                        onClick={() => {
                          detailAds?.softwareCount === 6
                            ? detailAds?.seeMore(
                                detailAds?.setSoftwareCount,
                                detailAds?.software?.length
                              )
                            : detailAds?.seeLess(detailAds?.setSoftwareCount);
                        }}
                      >
                        {detailAds?.software?.length > 6
                          ? detailAds?.softwareCount === 6
                            ? `Show more(${detailAds?.software?.length - 6})`
                            : 'Show less'
                          : ''}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='block'>
                  <h2 className='title'>Target industry</h2>
                  {detailAds?.industryLoader && <LoaderCommon />}

                  <ul className='listItemAds'>
                    {detailAds?.industry
                      ?.filter((e, i: number) => {
                        return i < detailAds?.industryCount;
                      })
                      ?.map((e: { id: number; name: string }, i: number) => (
                        <li key={i}>{e.name}</li>
                      ))}
                    <li>
                      <a
                        href='javascript:;'
                        onClick={() => {
                          detailAds?.industryCount === 6
                            ? detailAds?.seeMore(
                                detailAds?.setIndustryCount,
                                detailAds?.industry?.length
                              )
                            : detailAds?.seeLess(detailAds?.setIndustryCount);
                        }}
                      >
                        {detailAds?.industry?.length > 6
                          ? detailAds?.industryCount === 6
                            ? `Show more(${detailAds?.industry?.length - 6})`
                            : 'Show less'
                          : ''}
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          <div className='rightSection'>
            {detailAds?.ads ? (
              <>
                <h2 className='title'>{detailAds?.ads?.title}</h2>
                <div className='techno'>
                  {detailAds?.ads?.classified_ads_type?.type}
                </div>
                <div className='linkOfDetails'>
                  {!isModerator(detailAds.userReducer?.roles) && (
                    <>
                      <a
                        href='javascript:;'
                        className='px-3'
                        onClick={detailAds?.sendMessage}
                      >
                        {detailAds?.isOwner
                          ? 'View all Messages'
                          : 'Send a Message'}
                      </a>

                      <a href='javascript:;' onClick={detailAds?.favorite}>
                        <span
                          className={
                            detailAds?.isAdsFavorite
                              ? 'icon-fav-full'
                              : 'icon-fav-line'
                          }
                        />
                      </a>
                    </>
                  )}

                  {(detailAds?.isOwner ||
                    isModerator(detailAds.userReducer?.roles)) && (
                    <>
                      <a
                        href='javascript:;'
                        className='px-3'
                        onClick={detailAds?.goEdit}
                      >
                        Edit post
                      </a>
                      {detailAds?.loaderButton ? (
                        <LoaderCommon />
                      ) : (
                        <a
                          href='javascript:;'
                          className={`px-3 ${
                            detailAds?.loaderButton ? `disabled` : ''
                          }`}
                          onClick={detailAds?.deletePost}
                        >
                          Delete
                        </a>
                      )}
                    </>
                  )}
                </div>

                <div
                  className='detailsAds'
                  dangerouslySetInnerHTML={createMarkup(
                    convertCommentFromJSONToHTML(
                      getDescriptionEditorState(detailAds?.ads?.content)
                    )
                  )}
                />
              </>
            ) : (
              <LoadingFeed />
            )}
          </div>
        </div>
      </section>
      <LoginModal
        urlType={
          detailAds?.urlPath.indexOf('classified-ads') != -1 ? 'classified' : ''
        }
        show={detailAds?.showLogin}
        setShow={detailAds?.setShowLogin}
      />
      <Footer />
    </>
  );
}
