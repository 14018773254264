import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { useHistory, useParams } from "react-router-dom";
import {
  CONTRIBUTOR,
  GUEST,
  INVESTOR,
  ISV,
  MODERATOR,
  OEM_PROGRAM,
  RESELLER,
  url,
} from "../../../service/constant";
import store from "../../../redux/store";
import { getUserActivity } from "../../../service/applicatif/acount";
import { concatString } from "../../../utils";
import UseSearch from "../../../screen/widget/community/search/use-search";
import { IUserActivity } from "../../../interface/user-activity";

export default function Index() {
  const params: any = useParams();
  const history = useHistory();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );

  const [user, setUser] = useState<IUserActivity | null>(null);
  const [loadingFetchUser, setloadingFetchUser] = useState<boolean>(false);
  const role =
    userReducer?.roles?.filter((role: string) => role !== "USER_ROLE")?.[0] ||
    "";

  const getRole = (roles: Array<string>) => {
    if (roles.indexOf(ISV) !== -1)
      return { title: "Software Vendor", badge: "isv" };
    if (roles.indexOf(OEM_PROGRAM) !== -1)
      return { title: "Program Holder", badge: "program" };
    if (roles.indexOf(INVESTOR) !== -1)
      return { title: "Investor", badge: "investor" };
    if (roles.indexOf(RESELLER) !== -1)
      return { title: "Reseller", badge: "reseller" };
    if (roles.indexOf(CONTRIBUTOR) !== -1)
      return { title: "Expert Contributor", badge: "expert" };
    if (roles.indexOf(MODERATOR) !== -1)
      return { title: "Admin", badge: "admin" };
      // return { title: "Moderator", badge: "moderator" };
    if (roles.indexOf(GUEST) !== -1) return { title: "Guest", badge: "guest" };
    return { title: "", badge: "" };
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();

  useEffect(() => {
    let isCurrentUser = userReducer.id?.toString() === params?.id?.toString();
    //get the user
    (async () => {
      setloadingFetchUser(true);
      let response = await getUserActivity(
        url.community.activity.get_user_information_activity,
        params?.id,
        store.getState().userReducer.token
      );

      if (response?.status === 200) {
        let result = response?.data;
        setUser({
          id: result?.id,
          role: getRole(JSON.parse(result?.roles))?.title,
          photo: result?.file_name,
          user_name: concatString(result?.first_name, result?.last_name, " "),
          country_name: result?.country_name,
          city: result?.city,
          job_title: result?.job_title,
          company_name: result?.company_name,
          current_user: isCurrentUser,
          followed: isCurrentUser === true ? false : result?.is_followed,
          plain_text: result.plain_text,
          roles: (result?.roles && JSON.parse(result?.roles))?.[0] || "",
          //Dev #48290 Moderator suppression message
          has_closed_account: result?.has_closed_account === "1",
        });
      }
      setloadingFetchUser(false);
    })();
    window.scrollTo(0, 0);
  }, [params?.id]);

  const showActivityUser = (idUser: string) => {
    history.push("/community/activity-user/" + idUser);
  };

  const goToreplayPost = (currentPost: any) => {
    const categoryId = currentPost?.category?.id || 0;
    history.push("/community/reply-post/" + categoryId + "/" + currentPost?.id);
  };

  const showLabel = (
    categoryId: string,
    label_id: string,
    postType: number
  ) => {
    postType === 1 &&
      history.push("/community/one-category/" + categoryId + "/" + label_id);
    postType === 2 &&
      history.push(
        "/community/article-category/" + categoryId + "/" + label_id
      );
    postType === 3 && history.push("/community/press-releases/" + label_id);
    postType === 4 && history.push("/community/partner-program");
  };

  return {
    user,
    userId: params?.id,
    getBadge: getRole,
    showActivityUser,
    goToreplayPost,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    communityReducer,
    loadingFetchUser,
    role,
    showLabel,
  };
}
