import React, { useEffect, useState, useReducer } from 'react';
import Header from '../../widget/index';
import InfiniteScroll from 'react-infinite-scroll-component';
import './notification.scss';
import { LoadingFeed } from '../../widget/loader';
import { postJsonDataService } from '../../../service/applicatif/back-end-service';
import { url } from '../../../service/constant';
import { useDispatch, useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import {
  concatString,
  dateDiff,
  getTxtNotif,
  limitedText,
} from '../../../utils';
import { Redirect, useHistory } from 'react-router-dom';
import { markNotificationAsRead } from '../../../redux/actions/notification-action';
import { isEmpty } from 'lodash';
export interface IActionNotif {
  type: string;
  payload: any;
}
export type Dispatch = React.Dispatch<IActionNotif>;
type PropsNotif = {
  allNotif: Array<Notification>;
  total_result_to_paginate: number;
  currentOffset: number;
};
const initialState: PropsNotif = {
  allNotif: [],
  total_result_to_paginate: 0,
  currentOffset: 1,
};
const SET_ALL_NOTIF = 'SET_ALL_NOTIF';

const READ_NOTIF = 'READ_NOTIF';

const reducer = (state: PropsNotif, action: IActionNotif): PropsNotif => {
  switch (action.type) {
    case SET_ALL_NOTIF:
      return {
        ...state,
        allNotif: [...state.allNotif, ...action.payload],
        currentOffset: state.currentOffset + 1,
      };

    case READ_NOTIF:
      return {
        ...state,
        allNotif: state.allNotif.map((el: any) => {
          if (el.id == action.payload) {
            return {
              ...el,
              status: true,
            };
          } else return el;
        }),
      };
    default:
      return state;
  }
};

export default function AllNotification() {
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const history = useHistory();

  const isConnected = !isEmpty(userReducer?.token);
  const dispatcher = useDispatch();
  const [notifReducer, dispatch] = useReducer<
    (state: PropsNotif, action: IActionNotif) => PropsNotif
  >(reducer, initialState);

  const [hasMoreData, setHasMoreData] = useState(true);

  const [loading, setLoading] = useState(true);

  const fetchResult = async () => {
    const response = await postJsonDataService(
      url.notification.get_notification,
      {
        type: '',
        page: notifReducer.currentOffset,
      },
      userReducer.token
    );

    return response;
  };
  useEffect(() => {
    async function getNotification() {
      setLoading(true);
      const response = await fetchResult();
      setLoading(false);
      if (response?.status === 200) {
        const notifListe = response?.data?.list_notifications;
        if (notifListe?.length > 0) {
          dispatch({ type: SET_ALL_NOTIF, payload: notifListe });
        }
      }
    }
    isConnected && getNotification();
    return () => {};
  }, []);

  const fetchMoreData = async () => {
    const response = await fetchResult();
    if (response?.status === 200) {
      const notifListe = response?.data?.list_notifications;
      if (notifListe?.length > 0) {
        dispatch({ type: SET_ALL_NOTIF, payload: notifListe });
      } else {
        setHasMoreData(false);
      }
    }
  };

  const showUserActivity = (userId: number) => {
    history.push('/community/activity-user/' + userId);
  };

  const showCommunityPost = (notif: any) => {
    const categorieId = notif?.post?.category?.id;
    const postId = notif.post.id;
    if (categorieId && postId) {
      history.push('/community/reply-post/' + categorieId + '/' + postId);
    }
  };

  const markNotifAsRead = (notif: any) => {
    //Dev #48735,Bug pour indiquer que la notif est "lus"
    (async function readNotif() {
      const response = await postJsonDataService(
        url.notification.mark_read + notif.id,
        {},
        userReducer.token
      );

      if (response?.status === 200) {
        dispatch({ type: READ_NOTIF, payload: notif.id });
        //update number notif unread in redux
        dispatcher(markNotificationAsRead(notif.id));
      }
    })();
  };

  const counting = (data: Array<any>) => data.filter((el) => !el.status).length;

  let unreadNotif = counting(notifReducer?.allNotif);

  if (isEmpty(userReducer?.token)) {
    return <Redirect to={'/'} />;
  }

  return (
    <div style={{height:'100vh'}} className='bggris'>
      <Header />
      <div className='container mx-auto row allNotif' >
        <h4>Notifications</h4>
        {notifReducer?.allNotif?.length < 1 && !loading ? (
          <div>You have no notification.</div>
        ) : (
          <InfiniteScroll
            dataLength={notifReducer?.allNotif?.length}
            next={fetchMoreData}
            hasMore={hasMoreData}
            loader={<LoadingFeed />}
          >
          
            {notifReducer?.allNotif?.map((notif: any) => (
              <div className='one all_notif'>
                <a
                  href='javascript:;'
                  onClick={(e) => {
                    //Dev #48735,Bug pour indiquer que la notif est "lus"
                    e.preventDefault();
                    //mark the notification as read
                    if (notif?.status === false) markNotifAsRead(notif);
                    //go to notification
                    showCommunityPost(notif);
                  }}
                >
                  <div className={!notif?.status ? 'unreadAll' : ''}>
                    {
                      /*Miaro ticket 47407!notif?.status && (*/
                      <span className='icon-bubble mr-2 ico'></span>
                      /*)*/
                    }
                    <span className='message type'>
                      {notif?.type != 'SOLUTION_DISCUSSION_I_PARTICIPATED' &&
                      notif?.type !=
                        'SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE' ? (
                        <span
                          className='yourComment'
                          onClick={() =>
                            showUserActivity(notif?.user_who_notify?.id)
                          }
                        >
                          {concatString(
                            notif?.user_who_notify?.first_name,
                            notif?.user_who_notify?.last_name
                          )}
                        </span>
                      ) : (
                        <span className='defaultComment'>
                          The forum discussion
                        </span>
                      )}
                      {notif?.type != 'SOLUTION_DISCUSSION_I_PARTICIPATED' &&
                      notif?.type !=
                        'SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE' ? (
                        ''
                      ) : (
                        <span
                          className='yourComment'
                          onClick={() => showCommunityPost(notif)}
                        >
                          {notif?.post?.title}
                        </span>
                      )}{' '}
                      <span className='defaultComment'>
                        {getTxtNotif(notif?.type)}
                      </span>{' '}
                      <span
                        className='yourComment'
                        onClick={() =>
                          notif?.type != 'SOLUTION_DISCUSSION_I_PARTICIPATED' &&
                          notif?.type !=
                            'SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE'
                            ? showCommunityPost(notif)
                            : showUserActivity(notif?.user_who_notify?.id)
                        }
                      >
                        {notif?.type != 'SOLUTION_DISCUSSION_I_PARTICIPATED' &&
                        notif?.type !=
                          'SOLUTION_DISCUSSION_I_CHECKED_AS_FAVORITE'
                          ? notif?.post?.title
                          : notif?.user_who_notify?.first_name}
                      </span>
                    </span>
                  </div>
                  <div className='date'>{dateDiff(notif?.created_at)}</div>
                </a>
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
}
