import { setUncaughtExceptionCaptureCallback } from "process";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getFavorite,
  removeFavorite,
  restoreFavorite,
} from "../../../redux/actions/community-action";
import { rootState } from "../../../redux/reducers";
import { ItemDiscussion } from "../../../redux/types/community-interface";
import UseSearch from "../../../screen/widget/community/search/use-search";

export default function FavoriteCtr() {
  const dispatch = useDispatch();

  const userReducer = useSelector((state: rootState) => state?.userReducer);
  const communityReducer = useSelector(
    (state: rootState) => state?.communityReducer
  );
  const history = useHistory();

  const [loader, setLoader] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sortedBy, setSortedBy] = useState<
    "post_date" | "unread" | "last_activity" | "replies"
  >("post_date");
  const [checked, setChecked] = useState<Array<number>>([]);
  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();
  const cb = (data: any) => {
    if (data?.status !== 200) {
      // alert(data?.data);
    }
    setLoader(false);
  };

  const updateFavorites = (
    key: string,
    value: any,
    page: number | null = null
  ) => {
    setLoader(true);
    let getPage: number = currentPage;

    if (page) {
      getPage = page;
    }

    let data = {
      limit: limit,
      page: getPage,
      sortBy: sortedBy.toString(),
      orderBy: "DESC",
      [key]: value,
    };

    dispatch(getFavorite(data, cb));
  };

  useEffect(() => {
    let params = {
      limit: limit,
      page: currentPage,
      sortBy: sortedBy.toString(),
      orderBy: "DESC",
    };
    let current = communityReducer?.favorite_discussions?.currentPage;

    if (current < currentPage) {
      setLoader(true);
      dispatch(getFavorite(params, cb));
    }
    return () => {
      dispatch(restoreFavorite(() => {}));
    };
  }, []);

  const handleSortedBy = (params: any) => {
    setSortedBy(params);
    setCurrentPage(1);
    dispatch(restoreFavorite(() => updateFavorites("sortBy", params)));
  };

  const handlePageClick = (data: any) => {
    setCurrentPage(data);
    setLoader(true);
    dispatch(restoreFavorite(() => updateFavorites("page", data)));
  };

  const setPaginateDataFilter = (p: number) => {
    setLimit(p);
    setCurrentPage(1);

    setLoader(true);
    dispatch(restoreFavorite(() => updateFavorites("limit", p, 1)));
  };

  const checkAll = () => {
    setChecked([
      ...checked,
      ...communityReducer?.favorite_discussions?.discussions?.map(
        (el: ItemDiscussion) => el?.id
      ),
    ]);
  };

  const uncheckAll = () => {
    setChecked([]);
  };

  const unsubscribe = () => {
    let data = {
      posts: checked?.join()?.trim(),
    };
    if (checked.length > 0) {
      setCurrentPage(1);
      dispatch(
        removeFavorite(data, () => {
          updateFavorites("page", 1);
          setChecked([]);
        })
      );
    }
  };

  const handleSelection = (params: any) => {
    switch (params?.value) {
      case "check":
        checkAll();
        return;
      case "uncheck":
        uncheckAll();
        return;
      case "remove":
        unsubscribe();
        return;
      default:
        return;
    }
  };

  const handleClick = (id: number) => {
    checked?.find((el) => el === id)
      ? setChecked(checked?.filter((el) => el !== id))
      : setChecked([...checked, id]);
  };

  const goToReply = (currentPost: any) => {
    if (currentPost?.post_type) {
      history.push(
        "/community/reply-post/" +
          currentPost?.post_type?.id +
          "/" +
          currentPost?.id
      );
    }
  };

  return {
    communityReducer,
    currentPage,
    limit,
    sortedBy,
    handleSortedBy,
    handlePageClick,
    setPaginateDataFilter,
    totalFavorite: communityReducer?.favorite_discussions?.total,
    nbPageDiscussion: Math.ceil(
      communityReducer?.favorite_discussions?.total / limit
    ),
    handleSelection,
    checked,
    handleClick,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,

    goToReply,
  };
}
