import BlocLeftDirectory from "./bloc-left-directory";
import BlocLeftEvent from "./bloc-left-event";

type IProgramRoute = {
  route: string;
  filter: any;
  resetFilter: () => void;
};

export default function LeftProgram(props: IProgramRoute) {
  return (
    <>
      {props?.route === "DIRECTORY" && <BlocLeftDirectory {...props} />}
      {props?.route === "EVENT" && <BlocLeftEvent {...props} />}
    </>
  );
}
