import React, { useEffect, useState, useReducer } from "react";
import { useLocation, useHistory, useParams } from "react-router";
import store from "../../../redux/store";
import {
  contributorSearch,
  filterDateSearch,
  ItemContributorSearch,
  metaDataSearch,
} from "../../../screen/container/community/search-result/types";
import { postJsonDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import { rootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import useEventListener from "../../../hooks/event-handler";

export type ItemUserSearch = {
  order_rank: string;
  number_of_like: string;
  file_name: string;
  last_name: string;
  first_name: string;
  job_title: string;
  id: string;
  plain_text: string;
  roles: Array<string>;
  company_name: string;
  // Dev #47711 V1 [Community] Bug d'affichage sur la page 'Search' lorsque l'on filtre par 'USER'
  full_name: string;
};
export default function useSearchCtr() {
  const history = useHistory();
  const location: any = useLocation();
  const [categories, setCategories] = useState<string>(
    location?.state?.selectedCategory || ""
  );
  const [searchText, setSearchText] = useState<string>(
    location?.state?.inputText || ""
  );
  const [author, setAuthor] = useState<string>("");
  const [date, setDate] = useState<filterDateSearch>("");
  const [metadata, setMetadata] = useState<metaDataSearch>("");
  const [contributions, setContribution] = useState<contributorSearch>("");
  const [limit, setLimit] = useState<number>(10);
  const [currentPageContributor, setCurrentPageContributor] =
    useState<number>(1);
  const [sortBy, setSortBy] = useState<string>("created_at");
  const [orderBy, setOrderBy] = useState<string>("DESC");
  const [currentTab, setcurrentTab] = useState("contributions");
  const [loaderContributor, setLoaderContributor] = useState<boolean>(false);
  const [loaderAuthor, setLoaderAuthor] = useState<boolean>(false);
  const [currentPageUser, setCurrentPageUser] = useState<number>(1);
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const APPEND_USER = "APPEND_USER";
  const RESTORE_USER = "RESTORE_USER";

  const [resultContributor, setResultContributor] = useState<
    Array<ItemContributorSearch>
  >([]);

  const [paginateContributor, setPaginateContributor] = useState<number>(0);

  type InitialStateType = {
    users: Array<ItemUserSearch>;
    totalData: number;
    currentPage: number;
  };

  type IAction = {
    type: string;
    payload: any;
  };

  const initialUserState: InitialStateType = {
    users: [],
    totalData: 0,
    currentPage: 0,
  };

  const reducer = (
    state: InitialStateType,
    action: IAction
  ): InitialStateType => {
    switch (action.type) {
      case APPEND_USER:
        return {
          ...state,
          users: action.payload?.users,
          totalData: action?.payload?.count,
          currentPage: action?.payload?.currentPage,
        };
      case RESTORE_USER:
        return {
          ...state,
          users: [],
          totalData: 0,
          currentPage: 0,
        };
      default:
        return state;
    }
  };

  const [allUsers, dispatch] = useReducer<
    (state: InitialStateType, action: IAction) => InitialStateType
  >(reducer, initialUserState);

  const communityReducer = useSelector(
    (state: rootState) => state?.communityReducer
  );

  const [dataLocation, setDataLocation] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [dateOptions, setDateOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [metaDataOption, setMetaDataOption] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [typeContribution, setTypeContribution] = useState<
    Array<{ label: string; value: string }>
  >([]);

  useEffect(() => {
    let dataLocation: any = communityReducer?.discussionCategories?.map(
      (el: any) => ({
        label: el?.title,
        value: el?.id,
      })
    );
    setDataLocation(dataLocation);
    let tempDate = [
      { label: "All", value: "All" },
      { label: "day", value: "A day ago" },
      { label: "week", value: "A week ago" },
      { label: "month", value: "A month ago" },
      { label: "year", value: "A year ago" },
    ];
    setDateOptions(tempDate);

    let tempMetadata = [
      { label: "Best Answer", value: "Best Answers" },
      { label: "liked", value: "Liked" },
      { label: "No reply", value: "No reply" },
    ];

    setMetaDataOption(tempMetadata);

    let tempContributions = [
      { label: "discussions ", value: "Discussions" },
      { label: "reply", value: "Reply" },
    ];
    setTypeContribution(tempContributions);
  }, []);

  const getResponse = async (
    key: string,
    value: string,
    isReinitialisePage: boolean = false
  ) => {
    let currentPage = currentPageContributor;

    if (isReinitialisePage) {
      currentPage = 1;
    }

    let params = {
      categories: categories,
      page: currentPage,
      limit: limit,
      author: author,
      date: date,
      metadata: metadata,
      contribution: contributions,
      orderBy: orderBy,
      sortBy: sortBy,
      search: searchText,
      [key]: value,
    };
    setLoaderContributor(true);
    const response = await postJsonDataService(
      url?.community?.search,
      params,
      store?.getState().userReducer?.token
    );
    if (response?.status === 200) {
      let formatedResponse = response?.data?.results?.map((el: any) => ({
        id: el?._id,
        ...el?._source,
      }));

      setResultContributor(formatedResponse);
      setPaginateContributor(response?.data?.total_result_to_paginate);
      setLoaderContributor(false);
    }
  };

  useEffect(() => {
    getResponse("search", location?.state?.inputText, true);
    return () => {};
  }, []);

  const filterSearchText = (txt: string) => {
    setSearchText(txt);
  };

  const handlePageClick = (page: number) => {
    setCurrentPageContributor(page);
    getResponse("page", page?.toString());
  };

  const handleCateGory = (params: any) => {
    setCategories(params?.value || "");
    getResponse("categories", params?.value || "", true);
  };

  const handleDate = (params: any) => {
    setDate(params?.value || "");
    getResponse("date", params?.value || "", true);
  };

  const handleMetaData = (params: any) => {
    setMetadata(params?.value || "");
    getResponse("metadata", params?.value || "", true);
  };

  const handleContribution = (params: any) => {
    setContribution(params?.value || "");
    getResponse("contribution", params?.value || "", true);
  };

  const getUserSearch = async (params: { page: number; search: string }) => {
    let parmams = {
      page: params?.page,
      search: params?.search,
    };
    return await postJsonDataService(
      url?.community?.search_user,
      parmams,
      userReducer?.token
    );
  };

  const promiseOptions = async (inputValue: string) => {
    let params = {
      page: 1,
      search: inputValue,
    };
    let formatUser: Array<any> = [];
    setLoaderAuthor(true);
    let responseUser = await getUserSearch(params);
    if (responseUser?.status === 200) {
      formatUser = responseUser?.data?.results?.map((el: any) => ({
        id: el?._id,
        ...el?._source,
      }));
    }
    let tempResult = formatUser?.map((el: any) => ({
      label: el?.full_name,
      value: el?.full_name,
      id: el?.id,
    }));
    setLoaderAuthor(false);
    return tempResult;
  };

  const callback = () => {};

  const getAllUser = async (
    params: { page: number; search: string },
    dispatch: any,
    callback: () => void
  ) => {
    setLoaderAuthor(true);
    let responseUser = await getUserSearch(params);

    if (responseUser?.status === 200) {
      let formatUser = responseUser?.data?.results?.map((el: any) => ({
        id: el?._id,
        ...el?._source,
      }));
      const result = {
        users: formatUser,
        count: responseUser?.data?.total_result_to_paginate,
        currentPage: params?.page * 1 + 1,
      };

      if (responseUser?.data?.users?.length < 1) {
        callback();
        return true;
      }
      dispatch({ type: APPEND_USER, payload: result });
    }
    setLoaderAuthor(false);
  };

  const loadMoreUser = async (currentPage: number) => {
    setCurrentPageUser(currentPage);
    let params = {
      page: currentPage,
      search: searchText,
    };
    await getAllUser(params, dispatch, callback);
  };

  useEffect(() => {
    if (currentTab === "user") loadMoreUser(currentPageUser);
    return () => {};
  }, []);

  const setAuthorFilter = (p: any) => {
    setAuthor(p?.id || "");
    getResponse("author", p?.id || "", true);
  };

  const handleFilterTxt = () => {
    if (currentTab === "user") {
      loadMoreUser(1);
    } else {
      getResponse("search", searchText, true);
    }
  };

  const goActivity = (id: string) => {
    history.push("/community/activity-user/" + id);
  };

  const goToreplayPost = (currentPost: any) => {
    const categoryType = currentPost?.post_type?.id;
    history.push(
      "/community/reply-post/" + categoryType + "/" + currentPost?.id
    );
  };

  const keyEnter = ({ key }: any) => {
    if (key === "Enter") {
      if (categories || searchText) {
        handleFilterTxt();
        return;
      }
    } else return;
  };

  useEventListener("keydown", keyEnter);

  useEffect(() => {
    if (currentTab === "user") {
      dispatch({ type: RESTORE_USER, payload: "" });
      loadMoreUser(currentPageUser);
    }
    return () => {};
  }, [currentTab]);

  return {
    currentTab,
    setcurrentTab,
    loaderContributor,
    resultContributor,
    currentPageContributor,
    dataLocation,
    dateOptions,
    metaDataOption,
    typeContribution,
    searchText,
    filterSearchText,
    handlePageClick,
    paginateContributor,
    limit,
    handleCateGory,
    handleDate,
    handleMetaData,
    handleContribution,
    setAuthorFilter,
    promiseOptions,
    handleFilterTxt,
    communityReducer,
    categories,
    loadMoreUser,
    allUsers,
    loaderAuthor,
    currentPageUser,
    goActivity,
    goToreplayPost,
  };
}
