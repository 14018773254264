import React, { ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { refaCtoNullKey } from '../../../../../../../../utils';
import { ReactComponent as Loader } from '../../../../../../../../resources/images/Rolling.svg';
import { IpropsModal } from './type';

export default function ModalEditAbout(props: IpropsModal) {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.hideModal()}
      className='modalDevinsider large'
    >
      <Modal.Header closeButton>
        <Modal.Title>Expertise</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='contentBorder row m-0 p-0'>
          <div className='col-12 descCompany'>
            <textarea
              name=''
              value={refaCtoNullKey(props.expertise)}
              onChange={(evt: ChangeEvent<HTMLTextAreaElement>) =>
                props.handleChangeExpertise(evt.target.value)
              }
              maxLength={450}
            />
            {props?.expertise != 'null' && (
              <div className='caractercount'>
                {`${0 + props.expertise?.length || 0} / 450`}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='cancelbtn' onClick={() => props?.cancelChange()}>
          Cancel
        </Button>
        <Button
          className='validate'
          onClick={() => props?.updateInfoAbout()}
          disabled={props?.isLoaderShow}
        >
          {props?.isLoaderShow ? <Loader /> : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
