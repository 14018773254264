import { EventObject, IEvent } from "../../types/event-interface";

export const filterEventDelete = (state: Array<IEvent>, eventId: number) => {
  return state?.filter((el: IEvent, index: number) => el?.id !== eventId);
};

export const updateEventHelper = (state: Array<IEvent>, event: IEvent) => {
  return state?.map((el: IEvent, index: number) => {
    if (el?.id?.toString() === event?.id?.toString()) {
      return event;
    } else {
      return el;
    }
  });
};
