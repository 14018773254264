import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { rootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailsArticle,
  getDiscutionCategorie,
  getTopLabel,
  restoreDiscussions,
  restoreTopLabel,
} from "../../../redux/actions/community-action";
import { getDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import {
  IDiscutionCategorie,
  ItemDiscussion,
} from "../../../redux/types/community-interface";
import { IPropsContributor } from "../home-page/top-liked-contributions";
import { IpropsAuthor } from "../home-page/top-liked-author";
import UseSearch from "../../../screen/widget/community/search/use-search";

export default function UseFilterCategorieArticle() {
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );
  const history = useHistory();

  const params: any = useParams();

  const idCategorie = params?.id_category;

  const idLabel =
    params?.label_id && params?.label_id?.toString() !== "0"
      ? params?.label_id
      : null;

  const dispatch = useDispatch();

  const userReducer = useSelector((state: rootState) => state.userReducer);

  const [loaderGetLabels, setLoaderGetLabels] = useState<boolean>(false);

  const [cateGorieArticleSelected, setCateGorieArticleSelected] =
    useState<number>(idCategorie?.toString() !== "0" ? idCategorie : "0");

  const [currentPage, setcurrentPage] = useState<number>(1);
  const [limitCategorie, setLimitCategorie] = useState<number>(10);

  const [allViews, setAllViews] = useState<Array<any>>([]);

  const [limitDiscution, setlimitDiscution] = useState<number>(10);
  const [view, setView] = useState<string>("");
  const [currentDiscutionPage, setCurrentDiscutionPage] = useState<number>(1);
  const [sortedBy, setsortedBy] = useState<
    "post_date" | "first_activity" | "last_activity" | "replies" | "views"
  >("post_date");
  const [orderBy, setOrderBy] = useState("DESC");

  const [allNotSelectedLabel, setAllNotSelectedLabel] = useState<Array<number>>(
    []
  );
  const [allSelectedLabel, setAllSelectedLabel] = useState<Array<number>>([]);

  const [loader, setLoader] = useState<boolean>(false);

  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();

  const cb = (data: any) => {
    if (data?.status === 200) {
    }
  };

  const [topLikedContributions, setTopLikedContributions] = useState<
    Array<IPropsContributor>
  >([]);
  const [topLikedAuthor, setTopLikedAuthor] = useState<Array<IpropsAuthor>>([]);

  useEffect(() => {
    async function getData() {
      //Get top liked Articles
      const result = await getDataService(
        url.community.get_top_liked_contribution + "/" + -1 + "/" + 2,
        {},
        userReducer?.token
      );
      if (result?.status === 200) {
        setTopLikedContributions(result?.data);
      }
    }
    async function getTopLikeAuthor() {
      //Get top liked Authors
      const result = await getDataService(
        url.community.get_top_liked_author + "/" + -1 + "/" + 2,
        {},
        userReducer?.token
      );
      if (result?.status === 200) {
        setTopLikedAuthor(result?.data);
      }
    }
    getData();
    getTopLikeAuthor();
  }, []);

  /**
   *
   * Get all categories of top labels
   */
  useEffect(() => {
    dispatch(getDetailsArticle(cb));
    return () => {};
  }, []);

  const cbChangeCategorie = (data: any, idCategories: any) => {
    if (data?.status === 200) {
      setLoaderGetLabels(false);
    }
  };

  /**
   *
   * Get new top labels in categorie
   */
  const getNewTopLabel = (idCategories: number) => {
    dispatch(
      getTopLabel(
        { idCategorie: idCategories, postType: 2, numberPage: 1 },
        (data: any) => cbChangeCategorie(data, idCategories)
      )
    );
  };

  const getLabelsInCategories = async (
    idCategories: number,
    isRestoreSelectedLabel: boolean = false
  ) => {
    setcurrentPage(1);
    setLoaderGetLabels(true);
    setCateGorieArticleSelected(idCategories);
    isRestoreSelectedLabel && setAllSelectedLabel([]);
    dispatch(restoreTopLabel(() => getNewTopLabel(idCategories)));
  };

  const clikPrev = (index: number) => {
    setcurrentPage(currentPage - 1);
  };

  const cbNext = () => {
    setcurrentPage(currentPage + 1);
  };

  const nextPage = (nextPage: number) => {
    //Check if allData in store is inferieur of nextPage * limit
    let checkLoadNextData =
      communityReducer?.topLabelArticle?.currentPage < nextPage ? true : false;

    //if we must getData  , then we get a new topLabel
    if (checkLoadNextData) {
      dispatch(
        getTopLabel(
          {
            idCategorie: cateGorieArticleSelected,
            postType: 2,
            numberPage: nextPage,
          },
          cbNext
        )
      );
    } else {
      setcurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    async function getFiltredView() {
      let response = await getDataService(
        url?.community?.get_filtred_view + cateGorieArticleSelected + "/" + 2,
        {},
        userReducer?.token
      );

      if (response?.status == 200) {
        let tempArrayResult = response?.data;
        setAllViews(tempArrayResult);
      }
    }
    getFiltredView();
    return () => {};
  }, []);

  // For Article  data
  useEffect(() => {
    const labelId =
      params?.label_id && params?.label_id?.toString() !== "0"
        ? params?.label_id
        : null;

    const tempCategoryId =
      params?.id_category && params?.id_category?.toString() !== "0"
        ? params?.id_category
        : null;

    setAllSelectedLabel(labelId ? [labelId] : []);

    tempCategoryId && getLabelsInCategories(tempCategoryId);
    /**
     * if current page is inferieur to state current page , get new topLabel
     */
    let paramsArticle = {
      categories: tempCategoryId ? tempCategoryId : "",
      views: allViews?.join(),
      labels: labelId ? labelId : "",
      postTypes: 2,
      limit: limitDiscution,
      page: currentDiscutionPage,
      sortBy: sortedBy,
      orderBy: "DESC",
      notIdlabels: allNotSelectedLabel?.join(),
    };
    let discussions = communityReducer?.paginate_article;
    if (discussions?.currentPage < currentDiscutionPage) {
      setLoader(true);
      dispatch(getDiscutionCategorie(paramsArticle, cb));
    }
    return () => {
      //remove top label when leave page
      dispatch(restoreDiscussions(() => {}));
    };
  }, [params?.label_id]);

  const getNewDiscutionAfterUpdate = (
    key: string,
    value: any,
    isRestorePaginate: boolean = false
  ) => {
    let data = {
      categories: "",
      views: view,
      labels: "",
      postTypes: 2,
      limit: limitDiscution,
      page: isRestorePaginate ? 1 : currentDiscutionPage,
      sortBy: sortedBy,
      orderBy: orderBy,
      notIdlabels: allNotSelectedLabel?.join(),
      [key]: value,
    };
    dispatch(getDiscutionCategorie(data, cb));
  };

  const handleFilterView = (p: string) => {
    let tempWiew: string;
    if (view === p) {
      setView("");
      tempWiew = "";
    } else {
      setView(p);
      tempWiew = p;
    }
    setLoader(true);

    dispatch(
      restoreDiscussions(() =>
        getNewDiscutionAfterUpdate("views", tempWiew, true)
      )
    );
  };

  const setPaginateDataFilter = (p: number) => {
    setlimitDiscution(p);
    setLoader(true);
    dispatch(
      restoreDiscussions(() => getNewDiscutionAfterUpdate("limit", p, true))
    );
  };

  const setSortedate = (sorted: any) => {
    setsortedBy(sorted);
    setLoader(true);
    dispatch(
      restoreDiscussions(() =>
        getNewDiscutionAfterUpdate("sortBy", sorted, true)
      )
    );
  };

  const handlePageClick = (data: any) => {
    setCurrentDiscutionPage(data);
    setLoader(true);
    dispatch(
      restoreDiscussions(() => getNewDiscutionAfterUpdate("page", data))
    );
  };

  const handleLabelsArticle = (labelId: any) => {
    setLoader(true);

    const isSelectedFirst = idLabel;

    let tempLabel = isSelectedFirst
      ? allSelectedLabel?.find((el: number) => el == labelId)
      : allNotSelectedLabel?.find((el: number) => el == labelId);

    if (tempLabel) {
      isSelectedFirst
        ? setAllSelectedLabel(
            allSelectedLabel?.filter((el: number) => el != labelId)
          )
        : setAllNotSelectedLabel(
            allNotSelectedLabel?.filter((el: number) => el != labelId)
          );
      dispatch(
        restoreDiscussions(() =>
          getNewDiscutionAfterUpdate(
            isSelectedFirst ? "labels" : "notIdlabels",
            isSelectedFirst
              ? allSelectedLabel?.filter((el: number) => el != labelId)?.join()
              : allNotSelectedLabel
                  ?.filter((el: number) => el != labelId)
                  ?.join(),
            true
          )
        )
      );
    } else {
      isSelectedFirst
        ? setAllSelectedLabel([...allSelectedLabel, parseInt(labelId)])
        : setAllNotSelectedLabel([...allNotSelectedLabel, parseInt(labelId)]);
      dispatch(
        restoreDiscussions(() =>
          getNewDiscutionAfterUpdate(
            isSelectedFirst ? "labels" : "notIdlabels",
            isSelectedFirst
              ? [...allSelectedLabel, parseInt(labelId)]?.join()
              : [...allNotSelectedLabel, parseInt(labelId)]?.join(),
            true
          )
        )
      );
    }
  };

  const getDefaultArticle = () => {
    let data = {
      categories: -1,
      views: "",
      labels: "",
      postTypes: 2,
      limit: 10,
      page: 1,
      sortBy: "post_date",
      orderBy: orderBy,
      notIdlabels: "",
    };
    dispatch(getDiscutionCategorie(data, cb));
  };

  const resetFilter = () => {
    setLoader(true);
    setAllNotSelectedLabel([]);
    setView("");
    setCurrentDiscutionPage(1);
    setsortedBy("post_date");
    setlimitDiscution(10);
    setCateGorieArticleSelected(-1);
    dispatch(restoreDiscussions(() => getDefaultArticle()));
  };

  const goToreplayPost = (currentPost: ItemDiscussion) => {
    const categoryId = currentPost?.category?.id;
    history.push("/community/reply-post/" + categoryId + "/" + currentPost.id);
  };

  const viewAllContributor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "contributions",
      postType: "2",
    });
  };

  const viewAllAuthor = () => {
    history?.push("/community/all-top-authors-contributor", {
      activeKey: "author",
      postType: "2",
    });
  };

  const goArticle = () => {
    history?.push("/community/article-category");
  };

  const goFilterArticle = () => {
    history?.push("/community/discussions-categories");
  };

  const goHome = () => {
    history?.push("/community/home");
  };

  const showActivityUser = (idUser: string) => {
    history.push("/community/activity-user/" + idUser);
  };

  const goToLabelCategory = (idCategory: any, labelId: string) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (labelId?.toString() !== idLabel?.toString()) {
      dispatch(restoreDiscussions(() => {}));
      history.replace(
        "/community/article-category/" + idCategory + "/" + labelId
      );
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  return {
    communityReducer,
    cateGorieArticleSelected,
    loaderGetLabels,
    getLabelsInCategories,
    totalCategorie: communityReducer?.topLabelArticle?.total,
    limitCategorie,
    currentPage,
    clikPrev,
    nextPage,
    labelCategorie: communityReducer?.discussionCategories?.find(
      (el: IDiscutionCategorie) => el?.id == cateGorieArticleSelected
    )?.title,
    allViews,
    view,
    handleFilterView,
    resetFilter,
    limitDiscution,
    setPaginateDataFilter,
    sortedBy,
    setSortedate,
    handlePageClick,
    loader,
    handleLabelsArticle,
    allNotSelectedLabel,
    currentDiscutionPage,
    goToreplayPost,
    topLikedContributions,
    topLikedAuthor,
    viewAllContributor,
    goArticle,
    viewAllAuthor,
    goHome,
    goFilterArticle,
    showActivityUser,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    allSelectedLabel,
    useSelected: idLabel,
    goToLabelCategory,
    userReducer,
  };
}
