import React from "react";
import getTopBestAnswerAuthor from "../../../../../controller/community-ctr/home-page/top-best-answer-author";
import { fileUrl } from "../../../../../utils";
import { LoadingArticle } from "../../../../widget/loader";

const TopBestAnswerAuthor = () => {
  const topBestAnswerAuthor = { ...getTopBestAnswerAuthor() };
  return (
    <>
      <h2 className="title">Top best answer authors </h2>
      <ul className="separateElement">
        {topBestAnswerAuthor?.loaderBestAnswer ? (
          <LoadingArticle />
        ) : (
          topBestAnswerAuthor?.topBestAnswerAuthor?.map(
            (element: any, index: number) => (
              <li key={index}>
                <div
                  className="leftItem itemAuthor"
                  onClick={() =>
                    topBestAnswerAuthor?.showActivityUser(
                      element?.id?.toString()
                    )
                  }
                >
                  <div className="imageAffiliatedcompany empty">
                    {element.profil_picture && (
                      <img
                        src={fileUrl(element.profil_picture)}
                        alt="profil picture"
                      />
                    )}
                  </div>
                  <div className="infoAffiliated">
                    <h3 className="title">{element?.user_name}</h3>
                    <div className="post">{element?.job_title}</div>
                  </div>
                </div>
                <div className="rightItem solutionContent">
                  <b>{element?.number_of_like}</b>{" "}
                  {parseInt(element?.number_of_like) > 1
                    ? " solutions"
                    : " solution"}
                </div>
              </li>
            )
          )
        )}
        {topBestAnswerAuthor?.topBestAnswerAuthor?.length > 0 && (
          <li
            className="linkForLi"
            onClick={() => topBestAnswerAuthor?.viewAllBestAnswerAuthor()}
          >
            <a
              href="javascript"
              onClick={(e) => e?.preventDefault()}
              className="viewAll"
            >
              View all
            </a>
          </li>
        )}
      </ul>
    </>
  );
};

export default TopBestAnswerAuthor;
