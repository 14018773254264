import React from "react";
import Modal from "react-bootstrap/Modal";
import { fileUrl } from "../../../../utils";
import { BtnNext } from "../../bouton/btn-next";

type IProps = {
  showModal: boolean;
  handleModal: () => void;
  oemProgram: any;
  changeProgramOwner: boolean;
  setChangeProgramOwner: (p: boolean) => void;
  handleProgramOwnerRegistration: () => void;
  loader: boolean;
};

export default function ModalUpdateProgramOwner(props: IProps) {
  return (
    <Modal
      show={props.showModal}
      className="createCompanypopup medium forStep5custom"
    >
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Change program owner</h2>
          <a className="close" onClick={props.handleModal}>
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <div className="contentBorder forBillingCenter">
          <div className="continue mb-5">
            <span className="icon-exclamation-circle"></span>
            <p className="mb-4">Are you sure you want to continue?</p>
            <div>
              You are about to change the program owner of this current program
              :
            </div>
          </div>
          <div className="program mb-5">
            <div className="image">
              <img
                src={fileUrl(props.oemProgram?.photo_profil)}
                className="Image"
              />
            </div>
            <div className="desc">
              <ul>
                <li className="titleProgram">
                  {props.oemProgram?.program_name}
                </li>
                <li>{props.oemProgram?.company_name}</li>
              </ul>
            </div>
          </div>
          <div className="p-4">
            <div className="form-group form-check px-0 col-12 checkContent">
              <input
                type="checkbox"
                className="form-check-input regular-checkbox big-checkbox"
                onChange={() =>
                  props.setChangeProgramOwner(!props.changeProgramOwner)
                }
                checked={props.changeProgramOwner}
              />
              By changing the program owner, you acknowledge that you will no
              longer have a full control of this program. You will be downgraded
              to an admin role. <br />
              <br />
              Only program owner and admin roles can be selected as the billing
              owner. There can only be one billing owner.
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right forPitch">
          <button
            className="btn btn-devinsider cancel"
            onClick={props.handleModal}
          >
            Cancel
          </button>
          <button
            onClick={() => props.handleProgramOwnerRegistration()}
            type="submit"
            className="btn btn-devinsider px-5 save"
            disabled={props.loader || !props.changeProgramOwner}
          >
            <BtnNext text={"Change program owner"} showLoader={props.loader} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
