import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import { getData, getDataWithoutToken } from '../../../../service/api';
import {
  operationBillingAmount,
  url,
  urlDrupal,
} from '../../../../service/constant';
import { getMonth } from '../../../../utils';

export default function UseUnlockInvestor() {
  const now = new Date();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const companyPageReducer = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const [modalPR, setmodalPR] = useState<boolean>(false);
  const [amountPR, setAmountPR] = useState<string>('');
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + ' ' + now.getFullYear()
  );

  const [statePR, setstatePR] = useState<{
    number_left: number;
    number_max: number;
    next_grant: string;
    spent_by: Array<{
      id: string;
      first_name: string;
      last_name: string;
      user_uri: string;
      at: string;
      number: string;
      role: string;
    }>;
  }>({ number_left: 0, number_max: 0, next_grant: '', spent_by: [] });

  const closePRModal = () => {
    setstatePR({ number_left: 0, number_max: 0, next_grant: '', spent_by: [] });
    setmodalPR(false);
    setChoiseValue(undefined);
  };

  const showmodalPR = () => {
    setmodalPR(true);
  };

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };

  const getDataWs = async () => {
    let response = await getData(
      url.settings.billingCenter +
        '/' +
        operationBillingAmount.POST_PRESS_RELEASE +
        '/' +
        companyPageReducer.id,
      userReducer.token
    );
    if (response && response.data) setAmountPR(response.data);
  };

  useEffect(() => {
    (async () => {
      companyPageReducer.id && userReducer.token && getDataWs();
    })();
  }, []);

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + '/information/plan_details',
        { month: currentMonth },
        userReducer.token
      );
      if (response?.status === 200) {
        const { isv_press_release }: any = response.data.free_status;
        setstatePR(isv_press_release);
        setChargingPlanDetails(false);
        const isHaveFree = isv_press_release?.number_left > 0;
        if (isHaveFree) {
          setChoiseValue('FREE');
        }
      }
    }
    if (modalPR) {
      getPlanDetails();
    }
  }, [currentMonth, modalPR]);

  return {
    modalPR,
    closePRModal,
    setmodalPR,
    chargingPlanDetails,
    statePR,
    handleChoise,
    choiseValue,
    showmodalPR,
    amountPR,
  };
}
