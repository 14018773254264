import React from "react";

export type TProps = {
  email: string;
};
export default function ClaimStep3(props: TProps) {
  return (
    <div className="bodyCreateCompany ">
      <div className="finalpopup success">
        <h3 className="title">
          Check your inbox to complete
          <br />
          the process.{" "}
        </h3>
        To continue the process of claiming your company page, click the
        <br /> verification link we sent to your work email address:
        <br /> {props?.email}
        <p>
          (Please check your spam or junk mail if you have not received it{" "}
          <br /> within the next 5 minutes)
        </p>
      </div>
    </div>
  );
}
