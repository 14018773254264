import React, { useEffect, useState } from "react";
import { postUserActivity } from "../../../service/applicatif/acount";
import store from "../../../redux/store";
import { url } from "../../../service/constant";

export default function BestAnswerByUser(userId: number) {
  const [bestAnswerByUser, setBestAnswerByUser] = useState<any>([]);
  const [bestAnswerByUserFilter, setBestAnswerByUserFilter] = useState<any>({
    postTypes: "",
    limit: "10",
    page: "1",
    sortBy: "post_date",
    orderBy: "DESC",
  });
  const [loadingBestAnswer, setLoadingBestAnswer] = useState<boolean>(false);

  const getBestAnswer = async (params: any) => {
    setLoadingBestAnswer(true);
    const response = await postUserActivity(
      url.community.activity.get_best_answer_by_user,
      params,
      store.getState().userReducer.token
    );
    setBestAnswerByUser(response?.data);
    setLoadingBestAnswer(false);
  };
  useEffect(() => {
    const params = { ...bestAnswerByUserFilter, idUser: userId };
    if (userId) {
      getBestAnswer(params);
      setBestAnswerByUserFilter(params);
    }
  }, [userId]);

  const handleChangePostType = (e: any) => {
    const postType = e.value;
    const params = {
      ...bestAnswerByUserFilter,
      postTypes: postType,
    };
    getBestAnswer(params);
    setBestAnswerByUserFilter(params);
  };

  const handleChangeShow = (e: any) => {
    const show = e.value;

    const params = {
      ...bestAnswerByUserFilter,
      limit: show,
    };
    getBestAnswer(params);
    setBestAnswerByUserFilter(params);
  };

  const handleChangeSorted = (e: any) => {
    const sorted = e.value;
    const params = {
      ...bestAnswerByUserFilter,
      sortBy: sorted,
    };
    getBestAnswer(params);
    setBestAnswerByUserFilter(params);
  };

  const handlePage = (p: any) => {
    const params = {
      ...bestAnswerByUserFilter,
      page: p,
    };
    getBestAnswer(params);
    setBestAnswerByUserFilter(params);
  };

  return {
    bestAnswerByUser,
    bestAnswerByUserFilter,
    loadingBestAnswer,
    handleChangeShow,
    handleChangeSorted,
    handleChangePostType,
    handlePage,
  };
}
