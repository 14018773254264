import React, { ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as Loader } from '../../../../../../resources/images/Rolling.svg';

type IProps = {
  isShowExpressionStrategic: boolean;
  closeModalExpressionStrategic: () => void;
  valueExpressionStrategic: string;
  setValueExpressionStrategic: (txt: string) => void;
  updateExpressionStrategic: () => void;
  loader: boolean;
};
export default function ModalEditStrategy(props: IProps) {
  return (
    <Modal
      show={props.isShowExpressionStrategic}
      className='createCompanypopup large forStep5custom'
    >
      {/* smallPopup */}
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>Expansion strategy</h2>
          <a
            className='close'
            onClick={() => props.closeModalExpressionStrategic()}
          >
            <span className='icon-close-pop'></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className=''>
        <div className='contentBorder row forStrategy'>
          <div className='col-12 descCompany'>
            <label htmlFor='' className='form-group col-12'>
              What is your geographic expansion plan?
            </label>
            <div className='form-group col-12 descCompany m-0'>
              <textarea
                maxLength={2000}
                value={props.valueExpressionStrategic}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  props.setValueExpressionStrategic(event.target.value)
                }
              />
              <span className='caractercount'>
                {0 + (props.valueExpressionStrategic?.length || 0) + ' / 2000'}
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='contentBorder row forOverview'>
        <div className='contentBorder formForEmail text-right'>
          <button
            className='btn btn-devinsider cancel'
            onClick={() => props.closeModalExpressionStrategic()}
          >
            Cancel
          </button>
          <button
            className='btn btn-devinsider px-5 save'
            onClick={() => props.updateExpressionStrategic()}
          >
            {props.loader ? <Loader /> : 'Save'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
