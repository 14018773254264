import React from "react";
import Select from "react-select";
import UseCompleteCategoryInvestor from "../../../../../../controller/account-ctr/complete-create-account-investor/use-complete-target-investor";
import { IPropsSettingsFundingRound } from "../../types";
export default function FundingRound(props: IPropsSettingsFundingRound) {
  const { dataCompleted } = UseCompleteCategoryInvestor();

  let listFunding = dataCompleted?.fundingRound.filter(
    (funding: any) => funding.name !== "I am not looking for funding"
  );

  return (
    <div className="row m-0 p-0">
      <div className="col-xl-6 col-lg-8 col-md-12 col-12  p-0 pb-5 m-0">
        <Select
          options={listFunding?.map((el: any) => {
            return {
              id: el.id,
              label: el.name,
              value: el.name,
            };
          })}
          className="multipleSelect"
          isMulti={true}
          value={props?.foundingRound}
          onChange={(data: any) => {
            props?.setFoundingRound(data);
          }}
        />
      </div>
    </div>
  );
}
