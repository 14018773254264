import React from "react";
import Modal from "react-bootstrap/Modal";

export type IPopsModal = {
  showModal: boolean;
  handleModal: () => void;
};
export default function ModalNoCompany(props: IPopsModal) {
  return (
    <Modal
      show={props.showModal}
      onHide={props.handleModal}
      className="modalDevinsider medium forDeactivate"
    >
      <Modal.Header closeButton>
        <Modal.Title>Oops!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="contentBorder row m-0 p-0 desactivepopup">
          <h3 className="title withIcon mt-4">
            <span className="icon-info"></span>
            You don't have a company page...
          </h3>
        </div>
      </Modal.Body>
    </Modal>
  );
}
