import { useEffect, useState } from "react";

export type IPropsSlide = {
  defaultValue: Array<number>;
};
export default function UseSliderAnnualTurnOver(props: IPropsSlide) {
  const [annualTurnover, setAnnualTurnover] = useState<Array<number>>([1, 90]);

  const [annualTurnoverSlide, setAnnualTurnoverSlide] = useState(
    props?.defaultValue
  );
  const [unitTurnover, setUnitTurnover] = useState("K USD");
  const [unitTurnoverMax, setUnitTurnoverMax] = useState("M USD");

  const customSetAnnualSize = (min: number, max: number) => {
    let tempMin: number = 0;
    let tempMax: number = 0;
    switch (min) {
      case 0:
      case 1:
        tempMin = 0;
        setUnitTurnover("");
        break;
      case 10:
      case 11:
        tempMin = 500;
        setUnitTurnover("K USD");
        break;
      case 20:
      case 21:
        tempMin = 2;
        setUnitTurnover("M USD");
        break;
      case 30:
      case 31:
        tempMin = 5;
        setUnitTurnover("M USD");
        break;
      case 40:
      case 41:
        tempMin = 10;
        setUnitTurnover("M USD");
        break;
      case 50:
      case 51:
        tempMin = 25;
        setUnitTurnover("M USD");

        break;
      case 60:
      case 61:
        tempMin = 50;
        setUnitTurnover("M USD");
        break;
      case 70:
      case 71:
        tempMin = 200;
        setUnitTurnover("M USD");
        break;
      case 80:
      case 81:
        tempMin = 500;
        setUnitTurnover("M USD");
        break;
      default:
        break;
    }
    switch (max) {
      case 0:
        tempMax = 0;
        break;

      case 10:
      case 11:
        tempMax = 500;
        setUnitTurnoverMax("K USD");
        break;
      case 20:
      case 21:
        tempMax = 2;
        setUnitTurnoverMax("M USD");
        break;
      case 30:
      case 31:
        tempMax = 5;
        setUnitTurnoverMax("M USD");

        break;
      case 40:
      case 41:
        tempMax = 10;
        setUnitTurnoverMax("M USD");

        break;
      case 50:
      case 51:
        tempMax = 25;
        setUnitTurnoverMax("M USD");

        break;
      case 60:
      case 61:
        tempMax = 50;
        setUnitTurnoverMax("M USD");

        break;
      case 70:
      case 71:
        tempMax = 200;
        setUnitTurnoverMax("M USD");

        break;
      case 80:
      case 81:
        tempMax = 500;
        setUnitTurnoverMax("M USD");

        break;
      case 90:
      case 91:
        tempMax = 600;
        setUnitTurnoverMax("M USD");
        break;
      default:
        break;
    }
    setAnnualTurnoverSlide([tempMin, tempMax]);
    setAnnualTurnover([min, max]);
  };

  useEffect(() => {
    const [min, max] = props?.defaultValue;

    if (props?.defaultValue?.length > 0) {
      let tempMin: number = min / 1000000;
      let tempMax: number = max / 1000000;

      if (tempMin < 1 || tempMax < 1) {
        setAnnualTurnoverSlide([
          tempMin < 1 ? tempMin * 1000 : tempMin,
          tempMax < 1 ? tempMax * 1000 : tempMax,
        ]);
      } else {
        setAnnualTurnoverSlide([tempMin, tempMax]);
      }
      if (min !== 0 && max !== 0) {
        reverseFundraisingGoalSlide(tempMin, tempMax);
      }
    }
    return () => {};
  }, [props?.defaultValue]);

  const reverseFundraisingGoalSlide = (min: number, max: number) => {
    let tempMin: number = 0;
    let tempMax: number = 0;

    switch (min) {
      case 0:
        tempMin = 0;
        setUnitTurnover("");
        break;
      case 0.5:
        tempMin = 10;
        setUnitTurnover("K USD");
        break;
      case 2:
        tempMin = 20;
        setUnitTurnover("M USD");
        break;
      case 5:
        tempMin = 30;
        setUnitTurnover("M USD");
        break;
      case 10:
        tempMin = 40;
        setUnitTurnover("M USD");
        break;
      case 25:
        tempMin = 50;
        setUnitTurnover("M USD");
        break;
      case 50:
        tempMin = 60;
        setUnitTurnover("M USD");
        break;
      case 200:
        tempMin = 70;
        setUnitTurnover("M USD");
        break;
      case 500:
        tempMin = 80;
        setUnitTurnover("M USD");
        break;
      case 600:
        tempMin = 90;
        setUnitTurnover("M USD");
        break;
      default:
        break;
    }
    switch (max) {
      case 0:
        tempMax = 0;
        setUnitTurnoverMax("");
        break;
      case 0.5:
        tempMax = 10;
        setUnitTurnoverMax("K USD");
        break;
      case 2:
        tempMax = 20;
        setUnitTurnoverMax("M USD");
        break;
      case 5:
        tempMax = 30;
        setUnitTurnoverMax("M USD");
        break;
      case 10:
        tempMax = 40;
        setUnitTurnoverMax("M USD");
        break;
      case 25:
        tempMax = 50;
        setUnitTurnoverMax("M USD");
        break;
      case 50:
        tempMax = 60;
        setUnitTurnoverMax("M USD");
        break;
      case 200:
        tempMax = 70;
        setUnitTurnoverMax("M USD");
        break;
      case 500:
        tempMax = 80;
        setUnitTurnoverMax("M USD");
        break;
      case 600:
        tempMax = 90;
        setUnitTurnoverMax("M USD");
        break;
      default:
        break;
    }

    setAnnualTurnover([tempMin, tempMax]);
  };

  const getResult = (): Array<number> => {
    let min = 0;
    let max = 0;
    if (unitTurnover === "K USD") {
      min = annualTurnoverSlide[0] * 1000;
    } else if (unitTurnover === "M USD") {
      min = annualTurnoverSlide[0] * 1000000;
    }

    if (unitTurnoverMax === "K USD") {
      max = annualTurnoverSlide[1] * 1000;
    } else if (unitTurnoverMax === "M USD") {
      max = annualTurnoverSlide[1] * 1000000;
    }

    return [min, max];
  };

  return {
    annualTurnover,
    annualTurnoverSlide,
    unitTurnover,
    unitTurnoverMax,
    setAnnualTurnoverSlide,
    setAnnualTurnover,
    customSetAnnualSize,
    getResult,
  };
}
