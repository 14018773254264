import { IProgramMenu } from '../../../../controller/program-ctr/type';
import UseDirectoryProgram from '../../../../controller/program-ctr/program-directory-ctr/use-program-directory';
import { IProgram } from '../../../../redux/types/find-program-interface';
import {
  convertCommentFromJSONToHTML,
  fileUrl,
  getDescriptionEditorState,
} from '../../../../utils';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../widget/use-pagination/pagination';
import { LoaderCommon, LoadingPrograms } from '../../../widget/loader';
import { rootState } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import "../../events/Events.scss";

type IDirectory = {
  selectedType: Array<number>;
  selectedLocation: Array<number>;
  selectedSolution: Array<number>;
};

const constante = {
  DEVINSIDER: 'DEVINSIDER',
};

const BlocDirectory = (props: IDirectory) => {
  const history = useHistory();
  const userReducer = useSelector((state: rootState) => state.userReducer);

  const providerDirectoryProgram = UseDirectoryProgram({
    selectedType: props?.selectedType,
    selectedSolution: props?.selectedSolution,
    selectedLocation: props?.selectedLocation,
  });

  function createMarkup(html: string) {
    return {
      __html: html,
    };
  }

  const handleMatchContact = () => {
    //check if user connected, if so => match making else signin page
    if (userReducer?.token !== '') {
      history.push('/programs/program-matching');
    } else {
      history.push('/signin');
    }
  };

  function openBlock(e: any) {
    const elementH3 = e.target;
    if (elementH3.classList.contains('open')) {
      elementH3.classList.remove('open');
    } else {
      elementH3.classList.add('open');
    }
  }

  function moreInfoEvent(element: any) {
    const elementLi: any = document.getElementById(
      element.target.getAttribute('data-id')
    );
    if (elementLi.classList.contains('open')) {
      elementLi.classList.remove('open');
      element.target.innerHTML = 'Show more';
    } else {
      elementLi.classList.add('open');
      element.target.innerHTML = 'Show less';
    }
  }

  return (
    <div className='container mx-auto minHeightwin'>
      <div className='listOfIsv'>
        <div className='headerOfList'>
          <div className='leftHead'>
            <div className='number'>
              {providerDirectoryProgram?.programObject?.total > 0 ? (
                <>
                  Showing{' '}
                  <b>{providerDirectoryProgram?.programObject?.total || ''}</b>{' '}
                  Programs
                </>
              ) : (
                <>No results</>
              )}
            </div>
            <div className='formContentOpen' onClick={(e) => openBlock(e)}>
              <input
                placeholder='Search...'
                type='text'
                onClick={(e) => {
                  e.stopPropagation();
                }}
                value={providerDirectoryProgram?.search}
                onChange={(e) =>
                  providerDirectoryProgram?.setSearch(e?.target?.value)
                }
                onKeyPress={providerDirectoryProgram?.handleSearch}
              />
            </div>
          </div>
        </div>
        <div className='scrollContainer'>
          <div className='firstIn justForProfil'>
            Devinsider allows Software vendors to match with hundreds of
            partnership programs! Match with OEMs, Ecosystems and ISV channel
            Programs based on your company profile and preferences. Easily
            compare different partnership offers, including the benefits and how
            get started. Directly contact the right program representative for
            onboarding.
            {/* {userReducer?.token && (
              <button className="btn" onClick={() => handleMatchContact()}>
                Match & contact Program holders
              </button>
            )} */}
          </div>

          {providerDirectoryProgram?.loader ? (
            <LoadingPrograms />
          ) : (
            <ul className='listOfIsvMatch listEvents'>
              {providerDirectoryProgram?.programObject?.programs?.map(
                (el: IProgram, key: number) => (
                  <li id={'li' + key}>
                    <div className='isvLogo'>
                      <img src={fileUrl(el?.photo_profil)} alt='' />
                    </div>
                    <div className='isvInfo'>
                      <div className='infoUnlocked'>
                        <div className='calendar'>
                          <div className='item'>
                            <div className='date'>
                              <div className='eventInfos direct'>
                                <h3 className='title DIMemberTitle'>
                                  {el?.program_name}
                                  {/* Miaro ticket 46657 el?.origin === constante?.DEVINSIDER && (
                                    <div className="activeDIMember">
                                      <span className="icon-check"></span>
                                      <span>Active devinsider member</span>
                                    </div>
                                  )*/}
                                </h3>
                                <div className='infoProgram'>
                                  <div className='company'>
                                    <span className='semi-bold'>COMPANY</span>{' '}
                                    {el?.company_name}
                                  </div>
                                  <div className='headquarter'>
                                    <span className='semi-bold'>
                                      HEADQUARTER
                                    </span>{' '}
                                    {el?.head_quarter}{' '}
                                  </div>
                                  <div className='solution'>
                                    <span className='semi-bold'>SOLUTION</span>
                                    {el?.solutions?.map((el) => (
                                      <span className='topic'>{el?.name}</span>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='infoEventContainer'>
                          {!el?.information_fetched ? (
                            <LoaderCommon />
                          ) : (
                            <>
                              <p>
                                {el?.description?.map((e) => (
                                  <div
                                    dangerouslySetInnerHTML={createMarkup(
                                      convertCommentFromJSONToHTML(
                                        getDescriptionEditorState(e?.html)
                                      )
                                    )}
                                  />
                                ))}
                              </p>
                              <div className='similarPrograms'>
                                <span className='semi-bold'>
                                  {/* Bug #45089 Program directory similar programs */}
                                  SIMILAR PROGRAMS
                                </span>
                                <ul>
                                  {el?.similar_programs &&
                                    el?.similar_programs?.map((e) => (
                                      <li
                                        className='info'
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <a
                                          target={'_blank'}
                                          href={
                                            'programs/home?search=' +
                                            e?.program_name
                                          }
                                        >
                                          <img
                                            src={fileUrl(e?.photo_profil)}
                                            alt={e?.program_name}
                                          />
                                        </a>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className='actionUnlocked'>
                        <div className='btnContent'>
                          <div>
                            {/*Miaro ticket 46657*/}
                            {el?.origin === 'DEVINSIDER' ? (
                              <>
                                {
                                  <button
                                    className='btn btn-devinsider'
                                    onClick={() => handleMatchContact()}
                                  >
                                    Match & Contact
                                  </button>
                                }
                              </>
                            ) : (
                              <a
                                href={
                                  el?.website?.indexOf('http') !== -1
                                    ? el?.website
                                    : 'htpps://' + el?.website
                                }
                                target={'_blank'}
                              >
                                <button className='btn btn-devinsider gray'>
                                  Visit website
                                </button>
                              </a>
                            )}
                          </div>
                          <button
                            data-id={'li' + key}
                            className='btn btn-devinsider view'
                            onClick={(e) => {
                              moreInfoEvent(e);
                              providerDirectoryProgram?.showMore(el);
                            }}
                          >
                            Show more
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          )}
        </div>
        <div className='pagginationCategory fixePagination'>
          <Pagination
            onPageChange={providerDirectoryProgram?.handlePageChange}
            totalCount={providerDirectoryProgram?.programObject?.total}
            pageSize={providerDirectoryProgram?.limit}
            className='pagination'
            currentPage={providerDirectoryProgram?.currentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default BlocDirectory;
