import React, { ChangeEvent } from "react";
import "./Result.scss";
import Header from "../../../widget/index";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ContributionsPage from "./contributions";
import AuthorResult from "./author";
import UseResult from "../../../../controller/community-ctr/bloc-community-ctr/use-result-ctr";
import Footer from "../../../widget/footer/footer";

export default function ResultSearch() {
  const providerResult = UseResult();
  const txtCategory =
    providerResult?.communityReducer?.discussionCategories?.find(
      (el: any) => el?.id?.toString() === providerResult?.categories?.toString()
    )?.title || "All categories";

  return (
    <div>
      <Header />
      <div className="container mx-auto">
        <ul className="breadcrumbPerso">
          <li>
            <a
              href="javascript:;"
              onClick={(e: any) => {
                e?.preventDefault();
              }}
            >
              Community
            </a>
          </li>
          <li>Search</li>
        </ul>
        <div className="searchTopic">
          <form action="" onSubmit={(e) => e?.preventDefault()}>
            <select
              name="Categories"
              onChange={(evt: any) => {
                providerResult?.handleCateGory({ value: evt?.target?.value });
              }}
            >
              <option value="">Select a category</option>
              {providerResult?.communityReducer?.discussionCategories?.map(
                (el: any, index: number) => (
                  <option
                    value={el?.id}
                    key={index}
                    selected={
                      el?.id?.toString() ===
                      providerResult?.categories?.toString()
                    }
                  >
                    {el?.title}
                  </option>
                )
              )}
            </select>
            <div className="inputAndbtn">
              <button
                type="button"
                onClick={() => providerResult?.handleFilterTxt()}
              >
                <span className="icon-search"></span>
              </button>
              <input
                type="text"
                value={providerResult?.searchText}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  providerResult?.filterSearchText(evt?.target?.value);
                }}
              />
            </div>
          </form>
        </div>
        <div className="rightCommunityForum topContainer">
          <div className="block">
            <Tab.Container defaultActiveKey={providerResult?.currentTab}>
              <h2 className="title withLink uppercase">
                Result on :
                <Nav.Link
                  eventKey="contributions"
                  onClick={() => providerResult?.setcurrentTab("contributions")}
                  className={
                    providerResult?.currentTab === "contributions"
                      ? "active"
                      : ""
                  }
                >
                  Contributions
                </Nav.Link>
                <Nav.Link
                  eventKey="user"
                  onClick={() => providerResult?.setcurrentTab("user")}
                  className={
                    providerResult?.currentTab === "user" ? "active" : ""
                  }
                >
                  user
                </Nav.Link>
              </h2>
              <Tab.Content>
                <Tab.Pane eventKey="contributions">
                  <ContributionsPage
                    selectLocationOptions={providerResult?.dataLocation}
                    selectedDateOptions={providerResult?.dateOptions}
                    selectedMetaDataOptions={providerResult?.metaDataOption}
                    selectedTypeContribution={providerResult?.typeContribution}
                    resultContributor={providerResult?.resultContributor}
                    loaderContribution={providerResult?.loaderContributor}
                    handlePageClick={(p: number) =>
                      providerResult?.handlePageClick(p)
                    }
                    totalData={providerResult?.paginateContributor}
                    pageLimit={providerResult?.limit}
                    currentPage={providerResult?.currentPageContributor}
                    handleCateGory={(e: any) =>
                      providerResult?.handleCateGory(e)
                    }
                    handleDate={(e: any) => providerResult?.handleDate(e)}
                    handleMetaData={(e: any) =>
                      providerResult?.handleMetaData(e)
                    }
                    handleContribution={(e: any) =>
                      providerResult?.handleContribution(e)
                    }
                    setAuthorFilter={(p: any) =>
                      providerResult?.setAuthorFilter(p)
                    }
                    promiseOptions={(p: string) =>
                      providerResult?.promiseOptions(p)
                    }
                    categories={txtCategory}
                    goActivity={providerResult?.goActivity}
                    goToreplayPost={providerResult?.goToreplayPost}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="user">
                  <AuthorResult
                    dataUser={providerResult?.allUsers?.users}
                    loadAuthor={providerResult?.loaderAuthor}
                    currentPage={providerResult?.currentPageUser}
                    totalData={providerResult?.allUsers?.totalData}
                    handlePageClick={(p: number) =>
                      providerResult?.loadMoreUser(p)
                    }
                    goActivity={providerResult?.goActivity}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
