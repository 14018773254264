import React, { useState } from "react";
import { useHistory } from "react-router";
import useEventListener from "../../../../hooks/event-handler";

export default function useSearch() {
  const history = useHistory();
  const [selectedCategory, handleCateGory] = useState<string>("");
  const [inputText, settxtFilter] = useState<string>("");

  const handleFilterTxt = () => {
    history?.push("/community/result", {
      selectedCategory,
      inputText,
    });
  };
  const filterSearchText = (txt: string) => {
    settxtFilter(txt);
  };

  const keyEnter = ({ key }: any) => {
    if (key === "Enter") {
      if (selectedCategory || inputText) {
        handleFilterTxt();
      }
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
  };
}
