import React from "react";
import Modal from "react-bootstrap/Modal";
import SoftwareSolutionWidget from "../../../../widget/software-solution-with-details";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import Button from "react-bootstrap/Button";
import GeographyTargetWidget from "../../../../widget/pays";

type IpropsModal = {
    dataSource: any;
};

export default function ModalEditSolution(props : IpropsModal){
    return(
        <Modal
            show={props?.dataSource?.geographyShow}
            onHide={() => props?.dataSource?.cancelChange()}
            className="modalDevinsider large"
        >
            <Modal.Header closeButton>
                <Modal.Title>Geography target market</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div><b>What geographic market do you target?*</b><br/> Select all that apply</div>
                <GeographyTargetWidget
                    {...props?.dataSource?.UseGeographyTarget}
                />
            </Modal.Body>  
            <Modal.Footer>
                <Button
                    className="cancelbtn"
                    onClick={() => props?.dataSource?.cancelChange()}
                >
                Cancel
                </Button>
                <Button
                    className="validate"
                    onClick={() => props?.dataSource?.updateCompanyResellerDetails("country")}
                    disabled={props?.dataSource?.isLoaderShow}
                >
                {props?.dataSource?.isLoaderShow ? <Loader /> : "Save"}
                </Button> 
            </Modal.Footer>  
        </Modal>
    );
}