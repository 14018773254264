import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { remove_couverture_company_page, remove_logo_company_page, updateCompanyPageAction } from "../../../redux/actions/company-page-isv-action/current_isv_action";
import { rootState } from "../../../redux/reducers";
import { postDataFormDataService } from "../../../service/applicatif/company-page-investor";
import { url as URL } from "../../../service/constant";

export default function UseModalPhoto() {
  const [isShowUpdateProfil, setIsShowUpdateProfil] = useState<boolean>(false);
  const [isShowUpdateBackGroundPicture, setIsShowUpdateBackGroundPicture] =
    useState<boolean>(false);
  const fileRefProfil: React.RefObject<HTMLInputElement> = useRef(null);
  const [selectedFilePicture, setSelectedFilePicture] = useState<File>();
  const [isFilePickedPicture, setIsFilePickedPicture] = useState(false);
  const [showPopupRemoveLogo, setShowPopupRemoveLogo] =
    useState<boolean>(false);
  const [showPopupRemoveCouverture, setShowPopupRemoveCouverture] =
    useState<boolean>(false);
  const [processingRemovePhoto, setProcessingRemovePhoto] =
    useState<boolean>(false);

  let companyPageObject = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  let userObject = useSelector((state: rootState) => state.userReducer);
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();

  //event click bouton and lunch pick image in type image , fileRef is ref of img
  const handleBtnPicture = () => {
    if (fileRefProfil) {
      fileRefProfil?.current?.click();
    }
  };

  const changeHandlerProfil = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      if (event?.target?.files[0]) {
        setSelectedFilePicture(event?.target?.files[0]);
        setIsFilePickedPicture(true);
      }
    }
  };
  const hideSelectedFilePicture = () => {
    setSelectedFilePicture(undefined);
    if (isShowUpdateProfil) {
      setIsShowUpdateProfil(false);
    }
    if (isShowUpdateBackGroundPicture) {
      setIsShowUpdateBackGroundPicture(false);
    }
  };

  const restorePhotoChange = () => {
    setSelectedFilePicture(undefined);
  };

  const cbUpdateProfile = (data: any) => {
    setLoader(false);
    if (isShowUpdateProfil) {
      setIsShowUpdateProfil(false);
    }
    if (isShowUpdateBackGroundPicture) {
      setIsShowUpdateBackGroundPicture(false);
    }
  };

  const updateProfilPicture = () => {
    let params = {
      photoProfil: selectedFilePicture,
    };
    let paramsBackGroung = {
      photoCouverture: selectedFilePicture,
    };
    setLoader(true);
    dispatch(
      updateCompanyPageAction(
        isShowUpdateProfil ? params : paramsBackGroung,
        companyPageObject.id,
        cbUpdateProfile
      )
    );
  };

  const handleRemoveLogoCompanyPage = async () => {
    setProcessingRemovePhoto(true);
    //update company page
    const response = await postDataFormDataService(
      {
        "_photoProfile": "1",
      },
      URL.company.updateCompanyPage + "/" + companyPageObject.id,
      userObject.token
    );
    if (response?.status === 200) {
      //update redux
      dispatch(remove_logo_company_page(response?.data?.photo_profil));
    }
    setProcessingRemovePhoto(false);
    setShowPopupRemoveLogo(false);
  };

  const handleRemoveCouvertureCompanyPage = async () => {
    setProcessingRemovePhoto(true);
    //update company page
    const response = await postDataFormDataService(
      {
        "_photoCouverture": "1",
      },
      URL.company.updateCompanyPage + "/" + companyPageObject.id,
      userObject.token
    );
    if (response?.status === 200) {
      //update redux
      dispatch(remove_couverture_company_page(response?.data?.photo_couverture));
    }
    setProcessingRemovePhoto(false);
    setShowPopupRemoveCouverture(false);
  };


  return {
    isShowImage: isShowUpdateProfil
      ? isShowUpdateProfil
      : isShowUpdateBackGroundPicture,
    largeDesign: isShowUpdateBackGroundPicture,
    fileRef: fileRefProfil,
    changeHandler: changeHandlerProfil,
    hideSelectedFile: hideSelectedFilePicture,
    selectedFile: selectedFilePicture,
    handleBtn: handleBtnPicture,
    fileUrl: isShowUpdateProfil
      ? companyPageObject?.photo_profil
      : companyPageObject?.photo_couverture,
    updateUserDetail: updateProfilPicture,
    isLoaderShow: loader,
    setShowImage: isShowUpdateProfil
      ? setIsShowUpdateProfil
      : setIsShowUpdateBackGroundPicture,
    restorePhotoChange: restorePhotoChange,
    setIsShowUpdateBackGroundPicture,
    setIsShowUpdateProfil,
    showPopupRemoveLogo,
    setShowPopupRemoveLogo,
    showPopupRemoveCouverture,
    setShowPopupRemoveCouverture,
    processingRemovePhoto,
    handleRemoveLogoCompanyPage,
    handleRemoveCouvertureCompanyPage,
  };
}
