import React, { ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ReactComponent as Loader } from '../../../../../../../../resources/images/Rolling.svg';
import { IpropsModal } from './type';
import { refaCtoNullKey } from '../../../../../../../../utils';

export default function ModalEditOverview(props: IpropsModal) {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.hideModal()}
      className='modalDevinsider large'
    >
      <Modal.Header closeButton>
        <Modal.Title>Company overview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='contentBorder row m-0 p-0'>
          <div className='col-12 descCompany'>
            <textarea
              name=''
              value={refaCtoNullKey(props?.companyOverview, '')}
              onChange={(evt: ChangeEvent<HTMLTextAreaElement>) =>
                props?.handleCompanyOverview(evt.target.value)
              }
              maxLength={450}
            />
            {props?.companyOverview != 'null' && (
              <div className='caractercount'>
                {`${0 + (props?.companyOverview?.length || 0)} / 450`}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='cancelbtn' onClick={() => props?.cancelChange()}>
          Cancel
        </Button>
        <Button
          className='validate'
          onClick={() => props?.updateInfoOverview()}
          disabled={props?.isLoaderShow}
        >
          {props?.isLoaderShow ? <Loader /> : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
