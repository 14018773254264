import { IAction } from '../../redux/types/acount-interface';
import {
  actionType,
  handleFilterType,
  helperHandleMuteConversation,
  helperHandleReadConversation,
  helperHandleSeeConversation,
  helperRemove,
  helperUpdateMessage,
  IPropsDiscussion,
} from './type';

export const reducer = (
  state: IPropsDiscussion,
  action: IAction
): IPropsDiscussion => {
  switch (action.type) {
    case actionType.GET_INFO_CONVERSATION: {
      return {
        ...state,
        currentInfoToSendMessage: {
          sendTo: action.payload?.sendTo,
          discussionType: action.payload.type,
          idConversation: action.payload?.result?.id_conversation,
        },
        [handleFilterType(action.payload?.type)]: {
          infoConversation: action.payload?.result,
        },
      };
    }

    case actionType.GET_INFO_CONVERSATION_ON_CLICK: {
      return {
        ...state,
        currentInfoToSendMessage: {
          sendTo: action.payload?.sendTo,
          discussionType: action.payload.type,
          idConversation: action.payload?.result?.id_conversation,
        },
        [handleFilterType(action.payload?.type)]: {
          infoConversation: {
            id_conversation: action?.payload?.result?.id_conversation,
            informations_send_to: {
              ...action.payload?.result,
            },
          },
        },
      };
    }

    case actionType.GET_UNREAD_CATEGORY: {
      return {
        ...state,
        unreadeInCategory: action.payload,
      };
    }

    case actionType.GET_ALL_CONVERSATION: {
      return {
        ...state,
        allDiscussion: action.payload,
        // state.allDiscussion.length == 1
        //   ? action.payload
        //   : [...state.allDiscussion, ...action.payload],
      };
    }

    case actionType.HANDLE_CONVERSATION_IN_MERCURE: {
      return {
        ...state,
        allDiscussion: [...action.payload],
      };
    }

    case actionType.GET_ALL_MESSAGE: {
      return {
        ...state,
        paginateMessage: {
          messages:
            state.paginateMessage.messages.length == 1
              ? action.payload
              : [...action.payload, ...state.paginateMessage.messages],
          page: 1,
        },
      };
    }

    case actionType.HANDLE_CONVERSATIO_ID: {
      return {
        ...state,
        currentInfoToSendMessage: {
          ...state.currentInfoToSendMessage,
          idConversation: action.payload,
        },
      };
    }

    case actionType.SET_MESSAGE: {
      console.log(
        'SET_MESSAGE',
        action.payload,
        state.paginateMessage.messages
      );
      return {
        ...state,
        paginateMessage: {
          messages: helperUpdateMessage(
            state.paginateMessage.messages,
            action.payload
          ),
          page: 0,
        },
      };
    }

    case actionType.HANDLE_CURRENT_INFO_MESSAGE: {
      return {
        ...state,
        currentInfoToSendMessage: {
          sendTo: action.payload.sendTo,
          discussionType: action.payload.discussionType,
          idConversation: action.payload.idConversation,
        },

        paginateMessage: {
          messages: [],
          page: 0,
        },
        allFilePerDiscussion: [],
      };
    }

    case actionType.LOAD_MORE_MESSAGE: {
      return {
        ...state,
        paginateMessage: {
          messages: [
            ...action.payload.allMessage,
            ...state.paginateMessage.messages,
          ],
          page: action.payload.currentPage,
        },
      };
    }

    case actionType.RESTORE_CURRENT_MESSAGE_INFO: {
      return {
        ...state,
        paginateMessage: {
          messages: [],
          page: 0,
        },
        allDiscussion: [],
        allFilePerDiscussion: [],
        currentInfoToSendMessage: {
          sendTo: 0,
          discussionType: '',
          idConversation: 0,
        },
      };
    }

    case actionType.SET_OEM_PARTICIPANT: {
      return {
        ...state,
        oem_to_isv: {
          ...state.oem_to_isv,
          infoConversation: {
            ...state.oem_to_isv?.infoConversation,
            informations_send_to: {
              ...state?.oem_to_isv?.infoConversation?.informations_send_to,
              program_representatives: action.payload,
            },
          },
        },
        isv_to_oem: {
          ...state.isv_to_oem,
          infoConversation: {
            ...state.isv_to_oem?.infoConversation,
            informations_send_to: {
              ...state.isv_to_oem.infoConversation?.informations_send_to,
              representative: action.payload,
            },
          },
        },
      };
    }

    case actionType.REMOVE_CONVERSATION: {
      return {
        ...state,
        allDiscussion: helperRemove(state.allDiscussion, action.payload),
        paginateMessage: {
          messages: [],
          page: 0,
        },
      };
    }

    case actionType.ADD_ARCHIVE: {
      return {
        ...state,
        unreadeInCategory: {
          ...state.unreadeInCategory,
          NUMBER_ARCHIVE: state.unreadeInCategory?.NUMBER_ARCHIVE + 1,
        },
      };
    }

    case actionType.HANDLE_SEE_CONVERSATION: {
      return {
        ...state,
        allDiscussion: helperHandleSeeConversation(
          state.allDiscussion,
          action.payload
        ),
      };
    }

    case actionType.HANDLE_MUTED: {
      return {
        ...state,
        allDiscussion: helperHandleMuteConversation(
          state.allDiscussion,
          action.payload
        ),
      };
    }

    case actionType.HANDLE_READ: {
      return {
        ...state,
        allDiscussion: helperHandleReadConversation(
          state.allDiscussion,
          action.payload
        ),
      };
    }

    case actionType.GET_FILE_PER_CONVERSATION: {
      return {
        ...state,
        allFilePerDiscussion: action.payload,
      };
    }

    case actionType.REMOVE_ONE_ARCHIVE: {
      return {
        ...state,
        unreadeInCategory: {
          ...state.unreadeInCategory,
          NUMBER_ARCHIVE:
            state.unreadeInCategory?.NUMBER_ARCHIVE > 0
              ? state.unreadeInCategory?.NUMBER_ARCHIVE - 1
              : state.unreadeInCategory?.NUMBER_ARCHIVE,
        },
      };
    }

    case actionType.REMOVE_ONE_REQUEST: {
      return {
        ...state,
        unreadeInCategory: {
          ...state.unreadeInCategory,
          NUMBER_REQUEST:
            state.unreadeInCategory?.NUMBER_REQUEST > 0
              ? state.unreadeInCategory?.NUMBER_REQUEST - 1
              : state.unreadeInCategory?.NUMBER_REQUEST,
        },
        paginateMessage: {
          messages: [],
          page: 1,
        },
      };
    }

    case actionType.REMOVE_ALL_CONVERSATION: {
      return {
        ...state,
        allDiscussion: [],
      };
    }

    case actionType.HANDLE_NBR_REQUEST: {
      return {
        ...state,
        unreadeInCategory: {
          ...state.unreadeInCategory,
          NUMBER_REQUEST: state?.unreadeInCategory.NUMBER_REQUEST + 1,
        },
      };
    }

    case actionType.RESTORE_STATE_FILTER_CONVERSATION: {
      return {
        ...state,
        currentInfoToSendMessage: {
          sendTo: 0,
          discussionType: '',
          idConversation: 0,
        },
        allDiscussion: [],
        paginateMessage: {
          messages: [],
          page: 0,
        },
      };
    }

    case actionType.RESTORE_MESSAGE_CURRENT_DISCUSSION: {
      return {
        ...state,
        paginateMessage: {
          messages: [],
          page: 0,
        },
      };
    }

    case actionType.SET_MESSAGE_FILTER: {
      return {
        ...state,
        paginateMessage: {
          messages: [...action.payload],
          page: 1,
        },
      };
    }
    case actionType.PAGINATE_SEARCH_MESSAGE: {
      return {
        ...state,
        paginateMessage: {
          messages:
            action.payload.typeScroll === 'top'
              ? [...action.payload.data, ...state.paginateMessage.messages]
              : [...state.paginateMessage.messages, ...action.payload.data],
          page: 1,
        },
      };
    }

    default:
      return state;
  }
};
