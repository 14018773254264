/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { INVESTOR } from "../../../service/constant/index";
import { signupInvestorAccount } from "../../../service/applicatif/acount";
import useEventListener from "../../../hooks/event-handler";
import { clearSessionObject, getSStorage } from "../../../utils";
import { useDispatch } from "react-redux";

export default function UseCompletePublishIvestor() {
  const history = useHistory();
  const [wantToShow, setWantToShow] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);

  const valideAcount = async () => {
    setLoader(true);
    let data = {
      firstName: getSStorage("firstNameInvestor"),
      lastName: getSStorage("lastNameInvestor"),
      email: getSStorage("emailInvestor") || "",
      password: getSStorage("passwordIvestor") || "",
      roles: INVESTOR,
      country: getSStorage("locationInvestor"),
      jobTitle: getSStorage("jobTitileInvestor"),
      companyName: getSStorage("organisationNameInvestor"),
      linkedin: getSStorage("urlLinkDeanInvestor"),
      city: getSStorage("cityInvestor"),
      phone: getSStorage("phoneValue"),
      phoneNumberCode: getSStorage("phoneSelected"),
      dialCode: getSStorage("dialCode"),
      "--": "--",
      investorType: JSON.parse(getSStorage("toogleActive") || "true") ? 1 : 2,
      institutionalInvestorType: getSStorage("investorType") || null,
      numberOfMembers: getSStorage("numberMemberInvestor")
        ? getSStorage("numberMemberInvestor")
        : 55,
      _jobTitle: getSStorage("jobTitileInvestor"),
      organizationName: getSStorage("organisationNameInvestor"),
      _email: getSStorage("emailAdresseInvestor"),
      phoneNumber: getSStorage("phoneValue"),
      _phoneNumberCode: getSStorage("phoneSelected"),
      website: getSStorage("webSiteInvestor"),
      _linkedin: getSStorage("urlLinkDeanInvestor"),
      _country: getSStorage("locationInvestor"),
      location: getSStorage("cityInvestor"),
      investorStrategicPartnership: JSON.stringify(
        JSON.parse(getSStorage("strategicPartenerInvestor") || "[]")?.map(
          (el: any) => el.id
        )
      ),
      targetedCountry: JSON.stringify(
        JSON.parse(getSStorage("pays_company_investor") || "[]")?.map(
          (el: any) => el.countrId
        )
      ),
      minCompanySize: JSON.parse(getSStorage("companySizeSlide") || "[]")[0],
      maxCompanySize: JSON.parse(getSStorage("companySizeSlide") || "[]")[1],
      minAnnualTurnover:
        getSStorage("unitTurnover") == "K USD"
          ? JSON.parse(getSStorage("annualTurnoverSlide") || "[]")[0] * 1000
          : getSStorage("unitTurnover") == "M USD"
          ? JSON.parse(getSStorage("annualTurnoverSlide") || "[]")[0] * 1000000
          : JSON.parse(getSStorage("annualTurnoverSlide") || "[]")[0] * 1000,
      maxAnnualTurnover:
        getSStorage("unitTurnoverMax") == "K USD"
          ? JSON.parse(getSStorage("annualTurnoverSlide") || "[]")[1] * 1000
          : getSStorage("unitTurnoverMax") == "M USD"
          ? JSON.parse(getSStorage("annualTurnoverSlide") || "[]")[1] * 1000000
          : JSON.parse(getSStorage("annualTurnoverSlide") || "[]")[1] * 1000,
      investorFundingRound: JSON.stringify(
        JSON.parse(getSStorage("foundingRound") || "[]").map((el: any) => el.id)
      ),
      minFundraisingGoal:
        getSStorage("unitFundraisingGoal") == "K USD"
          ? JSON.parse(getSStorage("fundraisingGoalSlide") || "[]")[0] * 1000
          : getSStorage("unitFundraisingGoal") == "M USD"
          ? JSON.parse(getSStorage("fundraisingGoalSlide") || "[]")[0] * 1000000
          : JSON.parse(getSStorage("fundraisingGoalSlide") || "[]")[0] * 1000,
      maxFundraisingGol:
        getSStorage("unitFundraisingGoalMax") == "K USD"
          ? JSON.parse(getSStorage("fundraisingGoalSlide") || "[]")[1] * 1000
          : getSStorage("unitFundraisingGoalMax") == "M USD"
          ? JSON.parse(getSStorage("fundraisingGoalSlide") || "[]")[1] * 1000000
          : JSON.parse(getSStorage("fundraisingGoalSlide") || "[]")[1] * 1000,
      segmentDetails: JSON.stringify(
        JSON.parse(getSStorage("solutionCheckedFeatures") || "[]")?.map(
          (el: any) => el
        )
      ),
      segments: JSON.stringify(
        JSON.parse(getSStorage("solution_company_investor") || "[]")?.map(
          (el: any) => el
        )
      ),
      targetedIndustry: JSON.stringify(
        JSON.parse(getSStorage("company_industry_investor") || "[]")?.map(
          (el: any) => el.id
        )
      ),
      isvFindMe: wantToShow ? 1 : 0,
      _dialCode: getSStorage("dialCode"),
    };
    let response = await signupInvestorAccount(data);
    if (response && response.status === 200) {
      clearSessionObject();
      history.push("./complete-account", { email: data?.email });
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key === "Enter") {
      valideAcount();
    } else return;
  };

  useEventListener("keydown", keyEnter);

  return {
    loader,
    valideAcount,
    wantToShow,
    setWantToShow,
  };
}
