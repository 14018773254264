import { constante } from "../../actions/find-investor-action";
import { IAction, IFindInvestor } from "../../types/find-investor-interface";

const initalState: IFindInvestor = {
  investor_type: [],
  hq_location: [],
  solution_specialization: [],
  industry_specialization: [],
};

export const findInvestorReducer = (
  state = initalState,
  action: IAction
): IFindInvestor => {
  switch (action?.type) {
    case constante.GET_FILTERS:
      return {
        ...state,
        ...action?.payload,
      };
    default:
      return state;
  }
};
