/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { annee } from "../../../../../../utils/index";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
//Type formulaire for edit user
import Modal from "react-bootstrap/Modal";
import { FormHeaderAbout } from "../../header/Headerform";

export const CreateCompanyForm2 = (props: T.InputProps) => {
  return (
    <Modal show={props?.showStep2} className="createCompanypopup smallPopup">
      <form onSubmit={props?.handleSubmitForm2(props?.submitForm2)}>
        <Modal.Header>
          <FormHeaderAbout />
          <a
            href="javascript:;"
            className="close back"
            onClick={props?.handleBackStep1}
          >
            &nbsp;
          </a>

          <a
            href="javascript:;"
            className="close"
            onClick={props?.handleCloseStep2}
          >
            <span className="icon-close-pop"></span>
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="bodyCreateCompany">
            <div className="contentBorder row">
              <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0 ">
                <label htmlFor="">
                  Headquarters location
                  {props.errors.hQLocation && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>

                <select
                  className="custom-select"
                  {...props.register("hQLocation", {
                    required: props.required,
                  })}
                  disabled={props.dataCity?.length < 1}
                >
                  <option value="">Country...</option>
                  {props.dataCity &&
                    props.dataCity.map((el: T.Tcountry, index: number) => (
                      <option value={el.id} key={index}>
                        {el.country_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
                <label htmlFor="">&nbsp;</label>
                <input
                  type="text"
                  className="input-text form-control"
                  placeholder="City..."
                  {...props.register("city")}
                />
              </div>
              <div className="form-group required col-xl-6 col-md-6 col-sm-12 pr-xl-2 pr-md-2  p-0 ">
                <label htmlFor="">
                  Company size
                  {props.errors.companySize && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
                <select
                  className="custom-select"
                  {...props.register("companySize", {
                    required: props.required,
                  })}
                >
                  <option value="">Select an option</option>
                  {props.companySizeData &&
                    props.companySizeData.map((el: any, index: number) => {
                      return (
                        <option value={JSON.stringify(el)} key={index}>
                          {Object.keys(el)}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group required col-xl-6 col-md-6 col-sm-12 p-0 pl-xl-2 pl-md-2">
                <label htmlFor="">
                  Annual revenue
                  {props.errors.annualTurnover && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
                <select
                  className="custom-select"
                  {...props.register("annualTurnover", {
                    required: props.required,
                  })}
                >
                  <option value="">Select an option</option>
                  {props.annualTurnoverData &&
                    props.annualTurnoverData.map((el: any, index: number) => (
                      <option value={JSON.stringify(el)} key={index}>
                        {Object.keys(el)}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-12 required">
                <label htmlFor="">
                  Year founded
                  {props.errors.foundingDate && (
                    <div className="container-error pl-2 d-inline">
                      {/* change d-inline on d-none */}
                      <span className="error-red"> error </span>
                      <span className="txt-error">This field is required</span>
                    </div>
                  )}
                </label>
                <select
                  className="custom-select"
                  {...props.register("foundingDate", {
                    required: props.required,
                  })}
                >
                  <option value="">Select a date</option>
                  {annee &&
                    annee().map((el: any, index: number) => (
                      <option value={el?.value} key={index}>
                        {el?.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder">
            <button
              className="btn btn-devinsider large containerBtnBtn"
              type="submit"
            >
              Next
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
