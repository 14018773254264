import React, { ChangeEvent } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { concatString, isURL, refaCtoNullKey } from "../../../../../utils";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

export type IpropsModal = {
  dataSource: any;
};
export default function ModalEditAudience(props: IpropsModal) {
  return (
    <Modal
      show={props?.dataSource?.audienceShow}
      onHide={() => props?.dataSource?.setHideShowAudiance()}
      className="modalDevinsider medium"
    >
      <Modal.Header closeButton>
        <Modal.Title>Select audience</Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-negatif">
        <div className="contentBorder row p-0 m-0">
          <ul className="audience">
            <li>
              <div className="iconContent">
                <span className="icon-userIcon"></span>
              </div>
              <div className="label">
                <div className="textforAudience">
                  Investors and technology partners can view this information
                </div>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="audience"
                  value="all"
                  id="all"
                  checked={
                    props?.dataSource?.audienceClicked == "primary_email"
                      ? !props?.dataSource?.lockPrimaryEmail
                      : props?.dataSource?.audienceClicked == "worked_email"
                      ? !props?.dataSource?.lockWorkedEmail
                      : !props?.dataSource?.lockContact
                  }
                  onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                    props?.dataSource?.checkAudienceType(0)
                  }
                />
                <label htmlFor="all" className="custom-control-label">
                  &nbsp;
                </label>
              </div>
            </li>
            <li>
              <div className="iconContent">
                <span className="icon-locked"></span>
              </div>
              <div className="textforAudience">Only me</div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="audience"
                  value="me"
                  id="me"
                  checked={
                    props?.dataSource?.audienceClicked == "primary_email"
                      ? props?.dataSource?.lockPrimaryEmail
                      : props?.dataSource?.audienceClicked == "worked_email"
                      ? props?.dataSource?.lockWorkedEmail
                      : props?.dataSource?.lockContact
                  }
                  onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                    props?.dataSource?.checkAudienceType(1)
                  }
                />
                <label htmlFor="me" className="custom-control-label">
                  &nbsp;
                </label>
              </div>
            </li>
          </ul>
          {props?.dataSource?.isErrorLocked && (
            <div className="container-error pl-2 d-inline">
              <span className="error-red"> ERROR </span>
              <span className="txt-error">
                At least one (1) email address must be visible for technology
                parteners and investors.
              </span>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancelbtn"
          onClick={() => props?.dataSource?.setHideShowAudiance()}
        >
          Cancel
        </Button>

        <Button
          className="validate"
          onClick={() => props?.dataSource?.saveAudienceChoix()}
          disabled={props?.dataSource?.isLoaderShow}
        >
          {props?.dataSource?.isLoaderShow ? <Loader /> : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
