import React from "react";
import SearchForm from "../../../../widget/community/search/index";

export type IpropsTitle = {
  provider: any;
  goHome: () => void;
  goDiscussionCategorie: () => void;
  goFilter: () => void;
  categories: Array<any>;
  handleCateGory: (evt: any) => void;
  handleFilterTxt: () => void;
  searchText: string;
  filterSearchText: (params: string) => void;
};

export default function tittleHeader(props: IpropsTitle) {
  return (
    <>
      {props?.provider?.title && (
        <ul className="breadcrumbPerso">
          <li onClick={() => props?.goHome()}>
            <a href="javascript:;">Community</a>
          </li>
          <li onClick={() => props?.goDiscussionCategorie()}>
            <a href="javascript:;">Discussion categories</a>
          </li>
          <li onClick={() => props?.goFilter()}>
            <a href="javascript:;">{props?.provider?.post_type?.type}</a>
          </li>
          <li>{props?.provider?.title}</li>
        </ul>
      )}

      <div className="searchTopic">
        <SearchForm
          categories={props?.categories}
          filterSearchText={props?.filterSearchText}
          handleFilterTxt={props?.handleFilterTxt}
          searchText={props?.searchText}
          handleCateGory={props?.handleCateGory}
        />
      </div>
    </>
  );
}
