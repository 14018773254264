import "./classifiedAds.scss";
import React from "react";
import Header from "../../widget/index";
import "./classifiedAds.scss";
import UseListing from "../../../controller/classified-ads-ctr/listing-ctr";
import { IAd } from "../../../redux/types/classified-ads-interface";
import AdsItem from "../../widget/ads";
import { LoaderCommon } from "../../widget/loader";

export default function ListingClassifiedAds() {
  const providerClassifiedAds = UseListing();
  const isAllAddsIsGet =
    providerClassifiedAds?.classifiedAdsReducer?.my_classified_ads?.list
      ?.length <
    providerClassifiedAds?.classifiedAdsReducer?.my_classified_ads?.total;

  return (
    <div>
      <Header />
      <section className="classified">
        <div className="container mx-auto">
          <h2 className="title outsideFinds">Marketplace: Listings</h2>
          <div className="freshfinds">
            {providerClassifiedAds?.dataFetched ? (
              <div className="listFresh">
                {providerClassifiedAds?.classifiedAdsReducer?.my_classified_ads?.list?.map(
                  (el: IAd, key: number) => (
                    <AdsItem
                      key={key}
                      setShowLogin={providerClassifiedAds?.setShowLogin}
                      ads={el}
                      goToCategory={(idCategory: string) =>
                        providerClassifiedAds.goToCategoryPage(idCategory)
                      }
                    />
                  )
                )}
                {isAllAddsIsGet && (
                  <div className="linkView">
                    {providerClassifiedAds?.loader ? (
                      <LoaderCommon />
                    ) : (
                      <a
                        href="javascript:;"
                        className={`viewAll ${
                          providerClassifiedAds?.loader && `disabled`
                        }`}
                        onClick={providerClassifiedAds?.handleViewMore}
                      >
                        {providerClassifiedAds?.loader ? (
                          <LoaderCommon />
                        ) : (
                          "View more"
                        )}
                      </a>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <LoaderCommon />
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
