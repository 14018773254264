import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  getSStorage,
  isEmail,
  isRequired,
  length,
  setSessionStorageObject,
} from '../../../utils';
import { checkIfEmailExist } from '../../../service/applicatif/acount';
import useEventListener from '../../../hooks/event-handler';
import { useCompleteDataStorageOem } from './use-complete-data-storage-oem';
import { url as URL } from '../../../service/constant';
import { getData } from '../../../service/api';
const queryString = require('query-string');

export default function UseCompleteOemAccount() {
  const history = useHistory();
  let params: any = useLocation();
  const [tokenId, setTokenId] = useState(
    queryString.parse(params?.search)?.token
  );
  const { dataOem, addDataOem } = useCompleteDataStorageOem();

  const [firstNameOem, setFirstName] = useState<string>(
    dataOem.firstName || ''
  );
  const [lastNameOem, setLastName] = useState<string>(dataOem.lastName || '');
  const [emailOem, setEmail] = useState<string>(dataOem.email || '');
  const [passwordOem, setPassword] = useState<string>(dataOem.password || '');
  const [confirmPasswordOem, setConfirmPassword] = useState<string>(
    getSStorage('confirmPasswordOem') || ''
  );
  const [acceptCguOem, setAcceptCgu] = useState<boolean>(
    getSStorage('acceptCguOem') ? true : false
  );

  /*
   *Error form
   */
  const [errorFirstNameOem, setErrorFirstNameOem] = useState<boolean>(false);
  const [errorLastNameOem, setErrorLastNameOem] = useState<boolean>(false);
  const [errorEmailOem, setErrorEmailOem] = useState<boolean>(false);
  const [errorPasswordOem, setErrorpasswordOem] = useState<boolean>(false);
  const [errorConfirmPasswordOem, setErrorConfirmPasswordOem] =
    useState<boolean>(false);
  const [errorAcceptCguOem, setErrorAcceptCguOem] = useState<boolean>(false);

  /*
   * Loader Email check
   */
  const [loader, setloader] = useState<boolean>(false);

  /*
   *Error email alreasy use
   */
  const [emailSafe, setEmailSafe] = useState<boolean>(false);

  /*
   * Email false to show
   */
  const [emailNotSafe, setEmailNotSafe] = useState<boolean>(false);

  /*
   * Text message error
   */
  const [txtEmailError, setTxtEmailError] = useState('');
  const [txtPasswordError, setTxtPasswordError] = useState('');

  //initial load
  useEffect(() => {
    if (tokenId === undefined) {
      //redirect to get started
      history.push('/partner-program-registration');
    } else {
      //verify  token, if token ok continue, otherwise redirect to get started
      (async () => {
        const response = await getData(
          URL.oem.check_oem_program_token_before_creation + '/' + tokenId,
          ''
        );
        if (response?.status === 200) {
          setFirstName(response?.data?.first_name);
          setLastName(response?.data?.last_name);
          setEmail(response?.data?.email);
        } else {
          history.push('/partner-program-registration');
        }
      })();
    }
  }, []);

  /*
  Remove error when value change
  */
  useEffect(() => {
    setErrorFirstNameOem(false);
    return () => {};
  }, [firstNameOem]);

  useEffect(() => {
    setErrorLastNameOem(false);
    return () => {};
  }, [lastNameOem]);

  useEffect(() => {
    setErrorpasswordOem(false);
    return () => {};
  }, [passwordOem]);

  useEffect(() => {
    setErrorConfirmPasswordOem(false);
    return () => {};
  }, [confirmPasswordOem]);

  useEffect(() => {
    setErrorEmailOem(false);
    setEmailNotSafe(false);
    setTxtEmailError('');
    return () => {};
  }, [emailOem]);

  useEffect(() => {
    if (acceptCguOem) {
      setErrorAcceptCguOem(false);
    }
  }, [acceptCguOem]);

  useEffect(() => {
    setEmailSafe(false);
    setEmailNotSafe(false);
  }, [emailOem]);

  /*
   * Valid email
   * params string
   * return boolean
   */
  const validEmail = (email: string): boolean => {
    if (isEmail(email) && isRequired(email)) {
      return true;
    } else {
      setErrorEmailOem(true);
      return false;
    }
  };

  /*
   * Valid firstname
   * params string
   * return boolean
   */
  const validFirstName = (txt: any) => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErrorFirstNameOem(true);
      return false;
    }
  };

  /*
   * Valid lastName
   * params lastName
   * return boolean
   */
  const validLastName = (txt: string): boolean => {
    if (isRequired(txt)) {
      return true;
    } else {
      setErrorLastNameOem(true);
      return false;
    }
  };

  /*
   * Valid passsword
   * params passsword
   * return boolean
   */
  const validPassword = (txt: string): boolean => {
    if (
      isRequired(txt) &&
      passwordOem.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{12,}$/
      )
    ) {
      return true;
    } else {
      setErrorpasswordOem(true);
      return false;
    }
  };

  /*
   * Check if an email already use
   * paarams email adress string
   */
  const checkIfAlreadyEmailExist = async (email: string) => {
    if (validEmail(email)) {
      setloader(true);
      let response = await checkIfEmailExist(email, true);
      if (response?.status == 200 && response?.data?.details) {
        setloader(false);
        setEmailNotSafe(true);
        setTxtEmailError('This email address is already  used');
        return;
      }
      setloader(false);
      setEmailSafe(true);
    }
    setErrorEmailOem(false);
    return;
  };

  /*
   * Valid Confirm passsword
   * params passsword
   * return boolean
   */
  const validConfirmPassword = (txt: string): boolean => {
    if (
      isRequired(txt) &&
      length(6, 100, txt) &&
      passwordOem == confirmPasswordOem
    ) {
      return true;
    } else {
      setErrorConfirmPasswordOem(true);
      return false;
    }
  };

  const checkEmailBouton = async (email: string) => {
    if (validEmail(email)) {
      setloader(true);
      let response = await checkIfEmailExist(email, true);
      if (response?.status == 200 && response?.data?.details) {
        setloader(false);
        setEmailNotSafe(true);
        setTxtEmailError('This email address is already  used');
        setloader(false);
        return;
      }

      if (
        validFirstName(firstNameOem) &&
        validLastName(lastNameOem) &&
        validEmail(emailOem) &&
        validPassword(passwordOem) &&
        validConfirmPassword(confirmPasswordOem) &&
        acceptCguOem
      ) {
        registerUser();
      } else {
        if (!acceptCguOem) {
          setErrorAcceptCguOem(true);
        }
        if (!validEmail(emailOem)) {
          setTxtEmailError('your email address is not valid');
        }
        if (!isRequired(emailOem)) {
          setTxtEmailError('This field is required');
        }
        if (!isRequired(passwordOem)) {
          setTxtPasswordError('This field is required');
        }
        if (!length(12, 100, passwordOem)) {
          setTxtPasswordError('password length must be 12 or more characters ');
        }
        if (
          length(12, 100, passwordOem) &&
          !passwordOem.match(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{12,}$/
          )
        ) {
          setTxtPasswordError(
            'password must contain at least one uppercase, lowercase, special character and one number '
          );
          // setTxtPasswordError('password length must be 12 or more characters ');
        }
        return;
      }
    }
    setErrorEmailOem(false);
    setloader(false);
  };

  const navigateCompleteOemInformation = async () => {
    if (emailOem && !emailSafe && !emailNotSafe) {
      await checkEmailBouton(emailOem);
    }

    if (
      validFirstName(firstNameOem) &&
      validLastName(lastNameOem) &&
      validEmail(emailOem) &&
      validPassword(passwordOem) &&
      validConfirmPassword(confirmPasswordOem) &&
      acceptCguOem &&
      emailSafe
    ) {
      registerUser();
      // history.push("/complete-page-investor");
    } else {
      if (!acceptCguOem) {
        setErrorAcceptCguOem(true);
      }
      if (!validEmail(emailOem)) {
        setTxtEmailError('your email address is not valid');
      }
      if (!isRequired(emailOem)) {
        setTxtEmailError('This field is required');
      }
      if (!isRequired(passwordOem)) {
        setTxtPasswordError('This field is required');
      }
      if (!length(12, 100, passwordOem)) {
        setTxtPasswordError('password length must be 12 or more characters ');
      }
      if (
        length(12, 100, passwordOem) &&
        !passwordOem.match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{12,}$/
        )
      ) {
        setTxtPasswordError(
          'password must contain at least one uppercase, lowercase, special character and one number '
        );
        // setTxtPasswordError('password length must be 12 or more characters ');
      }
      setloader(false);
      return;
    }
  };

  const keyEnter = ({ key }: any) => {
    if (key === 'Enter') {
      navigateCompleteOemInformation();
    } else return;
  };

  useEventListener('keydown', keyEnter);

  const registerUser = () => {
    setSessionStorageObject({ confirmPasswordOem });
    addDataOem({
      firstName: firstNameOem,
      lastName: lastNameOem,
      email: emailOem,
      password: passwordOem,
      creation_id: tokenId,
    });

    history.push('/complete-company-oem');
  };

  return {
    navigateCompleteOemInformation,
    firstNameOem,
    setFirstName,
    lastNameOem,
    setLastName,
    emailOem,
    setEmail,
    passwordOem,
    setPassword,
    confirmPasswordOem,
    setConfirmPassword,
    acceptCguOem,
    setAcceptCgu,
    loader,
    checkIfAlreadyEmailExist,
    errorFirstNameOem,
    errorLastNameOem,
    errorEmailOem,
    errorPasswordOem,
    errorConfirmPasswordOem,
    errorAcceptCguOem,
    txtEmailError,
    txtPasswordError,
    emailNotSafe,
    emailSafe,
    tokenId,
  };
}
