import React from "react";
import Modal from "react-bootstrap/Modal";
import SoftwareSolutionWidget from "../../../../widget/software-solution-with-details";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import Button from "react-bootstrap/Button";

type IpropsModal = {
  dataSource: any;
};

export default function ModalEditSolution(props: IpropsModal) {
  return (
    <Modal
      show={props?.dataSource?.solutionShow}
      onHide={() => props?.dataSource?.cancelChange()}
      className="modalDevinsider large"
    >
      <Modal.Header closeButton>
        <Modal.Title>Software solution</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <b>What solution(s) does your company offer?</b> Select all that apply
        </div>
        <SoftwareSolutionWidget {...props?.dataSource?.UseSolutionOffert} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancelbtn"
          onClick={() => props?.dataSource?.cancelChange()}
        >
          Cancel
        </Button>
        <Button
          className="validate"
          onClick={() =>
            props?.dataSource?.updateCompanyResellerDetails("solution")
          }
          disabled={props?.dataSource?.isLoaderShow}
        >
          {props?.dataSource?.isLoaderShow ? <Loader /> : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
