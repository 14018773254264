import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateLogoExpert } from "../../../../../../../../redux/actions/acounts-action";
import { rootState } from "../../../../../../../../redux/reducers";

export default function UseUpdateLogo() {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isLoaderShow, setIsLoaderShow] = useState<boolean>(false);
  const userState = useSelector((state: rootState) => state.userReducer);
  const dispatch = useDispatch();
  const fileRef: React.RefObject<HTMLInputElement> = useRef(null);

  const isAccepter = (type: string): boolean => {
    const isImage = type?.split("/");
    return isImage?.[0] === "image" ? true : false;
  };

  const callBack = (data: any) => {
    setIsLoaderShow(false);
    setSelectedFile(undefined);
    if (data) {
      toast("Successfully updated");
    }
  };
  //for pick  image
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      const files = event?.target?.files[0];
      if (isAccepter(files.type)) {
        setIsLoaderShow(true);
        const params = {
          logo: files,
        };
        dispatch(updateLogoExpert(params, callBack));
        setSelectedFile(event?.target?.files[0]);
      } else {
        toast("File is not accepter");
      }
    }
  };

  //event click bouton and lunch pick image in type image , fileRef is ref of img
  const handleBtn = () => {
    if (fileRef) {
      fileRef?.current?.click();
    }
  };

  return { handleBtn, changeHandler, fileRef, selectedFile, isLoaderShow };
}
