import React from "react";
import getTopLikedContributions, {
  IPropsContributor,
} from "../../../../../controller/community-ctr/home-page/top-liked-contributions";
import { LoadingArticle } from "../../../../widget/loader";

const TopLikedContribution = () => {
  const topContributions = { ...getTopLikedContributions() };
  return (
    <>
      <h2 className="title">Top liked contributions</h2>
      <ul className="separateElement">
        {topContributions?.loaderContributor ? (
          <LoadingArticle />
        ) : (
          topContributions?.topLikedContributions?.map(
            (discussion: IPropsContributor, index: number) => (
              <li key={index}>
                <div className="leftItem txtLabel infoFeed">
                  <div className="titlePost">
                    {discussion?.post_type?.id?.toString() === "2" && (
                      <span className="article">Article</span>
                    )}
                    <span
                      className="title"
                      onClick={() =>
                        topContributions?.goToreplayPost(
                          discussion?.category?.id?.toString(),
                          discussion?.id
                        )
                      }
                    >
                      {discussion.title}
                    </span>
                  </div>
                  {discussion?.labels?.map((label: any, index: number) => (
                    <span
                      className="topic"
                      key={index}
                      onClick={() =>
                        topContributions.goToLabelCategory(
                          discussion?.category?.id,
                          label?.id,
                          discussion.post_type?.id?.toString()
                        )
                      }
                    >
                      {label?.title}
                    </span>
                  ))}
                </div>
                <div className="rightItem likeContent">
                  <span className="icon-like-full"></span>
                  {discussion?.numberOfLikes}
                </div>
              </li>
            )
          )
        )}

        {topContributions?.topLikedContributions?.length > 0 && (
          <li
            className="linkForLi"
            onClick={() => topContributions?.viewAllContributor()}
          >
            <a
              href="javascript"
              onClick={(e) => e?.preventDefault()}
              className="viewAll"
            >
              View all
            </a>
          </li>
        )}
      </ul>
    </>
  );
};

export default TopLikedContribution;
