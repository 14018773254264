import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { rootState } from '../../../../redux/reducers';
import { postJsonDataService } from '../../../../service/applicatif/back-end-service';
import {
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
  url,
} from '../../../../service/constant';
import { IPropsReceipt, IpropsTransaction } from './transaction-type';
type IProps = {
  tabType: string;
};
export default function UseTransactionHistory(props: IProps) {
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const companyIsv = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const [loaderGet, setLoaderGet] = useState<boolean>(false);
  const investorPageObject = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );

  const oemProgramObject = useSelector(
    (state: rootState) => state.oemProgramReducer.program
  );

  const resellerPage = useSelector(
    (state: rootState) => state.resellerCompanyReducer
  );
  const userObject = useSelector((state: rootState) => state.userReducer);

  const userType = userObject.roles?.filter((el) => el !== 'USER_ROLE')?.[0];

  const low = moment().subtract(1, 'months').format('MMMM DD, YYYY');
  const dateNow = moment().format('MMMM DD, YYYY');
  const [lowDate, setStartDate] = useState<Date>(new Date(low));
  const [endDate, setEndDate] = useState<Date>(new Date(dateNow));
  const [triDate, setTriDate] = useState<'day' | 'week' | 'month'>('day');

  let companyId: string | null =
    userType === ISV
      ? companyIsv.id
      : userType === OEM_PROGRAM
      ? oemProgramObject.id
      : userType === INVESTOR
      ? investorPageObject.id
      : resellerPage.id;

  const [transaction, setTransaction] = useState<{
    items: Array<IpropsTransaction>;
    meta: {
      nb_item: number;
      pages: number;
      pagination: number;
      total_count: number;
    };
  }>({
    items: [],
    meta: { nb_item: 0, pages: 1, total_count: 0, pagination: 0 },
  });

  const [paramsWs, setParamsWs] = useState<{
    begin: string;
    end: string;
    page: number;
    limit: number;
    companyId: string | null;
    group: 'day' | 'week' | 'month';
  }>({
    begin: moment(lowDate).format('YYYY-MM-DD'),
    end: moment(endDate).format('YYYY-MM-DD'),
    page: 1,
    limit: 10,
    companyId: companyId,
    group: triDate,
  });

  const getHistory = async (p: any) => {
    setLoaderGet(true);
    const response: any = await postJsonDataService(
      url.settings.get_history,
      p,
      userReducer.token
    );
    setLoaderGet(false);
    return response;
  };

  useEffect(() => {
    async function getTransaction() {
      const param = {
        ...paramsWs,
        begin: moment(lowDate).format('YYYY-MM-DD'),
        end: moment(endDate).format('YYYY-MM-DD'),
        page: 1,
        group: triDate,
      };
      const response = await getHistory(param);
      if (response?.status === 200) {
        setTransaction({
          items: response?.data?.object_list,
          meta: {
            ...response?.data?.meta,
            pages: 1,
          },
        });
      }
    }
    props?.tabType === 'history_list' && getTransaction();
    return () => {};
  }, [props?.tabType, lowDate, endDate, triDate]);

  const handlePageClick = (page: number) => {
    const params = {
      ...paramsWs,
      page,
    };
    setTransaction({
      items: transaction.items,
      meta: {
        ...transaction.meta,
        pages: page,
      },
    });
    getHistory(params);
  };

  return {
    transaction,
    handlePageClick,
    loaderGet,
    lowDate,
    endDate,
    setStartDate,
    setEndDate,
    triDate,
    setTriDate,
  };
}
