import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import store from "../../../../../redux/store";
import ModalAbout from "../../../../widget/modal-about";
import AboutUserCtr from "../../../../../controller/community-ctr/activity-user/about-user";
import { refaCtoNullKey } from "../../../../../utils";
import { CONTRIBUTOR, url } from "../../../../../service/constant";
import { getDataService } from "../../../../../service/applicatif/back-end-service";
const ActivityAboutUser = (props: any) => {
  const providerAboutUser = { ...AboutUserCtr() };
  const currentUser = store.getState().userReducer;
  const [labelsExpert, setLabelsExpert] = useState<
    Array<{ category_id: string; title: string }>
  >([]);
  const params: any = useParams();

  const role = currentUser?.roles?.filter(
    (role: string) => role !== "ROLE_USER"
  )?.[0];

  const isCurrentUserShowActivity =
    parseInt(currentUser?.id) === parseInt(props?.userId);

  const txtRole = role !== CONTRIBUTOR ? "About" : "Expertise";

  const txtAbout = isCurrentUserShowActivity
    ? currentUser?.plain_text
    : props?.userShowActivity?.plain_text;

  const isShowExpertExpertize = props.userShowActivity?.roles === CONTRIBUTOR;

  useEffect(() => {
    async function getLabelsExpert() {
      const labels = await getDataService(
        url.community.activity.get_expert_labels + "/" + params?.id,
        {},
        store.getState()?.userReducer?.token
      );

      if (labels?.status === 200) {
        setLabelsExpert(labels?.data);
      }
    }
    if (isShowExpertExpertize) {
      getLabelsExpert();
    }
  }, [isShowExpertExpertize]);

  useEffect(() => {
    providerAboutUser?.setAbout(txtAbout);
  }, [txtAbout]);

  const isAboutIsNull =
    txtAbout === "" ||
    txtAbout === "null" ||
    txtAbout === null ||
    txtAbout === "undefined" ||
    txtAbout === undefined;

  return !isCurrentUserShowActivity && isAboutIsNull ? (
    <></>
  ) : (
    <>
      <h2 className="title">{txtRole}</h2>

      {isShowExpertExpertize && (
        <div className="labelSelected">
          <ul>
            {labelsExpert?.map(
              (label: { category_id: string; title: string }) => (
                <li>{label.title}</li>
              )
            )}
          </ul>
        </div>
      )}

      <div className="containerMemberActivity">
        {isCurrentUserShowActivity && (
          <a
            onClick={() => providerAboutUser.setShowModal(true)}
            className="editItem"
          >
            <span className="icon-edit-full" />
          </a>
        )}
        <div className="fauxText">
          {isAboutIsNull ? (
            <>
              Tell other community members about yourself.
              <div className="l100"></div>
              <div className="l100"></div>
              <div className="l100"></div>
              <div className="l50"></div>
            </>
          ) : (
            refaCtoNullKey(txtAbout)
          )}
        </div>
      </div>

      <ModalAbout
        txt={providerAboutUser?.about}
        isShowModal={providerAboutUser.showModal}
        closeModal={() => providerAboutUser.setShowModal(false)}
        changeTxt={(txt: string) => providerAboutUser?.setAbout(txt)}
        cancelChange={() => providerAboutUser.setShowModal(false)}
        updateTextArea={() =>
          providerAboutUser?.updateAboutUser(providerAboutUser?.about)
        }
        isLoaderShow={providerAboutUser?.updatingAbout}
      />
    </>
  );
};

export default ActivityAboutUser;
