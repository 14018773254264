import React, { useState } from "react";

import { useSelector } from "react-redux";
import { sendClaimOwnerShip_Service } from "../../../../service/applicatif/company-page-isv";
import * as T from "../../types/types-company-page";
import { useForm } from "react-hook-form";
import { rootState } from "../../../../redux/reducers";
import { getDomainWebSite } from "../../../../utils";
import { postData } from "../../../../service/api";
import { url } from "../../../../service/constant";
import { contact } from "../../../../utils/country_phone";

export default function UseClaimCtr() {
  const userObject = useSelector((state: rootState) => state.userReducer);
  const [showFormAddEmailClaim, setshowFormAddEmailClaim] = useState(false);
  const [isShowSuccessRequestClaim, setIsShowSuccessRequestClaim] =
    useState(false);
  const [errorDiffWorkEmail, setErrorDiffWorkEmail] = useState(false);
  const [emailRequest, setEmailRequest] = useState<string>(
    userObject?.primary_email?.split("@")?.[0]
  );
  const [isShowContactUs, setisShowContactUs] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("US");
  const [isShowLoaderSendClaim, setIsShowLoaderSendClaim] =
    useState<boolean>(false);
  const [loaderContactUs, setLoaderContactUs] = useState<boolean>(false);
  const [isShowSuccessContactUs, handleShowSuccessContactUs] =
    useState<boolean>(false);
  const [companyTosendClaim, setCompanyTosendClaim] =
    useState<T.TFormAddEmailClaimOwnerShip>({
      companyName: "",
      pays: "",
      webSite: "",
      acceptTerm: false,
      workEmail: "",
      logoCompany: "",
      id: "",
    });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm<T.FormContactUs>({
    mode: "onSubmit",
  });

  const submitFormContactUs = async (data: T.FormContactUs) => {
    setLoaderContactUs(true);
    let params = {
      name: data?.name,
      phone_number:
        contact &&
        contact.find((el: any) => el.code == selected)?.dial_code +
          data?.phoneNumber,
      email_address: data?.emailAdress,
      message: data?.textMessage,
    };
    let response = await postData(
      url?.company?.contactUs,
      params,
      userObject?.token
    );
    if (response?.status === 200) {
      setLoaderContactUs(false);
      setisShowContactUs(false);
      handleShowSuccessContactUs(true);
    }
  };

  const showFormClaim = (el: T.TCompanyShearch) => {
    setshowFormAddEmailClaim(true);
    let workEmail = userObject?.primary_email;
    let domainMail = workEmail?.split("@")?.[1];

    if (domainMail != getDomainWebSite(el?.website)) {
      setErrorDiffWorkEmail(true);
    } else {
      setErrorDiffWorkEmail(false);
    }
    let tempToValueForm = {
      companyName: el?.company_name,
      pays: el?.country_name,
      webSite: el?.website,
      acceptTerm: false,
      workEmail: "",
      logoCompany: el?.photo_profil,
      id: el?.id,
    };
    setCompanyTosendClaim(tempToValueForm);
  };

  const toogleAcceptTerm = (toogle: boolean) => {
    let tempState: T.TFormAddEmailClaimOwnerShip = {
      ...companyTosendClaim,
      acceptTerm: toogle,
    };
    setCompanyTosendClaim(tempState);
  };

  const sendReQuestClaim = async () => {
    let data = companyTosendClaim?.id + "/" + userObject?.primary_email;
    setIsShowLoaderSendClaim(true);
    let response = await sendClaimOwnerShip_Service(data, userObject?.token);
    if (response?.status === 200) {
      setIsShowLoaderSendClaim(false);
      setshowFormAddEmailClaim(false);
      setIsShowSuccessRequestClaim(true);
    }
  };

  const cancelSendClaim = () => {
    setshowFormAddEmailClaim(false);
  };

  const showFormContactUs = () => {
    setisShowContactUs(true);
  };

  const setCloseContactUsModal = () => {
    if (showFormAddEmailClaim) {
      setshowFormAddEmailClaim(false);
    }
    setisShowContactUs(false);
    reset();
  };

  const sendFormContactUs = () => {};
  return {
    showFormAddEmailClaim,
    setshowFormAddEmailClaim,
    isShowSuccessRequestClaim,
    setIsShowSuccessRequestClaim,
    showFormClaim,
    companyTosendClaim,
    toogleAcceptTerm,
    errorDiffWorkEmail,
    emailRequest,
    sendReQuestClaim,
    isShowLoaderSendClaim,
    cancelSendClaim,
    isShowContactUs,
    setCloseContactUsModal,
    sendFormContactUs,
    setisShowContactUs,
    showFormContactUs,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    errors,
    submitFormContactUs,
    loaderContactUs,
    selected,
    setSelected,
    isShowSuccessContactUs,
    handleShowSuccessContactUs,
  };
}
