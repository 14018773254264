import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import EditBasicInfo from "../edit-basic-information/basic-information";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";
import { getSStorage } from "../../../../../utils";

type IProps = {
  show: boolean;
  handleSubmit: (submitFunction: any) => any;
  submitBasicInfo: (p: any) => void;
  cancelUpdateform: () => void;
  register: any;
  getValues: any;
  errors: any;
  loaderProfil: boolean;
};
export default function EditCompanyInformation(props: IProps) {
  const [dataCompleted, setDataCompleted] = useState(
    JSON.parse(getSStorage("completed_account") || "{}")
  );

  return (
    <Modal
      show={props?.show}
      className="createCompanypopup smallPopup forStep5custom"
    >
      <form onSubmit={props?.handleSubmit(props?.submitBasicInfo)}>
        <Modal.Header>
          <div className="headerpopupCompany noStep">
            <h2 className="title">Basic information</h2>
            <a className="close" onClick={() => props?.cancelUpdateform()}>
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className="withMinHeight">
          <EditBasicInfo
            register={props?.register}
            required
            companyLegalName="companyLegalName"
            companyTradeName="companyTradeName"
            hQLocation="hQLocation"
            companySize="companySize"
            webSite="webSite"
            foundingDate={"foundingDate"}
            getValuesForm={props?.getValues}
            dataCity={dataCompleted?.country}
            companySizeData={dataCompleted?.company_size}
            errors={props?.errors}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="contentBorder formForEmail text-right">
            <button
              className="btn btn-devinsider cancel"
              // onClick={handleCloseRequestBasicInformation}
              type="button"
              onClick={() => props?.cancelUpdateform()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-devinsider px-5 save"
              disabled={props?.loaderProfil}
            >
              {props?.loaderProfil ? <Loader /> : "Save"}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
