import Header from '../../../widget';
import LeftProgram from '../program-left-side/bloc-left-program';
import BlocDirectory from './bloc-directory';
import { useState } from 'react';
import UseProgram from '../../../../controller/program-ctr';
import MatchingProgram from '../../../../hooks/MatchingProgram';

export default function Directory() {
  const [route] = useState('DIRECTORY');

  const { type, location, solution, filter, resetFilter } = UseProgram(route);

  return (
    <MatchingProgram>
      <div className='findIsvcontainer programContainer'>
        <Header />
        <LeftProgram route={route} filter={filter} resetFilter={resetFilter} />
        <BlocDirectory
          selectedType={type}
          selectedLocation={location}
          selectedSolution={solution}
        />
      </div>
    </MatchingProgram>
  );
}
