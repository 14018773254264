import React from 'react';
import { NavLink } from 'react-router-dom';
import { fileUrl } from '../../../../../../utils';

type Iprops = {
  profil: string;
  companyName: string;
  roleDiscussion: string;
  companyId: string;
};
export default function OemToIsv(props: Iprops) {
  const companyUrl = '/company/company-unlocked/' + props.companyId;

  return (
    <div className='d-flex align-items-center'>
      <div className='photo-title'>
        <img src={fileUrl(props.profil)} alt='' />
      </div>
      <div className='info-title'>
        <div className='company-title'>
          <NavLink to={companyUrl}>{props?.companyName}</NavLink>
        </div>
        <div className='company-type'>{props?.roleDiscussion}</div>
      </div>
    </div>
  );
}
