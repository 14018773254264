import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useNavigation from "../../../../hooks/use-navigate";
import { signinAction } from "../../../../redux/actions/acounts-action";
import { postWithoutTokenCombineUrl } from "../../../../service/api";
import { createUserAndProgram, getUserMembersProgram } from "../../../../service/applicatif/oem";
import { url } from "../../../../service/constant";
import { clearSessionObject } from "../../../../utils";

export default function UseCreate() {
  const history = useHistory();
  const [result, setResult] = useState("");

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSkip, setLoadingSkip] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const [selectedFile, setSelectedFile] = useState<any>();

  const dataToSend = {
    firstName: sessionStorage?.getItem("first_name") || "",
    lastName: sessionStorage?.getItem("last_name") || "",
    email: sessionStorage?.getItem("email") || "",
    password: sessionStorage?.getItem("password") || "",
    country: sessionStorage?.getItem("country") || "",
    phoneNumber: sessionStorage?.getItem("phoneNumber") || "",
    companyName: sessionStorage?.getItem("companyName") || "",
    jobTitle: sessionStorage?.getItem("job_title") || "",
    phoneNumberCode: sessionStorage?.getItem("codePays") || "",
    dialCode: sessionStorage?.getItem("dialCode") || "",
    fileName: selectedFile,
    subscriptionToken: sessionStorage?.getItem("token_affiliated") || "",
  };

  const callBack = async (data: any) => {
    if ((data && data?.status === 200) || data?.status === 201) {
      clearSessionObject();
      if(data?.data?.oemProgramMembers.length > 0){
        const activeProgram = data?.data?.oemProgramMembers?.find(
          (program: any) => program.oem_program?.uuid != ""
        );
        navigate("/OEM/profil/" + activeProgram?.oem_program?.id);
      } 
    } else {
      setLoading(false);
      setLoadingSkip(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const result = await createUserAndProgram(dataToSend, true);
    if (result?.status === 201 || result?.status === 200) {
      await postWithoutTokenCombineUrl(
        url?.acount?.manualActivation,
        dataToSend.email!
      );
      dispatch(
        signinAction(
          {
            email: dataToSend.email?.trim(),
            password: dataToSend.password?.trim(),
          },
          callBack
        )
      );
    } else {
      setLoading(false);
    }
  };

  const onSkip = async () => {
    setLoadingSkip(true);
    const result = await createUserAndProgram(dataToSend, true);
    if (result?.status === 201 || result?.status === 200) {
      await postWithoutTokenCombineUrl(
        url?.acount?.manualActivation,
        dataToSend.email!
      );
      dispatch(
        signinAction(
          {
            email: dataToSend.email?.trim(),
            password: dataToSend.password?.trim(),
          },
          callBack
        )
      );
    } else {
      setLoadingSkip(false);
    }
  };

  const onBack = () => {
    history.goBack();
  };

  const handleFileProfile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      if (
        event?.target?.files?.[0] &&
        event?.target?.files[0]?.type?.includes("image")
      ) {
        setSelectedFile(event?.target?.files[0]);
      }
    }
  };

  return {
    result,
    setResult,
    onSubmit,
    onBack,
    onSkip,
    selectedFile,
    setSelectedFile,
    handleFileProfile,
    first_name: sessionStorage?.getItem("first_name") || "",
    last_name: sessionStorage?.getItem("last_name") || "",
    job_title: sessionStorage?.getItem("job_title") || "",
    company_name: sessionStorage?.getItem("companyName") || "",
    loading,
    loadingSkip,
  };
}
