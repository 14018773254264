import React from "react";
import { Link } from "react-router-dom";

import url from "../../../../../../service/constant/index";
import DefaultCompany from "../../../../../../resources/images/defaultCompany.png";
import { fileUrl } from "../../../../../../utils";

const CompanyCardIsv = (props: any) => {
  return (
    <div className="contentISV">
      <h2 className="title">Software vendor</h2>
      <ul className="svList">
        <li>
          <div
            className="imgSv"
            onClick={() => props?.goToCompanyPage()}
            style={{ cursor: "pointer" }}
          >
            <img
              src={
                props?.userState?.isvInCompanyPage
                  ? fileUrl(
                      props?.userState?.isvInCompanyPage?.company_page
                        ?.photo_profil
                    )
                  : DefaultCompany
              }
              alt="Software vendor logo"
            />
          </div>
          <div className="infoSV">
            {props?.userState?.isvInCompanyPage && (
              <>
                <h3 className="title">{props?.userState?.company_name}</h3>
                <div className="role">
                  {props?.userState?.isvInCompanyPage?.role}
                </div>
              </>
            )}
            <Link to={props?.targetUrl}>{props?.text}</Link>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CompanyCardIsv;
