import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { rootState } from "../../../redux/reducers";
import store from "../../../redux/store";
import { postJsonDataService } from "../../../service/applicatif/back-end-service";
import { url } from "../../../service/constant";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import UseSearch from "../../../screen/widget/community/search/use-search";

export type activeFilterContributor =
  | "last-day"
  | "last-week"
  | "last-month"
  | "last-six-mounth"
  | "last-year"
  | "all-time";

export type ItemAllTopContributor = {
  id: string;
  title: string;
  numberOfLikes: string;
  numberOfViews: string;
  labels: Array<{ id: number; title: string }>;
  tags: Array<{ id: number; name: string }>;
  created_at: string;
  updated_at: string;
  solve: boolean;
  category: { id: string; title: string; slug: string };

  user: {
    id: number;
    email: string;
    roles: Array<string>;
    username: string;
    last_name: string;
    first_name: string;
    file_name: string;
  };
  post_type: {
    id: number;
    type: string;
  };
  last_activity_by: {
    last_name: string;
    first_name: string;
    id: string;
  };
};
export type ItemAuthor = {
  order_rank: string;
  number_of_like: string;
  profil_picture: string;
  user_name: string;
  job_title: string;
  user_to_like_id: string;
  id: string;
};

export default function UseTopAuthorContributor() {
  const location: any = useLocation();
  const history = useHistory();
  const communityReducer = useSelector(
    (state: rootState) => state.communityReducer
  );

  const [postType, setPostType] = useState(location?.state?.postType);

  const [defaultActive, setdefaultActive] = useState<string>(
    location?.state?.activeKey || "author"
  );

  const [categorieTopLike, setCategorieTopLike] = useState<string>(
    location?.state?.categorie || ""
  );
  let labelCategorie: string =
    communityReducer?.discussionCategories?.find(
      (el: any) => el?.id == location?.state?.categorie
    )?.title || "ALL CATEGORIES";
  const { handleFilterTxt, inputText, filterSearchText, handleCateGory } =
    UseSearch();
  const [activeFilterTopContributor, setActiveFilterTopContributor] =
    useState<activeFilterContributor>("all-time");
  const [currentPageContributor, setcurrentPageContributor] = useState(1);
  const [limit, setLimit] = useState(10);
  const [dataContributors, setdataContributors] = useState<{
    allData: Array<ItemAllTopContributor>;
    totalData: number;
  }>({ allData: [], totalData: 0 });
  const [loaderContributor, setLoaderContributor] = useState<boolean>(false);

  const [activeFilterTopAuthor, setActiveFilterTopAuthor] =
    useState<activeFilterContributor>("all-time");
  const [currentPageAuthor, setcurrentPageAuthor] = useState(1);
  const [limitAuthor, setLimitAuthor] = useState(18);
  const [dataAuthor, setdataAuthor] = useState<{
    allData: Array<ItemAuthor>;
    totalData: number;
  }>({ allData: [], totalData: 0 });

  const [myRank, setMyRank] = useState<ItemAuthor>({
    order_rank: "",
    number_of_like: "",
    profil_picture: "",
    user_name: "",
    job_title: "",
    user_to_like_id: "",
    id: "",
  });
  const [topLikedAuthor, setTopLikedAuthor] = useState<Array<ItemAuthor>>([]);

  const getTopLike = async (
    page: number,
    limit: number,
    postType: string,
    category: string,
    filter: string,
    type: string = "contributor"
  ) => {
    let params = {
      page: page,
      limit: limit,
      postType: postType || "",
      category: category,
      filter: filter,
    };
    setLoaderContributor(true);

    const result = await postJsonDataService(
      type == "contributor"
        ? url?.community?.get_all_contributors
        : url?.community?.get_all_author,
      params,
      store?.getState()?.userReducer?.token
    );

    if (result?.status === 200) {
      if (type === "contributor") {
        let responseProvider = {
          allData: result?.data?.top_liked_contribution,
          totalData: result?.data?.total_result_to_paginate,
        };
        setdataContributors(responseProvider);
      } else {
        let responseProvider = {
          allData: result?.data?.top_liked_author,
          totalData: result?.data?.total_result_to_paginate,
        };
        setMyRank(result?.data?.my_ranked?.[0]);
        setdataAuthor(responseProvider);
        setTopLikedAuthor(result?.data?.top_three);
      }
    }
    setLoaderContributor(false);
  };

  useEffect(() => {
    getTopLike(
      currentPageContributor,
      limit,
      postType,
      categorieTopLike,
      activeFilterTopContributor,
      "contributor"
    );
    return () => {};
  }, []);

  useEffect(() => {
    getTopLike(
      currentPageAuthor,
      limitAuthor,
      postType,
      categorieTopLike,
      activeFilterTopAuthor,
      "author"
    );
    return () => {};
  }, []);

  const setFilterTopAuthor = (p: activeFilterContributor) => {
    setcurrentPageAuthor(1);
    getTopLike(1, limitAuthor, postType, categorieTopLike, p, "author");
    setActiveFilterTopAuthor(p);
  };

  const handlePageClickAuthor = (p: number) => {
    setcurrentPageAuthor(p);
    getTopLike(
      p,
      limit,
      postType,
      categorieTopLike,
      activeFilterTopAuthor,
      "author"
    );
  };

  const setFilterTopContributor = (p: activeFilterContributor) => {
    setcurrentPageContributor(1);
    getTopLike(1, limit, postType, categorieTopLike, p, "contributor");
    setActiveFilterTopContributor(p);
  };

  const handlePageClickContributor = (p: number) => {
    setcurrentPageContributor(p);
    getTopLike(
      p,
      limit,
      postType,
      categorieTopLike,
      activeFilterTopContributor,
      "contributor"
    );
  };

  const showActivityUser = (idUser: string) => {
    history.push("/community/activity-user/" + idUser);
  };

  const goToLabelCategory = (
    idCategory: any,
    labelId: string,
    postType: string
  ) => {
    postType === "1" &&
      history.push("/community/one-category/" + idCategory + "/" + labelId);

    postType === "2" &&
      history.push("/community/article-category/" + idCategory + "/" + labelId);

    postType === "3" && history.push("/community/press-releases/" + labelId);

    postType === "4" && history.push("/community/press-releases/" + labelId);
  };

  const goToreplayPost = (idCategorie: string, postId: string) => {
    history.push("/community/reply-post/" + idCategorie + "/" + postId);
  };

  return {
    defaultActive,
    setdefaultActive,
    activeFilterTopContributor,
    setFilterTopContributor,
    dataContributors,
    loaderContributor,
    currentPageContributor,
    handlePageClickContributor,
    labelCategorie,
    //Author
    dataAuthor,
    myRank,
    topLikedAuthor,
    activeFilterTopAuthor,
    currentPageAuthor,
    setFilterTopAuthor,
    handlePageClickAuthor,
    showActivityUser,
    handleFilterTxt,
    inputText,
    filterSearchText,
    handleCateGory,
    communityReducer,
    goToLabelCategory,
    goToreplayPost,
    postType,
  };
}
