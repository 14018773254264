/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import * as T from "../../../../../../controller/company-ctr/types/types-company-page";
import Modal from "react-bootstrap/Modal";
import { FormHeaderStrategy } from "../../header/Headerform";
import CreatableSelect from "react-select/creatable";

export default function CreateCompanyForm6(props: T.IpropsForm7) {
  const [activeId, setActiveId] = useState<any>("0");

  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }

  const isHaveSelectedLabel =
    props.currentSelected && props.currentSelected.label;

  return (
    <Modal show={props?.showStep7} className="createCompanypopup medium">
      <Modal.Header>
        <FormHeaderStrategy />
        <a
          href="javascript:;"
          className="close back"
          onClick={props?.handleBackStep6}
        >
          &nbsp;
        </a>
        <a
          href="javascript:;"
          className="close"
          onClick={props?.handleCloseStep7}
        >
          <span className="icon-close-pop"></span>
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="bodyCreateCompany">
          <div className="contentBorder row">
            <div className="form-group required col-12">
              <label htmlFor="">
                What technology partnership fields are you interested in?
              </label>
            </div>
            {props.isShowErrorPartenerShip && (
              <div className="container-error pl-2 d-inline">
                <span className="error-red"> error </span>
                <span className="txt-error">
                  select al least one partnerships are you interested
                </span>
              </div>
            )}
            <div className="geographicList col-12 mb-4">
              <Accordion>
                <div
                  className={`falseSelect ${
                    activeId === "1" ? "panel-wrap active-panel" : "panel-wrap"
                  }`}
                >
                  <div className="panel-header">
                    <Accordion.Toggle
                      onClick={() => toggleActive("1")}
                      className="panel-toggle"
                      eventKey="1"
                    >
                      Select all that apply ...
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey="1">
                    <div className="panel-body">
                      {props.technologiesPartnership &&
                        props.technologiesPartnership.map(
                          (el: any, index: number) => (
                            <div
                              key={index}
                              onClick={() => {
                                props.addParterShip(el);
                              }}
                            >
                              <Form.Check
                                custom
                                checked={
                                  props.partenerShipSelected &&
                                  props.partenerShipSelected?.find(
                                    (it: any) =>
                                      it.idSegment === el.idSegment &&
                                      it.name === el.name
                                  )
                                    ? true
                                    : false
                                }
                                label={el.name}
                                onChange={() => null}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
            <div className="form-group required col-12 m-0">
              <label className="m-0" htmlFor="">
              Do you currently have any technology partnerships with 
              <OverlayTrigger
                overlay={
                  <Tooltip id="trade">
                    Software vendors (ISVs) that offer an embedded or white labeled solution that 
                    becomes an integral part of another vendors’s software, 
                    resulting in a strategic partnership.
                  </Tooltip>
                }
              >
                <span className="oemTooltip">OEMs</span>
              </OverlayTrigger> 
              or 
              <OverlayTrigger
                overlay={
                  <Tooltip id="trade">
                   Software ecosystems that provide software vendors (ISVs) with tools, platforms, 
                   technologies they can build solutions on top of.
                  </Tooltip>
                }
              >
                <span className="oemTooltip">Ecosystems?</span>
              </OverlayTrigger> 
              </label>
              {/* Miaro ticket 46535
              <OverlayTrigger
                overlay={
                  <Tooltip id="trade">
                    Please enter your company’s trade name, or the name commonly
                    used for public reference (commonly exludes “Inc.”, “LLC”,
                    etc…)
                  </Tooltip>
                }
              >
                <span className="icon-aide ml-3"></span>
              </OverlayTrigger>
              */}
            </div>
            <div className="form-group col-12 m-0 contentCheckboxsolution lastRadio">
              <div
                className="checkcontainer"
                onClick={() => props.setCheckedSoftware("yes")}
              >
                <Form.Check
                  name="question"
                  type="radio"
                  custom
                  label="Yes"
                  checked={props.checkedSoftware === "yes" ? true : false}
                  onChange={() => null}
                />
              </div>
              <div
                className="checkcontainer"
                onClick={() => props.setCheckedSoftware("false")}
              >
                <Form.Check
                  name="question"
                  type="radio"
                  custom
                  label="No"
                  checked={props.checkedSoftware === "false" ? true : false}
                  onChange={() => null}
                />
              </div>
            </div>
            {props.checkedSoftware === "yes" && (
              <div className="form-group py-5 col-12 addPartneer">
                <label htmlFor="">
                  Who do you currently partner with?{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="trade">
                        Please select companies you work with by clicking on the
                        "+" button
                      </Tooltip>
                    }
                  >
                    <span className="icon-aide ml-3"></span>
                  </OverlayTrigger>
                </label>
                {props?.currentSelectedList &&
                props?.currentSelectedList?.length === 0
                  ? " (0 partner selected)"
                  : " (" +
                    props?.currentSelectedList?.length +
                    " partners selected)"}
                <div className="paterneer">
                  <CreatableSelect
                    options={props.currentPartener}
                    className="input-text form-control"
                    classNamePrefix="partneer"
                    onChange={(el: any) => {
                      props.setCurrentSelected(el);
                    }}
                    placeholder="Select partner that apply..."
                    value={props.currentSelected}
                  />

                  <a
                    className="add"
                    onClick={() =>
                      isHaveSelectedLabel &&
                      props.addListOption(props.currentSelected)
                    }
                  />
                </div>
                {props.currentSelectedList &&
                  props.currentSelectedList.map(
                    (el: { value: number; label: string }, index: number) => (
                      <>
                        <div className="paterneer" key={index}>
                          <input
                            type="text"
                            className="input-text form-control"
                            placeholder=""
                            value={el.label}
                            disabled={true}
                          />
                          <a
                            className="remove"
                            onClick={() => props.removeSelected(el.value)}
                          ></a>
                        </div>
                      </>
                    )
                  )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder m-0">
          <button
            className="btn btn-devinsider large containerBtnBtn"
            onClick={props?.nextSubmitForm7}
          >
            Next
          </button>
          <a className="skip rightgris" onClick={props?.submitForm7}>
            Skip
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
