import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { rootState } from "../../../../redux/reducers";
import { getDataWithoutToken, getData } from "../../../../service/api";
import { url, operationBillingAmount } from "../../../../service/constant";
import { getMonth } from "../../../../utils";

export default function UseUnlockInvestor() {
  const now = new Date();
  const userReducer = useSelector((state: rootState) => state.userReducer);
  const companyPageReducer = useSelector(
    (state: rootState) => state.companyPageReducer
  );
  const [modalUnlock, setModalUnlock] = useState<boolean>(false);
  const [chargingPlanDetails, setChargingPlanDetails] =
    useState<boolean>(false);
  const [choiseValue, setChoiseValue] = useState<string | undefined>();
  const [currentMonth, setCurrentMonth] = useState<string>(
    getMonth(now.getMonth()) + " " + now.getFullYear()
  );
  const [amountUnlockInvestor, setAmountUnlockInvestor] = useState<string>("");

  const closeUlocModal = () => {
    setStateUnlock({ number_left: 0, number_max: 0 });
    setModalUnlock(false);
    setChoiseValue(undefined);
  };

  const [idInvestorPage, setIdInvestorPage] = useState<number | undefined>();

  const showModalUnlock = (id: number) => {
    setModalUnlock(true);
    setIdInvestorPage(id);
  };

  const [stateUnlock, setStateUnlock] = useState<{
    number_left: number;
    number_max: number;
  }>({ number_left: 0, number_max: 0 });

  const handleChoise = (p: string) => {
    setChoiseValue(p);
  };

  //load amount to unlock investor
  useEffect(() => {
    (async () => {
      let response = await getData(
        url.settings.billingCenter +
          "/" +
          operationBillingAmount.ISV_UNLOCK_INVESTOR +
          "/" +
          companyPageReducer.id,
        userReducer.token
      );
      if (response?.status === 200) {
        setAmountUnlockInvestor(response.data);
      }
    })();
  }, []);

  useEffect(() => {
    async function getPlanDetails() {
      setChargingPlanDetails(true);
      const response = await getDataWithoutToken(
        url.settings.billingCenter + "/information/plan_details",
        { month: currentMonth },
        userReducer.token
      );
      if (response?.status === 200) {
        const { isv_unlock_investor }: any = response.data.free_status;
        setStateUnlock(isv_unlock_investor);
        setChargingPlanDetails(false);
        const isHaveFree = isv_unlock_investor?.number_left > 0;
        if (isHaveFree) {
          setChoiseValue("FREE");
        }
      }
    }
    if (modalUnlock) {
      getPlanDetails();
    }
  }, [currentMonth, modalUnlock]);

  return {
    modalUnlock,
    closeUlocModal,
    setModalUnlock,
    chargingPlanDetails,
    stateUnlock,
    handleChoise,
    choiseValue,
    showModalUnlock,
    idInvestorPage,
    amountUnlockInvestor,
  };
}
