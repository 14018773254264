import React from "react";
import Modal from "react-bootstrap/Modal";
import { IobJectResponse } from "../../../../../controller/company-ctr/types/types-company-page";
import ModalGeographieTarget from "../../../company/profil/strategy/modal/modal-edit-geographie-target";
import { ReactComponent as Loader } from "../../../../../resources/images/Rolling.svg";

type IGeographyTarget = {
  showStrategyGeographic: boolean;
  cancelGeoGraphieTarget: () => void;
  allContinents: Array<IobJectResponse>;
  paysChecked: Array<object>;
  allContinentSelected: Array<string>;
  addPaysInArray: (data: any) => void;
  isShowOfficeData: boolean;
  dataCompleted: any;
  addOffice: (data: string) => void;
  isShowErroCountrySelected: boolean;
  isShowErrorOffice: boolean;
  selectedOffice: string;
  updateModalGeographicTarget: () => void;
  loaderTargetCountry: boolean;
};

export default function ModalGeographyTarget(props: IGeographyTarget) {
  return (
    <Modal
      show={props.showStrategyGeographic}
      className="createCompanypopup medium forStep5custom"
    >
      {/* smallPopup */}
      <Modal.Header>
        <div className="headerpopupCompany noStep">
          <h2 className="title">Geographic target Market</h2>
          <a className="close" onClick={() => props.cancelGeoGraphieTarget()}>
            <span className="icon-close-pop"></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className="withMinHeight">
        <ModalGeographieTarget
          allContinents={props.allContinents}
          paysChecked={props.paysChecked}
          addPaysInArray={props.addPaysInArray}
          allContinentSelected={props.allContinentSelected}
          isShowOfficeData={props.isShowOfficeData}
          office={props?.dataCompleted?.office}
          addOffice={props.addOffice}
          isShowErroCountrySelected={props.isShowErroCountrySelected}
          isShowErrorOffice={false}
          selectedOffice={props.selectedOffice}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="contentBorder formForEmail text-right">
          <button
            className="btn btn-devinsider cancel"
            onClick={() => props.cancelGeoGraphieTarget()}
          >
            Cancel
          </button>
          <button
            className="btn btn-devinsider px-5"
            onClick={() => props.updateModalGeographicTarget()}
          >
            {props.loaderTargetCountry ? <Loader /> : "Save"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
