import Header from "../../../widget/index";
import Footer from "../../../widget/footer/footer";
import logo from "../../../../resources/images/sponsors/accusoft.png";
/*import logo1 from "../../../../resources/images/sponsors/bigidea.png";
import logo2 from "../../../../resources/images/sponsors/cavetech.png";
import logo3 from "../../../../resources/images/sponsors/ception.png";
import logo4 from "../../../../resources/images/sponsors/citySpeak.png";
import logo5 from "../../../../resources/images/sponsors/connect.png";
import logo6 from "../../../../resources/images/sponsors/rumble.png";
import logo7 from "../../../../resources/images/sponsors/techno.png";
import logo8 from "../../../../resources/images/sponsors/teknix.png";*/

export default function GetStartedCorporate() {
    return (
        <div className="landing_sponsors">
        <Header />
        <section className="main sponsors">
        <div className="container mx-auto">
        <h1 className="title">
        Devinsider sponsors
        </h1>
        <div className="list">
        <div className="item">
        <div className="img" style={{ background: '#F7F7F7' }}>
        <img src={logo} alt=""/>
        </div>
        <div className="desc">
        <span>
        Accusoft’s software tools and technologies help organizations supercharge their digital document processes. From mom-and-pop to Fortune 500 companies, if you need document viewing, collaboration, editing, eSignatures, digital forms, and document workflows, we’ve got it. <br/>
        </span>
        <div><a target="_blank" href="https://bit.ly/3XDBh6Y">Visit website ≫</a></div>
        </div>
        </div>
                    {/*<div className="item">
                        <div className="img" style={{ background: '#085663' }}>
                            <img src={logo1} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                    </div>*/}
                    {/*<div className="item">
                        <div className="img" style={{ background: '#051641' }}>
                            <img src={logo3} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                </div>*/}
        </div>
        </div>
        </section>
          {/*<section className="all sponsors mb-5">
            <div className="container mx-auto">
                <h1 className="title">
                    All sponsors
                </h1>
                <div className="list">
                    <div className="item">
                        <div className="img" style={{ background: '#F7F7F7' }}>
                            <img src={logo} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="img" style={{ background: '#085663' }}>
                            <img src={logo1} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="img" style={{ background: '#20082C' }}>
                            <img src={logo2} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="img" style={{ background: '#051641' }}>
                            <img src={logo3} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="img" style={{ background: '#F3C155' }}>
                            <img src={logo4} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="img" style={{ background: '#F3F4EF' }}>
                            <img src={logo5} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="img" style={{ background: '#460D52' }}>
                            <img src={logo6} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="img">
                            <img src={logo7} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="img">
                            <img src={logo8} alt=""/>
                        </div>
                        <div className="desc">
                            <span>
                            Lorem ipsum dolor sit amet, 
                            consectetur adipiscing elit. Cras 
                            laoreet ultrices lacinia. Donec et 
                            metus justo. Pellentesque suscipit non 
                            felis non convallis. Donec egestas nisl 
                            non odio convallis, nec facilisis nisl 
                            porta. Mauris feugiat purus eget ligula 
                            lacinia congue. <br/>
                            </span>
                            <div><a href="javascript:;">Go to sponsors site ≫</a></div>
                        </div>
                    </div>
                </div>
            </div>
    </section>*/}
        <Footer />
        </div>
        );
}