import { useEffect, useState } from "react";
import UseCompleteCategoryInvestor from "../../../screen/widget/software-solution/use-SF";
import useNavigation from "../../../hooks/use-navigate";
import { getSStorage, setSessionStorageObject } from "../../../utils";
import { useCompleteDataStorageOem } from "./use-complete-data-storage-oem";

export default function useCompleteCategiresOem() {
  const { navigate } = useNavigation();
  const { addDataOem } = useCompleteDataStorageOem();

  const [defaultSoftwareSolutionValue] = useState<Array<any>>(
    JSON.parse(getSStorage("solutionCheckedOem") || "[]")
  );

  const [defaultSoftwareSolutionValueFeatured] = useState<Array<any>>(
    JSON.parse(getSStorage("solutionCheckedFeaturesOem") || "[]")
  );

  const [hasError, setHasError] = useState<boolean>(false);

  const {
    solutionLeftColumnData,
    solutionRigthColumnData,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    featuredSolution,
    filterSolutionChecked,
    removeItemSelected
  } = UseCompleteCategoryInvestor({
    defaultValue: defaultSoftwareSolutionValue,
    defaultValueFeatured: defaultSoftwareSolutionValueFeatured,
  });

  useEffect(() => {
    setHasError(false);
  }, [solutionChecked, solutionCheckedFeatures]);

  const onSubmit = () => {
    if (solutionChecked.length < 1 && solutionCheckedFeatures.length < 1) {
      setHasError(true);
      return;
    }
    const segmentDetails = solutionCheckedFeatures.map((e: any) => e.id);
    const segments = solutionChecked.map((e: any) => e.id);

    addDataOem({
      segments: JSON.stringify(segments),
      segmentDetails: JSON.stringify(segmentDetails),
    });

    setSessionStorageObject({
      solutionCheckedOem: JSON.stringify(solutionChecked),
      solutionCheckedFeaturesOem: JSON.stringify(solutionCheckedFeatures),
    });

    navigate("/complete-industry-oem");
  };

  return {
    solutionLeftColumnData,
    solutionRigthColumnData,
    solutionChecked,
    solutionCheckedFeatures,
    addSolutionCheched,
    addSolutionChechedFeatured,
    featuredSolution,
    onSubmit,
    hasError,
    filterSolutionChecked,
    removeItemSelected,
  };
}
