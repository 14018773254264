import "./Discussion-categories.scss";
import React from "react";
import Header from "../../../widget/index";
import UseDetailsCategories from "../../../../controller/community-ctr/bloc-community-ctr/use-home-page-discussion-categories";
import { ITemDetailsDiscutionCategorie } from "../../../../redux/types/community-interface";
import { IpropsAuthor } from "../../../../controller/community-ctr/home-page/top-liked-author";
import { fileUrl, refaCtoNullKey } from "../../../../utils";
import { IPropsBestAnswer } from "../../../../controller/community-ctr/home-page/top-best-answer-author";
import { LoadingArticle } from "../../../widget/loader";
import SearchForm from "../../../widget/community/search/index";
import Footer from "../../../widget/footer/footer";
import { split } from "lodash";

export default function DiscussionCategories() {
  const providerDetailCategories = { ...UseDetailsCategories() };
  const renderLastActivityPlural = (last_activity:string) => {
    let activity = split(last_activity,' ');
    let duration = parseInt(activity[0]);
    activity[1] = duration > 1 ? activity[1] + "s" : activity[1];
    return activity.join(' ');
  };

  return (
    <div>
      <Header />
      <section className="discussion">
        <div className="container mx-auto">
          <ul className="breadcrumbPerso">
            <li onClick={() => providerDetailCategories.goHomeCommunity()}>
              <a href="javascript:;">Community </a>
            </li>
            <li>Discussion categories</li>
          </ul>
          <div className="searchTopic">
            <SearchForm
              categories={
                providerDetailCategories?.communityReducer?.discussionCategories
              }
              filterSearchText={providerDetailCategories?.filterSearchText}
              handleFilterTxt={providerDetailCategories?.handleFilterTxt}
              searchText={providerDetailCategories?.inputText}
              handleCateGory={providerDetailCategories?.handleCateGory}
            />
          </div>

          <div className="rightCommunityForum allWidth">
            <div className="block">
              <h2 className="title">Discussion categories</h2>
              <ul className="listBlockright categDiscussion parent">
                <li className="title">
                  <div className="itemBlock">Subject</div>
                  <div className="itemBlock">CONTRIBUTIONS</div>
                  <div className="itemBlock">Last activity</div>
                </li>

                {providerDetailCategories?.communityReducer?.detailsDiscutionCategorie?.map(
                  (el: ITemDetailsDiscutionCategorie, index: number) => (
                    <li key={index}>
                      <div className="itemBlock txtLabel otherViewTopic">
                        <h3
                          className="title"
                          onClick={() =>
                            providerDetailCategories?.goToLabelCategory(
                              el?.id?.toString(),
                              "0"
                            )
                          }
                        >
                          <span className="icon-discussion-semi"></span>
                          {el?.title}
                        </h3>
                        <div className="listOfTopic">
                          {el?.labels?.map(
                            (
                              element: { id: number; title: string },
                              index: number
                            ) => (
                              <span
                                className="topic"
                                key={index}
                                onClick={() =>
                                  providerDetailCategories?.goToLabelCategory(
                                    el?.id?.toString(),
                                    element?.id?.toString()
                                  )
                                }
                              >
                                {element?.title}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                      <div className="itemBlock centerContribution">
                        {el?.contribution}
                      </div>
                      <div className="itemBlock">
                        <span className="time">{renderLastActivityPlural(el?.last_activity) || ""}</span>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="leftCommunityForum allWidth">
            <div className="block">
              <h2 className="title">Top liked contributions</h2>
              {providerDetailCategories?.loaderContributor ? (
                <LoadingArticle />
              ) : (
                <ul className="separateElement">
                  {providerDetailCategories?.topLikedContributions?.map(
                    (element: any, index: number) => (
                      <li key={index}>
                        <div className="leftItem txtLabel">
                          <h3
                            className="title"
                            onClick={() =>
                              providerDetailCategories?.goToreplayPost(
                                element?.category?.id?.toString(),
                                element?.id?.toString()
                              )
                            }
                          >
                            {" "}
                            {element?.title}
                          </h3>
                          {element?.labels?.map((label: any, index: number) => (
                            <span
                              className="topic"
                              key={index}
                              onClick={() =>
                                providerDetailCategories?.goToLabelCategory(
                                  element?.category?.id?.toString(),
                                  label?.id?.toString()
                                )
                              }
                            >
                              {label?.title}
                            </span>
                          ))}
                        </div>
                        <div className="rightItem likeContent">
                          <span className="icon-like-full"></span>
                          {element?.numberOfLikes}
                        </div>
                      </li>
                    )
                  )}

                  {providerDetailCategories?.topLikedContributions.length >=
                    4 && (
                    <li
                      className="linkForLi"
                      onClick={() =>
                        providerDetailCategories?.viewAllContributor()
                      }
                    >
                      <a
                        href="javascript"
                        className="viewAll"
                        onClick={(e) => e?.preventDefault()}
                      >
                        View all
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </div>

            <div className="block">
              <h2 className="title">Top liked authors</h2>
              {providerDetailCategories?.loaderAuthor ? (
                <LoadingArticle />
              ) : (
                <ul className="separateElement">
                  {providerDetailCategories?.topLikedAuthor?.map(
                    (el: IpropsAuthor, index: number) => (
                      <li key={index}>
                        <div
                          className="leftItem itemAuthor"
                          onClick={() =>
                            providerDetailCategories.goActivity(
                              el?.user_to_like_id
                            )
                          }
                        >
                          <div className="imageAffiliatedcompany">
                            <img
                              src={fileUrl(el?.profil_picture)}
                              alt="profile"
                            />
                          </div>
                          <div className="infoAffiliated">
                            <h3 className="title">{el?.user_name}</h3>
                            <div className="post">
                              {refaCtoNullKey(el?.job_title)}
                            </div>
                          </div>
                        </div>
                        <div className="rightItem likeContent">
                          <span className="icon-like-full"></span>
                          {refaCtoNullKey(el?.number_of_like)}
                        </div>
                      </li>
                    )
                  )}
                  {providerDetailCategories?.topLikedAuthor?.length >= 4 && (
                    <li
                      className="linkForLi"
                      onClick={() => providerDetailCategories?.viewAllAuthor()}
                    >
                      <a
                        href="javascript"
                        className="viewAll"
                        onClick={(e) => e?.preventDefault()}
                      >
                        View all
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </div>

            <div className="block">
              <h2 className="title">Top best answer authors</h2>
              {providerDetailCategories?.loaderContributor ? (
                <LoadingArticle />
              ) : (
                <ul className="separateElement">
                  {providerDetailCategories?.topBestAnswerAuthor?.map(
                    (element: IPropsBestAnswer, index: number) => (
                      <li key={index}>
                        <div
                          className="leftItem itemAuthor"
                          onClick={() =>
                            providerDetailCategories.goActivity(element?.id)
                          }
                        >
                          <div className="imageAffiliatedcompany empty"></div>
                          <div className="infoAffiliated">
                            <h3 className="title">{element?.user_name}</h3>
                            <div className="post">
                              {refaCtoNullKey(element?.job_title)}
                            </div>
                          </div>
                        </div>
                        <div className="rightItem solutionContent">
                          <b> {refaCtoNullKey(element?.order_rank)}</b>{" "}
                          solutions
                        </div>
                      </li>
                    )
                  )}

                  {providerDetailCategories?.topBestAnswerAuthor?.length >=
                    4 && (
                    <li
                      className="linkForLi"
                      onClick={(e) => {
                        e?.preventDefault();
                        providerDetailCategories.viewAllBestAnswerAuthor();
                      }}
                    >
                      <a href="javascript" className="viewAll">
                        View all
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
