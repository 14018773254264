import React from "react";
import { fileUrl } from "../../../../../../utils";

const CompanyCardGuest = (props: any) => {
  return (
    <div className="contentISV">
      <h2 className="title">Guest account</h2>
      <ul className="svList">
        <li>
          <div className="imgSv" style={{ cursor: "pointer" }}>
            {props?.userState?.guestCompany?.logo && (
              <img
                src={fileUrl(props?.userState?.guestCompany?.logo)}
                alt="Software vendor logo"
              />
            )}
          </div>
          <div className="infoSV">
            <>
              <h3 className="title">{props?.userState?.company_name}</h3>
            </>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CompanyCardGuest;
