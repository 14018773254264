import React, { memo } from "react";
import { useHistory } from "react-router";

interface IProps {
  text?: string;
  aclasses?: string;
  liclasses?: string;
  url?: string;
  icon?: boolean;
  iconclasses?: string;
  target?: string;
}

function FooterLink(props: IProps) {
  const history = useHistory();
  const navigateTo = (url: string) => {
    history.push(url);
  };
  let textLabel = "Text";
  let isIcon = false;
  if (props?.text) textLabel = props?.text;
  if (props?.icon) isIcon = props?.icon;

  let target = props?.target ?? "_self";

  return (
    <li className={props?.liclasses}>
      <a
        href={props?.target !== "_blank" ? "javascript:;" : props?.url}
        onClick={() => props?.target !== "_blank"? (props.url && navigateTo(props?.url)) : null}
        className={props?.aclasses}
        target={props?.target}
      >
        {isIcon ? <span className={props?.iconclasses}></span> : textLabel}
      </a>
    </li>
  );
}

export default memo(FooterLink);
