/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react';
import './Dashboard.scss';

import UseDashBoard from '../../../controller/dashboard-ctr/commun-dashboars/commun-dashboard';
import {
  INVESTOR,
  ISV,
  OEM_PROGRAM,
  RESELLER,
} from '../../../service/constant/index';
import {
  concatString,
  fileUrl,
  getTxtNameAccount,
  refaCtoNullKey,
  uppercaseFirstLetter,
} from '../../../utils';
import { useSelector } from 'react-redux';
import { rootState } from '../../../redux/reducers';
import Header from '../../widget/index';
import * as Section from './sections/index';
import { LoadingAllPage } from '../../widget/loader';
import { Redirect } from 'react-router-dom';

export default function DashboardPage() {
  const providerUseDashBoard = { ...UseDashBoard() };
  const userState = useSelector((state: rootState) => state.userReducer);
  const isOem = userState?.roles?.[0] === OEM_PROGRAM;
  const isResseller = userState?.roles?.[0] === RESELLER;
  const isInvestor = userState?.roles?.[0] === INVESTOR;
  const isShowLoader =
    providerUseDashBoard.loader &&
    Object.values(providerUseDashBoard.loaderAtribute).every(
      (el) => el === true
    );
  const urlPath = window.location.pathname;

  console.log('urlPath', urlPath);

  const isOemPage = isOem && urlPath !== '/community/oem-feeds';

  const isInvestorPage = isInvestor && urlPath !== '/community/investor-feeds';

  if (isOemPage) {
    return <Redirect to='/community/oem-feeds' />;
  }

  if (isInvestorPage) {
    return <Redirect to='/community/investor-feeds' />;
  }

  return (
    <div className='bggris dashboard'>
      <Header />

      {userState?.roles?.[0] === ISV && <Section.CompletedSection />}

      <section className='containerDashboard'>
        {isShowLoader && <LoadingAllPage />}
        <div className='container mx-auto row'>
          <div className='leftDashboard'>
            <div className='block allblock'>
              <h3 className='title'>{getTxtNameAccount(userState.roles)}</h3>
              <ul className='listAffiliatedleft'>
                <li onClick={providerUseDashBoard.showProfil}>
                  <div
                    className={`imageAffiliatedcompany ${
                      providerUseDashBoard?.userReducer?.file_name
                        ? 'empty'
                        : ''
                    }`}
                  >
                    <img
                      src={`${fileUrl(
                        providerUseDashBoard?.userReducer?.file_name
                      )} `}
                      alt=''
                    />
                  </div>
                  <div className='infoAffiliated'>
                    <h4 className='title pointer'>
                      {concatString(
                        uppercaseFirstLetter(
                          providerUseDashBoard?.userReducer?.first_name
                        ),
                        uppercaseFirstLetter(
                          providerUseDashBoard?.userReducer?.last_name
                        ),
                        ' '
                      )}
                    </h4>
                    <div className='post'>
                      {uppercaseFirstLetter(
                        providerUseDashBoard?.userReducer?.job_title
                      )}
                    </div>
                    <div className='adress'>
                      {uppercaseFirstLetter(
                        providerUseDashBoard?.userReducer?.country?.country_name
                      )}
                    </div>
                  </div>
                </li>
                {providerUseDashBoard?.companyPageObject &&
                  !isOem &&
                  !isResseller && (
                    <li
                      className='companyItem'
                      onClick={() => providerUseDashBoard?.goToCompanyPage()}
                    >
                      <div
                        className={`imageAffiliatedcompany ${
                          !providerUseDashBoard?.companyPageObject?.profil
                            ? 'empty'
                            : 'imgSv'
                        }`}
                      >
                        {providerUseDashBoard?.companyPageObject?.profil && (
                          <img
                            src={fileUrl(
                              providerUseDashBoard?.companyPageObject?.profil
                            )}
                            alt=''
                          />
                        )}
                      </div>
                      <div className='infoAffiliated'>
                        <h4 className='title'>
                          {
                            providerUseDashBoard?.companyPageObject
                              ?.company_name
                          }
                        </h4>

                        <div className='post'>
                          {concatString(
                            refaCtoNullKey(
                              providerUseDashBoard?.companyPageObject?.country,
                              ''
                            ),
                            refaCtoNullKey(
                              providerUseDashBoard?.companyPageObject?.city,
                              ''
                            )
                          )}
                        </div>
                      </div>
                    </li>
                  )}
              </ul>
            </div>
            {userState?.roles?.[0] === ISV && (
              <Section.UpcommingEvent
                data={providerUseDashBoard.upComingEvent}
                viewAllEvent={() => providerUseDashBoard?.viewAllEvent()}
                navigateSelectedEvent={(eventId: number) =>
                  providerUseDashBoard.navigateSelectedEvent(eventId)
                }
              />
            )}

            {providerUseDashBoard.subscribedDiscussion?.length > 0 && (
              <Section.SubscribeDiscussion
                data={providerUseDashBoard.subscribedDiscussion}
              />
            )}
          </div>

          <div className='rightDashboard'>
            <Section.MyFeeds />
          </div>
        </div>
      </section>
    </div>
  );
}
